import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  TablePagination,
} from "@mui/material"
import dayjs from "dayjs"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Subhead, TextBody, Title2, Title3 } from "../../components/shared/Typography"
import useToggle from "../../hooks/useToggle"
import { Site, Subscription, UserGroupUser } from "../../types"
import enforceError from "../../utils/enforce-error"
import SiteLink from "../Sites/components/SiteLink"
import useGetSitesByUserId from "../Sites/hooks/useGetSitesByUserId"
import SubscriptionLink from "../Subscriptions/components/SubscriptionLink"
import DeleteUserConfirmationDialog from "./components/DeleteUserConfirmationDialog"
import useMergeUserDialog from "./components/MergeUserDialog"
import ShadowbanUserConfirmationDialog from "./components/ShadowbanUserConfirmationDialog"
import UnshadowbanUserConfirmationDialog from "./components/UnshadowbanUserConfirmationDialog"
import UserSiteCard from "./components/UserSiteCard"
import useDeleteUser from "./hooks/useDeleteUser"
import useGetUserById from "./hooks/useGetUserById"
import useGetUserShadowbanById from "./hooks/useGetUserShadowbanById"
import useGetUserSubscriptions from "./hooks/useGetUserSubscriptions"
import useShadowBanUser from "./hooks/useShadowBanUser"
import useUnshadowBanUser from "./hooks/useUnshadowBanUserByID"
import { Row } from "../Sites/components/SiteLayoutShared"
import useGetUserUserGroups from "./hooks/useGetUserUserGroups"
import SubscriptionBadge from "../../components/shared/SubscriptionBadge"
import Avatar from "../../components/shared/Avatar"
import config from "../../config"
import PlaceholderAvatar from "../../components/images/placeholder-avatar.png"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import UserIdentityTable from "./components/UserIdentityTable"
import useGetUserNotes from "./hooks/useGetUserNotes"
import NotesTable from "./components/NotesTable"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import CreateNoteDialog from "./components/CreateNoteDialog"

function extractUserGroupNames(userGroupUsers: UserGroupUser[]) {
  const groupNames: string[] = []

  userGroupUsers.forEach((user: UserGroupUser) => {
    const lowercaseName = user.userGroup?.name.toLowerCase()
    groupNames.push(lowercaseName || "")
  })

  return groupNames
}

const UserDetailsScreen: FunctionComponent = () => {
  const { user, getUserById, error: userError } = useGetUserById()
  const [loading, setLoading] = useState(true)
  const [createNoteOpen, setCreateNoteOpen] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()
  const { isOn: deleteConfIsOpen, turnOn: openDeleteConf, turnOff: closeDeleteConf } = useToggle()
  const {
    isOn: shadowbanConfIsOpen,
    turnOn: openShadowbanConf,
    turnOff: closeShadowbanConf,
  } = useToggle()
  const {
    isOn: unshadowbanConfIsOpen,
    turnOn: openUnshadowbanConf,
    turnOff: closeUnshadowbanConf,
  } = useToggle()
  const params: any = useParams()
  const userId = Number(params.userId)

  const { getSitesByUserId, sites, paginationData, loading: sitesLoading } = useGetSitesByUserId(10)
  const {
    getSubscriptions,
    subscriptions,
    paginationData: subscriptionsData,
    loading: subscriptionsLoading,
  } = useGetUserSubscriptions(10)
  const { getUserShadowban, shadowbanned } = useGetUserShadowbanById()
  const { getUserUserGroups, userGroups } = useGetUserUserGroups()

  const navToDomainSearch = useCallback(() => {
    if (!user) {
      return
    }
    navigate(`/domains?type=user_id&term=${user.id}`)
  }, [navigate, user])

  // User Notes
  const { getUserNotes, notes, paginationData: notesPaginationData } = useGetUserNotes()

  const handleCreateNoteClose = async () => {
    setCreateNoteOpen(false)
    await getUserNotes(userId)
  }

  const getUser = useCallback(async () => {
    try {
      setLoading(true)
      setError(null)
      getUserById(userId)
      getSitesByUserId(userId)
      getSubscriptions(userId)
      getUserShadowban(userId)
      getUserUserGroups(userId)
      getUserNotes(userId)
    } catch (error) {
      setError(enforceError(error))
    } finally {
      setLoading(false)
    }
  }, [
    userId,
    getUserById,
    getSitesByUserId,
    getSubscriptions,
    getUserShadowban,
    getUserUserGroups,
    getUserNotes,
  ])

  const { deleteUser, error: errorDeletingUser, loading: processingDeleteUser } = useDeleteUser()

  useEffect(() => {
    if (userId) {
      getUser()
    }
    // eslint-disable-next-line
  }, [])

  const handleDeleteUserSuccess = useCallback(() => {
    closeDeleteConf()
    navigate(-1)
    toast.success("Successfully deleted user")
  }, [closeDeleteConf, navigate])

  const handleDeleteUser = useCallback(() => {
    if (!deleteConfIsOpen) {
      openDeleteConf()
    } else {
      deleteUser(userId, handleDeleteUserSuccess)
    }
  }, [deleteConfIsOpen, userId, handleDeleteUserSuccess, deleteUser, openDeleteConf])

  const {
    shadowBanUser,
    error: errorShadowbanningUser,
    loading: loadingShadowbanUser,
  } = useShadowBanUser()

  const handleShadowbanUserSuccess = useCallback(() => {
    closeShadowbanConf()
    navigate(-1)
    toast.success("Successfully shadowbanned user")
  }, [closeShadowbanConf, navigate])

  const handleShadowbanUser = useCallback(() => {
    if (!shadowbanConfIsOpen) {
      openShadowbanConf()
    } else {
      shadowBanUser(userId, handleShadowbanUserSuccess)
    }
  }, [shadowbanConfIsOpen, userId, handleShadowbanUserSuccess, shadowBanUser, openShadowbanConf])

  const {
    unshadowBanUser,
    error: errorUnshadowbanningUser,
    loading: loadingUnshadowbanUser,
  } = useUnshadowBanUser()

  const handleUnshadowbanUserSuccess = useCallback(() => {
    closeUnshadowbanConf()
    navigate(-1)
    toast.success("Successfully unshadowbanned user")
  }, [closeUnshadowbanConf, navigate])

  const handleUnshadowbanUser = useCallback(() => {
    if (!unshadowbanConfIsOpen) {
      openUnshadowbanConf()
    } else {
      unshadowBanUser(userId, handleUnshadowbanUserSuccess)
    }
  }, [
    unshadowbanConfIsOpen,
    userId,
    handleUnshadowbanUserSuccess,
    unshadowBanUser,
    openUnshadowbanConf,
  ])

  // User Merge
  const { mergeUserDialog, openMergeUserDialog } = useMergeUserDialog()

  if (loading) {
    return (
      <div
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (error || userError) {
    return <ErrorComponent error={error || userError} />
  }

  if (!user) {
    return null
  }

  const name = [user.first_name, user.last_name].filter(Boolean).join(" ") || "N/A"
  const userGroupNames = extractUserGroupNames(userGroups?.results || [])
  const gusBetaUser = userGroupNames.some((name) => name.includes("gus"))

  return (
    <ScreenContainer>
      <Box
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        display="flex"
        width="100%"
      >
        <Avatar
          src={
            user.profile_picture_asset_id
              ? `${config.assetsBaseUrl}/${user.profile_picture_asset_id}`
              : PlaceholderAvatar
          }
          alt="User Profile Photo"
          sx={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
          }}
        />
        <Title2>{name || "-"}</Title2>
        <TextBody>User ID: {user.id}</TextBody>
        <TextBody>{user.email || "-"}</TextBody>
        <TextBody>
          <b>Analytics ID:</b> {user.analytics_id || "-"}
        </TextBody>
        {user.instagram ? <TextBody>Instagram: {user.instagram}</TextBody> : null}
        {shadowbanned ? (
          <TextBody>{`Shadowbanned: ${dayjs(shadowbanned.created_at).format(
            "MM/DD/YYYY",
          )}`}</TextBody>
        ) : null}
        <Row style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          {gusBetaUser && (
            <SubscriptionBadge text="GUS Beta" textColor="white" backgroundColor="#2753ff" />
          )}
          <Subhead>Deleted User: </Subhead>
          <Checkbox disabled style={{ padding: 0 }} checked={!!user.deleted_at} />
        </Row>
      </Box>
      <Box
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        display="flex"
        width="100%"
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            sx={{
              width: "300px",
              mt: "6px",
              marginRight: "5px",
            }}
            onClick={navToDomainSearch}
          >
            View Domains
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={!shadowbanned ? handleShadowbanUser : handleUnshadowbanUser}
            fullWidth
            sx={{ width: "300px", mt: "5px" }}
          >
            {!shadowbanned ? "Shadowban User" : "Un-Shadowban User"}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => user && openMergeUserDialog(user)}
            fullWidth
            sx={{ width: "300px", mt: "5px", marginRight: "5px" }}
          >
            Merge User
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteUser}
            fullWidth
            sx={{ width: "300px", mt: "5px" }}
          >
            Delete User
          </Button>
        </Box>
      </Box>

      <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Title3>Identities</Title3>
                </Grid>
                {error ? <ErrorComponent error={error} /> : null}
              </Grid>
              <UserIdentityTable
                identities={user.identities || []}
                user={user}
                refreshAction={getUser}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Title3>
                User Notes{" "}
                <IconButton onClick={() => setCreateNoteOpen(true)} sx={{ padding: 0, margin: 0 }}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Title3>
              <NotesTable
                notes={notes}
                paginationData={notesPaginationData}
                refreshData={() => getUserNotes(userId)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              flex: 1,
            }}
          >
            <div>
              {!sitesLoading ? (
                <div>
                  <Title3>Sites:</Title3>
                  <TablePagination
                    component="div"
                    count={paginationData.count}
                    page={paginationData.page}
                    onPageChange={paginationData.onPageChange}
                    rowsPerPage={paginationData.rowsPerPage}
                    onRowsPerPageChange={paginationData.onRowsPerPageChange}
                  />
                  <ul
                    style={{
                      height: "100%",
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      flexBasis: "100%",
                      justifyContent: "flex-start",
                      listStyle: "none",
                    }}
                  >
                    {sites.map((site: Site) => {
                      return (
                        <UserSiteCard
                          key={site.id}
                          id={site.id}
                          title={site.title}
                          domain={site.domain || site.backup_url}
                          role={site.user_role}
                          site={site}
                        ></UserSiteCard>
                      )
                    })}
                  </ul>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              flex: 1,
            }}
          >
            {!subscriptionsLoading ? (
              <div>
                <Title3>Subscriptions:</Title3>
                <TablePagination
                  component="div"
                  count={subscriptionsData.count}
                  page={subscriptionsData.page}
                  onPageChange={subscriptionsData.onPageChange}
                  rowsPerPage={subscriptionsData.rowsPerPage}
                  onRowsPerPageChange={subscriptionsData.onRowsPerPageChange}
                />
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Expires</th>
                      <th>Product</th>
                      <th>Site ID(s)</th>
                      <th>Subscription ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions.map((subscription: Subscription) => {
                      return (
                        <tr key={subscription.id}>
                          <td>{subscription.type}</td>
                          <td>{subscription.status === "active" ? "✅" : "❌"}</td>
                          <td>{dayjs(subscription.created_at).format("MM/DD/YYYY")}</td>
                          <td>
                            {subscription.end_date &&
                              dayjs(subscription.end_date).format("MM/DD/YYYY")}
                          </td>
                          <td>{subscription.sk_product_id}</td>
                          <td>
                            {subscription.site_ids.map((id) => (
                              <SiteLink key={id} siteId={id}>
                                {id}{" "}
                              </SiteLink>
                            ))}
                          </td>
                          <td>
                            <SubscriptionLink subscriptionId={subscription.id}>
                              {subscription.id}
                            </SubscriptionLink>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteUserConfirmationDialog
        isOpen={deleteConfIsOpen}
        close={closeDeleteConf}
        handleConfirmation={handleDeleteUser}
        hasError={!!errorDeletingUser}
        loading={!!processingDeleteUser}
        user={user}
      />
      <ShadowbanUserConfirmationDialog
        isOpen={shadowbanConfIsOpen}
        close={closeShadowbanConf}
        handleConfirmation={handleShadowbanUser}
        hasError={!!errorShadowbanningUser}
        loading={!!loadingShadowbanUser}
        user={user}
      />
      <UnshadowbanUserConfirmationDialog
        isOpen={unshadowbanConfIsOpen}
        close={closeUnshadowbanConf}
        handleConfirmation={handleUnshadowbanUser}
        hasError={!!errorUnshadowbanningUser}
        loading={!!loadingUnshadowbanUser}
        user={user}
      />
      {createNoteOpen ? (
        <CreateNoteDialog
          isOpen={createNoteOpen}
          close={handleCreateNoteClose}
          type="user"
          typeId={user.id}
        />
      ) : null}
      {mergeUserDialog}
    </ScreenContainer>
  )
}

export default UserDetailsScreen
