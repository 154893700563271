import { useCallback, useEffect, useState } from "react"
import { SUBSCRIPTIONSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Subscription } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetSubscriptionsByUserIds = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const [storedUserIds, setStoredUserIds] = useState<number[]>([])
  const [storedStatus, setStoredStatus] = useState<string>("active")

  const { data, loading, error, performAction } = useHandleRequest(
    (
      handleUnauthenticated,
      userIds: number[],
      status: string,
    ): Promise<PagedResponse<Subscription>> => {
      let queryString = `${SUBSCRIPTIONSV4}/list?limit=${encodeURIComponent(
        rowsPerPage,
      )}&offset=${encodeURIComponent(page * rowsPerPage)}`
      const filteredUserIds = userIds.filter((id) => id !== null && typeof id === "number")
      return makeRequest<PagedResponse<Subscription>>(
        queryString,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_ids: filteredUserIds, status }),
        },
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (storedUserIds.length) {
      performAction(storedUserIds, storedStatus)
    }

    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const getSubscriptionsByUserIds = useCallback(
    async (userIds: number[], status: string = "active") => {
      setStoredUserIds(userIds)
      setStoredStatus(status)
      return await performAction(userIds, status)
    },
    [performAction],
  )

  return {
    getSubscriptionsByUserIds,
    loading,
    error,
    subscriptions: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetSubscriptionsByUserIds
