import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Subdomain } from "../../../types"
import { SUBDOMAINS } from "../../../constants/urlDefs"

const useGetSubdomain = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, subdomainId: string | number): Promise<Subdomain> => {
    let queryString = `${SUBDOMAINS}/${subdomainId}`
    return makeRequest<Subdomain>(queryString, {}, { handleUnauthenticated })
  })

  return { getSubdomain: performAction, loading, error, subdomain: data }
}

export default useGetSubdomain
