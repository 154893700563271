import { TEKTON_PIPELINESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TektonPipeline } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPipelineById = () => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated, pipelineId: string): Promise<TektonPipeline> => {
      return makeRequest<TektonPipeline>(
        `${TEKTON_PIPELINESV4}/${pipelineId}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return {
    loading,
    error,
    performAction,
    data,
  }
}

export default useGetPipelineById
