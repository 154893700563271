import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { UserGroup } from "../../../types"

import UserGroupRow from "./UserGroupRow"

interface Props {
  userGroups: UserGroup[]
  handleEdit: (userGroup: UserGroup) => any
  canEdit: boolean
}

const UserGroupTable: FunctionComponent<Props> = ({ userGroups, handleEdit, canEdit }) => {
  const columns = canEdit ? ["ID", "Name", "Users", "Action"] : ["ID", "Name"]

  const getRows = useCallback(() => {
    return userGroups.map((userGroup: UserGroup, index: number) => (
      <UserGroupRow userGroup={userGroup} key={index} handleEdit={handleEdit} canEdit={canEdit} />
    ))
  }, [userGroups, canEdit, handleEdit])

  return <BasicTable columns={columns} rows={getRows()} />
}

export default UserGroupTable
