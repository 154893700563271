import { Box, ImageList, ImageListItem, ImageListItemBar, TablePagination } from "@mui/material"
import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SitePageGroupToSubLink from "./components/SitePageGroupToSubLink"
import useGetSitePages from "./hooks/useGetSitePages"

const SitePagesScreen: FunctionComponent = () => {
  const { siteId } = useParams<{ siteId: string }>()
  const { loading, error, getPages, pages, paginationData } = useGetSitePages()

  useEffect(() => {
    getPages(Number(siteId), "&type=Group")
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title={`Site ${siteId} Pages`}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      <TablePagination
        component="div"
        count={paginationData.count}
        page={paginationData.page}
        onPageChange={paginationData.onPageChange}
        rowsPerPage={paginationData.rowsPerPage}
        onRowsPerPageChange={paginationData.onRowsPerPageChange}
      />
      <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center", mt: "20px" }}>
        <ImageList rowHeight={240} sx={{ width: "100%", height: "100%" }} cols={6}>
          {pages.map((page) => (
            <ImageListItem key={page.id}>
              <SitePageGroupToSubLink siteId={page.site_id} groupId={page.id}>
                <img
                  height={240}
                  src={(page.thumbnails?.small && page.thumbnails?.small) ?? null}
                  alt={page.title || page.slug || undefined}
                />
              </SitePageGroupToSubLink>
              <ImageListItemBar title={page.title} subtitle={page.path} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </ScreenContainer>
  )
}

export default SitePagesScreen
