import { IDENTITIESV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useUpdateUserIdentity = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      identityId: number,
      userId: number,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${IDENTITIESV1}/${identityId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateIdentity: performAction, loading, error }
}

export default useUpdateUserIdentity
