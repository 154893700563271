import { Box, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Subhead, Title3 } from "../../../components/shared/Typography"

export const Row = styled(Box)``
Row.defaultProps = {
  sx: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
}

export const StyledText: any = styled(Subhead)`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
`

export const StyledTitle = styled(Title3)`
  color: ${({ theme }) => theme.colors.primary};
`

export const ReassignDomainButton = styled(Button)`
  width: 300px;
`

export const AddDNSRecordButton = styled(Button)`
  width: 300px;
`

export const AddApplePayButton = styled(Button)`
  width: 300px;
`
