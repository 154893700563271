import { Grid, Button, TextField } from "@mui/material"

import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../../components/shared/Error"
import useGetUserGroupUser from "../hooks/useGetUserGroupsUser"
import UserGroupUserTable from "./UserGroupUserTable"
import useCreateUserGroupsUser from "../hooks/useCreateUserGroupsUser"
import useRemoveUserGroupsUser from "../hooks/useDeleteUserGroupsUser"
import { useParams } from "react-router-dom"
import useGetUserGroup from "../hooks/useGetUserGroup"
import ScreenContainer from "../../../components/shared/layout/ScreenContainer"
import useParseEmail from "../hooks/useParseEmailAddresses"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"
import { FormikProvider, useFormik } from "formik"
import { toast } from "react-hot-toast"
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner"

interface Props {}

const UserGroupDetailScreen: FunctionComponent<Props> = () => {
  const [textArea, setTextArea] = useState<string>("")

  const { users, getUserGroupUser } = useGetUserGroupUser()

  const { userGroupId } = useParams<{ userGroupId: string }>()

  const { getUserGroup, userGroup } = useGetUserGroup()

  const { createUserGroupUser, error: createUserError } = useCreateUserGroupsUser()

  const { removeUserGroupUser, error: removeUserError } = useRemoveUserGroupsUser()

  const { parseEmailAddresses, error: emailError, loading } = useParseEmail()

  const formikUser = useFormik({
    initialValues: {
      userOption: "",
    } as any,

    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => addUsers(),
    onReset: (values) => {
      values.userOption = ""
    },
  })

  const addUsers = async () => {
    if (userGroup?.id) {
      if (textArea.length !== 0) {
        let addresses = await parseEmailAddresses(textArea)
        if (addresses && !Array.isArray(addresses)) {
          addresses = [addresses]
        }

        if (addresses) {
          await Promise.all(
            addresses.map(async (address) => {
              if (!users.find((user) => user.email === address.email)) {
                const ugu = await createUserGroupUser(userGroup.id, { email: address.email })
                if (ugu) {
                  users.splice(users.findIndex((user) => user.email > address.email) - 1, 0, ugu)
                }
              } else {
                toast.error(`${address.email} is already a member of this group`)
              }
            }),
          )
          setTextArea("")
        }
      }

      if (formikUser.values.userOption && formikUser.values.userOption.user?.id) {
        console.log(users)
        if (!users.find((user) => user.user_id === formikUser.values.userOption.user?.id)) {
          const ugu = await createUserGroupUser(userGroup.id, {
            user_id: formikUser.values.userOption.user?.id,
          })
          if (ugu) {
            users.splice(users.findIndex((user) => user.email > ugu.email) - 1, 0, ugu)
          }
          formikUser.resetForm()
        } else {
          toast.error(
            `${formikUser.values.userOption.user?.email} is already a member of this group`,
          )
        }
      }

      await getUserGroupUser(userGroup.id)
    }
  }

  const removeUser = async (id: number) => {
    if (userGroup?.id) {
      await removeUserGroupUser(userGroup.id, id)
      await getUserGroupUser(userGroup?.id)
    }
  }

  useEffect(() => {
    if (userGroupId) {
      getUserGroup(parseInt(userGroupId, 10))
      getUserGroupUser(parseInt(userGroupId, 10))
    }

    // eslint-disable-next-line
  }, [userGroupId])

  return (
    <ScreenContainer title={userGroup?.name}>
      <Grid container>
        <Grid item xs={12}>
          {(!!removeUserError || !!createUserError || !!emailError) && (
            <ErrorComponent error={createUserError || removeUserError || emailError} />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-multiline-flexible"
            maxRows={8}
            minRows={4}
            fullWidth
            label="Add Users"
            multiline
            value={textArea}
            onChange={(event) => setTextArea(event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button onClick={addUsers}>Add Users</Button>
        </Grid>
        <Grid item xs={2}>
          {loading ? <LoadingSpinner /> : ""}
        </Grid>
      </Grid>
      <Grid container>
        <FormikProvider value={formikUser}>
          <Grid item xs={6}>
            <UserSearchAutocomplete />
          </Grid>
        </FormikProvider>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <UserGroupUserTable users={users} removeUser={removeUser} />
        </Grid>
      </Grid>
    </ScreenContainer>
  )
}

export default UserGroupDetailScreen
