import  { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Employee } from "../../../types"
import EmployeeRow from "./EmployeeRow"

interface EmployeesTableProps {
  employees: Employee[]
  deleteEmployee: any
  canDelete: boolean
  canUpdate: boolean
  updateEmployee: (employee: Employee) => any
}

const EmployeesTable: FunctionComponent<EmployeesTableProps> = ({
  employees,
  deleteEmployee,
  canDelete,
  canUpdate,
  updateEmployee,
}) => {
  const getColumns = useCallback(() => {
    const columns = ["ID", "Name", "Email", "Roles"]
    if (canUpdate) {
      columns.push("Update")
    }
    if (canDelete) {
      columns.push("Delete")
    }
    return columns
  }, [canUpdate, canDelete])

  const getEmployeeRows = useCallback(() => {
    return employees.map((employee: Employee, index: number) => (
      <EmployeeRow
        key={index}
        employee={employee}
        deleteEmployee={deleteEmployee}
        canDelete={canDelete}
        canUpdate={canUpdate}
        updateEmployee={updateEmployee}
      />
    ))
  }, [employees, deleteEmployee, canDelete, canUpdate, updateEmployee])

  return <BasicTable columns={getColumns()} rows={getEmployeeRows()} />
}

export default EmployeesTable
