import { Box } from "@mui/material"
import { FunctionComponent } from "react"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import { useAuth } from "../../services/auth-service"

const DashboardScreen: FunctionComponent = () => {
  const { getUser } = useAuth()
  const employee = getUser()
  const name =
    employee?.employee?.name.substring(0, employee?.employee?.name.indexOf(" ")) ?? "to Universe"

  return (
    <ScreenContainer title={`Welcome ${name}`}>
      <Box height="100vh" display="flex" flexDirection="column" marginTop={1}>
        <iframe
          title="dashboard"
          src="https://metrics.univer.se/public/dashboard/0daf6a39-da8d-4b69-8133-629e222ef316#bordered=false&titled=false"
          width="100%"
          height="100%"
          allowTransparency
          frameBorder={0}
        ></iframe>
      </Box>
    </ScreenContainer>
  )
}

export default DashboardScreen
