import { styled } from "@mui/material/styles"
import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

export type IdentityReassignmentType = {
  identityId: number
  newUserId: number
}

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  reassignment: IdentityReassignmentType
  userId: number
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const IdentityReassignmentConfirmation: FunctionComponent<Props> = ({
  reassignment,
  userId,
  hasError,
  ...rest
}) => {
  return (
    <ConfirmationDialog
      title="Reassign Identity"
      errorMessage={hasError ? "There was a problem reassigning the identity" : null}
      affirmativeButtonText="Reassign"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>
              {`Wait! Are you sure you want to reassign identity ${reassignment.identityId} to user ${reassignment.newUserId}?`}
            </TextBody>
          </ConfirmationRow>
          <ConfirmationRow>
            <StyledText>
              {`This will NOT combine the sites or other data of these two users. User ${reassignment.newUserId} will NOT gain access to user ${userId}'s sites.`}
            </StyledText>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default IdentityReassignmentConfirmation
