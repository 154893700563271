import "highlight.js/styles/xcode.css"
import hljs from "highlight.js"
import { useEffect } from "react"

import { Button } from "@mui/material"
import { FunctionComponent } from "react"
import Dialog from "../../../components/shared/Dialog"
import CopyIcon from "../../../components/shared/CopyIcon"

type Props = {
  content: object | undefined
  close: () => any
  isOpen: boolean
  title?: string
}

const SitePageContentDialog: FunctionComponent<Props> = ({
  content,
  close,
  isOpen,
  title = "Content",
}) => {
  const formatJson = (json: any) => JSON.stringify(json, null, 2)

  // We need it configured this way so that hljs can highlight the code
  useEffect(() => {
    hljs.highlightAll()
  })

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={close}
      content={
        <>
          <div>
            <CopyIcon content={JSON.stringify(content)} />
            <pre>
              <code className="language-json">{formatJson(content ?? {})}</code>
            </pre>
          </div>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary" variant="outlined">
            Close
          </Button>
        </>
      }
    />
  )
}

export default SitePageContentDialog
