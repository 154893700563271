import  { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  subscriptionId: string | number
}

type SubscriptionLinkProps = Omit<Props, "to">

const SubscriptionLink: FunctionComponent<SubscriptionLinkProps> = ({
  subscriptionId,
  children,
  ...rest
}) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/subscriptions/${subscriptionId}`}>
    {children}
  </Link>
)

export default SubscriptionLink
