import { useEffect, useCallback, useState } from "react"
import { TEKTON_PIPELINERUNSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TektonPipelineRunLog } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPipelineRunLogsById = (pipelineRunId: string, status: string | undefined) => {
  const [logs, setLogs] = useState<TektonPipelineRunLog[]>([])
  const [timestamp, setTimestamp] = useState<number>(0)

  const request = useHandleRequest(
    (handleUnauthenticated, timestamp): Promise<TektonPipelineRunLog[]> => {
      let url = `${TEKTON_PIPELINERUNSV4}/${pipelineRunId}/logs`
      if (timestamp) {
        url += `?timestamp=${timestamp}`
      }
      return makeRequest<TektonPipelineRunLog[]>(url, {}, { handleUnauthenticated })
    },
  )

  const { loading, error, data, performAction } = request

  const handler = useCallback(async () => {
    const newLogs = await performAction(timestamp)

    if (newLogs && newLogs.length) {
      setLogs((logs) => [...logs, ...newLogs])
      setTimestamp(newLogs[newLogs.length - 1].timestamp + 1)
    }
  }, [timestamp, performAction])

  useEffect(() => {
    if (loading) return

    if (!status) return

    if (status !== "Running" && data && data.length === 0) return

    const timer = setTimeout(handler, status === "Running" ? 5000 : 1000)

    return () => clearTimeout(timer)
  }, [handler, performAction, status, loading, data])

  return {
    loading,
    error,
    data: logs,
  }
}

export default useGetPipelineRunLogsById
