import { Domain } from "domain"
import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useVerifyVercel = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, domainId: number, onSuccess?: () => any): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}/${domainId}/vercel/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { verifyVercel: performAction, loading, error }
}

export default useVerifyVercel
