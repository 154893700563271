import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { UserGroup } from "../../types"
import EditUserGroupModal from "./components/EditUserGroupModal"
import UserGroupTable from "./components/UserGroupTable"
import useGetUserGroups from "./hooks/useGetUserGroups"
import useUpdateExperiment from "./hooks/useUpdateUserGroup"

const UserGroupScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.remoteConfig.userGroups.create)
  const canEdit = hasScope(scopes.remoteConfig.userGroup.update)

  const { userGroups, getUserGroups, loading, error } = useGetUserGroups()
  const [editUserGroup, setUserGroup] = useState<UserGroup | undefined | null>()
  const { isOn: isOpen, turnOn: openModal, turnOff: closeModal } = useToggle()
  const { error: updateError } = useUpdateExperiment()

  const handleEdit = useCallback(
    (userGroup: UserGroup) => {
      setUserGroup(userGroup)
      openModal()
    },
    [openModal],
  )

  const handleCloseDialog = useCallback(() => {
    setUserGroup(null)
    closeModal()
  }, [closeModal, setUserGroup])

  useEffect(() => {
    getUserGroups()
    // eslint-disable-next-line
  }, [])

  const showTable = !loading && userGroups.length > 0

  return (
    <ScreenContainer title="User Groups" requiresScope={scopes.remoteConfig.userGroups.read}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {(!!error || !!updateError) && <ErrorComponent error={error || updateError} />}
      {canCreate && (
        <Button
          variant="outlined"
          color="primary"
          onClick={openModal}
          sx={{ my: "12px", width: "300px" }}
        >
          Create
        </Button>
      )}

      {showTable && (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <UserGroupTable userGroups={userGroups} handleEdit={handleEdit} canEdit={canEdit} />
        </Box>
      )}
      <EditUserGroupModal
        isOpen={isOpen}
        close={handleCloseDialog}
        onRefresh={getUserGroups}
        editUserGroup={editUserGroup}
        canEdit={canEdit}
      />
    </ScreenContainer>
  )
}

export default UserGroupScreen
