import { Box, Button, FormLabel, MenuItem, Select } from "@mui/material"
import { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { Site, Subscription } from "../../../types"
import useGetSitesByIds from "../../Sites/hooks/useGetSitesByIds"
import useRemoveSiteFromSubscription from "../hooks/useRemoveSiteFromSubscription"

interface Props {
  subscription: Subscription
  isOpen: boolean
  close: () => any
  refresh: () => any
}

const RemoveSiteFromSubscriptionDialog: FunctionComponent<Props> = ({
  subscription,
  isOpen,
  close,
  refresh,
}) => {
  const { removeSiteFromSubscription, error, loading } = useRemoveSiteFromSubscription()
  const [selectedSiteId, setSelectedSiteId] = useState<number | undefined>()
  const { sites, getSitesByIds, error: sitesError } = useGetSitesByIds()

  const handleSuccess = () => {
    setSelectedSiteId(undefined)
    refresh()
    close()
    toast.success("Successfully removed site from subscription")
  }

  const handleSubmit = () => {
    if (selectedSiteId) {
      removeSiteFromSubscription(subscription.id, selectedSiteId, handleSuccess)
    }
  }

  const handleChangeSelectedSite = (e: any) => {
    setSelectedSiteId(e.target.value || "")
  }

  useEffect(() => {
    if (sites && sites[0]) {
      setSelectedSiteId(sites[0].id)
    }

    // doing this to more accurately compare that sites has changed
    // eslint-disable-next-line
  }, [JSON.stringify(sites)])

  useEffect(() => {
    if (subscription.site_ids?.length) {
      const siteIds: number[] = subscription.site_ids.map((id: string) => parseInt(id))
      getSitesByIds(siteIds)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={close}
      title={`Remove Site From Subscription: ${subscription.id}`}
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {!!error && (
              <TextBody hasError={!!error}>
                There was a problem with the site subscription removal.
              </TextBody>
            )}
          </Row>
          <Row>
            <FormLabel>
              {sites.length < 1
                ? "No sites are available to remove."
                : "Select site to remove from selected subscription"}
            </FormLabel>
            <Select
              placeholder="Select Site"
              fullWidth
              name="siteSelect"
              variant="outlined"
              id="siteSelect"
              onChange={handleChangeSelectedSite}
              value={selectedSiteId || ""}
              sx={{ mt: "9px", mr: "4px" }}
              error={!!sitesError}
              disabled={sites.length < 2}
            >
              {sites.map((site: Site) => (
                <MenuItem key={site.id} value={site.id}>
                  {`SiteId: ${site.id} - domain: ${site.domain}`}
                </MenuItem>
              ))}
            </Select>
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
            disabled={!selectedSiteId || loading}
          >
            Save
          </Button>
        </>
      }
    />
  )
}

export default RemoveSiteFromSubscriptionDialog
