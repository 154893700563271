import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Domain } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUnlinkDomain = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainId: number | string,
      onSuccess?: () => any,
    ): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}/${domainId}/unlink`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { unlinkDomain: performAction, loading, error }
}

export default useUnlinkDomain
