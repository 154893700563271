import { Button, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"

import { useFormik } from "formik"
import React, { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { BlockTemplate, Page, PageSectionType, Site } from "../../../types"

import useSearchSites from "../../Sites/hooks/useSearchSites"
import useGetSitePages from "../../Sites/hooks/useGetSitePages"
import useGetSitePageById from "../../Sites/hooks/useGetSitePageById"
import useCreateBlockTemplate from "../../BlockTemplates/hooks/useCreateBlockTemplate"
import useGetPageSectionTypes from "../hooks/useGetPageSectionTypes"
import useCreatePageSectionType from "../hooks/useCreatePageSectionType"
import useUpdatePageContent from "../hooks/useUpdatePageContent"

interface Props {
  isOpen: boolean
  close: () => void
  pageSectionTypeUpdate?: PageSectionType | undefined
}

const BulkAddBlockTemplate: FunctionComponent<Props> = ({
  isOpen,
  close,
  pageSectionTypeUpdate,
}) => {
  const { getPageSectionTypes, pageSectionTypes, loading, error } = useGetPageSectionTypes()
  const { createPageSectionType, error: createPageSectionError } = useCreatePageSectionType()

  const { searchSites } = useSearchSites()
  const { getPages } = useGetSitePages(1000)
  const { getPageById } = useGetSitePageById()

  const { createBlockTemplate, error: createBlockTemplateError } = useCreateBlockTemplate()

  const { updatePageContent } = useUpdatePageContent()

  const [updateSectionTypes, setUpdateSectionTypes] = useState<{ [key: number]: string }>({})
  const [newSectionTypes, setNewSectionTypes] = useState<{ [key: number]: string }>({})

  const [pages, setPages] = useState<Page[]>([])
  const [site, setSite] = useState<Site>()

  const validationSchema = Yup.object().shape({
    template_group: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handlePageSelect = async (id: number, event: SelectChangeEvent<string>) => {
    // console.log(id, event.target.value)
    if (!event.target.value) {
      return
    }

    const newChecked = {
      ...updateSectionTypes,
      [id]: event.target.value,
    }

    return setUpdateSectionTypes(newChecked)
  }

  const handleTopicChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      return
    }

    formik.setFieldValue("template_group", event.target.value)
  }

  const handleNewSectionType = async (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!event.target.value) {
      return
    }

    const newChecked = {
      ...newSectionTypes,
      [id]: event.target.value,
    }

    setNewSectionTypes(newChecked)
  }

  const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>
    return (...args: Parameters<T>): void => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func(...args)
      }, delay)
    }
  }

  const importFromURL = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return

    const url = new URL(event.target.value)

    const host = url.host
    const path = url.pathname

    const sites = await searchSites(host)

    if (!sites || sites.results.length === 0) {
      toast.error("Site not found")
      return
    }
    // console.log(site.results[0].id)
    setSite(sites.results[0])
    console.log(`received site ${sites.results[0].id} for host {${host}} and path {${path}}`)

    const siteId = site?.id || sites.results[0].id
    const templateGroup = (site?.title || sites.results[0].title || host)
      .replace(/ \W+/g, "-")
      .toLowerCase()
    // console.log(`received site ${siteId} for path {${path}}`)
    const grids = await getPages(siteId, `&type=Grid&route=${encodeURIComponent(path)}`)

    setPages(grids?.results || [])

    const updateSections = grids?.results.reduce((acc: { [key: string]: string }, page: Page) => {
      acc[page.id] = ""
      return acc
    }, {})
    if (updateSections) {
      setUpdateSectionTypes(updateSections)
    }

    formik.setValues({ ...formik.values, template_group: templateGroup })
  }, 100)

  const formik = useFormik({
    initialValues: {
      template_group: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values: any) => {
      // console.log("onSubmit", values)

      const pageSectionsById = pageSectionTypes.reduce(
        (acc: { [key: string]: PageSectionType }, pageSectionType: PageSectionType) => {
          acc[pageSectionType.id.toString()] = pageSectionType
          return acc
        },
        {},
      )

      await Promise.all(
        pages.map(async (page: Page) => {
          if ((updateSectionTypes[page.id] !== "" || newSectionTypes[page.id]) && site) {
            // console.log(updateSectionTypes[page.id], newSectionTypes[page.id])
            const fullPage = await getPageById(site?.id, page.id)
            // console.log(fullPage)
            if (!fullPage || !fullPage.content) {
              toast.error("Page not found")
              return
            }

            const content = await updatePageContent(fullPage.content)

            let blockTemplate: BlockTemplate | undefined
            let pageSectionType: PageSectionType | undefined

            if (updateSectionTypes[page.id] && updateSectionTypes[page.id] !== "") {
              // console.log("sectionTypes", updateSectionTypes[page.id])
              pageSectionType = pageSectionsById[updateSectionTypes[page.id]]

              blockTemplate = await createBlockTemplate({
                name: `${pageSectionType?.name} - ${page.title} (${page.id})`,
                field_mapping: {},
                page_section_type_id: pageSectionType.id,
                priority: 350,
                template_group: values.template_group,
                content,
                page_id: page.id,
              })
              if (!blockTemplate) {
                toast.error("Failed to create block template")
                return
              }
            } else if (newSectionTypes[page.id]) {
              const pageSectionType = await createPageSectionType({
                name: `${newSectionTypes[page.id]}`,
                priority: 350,
                category: values.template_group,
                fields: {},
              })

              if (!pageSectionType) {
                toast.error("Failed to create page section type")
                return
              }

              blockTemplate = await createBlockTemplate({
                name: `${pageSectionType?.name} - ${page.title} (${page.id})`,
                field_mapping: {},
                page_section_type_id: pageSectionType.id,
                priority: 350,
                template_group: values.template_group,
                content,
                page_id: page.id,
              })
            }
          }
        }),
      )

      return handleCreatePageSectionTypeSuccess()
    },
  })

  const handleCreatePageSectionTypeSuccess: any = async () => {
    toast.success("Successfully created Block Templates")
    close()
  }

  useEffect(() => {
    getPageSectionTypes()
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={
        !!pageSectionTypeUpdate ? `Update ${pageSectionTypeUpdate.name}` : "Create Page Section"
      }
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      maxWidth="lg"
      content={
        <>
          {(error || createPageSectionError || createBlockTemplateError) && (
            <ErrorComponent error={error || createPageSectionError || createBlockTemplateError} />
          )}
          <TextField
            margin="dense"
            id="template_group"
            name="Template Group"
            label="Template Group"
            fullWidth
            variant="outlined"
            onChange={handleTopicChange}
            value={formik.values.template_group}
            error={!!formik.errors.template_group}
          />
          <TextField
            autoFocus
            margin="dense"
            id="url"
            name="URL"
            label="Import from URL"
            fullWidth
            variant="outlined"
            onChange={importFromURL}
          />
          <table>
            <tbody>
              {pages.map((page: Page) => (
                <tr key={page.id}>
                  <td>
                    <img
                      width={110}
                      height={240}
                      src={(page.thumbnails?.small && page.thumbnails?.small) ?? null}
                      alt={page.title || page.slug || undefined}
                    />
                  </td>
                  <td>
                    {page.title} <br />
                    {page.path}
                  </td>
                  <td>
                    <Select
                      name={`select-${page.id}`}
                      value={updateSectionTypes[page.id] ?? ""}
                      onChange={(x: SelectChangeEvent<string>) => handlePageSelect(page.id, x)}
                    >
                      <MenuItem value={""} />
                      {pageSectionTypes.map((pageSectionType: PageSectionType) => (
                        <MenuItem value={pageSectionType.id}>{pageSectionType.name}</MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <TextField
                      label="Name"
                      variant="outlined"
                      name={`text-${page.id}`}
                      onChange={(x) => handleNewSectionType(page.id, x)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
      buttonContent={
        <>
          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            Create Block Templates
          </Button>
        </>
      }
    />
  )
}

export default BulkAddBlockTemplate
