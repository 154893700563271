import { useCallback, useEffect, useState } from "react"
import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Site } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetSitesByIds = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const [storedSiteIds, setStoredSiteIds] = useState<number[]>([])
  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, siteIds: number[]): Promise<PagedResponse<Site>> => {
      const response = await makeRequest<PagedResponse<Site>>(
        `${SITESV4}/query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ids: siteIds,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            deleted: true,
          }),
        },
        { handleUnauthenticated },
      )
      return response
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (storedSiteIds.length) {
      performAction(storedSiteIds)
    }

    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const getSitesByIds = useCallback(
    async (siteIds: number[]) => {
      setStoredSiteIds(siteIds)

      return await performAction(siteIds)
    },
    [performAction],
  )

  return {
    getSitesByIds,
    loading,
    error,
    sites: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetSitesByIds
