import { REQUESTS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { RequestLog } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetRequests = () => {
  const currentDate = new Date()
  currentDate.setHours(currentDate.getHours() - 3)

  const {
    data,
    loading,
    error,
    performAction: getRequests,
  } = useHandleRequest(
    (
      handleUnauthenticated,
      startDate: string,
      endDate: string,
      searchText: string,
      page: number = 0,
      rowsPerPage: number = 100,
    ): Promise<PagedResponse<RequestLog>> => {
      const params = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        start_date: startDate,
        end_date: endDate,
        q: searchText,
      }
      console.log(params)
      const paramString = Object.entries(params)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&")

      return makeRequest<PagedResponse<RequestLog>>(
        `${REQUESTS}?${paramString}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return {
    loading,
    error,
    getRequests,
    requests: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
    },
  }
}

export default useGetRequests
