class Future<T> {
  promise: Promise<T>

  private _resolve?: (value: T) => void
  private _reject?: (error: Error) => void
  private _completedValue?: T
  private _completedError?: Error

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject

      // If the promise was completed before this function has a chance to run
      // Send the completed value or error directly here
      if (this._completedError) {
        reject(this._completedError)
      } else if (this._completedValue) {
        resolve(this._completedValue)
      }
    })
  }

  get completed() {
    return !!this._completedValue || !!this._completedError
  }

  completeValue(value: T) {
    if (this.completed) {
      throw new Error("Completed Future more than once")
    }

    this._completedValue = value
    this._resolve && this._resolve(value)
  }

  completeError(error: Error) {
    if (this.completed) {
      throw new Error("Completed Future more than once")
    }

    this._completedError = error
    this._reject && this._reject(error)
  }
}

export default Future
