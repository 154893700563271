import { Button } from "@mui/material"
import React, { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Template } from "../../../types"
import { LoadingButton } from "@mui/lab"

import config from "../../../config"
import useEditTemplateArchetypesDialog from "./EditTemplateArchetypesDialog"
import TemplateDialog from "./TemplateDialog"

type TemplateRowProps = {
  template: Template
  onEditPageTemplateClicked: () => void
  close: () => void
}

const TemplateRow: FunctionComponent<TemplateRowProps> = ({
  template,
  onEditPageTemplateClicked,
  close,
}) => {
  const { editTemplateArchetypesDialog, openEditTemplateArchetypesDialog } =
    useEditTemplateArchetypesDialog()

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    close()
  }

  return (
    <BasicTableRow key={template.id}>
      <BasicTableCell component="th" scope="row">
        <img
          src={template.snapshot_url || `${config.assetsBaseUrl}/${template.asset_snapshot_id}`}
          height="75"
          alt={template.display_name}
        ></img>
      </BasicTableCell>
      <BasicTableCell>{template.id}</BasicTableCell>
      <BasicTableCell>{template.display_name}</BasicTableCell>
      <BasicTableCell>{template.description}</BasicTableCell>
      <BasicTableCell>{template.tags?.sort((a, b) => (a < b ? -1 : 1)).join(", ")}</BasicTableCell>
      <BasicTableCell align="left">
        <div
          style={{ display: "flex", alignItems: "center", gap: 8, justifyContent: "flex-start" }}
        >
          <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
            Edit
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => openEditTemplateArchetypesDialog(template)}
          >
            Archetypes
          </Button>

          {open ? <TemplateDialog template={template} close={handleClose} open={open} /> : null}

          {editTemplateArchetypesDialog}

          {template.setup_flow_required ? (
            <LoadingButton variant="outlined" color="primary" onClick={onEditPageTemplateClicked}>
              Setup Flow
            </LoadingButton>
          ) : null}
        </div>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default TemplateRow
