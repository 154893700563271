import { Box, Chip } from "@mui/material"
import  { FunctionComponent } from "react"
import { Title2, Title3 } from "../../../components/shared/Typography"
import { Option, Variation } from "../../../types"

interface Props {
  variations: Variation[] | undefined
}

const SiteProductVariations: FunctionComponent<Props> = ({ variations }) => {
  if (!variations) {
    return null
  }

  return (
    <Box
      sx={{
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Title2
        sx={{
          marginBottom: "8px",
        }}
      >
        Variations
      </Title2>
      {variations.map((variation: Variation) => (
        <>
          <Title3>{variation.name}</Title3>
          {variation?.options?.map((option: Option) => (
            <>
              <Chip
                key={option.id}
                sx={{
                  margin: (theme) => theme.spacing(0.5),
                  borderRadius: "8px",
                }}
                label={option.name}
                color={"primary"}
              />
            </>
          ))}
        </>
      ))}
    </Box>
  )
}

export default SiteProductVariations
