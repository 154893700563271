import { useCallback, useEffect, useState } from "react"
import { SITESV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Subscription } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetSiteSubscriptions = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const [storedSiteId, setStoredSiteId] = useState<number>()

  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, siteId: number): Promise<PagedResponse<Subscription>> => {
      const response = await makeRequest<PagedResponse<Subscription>>(
        `${SITESV3}/${siteId}/subscriptions?limit=${rowsPerPage}&offset=${page * rowsPerPage}`,
        {},
        { handleUnauthenticated },
      )
      return response
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (storedSiteId) {
      performAction(storedSiteId)
    }

    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const getSiteSubscriptions = useCallback(
    async (siteId: number) => {
      setStoredSiteId(siteId)
      return await performAction(siteId)
    },
    [performAction],
  )

  return {
    getSiteSubscriptions,
    loading,
    error,
    subscriptions: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetSiteSubscriptions
