import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { ViewerSubscription } from "../../../types"
import SubscriberRow from "./SubscriberRow"

interface SubscribersTableProps {
  subscribers: ViewerSubscription[]
  unsubscribeViewer: any
  canUnsubscribe: boolean
  paginationData: PaginationData
}

const SubscribersTable: FunctionComponent<SubscribersTableProps> = ({
  subscribers,
  unsubscribeViewer,
  canUnsubscribe,
  paginationData,
}) => {
  const columns: string[] = canUnsubscribe
    ? ["ID", "Email", "Action", "Created"]
    : ["ID", "Email", "Created"]

  const getRows = useCallback(() => {
    return subscribers.map((subscriber: ViewerSubscription, index: number) => (
      <SubscriberRow
        key={index}
        subscriber={subscriber}
        unsubscribeViewer={unsubscribeViewer}
        canUnsubscribe={canUnsubscribe}
      />
    ))
  }, [subscribers, unsubscribeViewer, canUnsubscribe])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default SubscribersTable
