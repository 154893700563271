// DO NOT EDIT THIS FILE DIRECTLY
// See `specs/message-queue/README.md` for common issues / usage

const topics = [ 
    "v1-apple-subscription-updated", 
    "v1-replicate-alt-text-complete", 
    "v1-assets-asset-update_metadata", 
    "v1-assets-video-update_metadata", 
    "v1-aws-s3-videos-uploaded", 
    "v1-aws-s3-assets-uploaded", 
    "v1-aws-s3-assets-deleted", 
    "v1-assets-asset-deleted", 
    "v1-assets-s3-asset-deleted", 
    "v1-assets-s3-asset-upload-complete", 
    "v1-assets-asset-upload-complete", 
    "v1-assets-download-created", 
    "v1-assets-download-files-updated", 
    "v1-assets-download-updated", 
    "v1-assets-download-version-deleted", 
    "v1-assets-s3-file-upload-complete", 
    "v1-assets-file-deleted", 
    "v1-assets-file-ready", 
    "v1-assets-s3-file-deleted", 
    "v1-assets-s3-video-upload-complete", 
    "v1-assets-video-deleted", 
    "v1-assets-video-ready", 
    "v1-assets-s3-video-deleted", 
    "v1-aws-assets-asset_migration-start", 
    "v1-assets-asset_migration-start", 
    "v1-assets-asset_migration-migrate", 
    "v1-assets-snapshot-created", 
    "v1-mux-event-received", 
    "v1-domain-resource-action", 
    "v1-billing-attribution-created", 
    "v1-billing-coupon-created", 
    "v1-billing-coupon-updated", 
    "v1-billing-dispute-created", 
    "v1-billing-plan-created", 
    "v1-billing-plan-updated", 
    "v1-commerce-dispute-created", 
    "v1-commerce-dispute-updated", 
    "v1-commerce-dispute-charged", 
    "v1-commerce-dispute-refunded", 
    "v1-commerce-dispute-closed", 
    "v1-commerce-order-created", 
    "v1-commerce-shipment-created", 
    "v1-commerce-shipment_status-updated", 
    "v1-commerce-merchant-created", 
    "v1-commerce-merchant-urls-update", 
    "v1-commerce-merchant-payout_schedule-update", 
    "v1-commerce-transaction-succeeded", 
    "v1-commerce-transaction-failed", 
    "v1-commerce-merchant-first_transaction-created", 
    "v1-commerce-merchant-product-created", 
    "v1-commerce-merchant-order_review_threshold-exceeded", 
    "v1-paypal-payment-received", 
    "v1-notifications-customer_receipt-send", 
    "v1-notifications-downloads_receipt-send", 
    "v1-notifications-downloads_receipt-sent", 
    "v1-notifications-merchant_receipt-send", 
    "v1-notifications-notification-created", 
    "v1-notifications-notification-pushed", 
    "v1-notifications-order-slack_event-send", 
    "v1-slack-event-received", 
    "v1-stripe-event-received", 
    "v1-ship_engine-event-received", 
    "v1-data-task-ready", 
    "v1-data-site_views-updated", 
    "v1-sites-domain-created", 
    "v1-sites-domain-invalidated", 
    "v1-sites-domain-registered", 
    "v1-sites-domain_external_configuration-invalidated", 
    "v1-sites-domain-disable", 
    "v1-sites-domain-disabled", 
    "v1-sites-domain-enabled", 
    "v1-sites-domain-setup", 
    "v1-sites-domain-linked", 
    "v1-sites-domain-unlinked", 
    "v1-sites-site_domains-invalidated", 
    "v1-sites-domain-setup_failed", 
    "v1-sites-domain-register_failed", 
    "v1-sites-dns-record-creation_failed", 
    "v1-sites-domain-restore-completed", 
    "v1-sites-domain-restore-cancelled", 
    "v1-sites-domain-restore-started", 
    "v1-domain-transfer_in-completed", 
    "v1-sites-domain-transfer_in-cancelled", 
    "v1-sites-domain-transfer_in-started", 
    "v1-sites-subdomain-created", 
    "v1-sites-subdomain-deleted", 
    "v1-sites-subdomain-linked", 
    "v1-sites-subdomain-unlinked", 
    "v1-sites-email_forward-created", 
    "v1-sites-email_forward-setup", 
    "v1-sites-email_forward-deleted", 
    "v1-sites-email_forward-setup_failed", 
    "v1-sites-subscription-created", 
    "v1-sites-subscription-updated", 
    "v1-sites-subscription-invalidated", 
    "v1-sites-site-published", 
    "v1-sites-gus_page-published", 
    "v1-sites-gus_feedback-submitted", 
    "v1-sites-subscription-trial_converted", 
    "v1-sites-subscription-billed", 
    "v1-sites-subscription-expired", 
    "v1-sites-subscription-canceled", 
    "v1-sites-subscription-trial_started", 
    "v1-sites-subscription-interactive_renewal", 
    "v1-sites-subscription-plan_changed", 
    "v1-sites-subscription-renewal_status_changed", 
    "v1-sites-subscription_domain-created", 
    "v1-sites-subscription_domain-deleted", 
    "v1-sites-site-created", 
    "v1-sites-site-deleted", 
    "v1-sites-site-updated", 
    "v1-sites-sitemap-updated", 
    "v1-sites-theme-updated", 
    "v1-sites-page-snapshot_invalidated", 
    "v1-sites-site_urls-invalidated", 
    "v1-sites-site_urls-updated", 
    "v1-sites-site_entitlements-invalidated", 
    "v1-sites-site_entitlements-updated", 
    "v1-sites-site-unarchived", 
    "v1-sites-job-ready", 
    "v1-sites-ai-conversation-updated", 
    "v1-sites-pages-save_text_content", 
    "v1-sites-site-generate_site_info", 
    "v1-users-user-deleted", 
    "v1-users-user-merged", 
    "v1-users-user-first_login-created", 
    "v1-users-user-shadow_ban-created", 
    "v1-users-user-shadow_ban-deleted", 
    "v1-notifications-push-token-created", 
    "v1-client_events-created", 
    "v1-sites-viewer_subscription-created", 
    "v1-sites-viewer_subscription_update-created", 
    "v1-sites-viewer_subscription_custom_update-created", 
    "v1-sites-viewer_subscription_update-send", 
    "v1-sites-viewer_subscription_metrics-generate", 
    "v1-dnsimple-event-received", 
    "v1-zendesk-event-received", 
    "v1-braze-event-received", 
    "v1-paypal-event-received", 
    "v1-appstore-event-created", 
    "v1-yeet-no_context-quote-created", 
    "v1-yeet-no_context-asset-created", 
    "v1-yeet-no_context-image-created", 
    "v1-yeet-no_context-image-completed", 
    "v1-aws-yeet-image_yeeter-stop", 
    "v1-yeet-image_yeeter-stop", 
] 

export default topics
