import { TAGSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Tag, PostTag } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateTag = () => {
  const { error, loading, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      tagId: number,
      tag: PostTag,
      onSuccess?: () => any,
    ): Promise<Tag> => {
      const response = await makeRequest<Tag>(
        `${TAGSV4}/${tagId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...tag }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateTag: performAction, loading, error }
}

export default useUpdateTag
