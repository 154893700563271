import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material"
import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { CreateDNSRecordRequest, DNSimpleZone, DomainTypeEnum, PutDomainStatus } from "../../types"
import isEmpty from "../../utils/isEmpty"
import AddVercelDialog from "./components/AddVercelDialog"
import ConfirmDNSDeletionDialog from "./components/ConfirmDNSDeletionDialog"
import CreateDNSRecordDialog from "./components/CreateDNSRecordDialog"
import RemoveVercelDialog from "./components/RemoveVercelDialog"
import DeleteMailgunDomainDialog from "./components/DeleteMailgunDomainDialog"
import DNSimpleOverview from "./components/DNSimpleOverview"
import DNSimpleZonesTable from "./components/DNSimpleZonesTable"
import { AddDNSRecordButton, ReassignDomainButton } from "./components/DomainLayoutShared"
import DomainOverview from "./components/DomainOverview"
import EditDomainStatusDialog from "./components/EditDomainStatusDialog"
import ReassignDomainDialog, { DomainReassignBasisEnum } from "./components/ReassignDomainDialog"
import TransferOutDomainDialog from "./components/TransferOutDomainDialog"
import UnlinkDomainConfirmationDialog from "./components/UnlinkDomainConfirmationDialog"
import useAddVercel from "./hooks/useAddVercel"
import useCreateDNSRecord from "./hooks/useCreateDNSRecord"
import useDeleteVercel from "./hooks/useDeleteVercel"
import useDeleteDNSRecord from "./hooks/useDeleteDNSRecord"
import useDeleteMailgunDomain from "./hooks/useDeleteMailgunDomain"
import useEditDomainStatus from "./hooks/useEditDomainStatus"
import useGetDomainDiagnostics from "./hooks/useGetDomainDiagnostics"
import useReassignDomain from "./hooks/useReassignDomain"
import ValidateDomainDialog from "./components/ValidateDomainDialog"
import useGetDomainNotes from "./hooks/useGetDomainNotes"
import NotesTable from "../Users/components/NotesTable"
import { Title3 } from "../../components/shared/Typography"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import CreateNoteDialog from "../Users/components/CreateNoteDialog"
import VercelOverview from "./components/VercelOverview"
import useVerifyVercel from "./hooks/useVerifyVercel"
import useGetDomainCerts from "./hooks/useGetDomainCerts"

interface Params {
  domainId?: string | number
}

const DomainDetailsScreen: FunctionComponent = () => {
  const { domainId }: Params = useParams()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [createNoteOpen, setCreateNoteOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Domain Reassignment
  const {
    isOn: ReassignDialogIsOpen,
    turnOn: openReassignDialog,
    turnOff: closeReassignDialog,
  } = useToggle()

  const {
    getDomainDiagnostics,
    loading: loadingDiagnostic,
    error: diagnosticError,
    domainDiagnostic,
  } = useGetDomainDiagnostics()

  const {
    reassignDomain,
    error: errorReassigningDomain,
    loading: loadingReassignment,
  } = useReassignDomain()

  const onReassignSuccess = useCallback(async () => {
    if (domainId) {
      await getDomainDiagnostics(domainId)
    }
    closeReassignDialog()
    toast.success("Successfully reassigned domain")
  }, [domainId, closeReassignDialog, getDomainDiagnostics])

  const handleReassignDomain = useCallback(
    async (domainId: number, userId: number, siteId: number) => {
      await reassignDomain(domainId, userId, siteId, onReassignSuccess)
    },
    [reassignDomain, onReassignSuccess],
  )

  // Vercel
  const {
    isOn: VercelDialogIsOpen,
    turnOn: openVercelDialog,
    turnOff: closeVercelDialog,
  } = useToggle()
  const { addVercel, loading: addingVercel, error: vercelError } = useAddVercel()
  const handleAddVercel = useCallback(async () => {
    const onSuccess = async () => {
      if (domainId) {
        await getDomainDiagnostics(domainId)
      }
      closeVercelDialog()
      toast.success("Successfully added to Vercel")
    }
    await addVercel(Number(domainId), onSuccess)
  }, [domainId, closeVercelDialog, getDomainDiagnostics, addVercel])

  const {
    isOn: RemoveVercelDialogIsOpen,
    turnOn: openRemoveVercelDialog,
    turnOff: closeRemoveVercelDialog,
  } = useToggle()
  const { deleteVercel, loading: deletingVercel, error: deleteVercelError } = useDeleteVercel()
  const handleDeleteVercel = useCallback(async () => {
    const onSuccess = async () => {
      if (domainId) {
        await getDomainDiagnostics(domainId)
      }
      closeRemoveVercelDialog()
      toast.success("Successfully removed from Vercel")
    }
    await deleteVercel(Number(domainId), onSuccess)
  }, [domainId, closeRemoveVercelDialog, getDomainDiagnostics, deleteVercel])

  const { verifyVercel, error: vercelVerifyError, loading: vercelVerifyLoading } = useVerifyVercel()
  const verifyVercelSuccess = useCallback(async () => {
    if (domainId) {
      await getDomainDiagnostics(domainId)
    }
    toast.success("Successfully requested DNS record verification in Vercel")
  }, [domainId, getDomainDiagnostics])

  const handleVerifyVercel = useCallback(async () => {
    await verifyVercel(Number(domainId), verifyVercelSuccess)
  }, [verifyVercel, verifyVercelSuccess])

  // DNS Records
  const {
    isOn: CreateDNSRecordDialogIsOpen,
    turnOn: openCreateDNSRecordDialog,
    turnOff: closeCreateDNSRecordDialog,
  } = useToggle()

  const {
    deleteDNSRecord,
    error: deleteRecordError,
    loading: deleteRecordLoading,
  } = useDeleteDNSRecord()

  const [dnsRecord, setDNSRecord] = useState<DNSimpleZone>()

  const handleCloseDeleteDNSRecordDialog = useCallback(() => {
    setDNSRecord(undefined)
  }, [setDNSRecord])

  const handleDeleteDNSRecord = useCallback(async () => {
    const onSuccess = async () => {
      handleCloseDeleteDNSRecordDialog()
      await getDomainDiagnostics(Number(domainId))
      toast.success("Successfully deleted DNS record")
    }
    if (dnsRecord) {
      await deleteDNSRecord(Number(domainId), Number(dnsRecord), onSuccess)
    }
  }, [dnsRecord, handleCloseDeleteDNSRecordDialog, getDomainDiagnostics, domainId, deleteDNSRecord])

  const { createRecord, loading: createDNSLoading, error: createDNSError } = useCreateDNSRecord()

  const handleCreateDNSRecord = useCallback(
    async (record: CreateDNSRecordRequest, callback: () => any) => {
      const onSuccess = async () => {
        await getDomainDiagnostics(Number(domainId))
        callback()
        toast.success("Successfully created DNS record")
      }
      await createRecord(Number(domainId), record, onSuccess)
    },
    [createRecord, domainId, getDomainDiagnostics],
  )

  // Mailgun
  const {
    isOn: RemoveMailgunIsOpen,
    turnOn: openRemoveMailgun,
    turnOff: closeRemoveMailgun,
  } = useToggle()
  const {
    deleteMailgunDomain,
    loading: deletingMailgunDomain,
    error: deleteMailgunDomainError,
  } = useDeleteMailgunDomain()
  const handleDeleteMailgun = useCallback(async () => {
    const onSuccess = async () => {
      if (domainId) {
        await getDomainDiagnostics(domainId)
      }
      closeRemoveMailgun()
      toast.success("Successfully deleted Mailgun from domain")
    }
    await deleteMailgunDomain(Number(domainId), onSuccess)
  }, [domainId, closeRemoveMailgun, getDomainDiagnostics, deleteMailgunDomain])

  // Notes
  const { getDomainNotes, paginationData, notes } = useGetDomainNotes()

  const handleCreateNoteClose = async () => {
    setCreateNoteOpen(false)
    await getDomainNotes(Number(domainId))
  }

  // Transfer Out Domain
  const {
    isOn: TransferOutDomainDialogIsOpen,
    turnOn: openTransferOutDomainDialog,
    turnOff: closeTransferOutDomainDialog,
  } = useToggle()

  // Unlink Domain
  const {
    isOn: unlinkConfirmationIsOpen,
    turnOn: openUnlinkConfirmation,
    turnOff: closeUnlinkConfirmation,
  } = useToggle()

  // Domain Status
  const {
    isOn: editDomainStatusDialogIsOpen,
    turnOn: openEditDomainStatusDialog,
    turnOff: closeEditDomainStatusDialog,
  } = useToggle()
  const {
    editStatus,
    loading: editingDomainStatus,
    error: editingDomainStatusError,
  } = useEditDomainStatus()

  // Domain SSL Certs
  const {
    getDomainCerts,
    domainCerts,
    error: errorDomainCerts,
    loading: loadingDomainCerts,
  } = useGetDomainCerts()

  const handleEditDomainStatus = useCallback(
    async (domainId: number, body: PutDomainStatus, callback: () => any) => {
      const onSuccess = async () => {
        await getDomainDiagnostics(Number(domainId))
        handleClose()
        callback()
        toast.success("Successfully refreshed domain")
      }
      await editStatus(Number(domainId), body, onSuccess)
    },
    [editStatus, getDomainDiagnostics],
  )

  // General
  const { isOn: validateIsOpen, turnOn: openValidate, turnOff: closeValidate } = useToggle()

  useEffect(() => {
    if (domainId) {
      getDomainDiagnostics(domainId)
      getDomainNotes(Number(domainId))
      getDomainCerts(Number(domainId))
    }
    //eslint-disable-next-line
  }, [])

  const hasDNSimpleData = () => {
    if (!domainDiagnostic?.dnsimple) {
      return false
    }
    if (isEmpty(domainDiagnostic.dnsimple)) {
      return false
    }
    const { zones, ...rest } = domainDiagnostic.dnsimple
    if (isEmpty(rest)) {
      return false
    } else {
      return true
    }
  }

  if (!domainId) {
    return null
  }

  const hasDiagnostic = !!domainDiagnostic && !isEmpty(domainDiagnostic) && !loadingDiagnostic
  const showOverview =
    hasDiagnostic && domainDiagnostic?.domain && !isEmpty(domainDiagnostic.domain)
  const showDNSimpleOverview = hasDiagnostic && hasDNSimpleData()
  const showDNSimpleAddRecordButton = showDNSimpleOverview
  const showTransferOutDomainButton = showDNSimpleOverview && !domainDiagnostic.domain.external
  const showDNSimpleZonesTable = showDNSimpleOverview && domainDiagnostic?.dnsimple?.zones?.length
  const showUnlinkDomainButton = hasDiagnostic && domainDiagnostic?.domain.site_id

  return (
    <ScreenContainer title="Domain">
      <br />
      {(loadingDiagnostic || vercelVerifyLoading || loadingDomainCerts) && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {diagnosticError && <ErrorComponent error={diagnosticError} />}
      {vercelVerifyError && <ErrorComponent error={vercelVerifyError} />}
      {errorDomainCerts && <ErrorComponent error={errorDomainCerts} />}
      <ReassignDomainButton
        sx={{ width: "300px" }}
        variant="outlined"
        color="primary"
        onClick={openReassignDialog}
      >
        Reassign Domain
      </ReassignDomainButton>
      <br />
      <Button
        id="basic-button"
        sx={{ width: "300px" }}
        variant="outlined"
        color="primary"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Domain Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={openVercelDialog}>Add to Vercel</MenuItem>
        <MenuItem onClick={openRemoveVercelDialog}>Remove from Vercel</MenuItem>
        <MenuItem onClick={handleVerifyVercel}>Force Vercel DNS Verification</MenuItem>
        {hasDiagnostic && !domainDiagnostic?.domain.external && (
          <MenuItem onClick={openRemoveMailgun}>Remove From Mailgun</MenuItem>
        )}
        <MenuItem onClick={openEditDomainStatusDialog}>Edit/Refresh Domain</MenuItem>
        <MenuItem onClick={openValidate}>"Magic Fix" Domain ✨</MenuItem>
        {showTransferOutDomainButton && (
          <MenuItem onClick={openTransferOutDomainDialog}>Transfer Out Domain</MenuItem>
        )}
        {showUnlinkDomainButton && (
          <MenuItem onClick={openUnlinkConfirmation}>Unlink Domain</MenuItem>
        )}
      </Menu>
      <Box
        my="15px"
        sx={{
          my: "15px",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        display="flex"
      >
        {showOverview && (
          <DomainOverview
            domain={domainDiagnostic?.domain}
            refreshData={() => getDomainDiagnostics(domainId)}
          />
        )}
        {showDNSimpleOverview && (
          <DNSimpleOverview
            data={domainDiagnostic?.dnsimple}
            domain={domainDiagnostic.domain}
            refreshDomain={() => getDomainDiagnostics(domainId)}
          />
        )}
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "500px" }}
        >
          <VercelOverview data={domainDiagnostic?.vercel.root} certData={domainCerts || []} />
        </Box>
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "500px" }}
        >
          <VercelOverview data={domainDiagnostic?.vercel.www} certData={domainCerts || []} />
        </Box>
        <Box>
          <Title3>
            Domain Notes{" "}
            <IconButton onClick={() => setCreateNoteOpen(true)} sx={{ padding: 0, margin: 0 }}>
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Title3>
          <NotesTable
            notes={notes}
            paginationData={paginationData}
            refreshData={() => getDomainDiagnostics(domainId)}
          />
        </Box>
      </Box>
      {showDNSimpleAddRecordButton && (
        <AddDNSRecordButton
          sx={{ width: "300px" }}
          variant="outlined"
          color="primary"
          onClick={openCreateDNSRecordDialog}
        >
          Create DNS Record
        </AddDNSRecordButton>
      )}
      <br />
      {showDNSimpleZonesTable && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "500px" }}
        >
          <DNSimpleZonesTable
            zones={domainDiagnostic?.dnsimple?.zones || []}
            deleteZone={setDNSRecord}
          />
        </Box>
      )}
      {domainDiagnostic && (
        <ReassignDomainDialog
          title={`Reassign Domain - ${domainDiagnostic.domain.id}`}
          domainId={domainDiagnostic.domain.id}
          userId={domainDiagnostic?.domain?.user_id}
          close={closeReassignDialog}
          isOpen={ReassignDialogIsOpen}
          handleReassign={handleReassignDomain}
          hasError={!!errorReassigningDomain}
          domainType={DomainTypeEnum.Domain}
          loading={loadingReassignment}
          basis={DomainReassignBasisEnum.Domain}
        />
      )}
      {domainDiagnostic && (
        <CreateDNSRecordDialog
          close={closeCreateDNSRecordDialog}
          isOpen={CreateDNSRecordDialogIsOpen}
          handleCreate={handleCreateDNSRecord}
          hasError={!!createDNSError}
          loading={createDNSLoading}
        />
      )}
      {domainDiagnostic && (
        <TransferOutDomainDialog
          domainDiagnostics={domainDiagnostic}
          close={closeTransferOutDomainDialog}
          isOpen={TransferOutDomainDialogIsOpen}
        />
      )}
      {domainDiagnostic && (
        <UnlinkDomainConfirmationDialog
          domain={domainDiagnostic.domain}
          close={closeUnlinkConfirmation}
          isOpen={unlinkConfirmationIsOpen}
        />
      )}
      <AddVercelDialog
        domainID={domainId}
        isOpen={VercelDialogIsOpen}
        handleConfirmation={handleAddVercel}
        hasError={!!vercelError}
        loading={addingVercel}
        close={closeVercelDialog}
      />
      {!!dnsRecord && (
        <ConfirmDNSDeletionDialog
          record={dnsRecord}
          isOpen={!!dnsRecord}
          handleConfirmation={handleDeleteDNSRecord}
          hasError={!!deleteRecordError}
          loading={deleteRecordLoading}
          close={handleCloseDeleteDNSRecordDialog}
        />
      )}
      <RemoveVercelDialog
        domainID={domainId}
        isOpen={RemoveVercelDialogIsOpen}
        handleConfirmation={handleDeleteVercel}
        hasError={!!deleteVercelError}
        loading={deletingVercel}
        close={closeRemoveVercelDialog}
      />
      <DeleteMailgunDomainDialog
        domainID={domainId}
        isOpen={RemoveMailgunIsOpen}
        handleConfirmation={handleDeleteMailgun}
        hasError={!!deleteMailgunDomainError}
        loading={deletingMailgunDomain}
        close={closeRemoveMailgun}
      />
      {domainDiagnostic?.domain && (
        <EditDomainStatusDialog
          isOpen={!!editDomainStatusDialogIsOpen}
          domain={domainDiagnostic?.domain!}
          close={closeEditDomainStatusDialog}
          handleCreate={handleEditDomainStatus}
          hasError={!!editingDomainStatusError}
          errorMessage={!!editingDomainStatusError && editingDomainStatusError.message}
          loading={!!editingDomainStatus}
        />
      )}
      {domainDiagnostic && (
        <ValidateDomainDialog
          domainDiagnostics={domainDiagnostic}
          isOpen={validateIsOpen}
          close={closeValidate}
        />
      )}
      {createNoteOpen && domainId ? (
        <CreateNoteDialog
          isOpen={createNoteOpen}
          close={handleCreateNoteClose}
          type="domain"
          typeId={Number(domainId)}
        />
      ) : null}
    </ScreenContainer>
  )
}

export default DomainDetailsScreen
