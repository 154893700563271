export default function Commit({ commit }: { commit: string }) {
  if (!commit) {
    return <code>N/A</code>
  }

  return (
    <a href={`https://github.com/onuniverse/universe-platform/commit/${commit}`}>
      <code>{commit.slice(0, 8)}</code>
    </a>
  )
}
