import enforceError from "./enforce-error"

const makeRequest = async <T extends unknown>(
  input: RequestInfo,
  init?: RequestInit,
  options?: UniverseRequestOptions,
): Promise<T> => {
  const controller = new AbortController()
  const timeout = options?.timeout || 60000
  const timeoutId = setTimeout(() => controller.abort(), timeout)

  init = {
    ...init,
    signal: controller.signal,
  }

  let response
  try {
    response = await fetch(input, init)
    clearTimeout(timeoutId)

    // Attempt to handle 401s gracefully if a handleUnauthenticated handler is present
    if (response.status === 401 && options?.handleUnauthenticated) {
      try {
        await options.handleUnauthenticated()
      } catch (secondError) {
        // If unable to reauthenticate, throw the Unauthenticated error
        throw RequestError.forStatus(401, response)
      }

      // Create new options without a handleUnauthenticated handler for follow up requests
      // to prevent infinite loops and then retry the request
      const followUpRequestOptions = { timeout }

      return makeRequest(input, init, followUpRequestOptions)
    }

    if (!response.ok) {
      const responseMessage = await response.text()
      try {
        const parsedJSON = JSON.parse(responseMessage)
        const errorMessage = parsedJSON.message
        throw RequestError.forStatus(response.status, response, errorMessage)
      } catch (err) {
        throw RequestError.forStatus(response.status, response, responseMessage)
      }
    }

    const textResponse = await response.text()

    let parsedResponse
    try {
      parsedResponse = JSON.parse(textResponse)
    } catch (jsonError) {
      // If parsing as JSON fails, treat it as plain text
      parsedResponse = textResponse
    }

    return parsedResponse || {}
  } catch (err) {
    const error = enforceError(err)
    if (error.message === "Fetch is aborted") {
      throw RequestError.forStatus(408)
    } else {
      throw error
    }
  }
}

export default makeRequest

export type UniverseRequestOptions = {
  timeout?: number
  handleUnauthenticated?: () => Promise<void>
}

export type PagedResponse<T> = {
  results: T[]
  metadata: {
    count: number
    total: number
    limit: number
    offset: number
  }
}

export class RequestError extends Error {
  status: number
  message: string

  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }

  static forStatus(status: number, response?: Response, errorMessage?: string) {
    const message = ((httpStatus: number, response?: Response) => {
      const requestId = response?.headers.get("universe-request-id")
      switch (httpStatus) {
        case 400:
          return `${errorMessage ? errorMessage : "bad request"} (${requestId})`
        case 401:
          return `${errorMessage ? errorMessage : "unauthorized"} (${requestId})`
        case 403:
          return `${errorMessage ? errorMessage : "forbidden"} (${requestId})`
        case 404:
          return `${errorMessage ? errorMessage : "not found"} ${requestId}`
        case 408:
          return `${errorMessage ? errorMessage : "request timed out"}`
        case 409:
          return `${errorMessage ? errorMessage : "conflict"} (${requestId})`
        case 500:
          return `${errorMessage ? errorMessage : "something went wrong"} (${requestId})`
        default:
          return `${errorMessage ? errorMessage : "something went wrong"} (${requestId})`
      }
    })(status, response)

    return new RequestError(status, message)
  }
}
