import useHandleRequest from "../../../hooks/useHandleRequest"
import { D2 } from "../../../constants/urlDefs"
import makeRequest from "../../../utils/make-request"
import type { DiagramTypeT } from "../types"

const useDiagram = (type: DiagramTypeT, services: string[]) => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated): Promise<string> => {
      let path = `/diagram?type=${type}`
      if (services.length > 0) {
        path += `&services=${services.join(",")}`
      }
      return makeRequest<string>(D2 + path, {}, { handleUnauthenticated })
    },
  )

  return {
    loading,
    error,
    performAction,
    data: data || "",
  }
}

export default useDiagram
