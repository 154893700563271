import { PAGESECTIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageSectionType, UpdatePageSectionType } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdatePageSectionType = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      pageSectionTypeId: number,
      pageSectionType: UpdatePageSectionType,
      onSuccess?: () => any,
    ): Promise<PageSectionType> => {
      const snippet = await makeRequest<PageSectionType>(
        `${PAGESECTIONS}/${pageSectionTypeId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...pageSectionType }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return snippet
    },
  )

  return { updatePageSectionType: performAction, loading, error }
}

export default useUpdatePageSectionType
