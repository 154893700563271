import { useEffect, useState } from "react"
import { PAGETYPESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageType } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetPageTypes = (defaultRowsPerPage = 20) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getPageTypes,
  } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<PageType>> => {
    let queryString = `${PAGETYPESV5}?limit=${encodeURIComponent(
      rowsPerPage,
    )}&offset=${encodeURIComponent(page * rowsPerPage)}`
    return makeRequest<PagedResponse<PageType>>(queryString, {}, { handleUnauthenticated })
  })

  const handleChangePage = async (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = async (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getPageTypes()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    getPageTypes,
    loading,
    error,
    pageTypes: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetPageTypes
