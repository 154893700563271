import { Box, ImageList, ImageListItem, ImageListItemBar, TablePagination } from "@mui/material"
import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title3 } from "../../components/shared/Typography"
import SitePageGroupToSubGridLink from "./components/SitePageGroupToSubGridLink"
import SitePageOverview from "./components/SitePageOverview"
import useGetSitePageById from "./hooks/useGetSitePageById"
import useGetSitePages from "./hooks/useGetSitePages"

const SiteGroupDetailsScreen: FunctionComponent = () => {
  const { siteId, groupId } = useParams<{
    siteId: string
    groupId: string
  }>()
  const { loading, error, getPages, pages, paginationData } = useGetSitePages()
  const { loading: pageLoading, error: pageError, getPageById, page } = useGetSitePageById()

  useEffect(() => {
    getPages(Number(siteId), `&parent_id=${groupId}`)
    getPageById(Number(siteId), Number(groupId))
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title={page?.title ? page?.title : `${page?.type} ${page?.id}`}>
      {loading ||
        (pageLoading && (
          <Box
            display="flex"
            sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          >
            <LoadingSpinner />
          </Box>
        ))}
      {error && <ErrorComponent error={error} />}
      {pageError && <ErrorComponent error={pageError} />}
      <SitePageOverview
        page={page}
        handleGetPage={() => getPageById(Number(siteId), Number(groupId))}
      />
      <Title3>Subpages</Title3>
      <TablePagination
        component="div"
        count={paginationData.count}
        page={paginationData.page}
        onPageChange={paginationData.onPageChange}
        rowsPerPage={paginationData.rowsPerPage}
        onRowsPerPageChange={paginationData.onRowsPerPageChange}
      />
      <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
        <ImageList rowHeight={240} sx={{ width: "100%", height: "100%" }} cols={6}>
          {pages.map((page) => (
            <ImageListItem key={page.id}>
              <SitePageGroupToSubGridLink
                siteId={page.site_id}
                groupId={page.parent_id}
                pageId={page.id}
              >
                <img
                  height={240}
                  src={(page.thumbnails?.small && page.thumbnails?.small) ?? null}
                  alt={page.title || page.slug || undefined}
                />
              </SitePageGroupToSubGridLink>
              <ImageListItemBar title={page.title} subtitle={page.path} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </ScreenContainer>
  )
}

export default SiteGroupDetailsScreen
