import { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { RequestLog } from "../../../types"
import { Buffer } from "buffer"
import { Button } from "@mui/material"
import SitePageContentDialog from "../../Sites/components/SitePageContentDialog"
import useToggle from "../../../hooks/useToggle"
import { Link } from "react-router-dom"

const newRelicURL = (request: RequestLog) => {
  if (!request.request_id) {
    return "#"
  }
  const panel = { isEntitled: true, query: `request_id:${request.request_id}`, eventTypes: ["Log"] }

  let timeRange = ""
  const dt = request.ts ?? request.received_at
  if (dt) {
    const startDate = new Date(dt)
    // Can't figure out how to get NR to do UTC
    startDate.setHours(startDate.getHours() - 12)

    const endDate = new Date(dt)
    timeRange = `platform[timeRange][begin_time]=${startDate.valueOf()}&&platform[timeRange][end_time]=${endDate.valueOf()}`
  }

  return `https://one.newrelic.com/launcher/logger.log-launcher?platform[accountId]=3341604&${timeRange}&launcher=${Buffer.from(
    JSON.stringify(panel),
  ).toString("base64")}`
}

const endDate = (startDate?: string | null, minutes = 10) => {
  if (!startDate) return ""

  const dt = new Date(startDate)
  dt.setMinutes(dt.getMinutes() + minutes)
  return dt.toISOString()
}

type RequestRowProps = {
  request: RequestLog
}

const RequestRow: FunctionComponent<RequestRowProps> = ({ request }) => {
  const { isOn: contentIsOpen, turnOn: openContent, turnOff: closeContent } = useToggle()

  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        <a href={newRelicURL(request)}>{request.ts}</a>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Link
          to={`/requests?request_id=${request.request_id}&start=${request.ts}&end=${endDate(
            request.ts,
          )}&&service=${request.service}`}
        >
          {request.request_id}
        </Link>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {request.level}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {request.service}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row" style={{}}>
        <div
          style={{ overflow: "auto", whiteSpace: "pre-wrap", maxHeight: "12em", maxWidth: "40em" }}
        >
          {request.line}
          <br />
          <pre>{request.stack}</pre>
        </div>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {request.site_id ? (
          <Link to={`/sites/${request.site_id}`}>{request.site_id}</Link>
        ) : (
          request.site_id
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {request.auth}
      </BasicTableCell>
      <BasicTableCell>
        <Button onClick={openContent}>Details</Button>
      </BasicTableCell>
      <SitePageContentDialog
        content={{
          ...JSON.parse(request.message ?? "{}"),
          kubernetes: request.kubernetes ? JSON.parse(request.kubernetes) : {},
        }}
        isOpen={contentIsOpen}
        close={closeContent}
        title={`Request ${request.request_id}`}
      />
    </BasicTableRow>
  )
}

export default RequestRow
