import { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Note } from "../../../types"
import ReactMarkdown from "react-markdown"
import { Button } from "@mui/material"
import DeleteNoteConfirmationDialog from "./DeleteNoteConfirmationDialog"

type Props = {
  note: Note
  close: () => any
}

const NoteRow: FunctionComponent<Props> = ({ note, close }) => {
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)

  const handleDeleteOpen = () => {
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    close()
  }

  return (
    <BasicTableRow key={note.id}>
      <BasicTableCell component="th" scope="row">
        <ReactMarkdown children={note.note} linkTarget={"_blank"} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {note.employee_name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(note.created_at)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button onClick={handleDeleteOpen} variant="outlined" color="error">
          Delete
        </Button>
      </BasicTableCell>
      <DeleteNoteConfirmationDialog note={note} isOpen={deleteOpen} close={handleDeleteClose} />
    </BasicTableRow>
  )
}

export default NoteRow
