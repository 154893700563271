import { FunctionComponent } from "react"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { Entitlement } from "../../../types"
import useCreateEntitlement from "../hooks/useCreateEntitlement"
import useUpdateEntitlement from "../hooks/useUpdateEntitlement"
import useDeleteEntitlement from "../hooks/useDeleteEntitlement"

interface Props {
  isOpen: boolean
  close: () => void
  editEntitlement?: Entitlement
}

const EntitlementDialog: FunctionComponent<Props> = ({ isOpen, close, editEntitlement }) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.billing.entitlement.delete)

  const { createEntitlement, loading: creating, error: creationError } = useCreateEntitlement()
  const { updateEntitlement, loading: updating, error: updateError } = useUpdateEntitlement()
  const { deleteEntitlement, loading: deleting, error: deleteError } = useDeleteEntitlement()

  const handleSuccess = async (msg: string) => {
    toast.success(msg)
    handleClose()
  }

  const handleDelete = async () => {
    if (editEntitlement) {
      await deleteEntitlement(editEntitlement.id, () =>
        handleSuccess("Successfully deleted Entitlement"),
      )
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    variant: Yup.string().optional(),
    active: Yup.boolean().required(),
    isFree: Yup.boolean().required(),
    usageEnabled: Yup.boolean().required(),
    usageLimitUnit: Yup.string().optional(),
    usageLimitDefault: Yup.number().integer().optional(),
    usageLimitRemoteValueKey: Yup.string().optional(),
    usageLimitPeriod: Yup.string().optional(),
    usageFunction: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      title: editEntitlement?.title || "",
      description: editEntitlement?.description || "",
      variant: editEntitlement?.variant || "",
      active: editEntitlement?.active,
      isFree: editEntitlement?.is_free,
      usageEnabled: editEntitlement?.usage_enabled,
      usageLimitUnit: editEntitlement?.usage_limit_unit || "",
      usageLimitDefault: editEntitlement?.usage_limit_default || "",
      usageLimitRemoteValueKey: editEntitlement?.usage_limit_remote_value_key || "",
      usageLimitPeriod: editEntitlement?.usage_limit_period || "",
      usageFunction: editEntitlement?.usage_function || "sum",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      if (editEntitlement) {
        await updateEntitlement(
          editEntitlement.id,
          {
            title: values.title !== "" ? values.title : null,
            description: values.description !== "" ? values.description : null,
            variant: values.variant !== "" ? values.variant : null,
            active: values.active,
            is_free: values.isFree,
            usage_enabled: values.usageEnabled,
            usage_limit_unit: values.usageLimitUnit !== "" ? values.usageLimitUnit : null,
            usage_limit_default: values.usageLimitDefault !== "" ? values.usageLimitDefault : null,
            usage_limit_remote_value_key:
              values.usageLimitRemoteValueKey !== "" ? values.usageLimitRemoteValueKey : null,
            usage_limit_period: values.usageLimitPeriod !== "" ? values.usageLimitPeriod : null,
            usage_function: values.usageFunction !== "" ? values.usageFunction : null,
          },
          () => handleSuccess("Successfully updated Entitlement"),
        )
      } else {
        await createEntitlement(
          {
            title: values.title !== "" ? values.title : null,
            description: values.description !== "" ? values.description : null,
            variant: values.variant !== "" ? values.variant : null,
            active: values.active,
            is_free: values.isFree,
            usage_enabled: values.usageEnabled,
            usage_limit_unit: values.usageLimitUnit !== "" ? values.usageLimitUnit : null,
            usage_limit_default: values.usageLimitDefault !== "" ? values.usageLimitDefault : null,
            usage_limit_remote_value_key:
              values.usageLimitRemoteValueKey !== "" ? values.usageLimitRemoteValueKey : null,
            usage_limit_period: values.usageLimitPeriod !== "" ? values.usageLimitPeriod : null,
            usage_function: values.usageFunction !== "" ? values.usageFunction : null,
          },
          () => handleSuccess("Successfully created Entitlement"),
        )
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editEntitlement
          ? `Update Entitlement: ${editEntitlement.title}`
          : "Create A New Entitlement"
      }
      content={
        <Box width="500px">
          <Row mb="4px">
            {(!!creationError || !!updateError || !!deleteError) && (
              <ErrorComponent error={creationError || updateError || deleteError} />
            )}
          </Row>
          <Row>
            <TextField
              label="Title"
              variant="outlined"
              margin="normal"
              id="title"
              name="title"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
              helperText={formik.errors.title?.toString()}
              onBlur={(evt) => formik.validateField("title")}
              required
              disabled={!!editEntitlement}
            />
            <TextField
              label="Variant"
              variant="outlined"
              margin="normal"
              id="variant"
              name="variant"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.variant}
              error={!!formik.errors.variant}
              helperText={formik.errors.variant?.toString()}
              onBlur={(evt) => formik.validateField("variant")}
            />
            <TextField
              label="Description"
              fullWidth
              variant="outlined"
              margin="normal"
              id="description"
              name="description"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={!!formik.errors.description}
              helperText={formik.errors.description?.toString()}
              onBlur={(evt) => formik.validateField("description")}
              required
            />

            <FormControlLabel
              control={<Checkbox />}
              checked={!!formik.values.isFree}
              label="Free"
              name="is_free"
              onChange={() => formik.setFieldValue("isFree", !formik.values.isFree)}
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={!!formik.values.active}
              label="Active"
              name="active"
              onChange={() => formik.setFieldValue("active", !formik.values.active)}
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={!!formik.values.usageEnabled}
              label="Usage Enabled"
              name="usage_enabled"
              onChange={() => formik.setFieldValue("usageEnabled", !formik.values.usageEnabled)}
            />
            <TextField
              label="Default Usage Limit"
              variant="outlined"
              margin="normal"
              id="usageLimitDefault"
              name="usageLimitDefault"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.usageLimitDefault}
              error={!!formik.errors.usageLimitDefault}
              helperText={formik.errors.usageLimitDefault?.toString()}
              onBlur={(evt) => formik.validateField("usageLimitDefault")}
              disabled={!formik.values.usageEnabled}
              required={formik.values.usageEnabled}
            />
            <TextField
              label="Usage Limit Unit"
              variant="outlined"
              margin="normal"
              id="usageLimitUnit"
              name="usageLimitUnit"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.usageLimitUnit}
              error={!!formik.errors.usageLimitUnit}
              helperText={formik.errors.usageLimitUnit?.toString()}
              onBlur={(evt) => formik.validateField("usageLimitUnit")}
              disabled={!formik.values.usageEnabled}
              required={formik.values.usageEnabled}
            />
            <FormGroup style={{ minWidth: "85" }}>
              <FormLabel>Usage Period</FormLabel>
              <Select
                value={formik.values.usageLimitPeriod}
                onChange={(evt) => formik.setFieldValue("usageLimitPeriod", evt.target.value)}
                label="Usage Limit Period"
                defaultValue=""
                disabled={!formik.values.usageEnabled}
              >
                <MenuItem value={""} key="all">
                  All
                </MenuItem>
                <MenuItem value={"day"} key="day">
                  Day
                </MenuItem>
                <MenuItem value={"week"} key="week">
                  Week
                </MenuItem>
                <MenuItem value={"month"} key="month">
                  Month
                </MenuItem>
                <MenuItem value={"year"} key="year">
                  Year
                </MenuItem>
              </Select>
            </FormGroup>

            <FormGroup style={{ minWidth: "85" }}>
              <FormLabel>Function/Methodology</FormLabel>
              <Select
                value={formik.values.usageFunction}
                onChange={(evt) => formik.setFieldValue("usageFunction", evt.target.value)}
                label="Usage Limit Rate Unit"
                fullWidth={true}
                defaultValue="sum"
                disabled={!formik.values.usageEnabled}
              >
                <MenuItem value={"sum"} key="sum">
                  Sum
                </MenuItem>
                <MenuItem value={"max"} key="max">
                  Max
                </MenuItem>
                <MenuItem value={"min"} key="min">
                  Min
                </MenuItem>
              </Select>
            </FormGroup>

            <TextField
              label="Remote Value Override Key"
              fullWidth
              variant="outlined"
              margin="normal"
              id="usageLimitRemoteValueKey"
              name="usageLimitRemoteValueKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.usageLimitRemoteValueKey}
              error={!!formik.errors.usageLimitRemoteValueKey}
              helperText={formik.errors.usageLimitRemoteValueKey?.toString()}
              onBlur={(evt) => formik.validateField("usageLimitRemoteValueKey")}
              disabled={!formik.values.usageEnabled}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!editEntitlement && canDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={creating || updating || deleting}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creating || updating || deleting || !formik.isValid}
          >
            {editEntitlement ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default EntitlementDialog
