import { useEffect, useState } from "react"
import { COLLECTIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { Collection } from "../../../types"

const useGetCollections = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getCollections,
  } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<Collection>> => {
    let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}`
    return makeRequest<PagedResponse<Collection>>(
      `${COLLECTIONS}?${queryParams}`,
      {},
      { handleUnauthenticated },
    )
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getCollections()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getCollections,
    collections: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetCollections
