import { FunctionComponent, useEffect, useState } from "react"
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material"

import useListTemplateFilters from "./hooks/useListTemplateFilters"
import useGetCollections from "../Tags/hooks/useGetCollections"
import { SelectedFilter } from "../../types"

const TemplateFilters: FunctionComponent<{
  selectedFilters: SelectedFilter
  setSelectedFilters: (filters: SelectedFilter) => void
}> = ({ selectedFilters, setSelectedFilters }) => {
  const { listTemplateFilters, templateFilters } = useListTemplateFilters()

  const [buttonState, setButtonState] = useState<Record<string, boolean>>({})

  const { getCollections, collections } = useGetCollections()

  //
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, filterKey: string) => {
    const { name, checked } = event.target
    setButtonState({ ...buttonState, [name]: checked })

    if (!checked) {
      const newSelectedFilters = { ...selectedFilters }
      delete newSelectedFilters[filterKey]
      return setSelectedFilters(newSelectedFilters)
    }
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: checked,
    })
  }

  const handleSelectChange = (event: SelectChangeEvent<string>, filterKey: string) => {
    const newFilters = {
      ...selectedFilters,
      [filterKey]: event.target.value,
    }

    if (event.target.value === "") {
      delete newFilters[filterKey]
    }

    setSelectedFilters(newFilters)
  }

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>, filterKey: string) => {
    const newFilters = {
      ...selectedFilters,
      [filterKey]: event.target.value,
    }

    if (event.target.value.length === 0) {
      delete newFilters[filterKey]
    }
    setSelectedFilters(newFilters)
  }

  useEffect(() => {
    listTemplateFilters()
    getCollections()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Platform</InputLabel>
          <Select
            label="Platform"
            value={selectedFilters.platform?.toString()}
            onChange={(e) => handleSelectChange(e, "platform")}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="web">Web</MenuItem>
            <MenuItem value="ios">iOS</MenuItem>
            <MenuItem value="mac">Mac</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {templateFilters.map((filter, index) => (
        <Grid item xs={4} key={index}>
          {filter.type === "boolean" && (
            <FormControlLabel
              label={filter.title}
              value={buttonState[filter.key]}
              control={<Switch onChange={(e) => handleCheckboxChange(e, filter.key)} />}
            />
          )}
          {filter.type === "select" && (
            <FormControl fullWidth>
              <InputLabel>{filter.title}</InputLabel>
              <Select
                defaultValue=""
                label={filter.title}
                value={selectedFilters[filter.key] as string}
                onChange={(e) => handleSelectChange(e, filter.key)}
              >
                <MenuItem value="">All</MenuItem>
                {collections
                  ?.find((x) => x.id === filter.collection_id)
                  ?.tags?.map((tag, index) => (
                    <MenuItem key={index} value={tag.id}>
                      {tag.display_title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {filter.type === "multiselect" && (
            <FormControl fullWidth>
              <InputLabel>{filter.title}</InputLabel>
              <Select
                defaultValue={[]}
                multiple
                label={filter.title}
                onChange={(e) => handleMultiSelectChange(e, filter.key)}
              >
                {collections
                  ?.find((x) => x.id === filter.collection_id)
                  ?.tags?.map((tag, index) => (
                    <MenuItem key={index} value={tag.id}>
                      {tag.display_title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

const useTemplateFilters = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter>({ platform: "all" })
  return {
    templateFilters: (
      <TemplateFilters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
    ),
    selectedFilters,
  }
}

export default useTemplateFilters
