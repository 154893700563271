import { useState } from "react"
import { CONVERSATIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { AISiteGeneration } from "../../../types"

const useGetGenerationsForConversation = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getGenerationsForConversation,
  } = useHandleRequest(
    (handleUnauthenticated, conversationId: string): Promise<PagedResponse<AISiteGeneration>> => {
      const query = Object.entries({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      })
        .map(
          ([key, value]: [string, string | number | boolean | undefined]) =>
            value && `${key}=${encodeURIComponent(value)}`,
        )
        .filter(Boolean)
        .join("&")

      return makeRequest<PagedResponse<AISiteGeneration>>(
        `${CONVERSATIONS}/${conversationId}/generations?${query}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  return {
    loading,
    error,
    getGenerationsForConversation,
    generations: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetGenerationsForConversation
