import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Tag } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateSiteTags = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      siteId: string | number,
      userId: string | number,
      tags: Tag[],
      onSuccess?: () => any,
    ): Promise<void> => {
      console.log(JSON.stringify(tags))
      const response = await makeRequest<void>(
        `${SITESV4}/${siteId}/tags`,
        {
          method: "PUT",
          headers: {
            "universe-user-id": `${userId}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tag_ids: (tags || []).map((x) => x.id),
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateSiteTags: performAction, loading, error }
}

export default useUpdateSiteTags
