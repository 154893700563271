import { Box, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FunctionComponent, ReactNode } from "react"
import Dialog from "./Dialog"
import { TextBody } from "./Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  errorMessage?: string | null
  loading: boolean
  content: ReactNode | []
  title: string
  affirmativeButtonText?: string
  negativeButtonText?: string
}

export const ConfirmationRow = styled(Box)``
ConfirmationRow.defaultProps = {
  sx: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}

const ConfirmationDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  handleConfirmation,
  errorMessage,
  loading,
  content,
  title,
  affirmativeButtonText,
  negativeButtonText,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={close}
      title={title}
      content={
        <Box width="500px">
          <ConfirmationRow height="8px" mb="4px">
            {errorMessage && <TextBody hasError={!!errorMessage}>{errorMessage}</TextBody>}
          </ConfirmationRow>
          {content}

          <Box
            width="100%"
            display="flex"
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "12px",
            }}
          >
            <Button color="error" variant="outlined" onClick={close} style={{ width: "40%" }}>
              {negativeButtonText || "Cancel"}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ width: "40%" }}
              disabled={loading}
              onClick={handleConfirmation}
            >
              {affirmativeButtonText || "Continue"}
            </Button>
          </Box>
        </Box>
      }
    />
  )
}

export default ConfirmationDialog
