import { COUPONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Coupon } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateCoupon = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, coupon: Coupon, onSuccess?: () => any): Promise<Coupon> => {
      const createdCoupon = await makeRequest<Coupon>(
        `${COUPONS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(coupon),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return createdCoupon
    },
  )

  return { createCoupon: performAction, loading, error }
}

export default useCreateCoupon
