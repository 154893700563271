import { FunctionComponent } from "react"
import { Paper, Table, TableBody, TableContainer, TableHead } from "@mui/material"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import PipelineRunRow from "./PipelineRunRow"
import type { TektonPipelineRun } from "../../../types"

type PipelineRunsTableRowProps = {
  pipelineRuns: TektonPipelineRun[]
}

const PipelineRunsTable: FunctionComponent<PipelineRunsTableRowProps> = ({ pipelineRuns }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <BasicTableRow>
            <BasicTableCell>Name</BasicTableCell>
            <BasicTableCell>Status</BasicTableCell>
            <BasicTableCell>Created At</BasicTableCell>
          </BasicTableRow>
        </TableHead>
        <TableBody>
          {pipelineRuns
            .sort((a, b) => {
              const aTime = new Date(a.metadata.creationTimestamp)
              const bTime = new Date(b.metadata.creationTimestamp)
              return bTime.getTime() - aTime.getTime()
            })
            .map((pipelineRun) => (
              <PipelineRunRow key={pipelineRun.metadata.name} pipelineRun={pipelineRun} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PipelineRunsTable
