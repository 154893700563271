import  { FunctionComponent, useCallback } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { ViewerSubscription } from "../../../types"
import { Button } from "@mui/material"

type SubscriberRowProps = {
  subscriber: ViewerSubscription
  unsubscribeViewer: any
  canUnsubscribe: boolean
}

const SubscriberRow: FunctionComponent<SubscriberRowProps> = ({
  subscriber,
  unsubscribeViewer,
  canUnsubscribe,
}) => {
  const handleUnsubscribeViewer = useCallback(() => {
    unsubscribeViewer(subscriber.id)
  }, [subscriber, unsubscribeViewer])

  return (
    <BasicTableRow key={subscriber.id}>
      <BasicTableCell component="th" scope="row">
        {subscriber.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subscriber.email_address}
      </BasicTableCell>
      {canUnsubscribe && (
        <BasicTableCell>
          <Button
            style={{
              fontWeight: "bold",
              alignSelf: "center",
            }}
            onClick={handleUnsubscribeViewer}
          >
            Unsubscribe
          </Button>
        </BasicTableCell>
      )}
      <BasicTableCell component="th" scope="row">
        {subscriber.created_at}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default SubscriberRow
