import { styled } from "@mui/material/styles"
import { FunctionComponent, useState } from "react"
import { TextBody } from "../../../components/shared/Typography"
import enforceError from "../../../utils/enforce-error"
import { Button, DialogContentText } from "@mui/material"
import Dialog from "../../../components/shared/Dialog"
import useVoidShippingLabelById from "../hooks/useVoidShippingLabelById"
import toast from "react-hot-toast"

interface Props {
  orderId: number
  shipmentId: number
  close: () => any
  isOpen: boolean
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const VoidShippingLabelConfirmationDialog: FunctionComponent<Props> = ({
  orderId,
  shipmentId,
  close,
  isOpen,
}) => {
  const [error, setError] = useState<Error>()
  const { voidShippingLabel, error: voidError, loading } = useVoidShippingLabelById()

  const voidLabel = () => {
    try {
      voidShippingLabel(orderId, shipmentId, close)
      toast.success(`Successfully voided shipping label for shipment ${shipmentId}`)
      close()
    } catch (err) {
      setError(enforceError(err))
      toast.error(`Error voiding shipping label`)
    }
  }

  return (
    <Dialog
      title="Void Shipping Label"
      isOpen={isOpen}
      handleClose={close}
      error={error || voidError}
      loading={loading}
      content={
        <>
          {error || voidError ? <br /> : null}
          <DialogContentText>
            You are about to void the shipping label for shipment {shipmentId} on order {orderId}.
            Voiding a shipping label makes the label invalid, refunds the Universe ShipEngine
            account and credits the merchant's Stripe account with the cost of the label.
          </DialogContentText>
          <DialogContentText>Would you like to continue?</DialogContentText>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={voidLabel} color="primary" disabled={loading}>
            Void Label
          </Button>
        </>
      }
    />
  )
}

export default VoidShippingLabelConfirmationDialog
