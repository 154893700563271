import { CATEGORIESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Category } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateCategoryById = () => {
  const { loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, id: number, options: object): Promise<Category> => {
      return makeRequest<Category>(
        `${CATEGORIESV5}/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(options),
        },
        { handleUnauthenticated },
      )
    },
  )

  return { updateCategory: performAction, loading, error }
}

export default useUpdateCategoryById
