import { Button } from "@mui/material"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import RenderMarkdown from "./RenderMarkdown"
import type { TektonPipeline } from "../../../types"
import { dashToTitle } from "../utils"

type PipelineRowProps = {
  pipeline: TektonPipeline
}

const PipelineRow: FunctionComponent<PipelineRowProps> = ({ pipeline }) => {
  return (
    <BasicTableRow>
      <BasicTableCell>
        <Link to={`/tekton/${pipeline.metadata.name}`}>{dashToTitle(pipeline.metadata.name)}</Link>
      </BasicTableCell>
      <BasicTableCell>
        <RenderMarkdown markdown={pipeline.spec.description} />
      </BasicTableCell>
      <BasicTableCell>
        <Link to={`/tekton/${pipeline.metadata.name}/run`}>
          <Button variant="outlined" color="primary">
            Run
          </Button>
        </Link>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default PipelineRow
