import { Box, Button } from "@mui/material"
import { Form, Formik } from "formik"
import { FunctionComponent, useCallback } from "react"
import * as Yup from "yup"
import CopyIcon from "../../../components/shared/CopyIcon"
import Dialog from "../../../components/shared/Dialog"
import { Subhead, TextBody } from "../../../components/shared/Typography"
import useToggle from "../../../hooks/useToggle"
import { DomainTypeEnum } from "../../../types"
import isUniverseDomain from "../../../utils/isUniverseDomain"
import SitesByUserSelect from "../../Sites/components/SitesByUserSelect"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"
import { Row, StyledText } from "./DomainLayoutShared"
import DomainSearchAutocomplete from "./DomainSearchAutocomplete"

export enum DomainReassignBasisEnum {
  Site = "site",
  Domain = "domain",
}

const validationSchemaMap = {
  domain: Yup.object().shape({
    userOption: Yup.object().required("Required"),
    siteOption: Yup.object().required("Required"),
  }),
  site: Yup.object().shape({
    userOption: Yup.object().required("Required"),
    domainOption: Yup.object().required("Required"),
  }),
}

interface ContentProps {
  domainId?: number | null
  domainName?: string | null
  userId?: number | null
  siteId?: number | null
  close: () => any
  hasError?: boolean
  handleReassign: (
    domainId: number,
    userId: number,
    siteId: number,
    domainType?: DomainTypeEnum,
  ) => any
  domainType?: DomainTypeEnum
  loading: boolean
  basis: DomainReassignBasisEnum
}

interface DialogProps extends ContentProps {
  isOpen: boolean
  title: string
}

const ReassignDomainContent: FunctionComponent<ContentProps> = ({
  domainId,
  domainName,
  userId,
  siteId,
  close,
  handleReassign,
  hasError,
  domainType,
  loading,
  basis,
}) => {
  const {
    isOn: confirmationIsShown,
    turnOn: showConfirmation,
    turnOff: hideConfirmation,
  } = useToggle()

  const submitByDomain = useCallback(
    async (values: any) => {
      if (!domainId && domainType) {
        return
      }
      const currentSiteDomain = values?.siteOption?.domain

      // This is a check to see if the domain is actually a subdomain represented in the domain column due to some backend configuration
      const hasUniverseDomain = isUniverseDomain(currentSiteDomain)
      const currentSiteSubdomain = values?.siteOption?.subdomain

      const domainRequiresConf =
        domainType === DomainTypeEnum.Domain && currentSiteDomain && !hasUniverseDomain
      const subdomainRequiresConf =
        (domainType === DomainTypeEnum.Subdomain && currentSiteSubdomain) || hasUniverseDomain
      const requiresConfirmation = domainRequiresConf || subdomainRequiresConf

      if (requiresConfirmation && !confirmationIsShown) {
        showConfirmation()
      } else {
        await handleReassign(domainId as number, values.userOption?.user.id, values.siteOption.id)
        hideConfirmation()
      }
    },
    [handleReassign, domainId, domainType, confirmationIsShown, hideConfirmation, showConfirmation],
  )

  const submitBySite = useCallback(
    async (values: any) => {
      if (!siteId) {
        return
      }
      const currentDomainSiteId = values?.domainOption?.domain.site_id
      if (currentDomainSiteId && !confirmationIsShown) {
        showConfirmation()
      } else {
        const domainType =
          values?.domainOption?.domain?.hasOwnProperty("type") &&
          values?.domainOption?.domain?.type === DomainTypeEnum.Subdomain
            ? DomainTypeEnum.Subdomain
            : DomainTypeEnum.Domain
        await handleReassign(
          values?.domainOption?.domain?.id,
          values?.userOption?.user?.id,
          siteId,
          domainType,
        )
        hideConfirmation()
      }
    },
    [handleReassign, siteId, confirmationIsShown, showConfirmation, hideConfirmation],
  )

  const getTransferButtonDisabledStatus = useCallback(
    (values: any) => {
      if (basis === DomainReassignBasisEnum.Domain) {
        return !!loading || !domainId || !values.siteOption || !values.userOption
      } else if (basis === DomainReassignBasisEnum.Site) {
        return !!loading || !siteId || !values.domainOption || !values.userOption
      } else {
        return true
      }
    },
    [basis, domainId, loading, siteId],
  )

  const formik = {
    initialValues: {
      userOption: "",
      siteOption: "",
      domainOption: "",
    },
    validationSchema: validationSchemaMap[basis],
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: useCallback(
      async (values: any) => {
        if (basis === DomainReassignBasisEnum.Domain) {
          await submitByDomain(values)
        } else if (basis === DomainReassignBasisEnum.Site) {
          await submitBySite(values)
        } else {
          return
        }
      },
      [submitByDomain, basis, submitBySite],
    ),
  }

  return (
    <Formik {...formik}>
      {({ values }) => {
        return (
          <Form id="reassign-domain-form">
            <Box width="500px">
              <Row height="8px" mb="4px">
                {hasError && (
                  <TextBody hasError={hasError}>
                    There was a problem reassiging the domain.
                  </TextBody>
                )}
              </Row>
              <Row>
                <Subhead>Current User Id: </Subhead>
                <StyledText>{userId || "null"}</StyledText>
                {userId && <CopyIcon content={userId} />}
              </Row>

              <UserSearchAutocomplete defaultId={userId} />
              {basis === DomainReassignBasisEnum.Domain && (
                <>
                  <Row>
                    <Subhead>Current Site Id: </Subhead>
                    <StyledText>{siteId || "null"}</StyledText>
                    {siteId && <CopyIcon content={siteId} />}
                  </Row>

                  <SitesByUserSelect
                    userFieldName="userOption"
                    siteFieldName="siteOption"
                    highlightAssigned
                    domainType={domainType}
                  />
                </>
              )}

              {basis === DomainReassignBasisEnum.Site && (
                <>
                  <Row>
                    <Subhead>Current Domain: </Subhead>
                    <StyledText>{domainName || domainId || "null"}</StyledText>
                    {domainId && <CopyIcon content={domainId} />}
                  </Row>

                  <DomainSearchAutocomplete />
                </>
              )}

              {confirmationIsShown && (
                <Row>
                  <TextBody hasWarning>
                    {basis === DomainReassignBasisEnum.Domain
                      ? `This domain is already associated with another site. Reassignment will result in
                    the domain being associated only with the site selected above.`
                      : `The selected domain is already associated with another site. Reassignment will result in the domain being associated instead site ${siteId}`}
                  </TextBody>
                </Row>
              )}
              <Box
                display="flex"
                width="100%"
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: "12px",
                }}
              >
                <Button
                  color="error"
                  variant="outlined"
                  onClick={confirmationIsShown ? hideConfirmation : close}
                  style={{ width: "40%" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ width: "40%" }}
                  disabled={getTransferButtonDisabledStatus(values)}
                >
                  {confirmationIsShown ? "Continue" : "Reassign"}
                </Button>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const ReassignDomainDialog: FunctionComponent<DialogProps> = ({
  isOpen,
  close,
  domainId,
  domainName,
  userId,
  siteId,
  title,
  handleReassign,
  hasError,
  domainType,
  loading,
  basis,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={close}
      title={title}
      content={
        <ReassignDomainContent
          domainId={domainId}
          close={close}
          handleReassign={handleReassign}
          hasError={hasError}
          domainType={domainType}
          userId={userId}
          siteId={siteId}
          loading={loading}
          basis={basis}
          domainName={domainName}
        />
      }
    />
  )
}

export default ReassignDomainDialog
