import { Box, Button, Radio, RadioGroup, FormControlLabel, Checkbox } from "@mui/material"
import { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { TextBody } from "../../../components/shared/Typography"
import useCreateNote from "../../Users/hooks/useCreateNote"
import useBulkRefundOrdersBySiteId from "../hooks/useBulkRefundOrdersBySiteId"

type Props = {
  isOpen: boolean
  close: () => void
  siteId: number
  onSuccess: () => void
}

const BulkRefundOrdersDialog: FunctionComponent<Props> = ({ isOpen, close, siteId, onSuccess }) => {
  const { bulkRefundOrdersBySiteId, loading, error } = useBulkRefundOrdersBySiteId()
  const { createNote, loading: noteLoading, error: noteError } = useCreateNote()
  const [refundReason, setRefundReason] = useState("")
  const [forceful, setForceful] = useState(false)

  const handleClose = () => {
    close()
  }

  const handleSuccess = () => {
    close()
    onSuccess()
  }

  const createNoteAfterSuccess = async () => {
    await createNote(
      "site",
      siteId,
      "All orders have been bulk refunded on this site",
      handleSuccess,
    )
  }

  const handleBulkRefund = async () => {
    await bulkRefundOrdersBySiteId(siteId, refundReason, forceful, createNoteAfterSuccess)
  }

  const handleRefundReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundReason(event.target.value)
  }

  const handleForcefulChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceful(event.target.checked)
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Bulk Refund Orders"
      content={
        <Box width="500px">
          {error && <ErrorComponent error={error} />}
          {noteError && <ErrorComponent error={noteError} />}
          <TextBody>
            This is a dangerous action that cannot be reversed. ALL orders associated with this site
            will be refunded. ALL OF THEM. This should only ever be used if you know what you are
            doing. Please select a reason for this action below. Again, please make sure this is
            what you <i>actually</i> want to be doing.
          </TextBody>
          <RadioGroup value={refundReason} onChange={handleRefundReasonChange}>
            <FormControlLabel value="fraudulent" control={<Radio />} label="Fraudulent" />
            <FormControlLabel
              value="duplicate"
              control={<Radio />}
              label="Duplicate transactions"
            />
          </RadioGroup>
          <TextBody>
            A note will be automatically added to the site indicating that all orders were bulk
            refunded after completion.
          </TextBody>
          <FormControlLabel
            control={
              <Checkbox checked={forceful} onChange={handleForcefulChange} color="primary" />
            }
            label="I acknowledge how dangerous this is and wish to proceed"
          />
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="info" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleBulkRefund}
            color="error"
            variant="contained"
            disabled={loading || noteLoading}
          >
            REFUND ALL ORDERS
          </Button>
        </>
      }
    />
  )
}

export default BulkRefundOrdersDialog
