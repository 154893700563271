import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { RequestLog } from "../../../types"
import RequestRow from "./RequestRow"

interface RequestsTableProps {
  requests: RequestLog[]
  paginationData: PaginationData
}

const getRows = (requests: RequestLog[]) => {
  return requests.map((request, index) => {
    return <RequestRow key={index} request={request}   />
  })
}

const columns: string[] = ["Timestamp", "Request ID", "Level", "Service", "Line", "Site ID", "User ID", "Details"]

const RequestsTable = ({ requests, paginationData }: RequestsTableProps) => {
  return <BasicTable columns={columns} rows={getRows(requests)} paginationOptions={paginationData} />
}

export default RequestsTable
