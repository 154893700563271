import { Box, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material"
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import {
  BillingProduct,
  Entitlement,
  ProductEntitlement,
  UpdateProductEntitlement,
} from "../../../types"
import useGetProductEntitlements from "../hooks/useGetProductEntitlements"
import useUpdateProductEntitlements from "../hooks/useUpdateProductEntitlements"
import ErrorComponent from "../../../components/shared/Error"
import EntitlementComponent from "./EntitlementChecklist"

type BillingProductProps = {
  product: BillingProduct | undefined
  open: boolean
  handleClose: any
  handleGetProducts: () => any
  entitlements: Entitlement[]
  loadingEntitlements: boolean
  entitlementsError: boolean
}

const EditProductEntitlementsDialog: FunctionComponent<BillingProductProps> = ({
  open = false,
  handleClose,
  product,
  handleGetProducts,
  loadingEntitlements,
  entitlements,
}) => {
  const [checkedEntitlements, setCheckedEntitlements] = useState(
    new Map<number, ProductEntitlement | UpdateProductEntitlement>(),
  )

  const {
    getProductEntitlements,
    loading: gettingProductEntitlements,
    error: errorGettingProductEntitlements,
  } = useGetProductEntitlements()

  const { updateProductEntitlements, loading, error } = useUpdateProductEntitlements()

  const handleUpdateSuccess = useCallback(() => {
    handleClose()
    handleGetProducts()
    toast.success("Successfully updated product entitlements")
  }, [handleGetProducts, handleClose])

  const handleSubmit = () => {
    if (!product) {
      return
    }
    const productEntitlementUpdates: UpdateProductEntitlement[] = []
    checkedEntitlements.forEach((item) => {
      productEntitlementUpdates.push({
        entitlement_id: item.entitlement_id,
        usage_limit: item.usage_limit,
        usage_limit_period: item.usage_limit_period !== "" ? item.usage_limit_period : null,
      })
    })

    return updateProductEntitlements(product.id, productEntitlementUpdates, handleUpdateSuccess)
  }

  const handleChecked = async (entitlementUpdate: {
    entitlementId: number
    checked: boolean
    usageLimit: number | null
    usageLimitRate: string
  }) => {
    const { entitlementId, checked, usageLimit, usageLimitRate } = entitlementUpdate
    const currentEntitlements = new Map(checkedEntitlements)

    if (checked) {
      currentEntitlements.set(entitlementId, {
        entitlement_id: entitlementId,
        usage_limit: usageLimit,
        usage_limit_period: usageLimitRate,
      })
    } else {
      currentEntitlements.delete(entitlementId)
    }

    setCheckedEntitlements(currentEntitlements)
  }

  const handleGetProductEntitlements = useCallback((productEntitlements: ProductEntitlement[]) => {
    const newProductEntitlements = new Map()
    productEntitlements.forEach((productEntitlement) => {
      newProductEntitlements.set(productEntitlement.entitlement_id, productEntitlement)
    })
    setCheckedEntitlements(newProductEntitlements)
  }, [])

  useEffect(() => {
    if (product) {
      getProductEntitlements(product.id, handleGetProductEntitlements)
    }
    // eslint-disable-next-line
  }, [product])

  const getEntitlementsList = useCallback(() => {
    return (
      <Box flexDirection={"column"}>
        {entitlements.map((entitlement) => {
          return (
            <EntitlementComponent
              entitlement={entitlement}
              onChange={handleChecked}
              productEntitlement={checkedEntitlements.get(entitlement.id)}
            />
          )
        })}
      </Box>
    )
  }, [entitlements, checkedEntitlements, handleChecked])

  const close = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <Dialog
      loading={gettingProductEntitlements || loadingEntitlements}
      isOpen={open}
      handleClose={close}
      title={`Edit Product ${product?.title ? `"${product?.title}"` : ""} Entitlements`}
      aria-labelledby="edit-product-dialog"
      maxWidth="md"
      content={
        <Box width="md">
          <Row height="8px" mb="4px">
            {!!error && <ErrorComponent error={error} />}
            {!!errorGettingProductEntitlements && (
              <ErrorComponent error={errorGettingProductEntitlements} />
            )}
          </Row>
          <Row>{getEntitlementsList()}</Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="outlined" disabled={loading}>
            Save
          </Button>
        </>
      }
    ></Dialog>
  )
}
export default EditProductEntitlementsDialog
