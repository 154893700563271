import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UpdateEmailForwardRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useEditEmailForward = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      emailForward: UpdateEmailForwardRequest,
      onSuccess?: () => any,
    ): Promise<UpdateEmailForwardRequest> => {
      const response = await makeRequest<UpdateEmailForwardRequest>(
        `${DOMAINS}/${emailForward.domain}/emailforwards/${emailForward.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "universe-user-id": `${emailForward.user_id}`,
          },
          body: JSON.stringify({
            email_address: emailForward.email_address,
            forward_address: emailForward.forward_address,
            user_id: emailForward.user_id,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { editEmailForward: performAction, loading, error }
}

export default useEditEmailForward
