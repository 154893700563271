import { Button, Chip, Grid, TextField } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent, useEffect, useState } from "react"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import { PageType } from "../../../types"
import useCreatePageType from "../hooks/useCreatePageType"
import toast from "react-hot-toast"
import ErrorComponent from "../../../components/shared/Error"
import useGetPageTypeById from "../hooks/useGetPageTypeById"
import useUpdatePageType from "../hooks/useUpdatePageType"
import useDeletePageType from "../hooks/useDeletePageType"
import FileUploader from "../../../components/shared/FileUploader"

interface Props {
  isOpen: boolean
  close: () => void
  refreshData: () => void
  pageTypeUpdate?: PageType | undefined
  canDelete: boolean
}

const CreateEditPageTypeDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  refreshData,
  pageTypeUpdate,
  canDelete,
}) => {
  const tags = ["ios", "web"]
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [status, setStatus] = useState<string>("")

  const { createPageType, loading: createLoading, error: createError } = useCreatePageType()
  const { updatePageType, loading: updateLoading, error: updateError } = useUpdatePageType()
  const { deletePageType, loading: deleteLoading, error: deleteError } = useDeletePageType()
  const { getPageTypeById } = useGetPageTypeById()

  const validationSchema = Yup.object().shape({
    display_name: Yup.string().required(),
    icon_url: Yup.string().required(),
    content: Yup.string().required(),
    minimum_version: Yup.string().optional(),
    maximum_version: Yup.string().optional(),
  })

  const toggleTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag))
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  const handleCreateSuccess = async () => {
    toast.success("Successfully created page type")
    formik.resetForm()
    await refreshData()
    close()
  }

  const handleUpdateSuccess = async () => {
    toast.success("Successfully updated page type")
    formik.resetForm()
    await refreshData()
    close()
  }

  const handleDeletePageType = async () => {
    if (pageTypeUpdate) {
      await deletePageType(pageTypeUpdate.id, handleDeletePageTypeSuccess)
    }
  }

  const handleDeletePageTypeSuccess = async () => {
    toast.success("Successfully deleted page type")
    formik.resetForm()
    await refreshData()
    close()
  }

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      display_name: "",
      icon_url: "",
      content: "",
      minimum_version: "",
      maximum_version: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!pageTypeUpdate) {
        await updatePageType(
          pageTypeUpdate.id,
          {
            display_name: values.display_name,
            icon_url: values.icon_url,
            content: values.content === "" ? null : JSON.parse(values.content),
            minimum_version: values.minimum_version === "" ? null : values.minimum_version,
            maximum_version: values.maximum_version === "" ? null : values.maximum_version,
            tags: selectedTags,
          },
          handleUpdateSuccess,
        )
      } else {
        await createPageType(
          {
            display_name: values.display_name,
            icon_url: values.icon_url,
            content: values.content === "" ? null : JSON.parse(values.content),
            minimum_version: values.minimum_version === "" ? null : values.minimum_version,
            maximum_version: values.maximum_version === "" ? null : values.maximum_version,
            tags: selectedTags,
          },
          handleCreateSuccess,
        )
      }
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      if (pageTypeUpdate) {
        const resp = await getPageTypeById(pageTypeUpdate.id)

        // gross, but works
        if (resp) {
          formik.setValues({
            display_name: pageTypeUpdate.display_name,
            icon_url: pageTypeUpdate.icon_url,
            content: resp.content ? JSON.stringify(resp.content) : "",
            minimum_version: pageTypeUpdate.minimum_version || "",
            maximum_version: pageTypeUpdate.maximum_version || "",
          })
          const tagTitles = resp.tags ? resp.tags.map((tag) => tag.title) : []
          setSelectedTags(tagTitles)
        }
      }
    }

    fetchData()

    // eslint-disable-next-line
  }, [pageTypeUpdate])

  return (
    <Dialog
      title={!!pageTypeUpdate ? `Update ${pageTypeUpdate.display_name}` : "Create Page Type"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={createLoading || updateLoading}
      content={
        <>
          {createError && <ErrorComponent error={createError} />}
          {updateError && <ErrorComponent error={updateError} />}
          {deleteError && <ErrorComponent error={deleteError} />}
          <FormikProvider value={formik}>
            <TextField
              autoFocus
              margin="dense"
              id="display_name"
              name="display_name"
              label="Display Name"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.display_name}
              error={!!formik.errors.display_name}
            />

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField
                  label="Icon Url"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  id="icon_url"
                  name="icon_url"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.icon_url}
                  error={!!formik.errors.icon_url}
                  helperText={formik.errors.icon_url?.toString()}
                  onBlur={(evt) => formik.validateField("icon_url")}
                />
                {status && status !== "" && <div>{status}</div>}
              </Grid>
              <Grid item xs={2}>
                <FileUploader
                  onAssetUpload={(asset) => formik.setFieldValue("icon_url", asset.public_url)}
                  uploadStatus={(status) => setStatus(status)}
                />
              </Grid>
            </Grid>

            <TextField
              margin="dense"
              id="content"
              name="content"
              label="Content"
              fullWidth
              variant="outlined"
              required
              multiline
              maxRows={5}
              onChange={formik.handleChange}
              value={formik.values.content}
              error={!!formik.errors.content}
            />
            <TextField
              margin="dense"
              id="minimum_version"
              name="minimum_version"
              label="Minimum Version"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.minimum_version}
              error={!!formik.errors.minimum_version}
            />
            <TextField
              margin="dense"
              id="maximum_version"
              name="maximum_version"
              label="Maximum Version"
              fullWidth
              variant="outlined"
              required={false}
              onChange={formik.handleChange}
              value={formik.values.maximum_version}
              error={!!formik.errors.maximum_version}
            />
            {tags.map((tag, index) => (
              <Chip
                key={index}
                sx={{
                  margin: (theme) => theme.spacing(0.5),
                  borderRadius: "8px",
                }}
                label={tag}
                color={selectedTags.includes(tag) ? "primary" : undefined}
                onClick={() => toggleTag(tag)}
              />
            ))}
          </FormikProvider>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!pageTypeUpdate && canDelete && (
            <Button
              onClick={handleDeletePageType}
              color="error"
              variant="contained"
              disabled={createLoading || updateLoading || deleteLoading}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={createLoading || updateLoading || deleteLoading}
          >
            {!!pageTypeUpdate ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default CreateEditPageTypeDialog
