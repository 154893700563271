import { Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import { toast } from "react-hot-toast"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Package } from "../../../types"
import { formatPrice } from "../../../utils/formatters"
import useGetShipmentById from "../hooks/useGetShipmentById"

interface PackageRowProps {
  data: Package
  voidShippingLabel: any
  merchantId: number
}

const PackageRow: FunctionComponent<PackageRowProps> = ({
  data,
  voidShippingLabel,
  merchantId,
}) => {
  const handleVoidShippingLabel = useCallback(() => {
    voidShippingLabel(data)
  }, [data, voidShippingLabel])

  const { shipment, getShipmentById, error } = useGetShipmentById()

  useEffect(() => {
    if (data.shipment_id) {
      getShipmentById(merchantId, data.shipment_id)
    }
  }, [merchantId, data])

  if (error) {
    toast.error(`Error loading shipment: ${error.message}`)
  }

  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {data.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {data.order_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {data.shipment_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {data.shipment_status}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {shipment ? formatPrice(shipment.total_amount) : null}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {shipment ? shipment.carrier : null}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {data.line_items?.map((item) => item.id).join(", ")}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {data.shipment_id ? (
          <Button onClick={handleVoidShippingLabel}>Void Shipping Label</Button>
        ) : null}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default PackageRow
