import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { CodeSnippet } from "../../../types"
import { Button } from "@mui/material"
import { formatDate } from "../../../utils/formatters"
import CodeSnippetDialog from "./CodeSnippetDialog"

type CodeSnippetRowProps = {
  codeSnippet: CodeSnippet
  canEdit: boolean
}

const CodeSnippetRow: FunctionComponent<CodeSnippetRowProps> = ({ codeSnippet, canEdit }) => {
  const [open, setOpen] = useState(false)
  const [fullBody, setFullBody] = useState(false)

  const body = JSON.stringify(codeSnippet.code)
  const commaPosition = body?.indexOf(",", 25)

  let shortBody = body
  if (commaPosition && commaPosition > -1) {
    shortBody = body.slice(0, Math.min(commaPosition, 120))
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BasicTableRow key={codeSnippet.id}>
      <BasicTableCell component="th" scope="row">
        {codeSnippet.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {codeSnippet.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {codeSnippet.author}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(codeSnippet.active)}
      </BasicTableCell>
      <BasicTableCell style={{ overflow: "auto", maxWidth: 400 }}>
        {commaPosition > -1 ? <Button onClick={(x) => setFullBody(!fullBody)}>...</Button> : ""}
        <pre style={{ whiteSpace: "pre-wrap" }}>{fullBody ? body : shortBody}</pre>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(new Date(codeSnippet.created_at))}
      </BasicTableCell>
      {canEdit ? (
        <BasicTableCell component="th" scope="row">
          <Button onClick={handleClickOpen}>Edit</Button>
        </BasicTableCell>
      ) : null}
      {open ? (
        <CodeSnippetDialog isOpen={open} close={handleClose} codeSnippetUpdate={codeSnippet} />
      ) : null}
    </BasicTableRow>
  )
}

export default CodeSnippetRow
