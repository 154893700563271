import { DEADLETTER } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DeadLetterEvent } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useReplayDeadletterEvent = () => {
  const { error, loading, performAction } = useHandleRequest(
    (handleUnauthenticated, deadletterEventId: number): Promise<DeadLetterEvent> => {
      return makeRequest<DeadLetterEvent>(
        `${DEADLETTER}/${deadletterEventId}/retry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated }
      )
    },
  )

  return { replayDeadletterEvent: performAction, loading, error }
}

export default useReplayDeadletterEvent
