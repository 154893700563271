import { ORDERSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Shipment } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useVoidShippingLabelById = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      orderId: number,
      shipmentId: number,
      onSuccess?: () => any,
    ): Promise<Shipment> => {
      const response = await makeRequest<Shipment>(
        `${ORDERSV3}/${orderId}/shipments/${shipmentId}/void`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { voidShippingLabel: performAction, loading, error }
}

export default useVoidShippingLabelById
