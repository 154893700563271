import { Button } from "@mui/material"
import  { FunctionComponent, useCallback } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Employee } from "../../../types"

type EmployeeRowProps = {
  employee: Employee
  deleteEmployee: any
  canDelete: boolean
  canUpdate: boolean
  updateEmployee: (employee: Employee) => any
}

const EmployeeRow: FunctionComponent<EmployeeRowProps> = ({
  employee,
  deleteEmployee,
  canDelete,
  canUpdate,
  updateEmployee,
}) => {
  const handleDeleteEmployee = useCallback(() => {
    deleteEmployee(employee.id)
  }, [employee, deleteEmployee])

  const handleUpdateEmployee = useCallback(() => {
    updateEmployee(employee)
  }, [employee, updateEmployee])

  return (
    <BasicTableRow key={employee.id}>
      <BasicTableCell component="th" scope="row">
        {employee.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {employee.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {employee.email}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {JSON.stringify(employee.roles)}
      </BasicTableCell>
      {canUpdate && (
        <BasicTableCell>
          <Button
            style={{
              fontWeight: "bold",
              alignSelf: "center",
            }}
            onClick={handleUpdateEmployee}
          >
            Update
          </Button>
        </BasicTableCell>
      )}
      {canDelete && (
        <BasicTableCell>
          <Button
            style={{
              fontWeight: "bold",
              alignSelf: "center",
            }}
            onClick={handleDeleteEmployee}
          >
            Delete
          </Button>
        </BasicTableCell>
      )}
    </BasicTableRow>
  )
}

export default EmployeeRow
