import  { FunctionComponent } from "react"
import { Dispute } from "../../../types"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { StyledText } from "./MerchantLayoutShared"
import { formatPrice } from "../../../utils/formatters"

interface Props {
  dispute: Dispute
}

const DisputeRow: FunctionComponent<Props> = ({ dispute }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {dispute.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <StyledText>
          <a href={`/orders/${dispute.order_id}`}>{dispute.order_id}</a>
        </StyledText>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {dispute.status}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {dispute.reason}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatPrice(dispute.price)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(dispute.has_evidence)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {dispute.evidence_due_by}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {dispute.created_at}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default DisputeRow
