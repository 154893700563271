import { Box, Button } from "@mui/material"
import dayjs from "dayjs"
import { FunctionComponent, useState } from "react"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Subhead } from "../../../components/shared/Typography"
import { Merchant, PayoutSchedule } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"
import { Row, StyledText } from "./MerchantLayoutShared"
import useUpdateMerchantPayoutSchedule from "../hooks/useUpdateMerchantPayoutSchedule"
import MerchantRejectionDialog from "./MerchantRejectionDialog"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import BulkRefundOrdersDialog from "./BulkRefundOrdersDialog"

interface Props {
  data: Merchant | undefined
  payoutSchedule: PayoutSchedule | null
  refreshData: () => void
}

const MerchantOverview: FunctionComponent<Props> = ({ data, payoutSchedule, refreshData }) => {
  const { hasScope } = useAuth()

  const canRejectMerchant = hasScope(scopes.merchant.reject)
  const canBulkRefundOrders = hasScope(scopes.orders.bulkRefund)

  const { updateMerchantPayoutSchedule, loading } = useUpdateMerchantPayoutSchedule()

  const [rejectMerchantOpen, setRejectMerchantOpen] = useState(false)
  const [bulkRefundOrdersOpen, setBulkRefundOrdersOpen] = useState(false)

  if (!data || !payoutSchedule) {
    return null
  }

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "400px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Row>
        <Subhead>ID: </Subhead>
        <StyledText>{data.id}</StyledText>
        <CopyIcon content={data.id} />
      </Row>
      <Row>
        <Subhead>Site ID: </Subhead>
        <StyledText>
          <SiteLink siteId={data.site_id}>{data.site_id}</SiteLink>
        </StyledText>
        <CopyIcon content={data.site_id} />
      </Row>
      <Row>
        <Subhead>Stripe ID: </Subhead>
        <StyledText>
          <a
            href={`https://dashboard.stripe.com/connect/accounts/${data.stripe_account_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.stripe_account_id}
          </a>
        </StyledText>
        <CopyIcon content={data.stripe_account_id} />
      </Row>
      <Row>
        <Subhead>Email: </Subhead>
        <StyledText>{data.email}</StyledText>
      </Row>
      <Row>
        <Subhead>Fee Level: </Subhead>
        <StyledText>{payoutSchedule.fee_level}</StyledText>
      </Row>
      <Row>
        <Subhead>Payout Interval: </Subhead>
        <StyledText>{payoutSchedule.schedule.interval}</StyledText>
      </Row>
      <Row>
        <Subhead>Payout Hold Period: </Subhead>
        <StyledText>{payoutSchedule.schedule.delay_days} days</StyledText>
      </Row>
      <Row>
        <Subhead>First Transaction: </Subhead>
        <StyledText>
          {data.first_transaction_date
            ? dayjs(data.first_transaction_date).format("MM/DD/YYYY")
            : "None"}
        </StyledText>
      </Row>
      <Row>
        <Subhead>Created At: </Subhead>
        <StyledText>{data.created_at}</StyledText>
      </Row>
      <Row>
        <Button
          variant="contained"
          color={payoutSchedule.schedule.interval === "manual" ? "success" : "warning"}
          sx={{ width: "100%", mt: 1 }}
          onClick={() =>
            updateMerchantPayoutSchedule(
              data,
              payoutSchedule.schedule.interval === "manual" ? false : true,
              refreshData,
            )
          }
          disabled={loading}
        >
          {payoutSchedule.schedule.interval === "manual" ? "Resume Payouts" : "Pause Payouts"}
        </Button>
      </Row>
      {canBulkRefundOrders && (
        <Row>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%", mt: 1 }}
            onClick={() => setBulkRefundOrdersOpen(true)}
            disabled={loading}
          >
            Refund All Orders
          </Button>
        </Row>
      )}
      {canRejectMerchant && (
        <Row>
          <Button
            variant="contained"
            color="error"
            sx={{ width: "100%", mt: 1 }}
            onClick={() => setRejectMerchantOpen(true)}
            disabled={
              loading || (!payoutSchedule.charges_enabled && !payoutSchedule.payouts_enabled)
            }
          >
            Reject Merchant Account
          </Button>
        </Row>
      )}
      <BulkRefundOrdersDialog
        isOpen={bulkRefundOrdersOpen}
        close={() => setBulkRefundOrdersOpen(false)}
        siteId={data.site_id}
        onSuccess={refreshData}
      />
      <MerchantRejectionDialog
        isOpen={rejectMerchantOpen}
        close={() => setRejectMerchantOpen(false)}
        merchantId={data.id}
        onSuccess={refreshData}
      />
    </Box>
  )
}

export default MerchantOverview
