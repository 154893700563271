// DO NOT EDIT THIS FILE DIRECTLY
// See `specs/authorization/README.md` for common issues / usage
export const scopes = {
  "ai": {
    "conversations": {
      "read": "ai:conversations.read",
      "create": "ai:conversations.create"
    },
    "conversation": {
      "read": "ai:conversation.read",
      "update": "ai:conversation.update",
      "delete": "ai:conversation.delete"
    },
    "logs": {
      "read": "ai:logs.read",
      "create": "ai:logs.create"
    },
    "log": {
      "read": "ai:log.read",
      "update": "ai:log.update",
      "delete": "ai:log.delete"
    },
    "imageGenerationStyles": {
      "create": "ai:image-generation-styles.create",
      "read": "ai:image-generation-styles.read"
    },
    "imageGenerationStyle": {
      "read": "ai:image-generation-style.read",
      "update": "ai:image-generation-style.update",
      "delete": "ai:image-generation-style.delete"
    }
  },
  "appReleases": {
    "page": {
      "create": "app-releases:page.create"
    }
  },
  "cloudkit": {
    "user": {
      "read": "cloudkit:user.read"
    },
    "verse": {
      "update": "cloudkit:verse.update",
      "disable": "cloudkit:verse.disable",
      "delete": "cloudkit:verse.delete"
    },
    "verses": {
      "read": "cloudkit:verses.read"
    }
  },
  "apple": {
    "subscription": {
      "restorePurchase": "apple:subscription.restore-purchase",
      "event": {
        "process": "apple:subscription:event.process"
      }
    }
  },
  "stripe": {
    "subscription": {
      "event": {
        "process": "stripe:subscription:event.process"
      }
    }
  },
  "billing": {
    "attribution": {
      "read": "billing:attribution.read",
      "update": "billing:attribution.update",
      "delete": "billing:attribution.delete"
    },
    "attributions": {
      "create": "billing:attributions.create",
      "read": "billing:attributions.read"
    },
    "coupon": {
      "read": "billing:coupon.read",
      "update": "billing:coupon.update",
      "delete": "billing:coupon.delete"
    },
    "coupons": {
      "create": "billing:coupons.create",
      "read": "billing:coupons.read"
    },
    "entitlement": {
      "read": "billing:entitlement.read",
      "update": "billing:entitlement.update",
      "delete": "billing:entitlement.delete"
    },
    "entitlements": {
      "create": "billing:entitlements.create",
      "read": "billing:entitlements.read"
    },
    "plan": {
      "read": "billing:plan.read",
      "update": "billing:plan.update",
      "delete": "billing:plan.delete"
    },
    "plans": {
      "create": "billing:plans.create",
      "read": "billing:plans.read"
    },
    "product": {
      "read": "billing:product.read",
      "update": "billing:product.update",
      "delete": "billing:product.delete",
      "entitlement": {
        "delete": "billing:product:entitlement.delete"
      },
      "entitlements": {
        "create": "billing:product:entitlements.create",
        "read": "billing:product:entitlements.read",
        "update": "billing:product:entitlements.update"
      }
    },
    "products": {
      "create": "billing:products.create",
      "read": "billing:products.read"
    },
    "subscription": {
      "stripeToken": {
        "create": "billing:subscription:stripe-token.create"
      }
    },
    "user": {
      "plans": {
        "read": "billing:user:plans.read"
      }
    }
  },
  "creditSubscription": {
    "read": "credit-subscription.read",
    "update": "credit-subscription.update"
  },
  "creditSubscriptions": {
    "read": "credit-subscriptions.read",
    "create": "credit-subscriptions.create"
  },
  "promo": {
    "read": "promo.read",
    "update": "promo.update",
    "validate": "promo.validate",
    "redeem": "promo.redeem",
    "redemptions": {
      "read": "promo:redemptions.read"
    }
  },
  "promos": {
    "create": "promos.create",
    "read": "promos.read"
  },
  "subscriptions": {
    "read": "subscriptions.read",
    "create": "subscriptions.create"
  },
  "subscription": {
    "read": "subscription.read",
    "update": "subscription.update",
    "validate": "subscription.validate",
    "invalidate": "subscription.invalidate",
    "cancel": "subscription.cancel",
    "diagnostics": {
      "read": "subscription:diagnostics.read"
    },
    "sites": {
      "update": "subscription:sites.update"
    },
    "domains": {
      "create": "subscription:domains.create",
      "read": "subscription:domains.read"
    },
    "domain": {
      "read": "subscription:domain.read",
      "delete": "subscription:domain.delete"
    }
  },
  "user": {
    "subscriptions": {
      "read": "user:subscriptions.read"
    },
    "notification": {
      "read": "user:notification.read",
      "update": "user:notification.update"
    },
    "notifications": {
      "read": "user:notifications.read",
      "delete": "user:notifications.delete"
    },
    "pushToken": {
      "read": "user:push-token.read"
    },
    "pushTokens": {
      "create": "user:push-tokens.create",
      "read": "user:push-tokens.read"
    },
    "sites": {
      "create": "user:sites.create",
      "read": "user:sites.read"
    },
    "site": {
      "read": "user:site.read",
      "update": "user:site.update",
      "delete": "user:site.delete",
      "settings": {
        "update": "user:site:settings.update"
      }
    },
    "read": "user.read",
    "update": "user.update",
    "delete": "user.delete",
    "merge": "user.merge",
    "details": {
      "read": "user:details.read",
      "update": "user:details.update"
    },
    "identities": {
      "create": "user:identities.create",
      "read": "user:identities.read",
      "auth0": {
        "create": "user:identities:auth0.create"
      }
    },
    "login": {
      "create": "user:login.create"
    },
    "profilePicture": {
      "read": "user:profile-picture.read",
      "update": "user:profile-picture.update",
      "delete": "user:profile-picture.delete"
    },
    "shadowBan": {
      "create": "user:shadow-ban.create",
      "read": "user:shadow-ban.read",
      "delete": "user:shadow-ban.delete"
    }
  },
  "site": {
    "subscriptions": {
      "read": "site:subscriptions.read"
    },
    "subscription": {
      "delete": "site:subscription.delete"
    },
    "notification": {
      "read": "site:notification.read",
      "update": "site:notification.update"
    },
    "notifications": {
      "read": "site:notifications.read"
    },
    "ai": {
      "conversations": {
        "read": "site:ai:conversations.read",
        "create": "site:ai:conversations.create"
      },
      "conversation": {
        "read": "site:ai:conversation.read",
        "update": "site:ai:conversation.update",
        "delete": "site:ai:conversation.delete"
      }
    },
    "analytics": {
      "views": {
        "create": "site:analytics:views.create",
        "read": "site:analytics:views.read"
      }
    },
    "updates": {
      "create": "site:updates.create"
    },
    "jobs": {
      "create": "site:jobs.create",
      "read": "site:jobs.read"
    },
    "job": {
      "read": "site:job.read",
      "update": "site:job.update",
      "delete": "site:job.delete",
      "execute": "site:job.execute"
    },
    "viewerSubscriptionUpdates": {
      "create": "site:viewer-subscription-updates.create"
    },
    "viewerSubscriptionSubscribers": {
      "create": "site:viewer-subscription-subscribers.create",
      "read": "site:viewer-subscription-subscribers.read",
      "export": "site:viewer-subscription-subscribers.export",
      "delete": "site:viewer-subscription-subscribers.delete"
    },
    "viewerSubscriptionSubscriber": {
      "read": "site:viewer-subscription-subscriber.read",
      "delete": "site:viewer-subscription-subscriber.delete",
      "unsubscribe": "site:viewer-subscription-subscriber.unsubscribe",
      "resubscribe": "site:viewer-subscription-subscriber.resubscribe"
    },
    "read": "site.read",
    "update": "site.update",
    "edit": "site.edit",
    "delete": "site.delete",
    "archive": "site.archive",
    "render": "site.render",
    "unarchive": "site.unarchive",
    "generate": "site.generate",
    "settings": {
      "read": "site:settings.read",
      "update": "site:settings.update"
    },
    "tags": {
      "read": "site:tags.read",
      "update": "site:tags.update"
    },
    "domains": {
      "update": "site:domains.update"
    },
    "entitlements": {
      "update": "site:entitlements.update",
      "read": "site:entitlements.read",
      "invalidate": "site:entitlements.invalidate"
    },
    "versions": {
      "read": "site:versions.read"
    },
    "colors": {
      "create": "site:colors.create",
      "read": "site:colors.read"
    },
    "color": {
      "read": "site:color.read",
      "update": "site:color.update",
      "delete": "site:color.delete"
    },
    "template": {
      "apply": "site:template.apply"
    },
    "templateFilters": {
      "read": "site:template-filters.read"
    },
    "invitations": {
      "read": "site:invitations.read"
    },
    "users": {
      "create": "site:users.create",
      "read": "site:users.read"
    },
    "user": {
      "read": "site:user.read",
      "update": "site:user.update",
      "delete": "site:user.delete"
    },
    "marketingIntegration": {
      "read": "site:marketing-integration.read",
      "update": "site:marketing-integration.update",
      "delete": "site:marketing-integration.delete"
    },
    "marketingIntegrations": {
      "create": "site:marketing-integrations.create",
      "read": "site:marketing-integrations.read"
    },
    "shippingRate": {
      "read": "site:shipping-rate.read",
      "update": "site:shipping-rate.update",
      "delete": "site:shipping-rate.delete",
      "activate": "site:shipping-rate.activate"
    },
    "shippingRates": {
      "create": "site:shipping-rates.create",
      "read": "site:shipping-rates.read"
    }
  },
  "data": {
    "tasks": {
      "create": "data:tasks.create",
      "read": "data:tasks.read"
    },
    "task": {
      "read": "data:task.read",
      "update": "data:task.update",
      "delete": "data:task.delete"
    },
    "load": {
      "execute": "data:load.execute"
    }
  },
  "jobs": {
    "create": "jobs.create",
    "read": "jobs.read"
  },
  "job": {
    "read": "job.read",
    "update": "job.update",
    "delete": "job.delete",
    "execute": "job.execute"
  },
  "github": {
    "branches": {
      "read": "github:branches.read"
    },
    "pulls": {
      "read": "github:pulls.read"
    }
  },
  "pipeline": {
    "read": "pipeline.read",
    "update": "pipeline.update",
    "delete": "pipeline.delete",
    "run": {
      "read": "pipeline:run.read",
      "update": "pipeline:run.update",
      "delete": "pipeline:run.delete"
    },
    "runs": {
      "create": "pipeline:runs.create",
      "read": "pipeline:runs.read"
    }
  },
  "pipelines": {
    "create": "pipelines.create",
    "read": "pipelines.read"
  },
  "diagrams": {
    "read": "diagrams.read"
  },
  "domains": {
    "create": "domains.create",
    "read": "domains.read",
    "search": "domains.search",
    "delete": "domains.delete",
    "blockedPhrases": {
      "create": "domains:blocked-phrases.create",
      "read": "domains:blocked-phrases.read"
    },
    "blockedPhrase": {
      "read": "domains:blocked-phrase.read",
      "update": "domains:blocked-phrase.update",
      "delete": "domains:blocked-phrase.delete"
    }
  },
  "domain": {
    "change": "domain.change",
    "check": "domain.check",
    "delete": "domain.delete",
    "disable": "domain.disable",
    "downgrade": "domain.downgrade",
    "enable": "domain.enable",
    "invalidate": "domain.invalidate",
    "migrate": "domain.migrate",
    "read": "domain.read",
    "register": "domain.register",
    "scrub": "domain.scrub",
    "setup": "domain.setup",
    "status": "domain.status",
    "transfer": "domain.transfer",
    "unlink": "domain.unlink",
    "update": "domain.update",
    "validate": "domain.validate",
    "applePay": {
      "add": "domain:apple-pay.add"
    },
    "autoRenew": {
      "enable": "domain:auto-renew.enable",
      "disable": "domain:auto-renew.disable"
    },
    "vercel": {
      "add": "domain:vercel.add",
      "delete": "domain:vercel.delete"
    },
    "certificates": {
      "read": "domain:certificates.read"
    },
    "diagnostics": {
      "read": "domain:diagnostics.read"
    },
    "dnsRecord": {
      "read": "domain:dns-record.read",
      "update": "domain:dns-record.update",
      "delete": "domain:dns-record.delete"
    },
    "dnsRecords": {
      "create": "domain:dns-records.create",
      "read": "domain:dns-records.read",
      "propagation": {
        "read": "domain:dns-records:propagation.read"
      }
    },
    "emailForwards": {
      "create": "domain:email-forwards.create",
      "read": "domain:email-forwards.read"
    },
    "emailForward": {
      "read": "domain:email-forward.read",
      "update": "domain:email-forward.update",
      "delete": "domain:email-forward.delete"
    },
    "mailRecords": {
      "delete": "domain:mail-records.delete"
    },
    "mailDomain": {
      "delete": "domain:mail-domain.delete"
    },
    "instructions": {
      "create": "domain:instructions.create",
      "read": "domain:instructions.read"
    },
    "userManagedDnsRecords": {
      "create": "domain:user-managed-dns-records.create",
      "read": "domain:user-managed-dns-records.read"
    },
    "userManagedDnsRecord": {
      "read": "domain:user-managed-dns-record.read",
      "update": "domain:user-managed-dns-record.update",
      "delete": "domain:user-managed-dns-record.delete",
      "fix": "domain:user-managed-dns-record.fix"
    }
  },
  "dnsimple": {
    "event": {
      "process": "dnsimple:event.process"
    },
    "reports": {
      "read": "dnsimple:reports.read"
    }
  },
  "domainTldPricing": {
    "create": "domain-tld-pricing.create",
    "update": "domain-tld-pricing.update",
    "read": "domain-tld-pricing.read",
    "delete": "domain-tld-pricing.delete"
  },
  "emailForward": {
    "read": "email-forward.read",
    "scrub": "email-forward.scrub",
    "setup": "email-forward.setup",
    "verify": "email-forward.verify"
  },
  "subdomains": {
    "create": "subdomains.create",
    "read": "subdomains.read"
  },
  "subdomain": {
    "check": "subdomain.check",
    "setup": "subdomain.setup",
    "update": "subdomain.update",
    "delete": "subdomain.delete",
    "scrub": "subdomain.scrub",
    "transfer": "subdomain.transfer"
  },
  "email": {
    "send": "email.send"
  },
  "notification": {
    "send": "notification.send",
    "delete": "notification.delete"
  },
  "notifications": {
    "create": "notifications.create",
    "delete": "notifications.delete"
  },
  "pushToken": {
    "register": "push-token.register"
  },
  "slack": {
    "message": {
      "send": "slack:message.send"
    },
    "user": {
      "read": "slack:user.read"
    }
  },
  "archetypes": {
    "create": "archetypes.create",
    "read": "archetypes.read"
  },
  "archetype": {
    "read": "archetype.read",
    "update": "archetype.update",
    "delete": "archetype.delete"
  },
  "categories": {
    "create": "categories.create",
    "read": "categories.read"
  },
  "category": {
    "read": "category.read",
    "update": "category.update",
    "delete": "category.delete",
    "archetypes": {
      "read": "category:archetypes.read",
      "update": "category:archetypes.update"
    }
  },
  "collections": {
    "create": "collections.create",
    "read": "collections.read"
  },
  "collection": {
    "read": "collection.read",
    "update": "collection.update",
    "delete": "collection.delete"
  },
  "codeSnippets": {
    "create": "code-snippets.create",
    "read": "code-snippets.read"
  },
  "codeSnippet": {
    "read": "code-snippet.read",
    "update": "code-snippet.update",
    "delete": "code-snippet.delete"
  },
  "embeds": {
    "read": "embeds.read"
  },
  "pageTypes": {
    "create": "page-types.create",
    "read": "page-types.read"
  },
  "pageType": {
    "read": "page-type.read",
    "update": "page-type.update",
    "delete": "page-type.delete",
    "tags": {
      "read": "page-type:tags.read",
      "update": "page-type:tags.update"
    }
  },
  "tags": {
    "create": "tags.create",
    "read": "tags.read"
  },
  "tag": {
    "read": "tag.read",
    "update": "tag.update",
    "delete": "tag.delete"
  },
  "sites": {
    "create": "sites.create",
    "read": "sites.read",
    "explore": "sites.explore",
    "search": "sites.search",
    "user": {
      "merge": "sites:user.merge"
    }
  },
  "suggestions": {
    "create": "suggestions.create",
    "read": "suggestions.read"
  },
  "suggestion": {
    "read": "suggestion.read",
    "update": "suggestion.update",
    "delete": "suggestion.delete"
  },
  "themes": {
    "create": "themes.create",
    "read": "themes.read"
  },
  "theme": {
    "read": "theme.read",
    "update": "theme.update",
    "delete": "theme.delete",
    "adminEdit": "theme.adminEdit",
    "tags": {
      "read": "theme:tags.read",
      "update": "theme:tags.update"
    }
  },
  "templateFilters": {
    "create": "template-filters.create",
    "read": "template-filters.read"
  },
  "templateFilter": {
    "read": "template-filter.read",
    "update": "template-filter.update",
    "delete": "template-filter.delete"
  },
  "templates": {
    "create": "templates.create",
    "read": "templates.read",
    "setupFlows": {
      "read": "templates:setup-flows.read"
    }
  },
  "template": {
    "read": "template.read",
    "update": "template.update",
    "delete": "template.delete",
    "tags": {
      "read": "template:tags.read",
      "update": "template:tags.update"
    }
  },
  "verse": {
    "migrations": {
      "create": "verse:migrations.create",
      "read": "verse:migrations.read"
    },
    "migration": {
      "read": "verse:migration.read",
      "update": "verse:migration.update"
    }
  },
  "bug": {
    "read": "bug.read",
    "update": "bug.update",
    "resolve": "bug.resolve",
    "unresolve": "bug.unresolve",
    "delete": "bug.delete",
    "report": {
      "read": "bug:report.read",
      "update": "bug:report.update",
      "delete": "bug:report.delete"
    },
    "reports": {
      "create": "bug:reports.create",
      "read": "bug:reports.read"
    },
    "sighting": {
      "read": "bug:sighting.read",
      "delete": "bug:sighting.delete"
    },
    "sightings": {
      "create": "bug:sightings.create",
      "read": "bug:sightings.read"
    }
  },
  "bugs": {
    "create": "bugs.create",
    "read": "bugs.read"
  },
  "escalations": {
    "create": "escalations.create",
    "read": "escalations.read"
  },
  "escalation": {
    "read": "escalation.read",
    "update": "escalation.update",
    "delete": "escalation.delete"
  },
  "help": {
    "read": "help.read",
    "contents": {
      "create": "help:contents.create",
      "read": "help:contents.read"
    },
    "content": {
      "read": "help:content.read",
      "update": "help:content.update",
      "delete": "help:content.delete"
    },
    "sections": {
      "create": "help:sections.create",
      "read": "help:sections.read"
    },
    "section": {
      "read": "help:section.read",
      "update": "help:section.update",
      "delete": "help:section.delete"
    }
  },
  "zendesk": {
    "tickets": {
      "create": "zendesk:tickets.create",
      "read": "zendesk:tickets.read"
    },
    "articles": {
      "read": "zendesk:articles.read"
    },
    "events": {
      "create": "zendesk:events.create",
      "read": "zendesk:events.read"
    },
    "event": {
      "read": "zendesk:event.read",
      "update": "zendesk:event.update",
      "delete": "zendesk:event.delete"
    }
  },
  "support": {
    "errors": {
      "create": "support:errors.create",
      "read": "support:errors.read"
    }
  },
  "cache": {
    "web": {
      "purge": "cache:web.purge"
    },
    "images": {
      "purge": "cache:images.purge"
    }
  },
  "deadletterEvents": {
    "create": "deadletter-events.create",
    "read": "deadletter-events.read"
  },
  "deadletterEvent": {
    "read": "deadletter-event.read",
    "update": "deadletter-event.update",
    "delete": "deadletter-event.delete",
    "retry": "deadletter-event.retry"
  },
  "events": {
    "create": "events.create",
    "read": "events.read"
  },
  "event": {
    "read": "event.read",
    "update": "event.update",
    "delete": "event.delete"
  },
  "employees": {
    "create": "employees.create",
    "read": "employees.read"
  },
  "employee": {
    "read": "employee.read",
    "update": "employee.update",
    "delete": "employee.delete"
  },
  "identity": {
    "read": "identity.read",
    "update": "identity.update",
    "delete": "identity.delete"
  },
  "invitations": {
    "create": "invitations.create"
  },
  "invitation": {
    "accept": "invitation.accept"
  },
  "provider": {
    "users": {
      "read": "provider:users.read"
    }
  },
  "users": {
    "create": "users.create",
    "read": "users.read",
    "search": "users.search"
  },
  "merchants": {
    "create": "merchants.create",
    "read": "merchants.read"
  },
  "merchant": {
    "read": "merchant.read",
    "update": "merchant.update",
    "reject": "merchant.reject",
    "unreject": "merchant.unreject",
    "validate": "merchant.validate",
    "delete": "merchant.delete",
    "details": {
      "read": "merchant:details.read"
    },
    "disputes": {
      "read": "merchant:disputes.read"
    },
    "addresses": {
      "create": "merchant:addresses.create"
    },
    "balance": {
      "read": "merchant:balance.read",
      "history": "merchant:balance.history"
    },
    "banks": {
      "create": "merchant:banks.create"
    },
    "bank": {
      "update": "merchant:bank.update",
      "delete": "merchant:bank.delete"
    },
    "betaCredits": {
      "create": "merchant:beta-credits.create"
    },
    "capability": {
      "requirements": {
        "read": "merchant:capability:requirements.read",
        "enable": "merchant:capability:requirements.enable"
      }
    },
    "credits": {
      "create": "merchant:credits.create",
      "read": "merchant:credits.read"
    },
    "debits": {
      "create": "merchant:debits.create"
    },
    "documents": {
      "upload": "merchant:documents.upload"
    },
    "domain": {
      "update": "merchant:domain.update"
    },
    "payouts": {
      "history": "merchant:payouts.history"
    },
    "payoutSchedule": {
      "read": "merchant:payout-schedule.read",
      "update": "merchant:payout-schedule.update"
    },
    "stripeAccountLink": {
      "create": "merchant:stripe-account-link.create"
    },
    "stripePersons": {
      "create": "merchant:stripe-persons.create",
      "read": "merchant:stripe-persons.read"
    },
    "stripePerson": {
      "read": "merchant:stripe-person.read",
      "update": "merchant:stripe-person.update",
      "delete": "merchant:stripe-person.delete"
    },
    "transaction": {
      "complete": "merchant:transaction.complete"
    },
    "shipments": {
      "create": "merchant:shipments.create",
      "read": "merchant:shipments.read"
    },
    "shipment": {
      "read": "merchant:shipment.read",
      "void": "merchant:shipment.void"
    }
  },
  "notes": {
    "create": "notes.create",
    "read": "notes.read"
  },
  "note": {
    "read": "note.read",
    "update": "note.update",
    "delete": "note.delete"
  },
  "discounts": {
    "create": "discounts.create",
    "read": "discounts.read"
  },
  "discount": {
    "read": "discount.read",
    "update": "discount.update",
    "delete": "discount.delete",
    "code": {
      "read": "discount:code.read"
    }
  },
  "disputes": {
    "create": "disputes.create",
    "read": "disputes.read"
  },
  "dispute": {
    "read": "dispute.read",
    "update": "dispute.update",
    "close": "dispute.close",
    "charge": "dispute.charge",
    "refund": "dispute.refund",
    "evidence": {
      "submit": "dispute:evidence.submit"
    }
  },
  "orders": {
    "create": "orders.create",
    "read": "orders.read",
    "export": "orders.export",
    "query": "orders.query",
    "bulkRefund": "orders.bulk-refund"
  },
  "order": {
    "read": "order.read",
    "update": "order.update",
    "delete": "order.delete",
    "refund": "order.refund",
    "dispute": {
      "status": {
        "update": "order:dispute:status.update"
      }
    },
    "packages": {
      "create": "order:packages.create",
      "read": "order:packages.read"
    },
    "status": {
      "update": "order:status.update"
    }
  },
  "lineItem": {
    "update": "line-item.update"
  },
  "packages": {
    "read": "packages.read"
  },
  "package": {
    "read": "package.read",
    "update": "package.update",
    "rates": {
      "quote": "package:rates.quote"
    },
    "shipment": {
      "read": "package:shipment.read",
      "update": "package:shipment.update",
      "delete": "package:shipment.delete",
      "status": {
        "update": "package:shipment:status.update"
      }
    },
    "tracking": {
      "read": "package:tracking.read"
    }
  },
  "paymentIntegration": {
    "delete": "payment-integration.delete",
    "update": "payment-integration.update",
    "read": "payment-integration.read",
    "transactions": {
      "create": "payment-integration:transactions.create"
    }
  },
  "paymentIntegrations": {
    "read": "payment-integrations.read",
    "create": "payment-integrations.create",
    "paypal": {
      "read": "payment-integrations:paypal.read",
      "connectedPath": {
        "create": "payment-integrations:paypal:connected-path.create"
      },
      "orders": {
        "capture": "payment-integrations:paypal:orders.capture",
        "create": "payment-integrations:paypal:orders.create"
      },
      "sellerStatus": {
        "read": "payment-integrations:paypal:seller-status.read"
      }
    }
  },
  "countries": {
    "read": "countries.read"
  },
  "products": {
    "create": "products.create",
    "read": "products.read"
  },
  "product": {
    "read": "product.read",
    "update": "product.update",
    "delete": "product.delete",
    "options": {
      "create": "product:options.create",
      "read": "product:options.read"
    },
    "option": {
      "read": "product:option.read",
      "update": "product:option.update",
      "delete": "product:option.delete"
    },
    "units": {
      "read": "product:units.read"
    },
    "unit": {
      "read": "product:unit.read",
      "update": "product:unit.update"
    },
    "variations": {
      "create": "product:variations.create",
      "read": "product:variations.read"
    },
    "variation": {
      "read": "product:variation.read",
      "update": "product:variation.update",
      "delete": "product:variation.delete"
    }
  },
  "addresses": {
    "create": "addresses.create",
    "read": "addresses.read"
  },
  "address": {
    "validate": "address.validate",
    "read": "address.read",
    "update": "address.update",
    "delete": "address.delete"
  },
  "rates": {
    "create": "rates.create"
  },
  "shipments": {
    "read": "shipments.read"
  },
  "shipment": {
    "create": "shipment.create",
    "void": "shipment.void",
    "status": {
      "update": "shipment:status.update"
    },
    "tracking": {
      "read": "shipment:tracking.read",
      "start": "shipment:tracking.start"
    }
  },
  "transactions": {
    "create": "transactions.create",
    "read": "transactions.read",
    "fees": {
      "read": "transactions:fees.read"
    }
  },
  "transaction": {
    "read": "transaction.read",
    "delete": "transaction.delete",
    "refund": "transaction.refund",
    "stripeStatus": {
      "update": "transaction:stripe-status.update"
    }
  },
  "assets": {
    "create": "assets.create",
    "read": "assets.read"
  },
  "asset": {
    "read": "asset.read",
    "update": "asset.update",
    "delete": "asset.delete"
  },
  "downloads": {
    "create": "downloads.create",
    "read": "downloads.read"
  },
  "download": {
    "read": "download.read",
    "update": "download.update",
    "delete": "download.delete",
    "codes": {
      "create": "download:codes.create"
    },
    "code": {
      "read": "download:code.read",
      "update": "download:code.update"
    },
    "versions": {
      "create": "download:versions.create"
    },
    "version": {
      "delete": "download:version.delete"
    }
  },
  "files": {
    "create": "files.create",
    "read": "files.read"
  },
  "file": {
    "read": "file.read",
    "update": "file.update",
    "delete": "file.delete"
  },
  "images": {
    "search": "images.search",
    "sources": {
      "read": "images:sources.read"
    }
  },
  "videos": {
    "create": "videos.create",
    "read": "videos.read"
  },
  "video": {
    "read": "video.read",
    "update": "video.update",
    "delete": "video.delete"
  },
  "remoteConfig": {
    "defaultValues": {
      "create": "remote-config:default-values.create",
      "read": "remote-config:default-values.read"
    },
    "defaultValue": {
      "read": "remote-config:default-value.read",
      "update": "remote-config:default-value.update",
      "delete": "remote-config:default-value.delete"
    },
    "userValues": {
      "create": "remote-config:user-values.create",
      "read": "remote-config:user-values.read",
      "link": "remote-config:user-values.link"
    },
    "experiments": {
      "create": "remote-config:experiments.create",
      "read": "remote-config:experiments.read"
    },
    "experiment": {
      "read": "remote-config:experiment.read",
      "update": "remote-config:experiment.update",
      "delete": "remote-config:experiment.delete"
    },
    "values": {
      "create": "remote-config:values.create",
      "read": "remote-config:values.read"
    },
    "value": {
      "read": "remote-config:value.read",
      "update": "remote-config:value.update",
      "delete": "remote-config:value.delete"
    },
    "variants": {
      "create": "remote-config:variants.create",
      "read": "remote-config:variants.read"
    },
    "variant": {
      "read": "remote-config:variant.read",
      "update": "remote-config:variant.update",
      "delete": "remote-config:variant.delete"
    },
    "userGroups": {
      "create": "remote-config:user-groups.create",
      "read": "remote-config:user-groups.read"
    },
    "userGroup": {
      "read": "remote-config:user-group.read",
      "update": "remote-config:user-group.update",
      "delete": "remote-config:user-group.delete"
    }
  },
  "snapshot": {
    "generate": "snapshot.generate"
  },
  "yeet": {
    "noContext": {
      "images": {
        "create": "yeet:no-context:images.create",
        "read": "yeet:no-context:images.read"
      },
      "image": {
        "read": "yeet:no-context:image.read",
        "update": "yeet:no-context:image.update"
      },
      "quote": {
        "create": "yeet:no-context:quote.create"
      },
      "page": {
        "create": "yeet:no-context:page.create"
      }
    }
  },
  "blocks": {
    "create": "blocks.create",
    "read": "blocks.read"
  },
  "block": {
    "read": "block.read",
    "update": "block.update",
    "delete": "block.delete"
  },
  "blockTemplates": {
    "create": "block-templates.create",
    "read": "block-templates.read"
  },
  "blockTemplate": {
    "read": "block-template.read",
    "update": "block-template.update",
    "delete": "block-template.delete",
    "tags": {
      "read": "block-template:tags.read",
      "update": "block-template:tags.update"
    }
  },
  "blockTemplateBlocks": {
    "create": "block-template-blocks.create",
    "read": "block-template-blocks.read"
  },
  "blockTemplateBlock": {
    "read": "block-template-block.read",
    "update": "block-template-block.update",
    "delete": "block-template-block.delete"
  },
  "pageSectionTypes": {
    "create": "page-section-types.create",
    "read": "page-section-types.read"
  },
  "pageSectionType": {
    "read": "page-section-type.read",
    "update": "page-section-type.update",
    "delete": "page-section-type.delete"
  },
  "textGenerationActions": {
    "create": "text-generation-actions.create",
    "read": "text-generation-actions.read"
  },
  "textGenerationAction": {
    "read": "text-generation-action.read",
    "update": "text-generation-action.update",
    "delete": "text-generation-action.delete",
    "execute": "text-generation-action.execute"
  }
}
