import { USERGROUPS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UserGroup, PostUserGroup } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateUserGroup = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      id: number,
      body: PostUserGroup,
      onSuccess?: () => any,
    ): Promise<UserGroup> => {
      const response = await makeRequest<UserGroup>(
        `${USERGROUPS}/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateUserGroup: performAction, loading, error }
}

export default useUpdateUserGroup
