import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TopicCount } from "../../../types"
import { DEADLETTER } from "../../../constants/urlDefs"

const useGetDeadletterTopicCount = () => {
  const { data, error, loading, performAction } = useHandleRequest(
    (handleUnauthenticated): Promise<TopicCount[]> => {
      return makeRequest<TopicCount[]>(`${DEADLETTER}/count`, {}, { handleUnauthenticated })
    },
  )

  return { getDeadLetterTopicCount: performAction, loading, error, topics: data }
}

export default useGetDeadletterTopicCount
