import { Button, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { BlockedDomainPhrases } from "../../../types"
import useCreateBlockedDomainPhrase from "../hooks/useCreateBlockedDomainPhrase"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => void
  blockedDomainPhrase?: BlockedDomainPhrases | undefined
}

const BlockedDomainPhraseDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  blockedDomainPhrase,
}) => {
  const { createBlockedDomainPhrase, loading, error } = useCreateBlockedDomainPhrase()

  const validationSchema = Yup.object().shape({
    phrase: Yup.string().required(),
    type: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      phrase: "",
      type: "contains",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      await createBlockedDomainPhrase(values.phrase, values.type, handleCreateBlockSuccess)
    },
  })

  const handleCreateBlockSuccess: any = async () => {
    toast.success("Successfully created blocked domain phrase")
    close()
  }

  useEffect(() => {
    if (blockedDomainPhrase) {
      formik.setValues({
        phrase: blockedDomainPhrase.phrase,
        type: blockedDomainPhrase.type,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={
        !!blockedDomainPhrase
          ? `Update ${blockedDomainPhrase.phrase}`
          : "Create Blocked Domain Phrase"
      }
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextBody>
            <b>Match: </b> The domain must exactly match the phrase
          </TextBody>
          <TextBody>
            <b>Contains: </b> The phrase can be located anywhere in the domain
          </TextBody>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="phrase"
              name="phrase"
              label="Phrase"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.phrase}
              error={!!formik.errors.phrase}
            />
            <RadioGroup name="type" value={formik.values.type} onChange={formik.handleChange}>
              <FormControlLabel value="contains" control={<Radio />} label="Contains" />
              <FormControlLabel value="match" control={<Radio />} label="Match" />
            </RadioGroup>
          </div>
        </>
      }
      buttonContent={
        <>
          {blockedDomainPhrase ? (
            <Button color="error" variant="contained">
              Delete
            </Button>
          ) : null}
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            {!!blockedDomainPhrase
              ? "Update Blocked Domain Phrase"
              : "Create Blocked Domain Phrase"}
          </Button>
        </>
      }
    />
  )
}

export default BlockedDomainPhraseDialog
