import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent, useCallback, useEffect } from "react"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import roles from "../../../roles"
import { Employee, PostEmployee } from "../../../types"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"

interface Props {
  isOpen: boolean
  close: () => void
  handleCreate: (employee: PostEmployee, callback: () => any) => any
  handleUpdate: (employee: Employee, callback: () => any) => any
  hasError: boolean
  loading: boolean
  employeeUpdate?: Employee | undefined
}

const EmployeeDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  handleCreate,
  handleUpdate,
  hasError,
  loading,
  employeeUpdate,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    userOption: Yup.object().optional(),
    roles: Yup.array().of(Yup.string()),
    github_username: Yup.string().optional(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      roles: [] as string[],
      userOption: "",
      github_username: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!employeeUpdate) {
        await handleUpdate({ ...employeeUpdate, ...values }, handleClose)
      } else {
        await handleCreate(
          {
            name: values.name,
            email: values.email,
            roles: values.roles,
            github_username: values.github_username === "" ? null : values.github_username,
            user_id: values.userOption?.user?.id ?? null,
          },
          handleClose,
        )
      }
    },
  })

  const handleChecked = useCallback(
    async (event: any) => {
      const vals: string[] = [...formik.values.roles]
      const clickedVal = event.target.name
      const valIndex = vals.indexOf(clickedVal)

      if (valIndex > -1) {
        vals.splice(valIndex, 1)
      } else {
        vals.push(clickedVal)
      }

      formik.setFieldValue("roles", vals)
    },
    [formik],
  )

  const getRolesList = useCallback(() => {
    const rolesList = []
    var i,
      j,
      temporary,
      chunk = 3
    for (i = 0, j = roles.length; i < j; i += chunk) {
      const userRoles = roles.filter((x) => {
        return (
          !x.includes("-worker") &&
          !x.includes("-service") &&
          !x.includes("-api") &&
          !x.includes("-gateway") &&
          !x.includes("-client") &&
          !x.includes("guest") &&
          !x.includes("system") &&
          !x.includes("owner") &&
          !x.includes("member") &&
          !x.includes("creator") &&
          !x.includes("visitor") &&
          !x.includes("web-app")
        )
      })
      userRoles.push("template-creator")
      temporary = userRoles.slice(i, i + chunk)
      rolesList.push(
        <tr key={i}>
          {temporary.map((role) => (
            <td key={role}>
              <FormControlLabel
                control={<Checkbox />}
                label={role}
                name={role}
                value={!!formik.values.roles.includes(role)}
                onChange={handleChecked}
                checked={!!formik.values.roles.includes(role)}
              />
            </td>
          ))}
        </tr>,
      )
    }

    return rolesList
  }, [handleChecked, formik.values.roles])

  useEffect(() => {
    if (employeeUpdate) {
      formik.setValues({
        email: employeeUpdate.email,
        name: employeeUpdate.name,
        roles: employeeUpdate.roles,
        userOption: "",
        github_username: employeeUpdate.github_username,
      })
    }

    // eslint-disable-next-line
  }, [employeeUpdate])

  return (
    <Dialog
      title={!!employeeUpdate ? `Update Employee ${employeeUpdate.id}` : "New Employee"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {hasError && (
            <TextBody hasError={hasError}>{`There was a problem ${
              !!employeeUpdate ? "updating" : "creating"
            } the employee.`}</TextBody>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            required
            onChange={formik.handleChange}
            value={formik.values.name}
            error={!!formik.errors.name}
          />
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            required
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
          />
          <TextField
            margin="dense"
            id="github_username"
            name="github_username"
            label="Github Username"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.github_username}
            error={!!formik.errors.github_username}
          />
          {!employeeUpdate ? (
            <FormikProvider value={formik}>
              <UserSearchAutocomplete />
            </FormikProvider>
          ) : null}
          <FormGroup>
            <FormLabel component="legend">Roles</FormLabel>
            <table>
              <tbody>{getRolesList()}</tbody>
            </table>
          </FormGroup>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>

          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            {!!employeeUpdate ? "Update Employee" : "Create Employee"}
          </Button>
        </>
      }
    />
  )
}

export default EmployeeDialog
