import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { BillingProduct } from "../../../types"
import ProductRow from "./ProductRow"

interface Props {
  products: BillingProduct[]
  paginationData: PaginationData
  handleOpenEditProduct: (product: BillingProduct) => any
  handleOpenEditEntitlements: (product: BillingProduct) => any
  canEdit: boolean
  canEditEntitlements: boolean
}

const ProductsTable: FunctionComponent<Props> = ({
  products,
  paginationData,
  handleOpenEditProduct,
  handleOpenEditEntitlements,
  canEdit,
  canEditEntitlements,
}) => {
  const getColumns = useCallback(() => {
    const columns = ["Name", "Description", "Active"]
    if (canEdit) {
      columns.push("Edit Product")
    }
    if (canEditEntitlements) {
      columns.push("Edit Entitlements")
    }
    return columns
  }, [canEdit, canEditEntitlements])

  const getProductRows = useCallback(() => {
    return products.map((product: BillingProduct, index: number) => (
      <ProductRow
        key={index}
        product={product}
        handleOpenEditProduct={handleOpenEditProduct}
        handleOpenEditEntitlements={handleOpenEditEntitlements}
        canEdit={canEdit}
        canEditEntitlements={canEditEntitlements}
      />
    ))
  }, [products, handleOpenEditEntitlements, handleOpenEditProduct, canEdit, canEditEntitlements])

  return (
    <BasicTable columns={getColumns()} rows={getProductRows()} paginationOptions={paginationData} />
  )
}

export default ProductsTable
