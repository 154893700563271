import { ORDERSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { OrderDetail } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetOrderById = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, orderId: number): Promise<OrderDetail> => {
    return makeRequest<OrderDetail>(`${ORDERSV3}/${orderId}`, {}, { handleUnauthenticated })
  })

  return { loading, error, getOrderById: performAction, order: data }
}

export default useGetOrderById
