import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { BlockTemplate, PageSectionType } from "../../../types"
import { Button, Switch } from "@mui/material"
import { formatDate } from "../../../utils/formatters"
import BlockTemplateDialog from "./BlockTemplateDialog"
import useUpdateBlockTemplate from "../hooks/useUpdateBlockTemplate"
import { LoadingButton } from "@mui/lab"
import useBlockTemplateTagDialog from "./BlockTemplateTagDialog"

type BlockTemplateRowProps = {
  blockTemplate: BlockTemplate
  canEdit: boolean
  pageSectionTypes: PageSectionType[]
  getBlockTemplates: () => any
}

const BlockTemplateRow: FunctionComponent<BlockTemplateRowProps> = ({
  blockTemplate,
  canEdit,
  pageSectionTypes,
  getBlockTemplates,
}) => {
  const { openBlockTemplateTagDialog, blockTemplateTagDialogLoading, blockTemplateTagDialog } =
    useBlockTemplateTagDialog()
  const [open, setOpen] = useState(false)

  const { updateBlockTemplate } = useUpdateBlockTemplate()
  // const [fullBody, setFullBody] = useState(false)

  // const body = JSON.stringify(blockTemplate.field_mapping)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getBlockTemplates()
  }

  const pageSectionTypeMap = pageSectionTypes.reduce((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {} as Record<number, PageSectionType>)

  return (
    <BasicTableRow key={blockTemplate.id}>
      <BasicTableCell component="th" scope="row">
        {blockTemplate.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {blockTemplate.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {pageSectionTypeMap[blockTemplate.page_section_type_id]?.name} (
        {blockTemplate.page_section_type_id})
      </BasicTableCell>
      <BasicTableCell>{blockTemplate.template_group}</BasicTableCell>
      {/* <BasicTableCell style={{ overflow: "auto", maxWidth: 400 }}>
        <Button onClick={(x) => setFullBody(!fullBody)}>...</Button>
        <pre style={{ whiteSpace: "pre-wrap" }}>{body}</pre>
      </BasicTableCell> */}
      <BasicTableCell component="th" scope="row">
        {formatDate(new Date(blockTemplate.created_at))}
      </BasicTableCell>
      {canEdit ? (
        <>
          <BasicTableCell>
            <Switch
              checked={!blockTemplate.deleted_at}
              onChange={(evt) =>
                updateBlockTemplate(
                  blockTemplate.id,
                  {
                    enabled: evt.target.checked,
                  },
                  getBlockTemplates,
                )
              }
            />
          </BasicTableCell>
          <BasicTableCell component="th" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
          <BasicTableCell>
            <LoadingButton
              loading={blockTemplateTagDialogLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => openBlockTemplateTagDialog(blockTemplate)}
            >
              Tags
            </LoadingButton>

            {blockTemplateTagDialog}
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <BlockTemplateDialog
          isOpen={open}
          close={handleClose}
          blockTemplateId={blockTemplate.id}
          pageSectionTypes={pageSectionTypes}
        />
      ) : null}
    </BasicTableRow>
  )
}

export default BlockTemplateRow
