import dayjs from "dayjs"
import  { FunctionComponent } from "react"
import { PromoRedemption } from "../../../../types"
import "./RedemptionCard.css"

type RedemptionCardProps = {
  redemption: PromoRedemption
}

const RedemptionCard: FunctionComponent<RedemptionCardProps> = ({ redemption }) => (
  <li className="redemption-card">
    {redemption.site && (
      <p>
        <span className="user-details-site-card-content">
          <b>Site: </b>
        </span>
        <a className="redemption-card-content" href={`/sites/${redemption.site.id}`}>
          {redemption.site.title || redemption.site.urls[0]}
        </a>
        {" - "} {dayjs(redemption.created_at).format("MM/DD/YYYY")}
      </p>
    )}
  </li>
)

export default RedemptionCard
