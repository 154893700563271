import { FunctionComponent, useMemo } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Package } from "../../../types"
import PackageRow from "./PackageRow"

interface Props {
  packages: Package[]
  voidShippingLabel: any
  merchantId: number
}

const PackageTable: FunctionComponent<Props> = ({ packages, voidShippingLabel, merchantId }) => {
  const columns = [
    "ID",
    "Order ID",
    "Shipment ID",
    "Shipment Status",
    "Label Cost",
    "Carrier",
    "Product IDs",
    "Action",
  ]

  const serializedPackages = JSON.stringify(packages)
  const getPackageRows = useMemo(
    () =>
      JSON.parse(serializedPackages).map((data: Package, index: number) => (
        <PackageRow
          key={index}
          data={data}
          voidShippingLabel={voidShippingLabel}
          merchantId={merchantId}
        />
      )),
    [serializedPackages, voidShippingLabel, merchantId],
  )

  return <BasicTable columns={columns} rows={getPackageRows} />
}

export default PackageTable
