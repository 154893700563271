import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import  { FunctionComponent, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import CopyIcon from "../../../components/shared/CopyIcon"
import { OrderStub } from "../../../types"
import { formatPrice } from "../../../utils/formatters"
import SiteLink from "../../Sites/components/SiteLink"
interface OrderRowProps {
  order: OrderStub
}

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: 5px;
`

const OrderRow: FunctionComponent<OrderRowProps> = ({ order }) => {
  const navigate = useNavigate()

  const handleNavToOrder = useCallback(() => navigate(`/orders/${order.id}`), [order, navigate])

  return (
    <BasicTableRow sx={{ width: "100%" }}>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
          minWidth: "75px",
        }}
      >
        {order.id}
        <StyledCopyIcon content={order.id} />
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
          minWidth: "75px",
        }}
      >
        {order.transaction_id}
        <StyledCopyIcon content={order.transaction_id} />
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "150px",
          minWidth: "150px",
        }}
      >
        {order.stripe_payment_intent_id}
        <StyledCopyIcon content={order.stripe_payment_intent_id} />
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        <SiteLink siteId={order.site_id}>{order.site_id}</SiteLink>
        <StyledCopyIcon content={order.site_id} />
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        {order.status}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        {formatPrice(order.price)}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        {order.buyer_email}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        {order.buyer_name}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        {order.merchant_email}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        <>{order.created_at}</>
      </BasicTableCell>
      <BasicTableCell
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "100px",
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleNavToOrder}>
          View
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default OrderRow
