import { FunctionComponent, useState } from "react"

import { Button, CircularProgress } from "@mui/material"
import useCreateAsset from "../../hooks/useCreateAsset"
import makeRequest from "../../utils/make-request"
import { Asset } from "../../types"
import { Image } from "@mui/icons-material"

export interface FileUploaderProps {
  siteId?: number
  onAssetUpload?: (asset: Asset) => void
  uploadStatus?: (status: string) => void
}

const FileUploader: FunctionComponent<FileUploaderProps> = ({
  siteId = 0,
  onAssetUpload,
  uploadStatus,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)

  const { createAsset, error: assetError } = useCreateAsset()

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
      await handleUpload(event.target.files[0])
    }
  }

  const handleUpload = async (selectedFile: File) => {
    try {
      setLoading(true)
      uploadStatus && uploadStatus(`Uploading ${selectedFile.name}...`)
      const asset = await createAsset(siteId, {
        original_filename: selectedFile.name,
        file_type: selectedFile.name.split(".").pop() || selectedFile.type.split("/")[1],
        file_size_bytes: selectedFile.size,
      })

      if (asset) {
        const { presigned_s3_upload_url } = asset
        let response

        try {
          response = await makeRequest<Response>(presigned_s3_upload_url, {
            method: "PUT",
            body: selectedFile,
            headers: {
              "Content-Type": selectedFile.type,
              "Content-Disposition": "inline",
              "Cache-Control": "max-age=2592000",
            },
          })
        } catch (err: unknown) {
          try {
            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString((err as Error).message, "text/xml")

            const code = xmlDoc.querySelectorAll("Code")
            const message = xmlDoc.querySelectorAll("Message")

            uploadStatus && uploadStatus(`${code[0].textContent}: ${message[0].textContent}`)
          } catch (error: unknown) {
            uploadStatus && uploadStatus((error as Error).toString())
          }
          return
        }

        if (onAssetUpload) {
          onAssetUpload(asset)
        }
        console.log("Upload successful:", response.json)
        uploadStatus && uploadStatus("")
      }
    } catch (error: unknown) {
      console.error("Error uploading file:", error)
      uploadStatus && uploadStatus((error as Error).toString())
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <input
        type="file"
        accept="image/*,.svg"
        style={{ display: "none" }}
        onChange={handleFileChange}
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button variant="outlined" component="span">
          {loading ? <CircularProgress size={24} /> : <Image />}
        </Button>
      </label>
    </div>
  )
}

export default FileUploader
