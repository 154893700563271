import { Box } from "@mui/material"
import { useState, FunctionComponent, useEffect } from "react"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"

import D2Toolbar from "./components/Toolbar"
import D2Map from "./components/Map"
import useDiagram from "./hooks/useDiagram"
import { DiagramTypeT } from "./types"

const D2Diagrams: FunctionComponent = () => {
  const [type, setType] = useState<DiagramTypeT>("internal")
  const [services, setServices] = useState<string[]>([])

  const { performAction, data, error, loading } = useDiagram(type, services)

  useEffect(() => {
    performAction()
  }, [])

  let component = <D2Map data={data} />

  if (loading) component = <h3>Loading Diagram...</h3>

  if (error)
    component = (
      <h3 style={{ padding: "2rem", backgroundColor: "red", color: "white" }}>{error.message}</h3>
    )

  return (
    <ScreenContainer title="Service Connection Diagram">
      <Box style={{ height: "1rem" }} />
      <D2Toolbar
        type={type}
        setType={setType}
        services={services}
        setServices={setServices}
        loading={loading}
        handleClick={performAction}
      />
      <Box style={{ height: "1rem" }} />
      {component}
    </ScreenContainer>
  )
}

export default D2Diagrams
