import { useCallback, useState } from "react"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { User } from "../../../types"
import { Box, Button, TextField, Typography } from "@mui/material"
import { FormikHelpers, useFormik } from "formik"
import { LoadingButton } from "@mui/lab"
import * as Yup from "yup"
import useMergeUser from "../hooks/useMergeUser"
import { toast } from "react-hot-toast"

interface MergeUserForm {
  destroy_user_id: string
}

const schema = Yup.object().shape({
  destroy_user_id: Yup.number().required("Required"),
})

const useMergeUserDialog = () => {
  const [user, setUser] = useState<User>()

  const close = () => setUser(undefined)

  const { mergeUser } = useMergeUser()

  const formik = useFormik({
    initialValues: {
      destroy_user_id: "" as any,
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: useCallback(
      async (values: MergeUserForm, { setSubmitting }: FormikHelpers<MergeUserForm>) => {
        if (!user) {
          return
        }

        const userIdToDestroy = parseInt(values.destroy_user_id)

        console.log(`Merging user ${userIdToDestroy} into user ${user.id}`)

        setSubmitting(true)

        await mergeUser(user.id, userIdToDestroy, () => {
          toast.success(`Successfully merged user ${userIdToDestroy} into user ${user.id}`)
          close()
        })

        setSubmitting(false)
      },
      [user, mergeUser],
    ),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const dialog = user ? (
    <Dialog
      isOpen={true}
      title={`Merge User into User ${user.id}`}
      handleClose={handleClose}
      content={
        <Box width="400px">
          {formik.isSubmitting}
          <Row>
            <TextField
              margin="normal"
              id="destroy_user_id"
              name="destroy_user_id"
              label="User to be Merged"
              helperText={
                (formik.errors.destroy_user_id as string) || "This user will be DESTROYED"
              }
              type="integer"
              fullWidth
              required
              variant="outlined"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              value={formik.values.destroy_user_id}
              error={!!formik.errors.destroy_user_id}
            />
          </Row>
          <Row>
            <Typography variant="caption" color="error">
              The user entered in the text box will be deleted and all of its data will be moved
              over to user {user.id}. This is a destructive action. This action is not reversible.
            </Typography>
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            loading={formik.isSubmitting}
          >
            Merge
          </LoadingButton>
        </>
      }
    />
  ) : null

  return {
    mergeUserDialog: dialog,
    openMergeUserDialog: (user: User) => setUser(user),
  }
}

export default useMergeUserDialog
