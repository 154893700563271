import { Autocomplete, Box, TextField } from "@mui/material"
import { useField } from "formik"
import  { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Caption1, TextBody } from "../../../components/shared/Typography"
import useDebounce from "../../../hooks/useDebounce"
import { Domain, DomainTypeEnum, Subdomain } from "../../../types"
import useGetDomainsAndSubdomains, {
  DomainSubdomainParamTypeEnum,
} from "../hooks/useGetDomainsAndSubdomains"

interface DomainOption {
  label: string
  domain: Domain | Subdomain
}

const DomainSearchAutocomplete: FunctionComponent = () => {
  const [domainOptions, setDomainOptions] = useState<DomainOption[]>([])
  const [inputValue, setInputValue] = useState<string>("")
  // eslint-disable-next-line
  const [field, meta, helpers] = useField("domainOption")

  const { getDomainsAndSubdomains, loading } = useGetDomainsAndSubdomains()

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const renderInput = useCallback((params: any) => {
    return <TextField {...params} label="Search Destination Domain by URL" variant="outlined" />
  }, [])

  const handleValueChange = useCallback(
    (_: any, newValue: DomainOption | null) => {
      if (newValue?.domain) {
        helpers.setValue(newValue)
        helpers.setError(undefined)
      } else {
        helpers.setValue("")
      }
    },
    [helpers],
  )

  const handleChangeInput = useCallback(
    (_: any, newInputValue: string) => {
      if (!newInputValue) {
        helpers.setValue("")
      }
      setInputValue(newInputValue)
    },
    [helpers],
  )

  const getLabelFromDomainSubdomain = useCallback((domain: any) => {
    let label
    if (domain.hasOwnProperty("type") && domain?.type === DomainTypeEnum.Subdomain) {
      label = `Subdomain: ${domain.id} - ${domain.subdomain}`
    } else {
      label = `Domain: ${domain.id} - ${domain.url}`
    }
    if (domain.site_id) {
      label += ` - current site: ${domain.site_id}`
    }
    return label || ""
  }, [])

  const handleSearchDomains = useCallback(
    async (debouncedSearchTerm: string) => {
      if (!debouncedSearchTerm) {
        return setDomainOptions([])
      }
      const domains = await getDomainsAndSubdomains(
        DomainSubdomainParamTypeEnum.URL,
        debouncedSearchTerm,
      )
      const domainOptions: DomainOption[] = (domains || []).map((domain) => ({
        label: getLabelFromDomainSubdomain(domain),
        domain: domain,
      }))

      setDomainOptions(domainOptions)
    },
    [getDomainsAndSubdomains, getLabelFromDomainSubdomain],
  )

  const formatOptionLabel = useCallback((option: DomainOption) => {
    return option.label || ""
  }, [])

  const renderOption = useCallback((props: any, option: DomainOption) => {
    return (
      <TextBody hasWarning={!!option?.domain?.site_id} {...props} key={props["data-option-index"]}>
        {option.label}
      </TextBody>
    )
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== "") {
      handleSearchDomains(debouncedSearchTerm)
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  return (
    <Box sx={{ my: "8px" }}>
      <Autocomplete
        options={domainOptions}
        inputValue={inputValue}
        onInputChange={handleChangeInput}
        renderInput={renderInput}
        onChange={handleValueChange}
        getOptionLabel={formatOptionLabel}
        renderOption={renderOption}
        loading={loading}
      />
      <Box width="100%" height="12px">
        <Caption1 hasError={!!meta.error}> {meta.error && "Required"} </Caption1>
      </Box>
    </Box>
  )
}

export default DomainSearchAutocomplete
