import React, { FunctionComponent } from "react"
import { Box, Button, Grid, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { AIImageGenerationStyle } from "../../../types"
import useCreateAIImageGenerationStyle from "../hooks/useCreateAIImageGenerationStyle"
import useUpdateAIImageGenerationStyle from "../hooks/useUpdateAIImageGenerationStyle"
import useDeleteAIImageGenerationStyle from "../hooks/useDeleteAIImageGenerationStyle"
import FileUploader from "../../../components/shared/FileUploader"

interface Props {
  isOpen: boolean
  close: () => void
  editAIImageGenerationStyle?: AIImageGenerationStyle
}

const AIImageGenerationStyleDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  editAIImageGenerationStyle,
}) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.ai.imageGenerationStyle.delete)
  const [status, setStatus] = React.useState<string | null>(null)

  const {
    createAIImageGenerationStyle,
    loading: creating,
    error: creationError,
  } = useCreateAIImageGenerationStyle()
  const {
    updateAIImageGenerationStyle,
    loading: updating,
    error: updateError,
  } = useUpdateAIImageGenerationStyle()
  const {
    deleteAIImageGenerationStyle,
    loading: deleting,
    error: deleteError,
  } = useDeleteAIImageGenerationStyle()

  const handleSuccess = async (msg: string) => {
    toast.success(msg)
    handleClose()
  }

  const handleDelete = async () => {
    if (editAIImageGenerationStyle) {
      await deleteAIImageGenerationStyle(editAIImageGenerationStyle.id, () =>
        handleSuccess("Successfully deleted Ai Image Generation Style"),
      )
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    iconUrl: Yup.string().url().required(),
    prompt: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      iconUrl: editAIImageGenerationStyle?.icon_url || "",
      title: editAIImageGenerationStyle?.title || "",
      prompt: editAIImageGenerationStyle?.prompt || "",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      if (editAIImageGenerationStyle) {
        await updateAIImageGenerationStyle(
          editAIImageGenerationStyle.id,
          {
            icon_url: values.iconUrl !== "" ? values.iconUrl : null,
            title: values.title !== "" ? values.title : null,
            prompt: values.prompt !== "" ? values.prompt : null,
          },
          () => handleSuccess("Successfully updated Ai Image Generation Style"),
        )
      } else {
        await createAIImageGenerationStyle(
          {
            icon_url: values.iconUrl !== "" ? values.iconUrl : null,
            title: values.title !== "" ? values.title : null,
            prompt: values.prompt !== "" ? values.prompt : null,
          },
          () => handleSuccess("Successfully created Ai Image Generation Style"),
        )
      }
      close()
    },
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editAIImageGenerationStyle
          ? `Update Ai Image Generation Style: ${editAIImageGenerationStyle.id}`
          : "Create A New Ai Image Generation Style"
      }
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {(!!creationError || !!updateError || !!deleteError) && (
              <ErrorComponent error={creationError || updateError || deleteError} />
            )}
          </Row>
          <Row>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField
                  label="Icon Url"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  id="iconUrl"
                  name="iconUrl"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.iconUrl}
                  error={!!formik.errors.iconUrl}
                  helperText={formik.errors.iconUrl?.toString()}
                  onBlur={(evt) => formik.validateField("iconUrl")}
                />
              </Grid>
              <Grid item xs={2}>
                <FileUploader
                  onAssetUpload={(asset) => formik.setFieldValue("iconUrl", asset.public_url)}
                  uploadStatus={(status) => setStatus(status)}
                />
              </Grid>
            </Grid>
          </Row>
          {status && status !== "" && <Row>{status}</Row>}
          <Row>
            <TextField
              label="Title"
              fullWidth
              variant="outlined"
              margin="normal"
              id="title"
              name="title"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
              helperText={formik.errors.title?.toString()}
              onBlur={(evt) => formik.validateField("title")}
            />
          </Row>
          <Row>
            <TextField
              label="Prompt"
              fullWidth
              variant="outlined"
              margin="normal"
              id="prompt"
              name="prompt"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.prompt}
              error={!!formik.errors.prompt}
              helperText={formik.errors.prompt?.toString()}
              onBlur={(evt) => formik.validateField("prompt")}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!editAIImageGenerationStyle && canDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={creating || updating || deleting}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creating || updating || deleting || !formik.isValid}
          >
            {editAIImageGenerationStyle ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default AIImageGenerationStyleDialog
