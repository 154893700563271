import { Button, Card } from "@mui/material"
import { FunctionComponent } from "react"
import { TextBody } from "../../../components/shared/Typography"
import { formatDate } from "../../../utils/formatters"
import UserLink from "../../Users/components/UserLink"
import { SiteUser } from "../../../types"
import SubscriptionBadge from "../../../components/shared/SubscriptionBadge"

const SiteUserCard: FunctionComponent<{
  siteUser: SiteUser
  changeRole: (userId: number, newRole: string) => void
  removeSiteUser: (userId: number, siteId: number) => void
}> = ({ siteUser, changeRole, removeSiteUser }) => {
  const newRole = siteUser.role.toLowerCase() === "member" ? "Owner" : "Member"

  return (
    <Card sx={{ minWidth: "300px", backgroundColor: "#f2f2f2", mb: "15px", p: "8px" }}>
      {siteUser.deleted_at && (
        <SubscriptionBadge
          text="Removed"
          textColor="white"
          backgroundColor="red"
          hoverText="This user was removed from the site"
        />
      )}
      <TextBody>
        <b>User ID: </b> <a href={`/users/${siteUser.user.id}`}>{siteUser.user.id}</a>
      </TextBody>
      <TextBody>
        <b>Email: </b>
        {`${siteUser.user.email}`}
      </TextBody>
      <UserLink userId={siteUser.user.id}>
        <TextBody>
          <b>Role: </b>
          {`${siteUser.role}`}
        </TextBody>
        <TextBody>
          <b>Joined: </b>
          {`${formatDate(siteUser.created_at)}`}
        </TextBody>
      </UserLink>
      {!siteUser.deleted_at ? (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: "12px" }}
          onClick={() => changeRole(siteUser.user.id, newRole.toLowerCase())}
        >
          Make {siteUser.role.toLowerCase() === "member" ? "Owner" : "Member"}
        </Button>
      ) : null}
      {siteUser.role !== "owner" && !siteUser.deleted_at ? (
        <Button
          variant="contained"
          color="error"
          fullWidth
          sx={{ mt: "12px" }}
          onClick={() => removeSiteUser(siteUser.user.id, siteUser.site_id)}
        >
          Remove Site User
        </Button>
      ) : null}
    </Card>
  )
}

export default SiteUserCard
