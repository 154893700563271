import { FunctionComponent, useEffect, useState } from "react"
import { Box, Paper, Button, Chip } from "@mui/material"
import { Collection, Tag } from "../../../types"
import ErrorComponent from "../../../components/shared/Error"
import Dialog from "../../../components/shared/Dialog"
import { toast } from "react-hot-toast"
import useUpdateTag from "../hooks/useUpdateTag"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import useDeleteTag from "../hooks/useDeleteTag"

interface Props {
  tag: Tag
  collections: Collection[]
  isOpen: boolean
  close: () => any
}

const TagCollectionEditDialog: FunctionComponent<Props> = ({ tag, collections, isOpen, close }) => {
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>([])

  const { updateTag, loading, error } = useUpdateTag()
  const { deleteTag, loading: deleteLoading, error: deleteError } = useDeleteTag()
  const { hasScope } = useAuth()

  const canDeleteTag = hasScope(scopes.tag.delete)

  const toggleCollection = (collection: Collection) => {
    if (selectedCollections.includes(collection)) {
      setSelectedCollections(selectedCollections.filter((c) => c !== collection))
    } else {
      setSelectedCollections([...selectedCollections, collection])
    }
  }

  const save = async () => {
    const collectionNames = selectedCollections.map((collection) => collection.name)
    await updateTag(
      tag.id,
      { title: tag.title, display_title: tag.display_title, collections: collectionNames },
      handleSuccessfulSave,
    )
  }

  const handleSuccessfulSave = () => {
    toast.success("Successfully updated tag")
    close()
  }

  const deleteTagFunc = async () => {
    await deleteTag(tag.id, handleDeleteTagSuccess)
  }

  const handleDeleteTagSuccess = () => {
    toast.success("Successfully deleted tag")
    close()
  }

  useEffect(() => {
    setSelectedCollections(
      (
        tag.collections?.map((collectionName) =>
          collections.find((collection) => collection.name === collectionName),
        ) ?? []
      ).filter((collection) => collection !== undefined) as Collection[],
    )

    // eslint-disable-next-line
  }, [tag])

  return (
    <Dialog
      title={`Edit collections for tag '${tag.title}'`}
      isOpen={isOpen}
      handleClose={close}
      content={
        <Box>
          {error ? <ErrorComponent error={error} /> : null}
          {deleteError ? <ErrorComponent error={deleteError} /> : null}
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              padding: (theme: any) => theme.spacing(0.5),
              margin: 0,
              borderColor: "#000",
              boxShadow: "none",
            }}
          >
            {collections.map((collection, index) => (
              <Chip
                key={index}
                sx={{
                  margin: (theme) => theme.spacing(0.5),
                  borderRadius: "8px",
                }}
                label={collection.name}
                color={selectedCollections.includes(collection) ? "primary" : undefined}
                onClick={() => toggleCollection(collection)}
              />
            ))}
          </Paper>
        </Box>
      }
      buttonContent={
        <>
          {canDeleteTag ? (
            <Button
              onClick={deleteTagFunc}
              color="error"
              variant="contained"
              disabled={loading || deleteLoading}
            >
              Delete
            </Button>
          ) : null}
          <Button onClick={close} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={save}
            color="primary"
            variant="contained"
            disabled={loading || deleteLoading}
          >
            Update
          </Button>
        </>
      }
    />
  )
}

export default TagCollectionEditDialog
