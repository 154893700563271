import { Button } from "@mui/material"
import { FunctionComponent, useCallback } from "react"
import toast from "react-hot-toast"
import ErrorComponent from "../../../components/shared/Error"
import useToggle from "../../../hooks/useToggle"
import {
  DomainTypeEnum,
  EmailForward,
  SiteUser,
  UpdateEmailForwardRequest,
  V5_Site,
} from "../../../types"
import { AddApplePayButton } from "../../Domains/components/DomainLayoutShared"
import ReassignDomainDialog, {
  DomainReassignBasisEnum,
} from "../../Domains/components/ReassignDomainDialog"
import useReassignDomain from "../../Domains/hooks/useReassignDomain"
import useReassignSubdomain from "../../Domains/hooks/useReassignSubdomain"
import AddApplePayDialog from "../components/AddApplePayDialog"
import { Row } from "../components/SiteOverview"
import useArchiveSite from "../hooks/useArchiveSite"
import useDeleteSite from "../hooks/useDeleteSite"
import useEditEmailForward from "../hooks/useEditEmailForward"
import useUnarchiveSite from "../hooks/useUnarchiveSite"
import useUpdateSiteTags from "../hooks/useUpdateSiteTags"
import ArchiveSiteConfirmationDialog from "./ArchiveSiteConfirmationDialog"
import DeleteSiteConfirmationDialog from "./DeleteSiteConfirmationDialog"
import EditEmailForwardDialog from "./EditEmailForwardDialog"
import ReassignSubscriptionDialog from "./ReassignSubscriptionDialog"
import UnarchiveSiteConfirmationDialog from "./UnarchiveSiteConfirmationDialog"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import useSiteTagDialog from "./SiteTagDialog"
import { LoadingButton } from "@mui/lab"
import CreateCreditSubscriptionDialog from "./CreateCreditSubscriptionDialog"

interface Props {
  site: V5_Site
  users?: SiteUser[] | null
  emailForward?: EmailForward | null
  updateSuccessMessage: any
  handleGetSite: () => void
}

const SiteActions: FunctionComponent<Props> = ({
  site,
  users,
  emailForward,
  updateSuccessMessage,
  handleGetSite,
}) => {
  // Permissions
  const { hasScope } = useAuth()
  const canDeleteSite = hasScope(scopes.site.delete)
  const canArchiveSite = hasScope(scopes.site.archive)
  const canCreateCreditSubscription = hasScope(scopes.subscriptions.create)

  const {
    reassignDomain,
    error: errorReassigningDomain,
    loading: loadingDomainReassignment,
  } = useReassignDomain()

  const {
    reassignSubdomain,
    error: errorReassigningSubdomain,
    loading: loadingSubdomainReassignment,
  } = useReassignSubdomain()

  const { updateSiteTags, error: updateTagsError } = useUpdateSiteTags()

  const { isOn: archiveIsOpen, turnOn: openArchive, turnOff: closeArchive } = useToggle()
  const { archiveSite, error: archiveError, loading: isArchiving } = useArchiveSite()

  const { isOn: unarchiveIsOpen, turnOn: openUnarchive, turnOff: closeUnarchive } = useToggle()
  const { unarchiveSite, error: unarchiveError, loading: isUnarchiving } = useUnarchiveSite()

  const { isOn: deleteIsOpen, turnOn: openDelete, turnOff: closeDelete } = useToggle()
  const { deleteSite, error: deleteError, loading: isDeleting } = useDeleteSite()

  const {
    isOn: editForwardIsOpen,
    turnOn: openEditForward,
    turnOff: closeEditForward,
  } = useToggle()
  const {
    editEmailForward,
    error: editEmailForwardError,
    loading: editEmailForwardLoading,
  } = useEditEmailForward()

  const handleArchiveSuccess = useCallback(async () => {
    await handleGetSite()
    closeArchive()
    toast.success("Successfully archived site")
  }, [handleGetSite, closeArchive])

  const handleArchiveSite = async () => {
    if (!site) {
      return
    }
    await archiveSite(site.id, handleArchiveSuccess)
  }

  const handleUnarchiveSuccess = useCallback(async () => {
    await handleGetSite()
    closeUnarchive()
    toast.success("Successfully unarchived site")
  }, [handleGetSite, closeUnarchive])

  const handleUnarchiveSite = async () => {
    if (!site) {
      return
    }
    await unarchiveSite(site.id, handleUnarchiveSuccess)
  }

  const handleDeleteSuccess = useCallback(async () => {
    await handleGetSite()
    closeDelete()
    toast.success("Successfully deleted site")
  }, [closeDelete, handleGetSite])

  const handleDeleteSite = useCallback(async () => {
    if (!site) {
      return
    }

    await deleteSite(site.id, handleDeleteSuccess)
  }, [deleteSite, site, handleDeleteSuccess])

  const handleEditForwardSuccess = useCallback(async () => {
    await handleGetSite()
    closeDelete()
    toast.success("Successfully edited email forward")
  }, [closeDelete, handleGetSite])

  const handleEditForward = useCallback(
    async (emailForward: UpdateEmailForwardRequest) => {
      if (!emailForward) {
        return
      }
      await editEmailForward(emailForward, handleEditForwardSuccess)
    },
    [editEmailForward, handleEditForwardSuccess],
  )

  const {
    isOn: reassignDialogIsOpen,
    turnOn: openReassignDialog,
    turnOff: closeReassignDialog,
  } = useToggle()

  const {
    isOn: subscriptionCreateDialogIsOpen,
    turnOn: openSubscriptionCreateDialog,
    turnOff: closeSubscriptionCreateDialog,
  } = useToggle()

  const {
    isOn: subscriptionDialogIsOpen,
    turnOn: openSubscriptionDialog,
    turnOff: closeSubscriptionDialog,
  } = useToggle()

  const {
    isOn: addApplePayDialogIsOpen,
    turnOn: openAddApplePayDialog,
    turnOff: closeAddApplePayDialog,
  } = useToggle()

  const handleUpdateSiteTags = useCallback(
    async (tags: any) => {
      if (!site || !users?.length) {
        return
      }

      const owners = users.filter((x) => x.role === "owner") || []
      const userId = owners.length ? owners[0].user.id : users ? users[0].user.id : 0
      const { id: siteId } = site
      await updateSiteTags(siteId, userId, tags)
    },
    [site, updateSiteTags, users],
  )

  const onReassignSuccess = useCallback(async () => {
    if (site.id) {
      await handleGetSite()
    }
    closeReassignDialog()
    toast.success("Successfully reassigned domain")
  }, [closeReassignDialog, handleGetSite, site])

  const handleReassignDomain = useCallback(
    async (domainId: number, userId: number, siteId: number, domainType?: DomainTypeEnum) => {
      if (domainType === DomainTypeEnum.Domain) {
        await reassignDomain(domainId, userId, siteId, onReassignSuccess)
      } else if (domainType === DomainTypeEnum.Subdomain) {
        await reassignSubdomain(domainId, userId, siteId, onReassignSuccess)
      }
    },
    [reassignDomain, reassignSubdomain, onReassignSuccess],
  )

  const { siteTagDialog, openSiteTagDialog, siteTagDialogLoading } = useSiteTagDialog()

  return (
    <>
      {archiveError && <ErrorComponent error={archiveError} />}
      {deleteError && <ErrorComponent error={deleteError} />}
      {updateTagsError && <ErrorComponent error={updateTagsError} />}

      <Row mt="6px">
        <LoadingButton
          sx={{ width: "300px" }}
          variant="contained"
          color="primary"
          onClick={() => openSiteTagDialog(site)}
          loading={siteTagDialogLoading}
        >
          Tags
        </LoadingButton>
      </Row>

      <Row mt="12px">
        <Button
          sx={{ width: "300px" }}
          variant="contained"
          color="primary"
          onClick={openReassignDialog}
        >
          Reassign Domain
        </Button>
      </Row>
      {canCreateCreditSubscription && (
        <Row mt="12px">
          <Button
            sx={{ width: "300px" }}
            variant="contained"
            color="primary"
            onClick={openSubscriptionCreateDialog}
          >
            Create Credit Subscription
          </Button>
        </Row>
      )}
      <Row mt="12px">
        <Button
          sx={{ width: "300px" }}
          variant="contained"
          color="primary"
          onClick={openSubscriptionDialog}
        >
          Site Subscriptions
        </Button>
      </Row>
      {site.merchant_id && (
        <Row mt="12px">
          <AddApplePayButton variant="contained" color="primary" onClick={openAddApplePayDialog}>
            Add Apple Pay
          </AddApplePayButton>
        </Row>
      )}
      {emailForward && (
        <Row mt="12px">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={openEditForward}
            sx={{
              maxWidth: "300px",
            }}
          >
            Delete/Edit Email Forward
          </Button>
        </Row>
      )}
      {canArchiveSite && (
        <Row mt="12px">
          <Button
            variant="contained"
            fullWidth
            color="warning"
            sx={{
              maxWidth: "300px",
            }}
            onClick={!site.deleted_at ? openArchive : openUnarchive}
          >
            {!site.deleted_at ? "Archive" : "Unarchive"}
          </Button>
        </Row>
      )}
      {canDeleteSite && (
        <Row mt="12px">
          <Button
            variant="contained"
            color="error"
            fullWidth
            sx={{
              maxWidth: "300px",
            }}
            onClick={openDelete}
          >
            Delete
          </Button>
        </Row>
      )}
      <ReassignDomainDialog
        title={`Reassign Domain For Site - ${site.id}`}
        userId={users?.length && users[0].user ? users[0].user.id : null}
        close={closeReassignDialog}
        isOpen={reassignDialogIsOpen}
        handleReassign={handleReassignDomain}
        hasError={!!errorReassigningDomain || !!errorReassigningSubdomain}
        loading={!!loadingDomainReassignment || !!loadingSubdomainReassignment}
        basis={DomainReassignBasisEnum.Site}
        domainName={site.domain || site.subdomain || null}
        siteId={site.id}
      />
      <AddApplePayDialog
        merchantId={site.merchant_id}
        urls={site.urls}
        close={closeAddApplePayDialog}
        isOpen={addApplePayDialogIsOpen}
      />
      {!!users?.length && (
        <CreateCreditSubscriptionDialog
          isOpen={subscriptionCreateDialogIsOpen}
          close={closeSubscriptionCreateDialog}
          siteId={site.id}
          userId={
            users.find((user) => user.deleted_at !== null && user.role === "owner")?.user.id ?? null
          }
          onSuccess={handleGetSite}
        />
      )}
      {!!users?.length && (
        <ReassignSubscriptionDialog
          isOpen={subscriptionDialogIsOpen}
          close={closeSubscriptionDialog}
          site={site}
          users={users?.filter((user) => user?.deleted_at !== null)}
          refresh={handleGetSite}
        />
      )}
      <ArchiveSiteConfirmationDialog
        isOpen={archiveIsOpen}
        close={closeArchive}
        handleConfirmation={handleArchiveSite}
        hasError={!!archiveError}
        loading={isArchiving}
        siteId={site.id}
      />
      <UnarchiveSiteConfirmationDialog
        isOpen={unarchiveIsOpen}
        close={closeUnarchive}
        handleConfirmation={handleUnarchiveSite}
        hasError={!!unarchiveError}
        loading={isUnarchiving}
        siteId={site.id}
      />
      <DeleteSiteConfirmationDialog
        isOpen={deleteIsOpen}
        close={closeDelete}
        handleConfirmation={handleDeleteSite}
        hasError={!!deleteError}
        loading={isDeleting}
        siteId={site.id}
      />
      {emailForward ? (
        <EditEmailForwardDialog
          isOpen={editForwardIsOpen}
          close={closeEditForward}
          handleEdit={handleEditForward}
          hasError={!!editEmailForwardError}
          loading={editEmailForwardLoading}
          domain={site.domain}
          emailForward={emailForward}
        />
      ) : null}

      {siteTagDialog}
    </>
  )
}

export default SiteActions
