import { Box, TextField, Button } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import RequestsTable from "./components/RequestsTable"
import useGetRequests from "./hooks/useGetRequests"
import { useSearchParams } from "react-router-dom"

const RequestsScreen: FunctionComponent = () => {
  const currentDate = new Date()
  currentDate.setHours(currentDate.getHours() - 3)

  // TODO: Implement setSearchParams
  const [searchParams] = useSearchParams()

  const [rowsPerPage, setRowsPerPage] = useState<number>(50)
  const [page, setPage] = useState<number>(0)
  const [startDate, setStartDate] = useState<string>(`${currentDate.toISOString()}`)
  const [endDate, setEndDate] = useState<string>(`${new Date().toISOString()}`)

  const [query, setQuery] = useState<string>("")

  const {
    getRequests,
    loading: eventsLoading,
    error: eventsError,
    requests,
    paginationData,
  } = useGetRequests()

  const search = () => {
    setPage(0)
    setRowsPerPage(100)
    getRequests(startDate, endDate, query, 0, 100)
  }

  useEffect(() => {
    if (query) {
      getRequests(startDate, endDate, query, page, rowsPerPage)
    }

    // eslint-disable-next-line
  }, [page, rowsPerPage])

  useEffect(() => {
    const params: { [key: string]: string } = {}

    searchParams.forEach((value, key) => {
      if (key === "page") {
        setPage(parseInt(value))
        return
      } else if (key === "rowsPerPage") {
        setRowsPerPage(parseInt(value))
        return
      } else if (key === "start") {
        setStartDate(value)
        return
      } else if (key === "end") {
        setEndDate(value)
        return
      }

      params[key] = value
    })

    const q =
      Object.entries(params)
        .map(([key, value]) => `${key}:${value}`)
        .join(" ") || "status:500"

    setQuery(q)
    getRequests(startDate, endDate, q, page, rowsPerPage)
    // eslint-disable-next-line
  }, [searchParams])

  return (
    <ScreenContainer title="Requests" requiresScope={scopes.events.read}>
      <br />
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <DateTimePicker
          label="Start Date"
          value={startDate}
          onChange={(newStart) => {
            setStartDate(newStart!)
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          label="End Date"
          value={endDate}
          onChange={(newEnd) => {
            setEndDate(newEnd!)
          }}
          renderInput={(params) => <TextField {...params} required />}
        />
      </Box>
      <br />
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <TextField
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") search()
          }}
          label={"Search (request_id, service, container, site_id, auth, status)"}
          fullWidth={true}
        />
        <Button onClick={() => search()}>Search</Button>
      </Box>

      <br />
      {eventsError ? <ErrorComponent error={eventsError} /> : ""}
      {eventsLoading ? (
        <LoadingSpinner />
      ) : (
        <RequestsTable
          requests={requests}
          paginationData={{
            count: paginationData.count,
            page,
            rowsPerPage,
            onPageChange: setPage,
            onRowsPerPageChange: setRowsPerPage,
          }}
        />
      )}
    </ScreenContainer>
  )
}

export default RequestsScreen
