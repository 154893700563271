import { PROMOSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Promo, UpdatePromoRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdatePromo = () => {
  const { loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, promoId: number, promo: UpdatePromoRequest, onSuccess?: Function): Promise<Promo> => {
    const response = await makeRequest<Promo>(
      `${PROMOSV1}/${promoId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(promo),
      },
      { handleUnauthenticated },
    )

    if (onSuccess) {
      await onSuccess()
    }

    return response
  })

  return { updatePromo: performAction, loading, error }
}

export default useUpdatePromo
