import { SUBDOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Subdomain } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useReassignSubomain = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      subdomainId: string | number,
      user_id: string | number,
      site_id: string | number,
      onSuccess?: () => any,
    ): Promise<Subdomain> => {
      const response = makeRequest<Subdomain>(
        `${SUBDOMAINS}/${subdomainId}/transfer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id, site_id }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { reassignSubdomain: performAction, loading, error }
}

export default useReassignSubomain
