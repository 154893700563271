import { TEXTGENERATIONACTIONSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreateTextGenerationAction, TextGenerationAction } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateTextGenerationAction = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      textGenActionId: number,
      textGenAction: CreateTextGenerationAction,
      onSuccess?: () => any,
    ): Promise<TextGenerationAction> => {
      const response = await makeRequest<TextGenerationAction>(
        `${TEXTGENERATIONACTIONSV4}/${textGenActionId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(textGenAction),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateTextGenerationAction: performAction, loading, error }
}

export default useUpdateTextGenerationAction
