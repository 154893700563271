import { Button } from "@mui/material"
import { FunctionComponent } from "react"
import Dialog from "../../../components/shared/Dialog"
import { AIConversation } from "../../../types"
import ChatContainer from "./ChatContainer"

interface Props {
  isOpen: boolean
  close: () => void
  conversation: AIConversation
}

const AIConversationDialog: FunctionComponent<Props> = ({ isOpen, close, conversation }) => {
  const handleClose: any = () => {
    close()
  }

  return (
    <Dialog
      title={"Conversation"}
      isOpen={isOpen}
      handleClose={handleClose}
      content={<ChatContainer conversationId={conversation.id} />}
      buttonContent={
        <Button onClick={handleClose} color="error" variant="outlined">
          Close
        </Button>
      }
    />
  )
}

export default AIConversationDialog
