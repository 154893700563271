import { useEffect, useState } from "react"
import { NO_CONTEXT } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { ImageStatus as ImageStatusType } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetNoContextImages = (defaultRowsPerPage = 50) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction: getImages } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<ImageStatusType>> => {
    let queryString = `${NO_CONTEXT}?limit=${encodeURIComponent(
      rowsPerPage,
    )}&offset=${encodeURIComponent(page * rowsPerPage)}`
    return makeRequest<PagedResponse<ImageStatusType>>(queryString, {}, { handleUnauthenticated })
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getImages()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    getImages,
    loading,
    error,
    images: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetNoContextImages
