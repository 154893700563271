import { useEffect, useState } from "react"
import { CONVERSATIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { AISiteGenerationSection } from "../../../types"

const useGetSectionsForGeneration = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getSectionsForGeneration,
  } = useHandleRequest(
    (
      handleUnauthenticated,
      conversationId: string | undefined,
      generationId: number,
    ): Promise<PagedResponse<AISiteGenerationSection>> => {
      if (!conversationId) {
        return Promise.reject("No conversationId provided")
      }

      const query = Object.entries({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      })
        .map(
          ([key, value]: [string, string | number | boolean | undefined]) =>
            value && `${key}=${encodeURIComponent(value)}`,
        )
        .filter(Boolean)
        .join("&")

      return makeRequest<PagedResponse<AISiteGenerationSection>>(
        `${CONVERSATIONS}/${conversationId}/generations/${generationId}/sections?${query}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    // getAILogForConversation()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getSectionsForGeneration,
    sections: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetSectionsForGeneration
