import { Box } from "@mui/material"
import  { FunctionComponent } from "react"
import { Title2, Subhead } from "../../../components/shared/Typography"
import config from "../../../config"
import { OrderDetail } from "../../../types"
import { formatDate, formatPrice } from "../../../utils/formatters"
import SiteLink from "../../Sites/components/SiteLink"
import { Row, StyledText } from "./OrderLayoutShared"

interface Props {
  order: OrderDetail | undefined
}

const OrderOverview: FunctionComponent<Props> = ({ order }) => {
  if (!order) {
    return null
  }

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "400px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
        marginTop: "10px",
        marginBottom: "10px",
        marginRight: "10px",
      }}
    >
      <Title2>Order:</Title2>
      <Row>
        <Subhead>ID: </Subhead>
        <StyledText>{order.id}</StyledText>
      </Row>
      <Row>
        <Subhead>Price: </Subhead>
        <StyledText>{formatPrice(order.price)}</StyledText>
      </Row>
      <Row>
        <Subhead>Order Status: </Subhead>
        <StyledText>{order.status}</StyledText>
      </Row>
      <Row>
        <Subhead>Site ID: </Subhead>
        <SiteLink siteId={order.site_id}> {order.site_id}</SiteLink>
      </Row>
      <Row>
        <Subhead>Payment Intent: </Subhead>
        <a
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noreferrer"
          href={`${config.stripeUrl}/payments/${order.transaction.payment_intent}`}
        >
          {order.transaction.payment_intent}
        </a>
      </Row>
      <Row>
        <Subhead>Transaction ID: </Subhead>
        <StyledText>{order.transaction.id}</StyledText>
      </Row>
      <Row>
        <Subhead>Transaction Status: </Subhead>
        <StyledText>{order.transaction.transaction_status}</StyledText>
      </Row>
      <Row>
        <Subhead>Placed: </Subhead>
        <StyledText>{formatDate(order.created_at)}</StyledText>
      </Row>
    </Box>
  )
}

export default OrderOverview
