import { Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import { cloneDeep } from "lodash"
import { FunctionComponent, useEffect } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { UserGroup } from "../../../types"
import useCreateUserGroup from "../hooks/useCreateUserGroup"
import useUpdateUserGroup from "../hooks/useUpdateUserGroup"

interface Props {
  isOpen: boolean
  close: () => void
  editUserGroup: UserGroup | undefined | null
  onRefresh: () => any
  canEdit: boolean
}

const EditUserGroupModal: FunctionComponent<Props> = ({
  isOpen,
  close,
  onRefresh,
  editUserGroup,
  canEdit,
}) => {
  // disable form if user cannot edit or if editing an experiment that has ended
  const disabled = !canEdit

  const { createUserGroup, loading: creating, error: creationError } = useCreateUserGroup()

  const { updateUserGroup, loading: updating, error: updateError } = useUpdateUserGroup()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const item: any = {
        name: values.name,
      }

      if (!!editUserGroup) {
        const body = { ...item }
        await updateUserGroup(editUserGroup.id, body, handleOnSuccess)
      } else {
        await createUserGroup(item, handleOnSuccess)
      }
    },
  })

  useEffect(() => {
    if (!editUserGroup) {
      formik.resetForm()
    } else {
      const loadUserGroup = cloneDeep(editUserGroup)

      formik.setValues({
        name: loadUserGroup.name || "",
      })
    }

    // eslint-disable-next-line
  }, [editUserGroup])

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  const handleOnSuccess = () => {
    onRefresh()
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editUserGroup ? `Update User Group: ${editUserGroup.name}` : "Create A New User Group"
      }
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {(!!creationError || !!updateError) && (
              <ErrorComponent error={creationError || updateError} />
            )}
          </Row>
          <Row>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              margin="normal"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!formik.errors.name}
              disabled={disabled}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!disabled && (
            <Button
              onClick={formik.submitForm}
              color="primary"
              variant="outlined"
              disabled={creating || updating}
            >
              {editUserGroup ? "Update" : "Create"}
            </Button>
          )}
        </>
      }
    />
  )
}

export default EditUserGroupModal
