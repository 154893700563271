import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { UserGroupUser } from "../../../types"
import { USERSV4 } from "../../../constants/urlDefs"

const useGetUserUserGroups = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, userId: number): Promise<PagedResponse<UserGroupUser>> => {
      return makeRequest<PagedResponse<UserGroupUser>>(
        `${USERSV4}/${userId}/groups`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getUserUserGroups: performAction, userGroups: data }
}

export default useGetUserUserGroups
