import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { OrderStub } from "../../../types"
import OrderRow from "./OrderRow"

interface Props {
  orders: OrderStub[]
  paginationData: PaginationData
}

const OrderTable: FunctionComponent<Props> = ({ orders, paginationData }) => {
  const columns = [
    "Order ID",
    "Transaction ID",
    "Payment Intent",
    "Site ID",
    "Status",
    "Price",
    "Buyer Email",
    "Buyer Name",
    "Merchant Email",
    "Created At",
    "",
  ]

  const getCouponRows = useCallback(() => {
    return orders.map((order: OrderStub, index: number) => <OrderRow key={index} order={order} />)
  }, [orders])

  return <BasicTable columns={columns} rows={getCouponRows()} paginationOptions={paginationData} />
}

export default OrderTable
