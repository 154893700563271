import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FunctionComponent, useCallback } from "react"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Subhead } from "../../../components/shared/Typography"
import { Subscription } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"
import UserLink from "../../Users/components/UserLink"
import DomainLink from "../../Domains/components/DomainLink"

export const Row = styled(Box)``
Row.defaultProps = {
  flexWrap: "wrap",
  width: "100%",
  display: "flex",
  sx: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}

export const StyledText: any = styled(Subhead)`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
`

interface Props {
  subscription: Subscription | undefined
}

const SubscriptionOverview: FunctionComponent<Props> = ({ subscription }) => {
  const formatSiteIds = useCallback((siteIds: (string | number)[]) => {
    const ids = siteIds.reduce((accu: any, siteId: any, index) => {
      const elem = (
        <SiteLink key={index} siteId={siteId}>
          {siteId}
        </SiteLink>
      )
      return accu === null ? [elem] : [...accu, ",", elem]
    }, null)

    return ids
  }, [])

  const formatDomainIds = useCallback((domainIds: (string | number)[]) => {
    const ids = domainIds.reduce((accu: any, domainId: any, index) => {
      const elem = (
        <DomainLink key={index} domainId={domainId}>
          {domainId}
        </DomainLink>
      )
      return accu === null ? [elem] : [...accu, ",", elem]
    }, null)

    return ids
  }, [])

  if (!subscription) {
    return null
  }

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "400px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
      }}
    >
      <Row>
        <Subhead>Subscription ID: </Subhead>
        <StyledText>{subscription.id}</StyledText>
        <CopyIcon content={subscription.id} />
      </Row>
      <Row>
        <Subhead>SK Product ID: </Subhead>
        <StyledText>{subscription.sk_product_id}</StyledText>
      </Row>
      <Row>
        <Subhead>Status: </Subhead>
        <StyledText>
          {subscription.status} {subscription.is_trial ? "(Trial)" : ""}{" "}
          {subscription.grace_period_expires_date && new Date(subscription.end_date) < new Date()
            ? "(Billing Grace)"
            : ""}
        </StyledText>
      </Row>
      <Row>
        <Subhead>Type: </Subhead>
        <StyledText>{subscription.type}</StyledText>
      </Row>
      {subscription?.subscription_start && (
        <Row>
          <Subhead>Subscription Start: </Subhead>
          <StyledText>{subscription.subscription_start}</StyledText>
        </Row>
      )}
      {subscription?.end_date && (
        <Row>
          <Subhead>End Date: </Subhead>
          <StyledText>{subscription?.end_date}</StyledText>
        </Row>
      )}
      {subscription?.plan_id && (
        <Row>
          <Subhead>Plan ID: </Subhead>
          <StyledText>{subscription?.plan_id}</StyledText>
          <CopyIcon content={subscription.plan_id} />
        </Row>
      )}
      <Row>
        <Subhead>Auto Renew Status: </Subhead>
        <StyledText>{!!subscription?.auto_renew_status ? "On" : "Off"}</StyledText>
      </Row>
      {subscription.last_renewal_start && (
        <Row>
          <Subhead>Last Renewal Start: </Subhead>
          <StyledText>{subscription.last_renewal_start}</StyledText>
        </Row>
      )}
      {subscription?.user_id && (
        <Row>
          <Subhead>User Id: </Subhead>
          <UserLink userId={subscription.user_id}> {subscription.user_id}</UserLink>
          <CopyIcon content={subscription.user_id} />
        </Row>
      )}
      {subscription?.max_sites && (
        <Row>
          <Subhead>Max Sites: </Subhead>
          <StyledText>{subscription.max_sites}</StyledText>
        </Row>
      )}
      {!!subscription?.site_ids?.length && (
        <Row>
          <Subhead>Site Ids: </Subhead>
          {formatSiteIds(subscription.site_ids)}
        </Row>
      )}
      {!!subscription?.domain_ids?.length && (
        <Row>
          <Subhead>Domain Ids: </Subhead>
          {formatDomainIds(subscription.domain_ids)}
        </Row>
      )}
      <Row>
        <Subhead>Apple Transaction ID: </Subhead>
        <StyledText>{subscription.apple_transaction_id}</StyledText>
        <CopyIcon content={subscription.apple_transaction_id} />
      </Row>
      {subscription?.stripe_payment_intent_id && (
        <Row>
          <Subhead>Stripe Payment Intent Id: </Subhead>
          <StyledText>{subscription.stripe_payment_intent_id}</StyledText>
          <CopyIcon content={subscription.stripe_payment_intent_id} />
        </Row>
      )}
      {subscription?.stripe_subscription_id && (
        <Row>
          <Subhead>Stripe Subscription Id: </Subhead>
          <StyledText>{subscription.stripe_subscription_id}</StyledText>
          <CopyIcon content={subscription.stripe_subscription_id} />
        </Row>
      )}
      {subscription?.stripe_subscription_item_id && (
        <Row>
          <Subhead>Stripe Subscription Item Id: </Subhead>
          <StyledText>{subscription.stripe_subscription_item_id}</StyledText>
          <CopyIcon content={subscription.stripe_subscription_item_id} />
        </Row>
      )}
      <Row>
        <Subhead>Trial Account: </Subhead>
        <StyledText>{subscription.is_trial ? "Yes" : "No"}</StyledText>
      </Row>
      {subscription?.trial_start && (
        <Row>
          <Subhead>Trial Start: </Subhead>
          <StyledText>{subscription.trial_start}</StyledText>
        </Row>
      )}
      {subscription?.grace_period_expires_date && (
        <Row>
          <Subhead>Grace Period Expires: </Subhead>
          <StyledText>{subscription?.grace_period_expires_date}</StyledText>
        </Row>
      )}
      <Row>
        <Subhead>Fraud Account: </Subhead>
        <StyledText>{subscription.is_fraud ? "Yes" : "No"}</StyledText>
      </Row>
      <Row>
        <Subhead>Created At: </Subhead>
        <StyledText>{subscription.created_at}</StyledText>
      </Row>
      <Row>
        <Subhead>Updated At: </Subhead>
        <StyledText>{subscription.updated_at}</StyledText>
      </Row>
    </Box>
  )
}

export default SubscriptionOverview
