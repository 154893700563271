import { useCallback, useState } from "react"

import { TEKTON_PIPELINESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TektonEventListenerResponse } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useRunPipeline = () => {
  const [retryLoading, setRetryLoading] = useState(false)
  const [retryError, setRetryError] = useState<undefined | Error>(undefined)
  const { loading, error, data, performAction } = useHandleRequest(
    (
      handleUnauthenticated,
      pipelineId: string,
      body: Record<string, any>,
    ): Promise<TektonEventListenerResponse> => {
      return makeRequest<TektonEventListenerResponse>(
        `${TEKTON_PIPELINESV4}/${pipelineId}/pipelineruns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
        { handleUnauthenticated },
      )
    },
  )

  /**
   * This function is used to retry the action if it fails. It will retry the action 3 times before
   * giving up.
   */
  const performActionWithRetry = useCallback(
    async (
      pipelineId: string,
      body: Record<string, any>,
      localRetryCount: number = 0,
    ): Promise<Promise<TektonEventListenerResponse> | undefined> => {
      setRetryLoading(true)
      const result = await performAction(pipelineId, body)

      if (error) {
        localRetryCount++

        if (localRetryCount < 3) {
          return performActionWithRetry(pipelineId, body, localRetryCount)
        } else {
          setRetryError(error)
          setRetryLoading(false)
          return
        }
      }

      setRetryLoading(false)
      return result
    },
    [performAction, error, setRetryError],
  )

  // We hide the error from the user with the retryError, as to only show the error if the retry
  // fails. This is because the retry is done automatically and the user doesn't need to know about
  // it. Similarly, we do not display the loading state since it will be handled automatically.
  return {
    loading: loading || retryLoading,
    error: retryError,
    performAction: performActionWithRetry,
    data,
  }
}

export default useRunPipeline
