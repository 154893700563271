import { useEffect, useState } from "react"
import { EVENTS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Event } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetEvents = (defaultRowsPerPage = 25) => {
  const currentDate = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(currentDate.getDate() + 1)
  const currentDateString = String(currentDate.toISOString().split("T")[0])
  const tomorrowString = String(tomorrow.toISOString().split("T")[0])

  const [startDate] = useState(`${currentDateString}`)
  const [endDate] = useState(`${tomorrowString}`)
  const [topic] = useState("")
  const [requestID] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getEvents,
  } = useHandleRequest(
    (
      handleUnauthenticated,
      startDate?: String,
      endDate?: String,
      topic?: String,
      requestID?: String,
    ): Promise<PagedResponse<Event>> => {
      return makeRequest<PagedResponse<Event>>(
        `${EVENTS}?limit=${encodeURIComponent(rowsPerPage)}&offset=${encodeURIComponent(
          page * rowsPerPage,
        )}&start_date=${startDate}&end_date=${endDate}${topic ? `&topic=${topic}` : ""}${
          requestID ? `&request_id=${requestID}` : ""
        }`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getEvents(startDate, endDate, topic, requestID)
    // eslint-disable-next-line
  }, [page, rowsPerPage, startDate, endDate, topic, requestID])

  return {
    loading,
    error,
    getEvents,
    events: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetEvents
