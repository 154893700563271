import { Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { Employee, PostEmployee } from "../../types"
import ConfirmEmployeeDeletionDialog from "./components/ConfirmEmployeeDeletionDialog"
import EmployeeDialog from "./components/EmployeeDialog"
import EmployeesTable from "./components/EmployeesTable"
import useCreateEmployee from "./hooks/useCreateEmployee"
import useDeleteEmployee from "./hooks/useDeleteEmployee"
import useGetEmployees from "./hooks/useGetEmployees"
import useUpdateEmployee from "./hooks/useUpdateEmployee"
import toast from "react-hot-toast"

const EmployeesScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.employees.create)
  const canUpdate = hasScope(scopes.employee.update)
  const canDelete = hasScope(scopes.employee.delete)

  const {
    isOn: employeeDialogIsOpen,
    turnOn: openEmployeeDialog,
    turnOff: closeEmployeeDialog,
  } = useToggle()
  const {
    loading: employeesLoading,
    error: employeesError,
    getEmployees,
    employees,
  } = useGetEmployees()

  const { createEmployee, loading: creatingEmployee, error: createError } = useCreateEmployee()
  const { updateEmployee, loading: updatingEmployee, error: updateError } = useUpdateEmployee()
  const { deleteEmployee, loading: deletingEmployee, error: deletionError } = useDeleteEmployee()

  const [employeeUpdate, setEmployeeUpdate] = useState<Employee>()
  const [employeeDelete, setEmployeeDelete] = useState<Employee>()

  useEffect(() => {
    getEmployees()
    // eslint-disable-next-line
  }, [])

  const handleCloseEmployeeDialog = useCallback(() => {
    setEmployeeUpdate(undefined)
    closeEmployeeDialog()
  }, [closeEmployeeDialog])

  const handleCloseDeletionDialog = useCallback(() => {
    setEmployeeDelete(undefined)
  }, [setEmployeeDelete])

  const handleCreateEmployee = useCallback(
    async (employee: PostEmployee, callback: () => any) => {
      const onSuccess = async () => {
        await getEmployees()
        callback()
        toast.success("Successfully created employee")
      }
      await createEmployee(employee, onSuccess)
    },
    [createEmployee, getEmployees],
  )

  const handleUpdateEmployee = useCallback(
    async (employee: Employee, callback: () => any) => {
      const onSuccess = async () => {
        await getEmployees()
        callback()
        toast.success("Successfully updated employee")
      }
      await updateEmployee(employee, onSuccess)
    },
    [updateEmployee, getEmployees],
  )

  const handleDeleteEmployee = useCallback(async () => {
    const onSuccess = async () => {
      handleCloseDeletionDialog()
      await getEmployees()
      toast.success("Successfully deleted employee")
    }
    if (employeeDelete) {
      await deleteEmployee(Number(employeeDelete), onSuccess)
    }
  }, [employeeDelete, handleCloseDeletionDialog, getEmployees, deleteEmployee])

  return (
    <ScreenContainer
      title="Employees"
      subtitle="Internal Employee Management"
      requiresScope={scopes.employees.read}
    >
      <br />
      {canCreate && (
        <>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "300px" }}
            onClick={openEmployeeDialog}
          >
            Create Employee
          </Button>
          <br />
        </>
      )}

      {employeesError ? <ErrorComponent error={employeesError} /> : ""}
      {employeesLoading ? (
        <LoadingSpinner />
      ) : (
        <EmployeesTable
          employees={employees}
          deleteEmployee={setEmployeeDelete}
          updateEmployee={setEmployeeUpdate}
          canUpdate={canUpdate}
          canDelete={canDelete}
        />
      )}
      <EmployeeDialog
        isOpen={!!employeeDialogIsOpen || !!employeeUpdate}
        close={handleCloseEmployeeDialog}
        handleCreate={handleCreateEmployee}
        handleUpdate={handleUpdateEmployee}
        hasError={!!createError || !!updateError}
        loading={!!creatingEmployee || !!updatingEmployee}
        employeeUpdate={employeeUpdate}
      />
      {!!employeeDelete && (
        <ConfirmEmployeeDeletionDialog
          employee={employeeDelete}
          isOpen={!!employeeDelete}
          handleConfirmation={handleDeleteEmployee}
          hasError={!!deletionError}
          loading={deletingEmployee}
          close={handleCloseDeletionDialog}
        />
      )}
    </ScreenContainer>
  )
}

export default EmployeesScreen
