import { PAYMENTINTEGRATIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useDeletePaymentIntegration = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      paymentIntegrationId: number,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${PAYMENTINTEGRATIONS}/${paymentIntegrationId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deletePaymentIntegration: performAction, loading, error }
}

export default useDeletePaymentIntegration
