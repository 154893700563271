import { styled } from "@mui/material/styles"
import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"
import { UserRoleChangeType } from "../SiteDetailsScreen"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  roleChange: UserRoleChangeType
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const ChangeUserRoleConfirmationDialog: FunctionComponent<Props> = ({
  roleChange,
  hasError,
  ...rest
}) => {
  return (
    <ConfirmationDialog
      title="Change User Role"
      errorMessage={hasError ? "There was a problem changing the role" : null}
      affirmativeButtonText="Change Role"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>
              {`Are you sure you want to make user ${roleChange.userId} a ${roleChange.role}?`}
            </TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default ChangeUserRoleConfirmationDialog
