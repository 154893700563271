import { TEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Template } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteTemplate = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, templateId: number, onSuccess?: () => any): Promise<Template> => {
      const response = await makeRequest<Template>(
        `${TEMPLATES}/${templateId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteTemplate: performAction, loading, error }
}

export default useDeleteTemplate
