import { TEXTGENERATIONACTIONSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TextGenerationAction } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetTextGenerationActions = () => {
  const {
    loading,
    error,
    data,
    performAction: getTextGenerationActions,
  } = useHandleRequest((handleUnauthenticated): Promise<TextGenerationAction[]> => {
    return makeRequest<TextGenerationAction[]>(
      TEXTGENERATIONACTIONSV4,
      {},
      { handleUnauthenticated },
    )
  })

  return {
    loading,
    error,
    getTextGenerationActions,
    textGenerationActions: data || [],
  }
}

export default useGetTextGenerationActions
