import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Event } from "../../../types"
import { Button } from "@mui/material"

type EventRowProps = {
  event: Event
}

const EventRow: FunctionComponent<EventRowProps> = ({ event }) => {
  const [fullBody, setFullBody] = React.useState(false)

  const body = JSON.stringify(event.body)
  const commaPosition = body?.indexOf(",", 25)

  let shortBody = body
  if (commaPosition && commaPosition > -1) {
    shortBody = body.slice(0, Math.min(commaPosition, 120))
  }

  return (
    <BasicTableRow key={event.id}>
      <BasicTableCell component="th" scope="row">
        {event.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.request_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.date}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.topic}
      </BasicTableCell>
      <BasicTableCell style={{ overflow: "auto", maxWidth: 400 }}>
        {commaPosition > -1 ? <Button onClick={(x) => setFullBody(!fullBody)}>...</Button> : ""}
        <pre style={{ whiteSpace: "pre-wrap" }}>{fullBody ? body : shortBody}</pre>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {event.type}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default EventRow
