import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { RemoteValue } from "../../../types"
import RemoteValueRow from "./RemoteValueRow"

interface Props {
  remoteValues: RemoteValue[]
  onEditSelect: (item: RemoteValue) => any
  canEdit: boolean
}

const RemoteValuesTable: FunctionComponent<Props> = ({ remoteValues, onEditSelect, canEdit }) => {
  const columns = canEdit
    ? ["Key", "Default Value", "Description", "Created At", ""]
    : ["Key", "Default Value", "Description", "Created At"]

  const getRows = useCallback(() => {
    return remoteValues.map((remoteValue: RemoteValue, index: number) => (
      <RemoteValueRow
        key={index}
        remoteValue={remoteValue}
        onEditSelect={onEditSelect}
        canEdit={canEdit}
      />
    ))
  }, [remoteValues, onEditSelect, canEdit])

  return <BasicTable columns={columns} rows={getRows()} />
}

export default RemoteValuesTable
