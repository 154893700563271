import { GITHUB } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import type { Revision } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetBranches = () => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated): Promise<Revision[]> => {
      return makeRequest<Revision[]>(GITHUB + "/branches", {}, { handleUnauthenticated })
    },
  )

  return {
    loading,
    error,
    performAction,
    data: data || [],
  }
}

export default useGetBranches
