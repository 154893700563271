import { MERCHANTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Merchant } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetMerchantById = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, merchantId: number) => {
    return makeRequest<Merchant>(`${MERCHANTSV3}/${merchantId}/details`, {}, { handleUnauthenticated })
  })

  return { loading, error, getMerchantById: performAction, merchant: data }
}

export default useGetMerchantById
