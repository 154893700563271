import { USERGROUPS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UserGroup } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetUserGroup = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, id: number) => {
      return makeRequest<UserGroup>(`${USERGROUPS}/${id}`, {}, { handleUnauthenticated })
    },
  )

  return {
    getUserGroup: performAction,
    loading,
    error,
    userGroup: data,
  }
}

export default useGetUserGroup
