import React, { FunctionComponent, useState } from "react"
import { Button } from "@mui/material"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { AIImageGenerationStyle } from "../../../types"
import AIImageGenerationStyleDialog from "./AIImageGenerationStyleDialog"

type AIImageGenerationStyleTableRowProps = {
  aiImageGenerationStyle: AIImageGenerationStyle
  getAIImageGenerationStyles: () => any
}

const AIImageGenerationStyleTableRow: FunctionComponent<AIImageGenerationStyleTableRowProps> = ({
  aiImageGenerationStyle,
  getAIImageGenerationStyles,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.ai.imageGenerationStyle.update)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getAIImageGenerationStyles()
  }

  return (
    <BasicTableRow key={aiImageGenerationStyle.id}>
      <BasicTableCell component="td" scope="row">
        {aiImageGenerationStyle.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <img
          src={aiImageGenerationStyle.icon_url}
          style={{ maxWidth: "50px" }}
          alt={`${aiImageGenerationStyle.icon_url} Icon`}
        />
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {aiImageGenerationStyle.title}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {aiImageGenerationStyle.prompt}
      </BasicTableCell>

      {canEdit ? (
        <>
          <BasicTableCell component="td" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <AIImageGenerationStyleDialog
          isOpen={open}
          close={handleClose}
          editAIImageGenerationStyle={aiImageGenerationStyle}
        />
      ) : null}
    </BasicTableRow>
  )
}

export default AIImageGenerationStyleTableRow
