import { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  domainId: string | number
}

type DomainLinkProps = Omit<Props, "to">

const DomainLink: FunctionComponent<DomainLinkProps> = ({ domainId, children, ...rest }) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/domains/${domainId}`}>
    {children}
  </Link>
)

export default DomainLink
