import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { RemoteValue } from "../../types"
import RemoteValueDialog from "./components/RemoteValueDialog"
import RemoteValuesTable from "./components/RemoteValuesTable"
import useGetRemoteValues from "./hooks/useGetRemoteValues"

const RemoteValuesScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.remoteConfig.defaultValues.create)
  const canEdit = hasScope(scopes.remoteConfig.defaultValue.update)

  const { getRemoteValues, loading, error, remoteValues } = useGetRemoteValues()
  const { isOn, turnOn: openModal, turnOff: closeModal } = useToggle()

  // for editing
  const [remoteValue, setRemoteValue] = useState<RemoteValue>()

  useEffect(() => {
    getRemoteValues()

    // eslint-disable-next-line
  }, [])

  const handleClose = useCallback(() => {
    closeModal()
    setRemoteValue(undefined)
  }, [closeModal])

  const handleSelectEdit = useCallback(
    (value: RemoteValue) => {
      setRemoteValue(value)
      openModal()
    },
    [openModal],
  )

  const showTable = !loading && remoteValues.length > 0

  return (
    <ScreenContainer title="Remote Values" requiresScope={scopes.remoteConfig.defaultValues.read}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      {canCreate && (
        <Button
          variant="outlined"
          color="primary"
          onClick={openModal}
          sx={{ my: "12px", width: "300px" }}
        >
          Create
        </Button>
      )}

      {showTable && (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <RemoteValuesTable
            remoteValues={remoteValues}
            onEditSelect={handleSelectEdit}
            canEdit={canEdit}
          />
        </Box>
      )}
      <RemoteValueDialog
        isOpen={isOn}
        close={handleClose}
        onRefresh={getRemoteValues}
        remoteValue={remoteValue}
      />
    </ScreenContainer>
  )
}

export default RemoteValuesScreen
