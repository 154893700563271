import { TEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreateTemplate, Template } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateTemplate = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      template: CreateTemplate,
      onSuccess?: () => any,
    ): Promise<Template> => {
      const response = await makeRequest<Template>(
        `${TEMPLATES}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...template }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createTemplate: performAction, loading, error }
}

export default useCreateTemplate
