import { Fragment } from "react"
import { Chip } from "@mui/material"

export default function Tags({ tags }: { tags: string }) {
  if (tags === "") {
    return null
  }

  return (
    <Fragment>
      {tags.split(" ").map((tag) => (
        <Chip key={tag} label={tag} />
      ))}
    </Fragment>
  )
}
