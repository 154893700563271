import { CONVERSATIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { AIConversation } from "../../../types"

const useGetConversations = (defaultRowsPerPage = 50) => {
  const {
    data,
    loading,
    error,
    performAction: getConversations,
  } = useHandleRequest(
    (
      handleUnauthenticated,
      page: number,
      rowsPerPage: number,
      searchState: string | undefined = undefined,
    ): Promise<PagedResponse<AIConversation>> => {
      const query = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        q: searchState,
      }

      return makeRequest<PagedResponse<AIConversation>>(
        `${CONVERSATIONS}/search?${Object.entries(query)
          .map(
            ([key, value]: [string, string | number | boolean | undefined]) =>
              value && `${key}=${encodeURIComponent(value)}`,
          )
          .filter(Boolean)
          .join("&")}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return {
    loading,
    error,
    getConversations,
    conversations: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
    },
  }
}

export default useGetConversations
