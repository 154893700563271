import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import useGetTextGenerationActions from "./hooks/useGetTextGenerationActions"
import TextGenerationActionsTable from "./components/TextGenerationActionsTable"
import TextGenerationActionDialog from "./components/TextGenerationActionDialog"

const TextGenerationScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const { hasScope } = useAuth()

  const canCreate = hasScope(scopes.textGenerationActions.create)
  const canDelete = hasScope(scopes.textGenerationAction.delete)

  const { loading, error, textGenerationActions, getTextGenerationActions } =
    useGetTextGenerationActions()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getTextGenerationActions()
  }

  useEffect(() => {
    getTextGenerationActions()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Text Generation Actions" requiresScope={scopes.suggestions.read}>
      {error && <ErrorComponent error={error} />}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {canCreate && (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "300px" }}
                onClick={handleClickOpen}
              >
                Create Text Generation Action
              </Button>
              <br />
            </>
          )}
          <TextGenerationActionsTable
            texGenerationActions={textGenerationActions}
            refreshData={getTextGenerationActions}
          />
          <TextGenerationActionDialog
            isOpen={open}
            close={handleClose}
            canDelete={canDelete}
            refreshData={getTextGenerationActions}
          />
        </>
      )}
    </ScreenContainer>
  )
}

export default TextGenerationScreen
