import { useCallback, useEffect, useState } from "react"
import { MERCHANTSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Merchant } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useSearchMerchants = (defaultRowsPerPage = 25) => {
  const [searchTerm, setSearchTerm] = useState("")
  // eslint-disable-next-line
  const [currentTerm, setCurrentTerm] = useState("")

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const [handleSuccess, setHandleSuccess] =
    useState<(results: Merchant[], term: string) => any | undefined>()

  const { data, loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      term: string,
      onSuccess?: (results: Merchant[], term: string) => any,
    ): Promise<PagedResponse<Merchant>> => {
      const response = await makeRequest<PagedResponse<Merchant>>(
        `${MERCHANTSV1}/search?limit=${rowsPerPage}&offset=${rowsPerPage * page}&term=${term}`,
        {},
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess(response.results, term)
      }

      return response
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (searchTerm) {
      searchMerchants(searchTerm, handleSuccess)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage, searchTerm])

  const searchMerchants = useCallback(
    async (term: string, onSuccess?: (results: Merchant[], term: string) => any) => {
      setSearchTerm(term)
      setCurrentTerm(term)
      setHandleSuccess(onSuccess)

      return await performAction(term, onSuccess)
    },
    [performAction],
  )

  return {
    loading,
    error,
    searchMerchants,
    merchants: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useSearchMerchants
