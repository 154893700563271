import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useAddSiteUser = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      siteId: number,
      userId: number,
      role: string,
      onSuccess?: () => any,
    ): Promise<void> => {
      await makeRequest<void>(
        `${SITESV4}/${siteId}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            role,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }
    },
  )

  return { addSiteUser: performAction, loading, error }
}

export default useAddSiteUser
