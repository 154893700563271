import React, { FunctionComponent, useState } from "react"
import { Button } from "@mui/material"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { Archetype } from "../../../types"
import ArchetypeDialog from "./ArchetypeDialog"

type ArchetypeTableRowProps = {
  archetype: Archetype
  getArchetypes: () => any
}

const ArchetypeTableRow: FunctionComponent<ArchetypeTableRowProps> = ({
  archetype,
  getArchetypes,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.archetype.update)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getArchetypes()
  }

  return (
    <BasicTableRow key={archetype.id}>
      <BasicTableCell component="td" scope="row">
        {archetype.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {archetype.name}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {archetype.display_name}
      </BasicTableCell>

      {canEdit ? (
        <>
          <BasicTableCell component="td" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <ArchetypeDialog isOpen={open} close={handleClose} editArchetype={archetype} />
      ) : null}
    </BasicTableRow>
  )
}

export default ArchetypeTableRow
