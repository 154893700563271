import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { LineItem } from "../../../types"
import LineItemsRow from "./LineItemsRow"

interface Props {
  lineItems: LineItem[]
}

const LineItemsTable: FunctionComponent<Props> = ({ lineItems }) => {
  const columns = [
    "Product ID",
    "Name",
    "Description",
    "Note Prompt",
    "Note",
    "Quantity",
    "Price",
    "Download Code",
  ]

  const getLineItemsRows = useCallback(() => {
    return lineItems.map((lineItem: LineItem, index: number) => (
      <LineItemsRow key={index} lineItem={lineItem} />
    ))
  }, [lineItems])

  return <BasicTable columns={columns} rows={getLineItemsRows()} />
}

export default LineItemsTable
