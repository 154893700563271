import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Domain } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useTransferOutDomain = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainId: string | number,
      onSuccess?: () => void,
    ): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}/${domainId}/transfer-out`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { transferOutDomain: performAction, loading, error }
}

export default useTransferOutDomain
