import { useCallback } from "react"
import { NO_CONTEXT } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { useAuth } from "../../../services/auth-service"
import { ImageStatus } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateNoContextImage = () => {
  const { getUser } = useAuth()

  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, data: any, onSuccess?: () => any): Promise<ImageStatus> => {
      const username = getUser()?.employee?.email

      const response = await makeRequest<ImageStatus>(
        `${NO_CONTEXT}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...data, username }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  const createNoContextImage = useCallback(
    async (data: any, onSuccess: () => any) => {
      if (!prompt) {
        return
      }

      return await performAction(data, onSuccess)
    },
    [performAction],
  )

  return { createNoContextImage, loading, error }
}

export default useCreateNoContextImage
