import { Box, Button } from "@mui/material"
import  { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import RadioGroup, { RadioOption } from "../../components/shared/RadioGroup"
import SearchInput from "../../components/shared/SearchInput"
import useToggle from "../../hooks/useToggle"
import { Domain, Subdomain } from "../../types"
import { stripDomainJunk } from "../../utils/formatters"
import CreateNewDomainDialog from "./components/CreateNewDomainDialog"
import DomainTable from "./components/DomainsTable"
import TransferInDomainDialog from "./components/TransferInDomainDialog"
import useGetDomainsAndSubdomains, {
  DomainSubdomainParamTypeEnum,
} from "./hooks/useGetDomainsAndSubdomains"

const searchTypes: any = {
  user_id: {
    value: DomainSubdomainParamTypeEnum.UserId,
    label: "User ID",
  },
  site_id: {
    value: DomainSubdomainParamTypeEnum.SiteId,
    label: "Site ID",
  },
  url: {
    value: DomainSubdomainParamTypeEnum.URL,
    label: "URL",
  },
}

const DomainsScreen: FunctionComponent = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [searchType, setSearchType] = useState<DomainSubdomainParamTypeEnum>(
    DomainSubdomainParamTypeEnum.UserId,
  )
  const { getDomainsAndSubdomains, loading, error, allDomains } = useGetDomainsAndSubdomains()

  const handleSuccessfulSearch = useCallback(
    (
      results: (Domain | Subdomain)[],
      searchType: DomainSubdomainParamTypeEnum,
      searchValue: string | number,
    ) => {
      const stringSearchValue = stripDomainJunk(String(searchValue))
      setSearchParams({
        type: searchType,
        term: stringSearchValue,
      })
    },
    [setSearchParams],
  )

  const handleSearchFromRouter = useCallback(async () => {
    const type = searchParams.get("type")
    const term = searchParams.get("term")

    if (type && term) {
      setSearchType(searchTypes[type].value)
      const enumType: DomainSubdomainParamTypeEnum = searchTypes[type].value
      await getDomainsAndSubdomains(enumType, term)
    }
  }, [searchParams, getDomainsAndSubdomains])

  useEffect(() => {
    handleSearchFromRouter()
    // eslint-disable-next-line
  }, [searchParams])

  const getRadioOptions = useCallback(() => {
    const radioOptions: RadioOption[] = Object.keys(searchTypes).map(
      (key: string) => searchTypes[key],
    )
    return radioOptions
  }, [])

  const searchDomains = useCallback(
    async (term: string) => {
      const searchTerm = stripDomainJunk(term)
      await getDomainsAndSubdomains(searchType, searchTerm, handleSuccessfulSearch)
    },
    [getDomainsAndSubdomains, handleSuccessfulSearch, searchType],
  )

  // Create New Domain
  const {
    isOn: CreateNewDomainDialogIsOpen,
    turnOn: openCreateNewDomainDialog,
    turnOff: closeCreateNewDomainDialog,
  } = useToggle()

  // Transfer In Domain
  const {
    isOn: TransferInDomainDialogIsOpen,
    turnOn: openTransferInDomainDialog,
    turnOff: closeTransferInDomainDialog,
  } = useToggle()

  const searchTypeLabel = searchTypes[searchType].label

  const showTable = !loading && allDomains.length > 0
  return (
    <ScreenContainer title="Domain Search">
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <RadioGroup
          row
          value={searchType}
          label="Select Search Parameter"
          ariaLabel="Domain search parameter type selection"
          handleChange={setSearchType}
          options={getRadioOptions()}
        />
        <SearchInput
          handleSearch={searchDomains}
          title={`Search for Domains by ${searchTypeLabel}`}
          label={`Domain Search - ${searchTypeLabel}`}
          disabledWhenEmpty
          defaultValue={searchParams.get("term")}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "100%", mt: "16px" }}
          onClick={openCreateNewDomainDialog}
        >
          Create New Domain
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "100%", mt: "16px" }}
          onClick={openTransferInDomainDialog}
        >
          Transfer In Domain
        </Button>
      </Box>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      {showTable && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "500px" }}
        >
          <DomainTable allDomains={allDomains} />
        </Box>
      )}
      <CreateNewDomainDialog
        isOpen={CreateNewDomainDialogIsOpen}
        close={closeCreateNewDomainDialog}
      />
      <TransferInDomainDialog
        isOpen={TransferInDomainDialogIsOpen}
        close={closeTransferInDomainDialog}
      />
    </ScreenContainer>
  )
}

export default DomainsScreen
