import { Button, Switch, Tooltip } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import useToggle from "../../../hooks/useToggle"
import { scopes } from "../../../scopes"
import { useAuth } from "../../../services/auth-service"
import { BillingProduct, CreatePlanRequest, DomainTldPricing, Plan } from "../../../types"
import { formatPrice } from "../../../utils/formatters"
import CreatePlanDialog from "../../Plans/components/CreatePlanDialog"
import useCreatePlan from "../../Plans/hooks/useCreatePlan"
import DomainTldPricingDialog from "./DomainTldPricingDialog"
import useUpdateDomainTldPricing from "../hooks/useUpdateDomainTldPricing"

type DomainTldPricingTableRowProps = {
  domainTldPricing: DomainTldPricing
  getDomainTldPricings: Function
  plans: Plan[]
  getPlans: Function
  products: BillingProduct[]
}

const DomainTldPricingTableRow: FunctionComponent<DomainTldPricingTableRowProps> = ({
  domainTldPricing,
  getDomainTldPricings,
  plans,
  products,
  getPlans,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.domainTldPricing.update)

  const [open, setOpen] = useState(false)
  const [plan, setPlan] = useState<Plan | undefined>(undefined)

  const { updateDomainTldPricing } = useUpdateDomainTldPricing()

  const { createPlan, loading: creatingPlan, error: createError } = useCreatePlan()

  const { isOn: isCreating, turnOn: openCreate, turnOff: closeCreate } = useToggle()

  const handleCreatePlan = useCallback(
    async (item: CreatePlanRequest, callback: () => any) => {
      const onSuccess = () => {
        toast.success("Plan created successfully")
        callback()
        getPlans()
      }

      const plan = await createPlan(item, onSuccess)
      if (!plan) {
        return
      }
      await updateDomainTldPricing(domainTldPricing.id, { plan_id: plan.id })

      domainTldPricing.plan_id = plan.id
    },
    [createPlan],
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getDomainTldPricings()
  }

  useEffect(() => {
    const newPlan = plans.find((plan) => plan.id === domainTldPricing.plan_id)
    setPlan(newPlan)
  }, [plans])

  return (
    <BasicTableRow key={domainTldPricing.id}>
      <BasicTableCell component="td" scope="row">
        {domainTldPricing.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {domainTldPricing.tld}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <Switch checked={domainTldPricing.active} disabled />
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {formatPrice(domainTldPricing.registration_price)}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {formatPrice(domainTldPricing.renewal_price)}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {formatPrice(domainTldPricing.transfer_price)}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {formatPrice(domainTldPricing.restore_price)}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {plan ? (
          <Tooltip
            title={`${domainTldPricing.plan_id} - ${plan.title} - ${plan.version}.${plan.group}${plan.variant && `.${plan.variant}`}.${plan.duration}`}
          >
            <div>{formatPrice(plan.price)}</div>
          </Tooltip>
        ) : (
          domainTldPricing.plan_id
        )}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {domainTldPricing.legacy_tier}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {new Date(domainTldPricing.updated_at).toLocaleString()}
      </BasicTableCell>
      {canEdit ? (
        <>
          <BasicTableCell component="td" scope="row">
            <Button variant="outlined" color="primary" onClick={openCreate}>
              Create New Plan
            </Button>
            <Button variant="outlined" onClick={handleClickOpen}>
              Edit
            </Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <DomainTldPricingDialog
          isOpen={open}
          close={handleClose}
          editDomainTldPricing={domainTldPricing}
          plans={plans}
        />
      ) : null}
      <CreatePlanDialog
        isOpen={isCreating}
        close={closeCreate}
        creatingPlan={creatingPlan}
        hasError={!!createError}
        handleCreatePlan={handleCreatePlan}
        products={products}
        defaults={{
          product_id: products.find((product) => product.title.startsWith("V3 - TLD"))?.id,
          title: `TLD: ${domainTldPricing.tld}`,
          description: domainTldPricing.tld,
          group: "tld",
          price: {
            amount: Math.floor((domainTldPricing.registration_price + 600) / 0.7 / 100) * 100 + 99,
            currency: "usd",
          },
          duration: "annual",
          active: true,
          version: "v3",
          variant: domainTldPricing.tld.replaceAll(".", ""),
          domain_enabled: true,
        }}
      />
    </BasicTableRow>
  )
}

export default DomainTldPricingTableRow
