import { useEffect } from "react"
import { TEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { SetupFlow } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSetupFlows = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated): Promise<SetupFlow[]> => {
    return makeRequest<SetupFlow[]>(`${TEMPLATES}/setup-flows`, {}, { handleUnauthenticated })
  })

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  return {
    loading,
    error,
    getSetupFlows: performAction,
    setupFlows: data || [],
  }
}

export default useGetSetupFlows
