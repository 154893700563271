import { Box, Button, DialogContentText, TextField } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent } from "react"
import Dialog from "../../../components/shared/Dialog"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"
import * as Yup from "yup"
import useCreateCreditSubscription from "../hooks/useCreateCreditSubscription"
import toast from "react-hot-toast"

type Props = {
  siteId: number
  userId: number | null
  close: () => any
  isOpen: boolean
  onSuccess: () => void
}

const CreateCreditSubscriptionDialog: FunctionComponent<Props> = ({
  siteId,
  userId,
  close,
  isOpen,
  onSuccess,
}) => {
  const { createCreditSubscription, loading, error } = useCreateCreditSubscription()

  const validationSchema = Yup.object().shape({
    promoCode: Yup.string().required(),
    userOption: Yup.object().required(),
  })

  const formik = useFormik({
    initialValues: {
      promoCode: "",
      userOption: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      try {
        await createCreditSubscription(
          values.userOption.user?.id,
          siteId,
          values.promoCode,
          onSuccess,
        )
      } catch (err) {
        toast.error(JSON.stringify(err))
      }
    },
  })

  return (
    <Dialog
      title="Create Credit Subscription"
      isOpen={isOpen}
      handleClose={close}
      error={error}
      content={
        <>
          {error ? <br /> : null}
          <DialogContentText>
            Enter a promo code to create a credit subscription for the user/site combination below.
            <br />
            <br />
            Please ensure that the site is not currently tied to a subscription before proceeding.
            This will create a lifetime Pro subscription for the user.
            <br />
            <br />
            User: {userId}
          </DialogContentText>
          <FormikProvider value={formik}>
            <UserSearchAutocomplete defaultId={userId} />
          </FormikProvider>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <TextField
              margin="dense"
              id="promoCode"
              label="Promo Code"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.promoCode}
              error={!!formik.errors.promoCode}
            />
          </Box>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            Create Credit Subscription
          </Button>
        </>
      }
    />
  )
}

export default CreateCreditSubscriptionDialog
