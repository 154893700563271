import { DateTimePicker } from "@mui/lab"
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import useToggle from "../../../hooks/useToggle"
import { Coupon, CreatePromoRequest, Plan, BillingProduct } from "../../../types"
import SiteSearchAutocomplete from "../../Sites/components/SiteSearchAutocomplete"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"

type CreatePromoProps = {
  isOpen: boolean
  close: () => void
  handleCreatePromo: (promo: any) => any
  hasError: boolean
  creatingPromo: boolean
  coupons: Coupon[]
  plans: Plan[]
  products: BillingProduct[]
  loading?: boolean
}

const CreatePromoDialog: FunctionComponent<CreatePromoProps> = ({
  isOpen,
  close,
  hasError,
  handleCreatePromo,
  creatingPromo,
  coupons,
  plans,
  products,
  loading,
}) => {
  const { isOn: hasExpirationDate, toggle } = useToggle()

  const validationSchema = Yup.object().shape({
    code: Yup.string().nullable().optional().max(16),
    purpose: Yup.string().required(),
    redemptionLimit: Yup.number().integer().optional().nullable().min(1),
    siteOption: Yup.object().optional().nullable(),
    userOption: Yup.object().optional().nullable(),
    planId: Yup.number().integer().nullable().optional(),
    couponId: Yup.number().integer().required(),
  })

  const formik = useFormik({
    initialValues: {
      code: "",
      purpose: "",
      redemptionLimit: "",
      expirationDate: "",
      userOption: "",
      siteOption: "",
      planId: "",
      productId: "",
      duration: "",
      couponId: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const newPromo: CreatePromoRequest = {
        code: values.code || null,
        coupon_id: values.couponId || null,
        purpose: values.purpose,
        duration: values.duration || null,
        plan_id: values.planId || null,
        product_id: values.productId || null,
        user_id: values.userOption.user?.id || null,
        site_id: values.siteOption.id || null,
        redemption_limit: (values.redemptionLimit as number) || null,
        expiration_date: values.expirationDate || null,
      }
      handleCreatePromo(newPromo)
    },
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleSelectDate = (event: any) => {
    const date = new Date(event.$d)
    formik.setFieldValue("expirationDate", date)
  }

  return (
    <Dialog
      isOpen={isOpen}
      loading={loading}
      title="Create New Promo Code"
      handleClose={handleClose}
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {hasError && (
              <TextBody hasError={hasError}>There was a problem creating the promo code.</TextBody>
            )}
          </Row>
          <TextField
            id="purpose"
            label="Purpose"
            name="purpose"
            variant="outlined"
            margin="normal"
            type="text"
            fullWidth
            required
            onChange={formik.handleChange}
            value={formik.values.purpose}
            error={!!formik.errors.purpose}
          />

          <TextField
            id="code"
            name="code"
            label="Promo Code"
            variant="outlined"
            margin="normal"
            type="text"
            helperText={"Promo code will be randomly generated if omitted."}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.code}
            error={!!formik.errors.code}
          />

          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="coupon-select-label"
              sx={{
                mt: "10px",
              }}
            >
              Discount Type *
            </InputLabel>
            <Select
              fullWidth
              name="couponId"
              variant="outlined"
              id="couponId"
              label="Coupon"
              onChange={formik.handleChange}
              value={formik.values.couponId}
              sx={{ mt: "9px", mr: "4px" }}
              error={!!formik.errors.couponId}
            >
              {coupons.map((coupon) => (
                <MenuItem key={coupon.id} value={coupon.id}>
                  {`${coupon.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              mt: "10px",
            }}
          >
            <Select
              fullWidth
              label="Duration"
              name="duration"
              onChange={formik.handleChange}
              value={formik.values.duration}
            >
              {["annual", "monthly", "lifetime"].map((d) => (
                <MenuItem key={d} value={d}>
                  {`${d[0].toUpperCase()}${d.slice(1)}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="plan-select-label"
              sx={{
                mt: "10px",
              }}
            >
              Universe Subscription Plan (Optional)
            </InputLabel>
            <Select
              fullWidth
              name="planId"
              variant="outlined"
              id="planId"
              label="Plan"
              onChange={formik.handleChange}
              value={formik.values.planId}
              sx={{ mt: "9px", mr: "4px" }}
              error={!!formik.errors.planId}
            >
              {plans
                .filter((p) => p.version !== "legacy" && p.active)
                .map((plan: Plan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {`${plan.title} - ${plan.description} - $${plan.price.amount / 100}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="product-select-label"
              sx={{
                mt: "10px",
              }}
            >
              Universe Subscription Product (Optional)
            </InputLabel>
            <Select
              fullWidth
              name="productId"
              variant="outlined"
              id="productId"
              label="Product"
              onChange={formik.handleChange}
              value={formik.values.productId}
              sx={{ mt: "9px", mr: "4px" }}
              error={!!formik.errors.productId}
            >
              {products
                .filter((p) => p.active)
                .map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {`${product.title} - ${product.description}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            id="redemptionlimit"
            label="Number of allowed uses"
            name="redemptionLimit"
            fullWidth
            type="number"
            variant="outlined"
            margin="normal"
            onChange={formik.handleChange}
            value={formik.values.redemptionLimit}
            error={!!formik.errors.redemptionLimit}
          />

          <FormikProvider value={formik}>
            <UserSearchAutocomplete />
            <SiteSearchAutocomplete />
          </FormikProvider>

          <FormGroup sx={{ width: "100%", mt: "5px" }}>
            <FormControlLabel
              control={<Switch checked={hasExpirationDate} onChange={toggle} />}
              label="Expires?"
            />
            {hasExpirationDate && (
              <DateTimePicker
                value={formik.values.expirationDate}
                onChange={handleSelectDate}
                renderInput={(props: any) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="expirationDate"
                    {...props}
                    error={hasExpirationDate ? !!formik.errors.expirationDate : false}
                  />
                )}
                disablePast
                label="Expiration Date"
              />
            )}
          </FormGroup>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creatingPromo}
          >
            Create
          </Button>
        </>
      }
    />
  )
}
export default CreatePromoDialog
