import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Merchant } from "../../../types"
import MerchantRow from "./MerchantRow"

interface Props {
  merchants: Merchant[]
  paginationData: PaginationData
}

const MerchantTable: FunctionComponent<Props> = ({ merchants, paginationData }) => {
  const columns = [
    "Merchant ID",
    "Site ID",
    "Stripe Account ID",
    "Email",
    "Fee Level",
    "First Transaction Date",
    "Created At",
    "",
  ]

  const getCouponRows = useCallback(() => {
    return merchants.map((merchant: Merchant, index: number) => (
      <MerchantRow key={index} merchant={merchant} />
    ))
  }, [merchants])

  return <BasicTable columns={columns} rows={getCouponRows()} paginationOptions={paginationData} />
}

export default MerchantTable
