import { Box, Button } from "@mui/material"
import { FunctionComponent } from "react"
import ErrorComponent from "../../../components/shared/Error"
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title2, Subhead } from "../../../components/shared/Typography"
import useToggle from "../../../hooks/useToggle"
import { Page } from "../../../types"
import useRefreshPageSnapshot from "../hooks/useRefreshPageSnapshot"
import { Row, StyledText } from "./SiteLayoutShared"
import SiteLink from "./SiteLink"
import SitePageContentDialog from "./SitePageContentDialog"

interface Props {
  page?: Page | null
  handleGetPage: () => void
}

const SitePageOverview: FunctionComponent<Props> = ({ page, handleGetPage }) => {
  if (!page) {
    return null
  }

  // eslint-disable-next-line
  const { isOn: contentIsOpen, turnOn: openContent, turnOff: closeContent } = useToggle()
  // eslint-disable-next-line
  const { loading, error, refreshSnapshot } = useRefreshPageSnapshot()

  return (
    <>
      {error && <ErrorComponent error={error} />}
      <Box
        sx={{
          minWidth: "300px",
          maxWidth: "475px",
          borderRadius: "8px",
          border: (theme) => `1px solid ${theme.colors.disabled}`,
          p: "15px",
          marginTop: "10px",
          marginBottom: "10px",
          marginRight: "10px",
          display: "flex",
        }}
      >
        <img
          height={240}
          src={(page.thumbnails?.small && page.thumbnails?.small) ?? null}
          alt={page.title || page.slug || undefined}
          style={{ borderRadius: "8px" }}
        />
        <Box sx={{ marginLeft: "10px", marginBottom: "10px", marginRight: "10px" }}>
          <Title2>{page.type}:</Title2>
          <Row>
            <Subhead>ID: </Subhead>
            <StyledText>{page.id}</StyledText>
          </Row>
          <Row>
            <Subhead>Title: </Subhead>
            <StyledText>{page.title}</StyledText>
          </Row>
          {page.description ? (
            <Row>
              <Subhead>Description: </Subhead>
              <StyledText>{page.description}</StyledText>
            </Row>
          ) : null}
          <Row>
            <Subhead>Path: </Subhead>
            <StyledText>{page.path}</StyledText>
          </Row>
          <Row>
            <Subhead>Hidden: </Subhead>
            <StyledText>{String(page.is_hidden)}</StyledText>
          </Row>
          <Row>
            <Subhead>Homepage: </Subhead>
            <StyledText>{String(page.is_homepage)}</StyledText>
          </Row>
          {page.children_count > 0 ? (
            <Row>
              <Subhead>Subpage Count: </Subhead>
              <StyledText>{page.children_count}</StyledText>
            </Row>
          ) : null}
          <Row>
            <Subhead>Site ID: </Subhead>
            <SiteLink siteId={page.site_id}>{page.site_id}</SiteLink>
          </Row>
        </Box>
        <Box sx={{ marginBottom: "10px", marginRight: "10px" }}>
          <Row>
            <Button
              variant="contained"
              fullWidth
              sx={{
                maxWidth: "300px",
                marginBottom: "10px",
              }}
              onClick={openContent}
            >
              View Content
            </Button>
          </Row>
          <Row>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  maxWidth: "300px",
                }}
                onClick={() => refreshSnapshot(page.site_id, page.id, handleGetPage)}
              >
                Refresh Snapshot
              </Button>
            )}
          </Row>
        </Box>
      </Box>
      <SitePageContentDialog content={page.content} isOpen={contentIsOpen} close={closeContent} />
    </>
  )
}

export default SitePageOverview
