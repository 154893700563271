import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SearchInput from "../../components/shared/SearchInput"
import { stripDomainJunk } from "../../utils/formatters"
import useGetDomainPropagation from "./hooks/useGetDomainPropagation"
import { Check, Close } from "@mui/icons-material"

const DomainPropagationScreen: FunctionComponent = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [searchDomain, setSearchDomain] = useState<string>("")
  const [recordType, setRecordType] = useState<string>("A")
  const { getDomainPropagation, loading, error, propagation } = useGetDomainPropagation()

  const handleSuccessfulSearch = useCallback(
    (domain: string, type: string) => {
      const cleanedDomain = stripDomainJunk(String(domain))
      setSearchDomain(cleanedDomain)
      setRecordType(type)
      setSearchParams({
        domain: cleanedDomain,
        type: type,
      })
    },
    [setSearchParams],
  )

  const handleSearchFromRouter = useCallback(async () => {
    const domain = searchParams.get("domain")
    const type = searchParams.get("type")

    if (domain && type) {
      setSearchDomain(domain)
      setRecordType(type)
      await getDomainPropagation(domain, type)
    }
  }, [searchParams, getDomainPropagation])

  useEffect(() => {
    handleSearchFromRouter()
    // eslint-disable-next-line
  }, [searchParams])

  const handleGetPropagation = useCallback(
    async (domain: string) => {
      const cleanedDomain = stripDomainJunk(domain)
      await getDomainPropagation(cleanedDomain, recordType)
      handleSuccessfulSearch(cleanedDomain, recordType)
    },
    [getDomainPropagation, handleSuccessfulSearch, recordType],
  )

  return (
    <ScreenContainer title="Domain Propagation">
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <FormControl variant="outlined" fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="record-type-label">Record Type</InputLabel>
          <Select
            labelId="record-type-label"
            id="record-type"
            value={recordType}
            onChange={(event) => setRecordType(event.target.value as string)}
            label="Record Type"
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="AAAA">AAAA</MenuItem>
            <MenuItem value="CNAME">CNAME</MenuItem>
            <MenuItem value="MX">MX</MenuItem>
            <MenuItem value="TXT">TXT</MenuItem>
            <MenuItem value="NS">NS</MenuItem>
          </Select>
        </FormControl>
        <SearchInput
          handleSearch={handleGetPropagation}
          disabledWhenEmpty
          label="Domain"
          defaultValue={searchParams.get("domain")}
          buttonText="Get Propagation Details"
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      <Box border={1} borderRadius={5} p={2}>
        {propagation?.map((prop, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            alignItems="center"
            borderBottom={index !== propagation.length - 1 ? 1 : 0}
            borderColor="grey.300"
            py={1}
          >
            <Box width="10%" textAlign="center">
              <b>
                {prop.server.emoji} {prop.server.country}
              </b>
            </Box>
            <Box width="10%" textAlign="center">
              {prop.server.provider}
            </Box>
            <Box width="75%" textAlign="center" component="pre">
              {prop.results?.length > 0 ? (
                prop.results.map((result, resultIndex) => (
                  <Typography key={resultIndex} variant="body2" component="div">
                    <pre>{result}</pre>
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" component="div">
                  No results
                </Typography>
              )}
            </Box>
            <Box width="5%" textAlign="center">
              {prop.results?.length > 0 ? (
                <Check style={{ verticalAlign: "middle", color: "green" }} />
              ) : (
                <Close style={{ verticalAlign: "middle", color: "red" }} />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </ScreenContainer>
  )
}

export default DomainPropagationScreen
