import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { BillingProduct, CreateBillingProduct } from "../../types"
import useListEntitlements from "../Entitlements/hooks/useListEntitlements"
import CreateProductDialog from "./components/CreateProductDialog"
import EditProductDialog from "./components/EditProductDialog"
import EditProductEntitlementsDialog from "./components/EditProductEntitlementsDialog"
import ProductsTable from "./components/ProductsTable"
import useCreateProduct from "./hooks/useCreateProduct"
import useGetProducts from "./hooks/useGetProducts"

const BillingProductsScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.billing.products.create)
  const canEdit = hasScope(scopes.billing.product.update)
  const canEditEntitlements = hasScope(scopes.billing.product.entitlements.update)

  const { createProduct, loading: creatingProduct, error: creationError } = useCreateProduct()
  const {
    isOn: isCreatingProduct,
    turnOn: openCreateProduct,
    turnOff: closeCreateProduct,
  } = useToggle()

  const { isOn: isEditingProduct, turnOn: openEditProduct, turnOff: closeEditProduct } = useToggle()

  const {
    listEntitlements,
    loading: loadingEntitlements,
    error: entitlementsError,
    entitlements,
  } = useListEntitlements(1000)

  const {
    isOn: isEditingProductEntitlements,
    turnOn: openEditProductEntitlements,
    turnOff: closeEditProductEntitlements,
  } = useToggle()

  const { getProducts, loading, error, products, paginationData } = useGetProducts(50)

  const [productForEdit, setProductForEdit] = useState<BillingProduct>()

  const handleOpenEdit = useCallback(
    (product: BillingProduct) => {
      setProductForEdit(product)
      closeCreateProduct()
      closeEditProductEntitlements()
      openEditProduct()
    },
    [closeCreateProduct, closeEditProductEntitlements, openEditProduct],
  )

  const handleCloseEdit = useCallback(async () => {
    setProductForEdit(undefined)
    closeEditProduct()
    closeEditProductEntitlements()
  }, [closeEditProduct, closeEditProductEntitlements])

  const handleOpenEditEntitlements = useCallback(
    (product: BillingProduct) => {
      setProductForEdit(product)
      closeCreateProduct()
      closeEditProduct()
      openEditProductEntitlements()
    },
    [closeCreateProduct, closeEditProduct, openEditProductEntitlements],
  )

  useEffect(() => {
    getProducts()
    listEntitlements()
    // eslint-disable-next-line
  }, [])

  const handleCreateProduct = useCallback(
    async (item: CreateBillingProduct, callback: Function) => {
      const onSuccess = async () => {
        await getProducts()
        callback()
        toast.success("Successfully created product")
      }
      await createProduct(item, onSuccess)
    },
    [createProduct, getProducts],
  )

  const showTable = !loading && products.length > 0

  return (
    <ScreenContainer title="Products" requiresScope={scopes.billing.products.read}>
      {error && <ErrorComponent error={error} />}
      {canCreate && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ my: "12px", width: "300px" }}
          onClick={openCreateProduct}
        >
          Create New Product
        </Button>
      )}

      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {showTable && (
        <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
          <ProductsTable
            products={products}
            paginationData={paginationData}
            handleOpenEditProduct={handleOpenEdit}
            handleOpenEditEntitlements={handleOpenEditEntitlements}
            canEdit={canEdit}
            canEditEntitlements={canEditEntitlements}
          />
        </Box>
      )}

      <CreateProductDialog
        isOpen={isCreatingProduct}
        close={closeCreateProduct}
        creatingProduct={creatingProduct}
        hasError={!!creationError}
        handleCreateProduct={handleCreateProduct}
      />
      <EditProductDialog
        product={productForEdit}
        isOpen={isEditingProduct}
        close={handleCloseEdit}
        handleGetProducts={getProducts}
      />
      <EditProductEntitlementsDialog
        open={isEditingProductEntitlements}
        handleClose={handleCloseEdit}
        product={productForEdit}
        handleGetProducts={getProducts}
        entitlements={entitlements}
        loadingEntitlements={loadingEntitlements}
        entitlementsError={!!entitlementsError}
      />
    </ScreenContainer>
  )
}

export default BillingProductsScreen
