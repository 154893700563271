import { GITHUB } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import type { PullRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPullRequests = () => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated): Promise<PullRequest[]> => {
      return makeRequest<PullRequest[]>(GITHUB + "/pulls", {}, { handleUnauthenticated })
    },
  )

  return {
    loading,
    error,
    performAction,
    data: data || [],
  }
}

export default useGetPullRequests
