import  { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  subdomainId: string | number
}

type SubdomainLinkProps = Omit<Props, "to">

const SubdomainLink: FunctionComponent<SubdomainLinkProps> = ({
  subdomainId,
  children,
  ...rest
}) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/domains/subdomain/${subdomainId}`}>
    {children}
  </Link>
)

export default SubdomainLink
