import { EMAILFORWARDSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useSetupEmailForwardById = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, emailForwardID: number, onSuccess?: () => any): Promise<void> => {
      const response = await makeRequest<void>(
        `${EMAILFORWARDSV3}/${emailForwardID}/setup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { setupEmailForward: performAction, loading, error }
}

export default useSetupEmailForwardById
