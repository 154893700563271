import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  domainID: string | number
}

const AddVercelDialog: FunctionComponent<Props> = ({ domainID, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Add Vercel to Domain"
      errorMessage={hasError ? "There was a problem adding Vercel to this domain" : null}
      affirmativeButtonText="Add Vercel"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>
              You are about to add Vercel to domainId: {domainID}. Doing this will enable SSL. If
              the initial Vercel setup failed, this will trigger the setup flow again.
            </TextBody>
          </ConfirmationRow>
          <ConfirmationRow pb="15px" pt="8px">
            <TextBody>Would you like to add Vercel to this domain?</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default AddVercelDialog
