import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Domain, PutDomainStatus } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useEditDomainStatus = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      id: number,
      body: PutDomainStatus,
      onSuccess?: () => any,
    ): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}/${id}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...body }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { editStatus: performAction, loading, error }
}

export default useEditDomainStatus
