import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import AIConversationTable from "./components/AIConversationTable"
import useGetConversations from "./hooks/useGetConversations"
import { useSearchParams } from "react-router-dom"
import SearchInput from "../../components/shared/SearchInput"

const AIConverasationScreen: FunctionComponent = () => {
  const {
    loading,
    error,
    getConversations,
    conversations,
    paginationData: outputData,
  } = useGetConversations()

  const [searchParams, setSearchParams] = useSearchParams()

  const [page, setPage] = useState(searchParams.get("page") || "1")
  const [perPage, setPerPage] = useState(searchParams.get("perPage") || "25")
  const [query, setQuery] = useState(searchParams.get("q") || "")

  useEffect(() => {
    const newPage = searchParams.get("page") || "1"
    const newPerPage = searchParams.get("perPage") || "25"
    const newQuery = searchParams.get("q") || ""

    if (!loading) {
      getConversations(Number.parseInt(newPage) - 1, Number.parseInt(newPerPage), newQuery)
    }

    setPage(newPage)
    setPerPage(newPerPage)
    setQuery(newQuery)
  }, [searchParams])

  const searchConversations = (search: string) => {
    setSearchParams({ q: search, page: "1", perPage })
  }

  const paginationData = {
    count: outputData.count,
    page: Number.parseInt(page) - 1,
    rowsPerPage: Number.parseInt(perPage),
    onPageChange: (event: any, page: number) =>
      setSearchParams({ page: (page + 1).toString(), perPage, q: query }),
    onRowsPerPageChange: (event: any) =>
      setSearchParams({ perPage: event.target.value.toString(), page: "1", q: query }),
  }

  return (
    <ScreenContainer title="AI Conversations" requiresScope={scopes.blockTemplates.read}>
      {error ? <ErrorComponent error={error} /> : ""}

      <>
        <br />
        <SearchInput
          handleSearch={searchConversations}
          title={`Search`}
          label={`Search`}
          defaultValue={searchParams.get("q")}
        />
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <AIConversationTable conversations={conversations} paginationData={paginationData} />
          )}
        </div>
      </>
    </ScreenContainer>
  )
}

export default AIConverasationScreen
