import { CONVERSATIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { AIConversationMessageV2 } from "../../../types"

const useGetConversationMessagesById = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (
      handleUnauthenticated,
      conversationId: number,
      offset = 0,
      limit: number = 100,
    ): Promise<PagedResponse<AIConversationMessageV2>> => {
      return makeRequest<PagedResponse<AIConversationMessageV2>>(
        `${CONVERSATIONS}/${conversationId}/messages?offset=${offset}&limit=${limit}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getConversationMessages: performAction, messages: data?.results }
}

export default useGetConversationMessagesById
