import { BILLINGPRODUCTSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Entitlement, ProductEntitlement } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetProductEntitlements = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      productId: number,
      onSuccess?: Function,
    ): Promise<ProductEntitlement[]> => {
      let queryString = `${BILLINGPRODUCTSV4}/${productId}/entitlements`
      const response = await makeRequest<ProductEntitlement[]>(
        queryString,
        {},
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess(response)
      }

      return response
    },
  )

  return {
    getProductEntitlements: performAction,
    loading,
    error,
    productEntitlements: data || [],
  }
}

export default useGetProductEntitlements
