import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Site } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteSite = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      siteId: string | number,
      onSuccess?: () => any,
    ): Promise<Site> => {
      const response = await makeRequest<Site>(
        `${SITESV4}/${siteId}/permanent`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteSite: performAction, loading, error }
}

export default useDeleteSite
