import React, { FunctionComponent, useState } from "react"
import { Button, Switch } from "@mui/material"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { Tag, Collection, TemplateFilter } from "../../../types"
import TemplateFilterDialog from "./TemplateFilterDialog"

type TemplateFilterTableRowProps = {
  templateFilter: TemplateFilter
  getTemplateFilters: () => any
  collections?: Collection[]
  tags?: Tag[]
}

const TemplateFilterTableRow: FunctionComponent<TemplateFilterTableRowProps> = ({
  templateFilter,
  getTemplateFilters,
  collections,
  tags,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.templateFilter.update)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getTemplateFilters()
  }

  return (
    <BasicTableRow key={templateFilter.id}>
      <BasicTableCell component="td" scope="row">
        {templateFilter.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {templateFilter.title}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {templateFilter.type}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {templateFilter.key}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {templateFilter.sort_order}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <Switch checked={templateFilter.active} disabled />
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {collections?.find((x) => x.id === templateFilter.collection_id)?.name ?? ""}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {tags?.find((x) => x.id === templateFilter.tag_id)?.display_title ?? ""}
      </BasicTableCell>

      {canEdit ? (
        <>
          <BasicTableCell component="td" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <TemplateFilterDialog
          isOpen={open}
          close={handleClose}
          editTemplateFilter={templateFilter}
          collections={collections}
          tags={tags}
        />
      ) : null}
    </BasicTableRow>
  )
}

export default TemplateFilterTableRow
