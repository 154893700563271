import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { DOMAINS } from "../../../constants/urlDefs"
import { DNSimpleZone } from "../../../types"

const useDeleteDNSRecord = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainId: number,
      recordId: number,
      onSuccess?: () => any,
    ): Promise<DNSimpleZone> => {
      const response = await makeRequest<DNSimpleZone>(
        `${DOMAINS}/${domainId}/dns/records/${recordId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { deleteDNSRecord: performAction, loading, error }
}

export default useDeleteDNSRecord
