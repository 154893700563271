import { Button } from "@mui/material"
import { FunctionComponent, useCallback } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { UserGroup } from "../../../types"
import { Link } from "react-router-dom"

interface Props {
  userGroup: UserGroup
  handleEdit: (userGroup: UserGroup) => any
  canEdit: boolean
}

const UserGroupRow: FunctionComponent<Props> = ({ userGroup, handleEdit, canEdit }) => {
  const onClickEdit = useCallback(
    (e: any) => {
      handleEdit(userGroup)
    },
    [userGroup, handleEdit],
  )

  return (
    <BasicTableRow sx={{ width: "100%" }}>
      <BasicTableCell component="th" scope="row">
        {userGroup.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {userGroup.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Link to={`/users/groups/${userGroup.id}`}>Edit Users</Link>
      </BasicTableCell>
      {canEdit ? (
        <BasicTableCell
          component="th"
          scope="row"
          style={{
            whiteSpace: "normal",
            maxWidth: "100px",
            wordWrap: "break-word",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickEdit}
            sx={{ width: "80px" }}
          >
            Edit
          </Button>
        </BasicTableCell>
      ) : null}
    </BasicTableRow>
  )
}

export default UserGroupRow
