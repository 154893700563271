import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Identity } from "../../../types"
import { USERS } from "../../../constants/urlDefs"

const useGetUserIdentities = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, userId: string | number): Promise<Identity[]> => {
    return makeRequest<Identity[]>(`${USERS}/${userId}/identities`, {}, { handleUnauthenticated })
  })

  return { loading, error, getUserIdentities: performAction, users: data }
}

export default useGetUserIdentities
