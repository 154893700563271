import { styled } from "@mui/material/styles"
import { FunctionComponent, useState } from "react"
import { TextBody } from "../../../components/shared/Typography"
import { Domain } from "../../../types"
import useUnlinkDomain from "../hooks/useUnlinkDomain"
import enforceError from "../../../utils/enforce-error"
import { Button, DialogContentText } from "@mui/material"
import Dialog from "../../../components/shared/Dialog"

interface Props {
  domain: Domain
  close: () => any
  isOpen: boolean
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const UnlinkDomainConfirmationDialog: FunctionComponent<Props> = ({ domain, close, isOpen }) => {
  const [error, setError] = useState<Error>()
  const { unlinkDomain, error: unlinkError, loading } = useUnlinkDomain()

  const unlink = async () => {
    try {
      unlinkDomain(domain.id, close)
    } catch (err) {
      setError(enforceError(err))
    }
  }

  return (
    <Dialog
      title="Unlink Domain"
      isOpen={isOpen}
      handleClose={close}
      error={error || unlinkError}
      loading={loading}
      content={
        <>
          {error || unlinkError ? <br /> : null}
          <DialogContentText>
            Unlinking a domain will disconnect it from the site and free up the subscription domain.
          </DialogContentText>
          <DialogContentText>Would you like to continue?</DialogContentText>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={unlink} color="primary">
              Unlink Domain
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default UnlinkDomainConfirmationDialog
