import { Box } from "@mui/material"
import { useField } from "formik"
import  { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Caption1 } from "../../../components/shared/Typography"
import { DomainTypeEnum } from "../../../types"
import useGetSitesByUserId from "../hooks/useGetSitesByUserId"
import SiteSelect from "./SiteSelect"
interface Props {
  userFieldName: string
  siteFieldName: string
  highlightAssigned?: boolean
  domainType?: DomainTypeEnum
}

const SitesByUserSelect: FunctionComponent<Props> = ({
  userFieldName,
  siteFieldName,
  highlightAssigned,
  domainType,
}) => {
  const [siteMap, setSiteMap] = useState<any>({})

  // eslint-disable-next-line
  const [siteField, siteMeta, siteHelpers] = useField(siteFieldName)

  // eslint-disable-next-line
  const [userField, userMeta, userHelpers] = useField(userFieldName)

  const { getSitesByUserId, sites, loading } = useGetSitesByUserId(1000)
  const userVal = userMeta?.value?.user?.id

  const handleGetSites = useCallback(async () => {
    if (userVal) {
      const sites = await getSitesByUserId(userVal)
      if (sites?.results?.length) {
        const map = sites.results.reduce((obj, item) => {
          return Object.assign(obj, { [item.id]: item })
        }, {})

        setSiteMap(map)
      } else {
        setSiteMap({})
      }
    } else {
      siteHelpers.setValue("")
      setSiteMap({})
    }
  }, [userVal, getSitesByUserId, siteHelpers])

  const handleChangeValue = useCallback(
    (e: any) => {
      const val = e.target.value
      if (val) {
        siteHelpers.setValue(siteMap[val])
        siteHelpers.setError(undefined)
      } else {
        siteHelpers.setValue("")
      }
    },
    [siteMap, siteHelpers],
  )

  useEffect(() => {
    handleGetSites()
    // eslint-disable-next-line
  }, [userVal])

  const hasValidValue = !!siteMeta?.value?.id && !!siteMap[siteMeta?.value?.id]

  return (
    <Box sx={{ my: "8px" }}>
      <SiteSelect
        handleChange={handleChangeValue}
        sites={userVal ? sites : []}
        disabled={!userVal || loading}
        highlightAssigned={highlightAssigned}
        domainType={domainType}
        value={hasValidValue ? siteMeta?.value?.id : ""}
      />
      <Box width="100%" height="12px">
        <Caption1 hasError={!!siteMeta.error}> {siteMeta.error && "Required"} </Caption1>
      </Box>
    </Box>
  )
}

export default SitesByUserSelect
