import { createTheme } from "@mui/material/styles"

// TODO: reduce this list by converting things to use mui theme.palette

const theme = createTheme({
  colors: {
    primary: "#2c5aff",
    primaryText: "#000",
    disabled: "#bbb",
    card: "#f2f2f2",
    border: "#aaa",
    error: "#ff604f",
    warning: "#ff9800",
    successLight: "#81c784",
    successMain: "#66bb6a",
    successDark: "#388e3c",
    appHeader: "#0044FF",
    appHeaderSecondary: "#00000",
  },
  palette: {
    primary: {
      main: "#0044FF",
      dark: "#0044FF",
    },
    secondary: {
      main: "#000000",
      dark: "#000000",
    },
  },
})

export default theme
