import { useEffect } from "react"
import { EMPLOYEES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Employee } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetEmployees = () => {
  const { data, loading, error, performAction: getEmployees } = useHandleRequest((handleUnauthenticated): Promise<Employee[]> => {
    return makeRequest<Employee[]>(`${EMPLOYEES}`, {}, { handleUnauthenticated })
  })

  useEffect(() => {
    getEmployees()
    // eslint-disable-next-line
  }, [])

  return {
    loading,
    error,
    getEmployees,
    employees: data || [],
  }
}

export default useGetEmployees
