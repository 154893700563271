import { useCallback, useEffect, useState } from "react"
import { SITEPRODUCTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Product } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetSiteProducts = (defaultRowsPerPage = 25) => {
  const [siteId, setSiteId] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, siteId: number): Promise<PagedResponse<Product>> => {
      return makeRequest<PagedResponse<Product>>(
        `${SITEPRODUCTSV3}?site=${siteId}&limit=${encodeURIComponent(
          rowsPerPage,
        )}&offset=${encodeURIComponent(page * rowsPerPage)}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (siteId) {
      performAction(siteId)
    }

    // eslint-disable-next-line
  }, [page, rowsPerPage, siteId])

  const getProducts = useCallback(
    async (siteId: number) => {
      setSiteId(siteId)
      return await performAction(siteId)
    },
    [performAction],
  )

  return {
    loading,
    error,
    getProducts,
    products: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetSiteProducts
