import { FunctionComponent, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import AIImageGenerationStylesTable from "./components/AIImageGenerationStyleTable"
import AIImageGenerationStyleDialog from "./components/AIImageGenerationStyleDialog"
import useListAIImageGenerationStyles from "./hooks/useListAIImageGenerationStyles"

const AIImageGenerationStyleScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.ai.imageGenerationStyles.create)

  const { loading, error, listAIImageGenerationStyles, aiImageGenerationStyles, paginationData } =
    useListAIImageGenerationStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    listAIImageGenerationStyles()
  }

  useEffect(() => {
    listAIImageGenerationStyles()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer
      title="Ai Image Generation Style"
      requiresScope={scopes.ai.imageGenerationStyles.read}
    >
      {error ? <ErrorComponent error={error} /> : ""}
      {loading && !error ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <LoadingSpinner />
        </div>
      ) : (
        ""
      )}
      <>
        {canCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              onClick={handleClickOpen}
            >
              Create Ai Image Generation Style
            </Button>
            <br />
          </>
        )}
        <AIImageGenerationStylesTable
          aiImageGenerationStyles={aiImageGenerationStyles}
          paginationData={paginationData}
          getAIImageGenerationStyles={listAIImageGenerationStyles}
        />
        {open ? <AIImageGenerationStyleDialog isOpen={open} close={handleClose} /> : null}
      </>
    </ScreenContainer>
  )
}

export default AIImageGenerationStyleScreen
