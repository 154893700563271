import { Box } from "@mui/material"
import { FunctionComponent, ReactNode } from "react"
import { useAuth } from "../../../services/auth-service"
import { LargeTitle, TextBody } from "../Typography"
import ForbiddenPage from "./ForbiddenPage"

interface ScreenProps {
  title?: string
  subtitle?: string
  children?: ReactNode
  requiresScope?: string
  iconUrl?: string
}

const ScreenContainer: FunctionComponent<ScreenProps> = ({
  title,
  subtitle,
  children,
  requiresScope,
  iconUrl,
}) => {
  const { hasScope } = useAuth()

  if (!!requiresScope && !hasScope(requiresScope)) {
    return <ForbiddenPage />
  } else {
    return (
      <Box sx={{ flexDirection: "column" }}>
        {!!title && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {iconUrl && (
              <img
                style={{ height: 34, width: 34, marginRight: "10px" }}
                src={iconUrl}
                alt="Site favicon"
              />
            )}
            <div>
              <LargeTitle>{title}</LargeTitle>
              <TextBody>{subtitle}</TextBody>
            </div>
          </Box>
        )}

        <Box display="flex" sx={{ flexDirection: "column", flex: 1 }}>
          {children}
        </Box>
      </Box>
    )
  }
}

export default ScreenContainer
