import { Box, Button } from "@mui/material"
import { FunctionComponent, useState } from "react"
import { Subhead } from "../../../components/shared/Typography"
import { DNSimpleData, Domain } from "../../../types"
import { Row, StyledText, StyledTitle } from "./DomainLayoutShared"
import ManageAutoRenewDialog from "./ManageAutoRenewDialog.tsx"
import { toast } from "react-hot-toast"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

interface Props {
  data: DNSimpleData | undefined
  domain: Domain
  refreshDomain: () => any
}

const DomainOverview: FunctionComponent<Props> = ({ data, domain, refreshDomain }) => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()

  if (!data) {
    return null
  }

  // Permissions
  const canEnableAutoRenew = hasScope(scopes.domain.autoRenew.enable)
  const canDisableAutoRenew = hasScope(scopes.domain.autoRenew.disable)

  const handleAutoRenew = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSuccess = () => {
    setOpen(false)
    toast.success("Successfully changed auto-renew status")
    refreshDomain()
  }

  return (
    <Box sx={{ ml: "30px" }}>
      <Row>
        <StyledTitle>DNSimple</StyledTitle>
      </Row>
      <Row>
        <Subhead>Auto Renew: </Subhead>
        <StyledText>{data.auto_renew ? "On" : "Off"}</StyledText>
      </Row>
      <Row>
        <Subhead>State: </Subhead>
        <StyledText>{data.state}</StyledText>
      </Row>
      <Row>
        <Subhead>Created At: </Subhead>
        <StyledText>{data.created_at}</StyledText>
      </Row>
      <Row>
        <Subhead>Expires At: </Subhead>
        <StyledText>{data.expires_at}</StyledText>
      </Row>
      {(canEnableAutoRenew || canDisableAutoRenew) &&
      data.state !== "expired" &&
      data.state !== "hosted" ? (
        <Button
          sx={{ mt: 1 }}
          variant="contained"
          color={data.auto_renew ? "error" : "info"}
          onClick={handleAutoRenew}
        >
          {data.auto_renew ? "Disable Auto-Renew" : "Enable Auto-Renew"}
        </Button>
      ) : null}
      {open ? (
        <ManageAutoRenewDialog
          isOpen={open}
          close={handleClose}
          domain={domain}
          dnsimpleData={data}
          handleSuccess={handleSuccess}
        />
      ) : null}
    </Box>
  )
}

export default DomainOverview
