import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Collection, Tag } from "../../../types"
import TagRow from "./TagRow"

interface Props {
  tags: Tag[]
  collections: Collection[]
  paginationData: PaginationData
  close: () => any
}

const TagsTable: FunctionComponent<Props> = ({ tags, collections, paginationData, close }) => {
  const columns = ["ID", "Title", "Display Title", "Description", "Action"]

  const getRows = useCallback(() => {
    return tags.map((tag: Tag, index: number) => (
      <TagRow key={index} tag={tag} collections={collections} close={close} />
    ))
  }, [tags, close, collections])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default TagsTable
