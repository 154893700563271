import { Button, TextField } from "@mui/material"
import { Box, ImageList, ImageListItem, ImageListItemBar } from "@mui/material"

import { useFormik } from "formik"
import { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { Page, PageSectionType, Site, UpdatePageSectionType } from "../../../types"
import useCreatePageSectionType from "../hooks/useCreatePageSectionType"
import useDeletePageSectionType from "../hooks/useDeletePageSectionType"
import useUpdatePageSectionType from "../hooks/useUpdatePageSectionType"

// @ts-ignore
import { JSONEditor } from "react-json-editor-viewer"
import useSearchSites from "../../Sites/hooks/useSearchSites"
import useGetSitePages from "../../Sites/hooks/useGetSitePages"
import useGetSitePageById from "../../Sites/hooks/useGetSitePageById"
import useCreateBlockTemplate from "../../BlockTemplates/hooks/useCreateBlockTemplate"
import useUpdatePageContent from "../hooks/useUpdatePageContent"

interface CheckmarkProps {
  show: boolean
  children: any
}
const CheckmarkOverlay: FunctionComponent<CheckmarkProps> = ({ show, children }) => {
  const overlayStyles = {
    position: "absolute",
    top: "0",
    right: "0",

    // width: "24px",
    // height: "24px",
    display: "flex",
    alignItems: "top",
    justifyContent: "top",
    backgroundColor: show ? "rgba(0, 0, 0, 0.5)" : "transparent",
    opacity: show ? 1 : 0,
    transition: "opacity 0.1s ease",
    // pointerEvents: show ? "auto" : "none",
    zIndex: "9999",
  }

  return show ? (
    <div style={{ ...overlayStyles, position: "absolute" }}>
      {children}
      <span style={{ color: "green", fontSize: "24pt", float: "right" }}>&#x2713;</span>
    </div>
  ) : (
    <>{children}</>
  )
}

interface Props {
  isOpen: boolean
  close: () => void
  pageSectionTypeUpdate?: PageSectionType | undefined
}

const PageSectionTypeDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  pageSectionTypeUpdate,
}) => {
  const { createPageSectionType, loading, error } = useCreatePageSectionType()
  const {
    updatePageSectionType,
    loading: updateLoading,
    error: updateError,
  } = useUpdatePageSectionType()
  const {
    deletePageSectionType,
    loading: deleteLoading,
    error: deleteError,
  } = useDeletePageSectionType()

  const { searchSites } = useSearchSites()
  const { getPages } = useGetSitePages(1000)
  const { getPageById } = useGetSitePageById()

  const { createBlockTemplate } = useCreateBlockTemplate()

  const { updatePageContent } = useUpdatePageContent()

  const [checked, setChecked] = useState<{ [key: number]: boolean }>({})

  const [pages, setPages] = useState<Page[]>([])
  const [site, setSite] = useState<Site>()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    fields: Yup.object().optional().default({}),
    priority: Yup.number().required(),
    category: Yup.string().nullable().optional(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleDeletePageSectionType: any = async () => {
    if (pageSectionTypeUpdate) {
      await deletePageSectionType(pageSectionTypeUpdate.id, handleDeletePageSectionTypeSuccess)
    }
  }

  const handleDeletePageSectionTypeSuccess: any = async () => {
    toast.success("Successfully deleted section type")
    close()
  }

  const handleJsonChange: any = async (
    key: string,
    value: unknown,
    parent: { [key: string]: unknown },
    data: { fields: { [key: string]: string } },
  ) => {
    formik.setFieldValue("fields", parent)
  }

  const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>
    return (...args: Parameters<T>): void => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func(...args)
      }, delay)
    }
  }

  const importFromURL = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return

    const url = new URL(event.target.value)

    const host = url.host
    const path = url.pathname

    const sites = await searchSites(host)

    if (!sites || sites.results.length === 0) {
      // toast.error("Site not found")
      return
    }
    // console.log(site.results[0].id)
    setSite(sites.results[0])
    console.log(`received site ${sites.results[0].id} for host {${host}} and path {${path}}`)

    const siteId = site?.id || sites.results[0].id
    // console.log(`received site ${siteId} for path {${path}}`)
    const grids = await getPages(siteId, `&type=Grid&route=${encodeURIComponent(path)}`)
    console.log(grids)
    setPages(grids?.results || [])
  }, 100)

  const formik = useFormik({
    initialValues: {
      name: "",
      fields: {},
      priority: 500,
      category: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      // console.log("onSubmit", values)
      if (pageSectionTypeUpdate) {
        const updateData: UpdatePageSectionType = {
          name: values.name,
          fields: values.fields,
          priority: values.priority,
          category: values.category === "" ? null : values.category,
        }

        await updatePageSectionType(
          pageSectionTypeUpdate.id,
          updateData,
          handleUpdatePageSectionTypeSuccess,
        )

        pages.map(async (page: Page) => {
          if (checked[page.id] && site) {
            const fullPage = await getPageById(site?.id, page.id)
            // console.log(fullPage)
            if (!fullPage || !fullPage.content) {
              toast.error("Page not found")
              return
            }

            const content = await updatePageContent(fullPage.content)

            await createBlockTemplate({
              name: `${values.name} - ${page.title} (${page.id})`,
              field_mapping: {},
              page_section_type_id: pageSectionTypeUpdate?.id,
              priority: values.priority,
              template_group: "",
              content,
              page_id: fullPage.id,
            })
          }
        })
      } else {
        const pageSectionType = await createPageSectionType(
          {
            name: values.name,
            fields: values.fields,
            priority: values.priority,
          },
          handleCreatePageSectionTypeSuccess,
        )

        if (!pageSectionType) {
          toast.error("Failed to create page section type")
          return
        }

        pages.map(async (page: Page) => {
          if (checked[page.id] && site) {
            const fullPage = await getPageById(site?.id, page.id)
            // console.log(fullPage)
            if (!fullPage || !fullPage.content) {
              toast.error("Page not found")
              return
            }

            const content = await updatePageContent(fullPage.content)

            await createBlockTemplate({
              name: `${values.name} - ${page.title} (${page.id})`,
              field_mapping: {},
              page_section_type_id: pageSectionType.id,
              priority: values.priority,
              template_group: values.template_group,
              content,
              page_id: fullPage.id,
            })
          }
        })
      }
    },
  })

  const toggleChecked = (id: number) => {
    const newChecked = { ...checked }
    newChecked[id] = !newChecked[id]
    setChecked(newChecked)
  }

  const handleCreatePageSectionTypeSuccess: any = async () => {
    toast.success("Successfully created section type")
    close()
  }

  const handleUpdatePageSectionTypeSuccess: any = async () => {
    toast.success("Successfully updated section type")
    close()
  }

  useEffect(() => {
    if (pageSectionTypeUpdate) {
      formik.setValues({
        name: pageSectionTypeUpdate.name,
        fields: pageSectionTypeUpdate.fields,
        priority: pageSectionTypeUpdate.priority,
        category: pageSectionTypeUpdate.category,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={
        !!pageSectionTypeUpdate ? `Update ${pageSectionTypeUpdate.name}` : "Create Page Section"
      }
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading || updateLoading || deleteLoading}
      maxWidth="lg"
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {updateError && <ErrorComponent error={updateError} />}
          {deleteError && <ErrorComponent error={deleteError} />}
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!formik.errors.name}
            />
            <TextField
              margin="dense"
              id="priority"
              name="priority"
              label="Priority"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.priority}
              error={!!formik.errors.priority}
            />
            <TextField
              margin="dense"
              id="category"
              name="category"
              label="Category"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.category}
              error={!!formik.errors.category}
            />
          </div>
          <div>
            Fields:
            <br />
            <JSONEditor
              data={{ fields: formik.values.fields || {} }}
              onChange={(
                key: string,
                value: unknown,
                parent: { [key: string]: unknown },
                data: { fields: { [key: string]: string } },
              ) => handleJsonChange(key, value, parent, data)}
              collapsible
            />
          </div>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="url"
              name="URL"
              label="Import from URL"
              fullWidth
              variant="outlined"
              onChange={importFromURL}
            />
          </div>
          <div>
            <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center", mt: "20px" }}>
              <ImageList rowHeight={240} sx={{ width: "100%", height: "100%" }} cols={4}>
                {pages.map((page, index) => (
                  <ImageListItem key={page.id}>
                    <CheckmarkOverlay show={checked[page.id]}>
                      <img
                        width={110}
                        height={240}
                        src={(page.thumbnails?.small && page.thumbnails?.small) ?? null}
                        alt={page.title || page.slug || undefined}
                        onClick={() => toggleChecked(page.id)}
                      />
                    </CheckmarkOverlay>
                    <ImageListItemBar title={page.title} subtitle={page.path} />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </div>
        </>
      }
      buttonContent={
        <>
          {pageSectionTypeUpdate ? (
            <Button onClick={handleDeletePageSectionType} color="error" variant="contained">
              Delete Section Type
            </Button>
          ) : null}
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading || deleteLoading}
          >
            {!!pageSectionTypeUpdate ? "Update Section Type" : "Create Section Type"}
          </Button>
        </>
      }
    />
  )
}

export default PageSectionTypeDialog
