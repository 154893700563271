import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { AIConversationSearch } from "../../../types"
import AIConversationRow from "./AIConversationRow"

interface Props {
  conversations: AIConversationSearch[]
  paginationData: PaginationData
}

const AIConversationTable: FunctionComponent<Props> = ({ conversations, paginationData }) => {
  const columns = ["ID", "Site", "User ID", "Message", "Page", "Created", ""]

  const getRows = useCallback(() => {
    return conversations.map((conversation, index) => (
      <AIConversationRow key={index} conversation={conversation} />
    ))
  }, [conversations])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default AIConversationTable
