import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  siteId: number
}

const ArchiveSiteConfirmationDialog: FunctionComponent<Props> = ({ siteId, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Archive Site"
      errorMessage={hasError ? "There was a problem archiving the site" : null}
      affirmativeButtonText="Archive"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>{`Are you sure you want to archive site ${siteId}?`}</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default ArchiveSiteConfirmationDialog
