import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DomainDiagnostic } from "../../../types"
import { DOMAINS } from "../../../constants/urlDefs"

const useGetDomainDiagnostics = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, domainId: string | number): Promise<DomainDiagnostic> => {
    let queryString = `${DOMAINS}/${domainId}/diagnostics`
    return makeRequest<DomainDiagnostic>(queryString, {}, { handleUnauthenticated })
  })

  return { getDomainDiagnostics: performAction, loading, error, domainDiagnostic: data }
}

export default useGetDomainDiagnostics
