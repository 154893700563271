import { ENTITLEMENTS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Entitlement } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteEntitlement = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      entitlementId: number,
      onSuccess?: () => any,
    ): Promise<Entitlement> => {
      const res = await makeRequest<Entitlement>(
        `${ENTITLEMENTS}/${entitlementId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { deleteEntitlement: performAction, loading, error }
}

export default useDeleteEntitlement
