import { USERSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { ShadowBan } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetUserShadowbanById = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, userId: number): Promise<ShadowBan> => {
    return makeRequest<ShadowBan>(`${USERSV1}/${userId}/shadow-ban`, {}, { handleUnauthenticated })
  })

  return { loading, error, getUserShadowban: performAction, shadowbanned: data }
}

export default useGetUserShadowbanById
