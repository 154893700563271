import { Box, Button, FormLabel, MenuItem, Select, Typography } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import Dialog, { Row, StyledText, StyledTitle } from "../../../components/shared/Dialog"
import { Subhead, TextBody } from "../../../components/shared/Typography"
import { Site, SiteUser, Subscription, V5_Site } from "../../../types"
import useAssignSiteToSubscription from "../../Subscriptions/hooks/useAssignSiteToSubscription"
import useGetSubscriptionsByUserIds from "../../Subscriptions/hooks/useGetSubscriptionsByUserIds"
import useRemoveSiteFromSubscription from "../../Subscriptions/hooks/useRemoveSiteFromSubscription"
import useGetSitesByIds from "../hooks/useGetSitesByIds"
import useGetSiteSubscriptions from "../hooks/useGetSiteSubscriptions"
import ErrorComponent from "../../../components/shared/Error"
import { formatDate } from "../../../utils/formatters"

interface Props {
  site: V5_Site
  users: SiteUser[]
  isOpen: boolean
  close: () => void
  refresh: () => void
}

const ReassignSubscriptionDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  site,
  users,
  refresh,
}) => {
  const { getSitesByIds, sites, error: sitesError } = useGetSitesByIds()
  const {
    assignSiteToSubscription,
    error: assignSiteError,
    loading: assigningSiteToSubscription,
  } = useAssignSiteToSubscription()
  const {
    removeSiteFromSubscription,
    error: removeSiteError,
    loading: removingSiteFromSubscription,
  } = useRemoveSiteFromSubscription()

  const { getSiteSubscriptions, subscriptions: siteSubscriptions } = useGetSiteSubscriptions()
  const { getSubscriptionsByUserIds, subscriptions } = useGetSubscriptionsByUserIds(1000)
  const [selectedSiteId, setSelectedSite] = useState<number | undefined>()
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | undefined>()

  const handleClose = useCallback(() => {
    setSelectedSite(undefined)
    setSelectedSubscription(undefined)
    close()
  }, [setSelectedSite, setSelectedSubscription, close])

  const handleSuccess = useCallback(() => {
    refresh()
    getSiteSubscriptions(site.id)
    handleClose()
    toast.success("Successfully reassigned subscription")
  }, [site, refresh, handleClose, getSiteSubscriptions])

  const handleReassignSubscription = useCallback(async () => {
    const selectedSite = sites.find((site: Site) => site.id === selectedSiteId)
    // if a subscription is selected and there is room for more sites on subscription, add the site
    if (
      selectedSubscription &&
      selectedSubscription.max_sites > selectedSubscription.site_ids?.length &&
      !selectedSite
    ) {
      await assignSiteToSubscription(selectedSubscription.id, site.id, handleSuccess)
      // if a site and subscription are selected, then remove the selected site and add the current one
    } else if (!!selectedSubscription && !!selectedSite) {
      await removeSiteFromSubscription(selectedSubscription?.id, selectedSite.id)
      await assignSiteToSubscription(selectedSubscription.id, site.id, handleSuccess)
    } else {
      return
    }
  }, [
    handleSuccess,
    assignSiteToSubscription,
    removeSiteFromSubscription,
    selectedSiteId,
    selectedSubscription,
    site,
    sites,
  ])

  const handleChangeSelectedSite = useCallback(
    (e: any) => {
      setSelectedSite(e.target.value || "")
    },
    [setSelectedSite],
  )

  const handleSelectSubscription = useCallback(
    (e: any) => {
      const selectSubscription: Subscription | undefined = subscriptions.find(
        (sub: Subscription) => sub.id === e.target.value,
      )
      setSelectedSubscription(selectSubscription)
    },
    [setSelectedSubscription, subscriptions],
  )

  const getSelectedSubscriptionDetails = useCallback(() => {
    if (!selectedSubscription) {
      return null
    }
    return (
      <Row>
        <Box
          sx={{
            width: "100%",
            borderRadius: "8px",
            border: (theme) => `1px solid ${theme.colors.disabled}`,
            p: "15px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Row>
            <StyledTitle>{`Subscription Id: ${selectedSubscription?.id}`}</StyledTitle>
          </Row>
          <Row>
            <Subhead>Type</Subhead>
            <StyledText>{selectedSubscription.type}</StyledText>
          </Row>
          <Row>
            <Subhead>Created At:</Subhead>
            <StyledText>{selectedSubscription.created_at}</StyledText>
          </Row>
        </Box>
      </Row>
    )
  }, [selectedSubscription])

  const handleGetSubscriptionSites = useCallback(async () => {
    if (!!selectedSubscription?.site_ids.length) {
      const siteIds = selectedSubscription.site_ids.map((id: string) => parseInt(id))
      getSitesByIds(siteIds)
    }
  }, [getSitesByIds, selectedSubscription])

  const getSaveDisabledState = useCallback(() => {
    // removing site required to save if adding to a subscription with maxed out sites
    const requiresSite =
      !!selectedSubscription &&
      selectedSubscription.max_sites <= selectedSubscription.site_ids.length

    // disable if currently processing save action
    if (!!assigningSiteToSubscription || !!removingSiteFromSubscription) {
      return true
    }
    // disable if requires site but no site selected
    else if (requiresSite && !selectedSiteId) {
      return true
    }
    //disable if no selection entered
    else if (!selectedSubscription) {
      return true
    } else {
      return false
    }
  }, [
    selectedSubscription,
    selectedSiteId,
    assigningSiteToSubscription,
    removingSiteFromSubscription,
  ])

  const getSubscriptionOptions = useCallback(() => {
    const siteSubscriptionIds = siteSubscriptions.map((siteSub: Subscription) => siteSub.id)

    return subscriptions.reduce((acc: any, subscription: Subscription) => {
      const Component = (
        <MenuItem
          key={subscription.id}
          value={subscription.id}
          sx={{
            color: (theme: any) =>
              subscription.max_sites > subscription.site_ids.length
                ? theme.colors.primaryText
                : theme.palette.warning.main,
          }}
        >
          {`${subscription.sk_product_id} - subscriptionId: ${subscription.id} - type: ${subscription.type} - sites: ${subscription.site_ids.length}/${subscription.max_sites}`}
        </MenuItem>
      )
      if (!siteSubscriptionIds.includes(subscription.id)) {
        acc.push(Component)
      }
      return acc
    }, [])
  }, [subscriptions, siteSubscriptions])

  useEffect(() => {
    handleGetSubscriptionSites()

    // eslint-disable-next-line
  }, [selectedSubscription])

  useEffect(() => {
    if (!!site?.id) {
      getSiteSubscriptions(site.id)
    }
    if (!!users.length) {
      const userIds = users.map((u: SiteUser) => u.user.id)
      getSubscriptionsByUserIds(userIds)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={`Site Subscriptions - Site: ${site.id}`}
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <Box width="550px">
          <Row height="8px">
            {assignSiteError && <ErrorComponent error={assignSiteError} />}
            {removeSiteError && <ErrorComponent error={removeSiteError} />}
          </Row>
          <Typography variant="h6">Site Subscriptions</Typography>
          <Row>
            <Box
              sx={{
                width: "100%",
                borderRadius: "8px",
                border: (theme) => `1px solid ${theme.colors.disabled}`,
                p: "15px",
                marginBottom: "10px",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Product</th>
                    <th>End Date</th>
                    <th>Auto Renew</th>
                  </tr>
                </thead>
                <tbody>
                  {siteSubscriptions.map((sub: Subscription) => (
                    <tr key={sub.id}>
                      <td>
                        <StyledText>{sub.id}</StyledText>
                      </td>
                      <td>
                        <StyledText>{sub.type}</StyledText>
                      </td>
                      <td>
                        <StyledText>{sub.sk_product_id}</StyledText>
                      </td>
                      <td>
                        <StyledText>{formatDate(new Date(sub.end_date))}</StyledText>
                      </td>
                      <td>
                        <StyledText>{sub.auto_renew_status ? "Yes" : "No"}</StyledText>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Row>
          <Typography variant="h6">Reassign Subscription</Typography>
          <Row>
            <FormLabel>Select Destination Subscription</FormLabel>
            <Select
              fullWidth
              name="destinationSubscription"
              variant="outlined"
              id="destinationSubscription"
              onChange={handleSelectSubscription}
              value={selectedSubscription?.id || ""}
              sx={{ mt: "9px", mr: "4px" }}
            >
              {getSubscriptionOptions()}
            </Select>
          </Row>
          {getSelectedSubscriptionDetails()}
          {!!selectedSubscription?.id && !!selectedSubscription?.site_ids.length && (
            <Row>
              <FormLabel>
                Select Site to remove from selected Subscription in order to add {site.id}
              </FormLabel>
              <Select
                placeholder="Select Site"
                fullWidth
                name="siteSelect"
                variant="outlined"
                id="siteSelect"
                onChange={handleChangeSelectedSite}
                value={selectedSiteId || ""}
                sx={{ mt: "9px", mr: "4px" }}
                error={!!sitesError}
              >
                {sites.map((site: Site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {`SiteId: ${site.id} - domain: ${site.domain}`}
                  </MenuItem>
                ))}
              </Select>
            </Row>
          )}
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleReassignSubscription}
            color="primary"
            variant="outlined"
            disabled={getSaveDisabledState()}
          >
            Save
          </Button>
        </>
      }
    />
  )
}

export default ReassignSubscriptionDialog
