import { ENTITLEMENTS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Entitlement, UpdateEntitlement } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateEntitlement = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      entitlementId: number,
      entitlement: UpdateEntitlement,
      onSuccess?: () => any,
    ): Promise<Entitlement> => {
      const res = await makeRequest<Entitlement>(
        `${ENTITLEMENTS}/${entitlementId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(entitlement),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { updateEntitlement: performAction, loading, error }
}

export default useUpdateEntitlement
