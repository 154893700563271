import { Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import { cloneDeep } from "lodash"
import { FunctionComponent, useEffect } from "react"
import Select, { MultiValue } from "react-select"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { Subhead } from "../../../components/shared/Typography"
import { Experiment, RemoteValue, UserGroup, Variant, VariantValue } from "../../../types"
import useGetRemoteValues from "../../RemoteValues/hooks/useGetRemoteValues"
import useCreateExperiment from "../hooks/useCreateExperiment"
import useUpdateExperiment from "../hooks/useUpdateExperiment"
import ExperimentVariant from "./ExperimentVariant"

interface Props {
  isOpen: boolean
  close: () => void
  editExperiment: Experiment | undefined | null
  onRefresh: Function
  canEdit: boolean
  userGroups: UserGroup[] | undefined | null
}

const CreateRemoteValueDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  onRefresh,
  editExperiment,
  canEdit,
  userGroups,
}) => {
  // disable form if user cannot edit or if editing an experiment that has ended
  const disabled = !canEdit || !!editExperiment?.end_date
  const disabledVariant = disabled || !!editExperiment
  const {
    remoteValues,
    getRemoteValues,
    error: remoteValuesError,
    loading: loadingRemoteValues,
  } = useGetRemoteValues()

  // custom styles needed to make Select component not wonky
  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  }

  const { createExperiment, loading: creating, error: creationError } = useCreateExperiment()

  const { updateExperiment, loading: updating, error: updateError } = useUpdateExperiment()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().optional().nullable(),
    selectedValues: Yup.array().optional(),
    variants: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        distribution: Yup.number().required(),
        values: Yup.array().of(
          Yup.object().shape({
            key: Yup.string().required(),
            value: Yup.string().required(),
          }),
        ),
      }),
    ),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      selectedValues: [],
      variants: [],
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const item: any = {
        name: values.name,
        description: values.description || null,
        variants: values.variants || [],
      }

      if (!!editExperiment) {
        const body = { ...editExperiment, ...item }
        await updateExperiment(body, handleOnSuccess)
      } else {
        await createExperiment(item, handleOnSuccess)
      }
    },
  })

  useEffect(() => {
    if (!editExperiment) {
      formik.resetForm()
    } else {
      const loadExperiment = cloneDeep(editExperiment)

      let existingValues: RemoteValue[] = []
      if (loadExperiment?.variants?.length) {
        // since all variants have the same remote value keys, take the first one and load those into the values selector
        loadExperiment.variants.forEach((variant: Variant, index: number) => {
          variant.values.forEach((variantValue: VariantValue) => {
            const remoteValue = remoteValues.find((rv: RemoteValue) => rv.key === variantValue.key)
            if (remoteValue) {
              variantValue.remoteValueId = remoteValue.id
              // we only need to load them into selectedValues for the first variant
              if (index === 0) {
                existingValues.push(remoteValue)
              }
            }
          })
        })
      }

      formik.setValues({
        name: loadExperiment?.name || "",
        description: loadExperiment?.description || "",
        variants: loadExperiment?.variants || [],
        selectedValues: existingValues,
      })
    }

    // eslint-disable-next-line
  }, [editExperiment])

  useEffect(() => {
    // get the remote values for the values selection list
    getRemoteValues()

    // eslint-disable-next-line
  }, [])

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  const handleOnSuccess = () => {
    onRefresh()
    formik.resetForm()
    close()
  }

  const handleChangeSelectedValues = (newSelectedValues: MultiValue<RemoteValue>) => {
    //replace selected values with new
    formik.setFieldValue("selectedValues", newSelectedValues)

    const { variants } = cloneDeep(formik.values)

    if (!variants.length) {
      return
    }
    const newSelectedValueIds = newSelectedValues.map((nsv: RemoteValue) => nsv.id)
    //remove unneeded variantValues from variants
    const updatedVariants = variants.map((v: Variant) => {
      v.values = v.values.filter((vv: VariantValue) =>
        newSelectedValueIds.includes(vv.remoteValueId || -1),
      )
      return v
    })

    const updatedVariantValueRemoteIds = updatedVariants[0].values.map(
      (vv: VariantValue) => vv.remoteValueId,
    )
    // add variant values for each new remote value
    newSelectedValues.forEach((selectedValue: RemoteValue) => {
      if (!updatedVariantValueRemoteIds.includes(selectedValue.id)) {
        const newVariantValue = {
          key: selectedValue.key,
          value: selectedValue.value,
          remoteValueId: selectedValue.id,
        }

        updatedVariants.forEach((v: Variant) => {
          v.values.push({ ...newVariantValue })
        })
      }
    })

    formik.setFieldValue("variants", updatedVariants)
  }

  const addVariant = () => {
    const { selectedValues, variants } = cloneDeep(formik.values)

    // blank variant with a negative generated Id to differentiate create/update of the variant
    const variant: Variant = {
      id: -Date.now(),
      name: "",
      distribution: 0,
      values: [],
      user_group_id: -1,
    }

    // add variant to the list and add a set value as default
    if (selectedValues.length) {
      variant.values = selectedValues.map((rv: RemoteValue) => ({
        ...rv,
        value: rv.value,
        remoteValueId: rv.id,
      }))
    }

    formik.setFieldValue("variants", [...variants, variant])
  }

  const removeVariant = (id: number) => {
    const filteredVariants = formik.values.variants.filter((variant: any) => variant.id !== id)
    formik.setFieldValue("variants", filteredVariants)
  }

  const updateVariantName = (id: number, e: any) => {
    const value = e.target.value

    const newVariants = formik.values.variants.map((variant: Variant) => {
      const item = { ...variant }
      if (item.id === id) {
        item.name = value
      }
      return item
    })
    formik.setFieldValue("variants", newVariants)
  }

  const updateVariantDistribution = (id: number, e: any) => {
    const value = e.target.value
    const newVariants = formik.values.variants.map((variant: Variant) => {
      const item = { ...variant }
      if (item.id === id) {
        item.distribution = value
      }
      return item
    })
    formik.setFieldValue("variants", newVariants)
  }

  const updateVariantUserGroup = (id: number, e: any) => {
    const value = e.target.value
    const newVariants = formik.values.variants.map((variant: Variant) => {
      const item = { ...variant }
      if (item.id === id) {
        if (value !== "-1") {
          item.distribution = -1
          item.user_group_id = value
        } else {
          item.distribution = 0
          item.user_group_id = -1
        }
      }
      return item
    })
    formik.setFieldValue("variants", newVariants)
  }

  const updateVariantValue = (id: number, valueKey: string, e: any) => {
    const value = e.target.value
    const { variants } = cloneDeep(formik.values)

    const updateVariant = variants.find((v: Variant) => v.id === id)
    const updateValue = updateVariant.values.find((vv: VariantValue) => vv.key === valueKey)
    updateValue.value = value

    formik.setFieldValue("variants", variants)
  }

  const getVariantComponents = () => {
    return formik.values.variants.map((variant: Variant, index: number) => (
      <ExperimentVariant
        key={index}
        variant={variant}
        disabled={disabled}
        removeVariantDisabled={disabledVariant}
        removeVariant={removeVariant}
        updateVariantDistribution={updateVariantDistribution}
        updateVariantName={updateVariantName}
        updateVariantValue={updateVariantValue}
        updateVariantUserGroup={updateVariantUserGroup}
        userGroups={userGroups || []}
        formik={formik}
        index={index}
      />
    ))
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editExperiment ? `Update Experiment: ${editExperiment.name}` : "Create A New Experiment"
      }
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {(!!remoteValuesError || !!creationError || !!updateError) && (
              <ErrorComponent error={remoteValuesError || creationError || updateError} />
            )}
          </Row>
          <Row>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              margin="normal"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!formik.errors.name}
              disabled={disabled}
            />
          </Row>
          <Row>
            <TextField
              label="Description"
              fullWidth
              variant="outlined"
              margin="normal"
              id="description"
              name="description"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={!!formik.errors.description}
              disabled={disabled}
              multiline
              maxRows={10}
            />
          </Row>
          <Row sx={{ my: "18px" }}>
            <Select
              styles={customSelectStyles}
              menuPosition="fixed"
              menuPortalTarget={document.body}
              options={remoteValues}
              getOptionLabel={(option: any) => option.key}
              value={formik.values.selectedValues}
              onChange={handleChangeSelectedValues}
              isLoading={!!loadingRemoteValues}
              isDisabled={disabledVariant}
              isSearchable
              isMulti
              isClearable
              placeholder="Values"
            />
          </Row>
          <Row>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={addVariant}
              disabled={disabledVariant}
            >
              Add Variant
            </Button>
          </Row>
          {!!formik.values.variants.length && (
            <Row>
              <Subhead>Variants</Subhead>
              {getVariantComponents()}
            </Row>
          )}
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!disabled && (
            <Button
              onClick={formik.submitForm}
              color="primary"
              variant="outlined"
              disabled={creating || updating}
            >
              {editExperiment ? "Update" : "Create"}
            </Button>
          )}
        </>
      }
    />
  )
}

export default CreateRemoteValueDialog
