import { CODESNIPPETS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CodeSnippet, CreateCodeSnippet } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateCodeSnippet = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      codeSnippet: CreateCodeSnippet,
      onSuccess?: () => any,
    ): Promise<CodeSnippet> => {
      const snippet = await makeRequest<CodeSnippet>(
        `${CODESNIPPETS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...codeSnippet }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return snippet
    },
  )

  return { createCodeSnippet: performAction, loading, error }
}

export default useCreateCodeSnippet
