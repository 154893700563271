import { Button } from "@mui/material"
import  { FunctionComponent, useCallback } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Experiment } from "../../../types"

interface Props {
  experiment: Experiment
  handleStart: (experiment: Experiment) => any
  handleEnd: (experiment: Experiment) => any
  handleEdit: (experiment: Experiment) => any
  canEdit: boolean
}

const RemoteValueRow: FunctionComponent<Props> = ({
  experiment,
  handleEnd,
  handleStart,
  handleEdit,
  canEdit,
}) => {
  const onClickEdit = useCallback(
    (e: any) => {
      handleEdit(experiment)
    },
    [experiment, handleEdit],
  )

  const onClickEnd = useCallback(
    (e: any) => {
      handleEnd(experiment)
    },
    [experiment, handleEnd],
  )

  const onClickStart = useCallback(
    (e: any) => {
      handleStart(experiment)
    },
    [experiment, handleStart],
  )

  const getButton = useCallback(() => {
    if (!experiment.start_date && !experiment.end_date) {
      return (
        <Button variant="contained" color="success" onClick={onClickStart} sx={{ width: "80px" }}>
          Start
        </Button>
      )
    } else if (experiment.start_date && !experiment.end_date) {
      return (
        <Button variant="contained" color="error" onClick={onClickEnd} sx={{ width: "80px" }}>
          End
        </Button>
      )
    } else {
      return null
    }
  }, [experiment, onClickEnd, onClickStart])

  return (
    <BasicTableRow sx={{ width: "100%" }}>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "300px",
        }}
      >
        {experiment.name}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "600px",
        }}
      >
        {experiment.description}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          maxWidth: "300px",
          wordWrap: "break-word",
        }}
      >
        <>{experiment?.start_date}</>
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          maxWidth: "300px",
          wordWrap: "break-word",
        }}
      >
        <>{experiment?.end_date}</>
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          maxWidth: "100px",
          wordWrap: "break-word",
        }}
      >
        <Button variant="contained" color="primary" onClick={onClickEdit} sx={{ width: "80px" }}>
          {experiment.end_date || !canEdit ? "View" : "Edit"}
        </Button>
      </BasicTableCell>
      {canEdit && (
        <BasicTableCell
          component="th"
          scope="row"
          style={{
            whiteSpace: "normal",
            maxWidth: "100px",
            wordWrap: "break-word",
          }}
        >
          {getButton()}
        </BasicTableCell>
      )}
    </BasicTableRow>
  )
}

export default RemoteValueRow
