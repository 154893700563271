import { MERCHANTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AddApplePayRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useAddApplePay = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      merchantId: string | number,
      urls: object,
      onSuccess?: () => any,
    ): Promise<AddApplePayRequest> => {
      const response = await makeRequest<AddApplePayRequest>(
        `${MERCHANTSV3}/${merchantId}/domains`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            urls,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { addApplePay: performAction, loading, error }
}

export default useAddApplePay
