import React, { useEffect, useState } from "react"
import useGetConversationMessagesById from "../hooks/useGetConversationMessagesById"
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner"
import ErrorComponent from "../../../components/shared/Error"
import useGetUserById from "../../Users/hooks/useGetUserById"

import { AIConversationMessageV2, User } from "../../../types"

interface Props {
  conversationId: number
}

const getShortDateAndTime = (inputDate: string | Date | undefined): string => {
  if (!inputDate) return ""

  const date = new Date(inputDate)

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const month = months[date.getMonth()]
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  // Pad single digit day, hours, and minutes with leading zero if necessary
  const paddedDay = day < 10 ? "0" + day : day
  const paddedHours = hours < 10 ? "0" + hours : hours
  const paddedMinutes = minutes < 10 ? "0" + minutes : minutes

  return `${month} ${paddedDay}, ${paddedHours}:${paddedMinutes}`
}

const ChatContainer: React.FC<Props> = ({ conversationId }) => {
  const [users, setUsers] = useState<Record<number, User>>({})

  const { loading, error, getConversationMessages, messages } = useGetConversationMessagesById()

  const { user, getUserById } = useGetUserById()

  const chatContainer = {
    display: "flex",
    flexDirection: "column" as "column",
    height: "400px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    overflow: "auto",
    padding: "10px",
  }
  const chatMessage = {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "10px",
  }

  // const avatar = {
  //   width: "40px",
  //   height: "40px",
  //   borderRadius: "50%",
  //   backgroundColor: "#ccc",
  //   marginRight: "10px",
  // }

  const content = {
    flex: 1,
    backgroundColor: "#f1f1f1",
    borderRadius: "4px",
    padding: "8px",
  }
  const sender = { fontWeight: "bold", marginBottom: "4px" }
  const timestamp = { fontSize: "12px", color: "#888", textAlign: "right" as "right" }

  const parseMessages = async (messages: AIConversationMessageV2[]) => {
    const userList = messages
      .map((message) => message.user_id)
      .reduce((acc, curr) => {
        if (!acc.includes(curr)) {
          acc.push(curr)
        }
        return acc
      }, [] as number[])

    userList.map(async (userId) => {
      if (!users[userId]) {
        await getUserById(userId)
      }
    })
  }

  useEffect(() => {
    if (!messages) return
    parseMessages(messages)
  }, [messages])

  useEffect(() => {
    getConversationMessages(conversationId)
  }, [conversationId])

  useEffect(() => {
    if (user) {
      setUsers({ ...users, [user.id]: user })
    }
  }, [user])

  return loading ? (
    <LoadingSpinner />
  ) : error ? (
    <ErrorComponent error={error} />
  ) : (
    <div style={chatContainer}>
      {messages?.map((message) => (
        <div style={chatMessage} key={message.id}>
          <div
            style={{
              ...content,
              backgroundColor:
                message.role === "assistant"
                  ? "#f1f1f1"
                  : message.role === "gus"
                  ? "lightgreen"
                  : "lightblue",
            }}
          >
            <div style={sender}>
              {message.user_id && users[message.user_id]
                ? `${users[message.user_id]?.first_name ?? users[message.user_id]?.email} ${
                    users[message.user_id]?.last_name
                  }`
                : message.role}
            </div>
            <div style={{ whiteSpace: "pre-wrap" }}>{message.message}</div>
            <div style={timestamp}>{getShortDateAndTime(message.created_at)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ChatContainer
