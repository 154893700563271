import { AIIMAGEGENERATIONSTYLES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AIImageGenerationStyle, UpdateAIImageGenerationStyle } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateAIImageGenerationStyle = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      aiImageGenerationStyleId: number,
      aiImageGenerationStyle: UpdateAIImageGenerationStyle,
      onSuccess?: () => any,
    ): Promise<AIImageGenerationStyle> => {
      const res = await makeRequest<AIImageGenerationStyle>(
        `${AIIMAGEGENERATIONSTYLES}/${aiImageGenerationStyleId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(aiImageGenerationStyle),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { updateAIImageGenerationStyle: performAction, loading, error }
}

export default useUpdateAIImageGenerationStyle
