import { Box } from "@mui/material"
import React from "react"
import { TextBody } from "../Typography"
import OhShitHamid from "../../images/oh-shit-hamid.jpg"

const ForbiddenPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <img height={100} src={OhShitHamid} alt={"Oh Shit Hamid"} />
      <TextBody>You do not have access to this page</TextBody>
    </Box>
  )
}

export default ForbiddenPage
