import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreateDNSRecordRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateDNSRecord = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainId: string | number,
      record: CreateDNSRecordRequest,
      onSuccess?: () => any,
    ): Promise<CreateDNSRecordRequest> => {
      const response = await makeRequest<CreateDNSRecordRequest>(
        `${DOMAINS}/${domainId}/dns/records`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(record),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { createRecord: performAction, loading, error }
}

export default useCreateDNSRecord
