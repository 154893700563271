import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Site } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUnarchiveSite = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, siteId: number, onSuccess?: () => any): Promise<Site> => {
      const response = await makeRequest<Site>(
        `${SITESV4}/${siteId}/unarchive`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { unarchiveSite: performAction, loading, error }
}

export default useUnarchiveSite
