import { Box, Checkbox, FormControl, InputLabel, MenuItem, ListItemText } from "@mui/material"
import { DiagramTypeT } from "../types"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { web, services, vercel } from "../../../constants/services"
import { LoadingButton } from "@mui/lab"

type ToolbarComponentPropsT = {
  type: DiagramTypeT
  setType: (newType: DiagramTypeT) => void
  services: string[]
  setServices: (newServices: string[]) => void
  loading: boolean
  handleClick: () => void
}

const SERVICES = [...vercel, ...services, ...web]
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const ToolbarComponent = ({
  type,
  setType,
  services,
  setServices,
  loading,
  handleClick,
}: ToolbarComponentPropsT) => {
  return (
    <Box style={{ display: "flex", justifyContent: "space-between" }}>
      <Box style={{ maxWidth: "10rem", width: "100%" }}>
        <FormControl fullWidth>
          <InputLabel id="diagram-type-label">Type</InputLabel>
          <Select
            labelId="diagram-type-label"
            id="diagram-type-select"
            value={type}
            label="Type"
            onChange={(event: SelectChangeEvent) => setType(event.target.value as DiagramTypeT)}
          >
            <MenuItem value={"internal"}>
              <code>internal</code>
            </MenuItem>
            <MenuItem value={"external"}>
              <code>external</code>
            </MenuItem>
            <MenuItem value={"all"}>
              <code>all</code>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box style={{ width: "1rem" }}></Box>
      <Box style={{ width: "100%" }}>
        <FormControl fullWidth>
          <InputLabel id="services-label">Services</InputLabel>
          <Select
            labelId="services-label"
            id="services-select"
            multiple
            renderValue={(selected: string[]) => selected.join(", ")}
            value={services}
            label="Type"
            onChange={(event: SelectChangeEvent<typeof services>) => {
              const {
                target: { value },
              } = event
              setServices(typeof value === "string" ? value.split(",") : value)
            }}
            MenuProps={MenuProps}
          >
            {SERVICES.map((service) => (
              <MenuItem key={service} value={service}>
                <Checkbox checked={services.indexOf(service) > -1} />
                <ListItemText primary={service} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box style={{ width: "1rem" }}></Box>
      <Box>
        <LoadingButton
          loading={loading}
          style={{ height: "100%" }}
          variant="contained"
          type="button"
          onClick={handleClick}
        >
          Create
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default ToolbarComponent
