import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { EmailForward } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetDomainDiagnostics = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, emailForwardDomain: string): Promise<EmailForward[]> => {
    let queryString = `${DOMAINS}/${emailForwardDomain}/emailForwards`
    return makeRequest<EmailForward[]>(queryString, {}, { handleUnauthenticated })
  })

  return { getEmailForward: performAction, loading, error, emailForward: data && data[0] }
}

export default useGetDomainDiagnostics
