import { PAGESECTIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageSectionType, CreatePageSectionType } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreatePageSectionType = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      pageSectionType: CreatePageSectionType,
      onSuccess?: () => any,
    ): Promise<PageSectionType> => {
      const pageSectionTypeResponse = await makeRequest<PageSectionType>(
        `${PAGESECTIONS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...pageSectionType }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return pageSectionTypeResponse
    },
  )

  return { createPageSectionType: performAction, loading, error }
}

export default useCreatePageSectionType
