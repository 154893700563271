import { SUBSCRIPTIONSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useGetSubscriptionDiagnostics = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, subscriptionId: number): Promise<any> => {
    return makeRequest<any>(`${SUBSCRIPTIONSV3}/${subscriptionId}/diagnostics`, {}, { handleUnauthenticated })
  })

  return { loading, error, getSubscriptionDiagnostics: performAction, subscriptionDiagnostics: data }
}

export default useGetSubscriptionDiagnostics
