import  { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  siteId: string | number
}

type SiteLinkProps = Omit<Props, "to">

const SiteLink: FunctionComponent<SiteLinkProps> = ({ siteId, children, ...rest }) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/sites/${siteId}`}>
    {children}
  </Link>
)

export default SiteLink
