import { USERS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { User } from "../../../types"
import makeRequest from "../../../utils/make-request"
import useGetUserIdentities from "./useGetUserIdentities"

const useGetUserById = () => {
  const { getUserIdentities } = useGetUserIdentities()

  const {
    data,
    loading,
    error,
    performAction,
  } = useHandleRequest(async (handleUnauthenticated, userId: number): Promise<User> => {
    const results = await makeRequest<User>(`${USERS}/${userId}?deleted=true`, {}, { handleUnauthenticated })

    const identities = await getUserIdentities(results?.id)

    return {
      email: results.email,
      first_name: results.first_name,
      id: results.id,
      instagram: results.instagram,
      last_name: results.last_name,
      analytics_id: results.analytics_id,
      profile_picture_asset_id: results.profile_picture_asset_id,
      created_at: results.created_at,
      updated_at: results.updated_at,
      deleted_at: results.deleted_at,
      identities,
    }
  })

  return {
    loading: loading,
    error: error,
    getUserById: performAction,
    user: data,
  }
}

export default useGetUserById
