import { Button, DialogContentText, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import * as Yup from "yup"
import useUpdateIdentityEmail from "../hooks/useUpdateIdentityEmail"

type UpdateUserIdentityEmailDialogProps = {
  identityId: string
  close: () => any
  isOpen: boolean
}

const UpdateUserIdentityEmailDialog: FunctionComponent<UpdateUserIdentityEmailDialogProps> = ({
  identityId,
  close,
  isOpen,
}) => {
  const [error, setError] = useState<Error>()

  const { updateIdentityEmail, loading, error: requestError } = useUpdateIdentityEmail()

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      try {
        await updateIdentityEmail(identityId, values.email, close)
      } catch (err) {
        setError(enforceError(err))
      }
    },
  })

  return (
    <Dialog
      title="Edit Identity"
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      content={
        <>
          {error || requestError ? <br /> : null}
          <DialogContentText>
            Enter a new email below to update the login email for the user.
          </DialogContentText>
          <TextField
            margin="dense"
            id="email"
            label="New Login Email"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={formik.submitForm} color="primary" variant="outlined">
              Update Login Email
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default UpdateUserIdentityEmailDialog
