import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { OrderDiscount } from "../../../types"
import DiscountRow from "./DiscountsRow"

interface Props {
  discounts: OrderDiscount[]
}

const DiscountsTable: FunctionComponent<Props> = ({ discounts }) => {
  const columns = ["ID", "Discount Code", "Amount Off Order"]

  const getDiscountRows = useCallback(() => {
    return discounts.map((discount: OrderDiscount, index: number) => (
      <DiscountRow key={index} discount={discount} />
    ))
  }, [discounts])

  return <BasicTable columns={columns} rows={getDiscountRows()} />
}

export default DiscountsTable
