import { TEMPLATEFILTERS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TemplateFilter, CreateTemplateFilter } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateTemplateFilter = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      body: CreateTemplateFilter,
      onSuccess?: () => any,
    ): Promise<TemplateFilter> => {
      const res = makeRequest<TemplateFilter>(
        `${TEMPLATEFILTERS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { createTemplateFilter: performAction, loading, error }
}

export default useCreateTemplateFilter
