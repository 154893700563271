import { Box } from "@mui/material"
import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useGetPaymentIntegrations from "./hooks/useGetPaymentIntegrations"
import SitePaymentIntegrationsTable from "./components/SitePaymentIntegrationsTable"

const SitePaymentIntegrationsScreen: FunctionComponent = () => {
  const { siteId } = useParams<{ siteId: string }>()
  const { loading, error, getPaymentIntegrations, paymentIntegrations } =
    useGetPaymentIntegrations()

  const numberSiteId = Number(siteId)

  useEffect(() => {
    getPaymentIntegrations(numberSiteId)
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title={`Site ${siteId} Payment Integrations`}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
        <SitePaymentIntegrationsTable paymentIntegrations={paymentIntegrations || []} />
      </Box>
    </ScreenContainer>
  )
}

export default SitePaymentIntegrationsScreen
