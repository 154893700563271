import makeRequest, { PagedResponse } from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Dispute } from "../../../types"
import { MERCHANTSV3 } from "../../../constants/urlDefs"

const useGetMerchantDisputes = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, merchantId: number): Promise<PagedResponse<Dispute>> => {
    let queryString = `${MERCHANTSV3}/${merchantId}/disputes`
    return makeRequest<PagedResponse<Dispute>>(queryString, {}, { handleUnauthenticated })
  })

  return { getMerchantDisputes: performAction, loading, error, disputes: data?.results || [] }
}

export default useGetMerchantDisputes
