import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Domain, Subdomain } from "../../../types"
import makeRequest from "../../../utils/make-request"

export enum DomainSubdomainParamTypeEnum {
  UserId = "user_id",
  SiteId = "site_id",
  URL = "url",
}

const useGetDomainsAndSubdomains = () => {
  const { data, loading, error, performAction } = useHandleRequest(async (
    handleUnauthenticated,
    paramType: DomainSubdomainParamTypeEnum,
    searchValue: string | number,
    onSuccess?: (
      results: (Domain | Subdomain)[],
      paramType: DomainSubdomainParamTypeEnum,
      id: string | number,
    ) => any,
  ): Promise<(Domain | Subdomain)[]> => {
    let queryString = `${DOMAINS}/subdomains`
    if (paramType && searchValue) {
      queryString += `?${paramType}=${encodeURIComponent(searchValue)}`
    }
    const response = await makeRequest<(Domain | Subdomain)[]>(queryString, {}, { handleUnauthenticated })

    if (onSuccess) {
      await onSuccess(response, paramType, searchValue)
    }

    return response
  })

  return { getDomainsAndSubdomains: performAction, loading, error, allDomains: data || [] }
}

export default useGetDomainsAndSubdomains
