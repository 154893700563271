import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Identity, User } from "../../../types"
import UserIdentityRow from "./UserIdentityRow"

interface Props {
  identities: Identity[]
  user: User
  refreshAction: () => any
}

const UserIdentityTable: FunctionComponent<Props> = ({ identities, user, refreshAction }) => {
  const columns: string[] = ["Provider", "Provider User ID/Email", "Actions"]

  const getRows = useCallback(() => {
    return identities.map((identity: Identity, index: number) => (
      <UserIdentityRow key={index} identity={identity} user={user} refreshAction={refreshAction} />
    ))
  }, [identities, user, refreshAction])

  return (
    <BasicTable
      columns={columns}
      rows={getRows()}
      paginationOptions={{
        count: identities.length,
        page: 0,
        rowsPerPage: identities.length,
        onPageChange: () => null,
        onRowsPerPageChange: () => null,
      }}
    />
  )
}

export default UserIdentityTable
