import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { CodeSnippet } from "../../../types"
import CodeSnippetRow from "./CodeSnippetRow"

interface Props {
  codeSnippets: CodeSnippet[]
  paginationData: PaginationData
  canEdit: boolean
}

const CodeSnippetsTable: FunctionComponent<Props> = ({ codeSnippets, paginationData, canEdit }) => {
  const columns = canEdit
    ? ["ID", "Title", "Author", "Active", "Code", "Created", "Action"]
    : ["ID", "Title", "Author", "Active", "Code", "Created"]

  const getRows = useCallback(() => {
    return codeSnippets.map((codeSnippet: CodeSnippet, index: number) => (
      <CodeSnippetRow key={index} codeSnippet={codeSnippet} canEdit={canEdit} />
    ))
  }, [codeSnippets, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default CodeSnippetsTable
