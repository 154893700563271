import { Chip } from "@mui/material"

export default function PRNumber({ prnumber, branch }: { prnumber: string; branch?: string }) {
  if (!prnumber) {
    return <code>N/A</code>
  }
  const num = prnumber.replace("pr-", "")

  if (num.match(/^\d+$/)) {
    return (
      <a href={`https://github.com/onuniverse/universe-platform/pull/${num}`}>
        <code>#{num}</code>
      </a>
    )
  }

  return <Chip label={branch ?? "Side Deployment"} />
}
