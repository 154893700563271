import { FunctionComponent } from "react"
import { Chip } from "@mui/material"

type StatusLabelProps = {
  status: string
}

const StatusLabel: FunctionComponent<StatusLabelProps> = ({ status }) => {
  if (status === "Cancelled") {
    return <Chip label={status} color="error" variant="filled" />
  }

  if (status === "Failed") {
    return <Chip label={status} color="error" variant="outlined" />
  }

  if (status === "Succeeded") {
    return <Chip label={status} color="success" variant="outlined" />
  }

  if (status === "Running") {
    return <Chip label={status} color="info" variant="outlined" />
  }

  return <Chip label={status} color="default" variant="outlined" />
}

export default StatusLabel
