import { Box } from "@mui/material"
import { FunctionComponent } from "react"
import { Subhead } from "../../../components/shared/Typography"
import { DomainCertificate, VercelData } from "../../../types"
import { Row, StyledText, StyledTitle } from "./DomainLayoutShared"

interface Props {
  data: VercelData | undefined
  certData: DomainCertificate[]
}

const VercelOverview: FunctionComponent<Props> = ({ data, certData }) => {
  if (!data) {
    return null
  }

  const matchingCert =
    certData && certData.length > 0 && certData.find((cert) => cert.domain === data.name)

  return (
    <Box sx={{ ml: "30px" }}>
      <Row>
        <StyledTitle>Vercel</StyledTitle>
      </Row>
      <Row>
        <Subhead>Domain: </Subhead>
        <StyledText>{data.name}</StyledText>
      </Row>
      <Row>
        <Subhead>Accepted Challenges: </Subhead>
        <StyledText>{data.accepted_challenges}</StyledText>
      </Row>
      <Row>
        <Subhead>Conflicts: </Subhead>
        <StyledText>{data.conflicts}</StyledText>
      </Row>
      <Row>
        <Subhead>Misconfigured: </Subhead>
        <StyledText>{String(data.misconfigured)}</StyledText>
      </Row>
      <Row>
        <Subhead>Verified: </Subhead>
        <StyledText>{String(data.verified)}</StyledText>
      </Row>
      <Row>
        <Subhead>SSL Cert: </Subhead>
        <StyledText>{matchingCert ? `Issued (${matchingCert.id})` : "Not issued"}</StyledText>
      </Row>
      {data.verification && (
        <Row>
          <Subhead>Verification: </Subhead>
          <StyledText>{JSON.stringify(data.verification, null, 2)}</StyledText>
        </Row>
      )}
    </Box>
  )
}

export default VercelOverview
