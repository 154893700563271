import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title3 } from "../../components/shared/Typography"
import config from "../../config"
import useToggle from "../../hooks/useToggle"
import CancelSubscriptionConfirmationDialog from "./components/CancelSubscriptionConfirmationDialog"
import RemoveSiteFromSubscriptionDialog from "./components/RemoveSiteFromSubscriptionDialog"
import SubscriptionOverview from "./components/SubscriptionOverview"
import useCancelSubscription from "./hooks/useCancelSubscription"
import useGetSubscriptionById from "./hooks/useGetSubscriptionById"
import useGetSubscriptionDiagnostics from "./hooks/useGetSubscriptionDiagnostics"
import CombinedSubscriptionDetails from "./components/CombinedSubscriptionDetails"
import useInvalidateSiteEntitlements from "../Sites/hooks/useInvalidateSiteEntitlements"
import useInvalidateSubscription from "./hooks/useInvalidateSubscription"
interface Params {
  subscriptionId?: number
}

const SubscriptionDetailsPage: FunctionComponent = () => {
  const { subscriptionId }: Params = useParams()
  const { subscription, loading, error, getSubscriptionById } = useGetSubscriptionById()
  const {
    subscriptionDiagnostics,
    error: diagnosticsError,
    getSubscriptionDiagnostics,
  } = useGetSubscriptionDiagnostics()
  const {
    isOn: confirmationIsOpen,
    turnOn: showConfirmation,
    turnOff: hideConfirmation,
  } = useToggle()

  const {
    isOn: removeSiteDialogIsOpen,
    turnOn: showRemoveDialog,
    turnOff: hideRemoveDialog,
  } = useToggle()

  const { isOn: subscriptionInvalidated, toggle: toggleSubscriptionInvalidated } = useToggle()

  const {
    cancelSubscription,
    loading: processingSubscriptionCancellation,
    error: errorCancellingSubscription,
  } = useCancelSubscription()

  const {
    invalidateSubscription,
    error: invalidateSubscriptionError,
    loading: invalidateSubscriptionLoading,
  } = useInvalidateSubscription()

  const getSubscription = useCallback(async () => {
    if (subscriptionId) {
      await getSubscriptionById(subscriptionId)
      await getSubscriptionDiagnostics(subscriptionId)
    }
  }, [subscriptionId, getSubscriptionById, getSubscriptionDiagnostics])

  useEffect(() => {
    if (subscriptionId) {
      getSubscription()
    }

    //eslint-disable-next-line
  }, [subscriptionId])

  const handleSuccessfulCancel = useCallback(async () => {
    await getSubscription()
    hideConfirmation()
    toast.success("Successfully canceled subscription")
  }, [getSubscription, hideConfirmation])

  const handleCancelSubscription = useCallback(async () => {
    if (!subscription?.id || (subscription?.type !== "stripe" && subscription?.type !== "credit")) {
      return
    }
    if (confirmationIsOpen) {
      await cancelSubscription(subscription.id, handleSuccessfulCancel)
    } else {
      showConfirmation()
    }
  }, [
    cancelSubscription,
    subscription,
    handleSuccessfulCancel,
    showConfirmation,
    confirmationIsOpen,
  ])

  const linkToStripeSubscription = useCallback(() => {
    if (subscription?.type !== "stripe" || !subscription?.stripe_subscription_id) {
      return
    }
    const link = `${config.stripeUrl}/subscriptions/${subscription.stripe_subscription_id}`
    window.open(link)
  }, [subscription])

  return (
    <ScreenContainer title={`Subscription`}>
      <br />
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {(error ||
        (diagnosticsError && subscription?.type !== "credit") ||
        errorCancellingSubscription) && (
        <ErrorComponent
          error={
            error || diagnosticsError || errorCancellingSubscription || invalidateSubscriptionError
          }
        />
      )}
      {subscription && (
        <Box
          sx={{
            my: "15px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          display="flex"
        >
          <Button
            variant="outlined"
            onClick={showRemoveDialog}
            color="error"
            sx={{ width: "300px", my: "5px" }}
          >
            Remove Site
          </Button>
          {subscription?.type !== "apple" && subscription.status === "active" && (
            <Button
              variant="outlined"
              onClick={handleCancelSubscription}
              color="error"
              disabled={subscription?.type === "apple" || !!processingSubscriptionCancellation}
              sx={{ width: "300px", my: "5px" }}
            >
              Cancel Subscription
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={(x) => invalidateSubscription(subscription.id, toggleSubscriptionInvalidated)}
            disabled={
              !!invalidateSubscriptionLoading ||
              !!invalidateSubscriptionError ||
              subscriptionInvalidated
            }
            sx={{ width: "300px", my: "5px" }}
          >
            Invalidate Subscription
          </Button>
          {subscription?.type === "stripe" && subscription?.stripe_subscription_id && (
            <Button
              color="primary"
              variant="outlined"
              onClick={linkToStripeSubscription}
              disabled={subscription?.type !== "stripe" || !subscription.stripe_subscription_id}
              sx={{ width: "300px", my: "5px" }}
            >
              View In Stripe
            </Button>
          )}
          <SubscriptionOverview subscription={subscription} />

          {subscriptionDiagnostics && (
            <Box sx={{ mt: "15px" }}>
              <CombinedSubscriptionDetails data={subscriptionDiagnostics} />
            </Box>
          )}
          <CancelSubscriptionConfirmationDialog
            subscriptionId={subscription.id}
            isOpen={confirmationIsOpen}
            loading={processingSubscriptionCancellation}
            hasError={!!errorCancellingSubscription}
            close={hideConfirmation}
            handleConfirmation={handleCancelSubscription}
          />
          <RemoveSiteFromSubscriptionDialog
            subscription={subscription}
            isOpen={removeSiteDialogIsOpen}
            close={hideRemoveDialog}
            refresh={getSubscription}
          />
        </Box>
      )}
    </ScreenContainer>
  )
}

export default SubscriptionDetailsPage
