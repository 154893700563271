import { MERCHANTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PayoutSchedule } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetMerchantPayoutSchedule = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, merchantId: number) => {
      return makeRequest<PayoutSchedule>(
        `${MERCHANTSV3}/${merchantId}/payout-schedule`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getMerchantPayoutSchedule: performAction, payoutSchedule: data }
}

export default useGetMerchantPayoutSchedule
