export const removeUnderscores = (val: string) => val.replace("_", " ")

export const snakeCaseToTitleCase = (val: string) =>
  val
    .toLowerCase()
    .split("_")
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(" ")

export const camelize = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, "")
    .replace(/\W/g, "")

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)
