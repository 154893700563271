import React, { FunctionComponent } from "react"
import { Box, Button } from "@mui/material"
import ErrorComponent from "../../../components/shared/Error"
import Dialog from "../../../components/shared/Dialog"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"
import * as Yup from "yup"
import FormikTextField from "../../../components/shared/FormikTextField"
import { FormikProvider } from "formik"
import useCreateCollection from "../hooks/useCreateCollection"

interface Props {
  isOpen: boolean
  close: () => any
}

const CreateCollectionDialog: FunctionComponent<Props> = ({ isOpen, close }) => {
  const { createCollection, loading, error } = useCreateCollection()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  })

  const handleSuccessfulCreate = () => {
    toast.success("Successfully created collection")
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await createCollection(values.name, handleSuccessfulCreate)
    },
  })

  return (
    <Dialog
      title={"Create collection"}
      isOpen={isOpen}
      handleClose={close}
      content={
        <Box>
          {error ? <ErrorComponent error={error} /> : null}
          <FormikProvider value={formik}>
            <FormikTextField
              margin="dense"
              id="name"
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              required
              value={formik.values.name}
              error={!!formik.errors.name}
            />
          </FormikProvider>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            Create
          </Button>
        </>
      }
    />
  )
}

export default CreateCollectionDialog
