import  { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Experiment } from "../../../types"
import ExperimentRow from "./ExperimentRow"

interface Props {
  experiments: Experiment[]
  handleStart: (experiment: Experiment) => any
  handleEdit: (experiment: Experiment) => any
  handleEnd: (experiment: Experiment) => any
  canEdit: boolean
}

const ExperimentsTable: FunctionComponent<Props> = ({
  experiments,
  handleEnd,
  handleStart,
  handleEdit,
  canEdit,
}) => {
  const columns = canEdit
    ? ["Name", "Description", "Started", "Ended", "", ""]
    : ["Name", "Description", "Started", "Ended", ""]

  const getRows = useCallback(() => {
    return experiments.map((experiment: Experiment, index: number) => (
      <ExperimentRow
        key={index}
        experiment={experiment}
        handleEnd={handleEnd}
        handleStart={handleStart}
        handleEdit={handleEdit}
        canEdit={canEdit}
      />
    ))
  }, [experiments, handleEnd, handleStart, handleEdit, canEdit])

  return <BasicTable columns={columns} rows={getRows()} />
}

export default ExperimentsTable
