import { BLOCKTEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BlockTemplate, CreateBlockTemplate } from "../../../types"
import makeRequest from "../../../utils/make-request"

const nullEmptyStrings = (obj: any) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === "") {
      newObj[key] = null
    }
  })
  return newObj
}

const useCreateBlockTemplate = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      blockTemplate: CreateBlockTemplate,
      onSuccess?: () => any,
    ): Promise<BlockTemplate> => {
      const blockTemplateResponse = await makeRequest<BlockTemplate>(
        `${BLOCKTEMPLATES}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(nullEmptyStrings(blockTemplate)),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return blockTemplateResponse
    },
  )

  return { createBlockTemplate: performAction, loading, error }
}

export default useCreateBlockTemplate
