import { SiteViews } from "../types"

export function sumViews(siteViews: SiteViews): number {
  let totalViews = 0

  for (const range of siteViews.ranges) {
    for (const siteView of range) {
      totalViews += siteView.views
    }
  }

  return totalViews
}

export function weeklySumViews(siteViews: SiteViews): number {
  let totalViews = 0

  // yes, this is correct since the call for lifetime adds additional days
  // this matches up with the calls that the clients make
  for (const range of siteViews.ranges.slice(-9)) {
    for (const siteView of range) {
      totalViews += siteView.views
    }
  }

  return totalViews
}
