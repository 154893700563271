import { Autocomplete, Box, TextField } from "@mui/material"
import { useField } from "formik"
import  { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Caption1 } from "../../../components/shared/Typography"
import useDebounce from "../../../hooks/useDebounce"
import { User } from "../../../types"
import containsSpecialCharacters from "../../../utils/containsSpecialCharacters"
import useGetUserById from "../hooks/useGetUserById"
import useSearchUsers from "../hooks/useSearchUsers"
interface Props {
  defaultId?: number | null
}
interface UserOption {
  label: string
  user: User
}

// TODO: fix autocomplete option style

const UserSearchAutocomplete: FunctionComponent<Props> = ({ defaultId }) => {
  const [userOptions, setUserOptions] = useState<UserOption[]>([])
  const [inputValue, setInputValue] = useState<string>("")
  // eslint-disable-next-line
  const [field, meta, helpers] = useField("userOption")

  const { searchUsers, loading: loadingSearch } = useSearchUsers()
  const { getUserById, loading: loadingDefault } = useGetUserById()

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const renderInput = useCallback((params: any) => {
    return <TextField {...params} label="Search Destination User" variant="outlined" />
  }, [])

  const handleValueChange = useCallback(
    (_: any, newValue: UserOption | null) => {
      if (newValue?.user) {
        helpers.setValue(newValue)
        helpers.setError(undefined)
      } else {
        helpers.setValue("")
      }
    },
    [helpers],
  )

  const handleChangeInput = useCallback(
    (_: any, newInputValue: string) => {
      if (!newInputValue) {
        helpers.setValue("")
      }
      setInputValue(newInputValue)
    },
    [helpers],
  )

  const getLabelFromUser = useCallback(
    (user: User) => `${user.id} - ${user.first_name} ${user.last_name} - ${user.email}`,
    [],
  )

  const handleSearchUser = useCallback(
    async (debouncedSearchTerm: string) => {
      if (!debouncedSearchTerm) {
        return setUserOptions([])
      }
      const users = await searchUsers(debouncedSearchTerm)
      const userOptions: UserOption[] = (users?.results || []).map((option) => ({
        label: getLabelFromUser(option),
        user: option,
      }))

      setUserOptions(userOptions)
    },
    [searchUsers, getLabelFromUser],
  )

  const formatOptionLabel = useCallback((option: UserOption) => {
    return option.label || ""
  }, [])

  useEffect(() => {
    if (
      debouncedSearchTerm &&
      debouncedSearchTerm !== "" &&
      !containsSpecialCharacters(debouncedSearchTerm, true)
    ) {
      handleSearchUser(debouncedSearchTerm)
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  const handleGetUserById = useCallback(
    async (userId: number) => {
      const defaultUser = await getUserById(userId)
      if (defaultUser) {
        const option = {
          label: getLabelFromUser(defaultUser),
          user: defaultUser,
        }
        handleChangeInput(null, option.label)
        handleValueChange(null, option)
        setUserOptions([option])
      } else {
        helpers.setValue("")
      }
    },
    [getUserById, getLabelFromUser, handleChangeInput, handleValueChange, helpers],
  )

  useEffect(() => {
    if (defaultId) {
      handleGetUserById(defaultId)
    }
    // eslint-disable-next-line
  }, [defaultId])

  return (
    <Box sx={{ my: "8px", width: "100%" }}>
      <Autocomplete
        options={userOptions}
        inputValue={inputValue}
        onInputChange={handleChangeInput}
        renderInput={renderInput}
        onChange={handleValueChange}
        getOptionLabel={formatOptionLabel}
        loading={loadingSearch || loadingDefault}
      />
      <Box width="100%" height="12px">
        <Caption1 hasError={!!meta.error}> {meta.error && "Required"} </Caption1>
      </Box>
    </Box>
  )
}

export default UserSearchAutocomplete
