import { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { LineItem } from "../../../types"
import { formatPrice } from "../../../utils/formatters/format-numbers"

interface LineItemRowProps {
  lineItem: LineItem
}

const LineItemRow: FunctionComponent<LineItemRowProps> = ({ lineItem }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {lineItem.product_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {lineItem.product_name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {lineItem.description}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {lineItem.notes_prompt}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {lineItem.notes}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {lineItem.quantity}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatPrice(lineItem.price)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {lineItem.download_code}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default LineItemRow
