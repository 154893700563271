import { useEffect, useState } from "react"
import { BLOCKEDDOMAINPHRASES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { BlockedDomainPhrases } from "../../../types"

const useGetBlockedDomainPhrases = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getBlockedDomainPhrases,
  } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<BlockedDomainPhrases>> => {
    return makeRequest<PagedResponse<BlockedDomainPhrases>>(
      `${BLOCKEDDOMAINPHRASES}?limit=${encodeURIComponent(rowsPerPage)}&offset=${encodeURIComponent(
        page * rowsPerPage,
      )}`,
      {},
      { handleUnauthenticated },
    )
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getBlockedDomainPhrases()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getBlockedDomainPhrases,
    blockedDomainPhrases: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetBlockedDomainPhrases
