import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material"
import { FunctionComponent, useEffect, useCallback, useState } from "react"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SiteLink from "./components/SiteLink"
import useExploreSites from "./hooks/useExploreSites"
import useGetTags from "./hooks/useGetTags"
import { useSearchParams } from "react-router-dom"
import useGetCategories from "../Categories/hooks/useGetCategories"
import useGetArchetypesForCategory from "../Categories/hooks/useGetArchetypesForCategory"

const SiteExplorerScreen: FunctionComponent = () => {
  let [searchParams, setSearchParams] = useSearchParams()

  const [searchValue, setSearchValue] = useState("")
  const [archetype, setArchetype] = useState<String>("")
  const [tag, setTag] = useState<String>("")
  const [categoryId, setCategoryId] = useState<string>("")
  const [archetypeId, setArchetypeId] = useState<string>("")

  const { getTags, tags } = useGetTags()
  const { exploreSites, sites, paginationData, loading } = useExploreSites()
  const { getCategories, categories } = useGetCategories()
  const { getArchetypesForCategory, archetypes } = useGetArchetypesForCategory()

  const handleSearchFromRouter = useCallback(async () => {
    const term = searchParams.get("term")
    const searchArchetype = searchParams.get("legacy_archetype_name")
    const searchTag = searchParams.get("tag")
    const categoryId = searchParams.get("category_id")
    const archetypeId = searchParams.get("archetype_id")

    if (term || searchArchetype || searchTag) {
      await exploreSites({
        q: term,
        legacy_archetype_name: searchArchetype,
        tag: searchTag,
        category_id: categoryId,
        archetype_id: archetypeId,
      })
      setSearchValue(term || "")
      setArchetype(searchArchetype || "")
      setTag(searchTag || "")
    }
  }, [searchParams, exploreSites])

  useEffect(() => {
    handleSearchFromRouter()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (categoryId) {
      getArchetypesForCategory(categories.find((x) => x.id === parseInt(categoryId, 10))!)
    }
    // eslint-disable-next-line
  }, [categoryId])

  const searchSites = useCallback(async () => {
    await exploreSites({
      q: searchValue,
      legacy_archetype_name: archetype,
      tag,
      category_id: categoryId,
      archetype_id: archetypeId,
    })
    setSearchParams({
      term: searchValue as string,
      legacy_archetype_name: archetype as string,
      tag: tag as string,
      category_id: categoryId,
      archetype_id: archetypeId,
    })
  }, [searchValue, exploreSites, archetype, tag, setSearchParams])

  useEffect(() => {
    getTags()
    getCategories()
    // eslint-disable-next-line
  }, [])

  const handleArchetypeChange = (event: any) => {
    setArchetype(event.target.value)

    setArchetypeId("")
    setCategoryId("")
  }

  const handleArchetypeIdChange = (event: any) => {
    setArchetypeId(event.target.value)
  }

  const handleCategoryIdChange = (event: any) => {
    setCategoryId(event.target.value)
    setArchetypeId("")
    setArchetype("")
  }

  const handleTagChange = (event: any) => {
    setTag(event.target.value)
  }

  const handleSearchBoxChange = (event: any) => {
    setSearchValue(event.target.value)
  }

  return (
    <div>
      <div className="site-search-wrapper">
        <h2 className="find-sites-header">Explore</h2>
        <h3 className="find-sites-subtitle">
          Search by archetype, tags,<br></br>title, or description.
        </h3>
        <div>
          <div style={{ verticalAlign: "center" }}>
            <TextField
              className="find-sites-search-bar"
              variant="outlined"
              label="Search"
              defaultValue={searchParams.get("term")}
              value={searchValue}
              onChange={handleSearchBoxChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchSites()
                }
              }}
            />
            <Button
              variant="contained"
              fullWidth
              color="primary"
              sx={{
                marginTop: "16px",
                maxWidth: "500px",
                alignSelf: "center",
              }}
              onClick={() => searchSites()}
            >
              Search
            </Button>
          </div>
          <br />
          <span className="find-sites-search-bar">
            Category:
            <Select id="categories" onChange={handleCategoryIdChange} value={categoryId}>
              <MenuItem value="">--</MenuItem>
              {categories?.map((c) => (
                <MenuItem value={c.id} key={c.id}>
                  {c.display_name}
                </MenuItem>
              ))}
            </Select>
            Archetype:
            <Select id="archetypes" onChange={handleArchetypeIdChange} value={archetypeId}>
              <MenuItem value="">--</MenuItem>
              {archetypes?.map((a) => (
                <MenuItem value={a.id} key={a.id}>
                  {a.display_name}
                </MenuItem>
              ))}
            </Select>
            <br />
            Tag:
            <Select id="tags" onChange={handleTagChange}>
              <MenuItem value="">--</MenuItem>
              {tags?.map((t) => (
                <MenuItem value={t.title} key={t.id}>
                  {t.display_title}
                </MenuItem>
              ))}
            </Select>
            <br />
            Legacy Archetype:
            <Select id="legacy-archetypes" onChange={handleArchetypeChange}>
              <MenuItem value="">--</MenuItem>
              <MenuItem value="actor">Actor</MenuItem>
              <MenuItem value="agent">Agent</MenuItem>
              <MenuItem value="blog">Blog</MenuItem>
              <MenuItem value="business">Business</MenuItem>
              <MenuItem value="chef">Chef</MenuItem>
              <MenuItem value="church">Church</MenuItem>
              <MenuItem value="crafter">Crafter</MenuItem>
              <MenuItem value="designer">Designer</MenuItem>
              <MenuItem value="doctor">Doctor</MenuItem>
              <MenuItem value="education">Education</MenuItem>
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="fitness">Fitness</MenuItem>
              <MenuItem value="gamer">Gamer</MenuItem>
              <MenuItem value="influencer">Influencer</MenuItem>
              <MenuItem value="lawyer">Lawyer</MenuItem>
              <MenuItem value="localBusiness">Local Business</MenuItem>
              <MenuItem value="musician">Musician</MenuItem>
              <MenuItem value="onlineStore">OnlineStore</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="personal">Personal</MenuItem>
              <MenuItem value="photographer">Photographer</MenuItem>
              <MenuItem value="podcast">Podcast</MenuItem>
              <MenuItem value="portfolio">Portfolio</MenuItem>
              <MenuItem value="professionalService">Professional Service</MenuItem>
              <MenuItem value="restaurant">Restaurant</MenuItem>
              <MenuItem value="store">Store</MenuItem>
              <MenuItem value="stylist">Stylist</MenuItem>
              <MenuItem value="teacher">Teacher</MenuItem>
              <MenuItem value="unknown">Unknown</MenuItem>
              <MenuItem value="youtuber">Youtuber</MenuItem>
            </Select>
          </span>
        </div>
      </div>
      {!loading ? (
        <div>
          <TablePagination
            component="div"
            count={paginationData.count}
            page={paginationData.page}
            onPageChange={paginationData.onPageChange}
            rowsPerPage={paginationData.rowsPerPage}
            onRowsPerPageChange={paginationData.onRowsPerPageChange}
          />
          <ImageList rowHeight={240} cols={5} sx={{ width: "100%" }}>
            {sites.map((site) => (
              <ImageListItem key={site.id}>
                <SiteLink siteId={site.id}>
                  <img
                    height={240}
                    src={site.thumbnails.small}
                    alt={site.title || site.domain || site.subdomain || undefined}
                  />
                </SiteLink>

                <ImageListItemBar title={site.title} subtitle={site.domain || site.subdomain} />
              </ImageListItem>
            ))}
          </ImageList>
          <TablePagination
            component="div"
            count={paginationData.count}
            page={paginationData.page}
            onPageChange={paginationData.onPageChange}
            rowsPerPage={paginationData.rowsPerPage}
            onRowsPerPageChange={paginationData.onRowsPerPageChange}
          />
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default SiteExplorerScreen
