import { Box, Button } from "@mui/material"
import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import useGetPageTypes from "./hooks/useGetPageTypes"
import PageTypesTable from "./components/PageTypesTable"
import CreateEditPageTypeDialog from "./components/CreateEditPageTypeDialog"

const PageTypesScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.pageTypes.create)
  const canEdit = hasScope(scopes.pageType.update)
  const canDelete = hasScope(scopes.pageType.delete)

  const [createOpen, setCreateOpen] = useState(false)

  const { getPageTypes, pageTypes, paginationData, loading, error } = useGetPageTypes()

  useEffect(() => {
    getPageTypes()
    // eslint-disable-next-line
  }, [])

  const handlePageUpdateClose = () => {
    setCreateOpen(false)
  }

  return (
    <ScreenContainer title="Page Types" requiresScope={scopes.pageTypes.read}>
      {error && <ErrorComponent error={error} />}
      {canCreate && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ my: "12px", width: "300px" }}
          onClick={() => setCreateOpen(true)}
        >
          Create New Page Type
        </Button>
      )}
      <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
        {loading && <LoadingSpinner />}
        <PageTypesTable
          pageTypes={pageTypes}
          paginationData={paginationData}
          canEdit={canEdit}
          canDelete={canDelete}
          refreshData={getPageTypes}
        />
      </Box>
      {createOpen && (
        <CreateEditPageTypeDialog
          isOpen={createOpen}
          close={handlePageUpdateClose}
          refreshData={getPageTypes}
          canDelete={canDelete}
        />
      )}
    </ScreenContainer>
  )
}

export default PageTypesScreen
