import { Button, TextField } from "@mui/material"
import { FunctionComponent, useCallback, useState } from "react"
import toast from "react-hot-toast"
import Dialog from "../../../components/shared/Dialog"
import { camelize } from "../../../utils/formatters"
import useCreateCategory from "../hooks/useCreateCategory"

interface Props {
  isOpen: boolean
  handleClose: () => any
}

const CreateCategoryDialog: FunctionComponent<Props> = ({ isOpen, handleClose }) => {
  const [newDisplayName, setNewDisplayName] = useState<string>()
  const [iconUrl, setIconUrl] = useState<string>()
  const [archetypeSelectionDescription, setArchetypeSelectionDescription] = useState<string>()

  const { createCategory } = useCreateCategory()

  const handleSetDisplayName = (event: any) => setNewDisplayName(event?.target?.value)
  const handleSetIconUrl = (event: any) => setIconUrl(event?.target?.value)

  const handleSetArchetypeSelectionDescription = (event: any) =>
    setArchetypeSelectionDescription(event?.target?.value)

  const handleConfirmClose = useCallback(async () => {
    if (newDisplayName && iconUrl && archetypeSelectionDescription) {
      await createCategory({
        name: camelize(newDisplayName),
        display_name: newDisplayName,
        icon_url: iconUrl,
        archetype_selection_description: archetypeSelectionDescription,
      })

      toast.success("Successfully created category") // FIXME: there are some bugs around this - it will fire regardless of success of failure.
    }
    handleClose()
  }, [newDisplayName, iconUrl, archetypeSelectionDescription, handleClose, createCategory])

  return (
    <Dialog
      title="New Category"
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Display Name"
            fullWidth
            variant="outlined"
            onChange={handleSetDisplayName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="iconUrl"
            label="Icon URL (SVG)"
            fullWidth
            variant="outlined"
            onChange={handleSetIconUrl}
          />
          <TextField
            autoFocus
            margin="dense"
            id="archetypeSelectionDescription"
            label="Archetype Selection Description"
            fullWidth
            variant="outlined"
            onChange={handleSetArchetypeSelectionDescription}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmClose} color="primary" variant="outlined">
            Create Category
          </Button>
        </>
      }
    />
  )
}

export default CreateCategoryDialog
