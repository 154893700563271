import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import  { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import topics from "../../topics"
import EventsTable from "./components/EventsTable"
import useGetEvents from "./hooks/useGetEvents"

const EventsScreen: FunctionComponent = () => {
  const currentDate = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(currentDate.getDate() + 1)
  const currentDateString = String(currentDate.toISOString().split("T")[0])
  const tomorrowString = String(tomorrow.toISOString().split("T")[0])

  const [topicSelected, setTopicSelected] = useState("")
  const [requestID, setRequestID] = useState("")
  const [startDate, setStartDate] = useState(`${currentDateString}`)
  const [endDate, setEndDate] = useState(`${tomorrowString}`)
  const {
    loading: eventsLoading,
    error: eventsError,
    getEvents,
    events,
    paginationData,
  } = useGetEvents()

  const handleTopicChange = (event: any) => {
    setTopicSelected(event.target.value)
  }

  const handleRequestIDChange = (event: any) => {
    setRequestID(event.target.value)
  }

  const handleStartDateChange = (date: string) => {
    const stringToDate = new Date(date)
    setStartDate(stringToDate.toISOString().split("T")[0])
  }

  const handleEndDateChange = (date: string) => {
    const stringToDate = new Date(date)
    setEndDate(stringToDate.toISOString().split("T")[0])
  }

  useEffect(() => {
    getEvents(startDate, endDate, topicSelected, requestID)
    // eslint-disable-next-line
  }, [startDate, endDate, topicSelected, requestID])

  const sortedTopics = topics.sort()

  return (
    <ScreenContainer title="Events" requiresScope={scopes.events.read}>
      <br />
      <FormControl sx={{ width: "400px", ml: "0px" }}>
        <InputLabel>Topic</InputLabel>
        <Select
          name="topics"
          autoWidth
          onChange={handleTopicChange}
          value={topicSelected}
          fullWidth
          variant="outlined"
        >
          {sortedTopics.map((topic) => (
            <MenuItem key={topic} value={topic}>
              {topic}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <TextField label="Request ID" value={requestID} onChange={handleRequestIDChange} />
      </Box>
      <br />
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newStart) => {
            handleStartDateChange(newStart!)
          }}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="YYYY-MM-DD"
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newEnd) => {
            handleEndDateChange(newEnd!)
          }}
          renderInput={(params) => <TextField {...params} required />}
          inputFormat="YYYY-MM-DD"
        />
      </Box>
      <br />
      {eventsError ? <ErrorComponent error={eventsError} /> : ""}
      {eventsLoading ? (
        <LoadingSpinner />
      ) : (
        <EventsTable events={events} paginationData={paginationData} />
      )}
    </ScreenContainer>
  )
}

export default EventsScreen
