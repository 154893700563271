import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { ImageStatus } from "../../../types/images"
import NoContextRow from "./NoContextRow"

interface ImageStatusProps {
  images: ImageStatus[] | undefined
  paginationData: PaginationData
}

const NoContextList: FunctionComponent<ImageStatusProps> = ({ images, paginationData }) => {
  const columns = ["Image", "Prompt", "Date", "Completed", "User"]

  const getRows = useCallback(() => {
    return (images || []).map((image: ImageStatus, index: number) => (
      <NoContextRow key={index} image={image} />
    ))
  }, [images])

  return <BasicTable columns={columns} paginationOptions={paginationData} rows={getRows()} />
}

export default NoContextList
