import React, { useEffect, FunctionComponent, useCallback } from "react"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Typography,
  SelectChangeEvent,
} from "@mui/material"

import { Entitlement, ProductEntitlement, UpdateProductEntitlement } from "../../../types"

function prettyPrintByteSize(bytes: number | null) {
  if (bytes === null) return ""
  if (bytes === 0) return "0 Bytes"
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const size = (bytes / Math.pow(1024, i)).toFixed(2)
  return `${size} ${sizes[i]}`
}

type EntitlementUpdate = {
  entitlementId: number
  checked: boolean
  usageLimit: number | null
  usageLimitRate: string
}

type EntitlementProp = {
  onChange: (entitlement: EntitlementUpdate) => any
  entitlement: Entitlement
  productEntitlement?: ProductEntitlement | UpdateProductEntitlement
}

const EntitlementComponent: FunctionComponent<EntitlementProp> = ({
  entitlement,
  onChange,
  productEntitlement,
}) => {
  const [checked, setChecked] = React.useState(false)
  const [usageLimit, setUsageLimit] = React.useState(entitlement.usage_limit_default)
  const [usageLimitRate, setUsageLimitRate] = React.useState(entitlement.usage_limit_period || "")

  useEffect(() => {
    onChange({ entitlementId: entitlement.id, checked, usageLimit, usageLimitRate })
  }, [checked, usageLimit, usageLimitRate])

  useEffect(() => {
    if (productEntitlement) {
      setChecked(true)
      setUsageLimit(productEntitlement.usage_limit || null)
      setUsageLimitRate(productEntitlement.usage_limit_period || "")
    }
  }, [productEntitlement])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleUsageLimitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setUsageLimit(parseInt(event.target.value))
  }

  const handleUsageLimitRateChange = (event: SelectChangeEvent) => {
    setUsageLimitRate(event.target.value)
  }

  return (
    <Box display="flex" alignItems="center" gap={2} p={2}>
      <Box display="flex" flexDirection="column" alignItems="left">
        <Box>
          <FormControlLabel
            control={
              <Checkbox checked={checked || entitlement.is_free} onChange={handleCheckboxChange} />
            }
            label={`${entitlement.title} ${entitlement.variant ? `(${entitlement.variant})` : ""}`}
            disabled={entitlement.is_free}
          />
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            {entitlement.description}
          </Typography>
        </Box>
      </Box>
      <Box>
        {entitlement.usage_enabled && (
          <FormControl variant="outlined" size="small">
            <TextField
              label={`Limit (${entitlement.usage_limit_unit || ""})`}
              type="number"
              value={usageLimit}
              onChange={handleUsageLimitChange}
              size="small"
            />
          </FormControl>
        )}
      </Box>
      <Box>
        {entitlement.usage_enabled && (
          <FormControl variant="outlined" style={{ minWidth: 120 }}>
            <InputLabel>Usage Period</InputLabel>
            <Select
              value={usageLimitRate}
              onChange={handleUsageLimitRateChange}
              label="Usage Period"
              fullWidth
            >
              <MenuItem value=""> -- None --</MenuItem>
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
      {entitlement.usage_limit_unit === "bytes" && (
        <Box>
          <Typography>{prettyPrintByteSize(usageLimit)}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default EntitlementComponent
