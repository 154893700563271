import { TEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Template, UpdateTemplate } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateTemplate = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      templateId: number,
      template: UpdateTemplate,
      onSuccess?: () => any,
    ): Promise<Template> => {
      const response = await makeRequest<Template>(
        `${TEMPLATES}/${templateId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...template }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateTemplate: performAction, loading, error }
}

export default useUpdateTemplate
