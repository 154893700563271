import { Autocomplete, Box, TextField } from "@mui/material"
import { useField } from "formik"
import  { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Caption1 } from "../../../components/shared/Typography"
import useDebounce from "../../../hooks/useDebounce"
import { Site } from "../../../types"
import containsSpecialCharacters from "../../../utils/containsSpecialCharacters"
import useSearchSites from "../hooks/useSearchSites"

interface SiteOption {
  label: string
  site: Site
}

const SiteSearchAutocomplete: FunctionComponent = () => {
  const [siteOptions, setSiteOptions] = useState<SiteOption[]>([])
  const [inputValue, setInputValue] = useState<string>("")

  // eslint-disable-next-line
  const [field, meta, helpers] = useField("siteOption")

  const { searchSites, loading } = useSearchSites()

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const renderInput = useCallback((params: any) => {
    return <TextField {...params} label="Search Site" variant="outlined" />
  }, [])

  const handleValueChange = useCallback(
    (_: any, newValue: SiteOption | null) => {
      if (newValue?.site) {
        helpers.setValue(newValue.site)
        helpers.setError(undefined)
      } else {
        helpers.setValue("")
      }
    },
    [helpers],
  )

  const handleChangeInput = useCallback((_: any, newInputValue: string) => {
    setInputValue(newInputValue)
  }, [])

  const handleSearchSite = useCallback(
    async (debouncedSearchTerm: string) => {
      if (!debouncedSearchTerm) {
        return setSiteOptions([])
      }
      const sites = await searchSites(debouncedSearchTerm)
      const siteOptions: SiteOption[] = (sites?.results || []).map((option: Site) => {
        const obj = {
          label: `${option.id}`,
          site: option,
        }

        if (option?.title) {
          obj.label += ` - ${option.title}`
        }

        return obj
      })
      setSiteOptions(siteOptions)
    },
    [searchSites],
  )

  const formatOptionLabel = useCallback((option: SiteOption) => {
    return option.label || ""
  }, [])

  useEffect(() => {
    if (
      debouncedSearchTerm &&
      debouncedSearchTerm !== "" &&
      !containsSpecialCharacters(debouncedSearchTerm, true)
    ) {
      handleSearchSite(debouncedSearchTerm)
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  return (
    <Box sx={{ my: "8px", width: "100%" }}>
      <Autocomplete
        options={siteOptions}
        inputValue={inputValue}
        onInputChange={handleChangeInput}
        renderInput={renderInput}
        onChange={handleValueChange}
        value={meta.value.label}
        getOptionLabel={formatOptionLabel}
        loading={loading}
      />
      <Box width="100%" height="8px">
        <Caption1 hasError={!!meta.error}> {meta.error && "Required"} </Caption1>
      </Box>
    </Box>
  )
}

export default SiteSearchAutocomplete
