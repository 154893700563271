import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { ImageStatus } from "../../../types"

const useGetStatus = () => {
  const { error, loading, performAction } = useHandleRequest(
    //eslint-ignore-next-line
    (handleUnauthenticated): Promise<ImageStatus> => {
      return makeRequest<ImageStatus>("https://image-yeeter.onuniverse.cloud/status", {}, { handleUnauthenticated, timeout: 1250 })
    },
  )

  return { getStatus: performAction, loading, error }
}

export default useGetStatus
