import { SITESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { V5_Site } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSiteById = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, siteId: number): Promise<V5_Site> => {
      return makeRequest<V5_Site>(
        `${SITESV5}/${siteId}?deleted=true`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getSiteById: performAction, site: data }
}

export default useGetSiteById
