import  { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  siteId: number
  groupId: number
  pageId: number
}

type SitePageGroupToSubLinkProps = Omit<Props, "to">

const SitePageGroupToSubGridLink: FunctionComponent<SitePageGroupToSubLinkProps> = ({
  siteId,
  groupId,
  pageId,
  children,
  ...rest
}) => (
  <Link
    {...rest}
    style={{ textDecoration: "none" }}
    to={`/sites/${siteId}/pages/${groupId}/${pageId}`}
  >
    {children}
  </Link>
)

export default SitePageGroupToSubGridLink
