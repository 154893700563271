import { BLOCKTEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BlockTemplate } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteBlockTemplate = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      blockTemplateId: string,
      onSuccess?: () => any,
    ): Promise<BlockTemplate> => {
      const blockTemplate = await makeRequest<BlockTemplate>(
        `${BLOCKTEMPLATES}/${blockTemplateId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return blockTemplate
    },
  )

  return { deleteBlockTemplate: performAction, loading, error }
}

export default useDeleteBlockTemplate
