import  { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  siteId: number
  groupId: number
}

type SitePageGroupToSubLinkProps = Omit<Props, "to">

const SitePageGroupToSubLink: FunctionComponent<SitePageGroupToSubLinkProps> = ({
  siteId,
  groupId,
  children,
  ...rest
}) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/sites/${siteId}/pages/${groupId}`}>
    {children}
  </Link>
)

export default SitePageGroupToSubLink
