import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { useFormik } from "formik"
import { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"

import ErrorComponent from "../../../components/shared/Error"
import { Site, Template, UpdateTemplate, Tag } from "../../../types"
import useSearchSites from "../../Sites/hooks/useSearchSites"
import useCreateTemplate from "../hooks/useCreateTemplate"
import useDeleteTemplate from "../hooks/useDeleteTemplate"
import useUpdateTemplate from "../hooks/useUpdateTemplate"
import { stripDomainJunk } from "../../../utils/formatters"
import useGetCollections from "../../Tags/hooks/useGetCollections"
import useGetSetupFlows from "../hooks/useGetSetupFlows"
import useListEntitlements from "../../Entitlements/hooks/useListEntitlements"
import useListTemplateFilters from "../../TemplateFilters/hooks/useListTemplateFilters"
import useGetTags from "../../Sites/hooks/useGetTags"

import { ExpandLess } from "@mui/icons-material"
import Dialog from "../../../components/shared/Dialog"
interface Props {
  template?: Template | undefined
  close: () => void
  open: boolean
}

const TemplateDialog: FunctionComponent<Props> = ({ close, template, open }) => {
  const { getSetupFlows, setupFlows } = useGetSetupFlows()
  const { listEntitlements, entitlements } = useListEntitlements(1000)
  const { listTemplateFilters, templateFilters } = useListTemplateFilters()
  const { tags, getTags } = useGetTags()
  const { collections, getCollections } = useGetCollections()

  const { searchSites, sites } = useSearchSites()
  const { createTemplate, loading, error } = useCreateTemplate()
  const { updateTemplate, loading: updateLoading, error: updateError } = useUpdateTemplate()
  const { deleteTemplate, loading: deleteLoading, error: deleteError } = useDeleteTemplate()

  const [siteSearch, setSiteSearch] = useState<string>("")
  const [site, setSite] = useState<Site>()
  const [otherTags, setOtherTags] = useState<string[]>([])
  const [showOther, setShowOther] = useState<boolean>(false)
  const [uniqueEntitlements, setUniqueEntitlements] = useState<string[]>([])

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(),
    description: Yup.string().required(),
    subdomain: Yup.string().required(),
    badge: Yup.string().nullable(true).optional(),
    requiredEntitlement: Yup.string().nullable(true).optional(),
    setupFlowRequired: Yup.string().nullable(true).optional(),
    publicTheme: Yup.boolean().optional(),
    siteId: Yup.number().integer().nullable(true).optional(),
    tags: Yup.array().of(Yup.string()).optional(),
  })

  const toggleShowOther = () => {
    setShowOther(!showOther)
  }

  useEffect(() => {
    if (site) {
      formik.setFieldValue("siteId", site.id)
    }
  }, [site])

  useEffect(() => {
    listTemplateFilters()
    getCollections()
    getSetupFlows()
    listEntitlements()
    getTags()
  }, [])

  useEffect(() => {
    if (tags && collections && templateFilters) {
      const collectionTags = templateFilters.reduce((acc, next) => {
        collections
          .find((f) => f.id === next.collection_id)
          ?.tags?.forEach((tag: Tag) => {
            acc.add(tag.title)
          })

        return acc
      }, new Set<string>())

      const newTags = tags
        .map((tag) => tag.title)
        .filter(
          (tag) =>
            !collectionTags.has(tag) &&
            !templateFilters.find((f) => f.tag?.title === tag) &&
            ["ios", "web", "mac"].indexOf(tag) === -1,
        )
      setOtherTags(newTags)
    }
  }, [tags, collections, templateFilters])

  // unique entitlements
  useEffect(() => {
    const uniqueEntitlements = entitlements.reduce((acc, nxt) => {
      acc.add(nxt.title)
      return acc
    }, new Set<string>())

    setUniqueEntitlements(Array.from(uniqueEntitlements))
  }, [entitlements])

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleDeleteTemplate: any = async () => {
    if (template) {
      await deleteTemplate(template.id, () => handleSuccess("Successfully deleted template"))
    }
  }

  const handleSiteRefresh: any = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      formik.setFieldValue("siteId", template?.site_id || "")
    } else {
      formik.setFieldValue("siteId", "")
    }
  }

  const toggleTag = (tag: string) => {
    const tags = formik.values.tags
    if (tags.includes(tag)) {
      formik.setFieldValue(
        "tags",
        tags.filter((t: string) => t !== tag),
      )
    } else {
      formik.setFieldValue("tags", [...tags, tag])
    }
  }

  const formik = useFormik({
    initialValues: {
      displayName: template?.display_name || "",
      description: template?.description || "",
      subdomain: template?.subdomain || "",
      badge: template?.badge || "",
      requiredEntitlement: template?.required_entitlement || "",
      setupFlowRequired: template?.setup_flow_required || "",
      siteId: null,
      publicTheme: false,
      tags: template?.tags || [],
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!template) {
        const updateData: UpdateTemplate = {
          display_name: values.displayName,
          description: values.description,
          subdomain: values.subdomain,
          badge: values.badge === "" ? null : values.badge,
          required_entitlement:
            values.requiredEntitlement === "" ? null : values.requiredEntitlement,
          setup_flow_required: values.setupFlowRequired === "" ? null : values.setupFlowRequired,
          site_id: values.siteId === null ? undefined : values.siteId,
          public_theme: values.publicTheme,
          tags: values.tags,
        }

        await updateTemplate(template.id, updateData, () =>
          handleSuccess("Successfully updated template"),
        )
      } else {
        await createTemplate(
          {
            display_name: values.displayName,
            description: values.description,
            subdomain: values.subdomain,
            badge: values.badge === "" ? null : values.badge,
            required_entitlement:
              values.requiredEntitlement === "" ? null : values.requiredEntitlement,
            setup_flow_required: values.setupFlowRequired === "" ? null : values.setupFlowRequired,
            site_id: values.siteId,
            public_theme: values.publicTheme,
            tags: values.tags,
          },
          () => handleSuccess("Successfully created template"),
        )
      }
    },
  })

  const handleSuccess: any = async (msg: string) => {
    toast.success(msg)
    close()
  }

  return (
    <Dialog
      title={!!template ? `Update ${template.display_name}` : "Create New Template"}
      isOpen={open}
      handleClose={handleClose}
      loading={loading || updateLoading || deleteLoading}
      content={
        <>
          {(error || updateError || deleteError) && (
            <ErrorComponent error={error ?? updateError ?? deleteError} />
          )}

          <TextField
            margin="dense"
            id="displayName"
            name="displayName"
            label="Display Name"
            fullWidth
            variant="outlined"
            required
            onChange={formik.handleChange}
            value={formik.values.displayName}
            error={!!formik.errors.displayName}
            helperText={formik.errors.displayName?.toString()}
          />

          <TextField
            margin="dense"
            id="description"
            name="description"
            label="Description"
            fullWidth
            variant="outlined"
            required
            onChange={formik.handleChange}
            value={formik.values.description}
            error={!!formik.errors.description}
            helperText={formik.errors.description?.toString()}
          />

          <TextField
            margin="dense"
            id="subdomain"
            name="subdomain"
            label="Subdomain"
            fullWidth
            variant="outlined"
            required
            onChange={formik.handleChange}
            value={formik.values.subdomain}
            error={!!formik.errors.subdomain}
            helperText={formik.errors.subdomain?.toString()}
          />

          <Grid container spacing={2}>
            <Grid item sm={6}>
              <FormGroup>
                <FormLabel component="legend">Requires Entitlement</FormLabel>
                <Select
                  fullWidth
                  name="requiredEntitlement"
                  variant="outlined"
                  id="requiredEntitlement"
                  label="Required Entitlement"
                  onChange={formik.handleChange}
                  value={formik.values.requiredEntitlement}
                  error={!!formik.errors.requiredEntitlement}
                >
                  <MenuItem key={""} value={""} selected={formik.values.requiredEntitlement === ""}>
                    No entitlement required
                  </MenuItem>
                  {uniqueEntitlements.map((title, idx) => (
                    <MenuItem
                      key={`${title}-${idx}`}
                      value={title}
                      selected={formik.values.requiredEntitlement === title}
                    >
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <FormLabel component="legend">Required Setup Flow</FormLabel>
                <Select
                  fullWidth
                  name="setupFlowRequired"
                  variant="outlined"
                  id="setupFlowRequired"
                  label="Required Setup Flow"
                  onChange={formik.handleChange}
                  value={formik.values.setupFlowRequired}
                  error={!!formik.errors.setupFlowRequired}
                >
                  <MenuItem key={""} value={""} selected={formik.values.setupFlowRequired === ""}>
                    No setup flow
                  </MenuItem>
                  {setupFlows.map((setupFlow) => (
                    <MenuItem
                      key={setupFlow.setup_flow}
                      value={setupFlow.setup_flow}
                      selected={formik.values.setupFlowRequired === setupFlow.setup_flow}
                    >
                      {setupFlow.setup_flow}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>
            </Grid>
          </Grid>

          <TextField
            margin="dense"
            id="badge"
            name="badge"
            label="Badge"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.badge}
            error={!!formik.errors.badge}
            helperText={formik.errors.badge?.toString()}
          />

          {!template ? (
            <FormGroup>
              <FormLabel component="legend">Theme</FormLabel>
              <FormControlLabel
                control={
                  <Switch value={formik.values.publicTheme} onChange={formik.handleChange} />
                }
                label="Display template theme in the in-app theme picker for all users to apply to sites"
              />
            </FormGroup>
          ) : null}
          {template?.site_id ? (
            <FormGroup>
              <FormControlLabel
                control={<Switch value={formik.values.publicTheme} onChange={handleSiteRefresh} />}
                label={`Refresh template from live site (${template.site_id})`}
              />
            </FormGroup>
          ) : null}
          <p>Filters/Tags</p>
          <Grid container spacing={2}>
            <Grid item sm={2}>
              <FormLabel component="legend">Platform</FormLabel>
            </Grid>
            <Grid item sm={10}>
              {["Web", "iOS", "Mac"].map((platform, index) => (
                <Chip
                  key={index}
                  label={platform}
                  color={
                    formik.values.tags.includes(platform.toLowerCase()) ? "primary" : undefined
                  }
                  onClick={() => toggleTag(platform.toLowerCase())}
                  disabled={loading}
                  style={{ margin: "3px" }}
                />
              ))}
              <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
            </Grid>
          </Grid>

          {templateFilters.map((filter, index) => (
            <Grid container spacing={2} key={`${filter.title}-${index}`}>
              <Grid item sm={2}>
                <FormLabel component="legend">{filter.title}</FormLabel>
              </Grid>
              <Grid item sm={10}>
                {filter.type === "boolean" && filter.tag && (
                  <Chip
                    label={filter.tag?.display_title}
                    color={formik.values.tags.includes(filter.tag?.title) ? "primary" : undefined}
                    onClick={() => toggleTag(filter.tag!.title)}
                    disabled={loading}
                    style={{ margin: "3px" }}
                  />
                )}

                {collections &&
                  filter.collection &&
                  collections
                    .find((x) => x.id === filter.collection_id)
                    ?.tags?.map((tag: Tag, index: number) => (
                      <Chip
                        key={index}
                        label={tag.display_title}
                        color={formik.values.tags.includes(tag.title) ? "primary" : undefined}
                        onClick={() => toggleTag(tag.title)}
                        disabled={loading}
                        style={{ margin: "3px" }}
                      />
                    ))}

                <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
              </Grid>
            </Grid>
          ))}

          <Grid container spacing={2}>
            <Grid item sm={2}>
              <Button
                onClick={toggleShowOther}
                startIcon={showOther ? <ExpandMoreIcon /> : <ExpandLess />}
              >
                Other Tags
              </Button>
            </Grid>
            <Grid item sm={10}>
              {showOther &&
                otherTags.map((tag: string, index: number) => (
                  <Chip
                    key={index}
                    label={tag}
                    color={formik.values.tags.includes(tag) ? "primary" : undefined}
                    onClick={() => toggleTag(tag)}
                    disabled={loading}
                    style={{ margin: "3px" }}
                  />
                ))}
            </Grid>
          </Grid>

          <FormLabel component="legend">Site</FormLabel>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <TextField
              margin="dense"
              id="url"
              label="Site Search"
              fullWidth
              variant="outlined"
              onChange={(s) => setSiteSearch(stripDomainJunk(s.target.value))}
              value={siteSearch}
            />
            <Button
              onClick={() => searchSites(siteSearch)}
              sx={{ marginLeft: "10px" }}
              disabled={!siteSearch}
            >
              Search
            </Button>
          </Box>

          <FormGroup>
            <RadioGroup aria-label="sites" name="radio-buttons-group">
              <table>
                <tbody>
                  <tr>
                    <td valign="top">
                      {sites.map((s) => (
                        <FormControlLabel
                          key={s.id}
                          value={s.id}
                          control={<Radio checked={s.id === (site?.id || false)} />}
                          label={`${s.title} ${s.urls[0]}`}
                          onChange={(x) => setSite(s)}
                        />
                      ))}
                    </td>
                    <td>
                      {site ? (
                        <img
                          src={site?.thumbnails.small}
                          alt={site.title || site.description || site.urls[0]}
                          style={{ width: "100px" }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </RadioGroup>
          </FormGroup>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleDeleteTemplate} color="error" variant="contained">
            Delete Template
          </Button>

          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading || deleteLoading}
          >
            {!!template ? "Update Template" : "Create Template"}
          </Button>
        </>
      }
    />
  )
}

export default TemplateDialog
