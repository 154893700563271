import { PAGETYPESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageType, CreatePageType } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreatePageType = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      pageType: CreatePageType,
      onSuccess?: () => any,
    ): Promise<PageType> => {
      const response = await makeRequest<PageType>(
        `${PAGETYPESV5}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pageType),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createPageType: performAction, loading, error }
}

export default useCreatePageType
