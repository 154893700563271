import { TAGSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Tag } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteTag = () => {
  const { error, loading, performAction } = useHandleRequest(
    async (handleUnauthenticated, tagId: number, onSuccess?: () => any): Promise<Tag> => {
      const response = await makeRequest<Tag>(
        `${TAGSV4}/${tagId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteTag: performAction, loading, error }
}

export default useDeleteTag
