import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { PageSectionType } from "../../../types"
import { Button, Switch } from "@mui/material"
import { formatDate } from "../../../utils/formatters"
import PageSectionTypeDialog from "./PageSectionTypeDialog"
import useUpdatePageSectionType from "../hooks/useUpdatePageSectionType"
import { Link } from "react-router-dom"

type PageSectionTypeRowProps = {
  pageSectionType: PageSectionType
  canEdit: boolean
  getPageSectionTypes: () => any
}

const PageSectionTypeRow: FunctionComponent<PageSectionTypeRowProps> = ({
  pageSectionType,
  canEdit,
  getPageSectionTypes,
}) => {
  const [open, setOpen] = useState(false)
  const [fullBody, setFullBody] = useState(false)

  const { updatePageSectionType } = useUpdatePageSectionType()

  const body = JSON.stringify(pageSectionType.fields)
  let shortBody = Object.keys(pageSectionType.fields).join(", ")

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getPageSectionTypes()
  }

  return (
    <BasicTableRow key={pageSectionType.id}>
      <BasicTableCell component="td" scope="row">
        {pageSectionType.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {pageSectionType.name}
      </BasicTableCell>

      <BasicTableCell style={{ overflow: "auto", maxWidth: 400 }}>
        <Button onClick={(x) => setFullBody(!fullBody)}>...</Button>
        <pre style={{ whiteSpace: "pre-wrap" }}>{fullBody ? body : shortBody}</pre>
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {formatDate(new Date(pageSectionType.created_at))}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <Link to={`/block-templates?section_id=${pageSectionType.id}`}>
          {pageSectionType.block_template_count}
        </Link>
      </BasicTableCell>
      {canEdit ? (
        <>
          <BasicTableCell>
            <Switch
              checked={!pageSectionType.deleted_at}
              onChange={(evt) =>
                updatePageSectionType(
                  pageSectionType.id,
                  {
                    enabled: evt.target.checked,
                  },
                  getPageSectionTypes,
                )
              }
            />
          </BasicTableCell>
          <BasicTableCell component="td" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <PageSectionTypeDialog
          isOpen={open}
          close={handleClose}
          pageSectionTypeUpdate={pageSectionType}
        />
      ) : null}
    </BasicTableRow>
  )
}

export default PageSectionTypeRow
