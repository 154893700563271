import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SiteProductOverview from "./components/SiteProductOverview"
import SiteProductVariations from "./components/SiteProductVariations"
import useGetSiteProductById from "./hooks/useGetSiteProductById"

const SiteProductDetailsScreen: FunctionComponent = () => {
  const params: any = useParams()
  const productId = Number(params.productId)

  const { product, getProductById, loading } = useGetSiteProductById()

  useEffect(() => {
    getProductById(productId)
    // eslint-disable-next-line
  }, [productId])

  if (loading) {
    return (
      <div
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (!product) {
    return null
  }

  return (
    <ScreenContainer title={`${product.name}`}>
      <SiteProductOverview product={product} />
      <SiteProductVariations variations={product.variations} />
    </ScreenContainer>
  )
}

export default SiteProductDetailsScreen
