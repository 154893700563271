/* eslint-disable no-template-curly-in-string */
interface AppConfig {
  environmentName: string
  googleClientId: string
  stripeUrl: string
  productboardFeatureUrl: string
  slackBugChannelUrl: string
  assetsBaseUrl: string
}

const localConfig: AppConfig = {
  environmentName: "local",
  googleClientId: "495207993402-mecisg08l0vnmalmppfkqm27cq3lop39.apps.googleusercontent.com",
  stripeUrl: "https://dashboard.stripe.com/test",
  productboardFeatureUrl:
    "https://onuniverse.productboard.com/feature-board/1784473-top-bugs/features",
  slackBugChannelUrl: "https://onuniverse.slack.com/archives/C03PX5LG7L6",
  assetsBaseUrl: "https://assets.onuniverse-staging.com",
}

const remoteConfig: AppConfig = {
  environmentName: "${ENVIRONMENT_NAME}",
  googleClientId: "${GOOGLE_CLIENT_ID}",
  stripeUrl: "${STRIPE_DASHBOARD_URL}",
  productboardFeatureUrl: "${PRODUCTBOARD_FEATURE_URL}",
  slackBugChannelUrl: "${SLACK_BUG_CHANNEL_URL}",
  assetsBaseUrl: "${ASSETS_BASE_URL}",
}

const config: AppConfig = process.env.REACT_APP_LOCAL === "true" ? localConfig : remoteConfig

export default config
