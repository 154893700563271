import { AISUGGESTIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AISuggestion, UpdateAISuggestion } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateAISuggestion = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      aiSuggestionId: number,
      aiSuggestion: UpdateAISuggestion,
      onSuccess?: () => any,
    ): Promise<AISuggestion> => {
      const res = await makeRequest<AISuggestion>(
        `${AISUGGESTIONS}/${aiSuggestionId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(aiSuggestion),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { updateAISuggestion: performAction, loading, error }
}

export default useUpdateAISuggestion
