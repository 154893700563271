import { useCallback, useEffect, useState } from "react"
import { USERS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { User } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useSearchUsers = (defaultRowsPerPage = 25) => {
  const [searchValue, setSearchValue] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, searchValue: string): Promise<PagedResponse<User>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}&search=${searchValue}`
      return makeRequest<PagedResponse<User>>(
        `${USERS}?${queryParams}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (searchValue) {
      performAction(searchValue)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const searchUsers = useCallback(
    async (searchValue: string) => {
      setSearchValue(searchValue)
      return await performAction(searchValue)
    },
    [performAction],
  )

  return {
    loading,
    error,
    searchUsers,
    users: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useSearchUsers
