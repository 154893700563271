import { styled, TableCell } from "@mui/material"

const BasicTableCell = styled(TableCell)(
  ({ theme }) =>
    `
 &.MuiTableCell-head {
    background-color: ${theme.palette.common.black};
    color: ${theme.palette.common.white};
  }
 &.MuiTableCell-body{
    font-size: 14
  }
`,
)

export default BasicTableCell
