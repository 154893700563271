import { Box, Button } from "@mui/material"
import { FunctionComponent, useState } from "react"
import toast from "react-hot-toast"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreateNote from "../hooks/useCreateNote"
import MDEditor from "@uiw/react-md-editor"

interface Props {
  type: string
  typeId: number
  isOpen: boolean
  close: () => void
}

const CreateNoteDialog: FunctionComponent<Props> = ({ type, typeId, isOpen, close }) => {
  const { createNote, loading, error } = useCreateNote()
  const [note, setNote] = useState("")

  const handleClose = () => {
    setNote("")
    close()
  }

  const handleNoteChange = (value: string | undefined) => {
    setNote(value || "")
  }

  const handleCreateUserNoteSuccess = async () => {
    toast.success(`Successfully created ${type} note`)
    handleClose()
  }

  const handleCreateNote = async () => {
    await createNote(type, typeId, note, handleCreateUserNoteSuccess)
  }

  return (
    <Dialog
      title={`Create New Note`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      maxWidth="md"
      content={
        <Box>
          {error && <ErrorComponent error={error} />}
          <MDEditor value={note} onChange={handleNoteChange} preview="edit" />
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleCreateNote} color="primary" variant="outlined" disabled={loading}>
            Create Note
          </Button>
        </>
      }
    />
  )
}

export default CreateNoteDialog
