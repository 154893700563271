import { NOTES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Note } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteNote = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, noteId: number, onSuccess?: () => any): Promise<Note> => {
      const response = await makeRequest<Note>(
        `${NOTES}/${noteId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteNote: performAction, loading, error }
}

export default useDeleteNote
