import { Button, DialogContentText } from "@mui/material"
import { FunctionComponent } from "react"
import Dialog from "../../../components/shared/Dialog"
import useDeleteNote from "../hooks/useDeleteNote"
import ErrorComponent from "../../../components/shared/Error"
import { toast } from "react-hot-toast"
import { Note } from "../../../types"
import ReactMarkdown from "react-markdown"

type UpdateUserIdentityEmailDialogProps = {
  note: Note
  close: () => any
  isOpen: boolean
}

const DeleteNoteConfirmationDialog: FunctionComponent<UpdateUserIdentityEmailDialogProps> = ({
  note,
  close,
  isOpen,
}) => {
  const { deleteNote, loading, error } = useDeleteNote()

  const handleDeleteNote = async () => {
    await deleteNote(note.id, handleDeleteNoteSuccess)
  }

  const handleDeleteNoteSuccess = () => {
    toast.success("Successfully deleted note")
    close()
  }

  return (
    <Dialog
      title="Delete note?"
      isOpen={isOpen}
      handleClose={close}
      error={error}
      content={
        <>
          {error ? <ErrorComponent error={error} /> : null}
          <DialogContentText>
            Are you sure you would like to delete the following note from {note.type} ID{" "}
            {note.type_id}?
          </DialogContentText>
          <blockquote>
            <ReactMarkdown children={note.note} linkTarget={"_blank"} />
          </blockquote>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="info" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeleteNote} color="error" variant="contained" disabled={loading}>
            Delete Note
          </Button>
        </>
      }
    />
  )
}

export default DeleteNoteConfirmationDialog
