import { TEKTON_PIPELINESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TektonPipeline } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPipelines = () => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated): Promise<TektonPipeline[]> => {
      return makeRequest<TektonPipeline[]>(TEKTON_PIPELINESV4, {}, { handleUnauthenticated })
    },
  )

  return {
    loading,
    error,
    performAction,
    data: data || [],
  }
}

export default useGetPipelines
