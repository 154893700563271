import { COLLECTIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Collection } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateCollection = () => {
  const { error, loading, performAction } = useHandleRequest(
    async (handleUnauthenticated, name: string, onSuccess?: () => any): Promise<Collection> => {
      const response = await makeRequest<Collection>(
        `${COLLECTIONS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createCollection: performAction, loading, error }
}

export default useCreateCollection
