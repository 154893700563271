import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { SelectedFilter, Template } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSiteTemplates = () => {
  const {
    loading,
    error,
    data,
    performAction: getSiteTemplates,
  } = useHandleRequest((handleUnauthenticated, query: SelectedFilter): Promise<Template[]> => {
    const queryString = Object.entries(query)
      .map(([key, value]) => {
        if (value === "") return ""
        if (Array.isArray(value)) {
          return `${key}=${value.map((v) => `${encodeURIComponent(v)}`).join(",")}`
        }
        return `${key}=${encodeURIComponent(value)}`
      })
      .join("&")

    return makeRequest<Template[]>(
      `${SITESV4}/templates?sort=alpha&${queryString}`,
      {},
      { handleUnauthenticated },
    )
  })

  return {
    loading,
    error,
    getSiteTemplates,
    templates: data ?? [],
  }
}

export default useGetSiteTemplates
