import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TemplateContent } from "../../../types"
import { TEMPLATESV4 } from "../../../constants/urlDefs"

const useGetTemplateContentById = () => {
  const { data, error, loading, performAction } = useHandleRequest(
    (handleUnauthenticated, id: number): Promise<TemplateContent> => {
      return makeRequest<TemplateContent>(
        `${TEMPLATESV4}/${id}/content`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { getTemplateContent: performAction, loading, error, templateContent: data }
}

export default useGetTemplateContentById
