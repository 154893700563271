import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import BlockTemplatesTable from "./components/BlockTemplatesTable"
import useGetBlockTemplates from "./hooks/useGetBlockTemplates"
import {
  Button,
  Select,
  MenuItem,
  Switch,
  FormGroup,
  FormControlLabel,
  InputLabel,
} from "@mui/material"
import { useAuth } from "../../services/auth-service"
import BlockTemplateDialog from "./components/BlockTemplateDialog"
import useGetPageSectionTypes from "../PageSectionTypes/hooks/useGetPageSectionTypes"
import { useSearchParams } from "react-router-dom"

const BlockTemplatesScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.blockTemplates.create)
  const canEdit = hasScope(scopes.blockTemplate.update)

  const { loading, error, getBlockTemplates, blockTemplates, paginationData } =
    useGetBlockTemplates()
  const { getPageSectionTypes, pageSectionTypes } = useGetPageSectionTypes(1000)

  const [templateGroups, setTemplateGroups] = useState<string[]>([])

  const templateGroup = searchParams.get("template_group") ?? ""
  const sectionId = searchParams.get("section_id") ?? ""
  const rowsPerPage = parseInt(searchParams.get("rows_per_page") || "25", 10)
  const page = parseInt(searchParams.get("page") || "0", 10)
  const enabled = !searchParams.get("enabled") || searchParams.get("enabled") === "true"

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)

    getBlockTemplates(sectionId, enabled, templateGroup, page, rowsPerPage)
  }

  useEffect(() => {
    getBlockTemplates(sectionId, enabled, templateGroup, page, rowsPerPage)
  }, [searchParams])

  useEffect(() => {
    const t = blockTemplates.reduce(
      (acc: any, curr: any) => {
        acc[curr.template_group] = true
        return acc
      },
      { ...Object.fromEntries(templateGroups.map((x) => [x, true])) },
    )

    setTemplateGroups(Object.keys(t))
  }, [blockTemplates])

  useEffect(() => {
    getPageSectionTypes(enabled)
  }, [enabled])

  const addToSearch = (field: string, value: any) => {
    searchParams.set(field, value.toString())
    setSearchParams(searchParams)
  }

  return (
    <ScreenContainer
      title="Section Variants - Block Templates"
      requiresScope={scopes.blockTemplates.read}
    >
      <>
        <br />
        {canCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              onClick={handleClickOpen}
            >
              Create Block Template
            </Button>
            <br />
          </>
        )}

        <FormGroup>
          <InputLabel>Filter</InputLabel>

          <Select
            value={sectionId}
            onChange={(x) => addToSearch("section_id", x.target.value)}
            label="Filter"
            fullWidth={false}
            defaultValue=""
          >
            <MenuItem value={""} key="all">
              All
            </MenuItem>
            {pageSectionTypes
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((pageSectionType) => (
                <MenuItem
                  key={pageSectionType.id}
                  value={pageSectionType.id}
                  style={{ color: pageSectionType.deleted_at ? "gray" : undefined }}
                >
                  {pageSectionType.name}
                </MenuItem>
              ))}
          </Select>

          <InputLabel>Template Group</InputLabel>

          <Select
            label="Template Group"
            fullWidth={false}
            defaultValue=""
            value={templateGroup}
            onChange={(x) => addToSearch("template_group", x.target.value)}
          >
            <MenuItem value={""} key="all">
              All
            </MenuItem>
            {templateGroups.map((templateGroup: string) => (
              <MenuItem key={templateGroup} value={templateGroup}>
                {templateGroup}
              </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={(evnt, checked) => addToSearch("enabled", checked)}
              />
            }
            label="Enabled"
          />
        </FormGroup>
        {error ? <ErrorComponent error={error} /> : ""}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <BlockTemplatesTable
              blockTemplates={blockTemplates}
              paginationData={{
                ...paginationData,
                rowsPerPage,
                page,
                onPageChange: (event: any, page: number) => addToSearch("page", page),
                onRowsPerPageChange: (event: any) =>
                  addToSearch("rows_per_page", event.target.value),
              }}
              canEdit={canEdit}
              pageSectionTypes={pageSectionTypes}
              getBlockTemplates={() =>
                getBlockTemplates(sectionId, enabled, templateGroup, page, rowsPerPage)
              }
            />
            {open ? (
              <BlockTemplateDialog
                isOpen={open}
                close={handleClose}
                pageSectionTypes={pageSectionTypes}
              />
            ) : null}
          </div>
        )}
      </>
    </ScreenContainer>
  )
}

export default BlockTemplatesScreen
