import { EMPLOYEES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Employee, PostEmployee } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateEmployee = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      employee: PostEmployee,
      onSuccess?: () => any,
    ): Promise<Employee> => {
      const createdAt = new Date()
      const response = await makeRequest<Employee>(
        `${EMPLOYEES}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...employee, created_at: createdAt }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createEmployee: performAction, loading, error }
}

export default useCreateEmployee
