import { Box, ImageList, ImageListItem, ImageListItemBar, TablePagination } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SearchInput from "../../components/shared/SearchInput"
import { stripDomainJunk } from "../../utils/formatters"
import SiteLink from "./components/SiteLink"
import useSearchSites from "./hooks/useSearchSites"
import { useSearchParams } from "react-router-dom"

const SiteSearchScreen: FunctionComponent = () => {
  let [searchParams, setSearchParams] = useSearchParams()

  const { searchSites: searchSitesFunc, loading, sites, paginationData } = useSearchSites()

  const handleSearchFromRouter = useCallback(async () => {
    const term = searchParams.get("term")

    if (term) {
      await searchSitesFunc(term, true)
    }
  }, [searchParams, searchSitesFunc])

  useEffect(() => {
    handleSearchFromRouter()
    // eslint-disable-next-line
  }, [])

  const handleSearchSites = useCallback(
    async (term: string) => {
      const siteSearchQuery = stripDomainJunk(term)
      await searchSitesFunc(siteSearchQuery, true)
      setSearchParams({ term: siteSearchQuery as string })
    },
    [searchSitesFunc, setSearchParams],
  )

  const showTable = !loading && sites.length > 0
  return (
    <ScreenContainer title="Site Search">
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <SearchInput
          handleSearch={handleSearchSites}
          title={`Search by custom domain or subdomain`}
          label={`Site Search`}
          defaultValue={searchParams.get("term")}
          disabledWhenEmpty
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {showTable && (
        <div>
          <TablePagination
            component="div"
            count={paginationData.count}
            page={paginationData.page}
            onPageChange={paginationData.onPageChange}
            rowsPerPage={paginationData.rowsPerPage}
            onRowsPerPageChange={paginationData.onRowsPerPageChange}
          />
          <ImageList rowHeight={240} sx={{ width: "100%", height: "100%" }} cols={6}>
            {sites.map((site) => (
              <ImageListItem key={site.id}>
                <SiteLink siteId={site.id}>
                  <img
                    height={240}
                    src={site.thumbnails.small}
                    alt={site.title || site.domain || site.subdomain || undefined}
                  />
                </SiteLink>
                <ImageListItemBar
                  title={site.title}
                  subtitle={site.urls[0].replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0]}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      )}
    </ScreenContainer>
  )
}

export default SiteSearchScreen
