import { Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useCallback, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import { EmailForward, UpdateEmailForwardRequest } from "../../../types"
import enforceError from "../../../utils/enforce-error"
import useDeleteEmailForward from "../hooks/useDeleteEmailForward"

type EditEmailForwardDialogProps = {
  emailForward: EmailForward
  domain: string
  handleEdit: (emailForward: UpdateEmailForwardRequest, callback: () => any) => any
  close: () => any
  isOpen: boolean
  loading: boolean
  hasError: boolean
}

const EditEmailForwardDialog: FunctionComponent<EditEmailForwardDialogProps> = ({
  emailForward,
  domain,
  handleEdit,
  close,
  isOpen,
  loading,
  hasError,
}) => {
  const [emailAddress, setEmailAddress] = useState<string | null>(emailForward.email_address)
  const [forwardAddress, setForwardAddress] = useState<string | null>(emailForward.forward_address)
  const [error, setError] = useState<Error>()

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const {
    deleteEmailForward,
    loading: deleteEmailForwardLoading,
    error: deleteEmailForwardError,
  } = useDeleteEmailForward()
  const handleDeleteForward = useCallback(async () => {
    const formattedForward = {
      id: emailForward.id!,
      domain: domain!,
      user_id: emailForward.user_id!,
    }
    await deleteEmailForward(formattedForward, handleClose)
    // eslint-disable-next-line
  }, [deleteEmailForward, emailForward, domain])

  const formik = useFormik({
    initialValues: {
      emailAddress: emailAddress,
      forwardAddress: forwardAddress,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      try {
        // Format into an updated EmailForward
        const formattedForward = {
          id: emailForward.id!,
          domain: domain!,
          email_address: emailAddress!,
          forward_address: forwardAddress!,
          user_id: emailForward.user_id!,
        }
        await handleEdit(formattedForward, handleClose)
      } catch (err) {
        setError(enforceError(err))
      }
    },
  })

  return (
    <Dialog
      title="Edit Email Forward"
      isOpen={isOpen}
      handleClose={close}
      error={
        hasError || error || deleteEmailForwardError
          ? "There was a problem editing the email forward"
          : null
      }
      loading={loading}
      content={
        <>
          <TextField
            margin="dense"
            id="emailAddress"
            label="Email Address"
            fullWidth
            required
            variant="standard"
            defaultValue={emailForward.email_address}
            onChange={(x) => setEmailAddress(x.target.value)}
          />
          <TextField
            margin="dense"
            id="forwardAddress"
            label="Forward To Address"
            fullWidth
            required
            variant="standard"
            defaultValue={emailForward.forward_address}
            onChange={(x) => setForwardAddress(x.target.value)}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="warning" variant="outlined">
            Cancel
          </Button>
          {!loading || !deleteEmailForwardLoading ? (
            <Button onClick={handleDeleteForward} color="error" variant="contained">
              Delete Forward
            </Button>
          ) : null}
          {!loading || !deleteEmailForwardLoading ? (
            <Button onClick={formik.submitForm} color="primary" variant="outlined">
              Update Forward
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default EditEmailForwardDialog
