import { Box, Grid } from "@mui/material"
import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import useGetThemeById from "./hooks/useGetThemeById"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ErrorComponent from "../../components/shared/Error"
import { Headline, Subhead } from "../../components/shared/Typography"
import CopyIcon from "../../components/shared/CopyIcon"
import { Row } from "../Sites/components/SiteOverview"
import { StyledText } from "../Sites/components/SiteLayoutShared"
import UserLink from "../Users/components/UserLink"
import config from "../../config"

interface Params {
  themeId?: string
}

const ThemeDetailsScreen: FunctionComponent = () => {
  const { themeId }: Params = useParams()

  const { getThemeById, theme, loading, error } = useGetThemeById()

  const url = config.environmentName === "production" ? "univer.se" : "onuniverse-staging.com"

  useEffect(() => {
    if (themeId) {
      getThemeById(themeId)
    }

    //eslint-disable-next-line
  }, [themeId])

  return (
    <ScreenContainer title={`Theme Viewer`}>
      <Box>
        {loading ? <LoadingSpinner /> : null}
        {error ? <ErrorComponent error={error} /> : null}
      </Box>
      {theme ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Row>
                <Subhead>ID: </Subhead>
                <StyledText>{theme.id}</StyledText>
              </Row>
              <Row>
                <Subhead>Name: </Subhead>
                <StyledText>{theme.name}</StyledText>
              </Row>
              <Row>
                <Subhead>Is Public: </Subhead>
                <StyledText>{String(theme.is_public)}</StyledText>
              </Row>
              <Row>
                <Subhead>Is Universe: </Subhead>
                <StyledText>{String(theme.is_public)}</StyledText>
              </Row>
              <Row>
                <Subhead>User ID: </Subhead>
                <UserLink userId={theme.user_id}>{theme.user_id}</UserLink>
              </Row>
              <iframe
                src={`https://designsystem.${url}/vibes?isUniverse=true&theme_id=${themeId}`}
                height={600}
                frameBorder="0"
                allowFullScreen
                style={{ alignContent: "center", marginTop: 10 }}
                title="Theme Viewer"
              ></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                overflow: "auto",
              }}
            >
              <Headline>
                Theme Attributes
                <CopyIcon
                  content={
                    theme && theme.attributes ? JSON.stringify(theme.attributes, null, 2) : null
                  }
                />
              </Headline>
              <pre style={{ padding: "15px", backgroundColor: "#f5f5f5", borderRadius: "4px" }}>
                {theme && theme.attributes ? JSON.stringify(theme.attributes, null, 2) : null}
              </pre>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </ScreenContainer>
  )
}

export default ThemeDetailsScreen
