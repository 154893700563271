import { USERGROUPS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UserGroup } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetUserGroups = () => {
  const { data, loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, onSuccess?: Function): Promise<UserGroup[]> => {
    const response = await makeRequest<UserGroup[]>(USERGROUPS, {}, { handleUnauthenticated })

    const sortedResults = response.sort((a, b) => (a.name > b.name ? 1 : -1))

    if (onSuccess) {
      await onSuccess(sortedResults)
    }

    return sortedResults
  })

  return {
    getUserGroups: performAction,
    loading,
    error,
    userGroups: data || [],
  }
}

export default useGetUserGroups
