export default function RelativeDate({ milliseconds }: { milliseconds: number }) {
  return <p>{relativeDate(milliseconds)}</p>
}

function relativeDate(date: number | string) {
  const delta: number = Math.round((Date.now() - new Date(date).getTime()) / 1000)

  const minute = 60
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const year = month * 12

  if (delta < 30) {
    return "just now"
  } else if (delta < minute) {
    return delta + " seconds ago"
  } else if (delta < 2 * minute) {
    return "a minute ago"
  } else if (delta < hour) {
    return Math.floor(delta / minute) + " minutes ago"
  } else if (Math.floor(delta / hour) === 1) {
    return "1 hour ago"
  } else if (delta < day) {
    return Math.floor(delta / hour) + " hours ago"
  } else if (delta < day * 2) {
    return "yesterday"
  } else if (delta < month) {
    return Math.floor(delta / day) + " days ago"
  } else if (Math.floor(delta / month) === 1) {
    return "1 month ago"
  } else if (delta < year) {
    return Math.floor(delta / month) + " months ago"
  } else if (Math.floor(delta / year) === 1) {
    return "1 year ago"
  } else {
    return Math.floor(delta / year) + " years ago"
  }
}
