import { MERCHANTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Shipment } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetShipmentById = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, merchantId: number, shipmentId: number): Promise<Shipment> => {
    return makeRequest<Shipment>(`${MERCHANTSV3}/${merchantId}/shipments/${shipmentId}`, {}, { handleUnauthenticated })
  })

  return { loading, error, getShipmentById: performAction, shipment: data }
}

export default useGetShipmentById
