import { Button, DialogContentText, MenuItem, Select } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import  { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import useAddSiteUser from "../hooks/useAddSiteUser"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"
import * as Yup from "yup"

type AddSiteUserDialogProps = {
  siteId: number
  close: () => any
  isOpen: boolean
}

const AddSiteUserDialog: FunctionComponent<AddSiteUserDialogProps> = ({
  siteId,
  close,
  isOpen,
}) => {
  const [error, setError] = useState<Error>()

  const { addSiteUser, loading, error: requestError } = useAddSiteUser()

  const validationSchema = Yup.object().shape({
    role: Yup.string().required(),
    userOption: Yup.object().required(),
  })

  const formik = useFormik({
    initialValues: {
      role: "member",
      userOption: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      try {
        await addSiteUser(siteId, values.userOption.user?.id, values.role, close)
      } catch (err) {
        setError(enforceError(err))
      }
    },
  })

  return (
    <Dialog
      title="Add Site User"
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      content={
        <>
          {error || requestError ? <br /> : null}
          <DialogContentText>
            To add a site user, search for the user below and select a permission level.
          </DialogContentText>
          <FormikProvider value={formik}>
            <UserSearchAutocomplete />
          </FormikProvider>
          <Select
            placeholder="Select Role"
            fullWidth
            name="role"
            variant="outlined"
            id="role"
            onChange={formik.handleChange}
            value={formik.values.role}
            error={!!formik.errors.role}
            sx={{ mt: "9px", mr: "4px" }}
          >
            <MenuItem key={"owner"} value={"owner"}>
              Owner
            </MenuItem>
            <MenuItem key={"member"} value={"member"} selected>
              Member
            </MenuItem>
          </Select>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={formik.submitForm} color="primary" variant="outlined">
              Add Site User
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default AddSiteUserDialog
