import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Archetype, Category } from "../../../types"
import { CATEGORIESV5 } from "../../../constants/urlDefs"

const useGetArchetypesForCategory = () => {
  const { data, error, loading, performAction } = useHandleRequest(
    (handleUnauthenticated, category: Category) => {
      return makeRequest<Archetype[]>(
        `${CATEGORIESV5}/${category.id}/archetypes`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getArchetypesForCategory: performAction, archetypes: data || [] }
}

export default useGetArchetypesForCategory
