import { FunctionComponent } from "react"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { Navigate, Route, Routes } from "react-router-dom"

import AccountingScreen from "../../screens/Accounting/AccountingScreen"
import AIConverasationScreen from "../../screens/AIConversations/AIConversations"
import AIConversationDetail from "../../screens/AIConversations/AIConversationDetail"
import AIImageGenerationStyleScreen from "../../screens/AIImageGenerationStyles/AIImageGenerationStylesScreen"
import AISuggestionsScreen from "../../screens/AISuggestions/AISuggestionsScreen"
import ArchetypesScreen from "../../screens/Archetypes/ArchetypesScreen"
import BillingProductsScreen from "../../screens/Products/ProductsScreen"
import BlockedDomainPhrasesScreen from "../../screens/BlockedDomainPhrases/BlockedDomainPhrasesScreen"
import BlocksScreen from "../../screens/Blocks/BlockScreen"
import BlockTemplatesScreen from "../../screens/BlockTemplates/BlockTemplateScreen"
import CategoriesScreen from "../../screens/Categories/CategoriesScreen"
import CodeSnippetsScreen from "../../screens/CodeSnippets/CodeSnippetsScreen"
import CouponsScreen from "../../screens/Coupons/CouponsScreen"
import DashboardScreen from "../../screens/Dashboard/DashboardScreen"
import DeadLettersScreen from "../../screens/DeadLetter/DeadLettersScreen"
import DomainDetailsScreen from "../../screens/Domains/DomainDetailsScreen"
import DomainInstructionsScreen from "../../screens/DomainInstructions/DomainInstructionsScreen"
import DomainsScreen from "../../screens/Domains/DomainsScreen"
import EmployeesScreen from "../../screens/Employees/EmployeesScreen"
import EntitlementsScreen from "../../screens/Entitlements/EntitlementsScreen"
import EventsScreen from "../../screens/Events/EventsScreen"
import ExperimentsScreen from "../../screens/Experiments/ExperimentsScreen"
import LoginScreen from "../../screens/Login/LoginScreen"
import MainLayout from "../shared/layout/MainLayout"
import MerchantDetailsScreen from "../../screens/Merchants/MerchantDetailsScreen"
import MerchantSearchScreen from "../../screens/Merchants/MerchantSearchScreen"
import NoContextScreen from "../../screens/NoContext/NoContextScreen"
import OrderDetailsScreen from "../../screens/Orders/OrderDetailsScreen"
import OrderSearchScreen from "../../screens/Orders/OrderSearchScreen"
import PageSectionTypesScreen from "../../screens/PageSectionTypes/PageSectionTypeScreen"
import PageTypesScreen from "../../screens/PageTypes/PageTypesScreen"
import PlansScreen from "../../screens/Plans/PlansScreen"
import PromoDetailsScreen from "../../screens/Promos/PromoDetailScreen"
import PromosScreen from "../../screens/Promos/PromosScreen"
import ProtectedRoute from "./ProtectedRoute"
import RemoteValuesScreen from "../../screens/RemoteValues/RemoteValuesScreen"
import RequestsScreen from "../../screens/Requests/RequestsScreen"
import SandboxScreen from "../../screens/Sandbox/SandboxScreen"
import SiteDetailsScreen from "../../screens/Sites/SiteDetailsScreen"
import SiteExplorerScreen from "../../screens/Sites/SiteExplorerScreen"
import SiteGroupDetailsScreen from "../../screens/Sites/SiteGroupDetailsScreen"
import SitePageDetailsScreen from "../../screens/Sites/SitePageDetailsScreen"
import SitePagesScreen from "../../screens/Sites/SitePagesScreen"
import SitePaymentIntegrationsScreen from "../../screens/Sites/SitePaymentIntegrationsScreen"
import SiteProductDetailsScreen from "../../screens/Sites/SiteProductDetailsScreen"
import SiteProductsScreen from "../../screens/Sites/SiteProductsScreen"
import SiteSearchScreen from "../../screens/Sites/SiteSearchScreen"
import SiteSubscribersScreen from "../../screens/Sites/SiteSubscribersScreen"
import SubdomainDetailsScreen from "../../screens/Domains/SubdomainDetailsScreen"
import SubscriptionDetailsPage from "../../screens/Subscriptions/SubscriptionDetailsPage"
import TagsScreen from "../../screens/Tags/TagsScreen"
import TektonDeployments from "../../screens/Tekton/TektonDeployments"
import TektonPipeline from "../../screens/Tekton/TektonPipeline"
import TektonPipelinePipelineRun from "../../screens/Tekton/TektonPipelinePipelineRun"
import TektonPipelineRun from "../../screens/Tekton/TektonPipelineRun"
import TektonPipelines from "../../screens/Tekton/TektonPipelines"
import TemplatesScreen from "../../screens/Templates/TemplatesScreen"
import TextGenerationScreen from "../../screens/TextGeneration/TextGenerationScreen"
import ThemeDetailsScreen from "../../screens/Themes/ThemeDetailsScreen"
import ThemesScreen from "../../screens/Themes/ThemesScreen"
import UserDetailsScreen from "../../screens/Users/UserDetailsScreen"
import UserGroupDetailScreen from "../../screens/UserGroups/components/UserGroupDetail"
import UserGroupScreen from "../../screens/UserGroups/UserGroupScreen"
import UserSearchScreen from "../../screens/Users/UserSearchScreen"
import TemplateFilterScreen from "../../screens/TemplateFilters/TemplateFiltersScreen"
import D2Diagrams from "../../screens/D2/D2Diagrams"
import DomainTldPricingScreen from "../../screens/DomainTldPricings/DomainTldPricingsScreen"
import DomainPropagationScreen from "../../screens/Domains/DomainPropagationScreen"

const Router: FunctionComponent = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route path="/" element={<Navigate to="/users" replace />} />
      <Route
        path="/accounting"
        element={
          <ProtectedRoute>
            <AccountingScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <UserSearchScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users/:userId"
        element={
          <ProtectedRoute>
            <UserDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites"
        element={
          <ProtectedRoute>
            <SiteSearchScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/explore"
        element={
          <ProtectedRoute>
            <SiteExplorerScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId"
        element={
          <ProtectedRoute>
            <SiteDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/products"
        element={
          <ProtectedRoute>
            <SiteProductsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/products/:productId"
        element={
          <ProtectedRoute>
            <SiteProductDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/subscribers"
        element={
          <ProtectedRoute>
            <SiteSubscribersScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/pages"
        element={
          <ProtectedRoute>
            <SitePagesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/pages/:groupId"
        element={
          <ProtectedRoute>
            <SiteGroupDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/pages/:groupId/:pageId"
        element={
          <ProtectedRoute>
            <SitePageDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/:siteId/payment-integrations"
        element={
          <ProtectedRoute>
            <SitePaymentIntegrationsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/domains"
        element={
          <ProtectedRoute>
            <DomainsScreen />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/domains/:domainId"
        element={
          <ProtectedRoute>
            <DomainDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/domains/instructions"
        element={
          <ProtectedRoute>
            <DomainInstructionsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/domains/blocked-phrases"
        element={
          <ProtectedRoute>
            <BlockedDomainPhrasesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/domains/propagation"
        element={
          <ProtectedRoute>
            <DomainPropagationScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/domains/subdomain/:subdomainId"
        element={
          <ProtectedRoute>
            <SubdomainDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/merchants"
        element={
          <ProtectedRoute>
            <MerchantSearchScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/merchants/:merchantId"
        element={
          <ProtectedRoute>
            <MerchantDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <ProtectedRoute>
            <OrderSearchScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders/:orderId"
        element={
          <ProtectedRoute>
            <OrderDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/promos"
        element={
          <ProtectedRoute>
            <PromosScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/promos/:promoId"
        element={
          <ProtectedRoute>
            <PromoDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/archetypes"
        element={
          <ProtectedRoute>
            <ArchetypesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categories"
        element={
          <ProtectedRoute>
            <CategoriesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/page-types"
        element={
          <ProtectedRoute>
            <PageTypesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/themes"
        element={
          <ProtectedRoute>
            <ThemesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/themes/:themeId"
        element={
          <ProtectedRoute>
            <ThemeDetailsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/templates"
        element={
          <ProtectedRoute>
            <TemplatesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/template-filters"
        element={
          <ProtectedRoute>
            <TemplateFilterScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/entitlements"
        element={
          <ProtectedRoute>
            <EntitlementsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/products"
        element={
          <ProtectedRoute>
            <BillingProductsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/plans"
        element={
          <ProtectedRoute>
            <PlansScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/nocontext"
        element={
          <ProtectedRoute>
            <NoContextScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/deadletter"
        element={
          <ProtectedRoute>
            <DeadLettersScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/subscriptions/:subscriptionId"
        element={
          <ProtectedRoute>
            <SubscriptionDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/coupons"
        element={
          <ProtectedRoute>
            <CouponsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tld-pricing"
        element={
          <ProtectedRoute>
            <DomainTldPricingScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/events"
        element={
          <ProtectedRoute>
            <EventsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/requests"
        element={
          <ProtectedRoute>
            <RequestsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/employees"
        element={
          <ProtectedRoute>
            <EmployeesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/values"
        element={
          <ProtectedRoute>
            <RemoteValuesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/experiments"
        element={
          <ProtectedRoute>
            <ExperimentsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users/groups"
        element={
          <ProtectedRoute>
            <UserGroupScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users/groups/:userGroupId"
        element={
          <ProtectedRoute>
            <UserGroupDetailScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sandbox"
        element={
          <ProtectedRoute>
            <SandboxScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/code-snippets"
        element={
          <ProtectedRoute>
            <CodeSnippetsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ai/suggestions"
        element={
          <ProtectedRoute>
            <AISuggestionsScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ai/conversations"
        element={
          <ProtectedRoute>
            <AIConverasationScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ai/conversations/:conversationId"
        element={
          <ProtectedRoute>
            <AIConversationDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ai/text-generation"
        element={
          <ProtectedRoute>
            <TextGenerationScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ai/image-generation-styles"
        element={
          <ProtectedRoute>
            <AIImageGenerationStyleScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/blocks"
        element={
          <ProtectedRoute>
            <BlocksScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/page-section-types"
        element={
          <ProtectedRoute>
            <PageSectionTypesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/block-templates"
        element={
          <ProtectedRoute>
            <BlockTemplatesScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tags"
        element={
          <ProtectedRoute>
            <TagsScreen />
          </ProtectedRoute>
        }
      />
      {/** <Tekton Routes> **/}
      <Route
        path="/diagrams"
        element={
          <ProtectedRoute>
            <D2Diagrams />
          </ProtectedRoute>
        }
      />
      <Route
        path="/deployments"
        element={
          <ProtectedRoute>
            <TektonDeployments />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tekton"
        element={
          <ProtectedRoute>
            <TektonPipelines />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tekton/pipelineRuns/:pipelineRunId"
        element={
          <ProtectedRoute>
            <TektonPipelineRun />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tekton/:pipelineId"
        element={
          <ProtectedRoute>
            <TektonPipeline />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tekton/:pipelineId/run"
        element={
          <ProtectedRoute>
            <TektonPipelinePipelineRun />
          </ProtectedRoute>
        }
      />
      {/** </Tekton Routes> **/}

      <Route
        path="/docs"
        element={
          <ProtectedRoute>
            <SwaggerUI url={"/api-docs/api.yaml"} filter={true} displayRequestDuration={true} />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="/login" element={<LoginScreen />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default Router
