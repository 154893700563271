import { Button, Switch } from "@mui/material"
import { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { BillingProduct, Plan } from "../../../types"
import { CheckBox } from "@mui/icons-material"

type PlanRowProps = {
  plan: Plan
  handleOpenEditPlan: (plan: Plan) => any
  products?: BillingProduct[]
  canEdit: boolean
}

const PlanRow: FunctionComponent<PlanRowProps> = ({
  plan,
  handleOpenEditPlan,
  products,
  canEdit,
}) => {
  const editPlan = () => {
    handleOpenEditPlan(plan)
  }

  return (
    <BasicTableRow key={plan.id}>
      <BasicTableCell component="th" scope="row">
        {plan.id}
      </BasicTableCell>
      <BasicTableCell>{plan.title}</BasicTableCell>
      <BasicTableCell>{plan.description}</BasicTableCell>
      <BasicTableCell>
        {products?.find((x) => x.id === plan.product_id)?.title || plan.product_id}
      </BasicTableCell>

      <BasicTableCell>
        {plan.version !== "legacy" ? `${plan.version}.` : ""}
        {plan.group}
        {plan.variant && `.${plan.variant}`}.{plan.duration}
      </BasicTableCell>
      <BasicTableCell>${(plan.price.amount / 100.0).toFixed(2)}</BasicTableCell>
      <BasicTableCell>
        <Switch checked={plan.active} disabled />
      </BasicTableCell>

      {canEdit && (
        <BasicTableCell>
          <Button variant="outlined" color="primary" onClick={editPlan}>
            Edit
          </Button>
        </BasicTableCell>
      )}
    </BasicTableRow>
  )
}

export default PlanRow
