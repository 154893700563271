import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DomainPropagation } from "../../../types"
import { DOMAINS } from "../../../constants/urlDefs"

const useGetDomainPropagation = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (
      handleUnauthenticated,
      domainName: string,
      recordType: string,
    ): Promise<DomainPropagation[]> => {
      let queryString = `${DOMAINS}/propagation?domain=${domainName}&type=${recordType}`
      return makeRequest<DomainPropagation[]>(queryString, {}, { handleUnauthenticated })
    },
  )

  return { getDomainPropagation: performAction, loading, error, propagation: data }
}

export default useGetDomainPropagation
