import { EMAILPARSER } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

export type Email = {
  email: string
}

const useParseEmail = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, content: string): Promise<Email[]> => {
      return makeRequest<Email[]>(
        `${EMAILPARSER}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ content }),
        },
        { handleUnauthenticated },
      )
    },
  )

  return {
    parseEmailAddresses: performAction,
    loading,
    error,
  }
}

export default useParseEmail
