import { CONVERSATIONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { AIConversation } from "../../../types"

const useGetConversationById = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, conversationId: string): Promise<AIConversation> => {
      return makeRequest<AIConversation>(
        `${CONVERSATIONS}/${conversationId}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getConversationById: performAction, conversation: data }
}

export default useGetConversationById
