import { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Collection, Tag } from "../../../types"
import { Button } from "@mui/material"
import TagCollectionEditDialog from "./TagCollectionEditDialog"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

type Props = {
  tag: Tag
  collections: Collection[]
  close: () => any
}

const TagRow: FunctionComponent<Props> = ({ tag, collections, close }) => {
  const [open, setOpen] = useState(false)
  const { hasScope } = useAuth()

  const canEditTag = hasScope(scopes.tag.update)

  const handleClose = async () => {
    setOpen(false)
    await close()
  }

  return (
    <BasicTableRow key={tag.id}>
      <BasicTableCell component="th" scope="row">
        {tag.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {tag.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row" style={{ overflow: "auto", maxWidth: 400 }}>
        {tag.display_title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {tag.description}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {canEditTag ? (
          <Button variant="contained" onClick={() => setOpen(true)}>
            Edit
          </Button>
        ) : null}
        {open ? (
          <TagCollectionEditDialog
            tag={tag}
            collections={collections}
            isOpen={open}
            close={handleClose}
          />
        ) : null}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default TagRow
