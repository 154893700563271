import { FunctionComponent, useCallback, useEffect } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { DomainTldPricing } from "../../../types"
import DomainTldPricingTableRow from "./DomainTldPricingTableRow"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import useGetPlans from "../../Plans/hooks/useGetPlans"
import useGetProducts from "../../Products/hooks/useGetProducts"

interface Props {
  domainTldPricing: DomainTldPricing[]
  paginationData: PaginationData
  getDomainTldPricings: Function
}

const DomainTldPricingsTable: FunctionComponent<Props> = ({
  domainTldPricing,
  paginationData,
  getDomainTldPricings,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.domainTldPricing.update)

  const { getPlans, plans } = useGetPlans(1000)
  const { getProducts, products } = useGetProducts(1000)

  useEffect(() => {
    getPlans()
    getProducts()
  }, [])

  const columns = canEdit
    ? [
        "id",
        "Tld",
        "Active",
        "Registration Price",
        "Renewal Price",
        "Transfer Price",
        "Restore Price",
        "Plan Id",
        "Legacy Tier",
        "Last Updated",
        "Action",
      ]
    : [
        "id",
        "Tld",
        "Active",
        "Registration Price",
        "Renewal Price",
        "Transfer Price",
        "Restore Price",
        "Plan Id",
        "Legacy Tier",
        "Last Updated",
      ]

  const getRows = useCallback(() => {
    return domainTldPricing.map((domainTldPricing: DomainTldPricing) => (
      <DomainTldPricingTableRow
        key={domainTldPricing.id}
        domainTldPricing={domainTldPricing}
        getDomainTldPricings={getDomainTldPricings}
        plans={plans}
        products={products}
        getPlans={getPlans}
      />
    ))
    // eslint-disable-next-line
  }, [domainTldPricing, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default DomainTldPricingsTable
