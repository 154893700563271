import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  siteId: number
}

const DeleteSiteConfirmationDialog: FunctionComponent<Props> = ({ siteId, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Delete Site"
      errorMessage={hasError ? "There was a problem deleting the site" : null}
      affirmativeButtonText="Delete"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>{`Are you sure you want to delete site ${siteId}?`}</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default DeleteSiteConfirmationDialog
