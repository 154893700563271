import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { DomainTypeEnum } from "../../types"
import ReassignDomainDialog, { DomainReassignBasisEnum } from "./components/ReassignDomainDialog"
import SubdomainOverview from "./components/SubdomainOverview"
import useGetSubdomain from "./hooks/useGetSubdomain"
import useReassignSubdomain from "./hooks/useReassignSubdomain"

interface Params {
  subdomainId?: number
}

const SubdomainDetailsScreen: FunctionComponent = () => {
  const { subdomainId }: Params = useParams()
  const {
    isOn: reassignDialogIsOpen,
    turnOn: openReassignDialog,
    turnOff: closeReassignDialog,
  } = useToggle()

  const {
    getSubdomain,
    loading: loadingSubdomain,
    error: subdomainError,
    subdomain,
  } = useGetSubdomain()

  const {
    reassignSubdomain,
    error: errorReassigningSubdomain,
    loading: loadingReassignment,
  } = useReassignSubdomain()

  useEffect(() => {
    if (subdomainId) {
      getSubdomain(subdomainId)
    }
    // eslint-disable-next-line
  }, [subdomainId])

  const onReassignSuccess = useCallback(async () => {
    if (subdomainId) {
      await getSubdomain(subdomainId)
    }
    closeReassignDialog()
    // eslint-disable-next-line
  }, [subdomainId, closeReassignDialog])

  const handleReassigSubnDomain = useCallback(
    async (subdomainId: number, userId: number, siteId: number) => {
      await reassignSubdomain(subdomainId, userId, siteId, onReassignSuccess)
    },
    [reassignSubdomain, onReassignSuccess],
  )

  if (!subdomainId) {
    return null
  }

  const showOverview = !!subdomain && !loadingSubdomain
  return (
    <ScreenContainer title="Subdomain">
      {loadingSubdomain && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {subdomainError && <ErrorComponent error={subdomainError} />}
      <br />
      <Button
        sx={{ width: "300px" }}
        variant="outlined"
        color="primary"
        onClick={openReassignDialog}
      >
        Reassign Subdomain
      </Button>
      <br />
      <Box
        sx={{
          my: "15px",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        display="flex"
      >
        {showOverview && <SubdomainOverview subdomain={subdomain} />}
      </Box>
      {subdomain && (
        <ReassignDomainDialog
          title={`Reassign Subdomain - ${subdomain.id}`}
          domainId={subdomain.id}
          userId={subdomain?.user_id}
          close={closeReassignDialog}
          isOpen={reassignDialogIsOpen}
          handleReassign={handleReassigSubnDomain}
          hasError={!!errorReassigningSubdomain}
          domainType={DomainTypeEnum.Subdomain}
          loading={loadingReassignment}
          basis={DomainReassignBasisEnum.Domain}
        />
      )}
    </ScreenContainer>
  )
}

export default SubdomainDetailsScreen
