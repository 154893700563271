import { MERCHANTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Merchant, PayoutSchedule } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateMerchantPayoutSchedule = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      merchant: Merchant,
      manual: boolean,
      onSuccess?: () => any,
    ): Promise<PayoutSchedule> => {
      const url = `${MERCHANTSV3}/${merchant.id}/payouts/${manual ? "disable" : "enable"}`
      const response = await makeRequest<PayoutSchedule>(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateMerchantPayoutSchedule: performAction, loading, error }
}

export default useUpdateMerchantPayoutSchedule
