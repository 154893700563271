import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { PaymentIntegration } from "../../../types"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import SitePaymentIntegrationRow from "./SitePaymentIntegrationRow"

interface Props {
  paymentIntegrations: PaymentIntegration[]
}

const SitePaymentIntegrationsTable: FunctionComponent<Props> = ({ paymentIntegrations }) => {
  // Auth scopes
  const { hasScope } = useAuth()
  const canDeleteIntegration = hasScope(scopes.paymentIntegration.delete)

  const columns: string[] = canDeleteIntegration
    ? ["ID", "Type", "Merchant ID", "Merchant Email", "Currency", "Status", "Action"]
    : ["ID", "Type", "Merchant ID", "Merchant Email", "Currency", "Status"]

  const getRows = useCallback(() => {
    return paymentIntegrations.map((paymentIntegration: PaymentIntegration, index: number) => (
      <SitePaymentIntegrationRow key={index} paymentIntegration={paymentIntegration} />
    ))
  }, [paymentIntegrations])

  return <BasicTable columns={columns} rows={getRows()} />
}

export default SitePaymentIntegrationsTable
