import { Button, DialogContentText } from "@mui/material"
import { FunctionComponent } from "react"
import Dialog from "../../../components/shared/Dialog"
import { DNSimpleData, Domain } from "../../../types"
import useEnableDomainAutoRenew from "../hooks/useEnableDomainAutoRenew"
import useDisableDomainAutoRenew from "../hooks/useDisableDomainAutoRenew"
import ErrorComponent from "../../../components/shared/Error"

type ManageAutoRenewDialogProps = {
  domain: Domain
  dnsimpleData: DNSimpleData
  close: () => any
  handleSuccess: () => any
  isOpen: boolean
}

const ManageAutoRenewDialog: FunctionComponent<ManageAutoRenewDialogProps> = ({
  domain,
  dnsimpleData,
  close,
  handleSuccess,
  isOpen,
}) => {
  const { enableAutoRenew, loading: enableLoading, error: enableError } = useEnableDomainAutoRenew()
  const {
    disableAutoRenew,
    loading: disableLoading,
    error: disableError,
  } = useDisableDomainAutoRenew()

  const handleAutoRenewStatus = async () => {
    if (!!dnsimpleData.auto_renew) {
      // Auto renew is currently enabled, lets disable it
      await disableAutoRenew(domain.id, handleSuccess)
    }

    if (!!!dnsimpleData.auto_renew) {
      // Auto renew is currently disabled, lets enable it
      await enableAutoRenew(domain.id, handleSuccess)
    }
  }

  return (
    <Dialog
      title={`Manage Domain Auto-Renew Status for ${domain.url}`}
      isOpen={isOpen}
      handleClose={close}
      error={enableError || disableError}
      loading={enableLoading || disableLoading}
      content={
        <>
          {enableError ? <ErrorComponent error={enableError} /> : null}
          {disableError ? <ErrorComponent error={disableError} /> : null}
          <DialogContentText>
            This domain currently has auto-renew
            <b> {dnsimpleData.auto_renew ? "enabled" : "disabled"}</b>. Only make changes to this
            status if a domain was changed in error. Ensure the subscription properly aligns with
            the state of auto-renew on the domain.
          </DialogContentText>
          <br />
          <DialogContentText>Would you like to continue?</DialogContentText>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAutoRenewStatus}
            color="primary"
            disabled={enableLoading || disableLoading}
          >
            {dnsimpleData.auto_renew ? "Disable Auto-Renew" : "Enable Auto-Renew"}
          </Button>
        </>
      }
    />
  )
}

export default ManageAutoRenewDialog
