import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material"
import { FunctionComponent, ReactNode, useCallback } from "react"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { Entitlement, SiteEntitlement } from "../../../types"
import useInvalidateSiteEntitlements from "../hooks/useInvalidateSiteEntitlements"
import toast from "react-hot-toast"

type Props = {
  open: boolean
  handleClose: any
  entitlements: Entitlement[]
  selectedEntitlements: SiteEntitlement[]
  loadingEntitlements: boolean
  entitlementsError: boolean
  siteId: number
  refreshData: () => void
}

const ViewEntitlementsDialog: FunctionComponent<Props> = ({
  open = false,
  handleClose,
  selectedEntitlements,
  entitlements,
  loadingEntitlements,
  entitlementsError,
  siteId,
  refreshData,
}) => {
  const { invalidateSiteEntitlements, loading, error } = useInvalidateSiteEntitlements()

  const handleInvalidateSuccess = async () => {
    toast.success("Successfully sent request to invalidate entitlements")
    await refreshData()
    handleClose()
  }

  const invalidateEntitlements = async () => {
    await invalidateSiteEntitlements(siteId, handleInvalidateSuccess)
  }

  const getEntitlemenstList = useCallback(() => {
    const entitlementsList: ReactNode[] = []
    entitlements
      .sort((a, b) => {
        // First, sort by title using localeCompare for locale-aware sorting
        const titleComparison = a.title.localeCompare(b.title)
        if (titleComparison !== 0) return titleComparison

        // Titles are the same, sort by variant
        if (a.variant === null && b.variant === null) return 0
        if (a.variant === null) return -1
        if (b.variant === null) return 1

        return a.variant.localeCompare(b.variant)
      })
      .forEach((entitlement) => {
        const selected = selectedEntitlements.find(
          (x) => x.id === entitlement.title && x.variant === entitlement.variant,
        )
        let label = !!entitlement.variant
          ? `${entitlement.title} (${entitlement.variant})`
          : entitlement.title
        if (selected?.usage) {
          label += ` (${selected.usage.formatted_usage} / ${selected.usage.formatted_limit})`
        }

        entitlementsList.push(
          <Grid item xs={4} key={entitlement.id}>
            <FormControlLabel
              control={<Checkbox />}
              checked={!!selected}
              label={label}
              name={entitlement.title}
            />
          </Grid>,
        )
      })
    return entitlementsList?.length ? entitlementsList : []
  }, [entitlements, selectedEntitlements])

  return (
    <Dialog
      loading={loadingEntitlements}
      isOpen={open}
      handleClose={handleClose}
      title={`Entitlements`}
      maxWidth="md"
      content={
        <Box width="md">
          <Row height="8px" mb="4px">
            {!!entitlementsError && (
              <TextBody hasError={!!entitlementsError}>
                There was a problem getting the entitlements.
              </TextBody>
            )}
          </Row>
          <Row>
            <Grid container>{getEntitlemenstList()}</Grid>
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={invalidateEntitlements} color="primary" variant="outlined">
            Invalidate Entitlements
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Close
          </Button>
        </>
      }
    />
  )
}
export default ViewEntitlementsDialog
