import { TEKTON_PIPELINERUNSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TektonPipelineRun } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPipelineRunById = () => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated, pipelineRunId: string): Promise<TektonPipelineRun> => {
      return makeRequest<TektonPipelineRun>(
        `${TEKTON_PIPELINERUNSV4}/${pipelineRunId}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return {
    loading,
    error,
    performAction,
    data,
  }
}

export default useGetPipelineRunById
