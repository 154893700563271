import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { Experiment } from "../../types"
import EditExperimentModal from "./components/EditExperimentModal"
import ExperimentsTable from "./components/ExperimentsTable"
import useGetExperiments from "./hooks/useGetExperiments"
import useUpdateExperiment from "./hooks/useUpdateExperiment"
import useGetUserGroups from "../UserGroups/hooks/useGetUserGroups"

const ExperimentsScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.remoteConfig.experiments.create)
  const canEdit = hasScope(scopes.remoteConfig.experiment.update)

  const { experiments, getExperiments, loading, error } = useGetExperiments()
  const { userGroups, getUserGroups } = useGetUserGroups()

  const [editExperiment, setEditExperiment] = useState<Experiment | undefined | null>()
  const { isOn: isOpen, turnOn: openModal, turnOff: closeModal } = useToggle()
  const { endExperiment, startExperiment, error: updateError } = useUpdateExperiment()

  const handleEnd = useCallback(
    (experiment: Experiment) => {
      endExperiment(experiment, getExperiments)
    },
    [endExperiment, getExperiments],
  )

  const handleStart = useCallback(
    (experiment: Experiment) => {
      startExperiment(experiment, getExperiments)
    },
    [startExperiment, getExperiments],
  )

  const handleEdit = useCallback(
    (experiment: Experiment) => {
      setEditExperiment(experiment)
      openModal()
    },
    [openModal],
  )

  const handleCloseDialog = useCallback(() => {
    setEditExperiment(null)
    closeModal()
  }, [closeModal, setEditExperiment])

  useEffect(() => {
    getExperiments()
    getUserGroups()
    // eslint-disable-next-line
  }, [])

  const showTable = !loading && experiments.length > 0

  return (
    <ScreenContainer title="Experiments" requiresScope={scopes.remoteConfig.experiments.read}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {(!!error || !!updateError) && <ErrorComponent error={error || updateError} />}
      {canCreate && (
        <Button
          variant="outlined"
          color="primary"
          onClick={openModal}
          sx={{ my: "12px", width: "300px" }}
        >
          Create
        </Button>
      )}

      {showTable && (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <ExperimentsTable
            experiments={experiments}
            handleStart={handleStart}
            handleEnd={handleEnd}
            handleEdit={handleEdit}
            canEdit={canEdit}
          />
        </Box>
      )}
      <EditExperimentModal
        isOpen={isOpen}
        close={handleCloseDialog}
        onRefresh={getExperiments}
        editExperiment={editExperiment}
        userGroups={userGroups}
        canEdit={canEdit}
      />
    </ScreenContainer>
  )
}

export default ExperimentsScreen
