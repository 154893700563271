import { styled } from "@mui/material/styles"
import  { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Domain } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"
import UserLink from "../../Users/components/UserLink"
import DomainLink from "./DomainLink"

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: 5px;
`
interface DomainRowProps {
  domain: Domain
}

const DomainRow: FunctionComponent<DomainRowProps> = ({ domain }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        <DomainLink domainId={domain.id}>{domain.url}</DomainLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {domain.id && (
          <>
            {domain.id}
            <StyledCopyIcon content={domain.id} />
          </>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        Domain
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {domain?.user_id && (
          <>
            <UserLink userId={domain.user_id}>{domain.user_id}</UserLink>
            <StyledCopyIcon content={domain.user_id} />
          </>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {domain?.site_id && (
          <>
            <SiteLink siteId={domain.site_id}>{domain.site_id}</SiteLink>
            <StyledCopyIcon content={domain.site_id} />
          </>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {domain.status}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {domain.enabled ? "True" : "False"}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {domain.external ? "True" : "False"}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default DomainRow
