import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { AISuggestion } from "../../../types"
import AISuggestionTableRow from "./AISuggestionTableRow"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

interface Props {
  aiSuggestions: AISuggestion[]
  paginationData: PaginationData
  getAISuggestions: () => any
}

const AISuggestionsTable: FunctionComponent<Props> = ({
  aiSuggestions,
  paginationData,
  getAISuggestions,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.suggestion.update)

  const columns = canEdit ? ["id", "Suggestion", "Action"] : ["id", "Suggestion"]

  const getRows = useCallback(() => {
    return aiSuggestions.map((aiSuggestion: AISuggestion) => (
      <AISuggestionTableRow
        key={aiSuggestion.id}
        aiSuggestion={aiSuggestion}
        getAISuggestions={getAISuggestions}
      />
    ))
    // eslint-disable-next-line
  }, [aiSuggestions, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default AISuggestionsTable
