import { FunctionComponent } from "react"
import { Box, Button } from "@mui/material"
import { PaymentIntegration } from "../../../types"
import ErrorComponent from "../../../components/shared/Error"
import Dialog from "../../../components/shared/Dialog"
import { toast } from "react-hot-toast"
import useDeletePaymentIntegration from "../hooks/useDeletePaymentIntegration"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  paymentIntegration: PaymentIntegration
  isOpen: boolean
  close: () => any
  handleSuccess: () => any
}

const DeletePaymentIntegrationConfirmationDialog: FunctionComponent<Props> = ({
  paymentIntegration,
  isOpen,
  close,
  handleSuccess,
}) => {
  const { deletePaymentIntegration, loading, error } = useDeletePaymentIntegration()

  const deletePaymentIntegrationFunc = async () => {
    await deletePaymentIntegration(paymentIntegration.id, handleDeleteTagSuccess)
  }

  const handleDeleteTagSuccess = () => {
    toast.success("Successfully deleted payment integration")
    handleSuccess()
  }

  return (
    <Dialog
      title={`Delete payment integration ${paymentIntegration.id} for site ${paymentIntegration.site_id}`}
      isOpen={isOpen}
      handleClose={close}
      content={
        <Box>
          {error ? <ErrorComponent error={error} /> : null}
          <TextBody>
            Are you sure you would like to delete this payment integration? Doing so will stop
            existing integration blocks from working on the site.
          </TextBody>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={close} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={deletePaymentIntegrationFunc}
            color="error"
            variant="contained"
            disabled={loading}
          >
            Delete Integration
          </Button>
        </>
      }
    />
  )
}

export default DeletePaymentIntegrationConfirmationDialog
