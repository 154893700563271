import { USERGROUPS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UserGroupUser } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetUserGroupUser = () => {
  const { data, loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, groupId: number, onSuccess?: (results: UserGroupUser[]) => any): Promise<UserGroupUser[]> => {
    const response = await makeRequest<UserGroupUser[]>(`${USERGROUPS}/${groupId}/users`, {}, { handleUnauthenticated })

    const sortedResults = response.sort((a, b) => (a.email > b.email ? 1 : -1))

    if (onSuccess) {
      await onSuccess(sortedResults)
    }

    return sortedResults
  })

  return {
    getUserGroupUser: performAction,
    loading,
    error,
    users: data || [],
  }
}

export default useGetUserGroupUser
