import { Box } from "@mui/material"
import { FunctionComponent } from "react"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import { scopes } from "../../scopes"

const AccountingScreen: FunctionComponent = () => {
  return (
    <ScreenContainer title={"Universe Accounting"} requiresScope={scopes.dnsimple.reports.read}>
      <Box height="100vh" display="flex" flexDirection="column" marginTop={1}>
        <iframe
          title="dashboard"
          src="https://metrics.univer.se/public/dashboard/3723d579-c188-4409-9c68-363ba9efcc1b#bordered=false&titled=false"
          width="100%"
          height="100%"
          allowTransparency
          frameBorder={0}
        ></iframe>
      </Box>
    </ScreenContainer>
  )
}

export default AccountingScreen
