import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import toast from "react-hot-toast"
import BasicTableCell from "../../components/shared/BasicTable/BasicTableCell"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { DeadLetterEvent } from "../../types/events"
import enforceError from "../../utils/enforce-error"
import DeadLetterRow from "./components/DeadLetterRow"
import Confetti from "react-confetti"
import useGetDeadletterTopicCount from "./hooks/useGetDeadletterTopicCount"
import useGetDeadletters from "./hooks/useGetDeadletters"
import useDeleteDeadletterEvent from "./hooks/useDeleteDeadletterEvent"
import useReplayDeadletterEvent from "./hooks/useReplayDeadletterEvent"
import useReplayDeadletterEventByTopicAndQueue from "./hooks/useReplayDeadletterEventByTopicAndQueue"
import { queues } from "../../queues"

// TODO: refactor to use reusable table

const DeadLettersScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.deadletterEvent.delete)
  const canReplay = hasScope(scopes.deadletterEvent.retry)

  const [retryDisabled, setRetryDisabled] = useState<number[]>([])
  const [deletedDisabled, setDeleteDisabled] = useState<number[]>([])
  const [checked, setChecked] = useState<number[]>([])
  const [removedRows, setRemovedRows] = useState<number[]>([])

  const [topicSelection, setTopicSelection] = useState("")
  const [queueSelection, setQueueSelection] = useState("")

  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)

  const { getDeadLetterTopicCount, topics } = useGetDeadletterTopicCount()
  const { getDeadletters, deadletters, paginationData } = useGetDeadletters()
  const { deleteDeadletterEvent } = useDeleteDeadletterEvent()
  const { replayDeadletterEvent } = useReplayDeadletterEvent()
  const { replayDeadletterEventByTopicAndQueue, error: deadletterRetryError } =
    useReplayDeadletterEventByTopicAndQueue()

  const handleFormChange = (event: any) => {
    handleTopicChange(event.target.value)
  }

  const handleChecked = (
    id: DeadLetterEvent["id"],
    idx: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (checked.includes(id)) {
      setChecked(checked.filter((x) => x !== id))
    } else {
      setChecked([...checked, id])
    }
  }

  const handleTopicChange = async (topic: string) => {
    setTopicSelection(topic)
    await getDeadletters(topic, queueSelection)
  }

  const handleQueueChange = async (queue: any) => {
    setQueueSelection(queue.target.value)
    await getDeadletters(topicSelection, queue.target.value)
  }

  const massReplay = async () => {
    setRetryDisabled([...checked])

    await Promise.all(
      checked.map(async (checked, index) => {
        const dl = deadletters?.find((x) => x.id === checked)
        if (checked && dl) {
          return replayEvent(dl.id, index)
        }
        return null
      }),
    )
    setTopicSelection("")
    await getDeadletters()
    await getDeadLetterTopicCount()
    toast.success("Successfully replayed all deadletter events")
  }

  const massReplayByTopic = async () => {
    setRetryDisabled(deadletters?.filter((x) => x.topic === topicSelection).map((x) => x.id) || [])
    setDeleteDisabled(deadletters?.filter((x) => x.topic === topicSelection).map((x) => x.id) || [])

    await replayDeadletterEventByTopicAndQueue(topicSelection, queueSelection)

    if (deadletterRetryError) {
      toast.error(`Failed to replay deadletter events: ${deadletterRetryError.message}`)
      return
    }

    setTopicSelection("")
    setQueueSelection("")
    await getDeadletters()
    await getDeadLetterTopicCount()
    toast.success(
      `Successfully replayed deadletter events for topic ${topicSelection || "none"} & queue ${queueSelection || "none"}`,
    )
  }

  useEffect(() => {
    if (deadletterRetryError) {
      toast.error(`Failed to replay deadletter events: ${deadletterRetryError.message}`)
    }
  }, [deadletterRetryError])

  const listDeadLetters = React.useCallback(async () => {
    setError(null)
    setLoading(true)

    try {
      await getDeadletters()
      await getDeadLetterTopicCount()
      setRetryDisabled([])
      setDeleteDisabled([])
      setChecked([])
    } catch (error) {
      setError(enforceError(error))
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [setRetryDisabled, setDeleteDisabled, setChecked])

  const replayEvent = async (id: DeadLetterEvent["id"], idx: number) => {
    try {
      await replayDeadletterEvent(id)
      if (deadletters) {
        const remainingDeadLetters = deadletters?.filter((x) => x.id !== id)
        if (!remainingDeadLetters.length) {
          handleTopicChange("")
          await getDeadLetterTopicCount()
          return
        }
        setRemovedRows([...removedRows, id])
      }
      toast.success("Successfully replayed event")
    } catch (err) {
      setError(enforceError(err))
    }
  }

  const deleteEvent = async (id: DeadLetterEvent["id"], idx: number) => {
    setDeleteDisabled([id])
    try {
      await deleteDeadletterEvent(id)
      if (deadletters) {
        const remainingDeadLetters = deadletters?.filter((x) => x.id !== id)
        if (!remainingDeadLetters.length) {
          return handleTopicChange("")
        }
        setRemovedRows([...removedRows, id])
      }
      toast.success("Successfully deleted event")
    } catch (err) {
      setError(enforceError(err))
    }
  }

  useEffect(() => {
    listDeadLetters()
  }, [listDeadLetters])

  return (
    <ScreenContainer
      requiresScope={scopes.deadletterEvents.read}
      title="DeadLetters"
      subtitle={`${paginationData.count || "0"} total errors`}
    >
      {!loading && paginationData.count === 0 ? <Confetti /> : null}
      <div style={{ display: "flex" }}>
        <FormControl sx={{ width: "400px", ml: "0px" }}>
          <br />
          <br />
          <InputLabel>Topic</InputLabel>
          <Select
            name="topics"
            autoWidth
            onChange={handleFormChange}
            value={topicSelection}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="">
              <em>Topics</em>
            </MenuItem>
            {topics
              ?.sort((a, b) => (a.topic > b.topic ? 1 : -1))
              .map((topic) => (
                <MenuItem key={topic.topic} value={topic.topic}>
                  {topic.topic} ({topic.count})
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "400px", ml: "0px" }}>
          <br />
          <br />
          <InputLabel>Queue</InputLabel>
          <Select
            name="queues"
            autoWidth
            onChange={handleQueueChange}
            value={queueSelection}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="">
              <em>Queues</em>
            </MenuItem>
            {queues
              ?.sort((a, b) => (a > b ? 1 : -1))
              .map((queue) => (
                <MenuItem key={queue} value={queue}>
                  {queue}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <br />
      {canReplay && (
        <FormControl sx={{ my: "10px" }}>
          <Button onClick={massReplayByTopic} variant="contained" color="primary">
            Replay All
          </Button>
        </FormControl>
      )}

      {error ? <ErrorComponent error={error} /> : ""}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <TableContainer component={Paper}>
          <TablePagination
            component="div"
            count={paginationData.count}
            page={paginationData.page}
            onPageChange={paginationData.onPageChange}
            rowsPerPage={paginationData.rowsPerPage}
            onRowsPerPageChange={paginationData.onRowsPerPageChange}
          />

          <Table size="small">
            <TableHead>
              <TableRow>
                <BasicTableCell>ID</BasicTableCell>
                <BasicTableCell>Date</BasicTableCell>
                <BasicTableCell>Topic</BasicTableCell>
                <BasicTableCell>Queue</BasicTableCell>
                <BasicTableCell>Request</BasicTableCell>
                <BasicTableCell>Body</BasicTableCell>
                {(canReplay || canDelete) && <BasicTableCell>Replay</BasicTableCell>}
                {canReplay && (
                  <BasicTableCell>
                    <Button
                      onClick={massReplay}
                      style={{
                        fontWeight: "bold",
                        alignSelf: "center",
                        color: "white",
                      }}
                    >
                      Replay Checked?
                    </Button>
                  </BasicTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {deadletters?.map((deadletter, idx) => (
                <DeadLetterRow
                  key={deadletter.id}
                  deadletter={deadletter}
                  replayEvent={(x: any) => replayEvent(deadletter.id, idx)}
                  deleteEvent={(x: any) => deleteEvent(deadletter.id, idx)}
                  handleChecked={(x: any) => handleChecked(deadletter.id, idx, x)}
                  checked={checked.includes(deadletter.id)}
                  deleteDisabled={deletedDisabled.includes(deadletter.id)}
                  retryDisabled={retryDisabled.includes(deadletter.id)}
                  hidden={removedRows.includes(deadletter.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ScreenContainer>
  )
}

export default DeadLettersScreen
