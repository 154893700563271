import { useState, useCallback } from "react"

const useRetry = () => {
  const [retriesLeft, setRetriesLeft] = useState(3)
  const [error, setError] = useState<unknown | null>(null)

  const execute = useCallback(
    async (action: Function, ...args: any[]) => {
      try {
        setRetriesLeft((prev) => prev - 1)
        await action(...args)
        setError(null)
      } catch (err) {
        if (retriesLeft > 0) {
          execute(action, ...args)
        } else {
          setError(err as Error)
        }
      }
    },
    [retriesLeft],
  )

  return {
    execute,
    error,
    retriesLeft,
  }
}

export default useRetry
