import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { DBBlock } from "../../../types"
import { Button } from "@mui/material"
import { formatDate } from "../../../utils/formatters"
import BlockDialog from "./BlockDialog"

type BlockRowProps = {
  block: DBBlock
  canEdit: boolean
}

const BlockRow: FunctionComponent<BlockRowProps> = ({ block, canEdit }) => {
  const [open, setOpen] = useState(false)
  const [fullBody, setFullBody] = useState(false)

  const body = JSON.stringify(block.fields)
  let shortBody = block.fields.map((x) => x.key).join(", ")

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BasicTableRow key={block.id}>
      <BasicTableCell component="th" scope="row">
        {block.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {block.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {block.type}
      </BasicTableCell>

      <BasicTableCell style={{ overflow: "auto", maxWidth: 400 }}>
        <Button onClick={(x) => setFullBody(!fullBody)}>...</Button>
        <pre style={{ whiteSpace: "pre-wrap" }}>{fullBody ? body : shortBody}</pre>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(new Date(block.created_at))}
      </BasicTableCell>
      {canEdit ? (
        <BasicTableCell component="th" scope="row">
          <Button onClick={handleClickOpen}>Edit</Button>
        </BasicTableCell>
      ) : null}
      {open ? <BlockDialog isOpen={open} close={handleClose} blockUpdate={block} /> : null}
    </BasicTableRow>
  )
}

export default BlockRow
