import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { Coupon } from "../../types"
import CouponsTable from "./components/CouponsTable"
import CreateCouponDialog from "./components/CreateCouponDialog"
import useCreateCoupon from "./hooks/useCreateCoupon"
import useGetCoupons from "./hooks/useGetCoupons"
import toast from "react-hot-toast"

const CouponsScreen: FunctionComponent = () => {
  const { getCoupons, loading, error, coupons, paginationData } = useGetCoupons()
  const { isOn, turnOn: openCouponCreation, turnOff: closeCouponCreation } = useToggle()
  const { createCoupon, loading: creatingCoupon, error: creationError } = useCreateCoupon()
  const { hasScope } = useAuth()

  useEffect(() => {
    getCoupons()
    // eslint-disable-next-line
  }, [])

  const handleCreateCoupon = useCallback(
    async (item: Coupon, callback: () => any) => {
      const onSuccess = async () => {
        await getCoupons()
        callback()
        toast.success("Successfully created coupon")
      }
      await createCoupon(item, onSuccess)
    },
    [createCoupon, getCoupons],
  )

  const showTable = !loading && coupons.length > 0
  const canCreateCoupon = hasScope(scopes.billing.coupons.create)
  return (
    <ScreenContainer title="Coupons" requiresScope={scopes.billing.coupons.read}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      {canCreateCoupon && (
        <Button
          variant="outlined"
          color="primary"
          onClick={openCouponCreation}
          sx={{ my: "12px", width: "300px" }}
        >
          Create New Coupon
        </Button>
      )}

      {showTable && (
        <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
          <CouponsTable coupons={coupons} paginationData={paginationData} />
        </Box>
      )}
      <CreateCouponDialog
        isOpen={isOn}
        close={closeCouponCreation}
        handleCreateCoupon={handleCreateCoupon}
        hasError={!!creationError}
        creatingCoupon={creatingCoupon}
      />
    </ScreenContainer>
  )
}

export default CouponsScreen
