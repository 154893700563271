import {
  Menu as MenuIcon,
  ExpandMore as ExpandMoreIcon,
  Support as SupportIcon,
  Apps as AppIcon,
  CreditCard as BillingIcon,
  CalendarMonth as EventsIcon,
  Block as DeploymentIcon,
  SettingsRemote as RemoteConfigIcon,
  FeaturedPlayList as CategoriesIcon,
  AutoAwesome as AIIcon,
} from "@mui/icons-material"
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemIcon,
  TextField,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { useCallback, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import config from "../../../config"
import useBreakpoint from "../../../hooks/useBreakpoint"
import useToggle from "../../../hooks/useToggle"
import { scopes } from "../../../scopes"
import { useAuth } from "../../../services/auth-service"
import { capitalizeFirstLetter } from "../../../utils/formatters"
import { Title2 } from "../../shared/Typography"
import Logo from "../../svg/Logo.svg"
import Chad from "../../images/chad.png"

interface LinkItem {
  path: string
  label?: string
  hasScope?: string
  activeResolver?: () => boolean
}

const Title = styled(Title2)`
  margin-left: 15px;
  color: white;
`

const DRAWER_WIDTH = 240

const MainLayout = () => {
  const {
    isOn: menuIsShowing,
    turnOn: showMenu,
    turnOff: hideMenu,
    toggle: toggleMenu,
  } = useToggle(true)
  const { hasScope, getUser } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [searchTerm, setSearchTerm] = useState<string>("")

  const breakpoint = useBreakpoint()

  useEffect(() => {
    if (!!menuIsShowing && (breakpoint === "xxs" || breakpoint === "xs" || breakpoint === "sm")) {
      hideMenu()
    } else if (
      !menuIsShowing &&
      breakpoint !== "xxs" &&
      breakpoint !== "xs" &&
      breakpoint !== "sm"
    ) {
      showMenu()
    }
  }, [breakpoint])

  const getDomainIsActiveRoute = useCallback(() => {
    const isDomain = location?.pathname.startsWith("/domains")
    const isInstruction = location?.pathname.startsWith("/domains/instruction")
    const isPropagation = location?.pathname.startsWith("/domains/propagation")
    const isBlockedPhrases = location?.pathname.startsWith("/domains/blocked-phrases")
    return !!isDomain && !isInstruction && !isBlockedPhrases && !isPropagation
  }, [location])

  const supportLinks: LinkItem[] = [
    { path: "accounting", hasScope: scopes.dnsimple.reports.read },
    { path: "dashboard", hasScope: scopes.sites.read },
    { path: "users", hasScope: scopes.users.search },
    { path: "sites", hasScope: scopes.sites.search },
    { path: "domains", activeResolver: getDomainIsActiveRoute, hasScope: scopes.domains.search },
    { path: "merchants", hasScope: scopes.merchants.read },
    { path: "orders", hasScope: scopes.orders.read },
    { path: "explore", hasScope: scopes.sites.explore },
  ]

  const taggingLinks: LinkItem[] = [
    { path: "tags", hasScope: scopes.tags.read },
    { path: "categories", hasScope: scopes.categories.read },
    { path: "archetypes", hasScope: scopes.archetypes.read },
    { path: "page-types", label: "Page Types", hasScope: scopes.pageTypes.read },
    { path: "code-snippets", label: "Code Snippets", hasScope: scopes.codeSnippets.read },
  ]

  const appLinks: LinkItem[] = [
    {
      path: "domains/blocked-phrases",
      label: "Blocked Domain Phrases",
      hasScope: scopes.domains.blockedPhrases.read,
    },
    {
      path: "domains/instructions",
      label: "Domain Instructions",
      hasScope: scopes.domain.instructions.read,
    },
    {
      path: "domains/propagation",
      label: "Propagation Checker",
      hasScope: scopes.domain.dnsRecords.propagation.read,
    },
    { path: "templates", hasScope: scopes.templates.read },
    { path: "template-filters", label: "Template Filters", hasScope: scopes.templateFilters.read },
    { path: "themes", hasScope: scopes.themes.read },
  ]

  const billingLinks: LinkItem[] = [
    { path: "entitlements", hasScope: scopes.billing.entitlements.read },
    { path: "products", hasScope: scopes.billing.products.read },
    { path: "plans", hasScope: scopes.billing.plans.read },
    { path: "promos", label: "Promo Codes", hasScope: scopes.promos.read },
    { path: "coupons", hasScope: scopes.billing.coupons.read },
    { path: "tld-pricing", label: "TLD Pricing", hasScope: scopes.domainTldPricing.read },
  ]

  const adminLinks: LinkItem[] = [
    { path: "deadletter", label: "Dead Letter", hasScope: scopes.deadletterEvents.read },
    { path: "employees", hasScope: scopes.employees.read },
    { path: "events", hasScope: scopes.events.read },
    { path: "nocontext", label: "No Context", hasScope: scopes.yeet.noContext.images.read },
    { path: "requests", hasScope: scopes.events.read },
  ]

  const deploymentsLinks: LinkItem[] = [
    { path: "diagrams" },
    { path: "deployments", hasScope: scopes.pipelines.read },
    { path: "tekton", hasScope: scopes.pipelines.read },
    { path: "docs", hasScope: scopes.pipelines.read },
  ]

  const blockTemplateLinks: LinkItem[] = [
    { path: "ai/conversations", label: "AI Conversations", hasScope: scopes.blocks.read },
    { path: "ai/suggestions", label: "AI Suggestions", hasScope: scopes.suggestions.read },
    { path: "page-section-types", label: "Page Sections", hasScope: scopes.pageSectionTypes.read },
    {
      path: "block-templates",
      label: "Page Section Variants",
      hasScope: scopes.blockTemplates.read,
    },
    {
      path: "ai/text-generation",
      label: "Text Generation",
      hasScope: scopes.textGenerationActions.read,
    },
    {
      path: "/ai/image-generation-styles",
      label: "Image Generation Styles",
      hasScope: scopes.ai.imageGenerationStyles.read,
    },
  ]

  const remoteConfigLinks: LinkItem[] = [
    { path: "values", hasScope: scopes.remoteConfig.defaultValues.read },
    { path: "experiments", hasScope: scopes.remoteConfig.experiments.read },
    { path: "users/groups", label: "User Groups", hasScope: scopes.remoteConfig.userGroups.read },
  ]

  const isActiveRoute = (links: LinkItem[]) => {
    return links.some((link) => location.pathname.includes(link.path))
  }

  const getLinks = (links: LinkItem[]) => {
    const filteredLinks = links.filter((link) => {
      if (!link.hasOwnProperty("hasScope") && !link.hasScope) {
        return true
      } else {
        return hasScope(link?.hasScope as string)
      }
    })

    return {
      count: filteredLinks?.length,
      component: (
        <List>
          {filteredLinks.map((item, index) => {
            let isActive: boolean = false
            if (item?.activeResolver) {
              isActive = item.activeResolver()
            } else {
              isActive = location?.pathname.startsWith(`/${item.path}`)
            }

            const textProps: any = {
              primary: item.label || capitalizeFirstLetter(item.path),
            }
            if (isActive) {
              textProps.style = { textDecoration: "underline" }
            }
            return (
              <ListItem button key={index} onClick={() => navigate(item.path)}>
                <ListItemText {...textProps} />
              </ListItem>
            )
          })}
        </List>
      ),
    }
  }

  const filteredAdminLinks = getLinks(adminLinks)
  const filteredBillingLinks = getLinks(billingLinks)
  const filteredRemoteConfigLinks = getLinks(remoteConfigLinks)
  const filteredBlockLinks = getLinks(blockTemplateLinks)
  const filteredDeploymentsLinks = getLinks(deploymentsLinks)

  const searchableLinks = [
    ...supportLinks,
    ...adminLinks,
    ...billingLinks,
    ...remoteConfigLinks,
    ...blockTemplateLinks,
    ...deploymentsLinks,
  ]

  const filterLinks = (links: LinkItem[]) => {
    if (!searchTerm) return links
    return links.filter(
      (link) =>
        link.path.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.label?.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  }

  const filteredSearchableLinks = filterLinks(searchableLinks)
  const filteredLinksComponent = getLinks(filteredSearchableLinks)

  const navigateToHome = () => {
    navigate("/")
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const useEasterEggString = sessionStorage.getItem("usePapyrusFont")
  const useEasterEgg = useEasterEggString && Boolean(useEasterEggString)

  const name = useEasterEgg ? "Chad" : getUser()?.employee?.name
  const email = useEasterEgg ? "chad@ohnoyourglobe.com" : getUser()?.employee?.email
  const profilePhoto = useEasterEgg ? Chad : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: (theme) =>
            config.environmentName === "production"
              ? theme.palette.primary.dark
              : theme.palette.secondary.dark,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton onClick={toggleMenu} size="large">
                <MenuIcon htmlColor="white" fontSize="large" />
              </IconButton>
              <img
                height={55}
                src={Logo}
                alt={"Universe"}
                style={{ cursor: "pointer" }}
                onClick={navigateToHome}
              />
              <Title style={{ marginLeft: 10, cursor: "pointer" }} onClick={navigateToHome}>
                Universe
              </Title>
            </Box>
            <IconButton sx={{ p: 0, marginLeft: "auto" }}>
              <Avatar id="user-profile-pic" alt={name} onClick={handleClick} src={profilePhoto}>
                {name?.charAt(0)}
              </Avatar>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box sx={{ pl: 2, pr: 2 }}>
                  <p id="current-user-name">
                    <b>{name}</b>
                  </p>
                  <p>{email}</p>
                </Box>
              </Menu>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={menuIsShowing}
        onClose={toggleMenu}
        variant="persistent"
        hideBackdrop
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: menuIsShowing ? DRAWER_WIDTH : 0,
          flexShrink: 0,
          "& .MuiPaper-root": {
            width: menuIsShowing ? DRAWER_WIDTH : 0,
          },
        }}
      >
        <Toolbar />
        <Box overflow="auto">
          <Box sx={{ p: 2 }}>
            <TextField
              label="Search screens"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          {searchTerm.length > 0 && filteredLinksComponent.count === 0 && (
            <Typography sx={{ p: 2 }}>No results found for "{searchTerm}"</Typography>
          )}
          {searchTerm.length > 0 && filteredLinksComponent.count > 0 ? (
            filteredLinksComponent.component
          ) : (
            <>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ListItemIcon>
                    <SupportIcon />
                  </ListItemIcon>
                  <Typography
                    style={{ fontWeight: isActiveRoute(supportLinks) ? "bold" : "normal" }}
                  >
                    Support
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{getLinks(supportLinks).component}</AccordionDetails>
              </Accordion>
              <Divider />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ListItemIcon>
                    <CategoriesIcon />
                  </ListItemIcon>
                  <Typography
                    style={{ fontWeight: isActiveRoute(taggingLinks) ? "bold" : "normal" }}
                  >
                    Archetypes & Categories
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{getLinks(taggingLinks).component}</AccordionDetails>
              </Accordion>
              <Divider />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ListItemIcon>
                    <AppIcon />
                  </ListItemIcon>
                  <Typography style={{ fontWeight: isActiveRoute(appLinks) ? "bold" : "normal" }}>
                    App
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{getLinks(appLinks).component}</AccordionDetails>
              </Accordion>
              <Divider />
              {filteredBillingLinks.count > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon>
                        <BillingIcon />
                      </ListItemIcon>
                      <Typography
                        style={{ fontWeight: isActiveRoute(billingLinks) ? "bold" : "normal" }}
                      >
                        Billing
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{filteredBillingLinks.component}</AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {filteredAdminLinks.count > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon>
                        <EventsIcon />
                      </ListItemIcon>
                      <Typography
                        style={{ fontWeight: isActiveRoute(adminLinks) ? "bold" : "normal" }}
                      >
                        Events & Requests
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{filteredAdminLinks.component}</AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {filteredDeploymentsLinks.count > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon>
                        <DeploymentIcon />
                      </ListItemIcon>
                      <Typography
                        style={{ fontWeight: isActiveRoute(deploymentsLinks) ? "bold" : "normal" }}
                      >
                        DevOps
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{filteredDeploymentsLinks.component}</AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {filteredRemoteConfigLinks.count > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon>
                        <RemoteConfigIcon />
                      </ListItemIcon>
                      <Typography
                        style={{ fontWeight: isActiveRoute(remoteConfigLinks) ? "bold" : "normal" }}
                      >
                        Remote Config
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{filteredRemoteConfigLinks.component}</AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {filteredBlockLinks.count > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ListItemIcon>
                        <AIIcon />
                      </ListItemIcon>
                      <Typography
                        style={{
                          fontWeight: isActiveRoute(blockTemplateLinks) ? "bold" : "normal",
                        }}
                      >
                        AI Tools
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{filteredBlockLinks.component}</AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
            </>
          )}
        </Box>
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          m: "15px",
          pt: "56px",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout
