import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import  { FunctionComponent } from "react"
import { TextBody } from "../../../components/shared/Typography"
import { DomainTypeEnum, Site } from "../../../types"
import isUniverseDomain from "../../../utils/isUniverseDomain"

//TODO: fix menu item list styling mui

interface Props {
  handleChange: (event: any, value: any) => any
  sites: Site[]
  disabled?: boolean
  highlightAssigned?: boolean
  domainType?: DomainTypeEnum
  value?: Site | string | null
}

// TODO: fix select item styles

const SiteSelect: FunctionComponent<Props> = ({
  handleChange,
  sites,
  disabled,
  highlightAssigned,
  domainType,
  value,
}) => {
  const getOptions = () => {
    if (!sites?.length) {
      return
    }
    return sites.map((site, index) => {
      let label = `${site.id}`
      if (site.title) {
        label += ` - ${site.title}`
      }

      // This is a check to see if the domain is actually a subdomain represented in the domain column due to some backend configuration
      const hasUniverseDomain = isUniverseDomain(site.domain)
      if (site.domain && domainType === DomainTypeEnum.Domain && highlightAssigned) {
        label += ` - current domain: ${site.domain}`
      }

      if (site.subdomain && domainType === DomainTypeEnum.Subdomain && highlightAssigned) {
        label += ` - current subdomain: ${site.subdomain}`
      }

      return (
        <MenuItem value={site.id} key={index}>
          <TextBody
            hasWarning={
              highlightAssigned &&
              ((domainType === DomainTypeEnum.Domain && site?.domain && !hasUniverseDomain) ||
                (domainType === DomainTypeEnum.Subdomain && site.subdomain))
            }
          >
            {label}
          </TextBody>
        </MenuItem>
      )
    })
  }

  return (
    <FormControl fullWidth>
      <InputLabel variant="outlined">Destination Site</InputLabel>
      <Select
        label="Destination Site"
        onChange={handleChange}
        disabled={disabled}
        variant="outlined"
        defaultValue=""
        value={value}
      >
        <MenuItem key={"empty-option"} value=""></MenuItem>
        {getOptions()}
      </Select>
    </FormControl>
  )
}

export default SiteSelect
