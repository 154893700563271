import { Box, Button, TextField } from "@mui/material"
import { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { ImageStatus as ImageStatusType } from "../../types"
import ImageStatus from "./components/ImageStatus"
import NoContextTable from "./components/NoContextTable"
import useCreateNoContextImage from "./hooks/useCreateNoContextImage"
import useGetNoContextImages from "./hooks/useGetNoContextImages"

const NoContextScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.yeet.noContext.images.create)

  const { getImages, images, error, loading, paginationData } = useGetNoContextImages()
  const { createNoContextImage, loading: postLoading, error: postError } = useCreateNoContextImage()
  const [prompt, setPrompt] = useState("")
  const [status, setStatus] = useState<ImageStatusType | null>()
  const [statusError, setStatusError] = useState<any>()
  const { getUser } = useAuth()

  useEffect(() => {
    getImages()
    // eslint-disable-next-line
  }, [])

  const handleTextBoxChange = (event: any) => {
    setPrompt(event.target.value)
  }

  const handleCreateImage = async () => {
    const ts = new Date()
    const key = `${
      getUser()?.employee?.email.split("@")[0]
    }-${ts.toISOString()}-${prompt.toLowerCase()}`
    const data = {
      prompt,
      bucket: "universe-assets-staging",
      s3_key: `gan/${key.replace(/\W+/g, "-")}.png`,
    }

    const onSuccess = async () => {
      await getImages()
      setStatus({ ...data, message: ":deal:" })
      toast.success("Successfully created VQGAN image")
    }

    await createNoContextImage(data, onSuccess)
  }

  const showTable = !loading && images.length > 0
  const hasError =
    (error || postError || statusError) &&
    (error?.message !== "request timed out" ||
      postError?.message !== "request timed out" ||
      statusError?.message !== "request timed out")

  return (
    <ScreenContainer title="No Context" requiresScope={scopes.yeet.noContext.images.read}>
      <div>
        <TextField
          className="find-sites-search-bar"
          variant="outlined"
          label="Prompt"
          value={prompt}
          onChange={handleTextBoxChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              createNoContextImage(prompt, getImages)
            }
          }}
        />
        <br />
        {canCreate && (
          <Button
            variant="contained"
            sx={{
              marginTop: "16px",
              maxWidth: "500px",
              alignSelf: "center",
              width: "100%",
            }}
            color="primary"
            disabled={postLoading}
            onClick={handleCreateImage}
          >
            Create!
          </Button>
        )}
      </div>
      {hasError && <ErrorComponent error={error || postError || statusError} />}

      <ImageStatus inputStatus={status} setError={setStatusError} />

      {showTable && (
        <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
          <NoContextTable images={images} paginationData={paginationData} />
        </Box>
      )}
    </ScreenContainer>
  )
}

export default NoContextScreen
