import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DeleteEmailForwardRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteEmailForward = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      emailForward: DeleteEmailForwardRequest,
      onSuccess?: () => any,
    ): Promise<DeleteEmailForwardRequest> => {
      const response = await makeRequest<DeleteEmailForwardRequest>(
        `${DOMAINS}/${emailForward.domain}/emailforwards/${emailForward.id}`,
        {
          method: "DELETE",
          headers: {
            "universe-user-id": `${emailForward.user_id}`,
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteEmailForward: performAction, loading, error }
}

export default useDeleteEmailForward
