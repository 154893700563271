import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Entitlement } from "../../../types"
import EntitlementTableRow from "./EntitlementTableRow"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

interface Props {
  entitlements: Entitlement[]
  paginationData: PaginationData
  getEntitlements: () => any
}

const EntitlementsTable: FunctionComponent<Props> = ({
  entitlements,
  paginationData,
  getEntitlements,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.billing.entitlement.update)

  const columns = canEdit
    ? [
        "id",
        "Title",
        "Description",
        "Variant",
        "Active",
        "Is Free",
        "Usage Enabled",
        "Limit Default",
        "Unit",
        "Action",
      ]
    : [
        "id",
        "Title",
        "Description",
        "Variant",
        "Active",
        "Is Free",
        "Usage Enabled",
        "Limit Default",
        "Unit",
      ]

  const getRows = useCallback(() => {
    return entitlements.map((entitlement: Entitlement) => (
      <EntitlementTableRow
        key={entitlement.id}
        entitlement={entitlement}
        getEntitlements={getEntitlements}
      />
    ))
    // eslint-disable-next-line
  }, [entitlements, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default EntitlementsTable
