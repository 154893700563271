import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { DomainInstruction } from "../../../types"
import { DOMAIN_INSTRUCTIONS } from "../../../constants/urlDefs"

const useGetDomainInstructions = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<DomainInstruction>> => {
    return makeRequest<PagedResponse<DomainInstruction>>(`${DOMAIN_INSTRUCTIONS}`, {}, { handleUnauthenticated })
  })

  return { loading, error, getDomainInstructions: performAction, instructions: data?.results || [] }
}

export default useGetDomainInstructions
