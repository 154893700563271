import { Button } from "@mui/material"
import React, { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Theme } from "../../../types"
import EditThemeDialog from "./EditThemeDialog"
import { LoadingButton } from "@mui/lab"
import useThemeTagDialog from "./ThemeTagDialog"

type ThemeRowProps = {
  theme: Theme
}

const ThemeRow: FunctionComponent<ThemeRowProps> = ({ theme }) => {
  const { openThemeTagDialog, themeTagDialogLoading, themeTagDialog } = useThemeTagDialog()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BasicTableRow key={theme.id}>
      <BasicTableCell component="th" scope="row">
        {theme.icon_url ? <img src={theme.icon_url} height="50" alt={theme.name}></img> : ""}
      </BasicTableCell>
      <BasicTableCell>{theme.name}</BasicTableCell>
      <BasicTableCell>{theme.description}</BasicTableCell>
      <BasicTableCell>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Edit
        </Button>
        <EditThemeDialog theme={theme} open={open} handleClose={handleClose}></EditThemeDialog>
      </BasicTableCell>
      <BasicTableCell>
        <LoadingButton
          loading={themeTagDialogLoading}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => openThemeTagDialog(theme)}
        >
          Tags
        </LoadingButton>

        {themeTagDialog}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default ThemeRow
