import { FunctionComponent } from "react"
import { Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { Archetype } from "../../../types"
import useCreateArchetype from "../hooks/useCreateArchetype"
import useUpdateArchetype from "../hooks/useUpdateArchetype"
import useDeleteArchetype from "../hooks/useDeleteArchetype"

interface Props {
  isOpen: boolean
  close: () => void
  editArchetype?: Archetype
}

const ArchetypeDialog: FunctionComponent<Props> = ({ isOpen, close, editArchetype }) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.archetype.delete)

  const { createArchetype, loading: creating, error: creationError } = useCreateArchetype()
  const { updateArchetype, loading: updating, error: updateError } = useUpdateArchetype()
  const { deleteArchetype, loading: deleting, error: deleteError } = useDeleteArchetype()

  const handleSuccess = async (msg: string) => {
    toast.success(msg)
    handleClose()
  }

  const handleDelete = async () => {
    if (editArchetype) {
      await deleteArchetype(editArchetype.id, () => handleSuccess("Successfully deleted Archetype"))
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    displayName: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      name: editArchetype?.name || "",
      displayName: editArchetype?.display_name || "",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      if (editArchetype) {
        await updateArchetype(
          editArchetype.id,
          {
            name: values.name !== "" ? values.name : null,
            display_name: values.displayName !== "" ? values.displayName : null,
          },
          () => handleSuccess("Successfully updated Archetype"),
        )
      } else {
        await createArchetype(
          {
            name: values.name !== "" ? values.name : null,
            display_name: values.displayName !== "" ? values.displayName : null,
          },
          () => handleSuccess("Successfully created Archetype"),
        )
      }
      close()
    },
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editArchetype
          ? `Update Archetype: ${editArchetype.display_name} (${editArchetype.id})`
          : "Create A New Archetype"
      }
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {(!!creationError || !!updateError || !!deleteError) && (
              <ErrorComponent error={creationError || updateError || deleteError} />
            )}
          </Row>
          <Row>
            {!editArchetype && (
              <TextField
                label="Name"
                fullWidth
                variant="outlined"
                margin="normal"
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
                helperText={formik.errors.name?.toString()}
                onBlur={(evt) => formik.validateField("name")}
              />
            )}
            <TextField
              label="Display Name"
              fullWidth
              variant="outlined"
              margin="normal"
              id="displayName"
              name="displayName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.displayName}
              error={!!formik.errors.displayName}
              helperText={formik.errors.displayName?.toString()}
              onBlur={(evt) => formik.validateField("displayName")}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!editArchetype && canDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={creating || updating || deleting}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creating || updating || deleting || !formik.isValid}
          >
            {editArchetype ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default ArchetypeDialog
