import { Box, Button, DialogContentText, TextField } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import useCreateDomain from "../hooks/useCreateDomain"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"
import SitesByUserSelect from "../../Sites/components/SitesByUserSelect"
import * as Yup from "yup"
import useSearchDomains from "../hooks/useSearchDomains"
import { DomainSearch } from "../../../types"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"

type NewDomainDialogProps = {
  close: () => any
  isOpen: boolean
}

const CreateNewDomainDialog: FunctionComponent<NewDomainDialogProps> = ({ close, isOpen }) => {
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams()
  const [error, setError] = useState<Error>()
  const [selectedDomain, setSelectedDomain] = useState<string>("")

  const { createDomain, loading, error: requestError } = useCreateDomain()
  const { searchDomains, loading: domainsLoading, domains } = useSearchDomains()

  const validationSchema = Yup.object().shape({
    url: Yup.string().required(),
    siteOption: Yup.object().required(),
    userOption: Yup.object().required(),
  })

  const formik = useFormik({
    initialValues: {
      url: "",
      userOption: "",
      siteOption: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      try {
        await createDomain(selectedDomain, values.userOption.user?.id, values.siteOption.id, close)
        setSearchParams({ type: "url", term: selectedDomain, source: "newdomain" })
      } catch (err) {
        setError(enforceError(err))
      }
    },
  })

  const handleSearchDomains = async () => {
    setSelectedDomain("")
    await searchDomains(formik.values.url)
  }

  const columns: string[] = ["Domain", "Action"]

  return (
    <Dialog
      title="Create New Domain"
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      content={
        <>
          {error || requestError ? <br /> : null}

          <DialogContentText>
            Creating a domain will add it to the database and then register it in DNSimple if it is
            not already registered. This will also create the DNS records and set it up in Vercel.
            <br />
            <br />
            You will need to <b>unlink the existing domain</b> from a site if you are changing a
            custom domain. Otherwise, you can create it an assign to a site here.
          </DialogContentText>
          <FormikProvider value={formik}>
            <UserSearchAutocomplete />
            <SitesByUserSelect
              userFieldName="userOption"
              siteFieldName="siteOption"
              highlightAssigned
            />
          </FormikProvider>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <TextField
              margin="dense"
              id="url"
              label="Desired Domain"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.url}
              error={!!formik.errors.url}
            />
            <Button
              onClick={handleSearchDomains}
              sx={{ marginLeft: "10px" }}
              disabled={!formik.values.url}
            >
              Search
            </Button>
          </Box>
          {domains ? (
            <>
              <BasicTable
                columns={columns}
                rows={domains.status.map((domain: DomainSearch) => (
                  <>
                    <BasicTableRow>
                      <BasicTableCell>{domain.domain}</BasicTableCell>
                      {domain.summary === "inactive" ? (
                        <BasicTableCell>
                          <Button
                            onClick={() => setSelectedDomain(domain.domain)}
                            sx={{ margin: 0 }}
                            disabled={selectedDomain === domain.domain}
                          >
                            {selectedDomain === domain.domain ? "Selected" : "Select"}
                          </Button>
                        </BasicTableCell>
                      ) : (
                        <BasicTableCell>Unavailable</BasicTableCell>
                      )}
                    </BasicTableRow>
                  </>
                ))}
              ></BasicTable>
            </>
          ) : null}
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={!selectedDomain || loading || domainsLoading}
          >
            Register Domain
          </Button>
        </>
      }
    />
  )
}

export default CreateNewDomainDialog
