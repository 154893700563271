import "highlight.js/styles/xcode.css"
import hljs from "highlight.js"
import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import useGetConversationById from "./hooks/useGetConversationById"
import { useNavigate, useParams } from "react-router-dom"
import { Grid, Button } from "@mui/material"
import ChatContainer from "./components/ChatContainer"

import {
  AILog,
  AIConversationMessage,
  AISiteGeneration,
  AISiteGenerationSection,
  PageSectionType,
} from "../../types"
import useGetAILogForConversation from "./hooks/useGetLogsForConversationId"
// @ts-ignore
import { JSONViewer } from "react-json-editor-viewer"
import { Link } from "react-router-dom"
import useGetGenerationsForConversation from "./hooks/useGetGenerationsForConversation"
import useGetSectionsForGeneration from "./hooks/useGetSectionsForGeneration"
import useGetBlockTemplates from "../BlockTemplates/hooks/useGetBlockTemplates"
import useGetPageSectionTypes from "../PageSectionTypes/hooks/useGetPageSectionTypes"
import useGetSiteById from "../Sites/hooks/useGetSiteById"
import useGetUserById from "../Users/hooks/useGetUserById"
import useGetSitePages from "../Sites/hooks/useGetSitePages"
import config from "../../config"
import useGetPageById from "../Sites/hooks/useGetPageById"

const AIConversationDetail: FunctionComponent = () => {
  const { loading, error, getConversationById, conversation } = useGetConversationById()
  const { getAILogForConversation, logs } = useGetAILogForConversation()

  const { conversationId } = useParams<{ conversationId: string }>()

  const { getGenerationsForConversation, generations } = useGetGenerationsForConversation(25)

  const { getPageById, page } = useGetPageById()

  const { getSectionsForGeneration, sections } = useGetSectionsForGeneration(25)

  const { getBlockTemplates, blockTemplates } = useGetBlockTemplates(1000)

  const { getPageSectionTypes, pageSectionTypes } = useGetPageSectionTypes(1000)

  const { getUserById, user } = useGetUserById()

  const { site, getSiteById } = useGetSiteById()

  const { getPages, pages } = useGetSitePages()

  const [json, setJson] = useState<any>({})

  const navigate = useNavigate()

  useEffect(() => {
    if (generations.length > 0) {
      getPageById(generations.slice(-1)[0].page_id)
      getSectionsForGeneration(conversationId, generations.slice(-1)[0].id)
    }
    // eslint-disable-next-line
  }, [generations])

  useEffect(() => {
    setJson(page?.content)
  }, [page])

  useEffect(() => {
    if (conversationId) {
      getConversationById(conversationId)
      getAILogForConversation(conversationId)
      getGenerationsForConversation(conversationId)
    }
    getBlockTemplates()
    getPageSectionTypes()
  }, [])

  useEffect(() => {
    if (conversation) {
      getSiteById(conversation?.site_id)
      getPages(conversation?.site_id, "")

      const users = conversation?.messages.reduce(
        (acc: { [key: number]: boolean }, message: AIConversationMessage) => {
          acc[message.sender.user_id] = true
          return acc
        },
        {},
      )
      const userId = Object.keys(users)[0] as unknown as number
      if (userId) {
        getUserById(userId)
      }
    }

    // eslint-disable-next-line
  }, [conversation])

  useEffect(() => {
    hljs.highlightAll()
  }, [json])

  const formatDate = (inputDate: string | undefined) => {
    if (inputDate) {
      const date: Date = new Date(inputDate)

      const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }

      return date.toLocaleString("en-US", options)
    }
    return ""
  }
  const pageId = conversation?.page_id

  const themeId = pages?.find((x) => x.theme_id !== null)?.theme_id

  const url = config.environmentName === "production" ? "univer.se" : "onuniverse-staging.com"

  const endDate = (startDate: string) => {
    const dt = new Date(startDate)
    dt.setMinutes(dt.getMinutes() + 10)
    return dt.toISOString()
  }

  const navigateToThemeDetails = () => {
    navigate(`/themes/${themeId}`)
  }

  return (
    <ScreenContainer
      title={`${conversation?.title} - ${conversationId} - ${formatDate(conversation?.created_at)}`}
      requiresScope={scopes.ai.conversations.read}
    >
      {error ? <ErrorComponent error={error} /> : ""}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container spacing={"1em"}>
            <Grid item lg={4} md={6} sm={8} xs={12}>
              <h3>Chat</h3>
              {conversationId ? <ChatContainer conversationId={parseInt(conversationId)} /> : ""}

              <h3>Generations</h3>
              <table>
                <thead>
                  <tr key={"header"}>
                    <td>Page ID</td>
                    <td>Title</td>
                    <td>Created</td>
                  </tr>
                </thead>
                <tbody>
                  {generations.map((x: AISiteGeneration) => (
                    <tr key={x.id}>
                      <td>{x.page_id}</td>
                      <td>{x.summary}</td>
                      <td>{formatDate(x.created_at)}</td>
                      <td>
                        <Button onClick={(evnt) => getSectionsForGeneration(conversationId, x.id)}>
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>

            <Grid item lg={3} md={6} sm={8} xs={12}>
              <h3>Site</h3>
              {site && pageId ? (
                <a href={site.urls[0]} target="_blank" rel="noreferrer">
                  <img
                    className="thumbnail"
                    src={site?.thumbnails.small}
                    alt="Site thumbnail"
                    height={600}
                  />
                </a>
              ) : (
                ""
              )}
            </Grid>

            <Grid item lg={3} md={6} sm={4} xs={12}>
              <h3 onClick={navigateToThemeDetails}>
                Theme ID: <u style={{ cursor: "pointer" }}>{themeId}</u>
              </h3>
              <iframe
                src={`https://designsystem.${url}/vibes?theme_id=${themeId}&isUniverse=true`}
                height={600}
                frameBorder={0}
              ></iframe>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <table>
                <thead>
                  <tr>
                    <th>GPT ID</th>
                    <th>GPT Type</th>
                    <th>Section</th>
                    <th>Template</th>
                    <th>Output</th>
                  </tr>
                </thead>
                <tbody>
                  {sections
                    .sort((a, b) => a.id - b.id)
                    .map((section: AISiteGenerationSection) => (
                      <tr key={section.id}>
                        <td>{section.section_id}</td>
                        <td>
                          <Button onClick={(evt) => setJson(section.input)}>
                            {(section.input as any)?.type}
                          </Button>
                        </td>
                        <td>
                          {pageSectionTypes.find(
                            (x: PageSectionType) => x.id === section.section_type_id,
                          )?.name ?? section.section_type_id}
                        </td>
                        <td>
                          {blockTemplates.find((x) => x.id === section.block_template_id)?.name ??
                            section.block_template_id}
                        </td>
                        <td>
                          <Button onClick={(evt) => setJson(section.content)}>View</Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <h3>Logs</h3>
              <table>
                <thead>
                  <tr key={"header"}>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Request</th>
                    <th>Req</th>
                    <th>Response</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log: AILog) => (
                    <tr key={log.id}>
                      <td>{formatDate(log.created_at)}</td>
                      <td>
                        {log.type.replace("controllers/v4/ai/sites", "").replace(/\/handler.*/, "")}
                      </td>
                      <td>
                        <Link
                          to={`/requests?request_id=${log.request_id}&start=${
                            log.created_at
                          }&end=${endDate(log.created_at)}&&service=site-service&-line=Sequelize`}
                        >
                          {log.request_id}
                        </Link>
                      </td>
                      <td>
                        <Button onClick={(evt) => setJson(log.body)}>View</Button>
                      </td>
                      <td>
                        <Button onClick={(evt) => setJson(JSON.parse(log.raw_response))}>
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <pre style={{ whiteSpace: "pre-wrap" }}>
                <code className="language-json">
                  {JSON.stringify(json, null, 2)?.replaceAll("\\n", "\n").replaceAll('\\"', '"')}
                </code>
              </pre>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={6} sm={4} xs={12}>
              <></>
            </Grid>
          </Grid>
        </>
      )}
    </ScreenContainer>
  )
}

export default AIConversationDetail
