import { NavigateNext as NavigateNextIcon } from "@mui/icons-material"
import { styled, Theme } from "@mui/material/styles"
import { FunctionComponent } from "react"
import Card, { CardColumn, CardRow } from "../../../components/shared/Card"
import CopyIcon from "../../../components/shared/CopyIcon"
import SubscriptionBadge from "../../../components/shared/SubscriptionBadge"
import { TextBody } from "../../../components/shared/Typography"
import SiteLink from "../../Sites/components/SiteLink"
import { Site } from "../../../types"

type UserCardProps = {
  id: number
  domain: string
  role: string | undefined
  title: string
  site: Site
}

const UserSiteCard: FunctionComponent<UserCardProps> = ({ id, domain, title, role, site }) => {
  return (
    <Container>
      <CardColumn flexBasis="90%">
        <CardRow>
          <StyledText>
            <SubscriptionBadge
              text={site.badge.title}
              textColor={site.badge.title_text_color.light_mode}
              backgroundColor={site.badge.background_color.light_mode}
              hoverText={`ID: ${site?.plan?.id || "null"}\nName: ${site?.plan?.name || "null"}\nDuration: ${site?.plan?.duration || "null"}`}
              borderColor={site.badge.border_color.light_mode}
              borderWidth={site.badge.border_width}
            />
            {title} ({id})
          </StyledText>
          <CopyIcon content={id} />
        </CardRow>
        <CardRow>
          <StyledText>
            {domain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0]} ({role})
          </StyledText>
        </CardRow>
      </CardColumn>
      <CardColumn flexBasis="10%">
        <SiteLink siteId={id}>
          <NavigateNextIcon fontSize="large" />
        </SiteLink>
      </CardColumn>
    </Container>
  )
}

interface ContainerProps {
  theme: Theme
  success?: boolean
}

const Container: any = styled(Card, {
  shouldForwardProp: (prop) => prop !== "success",
})(
  ({ theme, success = false }: ContainerProps) => `
  margin: 8px;
  display: flex;
  width: 100%;
  background-color:
    ${success ? theme.colors.successLight : theme.colors.card};
  
  ${
    success &&
    `
    border: 1.5px solid ${theme.colors.successDark};
  `
  }  
`,
)

const StyledText = styled(TextBody)`
  margin-left: 5px;
  margin-right: 5px;
`

export default UserSiteCard
