import { Box, Button, IconButton, Tooltip } from "@mui/material"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import { FunctionComponent, useCallback } from "react"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Subhead } from "../../../components/shared/Typography"
import { Domain, DomainStatusEnum, DomainSub } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"
import UserLink from "../../Users/components/UserLink"
import { Row, StyledText } from "./DomainLayoutShared"
import useMigrateToNameservers from "../hooks/useMigrateToNameservers"
import ErrorComponent from "../../../components/shared/Error"
import SubscriptionLink from "../../Subscriptions/components/SubscriptionLink"
import config from "../../../config"

interface Props {
  domain: Domain | undefined
  refreshData: () => void
}

const DomainOverview: FunctionComponent<Props> = ({ domain, refreshData }) => {
  const { migrateToNameservers, loading, error } = useMigrateToNameservers()

  const formatSubscriptionIDs = useCallback((subscriptions: DomainSub[]) => {
    const ids = subscriptions.reduce((accu: any, subscription: DomainSub, index) => {
      const elem = (
        <SubscriptionLink key={index} subscriptionId={subscription.id}>
          {subscription.id}
        </SubscriptionLink>
      )
      return accu === null ? [elem] : [...accu, ",", elem]
    }, null)

    return ids
  }, [])

  if (!domain) {
    return null
  }

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "450px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
      }}
    >
      <Row>
        <Subhead>Name: </Subhead>
        <StyledText>
          {domain.url} {domain.deleted_at ? `[Deleted]` : ""}
        </StyledText>
        <CopyIcon content={domain.url} />
      </Row>
      <Row>
        <Subhead>Domain Id: </Subhead>
        <StyledText>{domain.id}</StyledText>
        <CopyIcon content={domain.id} />
      </Row>
      {domain?.user_id && (
        <Row>
          <Subhead>User Id: </Subhead>
          <UserLink userId={domain.user_id}> {domain.user_id}</UserLink>
          <CopyIcon content={domain.user_id} />
        </Row>
      )}
      {domain?.site_id && (
        <Row>
          <Subhead>Site Id: </Subhead>
          <SiteLink siteId={domain.site_id}> {domain.site_id}</SiteLink>
          <CopyIcon content={domain.site_id} />
        </Row>
      )}
      <Row>
        <Subhead>Status: </Subhead>
        <StyledText>{domain.status}</StyledText>
      </Row>
      <Row>
        <Subhead>External: </Subhead>
        <StyledText>{domain.external ? "True" : "False"}</StyledText>
      </Row>
      <Row>
        <Subhead>Enabled: </Subhead>
        <StyledText>{domain.enabled ? "True" : "False"}</StyledText>
      </Row>
      <Row>
        <Subhead>
          Subscription IDs
          <Tooltip
            title={`If the domain is associated with a domain subscription, the id will be listed here.`}
          >
            <IconButton sx={{ m: 0, p: 0 }}>
              <InfoIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>{" "}
        </Subhead>
        <StyledText>{formatSubscriptionIDs(domain.subscriptions)}</StyledText>
      </Row>
      <Row>
        <Subhead>Add Subscription: </Subhead>
        <StyledText>
          <a
            href={`https://web.${config.environmentName === "production" ? "univer.se" : "onuniverse-staging.com"}/sites/${domain.site_id}/domains/claim?domain=${domain.url}&onboarding=false&context=buy-domain-existing-site`}
            target="_blank"
            rel="noreferrer"
          >
            Add Subscription Link
          </a>
        </StyledText>
      </Row>

      <Row>
        <Subhead>
          Last Successful Live Check:{" "}
          <Tooltip
            title={`This is when the domain was last reached on the internet by the system. If the domain was found to be live on the internet, the status was updated to reflect that. The current domain status is "${domain.status}". Some updates may not take place until this check has passed.`}
          >
            <IconButton sx={{ m: 0, p: 0 }}>
              <InfoIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>{" "}
        </Subhead>
        <StyledText>{domain.last_successful_liveliness_check || "None"}</StyledText>
      </Row>
      <Row>
        <Subhead>Created At: </Subhead>
        <StyledText>{domain.created_at}</StyledText>
      </Row>
      {domain.deleted_at && (
        <Row>
          <Subhead>Deleted At: </Subhead>
          <StyledText>{domain.deleted_at}</StyledText>
        </Row>
      )}
      {domain && domain.external && domain.status !== DomainStatusEnum.managed && (
        <Row>
          {error && <ErrorComponent error={error} />}
          <Button
            style={{ textTransform: "none", marginTop: 5, width: "100%" }}
            variant="contained"
            onClick={() => migrateToNameservers(domain.id, refreshData)}
            disabled={loading}
          >
            Migrate to nameserver setup
          </Button>
        </Row>
      )}
    </Box>
  )
}

export default DomainOverview
