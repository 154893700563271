import { useCallback, useEffect, useState } from "react"
import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Site } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useSearchSites = (defaultRowsPerPage = 25) => {
  const [includeArchived, setIncludeArchived] = useState(false)
  const [searchObject, setSiteSearch] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction } = useHandleRequest(
    (
      handleUnauthenticated,
      searchObject: string,
      includeArchived: boolean,
    ): Promise<PagedResponse<Site>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}&q=${searchObject}`
      if (!!includeArchived) {
        queryParams += `&deleted=true`
      }
      return makeRequest<PagedResponse<Site>>(
        `${SITESV4}/search?${queryParams}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (searchObject) {
      performAction(searchObject, includeArchived)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const searchSites = useCallback(
    async (searchObject: string, includeArchived: boolean = false) => {
      setIncludeArchived(includeArchived)
      setSiteSearch(searchObject)
      return await performAction(searchObject, includeArchived)
    },
    [performAction],
  )

  return {
    loading,
    error,
    searchSites,
    sites: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useSearchSites
