import { CATEGORIESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Category, CreateCategory } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateCategory = () => {
  const { error, loading, performAction } = useHandleRequest(
    (handleUnauthenticated, category: CreateCategory): Promise<Category> => {
      return makeRequest<Category>(
        `${CATEGORIESV5}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(category),
        },
        { handleUnauthenticated },
      )
    },
  )

  return { createCategory: performAction, loading, error }
}

export default useCreateCategory
