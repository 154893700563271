import { Button } from "@mui/material"
import { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Category } from "../../../types"

const INT_MAX = 2147483647

type CategoryRowProps = {
  category: Category
  onEditClicked: () => void
  onEditArchetypesClicked: () => void
}

const CategoryRow: FunctionComponent<CategoryRowProps> = ({
  category,
  onEditClicked,
  onEditArchetypesClicked,
}) => {
  const isOther = category.name === "other"
  return (
    <BasicTableRow key={category.name}>
      <BasicTableCell component="th" scope="row">
        <img src={category.icon_url} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {category.sort_order === INT_MAX ? "max" : category.sort_order}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {category.name}
      </BasicTableCell>
      <BasicTableCell>{category.display_name}</BasicTableCell>
      <BasicTableCell>{isOther ? "-" : category.archetype_selection_description}</BasicTableCell>
      <BasicTableCell align="right">
        {isOther ? null : (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={onEditClicked}
              style={{ margin: 4 }}
            >
              Edit
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={onEditArchetypesClicked}
              style={{ margin: 4 }}
            >
              Archetypes
            </Button>
          </>
        )}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default CategoryRow
