import { useEffect, useState } from "react"
import { CODESNIPPETS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { CodeSnippet } from "../../../types/code-snippets"

const useGetCodeSnippets = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getCodeSnippets,
  } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<CodeSnippet>> => {
    return makeRequest<PagedResponse<CodeSnippet>>(
      `${CODESNIPPETS}?limit=${encodeURIComponent(rowsPerPage)}&offset=${encodeURIComponent(
        page * rowsPerPage,
      )}&include_inactive=true`,
      {},
      { handleUnauthenticated },
    )
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getCodeSnippets()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getCodeSnippets,
    codeSnippets: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetCodeSnippets
