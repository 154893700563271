import React, { FunctionComponent, useState } from "react"
import { Button } from "@mui/material"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { AISuggestion } from "../../../types"
import AISuggestionDialog from "./AISuggestionDialog"

type AISuggestionTableRowProps = {
  aiSuggestion: AISuggestion
  getAISuggestions: () => any
}

const AISuggestionTableRow: FunctionComponent<AISuggestionTableRowProps> = ({
  aiSuggestion,
  getAISuggestions,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.suggestion.update)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getAISuggestions()
  }

  return (
    <BasicTableRow key={aiSuggestion.id}>
      <BasicTableCell component="td" scope="row">
        {aiSuggestion.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {aiSuggestion.suggestion}
      </BasicTableCell>

      {canEdit ? (
        <>
          <BasicTableCell component="td" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <AISuggestionDialog isOpen={open} close={handleClose} editAISuggestion={aiSuggestion} />
      ) : null}
    </BasicTableRow>
  )
}

export default AISuggestionTableRow
