import { USERSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { User } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteUser = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      userId: string | number,
      onSuccess?: () => any,
    ): Promise<User> => {
      const response = await makeRequest<User>(
        `${USERSV4}/${userId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteUser: performAction, loading, error }
}

export default useDeleteUser
