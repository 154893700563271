import  { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { DomainInstruction } from "../../../types"

type DomainInstructionRowProps = {
  instruction: DomainInstruction
}

const DomainInstructionRow: FunctionComponent<DomainInstructionRowProps> = ({ instruction }) => {
  return (
    <BasicTableRow key={instruction.id}>
      <BasicTableCell component="th" scope="row">
        {instruction.id}
      </BasicTableCell>
      <BasicTableCell>
        <img src={instruction.icon_url} height="50" width="50" alt={`${instruction.name} Icon`} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {instruction.name}
      </BasicTableCell>
      <BasicTableCell>{instruction.instructions}</BasicTableCell>
    </BasicTableRow>
  )
}

export default DomainInstructionRow
