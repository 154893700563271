import { FunctionComponent } from "react"
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { Collection, Tag, TemplateFilter } from "../../../types"
import useCreateTemplateFilter from "../hooks/useCreateTemplateFilter"
import useUpdateTemplateFilter from "../hooks/useUpdateTemplateFilter"
import useDeleteTemplateFilter from "../hooks/useDeleteTemplateFilter"

interface Props {
  isOpen: boolean
  close: () => void
  editTemplateFilter?: TemplateFilter
  collections?: Collection[]
  tags?: Tag[]
  maxSortOrder?: number
}

const TemplateFilterDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  editTemplateFilter,
  collections,
  tags,
  maxSortOrder,
}) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.templateFilter.delete)

  const {
    createTemplateFilter,
    loading: creating,
    error: creationError,
  } = useCreateTemplateFilter()
  const { updateTemplateFilter, loading: updating, error: updateError } = useUpdateTemplateFilter()
  const { deleteTemplateFilter, loading: deleting, error: deleteError } = useDeleteTemplateFilter()

  function toCamelCase(input: string): string {
    const words = input.replace(/[^a-zA-Z0-9]+/g, " ").split(" ")

    const camelCasedWords = words.map((word, index) =>
      index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )

    return camelCasedWords.join("")
  }

  const handleSuccess = async (msg: string) => {
    toast.success(msg)
    handleClose()
  }

  const handleDelete = async () => {
    if (editTemplateFilter) {
      await deleteTemplateFilter(editTemplateFilter.id, () =>
        handleSuccess("Successfully deleted Template Filter"),
      )
    }
  }

  const handleTitleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(evt)
    formik.setFieldValue("key", toCamelCase(evt.target.value))
  }

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    title: Yup.string().required(),
    key: Yup.string()
      .matches(/^\w+$/, "Input must contain only alphanumeric characters")
      .required(),
    sortOrder: Yup.number().integer().optional(),
    active: Yup.boolean().required(),
    collectionId: Yup.number().integer().optional(),
    tagId: Yup.number().integer().optional(),
  })

  const formik = useFormik({
    initialValues: {
      type: editTemplateFilter?.type,
      title: editTemplateFilter?.title,
      key: editTemplateFilter?.key,
      sortOrder: editTemplateFilter?.sort_order ?? maxSortOrder?.toString() ?? "",
      active: editTemplateFilter?.active || false,
      collectionId: editTemplateFilter?.collection_id ?? "",
      tagId: editTemplateFilter?.tag_id ?? "",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      if (editTemplateFilter) {
        await updateTemplateFilter(
          editTemplateFilter.id,
          {
            type: values.type,
            title: values.title,
            key: values.key,
            sort_order: values.sortOrder,
            active: values.active,
            collection_id: values.type !== "boolean" ? values.collectionId : undefined,
            tag_id: values.type === "boolean" ? values.tagId : undefined,
          },
          () => handleSuccess("Successfully updated Template Filter"),
        )
      } else {
        await createTemplateFilter(
          {
            type: values.type,
            title: values.title,
            key: values.key,
            sort_order: values.sortOrder,
            active: values.active,
            collection_id: values.type !== "boolean" ? values.collectionId : undefined,
            tag_id: values.type === "boolean" ? values.tagId : undefined,
          },
          () => handleSuccess("Successfully created Template Filter"),
        )
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editTemplateFilter
          ? `Update Template Filter ${editTemplateFilter.title} - ${editTemplateFilter.id}`
          : "Create A New Template Filter"
      }
      content={
        <Box width="500px">
          <Row mb="4px">
            {(!!creationError || !!updateError || !!deleteError) && (
              <ErrorComponent error={creationError || updateError || deleteError} />
            )}
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                label="Type"
                fullWidth
                variant="outlined"
                id="type"
                name="type"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.type}
                error={!!formik.errors.type}
                onBlur={(evt) => formik.validateField("type")}
              >
                <MenuItem key={"boolean"} value={"boolean"}>
                  Boolean
                </MenuItem>
                <MenuItem key={"select"} value={"select"}>
                  Select
                </MenuItem>
                <MenuItem key={"multiselect"} value={"multiselect"}>
                  Multi-Select
                </MenuItem>
              </Select>
              <FormHelperText error={!!formik.errors.type}>
                {formik.errors.type?.toString()}
              </FormHelperText>
            </FormControl>
            <TextField
              label="Title"
              fullWidth
              variant="outlined"
              margin="normal"
              id="title"
              name="title"
              type="text"
              onChange={handleTitleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
              helperText={formik.errors.title?.toString()}
              onBlur={(evt) => formik.validateField("title")}
            />
            <TextField
              label="Key"
              fullWidth
              variant="outlined"
              margin="normal"
              id="key"
              name="key"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.key}
              error={!!formik.errors.key}
              helperText={formik.errors.key?.toString()}
              onBlur={(evt) => formik.validateField("key")}
            />
            <TextField
              label="Sort Order"
              fullWidth
              variant="outlined"
              margin="normal"
              id="sortOrder"
              name="sortOrder"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.sortOrder}
              error={!!formik.errors.sortOrder}
              helperText={formik.errors.sortOrder?.toString()}
              onBlur={(evt) => formik.validateField("sortOrder")}
            />
          </Row>
          <Row>
            {(formik.values.type === "select" || formik.values.type === "multiselect") && (
              <FormControl fullWidth>
                <InputLabel htmlFor="collectionId">Collection</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  id="collectionId"
                  name="collectionId"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.collectionId}
                >
                  <MenuItem value="">None</MenuItem>
                  {collections?.map((collection) => (
                    <MenuItem key={collection.id} value={collection.id}>
                      {collection.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!formik.errors.collectionId}>
                  {formik.errors.collectionId?.toString()}
                </FormHelperText>
              </FormControl>
            )}
            {formik.values.type === "boolean" && (
              <FormControl fullWidth>
                <InputLabel htmlFor="tagId">Tag</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  id="tagId"
                  name="tagId"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.tagId}
                >
                  <MenuItem value="">None</MenuItem>
                  {tags?.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      {tag.title}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!formik.errors.tagId}>
                  {formik.errors.tagId?.toString()}
                </FormHelperText>
              </FormControl>
            )}
          </Row>
          <Row>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.active}
                  onChange={(evt) => formik.setFieldValue("active", evt.target.checked)}
                  onBlur={(evt) => formik.validateField("active")}
                />
              }
              label="Active"
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!editTemplateFilter && canDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={creating || updating || deleting}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creating || updating || deleting || !formik.isValid}
          >
            {editTemplateFilter ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default TemplateFilterDialog
