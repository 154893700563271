import React, { useState } from "react"
import { CombinedSubscriptionData, Stripe, Apple } from "../../../types"

import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"

interface Props {
  data: CombinedSubscriptionData
}

const formatDate = (date: string) => {
  return new Date(parseInt(date)).toLocaleString()
}

const CombinedSubscriptionDetails: React.FC<Props> = ({ data }) => {
  const [showJSON, setShowJSON] = useState(false)

  const toggleJSON = () => {
    setShowJSON(!showJSON)
  }
  const currentDate = new Date().getTime()
  return (
    <div>
      <h2>Diagnostics</h2>

      {data.stripe && (
        <div>
          <h3>Stripe Items</h3>
          <BasicTable
            columns={["Item ID", "Plan", "Amount", "Quantity", "Created"]}
            rows={data.stripe.items?.data.map((item) => (
              <BasicTableRow key={item.id}>
                <BasicTableCell>{item.id}</BasicTableCell>
                <BasicTableCell>{item.plan.nickname}</BasicTableCell>
                <BasicTableCell>${(item.price.unit_amount / 100).toFixed(2)}</BasicTableCell>
                <BasicTableCell>{item.quantity}</BasicTableCell>
                <BasicTableCell>{new Date(item.created * 1000).toISOString()}</BasicTableCell>
              </BasicTableRow>
            ))}
          />
        </div>
      )}

      {data.subscription.type === "apple" && (
        <div>
          <h2>Apple User Subscriptions</h2>

          <BasicTable
            columns={[
              "Status",
              "Product ID",
              "Transaction ID",
              "Purchase Date",
              "Expires Date",
              "Trial",
            ]}
            rows={data.apple.receipt.in_app
              .filter((x) => x.original_transaction_id === data.subscription.apple_transaction_id)
              .map((purchase, index) => (
                <BasicTableRow key={index}>
                  <BasicTableCell>
                    {parseInt(purchase.original_purchase_date_ms) < currentDate &&
                    currentDate < parseInt(purchase.expires_date_ms)
                      ? "✅"
                      : "❌"}
                  </BasicTableCell>
                  <BasicTableCell>{purchase.product_id}</BasicTableCell>
                  <BasicTableCell>{purchase.transaction_id}</BasicTableCell>
                  <BasicTableCell>{formatDate(purchase.original_purchase_date_ms)}</BasicTableCell>
                  <BasicTableCell>{formatDate(purchase.expires_date_ms)}</BasicTableCell>
                  <BasicTableCell>{purchase.is_trial_period}</BasicTableCell>
                </BasicTableRow>
              ))}
          />

          <h2>Historical Line Item/Charges</h2>

          <BasicTable
            columns={["Renewal Date", "Product ID", "Transaction ID", "Expires Date", "Trial"]}
            rows={data.apple.latest_receipt_info
              .filter((x) => x.original_transaction_id === data.subscription.apple_transaction_id)
              .map((purchase, index) => (
                <BasicTableRow key={index}>
                  <BasicTableCell>{formatDate(purchase.purchase_date_ms)}</BasicTableCell>
                  <BasicTableCell>{purchase.product_id}</BasicTableCell>
                  <BasicTableCell>{purchase.transaction_id}</BasicTableCell>
                  <BasicTableCell>{formatDate(purchase.expires_date_ms)}</BasicTableCell>
                  <BasicTableCell>{purchase.is_trial_period}</BasicTableCell>
                </BasicTableRow>
              ))}
          />
        </div>
      )}
      <button
        onClick={toggleJSON}
        style={{
          padding: "10px",
          background: "blue",
          color: "white",
          border: "none",
          borderRadius: "5px",
        }}
      >
        {showJSON ? "Hide JSON" : "Show JSON"}
      </button>

      {showJSON && (
        <pre
          style={{
            marginTop: "20px",
            background: "#f0f0f0",
            padding: "20px",
            borderRadius: "5px",
            overflow: "auto",
            maxWidth: "750px",
          }}
        >
          {JSON.stringify(data, null, 2)}
        </pre>
      )}
    </div>
  )
}

export default CombinedSubscriptionDetails
