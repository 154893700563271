import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import StatusLabel from "./StatusLabel"
import type { TektonPipelineRun } from "../../../types"
import { dashToTitle } from "../utils"

type PipelineRunRowProps = {
  pipelineRun: TektonPipelineRun
}

const PipelineRunRow: FunctionComponent<PipelineRunRowProps> = ({ pipelineRun }) => {
  const { status = { conditions: [] } } = pipelineRun
  const { conditions = [] } = status
  const condition = conditions[0]

  return (
    <BasicTableRow>
      <BasicTableCell>
        <Link to={`/tekton/pipelineRuns/${pipelineRun.metadata.name}`}>
          {dashToTitle(pipelineRun.metadata.name)}
        </Link>
      </BasicTableCell>
      <BasicTableCell>
        <StatusLabel status={condition ? condition.reason : "Unknown"} />
      </BasicTableCell>
      <BasicTableCell>{pipelineRun.metadata.creationTimestamp}</BasicTableCell>
    </BasicTableRow>
  )
}

export default PipelineRunRow
