import  { FunctionComponent } from "react"
import { DomainInstruction } from "../../../types"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import DomainInstructionRow from "./DomainInstructionRow"

interface Props {
  instructions: DomainInstruction[]
}

const getRows = (instructions: DomainInstruction[]) => {
  return instructions.map((item, index) => {
    return <DomainInstructionRow key={index} instruction={item} />
  })
}

const columns: string[] = ["ID", "Icon", "Name", "Instructions URL"]

const DomainInstructionsTable: FunctionComponent<Props> = ({ instructions }) => (
  <BasicTable columns={columns} rows={getRows(instructions)} />
)

export default DomainInstructionsTable
