import { ContentCopy } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import  { FunctionComponent, useCallback } from "react"

interface CopyIconProps {
  content: string | unknown | undefined
}

const CopyIcon: FunctionComponent<CopyIconProps> = ({ content }) => {
  const { colors } = useTheme()

  const handleCopyContent = useCallback(async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(content as string)
    } else {
      return document.execCommand("copy", true, content as string)
    }
  }, [content])

  return (
    <IconButton onClick={handleCopyContent} size="small">
      <ContentCopy htmlColor={colors.primary} fontSize="small" />
    </IconButton>
  )
}

export default CopyIcon
