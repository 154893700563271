import { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { OrderDiscount } from "../../../types"
import { formatPrice } from "../../../utils/formatters/format-numbers"

interface DiscountRowProps {
  discount: OrderDiscount
}

const DiscountRow: FunctionComponent<DiscountRowProps> = ({ discount }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {discount.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {discount.code}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatPrice(discount.price)}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default DiscountRow
