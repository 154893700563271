import { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Identity, User } from "../../../types"
import { Button, TextField } from "@mui/material"
import { toast } from "react-hot-toast"
import useResetUserPassword from "../hooks/useResetUserPassword"
import UpdateUserIdentityEmailDialog from "./UpdateUserIdentityEmailDialog"
import useToggle from "../../../hooks/useToggle"
import IdentityReassignmentConfirmation, {
  IdentityReassignmentType,
} from "./IdentityReassignmentConfirmation"
import useUpdateUserIdentity from "../hooks/useUpdateUserIdentity"

type Props = {
  identity: Identity
  user: User
  refreshAction: () => any
}

const UserIdentityRow: FunctionComponent<Props> = ({ identity, user, refreshAction }) => {
  const [reassignIdentityActive, setReassignIdentityActive] = useState(false)
  const [identityUpdating, setIdentityUpdating] = useState<string>()
  const [userId, setUserId] = useState<number>()
  const [identityReassignment, setIdentityReassignment] = useState<
    IdentityReassignmentType | undefined
  >()

  // Identity Reassignment
  const handleIdentityReassignmentSubmit = () => {
    if (!userId) {
      toast.error("Please enter a User ID")
    }
    setIdentityReassignment({ newUserId: userId!, identityId: identity.id })
  }

  const { updateIdentity, error, loading } = useUpdateUserIdentity()

  const reassignIdentity = async () => {
    if (!identityReassignment) {
      return
    }

    try {
      await updateIdentity(
        identityReassignment.identityId,
        identityReassignment.newUserId,
        handleReassignIdentitySuccess,
      )
    } catch (error) {
      toast.error(`Error: ${error}`)
    }
  }

  const handleReassignIdentitySuccess = () => {
    toast.success("Successfully reassigned identity")
    setIdentityReassignment(undefined)
    setReassignIdentityActive(false)
    refreshAction()
  }

  // Edit Login Email
  const {
    isOn: UpdateUserIdentityEmailDialogIsOpen,
    turnOn: openUpdateUserIdentityEmailDialog,
    turnOff: closeUpdateUserIdentityEmailDialog,
  } = useToggle()

  const setIdentityIdToUpdate = (identityId: string) => {
    setIdentityUpdating(identityId)
    openUpdateUserIdentityEmailDialog()
  }

  const handleUpdateUserIdentityEmail = () => {
    closeUpdateUserIdentityEmailDialog()
    refreshAction()
  }

  // Reset password
  const { resetPassword } = useResetUserPassword()

  const resetUserPassword = async (identityId: number) => {
    try {
      await resetPassword(user.id, identityId)
      toast.success("Successfully sent password reset email")
    } catch (error) {
      toast.error(`Error: ${error}`)
    }
  }

  return (
    <BasicTableRow key={identity.id}>
      <BasicTableCell component="th" scope="row">
        {identity.provider}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {identity.provider_user_id} <br /> {identity.email}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {identity.provider_user_id.startsWith("auth0") && !reassignIdentityActive ? (
          <>
            <Button
              style={{
                alignSelf: "center",
              }}
              onClick={() => setIdentityIdToUpdate(identity.provider_user_id)}
            >
              Edit Login Email
            </Button>
            <Button
              style={{
                alignSelf: "center",
              }}
              onClick={() => resetUserPassword(identity.id)}
            >
              Send Password Reset
            </Button>
          </>
        ) : null}
        {reassignIdentityActive ? (
          <>
            <TextField
              margin="dense"
              id="template"
              label="User ID to move to"
              variant="outlined"
              value={userId}
              onChange={(x) => setUserId(Number(x.target.value))}
            />
            <Button
              style={{
                alignSelf: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
              onClick={handleIdentityReassignmentSubmit}
              color="success"
              variant="contained"
              disabled={!userId}
            >
              Submit
            </Button>
          </>
        ) : null}
        <Button
          style={{
            alignSelf: "center",
          }}
          onClick={
            reassignIdentityActive
              ? () => setReassignIdentityActive(false)
              : () => setReassignIdentityActive(true)
          }
          color={reassignIdentityActive ? "error" : "primary"}
          variant={reassignIdentityActive ? "contained" : "text"}
        >
          {reassignIdentityActive ? "Cancel" : "Reassign Identity"}
        </Button>
      </BasicTableCell>
      <UpdateUserIdentityEmailDialog
        identityId={identityUpdating!}
        close={handleUpdateUserIdentityEmail}
        isOpen={UpdateUserIdentityEmailDialogIsOpen}
      />
      {identityReassignment && (
        <IdentityReassignmentConfirmation
          isOpen={!!identityReassignment}
          close={() => setIdentityReassignment(undefined)}
          handleConfirmation={reassignIdentity}
          reassignment={identityReassignment}
          userId={identity.user_id}
          hasError={!!error}
          loading={!!loading}
        />
      )}
    </BasicTableRow>
  )
}

export default UserIdentityRow
