export const AIIMAGEGENERATIONSTYLES = "/api/internal/admin/ai/image-generation-styles"
export const AISUGGESTIONS = "/api/internal/admin/ai/suggestions"
export const ARCHETYPES = "/api/internal/admin/archetypes"
export const ARCHETYPESV4 = "/api/internal/v4/archetypes"
export const ASSETS = "/api/internal/v1/assets"
export const BILLINGPRODUCTSV4 = "/api/internal/admin/billing/products"
export const BLOCKEDDOMAINPHRASES = "/api/internal/v3/domains/blocked-phrases"
export const BLOCKS = "/api/internal/admin/blocks"
export const BLOCKTEMPLATES = "/api/internal/admin/block-templates"
export const BUGSV4 = "/api/internal/v4/bugs"
export const CATEGORIESV5 = "/api/internal/v5/categories"
export const CODESNIPPETS = "/api/internal/admin/code-snippets"
export const COLLECTIONS = "/api/internal/admin/collections"
export const CONVERSATIONLOGS = "/api/internal/admin/ai/logs"
export const CONVERSATIONS = "/api/internal/admin/ai/conversations"
export const COUPONS = "/api/internal/v4/coupons"
export const D2 = "/api/internal/v4/d2"
export const DEADLETTER = "/api/internal/v1/dead-letter-events"
export const DEPLOYMENTS_LABELS = "/api/internal/v4/k8s/deployments/labels"
export const DOMAIN_INSTRUCTIONS = "/api/internal/v3/domains/instructions"
export const DOMAINS = "/api/internal/v3/domains"
export const DOMAINTLDPRICING = "/api/internal/admin/domain-tld-pricing"
export const EMAILFORWARDSV3 = "/api/internal/v3/email-forwards"
export const EMAILPARSER = "/api/internal/v4/ai/parse-email"
export const EMPLOYEES = "/api/internal/v3/employees"
export const ENTITLEMENTS = "/api/internal/admin/entitlements"
export const EVENTS = "/api/internal/v1/events"
export const EXPERIMENT = "/api/internal/v1/experiments"
export const FAFOV1 = "/api/internal/v1/fafo"
export const GITHUB = "/api/internal/v4/github"
export const IDENTITIESV1 = "/api/internal/v1/identities"
export const MERCHANTSV1 = "/api/internal/v1/merchants"
export const MERCHANTSV3 = "/api/internal/v3/merchants"
export const NO_CONTEXT = "/api/internal/v1/yeeter-gan-images"
export const NOTES = "/api/internal/v4/notes"
export const ORDERSV1 = "/api/internal/v1/orders"
export const ORDERSV3 = "/api/internal/v3/orders"
export const PAGESECTIONS = "/api/internal/admin/page-section-types"
export const PAGETYPESV5 = "/api/internal/v5/page-types"
export const PAYMENTINTEGRATIONS = "/api/internal/v1/payment-integrations"
export const PLANSV4 = "/api/internal/v4/plans"
export const PRODUCTV4 = "/api/internal/v4/products"
export const PROMOSV1 = "/api/internal/v1/promos"
export const REMOTE_VALUE = "/api/internal/v1/default-values"
export const RENDERUPDATE = "/api/internal/v1/content/update"
export const REQUESTS = "/api/internal/v1/logs/requests"
export const SITEPRODUCTSV3 = "/api/internal/v3/products"
export const SITEPRODUCTSV4 = "/api/internal/v4/products"
export const SITESV1 = "/api/internal/v1/sites"
export const SITESV3 = "/api/internal/v3/sites"
export const SITESV4 = "/api/internal/v4/sites"
export const SITESV5 = "/api/internal/v5/sites"
export const SUBDOMAINS = "/api/internal/v3/subdomains"
export const SUBSCRIPTIONSV1 = "/api/internal/v1/subscriptions"
export const SUBSCRIPTIONSV3 = "/api/internal/v3/subscriptions"
export const SUBSCRIPTIONSV4 = "/api/internal/v4/subscriptions"
export const TAGSV4 = "/api/internal/v4/tags"
export const TEKTON_PIPELINERUNSV4 = "/api/internal/v4/k8s/tekton/pipelineruns"
export const TEKTON_PIPELINESV4 = "/api/internal/v4/k8s/tekton/pipelines"
export const TEMPLATEFILTERS = "/api/internal/admin/template-filters"
export const TEMPLATES = "/api/internal/admin/templates"
export const TEMPLATESV4 = "/api/internal/v4/templates"
export const TEXTGENERATIONACTIONSV4 = "/api/internal/v4/text-generation-actions"
export const THEMES = "/api/internal/v4/themes"
export const TRANSACTIONSV3 = "/api/internal/v3/transactions"
export const USERGROUPS = "/api/internal/v1/users/groups"
export const USERS = "/api/internal/v3/users"
export const USERSV1 = "/api/internal/v1/users"
export const USERSV4 = "/api/internal/v4/users"
export const WEBV4 = "/api/internal/v4/web"
