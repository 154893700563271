import { DOMAINTLDPRICING } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DomainTldPricing, CreateDomainTldPricing } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateDomainTldPricing = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      body: CreateDomainTldPricing,
      onSuccess?: Function,
    ): Promise<DomainTldPricing> => {
      const res = makeRequest<DomainTldPricing>(
        `${DOMAINTLDPRICING}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { createDomainTldPricing: performAction, loading, error }
}

export default useCreateDomainTldPricing
