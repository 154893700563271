import { useCallback, useEffect, useState } from "react"
import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Site } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useExploreSites = (defaultRowsPerPage = 50) => {

  const [siteSearch, setSiteSearch] = useState({})
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, search: object): Promise<PagedResponse<Site>> => {
    const query = (Object.entries(search) || [])
      .map(([k, v]) => (v ? `${k}=${encodeURIComponent(v)}` : null))
      .filter(Boolean)
      .join("&")
    return makeRequest<PagedResponse<Site>>(
      `${SITESV4}/explore?${query}&limit=${rowsPerPage}&offset=${rowsPerPage * page}`,
      {},
      { handleUnauthenticated },
    )
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    performAction(siteSearch)
    // eslint-disable-next-line
  }, [page, rowsPerPage, siteSearch])

  const exploreSites = useCallback(
    async (search: object) => {
      setSiteSearch(search)
      return await performAction(search)
    },
    [performAction],
  )

  return {
    loading,
    error,
    exploreSites,
    sites: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useExploreSites
