import { PROMOSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Promo } from "../../../types"
import makeRequest from "../../../utils/make-request"
import useGetPromoRedemptions from "./useGetPromoRedemptions"

const useGetPromoById = () => {
  const {
    loading: loadingRedemptions,
    error: redemptionsError,
    getPromoRedemptions,
  } = useGetPromoRedemptions()

  const { data, loading: loadingPromo, error: promoError, performAction } = useHandleRequest(async (handleUnauthenticated, promoId: number): Promise<Promo> => {
    const results = await makeRequest<Promo>(`${PROMOSV1}/${promoId}`, {}, { handleUnauthenticated })

    const redemptions = await getPromoRedemptions(results?.id)

    return {
      ...results,
      redemptions,
    }
  })

  return {
    loading: loadingRedemptions || loadingPromo,
    error: promoError || redemptionsError,
    getPromoById: performAction,
    promo: data,
  }
}

export default useGetPromoById
