import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Archetype } from "../../../types"
import ArchetypeTableRow from "./ArchetypeTableRow"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

interface Props {
  archetypes: Archetype[]
  paginationData: PaginationData
  getArchetypes: () => any
}

const ArchetypesTable: FunctionComponent<Props> = ({
  archetypes,
  paginationData,
  getArchetypes,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.archetype.update)

  const columns = canEdit
    ? ["id", "Name", "Display Name", "Action"]
    : ["id", "Name", "Display Name"]

  const getRows = useCallback(() => {
    return archetypes.map((archetype: Archetype) => (
      <ArchetypeTableRow key={archetype.id} archetype={archetype} getArchetypes={getArchetypes} />
    ))
    // eslint-disable-next-line
  }, [archetypes, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default ArchetypesTable
