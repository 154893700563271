import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { AIImageGenerationStyle } from "../../../types"
import AIImageGenerationStyleTableRow from "./AIImageGenerationStyleTableRow"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

interface Props {
  aiImageGenerationStyles: AIImageGenerationStyle[]
  paginationData: PaginationData
  getAIImageGenerationStyles: () => any
}

const AIImageGenerationStylesTable: FunctionComponent<Props> = ({
  aiImageGenerationStyles,
  paginationData,
  getAIImageGenerationStyles,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.ai.imageGenerationStyle.update)

  const columns = canEdit
    ? ["id", "Icon Url", "Title", "Prompt", "Action"]
    : ["id", "Icon Url", "Title", "Prompt"]

  const getRows = useCallback(() => {
    return aiImageGenerationStyles.map((aiImageGenerationStyle: AIImageGenerationStyle) => (
      <AIImageGenerationStyleTableRow
        key={aiImageGenerationStyle.id}
        aiImageGenerationStyle={aiImageGenerationStyle}
        getAIImageGenerationStyles={getAIImageGenerationStyles}
      />
    ))
    // eslint-disable-next-line
  }, [aiImageGenerationStyles, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default AIImageGenerationStylesTable
