import { Merchant } from "../../../types"
import { Box, Button } from "@mui/material"
import WarningIcon from "../../../components/svg/Warning.svg"
import { FunctionComponent } from "react"

interface Props {
  merchant: Merchant
  reason: string
}

const MerchantRejectedBanner: FunctionComponent<Props> = ({ merchant, reason }) => {
  if (!merchant || !reason) {
    return null
  }

  const handleViewStripe = () => {
    window.open(`https://dashboard.stripe.com/connect/accounts/${merchant.stripe_account_id}`)
  }

  let reasonText = ""
  const reasonParts = reason.split(".")

  if (reason.includes("rejected")) {
    reasonText = `This account was rejected for: ${reasonParts[1]}`
  }

  if (reason.includes("requirements")) {
    reasonText = `This account needs more information. (${reasonParts[1]})`
  }

  if (reason === "under_review") {
    reasonText = "This account is currently under review by Stripe"
  }

  if (reason === "platform_paused") {
    reasonText = "This account was paused by Universe"
  }

  if (reasonText === "") {
    return null
  }

  return (
    <Box
      style={{
        borderRadius: "8px",
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.12)",
        backgroundColor: "white",
        marginTop: "12px",
        marginBottom: "12px",
      }}
    >
      <Box
        style={{
          backgroundColor: reason.includes("rejected")
            ? "rgba(255, 69, 58, 0.5)"
            : "rgba(0, 68, 255, 0.5)",
          borderBottom: "1px solid lightgray",
          padding: "12px 20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            transform: "translateY(1.07px)",
          }}
        >
          <img height={18} src={WarningIcon} alt={"Warning Icon"} />
        </Box>
        <Box style={{ flex: "1 1 0px", marginLeft: 10 }}>
          <span style={{ marginTop: "-1px" }}>{reasonText}</span>
        </Box>
        <Box style={{ marginLeft: "12px" }}>
          <Button
            style={{
              color: "rgb(64, 68, 82)",
            }}
            onClick={handleViewStripe}
          >
            View in Stripe
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default MerchantRejectedBanner
