import { FunctionComponent } from "react"
import { useCallback, useEffect, useState } from "react"
import type { SelectChangeEvent } from "@mui/material"
import {
  Input,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  FormControlLabel,
  Select,
  MenuItem,
  Checkbox,
  Stack,
} from "@mui/material"
import RelativeDate from "../../../components/shared/RelativeDate"
import Tags from "../../../components/shared/Tags"
import PRNumber from "../../../components/shared/PRNumber"
import Commit from "../../../components/shared/Commit"
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner"
import type { TektonPipelineParam } from "../../../types"
import { web, services, vercel } from "../../../constants/services"
import { environments, vercelEnvironments } from "../../../constants/environments"
import { images } from "../../../constants/images"
import useGetTags from "../hooks/useGetTags"
import useGetBranches from "../hooks/useGetBranches"
import useGetPullRequests from "../hooks/useGetPullRequests"
import useGetDeploymentsLabels from "../hooks/useGetDeploymentsLabels"
import { useAuth } from "../../../services/auth-service"
import { Box } from "@mui/system"

type ParamSelectProps = {
  param: TektonPipelineParam
  onChange: (value: any) => void
  label: string
  value?: any
}

const ALL = [...services, ...web].join(" ")

export const ReleaseInput: FunctionComponent<ParamSelectProps> = ({ value, onChange }) => {
  useEffect(() => {
    if (!value || value === "") {
      setTimeout(
        () => onChange(`release-${new Date().toISOString().replace(":", "").substring(0, 15)}`),
        0,
      )
    }
  }, [value, onChange])

  return (
    <Input autoFocus={true} type="text" value={value} onChange={(e) => onChange(e.target.value)} />
  )
}

export const DeployedByReadonlyInput: FunctionComponent<ParamSelectProps> = ({
  value,
  onChange,
}) => {
  const { getUser } = useAuth()
  const employee = getUser()

  useEffect(() => {
    if (!value) return
    const name = employee?.employee?.name
      .replace("á", "a")
      .replace("é", "e")
      .replace("í", "i")
      .replace("ó", "o")
      .replace("ú", "u")
      .replace("ń", "n")
      // eslint-disable-next-line
      .replace(/[^\x00-\x7F]/g, "")
      .replace(/ /g, "-")
      .toLowerCase()
    setTimeout(() => onChange(name), 0)
    // eslint-disable-next-line
  }, [value, onChange])

  return <Input type="text" value={value} readOnly />
}

export const ServicesParamSelect: FunctionComponent<ParamSelectProps> = ({ value, onChange }) => {
  const { performAction, data, error, loading } = useGetDeploymentsLabels()

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <Box mt={5}>
        <LoadingSpinner />
      </Box>
    )
  }

  if (error) {
    return (
      <Box mt={5} style={{ color: "white", backgroundColor: "#da0000", padding: "0.25rem 0.5rem" }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Box>
    )
  }

  if (data === null) {
    return (
      <Box mt={5} style={{ color: "white", backgroundColor: "#da0000", padding: "0.25rem 0.5rem" }}>
        <pre>data is null</pre>
      </Box>
    )
  }

  return (
    <Box mt={4}>
      <FormControlLabel
        control={<Checkbox checked={value === ALL} onChange={(_) => onChange(ALL)} />}
        label="All?"
      />

      <TableContainer style={{ opacity: value === ALL ? 0.5 : 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>Commit</TableCell>
              <TableCell>PR</TableCell>
              <TableCell>Release</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Deployed</TableCell>
              <TableCell>Selected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={8} align="center">
                <b>Web</b>
              </TableCell>
            </TableRow>
            {web.map((service) => (
              <TableRow key={service}>
                <TableCell>
                  <code>{service}</code>
                </TableCell>
                <TableCell>
                  <Commit commit={data[service]["commit"]} />
                </TableCell>
                <TableCell>
                  <PRNumber prnumber={data[service]["prNumber"]} branch={data[service]["branch"]} />
                </TableCell>
                <TableCell>
                  <Tags tags={data[service]["release"] || ""} />
                </TableCell>
                <TableCell>
                  <code>{data[service]["lastDeployedBy"]}</code>
                </TableCell>
                <TableCell>
                  <RelativeDate milliseconds={parseInt(data[service]["date"], 10) * 1000} />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={value === ALL || value.includes(service)}
                    onChange={(_) => onChange(service)}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={8} align="center">
                <b>Backend</b>
              </TableCell>
            </TableRow>
            {services.map((service) => (
              <TableRow key={service}>
                <TableCell>
                  <code>{service}</code>
                </TableCell>
                <TableCell>
                  <Commit commit={data[service]["commit"]} />
                </TableCell>
                <TableCell>
                  <PRNumber prnumber={data[service]["prNumber"]} branch={data[service]["branch"]} />
                </TableCell>
                <TableCell>
                  <Tags tags={data[service]["release"] || ""} />
                </TableCell>
                <TableCell>
                  <code>{data[service]["lastDeployedBy"]}</code>
                </TableCell>
                <TableCell>
                  <RelativeDate milliseconds={parseInt(data[service]["date"], 10) * 1000} />
                </TableCell>
                <TableCell>
                  <Checkbox checked={value.includes(service)} onChange={(_) => onChange(service)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

// We handle prnumbers differently to make sure that Pipelines that are run from a specific PR
// use the correct PR branch.
type PullRequestsParamSelectProps = Omit<ParamSelectProps, "onChange"> & {
  onChange: (prnumber: string | number, revision?: string) => void
}

export const PullRequestsParamSelect: FunctionComponent<PullRequestsParamSelectProps> = ({
  onChange,
  ...rest
}) => {
  const { performAction, data, error, loading } = useGetPullRequests()
  const handleChange = useCallback(
    (value: any) => {
      if (value === "0") {
        onChange("0")
        return
      }
      const prnumber = data?.find((pr) => pr.number === value)
      if (!prnumber) {
        console.error("PR not found for value", value)
        return
      }
      onChange(prnumber.number, prnumber.sha)
    },
    [onChange, data],
  )

  const props: ParamSelectProps = { ...rest, onChange: handleChange }

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ParamSelect {...props}>
        <MenuItem value={"0"}>{`0: No PR`}</MenuItem>
        <MenuItem value="">
          <LoadingSpinner size="xs" />
        </MenuItem>
      </ParamSelect>
    )
  }

  if (error) {
    return (
      <ParamSelect {...props}>
        <MenuItem value={"0"}>{`0: No PR`}</MenuItem>
        <MenuItem value="">
          <LoadingSpinner size="xs" />
        </MenuItem>
      </ParamSelect>
    )
  }

  return (
    <ParamSelect {...props}>
      <MenuItem value={"0"}>{`0: No PR`}</MenuItem>
      {data?.map((pr) => (
        <MenuItem key={pr.number} value={pr.number}>
          {`${pr.number}: ${pr.title} (${pr.sha.slice(0, 7)})`}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

const REVISION_TYPES = ["Branch", "Tag"]

export const RevisionParamSelect: FunctionComponent<ParamSelectProps> = ({ value, ...props }) => {
  const [revisionType, setRevisionType] = useState<string>("Branch")
  const handleChange = useCallback(
    (e: SelectChangeEvent<any>) => {
      setRevisionType(e.target.value)
    },
    [setRevisionType],
  )

  return (
    <Stack direction="row" spacing={1} width="100%">
      <Select
        id="revision-type"
        aria-describedby="Revision Type"
        value={revisionType}
        onChange={handleChange}
        variant="standard"
        style={{ width: "100%" }}
      >
        {REVISION_TYPES.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
      {revisionType === "Branch" ? (
        <BranchesParamSelect {...props} value={value} />
      ) : (
        <TagsParamSelect {...props} value={value} />
      )}
    </Stack>
  )
}

export const TagsParamSelect: FunctionComponent<ParamSelectProps> = ({
  value,
  ...props
}: ParamSelectProps) => {
  const { performAction, data, error, loading } = useGetTags()

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ParamSelect {...props}>
        <MenuItem value="">
          <LoadingSpinner size="xs" />
        </MenuItem>
      </ParamSelect>
    )
  }

  if (error) {
    return (
      <ParamSelect {...props}>
        <MenuItem value="">
          <LoadingSpinner size="xs" />
        </MenuItem>
      </ParamSelect>
    )
  }

  return (
    <ParamSelect {...props} value={value}>
      {data?.map((tag) => (
        <MenuItem key={tag.name} value={tag.sha}>
          {`${tag.name} (${tag.sha.slice(0, 7)})`}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const BranchesParamSelect: FunctionComponent<ParamSelectProps> = ({
  value,
  ...props
}: ParamSelectProps) => {
  const { performAction, data, error, loading } = useGetBranches()

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ParamSelect {...props}>
        <MenuItem value="">
          <LoadingSpinner size="xs" />
        </MenuItem>
      </ParamSelect>
    )
  }

  if (error) {
    return (
      <ParamSelect {...props}>
        <MenuItem value="">
          <LoadingSpinner size="xs" />
        </MenuItem>
      </ParamSelect>
    )
  }

  if (value === "development") {
    value = data.find((branch) => branch.name === "development")?.sha
  }

  return (
    <ParamSelect {...props} value={value}>
      {data?.map((branch) => (
        <MenuItem key={branch.name} value={branch.sha}>
          {`${branch.name} (${branch.sha.slice(0, 7)})`}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const VercelServiceParamSelect: FunctionComponent<ParamSelectProps> = (props) => {
  return (
    <ParamSelect {...props}>
      {[...vercel].map((service) => (
        <MenuItem key={service} value={service}>
          {service}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const ServiceParamSelect: FunctionComponent<ParamSelectProps> = (props) => {
  return (
    <ParamSelect {...props}>
      {[...web, ...services].map((service) => (
        <MenuItem key={service} value={service}>
          {service}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const ImagesParamSelect: FunctionComponent<ParamSelectProps> = (props) => {
  return (
    <ParamSelect {...props}>
      {images.map((env) => (
        <MenuItem key={env} value={env}>
          {env}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const VercelEnvironmentsParamSelect: FunctionComponent<ParamSelectProps> = (props) => {
  return (
    <ParamSelect {...props}>
      {vercelEnvironments.map((env) => (
        <MenuItem key={env} value={env}>
          {env}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const EnvironmentsParamSelect: FunctionComponent<ParamSelectProps> = (props) => {
  return (
    <ParamSelect {...props}>
      {environments.map((env) => (
        <MenuItem key={env} value={env}>
          {env}
        </MenuItem>
      ))}
    </ParamSelect>
  )
}

export const ParamSelect: FunctionComponent<ParamSelectProps & { children: React.ReactNode }> = ({
  label,
  param,
  onChange,
  value,
  children,
}) => {
  const handleChange = useCallback(
    (e: SelectChangeEvent<any>) => {
      onChange(e.target.value)
    },
    [onChange],
  )

  return (
    <Select
      id={param.name}
      aria-describedby={param.description}
      value={value || ""}
      onChange={handleChange}
      variant="standard"
      style={{ width: "100%" }}
      autoFocus={true}
    >
      <MenuItem value="">
        <em>{label}</em>
      </MenuItem>
      {children}
    </Select>
  )
}
