import { Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import  { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { CreateBillingProduct } from "../../../types"

type BillingProductProps = {
  isOpen: boolean
  close: () => void
  hasError: boolean
  creatingProduct: boolean
  handleCreateProduct: (product: CreateBillingProduct, callback: Function) => any
}

const CreateProductDialog: FunctionComponent<BillingProductProps> = ({
  isOpen,
  close,
  hasError,
  creatingProduct,
  handleCreateProduct,
}) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const item: CreateBillingProduct = {
        ...values,
      }

      await handleCreateProduct(item, handleClose)
    },
  })

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Create New Product"
      content={
        <Box width="500px">
          <Row height="12px" mb="15px">
            {hasError && (
              <TextBody hasError={hasError}>There was a problem creating the product.</TextBody>
            )}
          </Row>
          <Row mb={"17px"}>
            <TextField
              id="title"
              label="Title"
              type="text"
              variant="outlined"
              required
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
            />
          </Row>
          <Row>
            <TextField
              id="description"
              label="Description"
              fullWidth
              required
              type="text"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={!!formik.errors.description}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creatingProduct}
          >
            Create
          </Button>
        </>
      }
    />
  )
}
export default CreateProductDialog
