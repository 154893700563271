import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { TextGenerationAction } from "../../../types"
import TextGenerationActionsRow from "./TextGenerationActionsRow"

interface Props {
  texGenerationActions: TextGenerationAction[]
  refreshData: () => any
}

const TextGenerationActionsTable: FunctionComponent<Props> = ({
  texGenerationActions,
  refreshData,
}) => {
  const columns: string[] = ["", "ID", "Title", "Message", "Model", "Actions"]

  const getRows = useCallback(() => {
    return texGenerationActions.map((textGenAction: TextGenerationAction, index: number) => (
      <TextGenerationActionsRow key={index} textGenAction={textGenAction} close={refreshData} />
    ))
  }, [texGenerationActions, refreshData])

  return <BasicTable columns={columns} rows={getRows()} />
}

export default TextGenerationActionsTable
