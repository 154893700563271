import { useCallback } from "react"
import { EXPERIMENT } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import {
  Experiment,
  PostExperiment,
  PostVariant,
  PutVariantValue,
  Variant,
  VariantValue,
} from "../../../types"
import { PostVariantValue } from "../../../types/experiments"
import makeRequest from "../../../utils/make-request"
import useCreateExperiment from "./useCreateExperiment"

const useUpdateExperiment = () => {
  const { createVariant, createVariantValue } = useCreateExperiment()

  const { loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, experiment: Experiment, ignoreVariants: boolean, onSuccess?: Function): Promise<Experiment> => {
    const putVariant = (variant: PostVariant, variantId: number, experimentId: number): PromiseLike<Variant> => {
      return makeRequest<Variant>(
        `${EXPERIMENT}/${experimentId}/variants/${variantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(variant),
        },
        { handleUnauthenticated },
      )
    }

    const putVariantValue = (
      variantValue: PutVariantValue,
      experimentId: number,
      variantId: number,
      valueId: number,
    ): PromiseLike<Variant> => {
      return makeRequest<Variant>(
        `${EXPERIMENT}/${experimentId}/variants/${variantId}/values/${valueId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(variantValue),
        },
        { handleUnauthenticated },
      )
    }

    const processVariantValues = async (
      variantValues: VariantValue[],
      experimentId: number,
      variantId: number,
    ) => {
      for (let i = 0; i < variantValues.length; i++) {
        if (variantValues[i].hasOwnProperty("remoteValueId")) {
          if (!!variantValues[i].id) {
            const item: PutVariantValue = {
              value: variantValues[i].value,
            }
            await putVariantValue(item, experimentId, variantId, variantValues[i].id as number)
          } else {
            const item: PostVariantValue = {
              value: variantValues[i].value,
              remote_value_id: variantValues[i].remoteValueId as number,
            }
            await createVariantValue(item, experimentId, variantId)
          }
        }
      }
    }

    const processVariants = async (
      variants: Variant[],
      experimentId: number,
    ) => {
      for (let i = 0; i < variants.length; i++) {
        let item = {
          name: variants[i].name,
          distribution:
            variants[i].distribution !== -1 ? parseFloat(variants[i].distribution as any) : null,
          user_group_id: variants[i].user_group_id !== -1 ? variants[i].user_group_id : null,
        }
        // checking if the id indicates prior existence or manually added and used for editing
        let updatedVariant
        if (variants[i].id && variants[i].id > -1) {
          updatedVariant = await putVariant(item, variants[i].id, experimentId)
        } else {
          updatedVariant = await createVariant(item, experimentId)
        }

        if (!updatedVariant) {
          throw new Error("Variant not created or updated or whatever for some reason")
        }

        const variantValues = variants[i].values
        if (variantValues.length) {
          await processVariantValues(
            variantValues,
            experimentId,
            updatedVariant.id,
          )
        }
      }
    }

    const body: PostExperiment = {
      name: experiment.name,
      description: experiment.description,
      start_date: experiment.start_date,
      end_date: experiment.end_date,
    }

    const updatedExperiment = await makeRequest<Experiment>(
      `${EXPERIMENT}/${experiment.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      { handleUnauthenticated },
    )

    if (experiment.variants?.length && !ignoreVariants) {
      await processVariants(experiment.variants, updatedExperiment.id)
    }
    if (onSuccess) {
      await onSuccess()
    }

    return updatedExperiment
  })

  const updateExperiment = useCallback(
    async (
      experiment: Experiment,
      onSuccess: Function,
      // existingExperiment: Experiment,
      ignoreVariants: boolean = false,
    ) => {
      return performAction(experiment, ignoreVariants, onSuccess)
    },
    [performAction],
  )

  const startExperiment = useCallback(
    async (experiment: Experiment, onSuccess: Function) => {
      const startDate = new Date()
      const body = { ...experiment, start_date: startDate }
      return updateExperiment(body, onSuccess, true)
    },
    [updateExperiment],
  )

  const endExperiment = useCallback(
    async (experiment: Experiment, onSuccess: Function) => {
      const endDate = new Date()
      const body = { ...experiment, end_date: endDate }
      return updateExperiment(body, onSuccess, true)
    },
    [updateExperiment],
  )

  return { startExperiment, endExperiment, updateExperiment, loading, error }
}

export default useUpdateExperiment
