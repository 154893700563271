import { FunctionComponent } from "react"
import { Box } from "@mui/material"
import ErrorComponent from "../../../components/shared/Error"
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner"
import useGetPipelineRunLogsById from "../hooks/useGetPipelineRunLogsById"
import { default as AnsiUp } from "ansi_up"

type TektonPipelineRunLogsLogs = {
  pipelineRunId: string
  status?: string
}

const ansi_up = new AnsiUp()

const TektonPipelineRunLogs: FunctionComponent<TektonPipelineRunLogsLogs> = ({
  pipelineRunId,
  status,
}) => {
  const { data = [], error, loading } = useGetPipelineRunLogsById(pipelineRunId, status)

  if (loading && data.length === 0) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data) {
    return null
  }

  return (
    <Box
      style={{
        fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
      }}
      dangerouslySetInnerHTML={{
        __html: data.map(({ message }) => ansi_up.ansi_to_html(message)).join("<br/>"),
      }}
    />
  )
}

export default TektonPipelineRunLogs
