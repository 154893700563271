import { useEffect, useState } from "react"
import { COUPONS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Coupon } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetCoupons = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction: getCoupons } = useHandleRequest(async (handleUnauthenticated): Promise<PagedResponse<Coupon>> => {
    let queryString = `${COUPONS}?limit=${encodeURIComponent(
      rowsPerPage,
    )}&offset=${encodeURIComponent(page * rowsPerPage)}`
    return makeRequest<PagedResponse<Coupon>>(queryString, {}, { handleUnauthenticated })
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getCoupons()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    getCoupons,
    loading,
    error,
    coupons: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetCoupons
