import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { SiteViews } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSiteViews = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, siteId: number, startDate: Date, endDate: Date): Promise<SiteViews> => {
      return makeRequest<SiteViews>(
        `${SITESV4}/${siteId}/analytics/views?startDate=${startDate}&endDate=${endDate}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getSiteViews: performAction, views: data }
}

export default useGetSiteViews
