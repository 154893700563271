import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
} from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { Plan, UpdatePlanRequest } from "../../../types"

const editPlanSchema = Yup.object().shape({
  title: Yup.string().optional(),
  description: Yup.string().optional(),
  active: Yup.boolean().optional(),
  price: Yup.object()
    .optional()
    .shape({ amount: Yup.number().optional(), currency: Yup.string().optional() }),
  variant: Yup.string().optional(),
  duration: Yup.string().optional(),
})

type PlanProps = {
  plan: Plan
  isOpen: boolean
  close: () => void
  handleEditPlan: (plan: UpdatePlanRequest, callback: () => any) => any
  hasError: boolean
  isEditing: boolean
}

const EditPlanDialog: FunctionComponent<PlanProps> = ({
  plan,
  isOpen,
  close,
  handleEditPlan,
  hasError,
  isEditing,
}) => {
  const formik = useFormik({
    initialValues: {
      title: plan.title || "",
      description: plan.description || "",
      active: plan.active,
      price: plan.price,
      variant: plan.variant,
      duration: plan.duration,
    },
    validationSchema: editPlanSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: UpdatePlanRequest) => {
      handleEditPlan(values, handleClose)
    },
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleDomainChecked = async (event: any) => {
    formik.setFieldValue("domain_enabled", event.target.checked)
  }

  return (
    <Dialog
      title={`Edit Plan ${plan.version}.${plan.group}.${plan.variant}.${plan.duration}`}
      isOpen={isOpen}
      handleClose={close}
      content={
        <Box width="400px">
          <Row height="8px" mb="4px">
            {!!hasError && (
              <TextBody hasError={!!hasError}>There was a problem updating the plan.</TextBody>
            )}
          </Row>
          <TextField
            label="Title"
            fullWidth
            variant="outlined"
            margin="normal"
            id="title"
            name="title"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <TextField
            label="Description"
            fullWidth
            variant="outlined"
            margin="normal"
            id="description"
            name="description"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.description}
          />

          <InputLabel>Active</InputLabel>
          <Checkbox
            id="active"
            name="active"
            checked={formik.values.active}
            onChange={formik.handleChange}
            value={formik.values.active}
          />

          <TextField
            label="Amount"
            fullWidth
            variant="outlined"
            margin="normal"
            id="price-amount"
            name="price.amount"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.price?.amount}
          />

          <TextField
            label="Currency"
            fullWidth
            variant="outlined"
            margin="normal"
            id="price-currency"
            name="price.currency"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.price?.currency}
          />

          <TextField
            label="Variant"
            fullWidth
            variant="outlined"
            margin="normal"
            id="variant"
            name="variant"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.variant}
          />

          <Select
            fullWidth
            label="Duration"
            name="duration"
            onChange={formik.handleChange}
            value={formik.values.duration}
          >
            {["annual", "monthly", "lifetime"].map((d) => (
              <MenuItem key={d} value={d}>
                {`${d[0].toUpperCase()}${d.slice(1)}`}
              </MenuItem>
            ))}
          </Select>
          <Row>
            <FormControlLabel
              sx={{ ml: "0px" }}
              control={<Checkbox checked={formik.values.domain_enabled} />}
              label="Domain Enabled"
              name="domain_enabled"
              onChange={handleDomainChecked}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={isEditing}
          >
            Update Plan
          </Button>
        </>
      }
    />
  )
}

export default EditPlanDialog
