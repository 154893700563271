import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Domain } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDisableDomainAutoRenew = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, domainId: number, onSuccess?: () => any): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}/${domainId}/auto-renew`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { disableAutoRenew: performAction, loading, error }
}

export default useDisableDomainAutoRenew
