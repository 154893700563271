import { FunctionComponent } from "react"
import { SetupFlow, Template } from "../../../types"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import useEditPageTemplateDialog from "./EditTemplatePageDialog"
import TemplateRow from "./TemplateRow"

interface Props {
  templates: Template[]
  close: () => void
  setupFlows: SetupFlow[]
}

const TemplatesTable: FunctionComponent<Props> = ({ templates, close, setupFlows }) => {
  const { editPageTemplateDialog, openEditPageTemplateDialog } =
    useEditPageTemplateDialog(setupFlows)

  const columns = ["", "ID", "Name", "Description", "Tags", "Actions"]
  const rows = templates.map((template, index) => {
    return (
      <TemplateRow
        key={index}
        template={template}
        close={close}
        onEditPageTemplateClicked={() => openEditPageTemplateDialog(template)}
      />
    )
  })

  return (
    <>
      <BasicTable columns={columns} rows={rows} />
      {editPageTemplateDialog}
    </>
  )
}

export default TemplatesTable
