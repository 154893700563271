import { Box } from "@mui/material"
import { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SiteProductsTable from "./components/SiteProductsTable"
import useGetSiteProducts from "./hooks/useGetSiteProducts"

const SiteProductsScreen: FunctionComponent = () => {
  const { siteId } = useParams<{ siteId: string }>()
  const { loading, error, getProducts, products, paginationData } = useGetSiteProducts()

  useEffect(() => {
    getProducts(Number(siteId))
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer
      title={`Site ${siteId} Products`}
      subtitle="All active products are shown below"
    >
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center", mt: "20px" }}>
        <SiteProductsTable products={products} paginationData={paginationData} />
      </Box>
    </ScreenContainer>
  )
}

export default SiteProductsScreen
