import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { CreatePlanRequest, Plan, UpdatePlanRequest } from "../../types"
import useGetProducts from "../Products/hooks/useGetProducts"
import CreatePlanDialog from "./components/CreatePlanDialog"
import EditPlanDialog from "./components/EditPlanDialog"
import PlansTable from "./components/PlansTable"
import useCreatePlan from "./hooks/useCreatePlan"
import useGetPlans from "./hooks/useGetPlans"
import useUpdatePlan from "./hooks/useUpdatePlan"

// sort legacy after v1,v2,v3, etc
const sortPlans = (a: Plan, b: Plan) => {
  if (a.active && !b.active) return -1
  if (!a.active && b.active) return 1

  // Sort by version, with 'legacy' last
  if (a.version.toLowerCase() === "legacy" && b.version.toLowerCase() !== "legacy") return 1
  if (a.version.toLowerCase() !== "legacy" && b.version.toLowerCase() === "legacy") return -1

  // Sort by group, with 'tld' and 'domain' last
  const groupOrder = ["tld", "domain"]
  const aGroupIndex = groupOrder.indexOf(a.group.toLowerCase())
  const bGroupIndex = groupOrder.indexOf(b.group.toLowerCase())

  if (aGroupIndex === -1 && bGroupIndex !== -1) return -1
  if (aGroupIndex !== -1 && bGroupIndex === -1) return 1
  if (aGroupIndex !== bGroupIndex) return aGroupIndex - bGroupIndex

  return a.title.localeCompare(b.title)
}

const PlansScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.billing.plans.create)
  const canEdit = hasScope(scopes.billing.plan.update)
  const { getPlans, loading: loadingPlans, error: plansError, plans } = useGetPlans(1000)
  const { updatePlan, loading: updatingPlan, error: updatingError } = useUpdatePlan()
  const { createPlan, loading: creatingPlan, error: createError } = useCreatePlan()
  const {
    getProducts,
    loading: loadingProducts,
    error: productsError,
    products,
  } = useGetProducts(1000)

  const { isOn: isCreating, turnOn: openCreate, turnOff: closeCreate } = useToggle()
  const [planForEdit, setPlanForEdit] = useState<Plan>()

  const handleOpenEdit = useCallback((plan: Plan) => {
    setPlanForEdit(plan)
  }, [])

  useEffect(() => {
    getPlans()
    getProducts()

    // eslint-disable-next-line
  }, [])

  const showTable = !loadingPlans && !loadingProducts && plans.length > 0

  const handleCloseEdit = useCallback(() => {
    setPlanForEdit(undefined)
  }, [setPlanForEdit])

  const handleUpdatePlan = useCallback(
    async (item: UpdatePlanRequest, callback: () => any) => {
      const onSuccess = async () => {
        await getPlans()
        setPlanForEdit(undefined)
        callback()
        toast.success("Successfully updated plan")
      }

      if (planForEdit) {
        await updatePlan(planForEdit.id, item, onSuccess)
      }
    },
    [updatePlan, getPlans, planForEdit],
  )

  const handleCreatePlan = useCallback(
    async (item: CreatePlanRequest, callback: () => any) => {
      const onSuccess = async () => {
        await getPlans()
        callback()
        toast.success("Successfully created plan")
      }

      await createPlan(item, onSuccess)
    },
    [getPlans, createPlan],
  )

  return (
    <ScreenContainer title="Plans" requiresScope={scopes.billing.plans.read}>
      {(plansError || productsError) && <ErrorComponent error={plansError || productsError} />}

      {canCreate && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ my: "12px", width: "300px" }}
          onClick={openCreate}
        >
          Create New Plan
        </Button>
      )}
      {(loadingPlans || loadingProducts) && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {showTable && (
        <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
          <PlansTable
            plans={plans.sort(sortPlans)}
            handleOpenEditPlan={handleOpenEdit}
            products={products}
            canEdit={canEdit}
          />
        </Box>
      )}
      <CreatePlanDialog
        isOpen={isCreating}
        close={closeCreate}
        creatingPlan={creatingPlan}
        hasError={!!createError}
        handleCreatePlan={handleCreatePlan}
        products={products}
      />
      {planForEdit && (
        <EditPlanDialog
          plan={planForEdit}
          isOpen={!!planForEdit}
          close={handleCloseEdit}
          handleEditPlan={handleUpdatePlan}
          hasError={!!updatingError}
          isEditing={!!updatingPlan}
        />
      )}
    </ScreenContainer>
  )
}

export default PlansScreen
