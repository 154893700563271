import {
  Box,
  Button,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { Domain, PutDomainStatus } from "../../../types"

interface Props {
  isOpen: boolean
  domain: Domain
  close: () => void
  handleCreate: (domainId: number, body: PutDomainStatus, callback: () => any) => any
  hasError: boolean
  errorMessage: string | false
  loading: boolean
}

const EditDomainStatusDialog: FunctionComponent<Props> = ({
  isOpen,
  domain,
  close,
  handleCreate,
  hasError,
  errorMessage,
  loading,
}) => {
  const validationSchema = Yup.object().shape({
    external: Yup.boolean().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      external: domain.external!,
      status: domain.status!,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await handleCreate(domain.id, values, handleClose)
    },
  })

  return (
    <Dialog
      title={`Edit/Refresh Domain Status`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {hasError && <TextBody hasError={hasError}>{errorMessage}</TextBody>}
          <DialogContentText sx={{ mb: 1 }}>
            You're able to manually update the external state of a domain by using the checkbox
            below along with the <b>Update Domain</b> button.
          </DialogContentText>
          <DialogContentText sx={{ mb: 1 }}>
            Upon clicking the save button, the <b>status</b> and <b>enabled</b> fields will also be
            refreshed on the domain based on domain setup state and billing entitlements. Site URLs
            will also be updated with the latest changes.
          </DialogContentText>
          <DialogContentText sx={{ mb: 1 }}>
            To refresh a domain <i>without making changes</i>, simply click the <b>Update Domain</b>{" "}
            button below and the domain will be refreshed.
          </DialogContentText>
          <Box sx={{ minWidth: 500 }}>
            <FormControlLabel
              control={<Checkbox checked={formik.values.external} />}
              label="External"
              labelPlacement="start"
              name="external"
              onChange={formik.handleChange}
            />
          </Box>
          <Select
            placeholder="Status"
            fullWidth
            name="status"
            variant="outlined"
            id="status"
            onChange={formik.handleChange}
            value={formik.values.status}
            error={!!formik.errors.status}
            sx={{ mt: "9px", mr: "4px" }}
          >
            <MenuItem key={"claimed"} value={"claimed"}>
              claimed
            </MenuItem>
            <MenuItem key={"managed"} value={"managed"}>
              managed
            </MenuItem>
            <MenuItem key={"partially-setup-root"} value={"partially-setup-root"}>
              partially-setup-root
            </MenuItem>
            <MenuItem key={"partially-setup-www"} value={"partially-setup-www"}>
              partially-setup-www
            </MenuItem>
            <MenuItem key={"ready"} value={"ready"}>
              ready
            </MenuItem>
            <MenuItem key={"registered"} value={"registered"}>
              registered
            </MenuItem>
            <MenuItem key={"setup"} value={"setup"}>
              setup
            </MenuItem>
            <MenuItem key={"unconfigured"} value={"unconfigured"}>
              unconfigured
            </MenuItem>
            <MenuItem key={"unregistered"} value={"unregistered"}>
              unregistered
            </MenuItem>
          </Select>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>

          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            Update Domain
          </Button>
        </>
      }
    />
  )
}

export default EditDomainStatusDialog
