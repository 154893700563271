import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { SiteUser } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSiteUsers = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, siteId: number | string): Promise<SiteUser[]> => {
    let queryString = `${SITESV4}/${siteId}/users?deleted=true`
    return makeRequest<SiteUser[]>(queryString, {}, { handleUnauthenticated })
  })

  return { getSiteUsers: performAction, loading, error, siteUsers: data }
}

export default useGetSiteUsers
