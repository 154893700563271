import { Box, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import dayjs from "dayjs"
import { FunctionComponent, useCallback, useEffect } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import CopyIcon from "../../../components/shared/CopyIcon"
import ErrorComponent from "../../../components/shared/Error"
import SubscriptionBadge from "../../../components/shared/SubscriptionBadge"
import { Subhead as Textblock, TextBody } from "../../../components/shared/Typography"
import useToggle from "../../../hooks/useToggle"
import { EmailForward, V5_Site } from "../../../types"
import useListEntitlements from "../../Entitlements/hooks/useListEntitlements"
import MerchantLink from "../../Merchants/components/MerchantLink"
import SiteLink from "./SiteLink"
import ViewEntitlementsDialog from "./ViewEntitlementsDialog"
import ThemeLink from "./ThemeLink"
import useSetupEmailForwardById from "../hooks/useSetupEmailForwardById"
import { toast } from "react-hot-toast"
import { delay } from "lodash"
import useGetSiteViews from "../hooks/useGetSiteViews"
import { sumViews, weeklySumViews } from "../../../utils/siteViews"

export const Row = styled(Box)``
Row.defaultProps = {
  flexWrap: "wrap",
  width: "100%",
  height: "32px",
  display: "flex",
  sx: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}

export const Subhead = styled(Textblock)`
  margin-right: 5px;
`

export const StyledText: any = styled(Subhead)`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
`

interface SiteOverviewProps {
  site: V5_Site
  emailForward?: EmailForward | null
  emailForwardError: any
  themeId?: string
  refreshData: () => void
}

const SiteOverview: FunctionComponent<SiteOverviewProps> = ({
  site,
  emailForward,
  emailForwardError,
  themeId,
  refreshData,
}) => {
  const navigate = useNavigate()

  const {
    listEntitlements,
    loading: loadingEntitlements,
    error: entitlementsError,
    entitlements,
  } = useListEntitlements(1000)

  const { getSiteViews, views, loading: viewsLoading, error: viewsError } = useGetSiteViews()

  const { setupEmailForward, loading, error } = useSetupEmailForwardById()

  const {
    isOn: showingEntitlements,
    toggle: toggleEntitlements,
    turnOff: closeEntitlements,
  } = useToggle()

  const navToDomains = useCallback(() => {
    navigate(`/domains?type=site_id&term=${site.id}`)
  }, [navigate, site])

  const navToOrders = useCallback(() => {
    navigate({
      pathname: "/orders",
      search: createSearchParams({
        term: site.id.toString(),
      }).toString(),
    })
  }, [navigate, site])

  const navToProducts = useCallback(() => {
    navigate(`/sites/${site.id}/products`)
  }, [navigate, site])

  const navToPages = () => {
    navigate(`/sites/${site.id}/pages`)
  }

  const navToSubscribers = () => {
    navigate(`/sites/${site.id}/subscribers`)
  }

  const navToPaymentIntegrations = () => {
    navigate(`/sites/${site.id}/payment-integrations`)
  }

  const handleSetupEmailForwardClicked = async () => {
    if (emailForward && emailForward.id) {
      await setupEmailForward(emailForward.id, handleSetupEmailForwardSuccess)
    } else {
      toast.error("No email forward found")
    }
  }

  const handleSetupEmailForwardSuccess = async () => {
    toast.success(
      "Successfully sent event to setup email forward. Please check back soon for updates.",
      // Show toast for 10 seconds so people can read it
      { duration: 10000 },
    )
    // Delay refreshing until the 10 seconds has passed
    await delay(refreshData, 10000)
  }

  useEffect(() => {
    listEntitlements()

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    getSiteViews(site.id, site.created_at, tomorrow)

    // eslint-disable-next-line
  }, [])

  let creationSource = ""

  switch (site.source) {
    case "iphone:nux":
      creationSource = "iPhone NUX"
      break
    case "iphone:new-site":
      creationSource = "iPhone New Site"
      break
    case "ipad:nux":
      creationSource = "iPad NUX"
      break
    case "ipad:new-site":
      creationSource = "iPad New Site"
      break
    case "ipod-touch:nux":
      creationSource = "iPod NUX"
      break
    case "ipod-touch:new-site":
      creationSource = "iPod New Site"
      break
    case "mac:nux":
      creationSource = "Mac NUX"
      break
    case "mac:new-site":
      creationSource = "Mac New Site"
      break
    case "web:nux:link-in-bio":
      creationSource = "Web App NUX Link in Bio"
      break
    case "web:new-site":
      creationSource = "Web App New Site"
      break
    case "web:new-site:guest":
      creationSource = "Web App New Guest Site"
      break
    case "web:nux:sign-up-first":
      creationSource = "Web App NUX Signup First"
      break
    default:
      // We likely added a new source. Let's just show that instead!
      creationSource = site.source ?? "Unknown"
  }

  const isPro = !!site.entitlements.find((e) => e.id === "pro")
  const isDomainPlus = site.entitlements.find((e) => e.id === "domain") && !isPro
  const isArchived = !!site.deleted_at
  const siteViews = views && sumViews(views)
  const weeklySiteViews = views && weeklySumViews(views)

  return (
    <>
      {!!emailForwardError && <ErrorComponent error={emailForwardError} />}
      <Row>
        <SubscriptionBadge
          text={site.badge.title}
          textColor={site.badge.title_text_color.light_mode}
          backgroundColor={site.badge.background_color.light_mode}
          hoverText={`ID: ${site?.plan?.id || "null"}\nName: ${site?.plan?.name || "null"}\nDuration: ${site?.plan?.duration || "null"}`}
          borderColor={site.badge.border_color.light_mode}
          borderWidth={site.badge.border_width}
        />
        {isArchived && (
          <SubscriptionBadge text="Archived" textColor="white" backgroundColor="red" />
        )}
      </Row>
      <Row>
        <Subhead>Site ID:</Subhead>
        <SiteLink siteId={site.id}>{site.id}</SiteLink>
        <CopyIcon content={site.id} />
      </Row>
      <Row>
        <Subhead>Merchant ID:</Subhead>
        {site.merchant_id ? (
          <MerchantLink merchantId={site.merchant_id}>{site.merchant_id}</MerchantLink>
        ) : (
          "-"
        )}
        {site.merchant_id && <CopyIcon content={site.merchant_id} />}
      </Row>
      {themeId ? (
        <Row>
          <Subhead>Theme ID: </Subhead>
          <ThemeLink themeId={themeId}>{themeId}</ThemeLink>
          <CopyIcon content={themeId} />
        </Row>
      ) : null}
      <Row>
        <Subhead>Source: </Subhead>
        <StyledText>{creationSource}</StyledText>
      </Row>
      <Row>
        <Subhead>Lifetime Views: </Subhead>
        <StyledText>
          {viewsError && <ErrorComponent error={viewsError} />}
          {viewsLoading ? "calculating..." : siteViews}
        </StyledText>

        {!viewsLoading && (
          <>
            <Subhead>Weekly Views: </Subhead>
            <StyledText>{weeklySiteViews}</StyledText>
          </>
        )}
      </Row>
      <Row>
        <Subhead>Category: </Subhead>
        <StyledText>{site.category?.display_name ?? "-"}</StyledText>
      </Row>
      <Row>
        <Subhead>Archetype: </Subhead>
        <StyledText>{site.archetype?.display_name ?? "-"}</StyledText>
      </Row>
      <Row>
        <Subhead>Created: </Subhead>
        <StyledText>{`${dayjs(site.created_at).format("MM/DD/YYYY") || " "}`}</StyledText>
      </Row>
      <Row>
        <Subhead>Updated: </Subhead>
        <StyledText> {`${dayjs(site.last_updated_at).format("MM/DD/YYYY") || " "}`}</StyledText>
      </Row>
      {site.shadow_banned_at ? (
        <Row>
          <Subhead>Shadowbanned: </Subhead>
          <StyledText> {`${dayjs(site.shadow_banned_at).format("MM/DD/YYYY") || " "}`}</StyledText>
        </Row>
      ) : null}
      {emailForward && (
        <Row height="auto">
          <Subhead>Email Forward: </Subhead>
          <TextBody>
            {emailForward.email_address} 👉 {emailForward.forward_address}
          </TextBody>
          {emailForward.is_added && emailForward.is_verified && emailForward.is_ready ? null : (
            <>
              <StyledText>🚨 Email forward is not verified and/or setup in Mailgun 🚨</StyledText>
              <Button
                color="warning"
                variant="outlined"
                onClick={handleSetupEmailForwardClicked}
                disabled={loading}
              >
                Setup Email Forward
              </Button>
              {error ? (
                <>
                  <StyledText>Error setting up email forward: </StyledText>
                  <ErrorComponent error={error} />
                </>
              ) : null}
            </>
          )}
        </Row>
      )}
      <Row mt="12px">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={toggleEntitlements}
          sx={{ maxWidth: "300px" }}
        >
          View Entitlements
        </Button>
      </Row>
      <Row mt="12px">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={navToDomains}
          sx={{ maxWidth: "300px" }}
        >
          View Domains
        </Button>
      </Row>
      <Row mt="12px">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={navToOrders}
          sx={{ maxWidth: "300px" }}
        >
          View Orders
        </Button>
      </Row>
      <Row mt="12px">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={navToProducts}
          sx={{ maxWidth: "300px" }}
        >
          View Products
        </Button>
      </Row>
      <Row mt="12px">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={navToPages}
          sx={{ maxWidth: "300px" }}
        >
          View Pages
        </Button>
      </Row>
      <Row mt="12px">
        <Button
          sx={{ width: "303px", my: "3px" }}
          variant="outlined"
          color="primary"
          onClick={navToSubscribers}
        >
          Site Subscribers
        </Button>
      </Row>
      <Row mt="12px">
        <Button
          sx={{ width: "303px", my: "3px" }}
          variant="outlined"
          color="primary"
          onClick={navToPaymentIntegrations}
        >
          Payment Integrations
        </Button>
      </Row>
      <ViewEntitlementsDialog
        open={showingEntitlements}
        entitlements={entitlements}
        selectedEntitlements={site.entitlements}
        handleClose={closeEntitlements}
        loadingEntitlements={loadingEntitlements}
        entitlementsError={!!entitlementsError}
        siteId={site.id}
        refreshData={refreshData}
      />
    </>
  )
}

export default SiteOverview
