import { Box, Button, Radio, RadioGroup, FormControlLabel } from "@mui/material"
import { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import useRejectMerchant from "../hooks/useRejectMerchant"
import ErrorComponent from "../../../components/shared/Error"
import { TextBody } from "../../../components/shared/Typography"
import MDEditor from "@uiw/react-md-editor"
import useCreateNote from "../../Users/hooks/useCreateNote"

type Props = {
  isOpen: boolean
  close: () => void
  merchantId: number
  onSuccess: () => void
}

const MerchantRejectionDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  merchantId,
  onSuccess,
}) => {
  const { rejectMerchant, loading, error } = useRejectMerchant()
  const { createNote, loading: noteLoading, error: noteError } = useCreateNote()
  const [rejectionReason, setRejectionReason] = useState("")
  const [note, setNote] = useState("")

  const handleClose = () => {
    setNote("")
    close()
  }

  const handleSuccess = () => {
    close()
    onSuccess()
  }

  const createNoteAfterSuccess = async () => {
    await createNote("merchant", merchantId, note, handleSuccess)
  }

  const handleRejectMerchant = async () => {
    await rejectMerchant(merchantId, rejectionReason, createNoteAfterSuccess)
  }

  const handleRejectionReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionReason(event.target.value)
  }

  const handleNoteChange = (value: string | undefined) => {
    setNote(value || "")
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Reject Stripe Account"
      content={
        <Box width="500px">
          {error && <ErrorComponent error={error} />}
          {noteError && <ErrorComponent error={noteError} />}
          <TextBody>
            Please select a reason on why you are rejecting the Stripe account below. The response
            will be used to help improve the Stripe detection technology. An account can only be
            rejected once all balances are zero on it. Upon rejection, payouts and charged will be
            disabled.
          </TextBody>
          <RadioGroup value={rejectionReason} onChange={handleRejectionReasonChange}>
            <FormControlLabel value="fraud" control={<Radio />} label="Fraud" />
            <FormControlLabel
              value="terms_of_service"
              control={<Radio />}
              label="Terms of Service Violation"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
          <TextBody>
            Please provide information on the rejection for our own internal note keeping below.
            This note will be associated with the merchant.
          </TextBody>
          <MDEditor
            value={note}
            onChange={handleNoteChange}
            preview="edit"
            style={{ width: "100%" }}
          />
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="info" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRejectMerchant}
            color="error"
            variant="contained"
            disabled={loading || noteLoading}
          >
            Reject Stripe Account
          </Button>
        </>
      }
    />
  )
}

export default MerchantRejectionDialog
