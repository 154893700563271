import { BILLINGPRODUCTSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { ProductEntitlement, UpdateProductEntitlement } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateProductEntitlements = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      productId: number,
      entitlements: UpdateProductEntitlement[],
      onSuccess?: Function,
    ): Promise<ProductEntitlement[]> => {
      const response = await makeRequest<ProductEntitlement[]>(
        `${BILLINGPRODUCTSV4}/${productId}/entitlements`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ entitlements }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateProductEntitlements: performAction, loading, error }
}

export default useUpdateProductEntitlements
