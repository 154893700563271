import { FunctionComponent } from "react"
import "./LoadingSpinner.css"

export type LoadingSpinnerProps = {
  size?: "xs" | "sm" | "md"
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  size = "md",
}: LoadingSpinnerProps) => {
  return (
    <div className={`lds-ring ${size}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoadingSpinner
