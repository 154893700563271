import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DomainCertificate } from "../../../types"
import { DOMAINS } from "../../../constants/urlDefs"

const useGetDomainCerts = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, domainId: string | number): Promise<DomainCertificate[]> => {
      let queryString = `${DOMAINS}/${domainId}/certs`
      return makeRequest<DomainCertificate[]>(queryString, {}, { handleUnauthenticated })
    },
  )

  return { getDomainCerts: performAction, loading, error, domainCerts: data }
}

export default useGetDomainCerts
