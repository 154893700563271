// DO NOT EDIT THIS FILE DIRECTLY
// See `specs/message-queue/README.md` for common issues / usage

export const queues = [    
    "dead-letter-queue",
    "aws-worker-queue",
    "asset-worker-queue",
    "asset-worker-low-priority-queue",
    "billing-worker-queue",
    "cache-worker-queue",
    "commerce-worker-queue",
    "data-etl-worker-queue",
    "domain-worker-queue",
    "event-worker-queue",
    "notification-worker-queue",
    "site-worker-queue",
    "tracking-worker-queue",
    "user-worker-queue"
]
