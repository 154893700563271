import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FunctionComponent, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Merchant } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"

interface MerchantRowProps {
  merchant: Merchant
}

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: 5px;
`

const MerchantRow: FunctionComponent<MerchantRowProps> = ({ merchant }) => {
  const navigate = useNavigate()

  const handleNavToMerchant = useCallback(
    () => navigate(`/merchants/${merchant.id}`),
    [merchant, navigate],
  )

  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {merchant.id}
        <StyledCopyIcon content={merchant.id} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <SiteLink siteId={merchant.site_id}>{merchant.site_id}</SiteLink>
        <StyledCopyIcon content={merchant.site_id} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {merchant.stripe_account_id || "null"}
        <StyledCopyIcon content={merchant.stripe_account_id} />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {merchant.email}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {merchant.created_at}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {merchant.first_transaction_date}
      </BasicTableCell>
      <BasicTableCell>
        <Button variant="outlined" color="primary" onClick={handleNavToMerchant}>
          View
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default MerchantRow
