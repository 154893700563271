import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../../components/shared/BasicTable/BasicTable"
import { Promo } from "../../../../types"
import PromoRow from "./PromoRow"

type PromoListProps = {
  promos: Promo[]
  paginationData: PaginationData
}

const PromoList: FunctionComponent<PromoListProps> = ({ promos, paginationData }) => {
  const columns = [
    "Promo ID",
    "Code",
    "DiscountKey",
    "Purpose",
    "Redemptions",
    "Created",
    "Expires",
    "",
  ]

  const getPromoRows = useCallback(() => {
    return promos?.map((promo) => <PromoRow key={promo.id} promo={promo}></PromoRow>)
  }, [promos])

  return <BasicTable columns={columns} rows={getPromoRows()} paginationOptions={paginationData} />
}

export default PromoList
