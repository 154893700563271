import { USERSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { User } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useMergeUser = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      userId: string | number,
      userIdToDestroy: string | number,
      onSuccess?: () => any,
    ): Promise<User> => {
      const response = await makeRequest<User>(
        `${USERSV4}/${userId}/merge`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ destroy_user_id: userIdToDestroy }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { mergeUser: performAction, loading, error }
}

export default useMergeUser
