import { DateTimePicker } from "@mui/lab"
import { Box, Button, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import  { FunctionComponent, useEffect } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import useToggle from "../../../hooks/useToggle"
import { EditPromoRequest, Promo } from "../../../types"

type CreatePromoProps = {
  isOpen: boolean
  promo: Promo
  close: () => void
  updatePromo: (promo: EditPromoRequest) => any
  isUpdating: boolean
  hasError: boolean
}

const EditPromoDialog: FunctionComponent<CreatePromoProps> = ({
  isOpen,
  promo,
  close,
  updatePromo,
  hasError,
  isUpdating,
}) => {
  const { isOn: hasExpirationDate, toggle, turnOn: turnOnDate } = useToggle()

  const validationSchema = Yup.object().shape({
    purpose: Yup.string().required(),
    redemptionLimit: Yup.number().integer().optional().nullable().min(1),
    expirationDate: Yup.date().optional().nullable(),
  })

  const formik = useFormik({
    initialValues: {
      purpose: "",
      redemptionLimit: "",
      expirationDate: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const item: EditPromoRequest = {
        purpose: values.purpose,
        redemption_limit: values.redemptionLimit as number,
        expiration_date: values.expirationDate || null,
      }

      await updatePromo(item)
    },
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  useEffect(() => {
    if (!!promo.expiration_date) {
      turnOnDate()
    }

    formik.setValues({
      purpose: promo.purpose,
      redemptionLimit: promo.redemption_limit?.toString() || "",
      expirationDate: promo.expiration_date ? new Date(promo.expiration_date).toDateString() : "",
    })

    // eslint-disable-next-line
  }, [isOpen, promo])

  const handleSelectDate = (event: any) => {
    const date = new Date(event.$d)
    formik.setFieldValue("expirationDate", date)
  }

  useEffect(() => {
    if (!hasExpirationDate) {
      formik.setFieldValue("expirationDate", "")
    }

    // eslint-disable-next-line
  }, [hasExpirationDate])

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={`Edit Promo ${promo.code}`}
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {hasError && (
              <TextBody hasError={hasError}>There was a problem updating the promo code.</TextBody>
            )}
          </Row>
          <Row>
            <TextField
              id="purpose"
              label="Purpose"
              name="purpose"
              variant="outlined"
              margin="normal"
              type="text"
              fullWidth
              required
              onChange={formik.handleChange}
              value={formik.values.purpose}
              error={!!formik.errors.purpose}
            />
          </Row>
          <Row>
            <TextField
              id="redemptionlimit"
              label="Number of allowed uses"
              name="redemptionLimit"
              fullWidth
              type="number"
              variant="outlined"
              margin="normal"
              onChange={formik.handleChange}
              value={formik.values.redemptionLimit}
              error={!!formik.errors.redemptionLimit}
            />
          </Row>
          <Row>
            <FormGroup sx={{ width: "100%", mt: "5px" }}>
              <FormControlLabel
                control={<Switch checked={hasExpirationDate} onChange={toggle} />}
                label="Expires?"
              />
              {hasExpirationDate && (
                <DateTimePicker
                  value={formik.values.expirationDate}
                  onChange={handleSelectDate}
                  renderInput={(props: any) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="expirationDate"
                      {...props}
                      error={hasExpirationDate ? !!formik.errors.expirationDate : false}
                    />
                  )}
                  disablePast
                  label="Expiration Date"
                />
              )}
            </FormGroup>
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={isUpdating}
          >
            Update
          </Button>
        </>
      }
    />
  )
}
export default EditPromoDialog
