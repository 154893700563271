import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { EmailForward } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteMailgunDomain = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainId: string | number,
      onSuccess?: () => any,
    ): Promise<EmailForward> => {
      const response = await makeRequest<EmailForward>(
        `${DOMAINS}/${domainId}/mail/domain`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteMailgunDomain: performAction, loading, error }
}

export default useDeleteMailgunDomain
