import { useEffect, useState } from "react"
import { DEADLETTER } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DeadLetterEvent } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetDeadletters = (defaultRowsPerPage = 50) => {
  const [topic, setTopic] = useState("")
  const [queue, setQueue] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    loading,
    error,
    data,
    performAction: getDeadletters,
  } = useHandleRequest(
    (
      handleUnauthenticated,
      topic?: string,
      queue?: string,
    ): Promise<PagedResponse<DeadLetterEvent>> => {
      setTopic(topic || "")
      setQueue(queue || "")

      return makeRequest<PagedResponse<DeadLetterEvent>>(
        `${DEADLETTER}?limit=${encodeURIComponent(rowsPerPage)}&offset=${encodeURIComponent(
          page * rowsPerPage,
        )}${topic ? `&topic=${topic}` : ""}${queue ? `&queue=${queue}` : ""}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getDeadletters(topic, queue)
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getDeadletters,
    deadletters: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetDeadletters
