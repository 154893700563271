import { styled } from "@mui/material/styles"
import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"
import { RemoveSiteUserType } from "../SiteDetailsScreen"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  removeSiteUser: RemoveSiteUserType
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const RemoveSiteUserConfirmationDialog: FunctionComponent<Props> = ({
  removeSiteUser,
  hasError,
  ...rest
}) => {
  return (
    <ConfirmationDialog
      title="Remove Site User"
      errorMessage={hasError ? "There was a problem removing the site user" : null}
      affirmativeButtonText="Remove User"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>
              {`Are you sure you want to remove user ${removeSiteUser.userId} from site ${removeSiteUser.siteId}?`}
            </TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default RemoveSiteUserConfirmationDialog
