import useHandleRequest from "../../../hooks/useHandleRequest"
import { Tag } from "../../../types"
import makeRequest from "../../../utils/make-request"
import { THEMES } from "../../../constants/urlDefs"

const useUpdateTagsForTheme = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      themeId: string | number,
      tags: Tag[],
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${THEMES}/${themeId}/tags`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tag_ids: (tags || []).map((t) => t.id),
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateTagsForTheme: performAction, loading, error }
}

export default useUpdateTagsForTheme
