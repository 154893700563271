import { SITESV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PaymentIntegration } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPaymentIntegrations = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, siteId: number | string): Promise<PaymentIntegration[]> => {
      let queryString = `${SITESV1}/${siteId}/payment-integrations`
      return makeRequest<PaymentIntegration[]>(queryString, {}, { handleUnauthenticated })
    },
  )

  return { getPaymentIntegrations: performAction, loading, error, paymentIntegrations: data }
}

export default useGetPaymentIntegrations
