import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { TemplateFilter, Collection, Tag } from "../../../types"
import TemplateFilterTableRow from "./TemplateFilterTableRow"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

interface Props {
  templateFilters: TemplateFilter[]
  paginationData: PaginationData
  getTemplateFilters: () => any
  collections?: Collection[]
  tags?: Tag[]
}

const TemplateFiltersTable: FunctionComponent<Props> = ({
  templateFilters,
  paginationData,
  getTemplateFilters,
  collections,
  tags,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.templateFilter.update)

  const columns = canEdit
    ? ["ID", "Type", "Title", "Key", "Sort Order", "Active", "Collection", "Tag", "Action"]
    : ["ID", "Type", "Title", "Key", "Sort Order", "Active", "Collection", "Tag"]

  const getRows = useCallback(() => {
    return templateFilters.map((templateFilter: TemplateFilter) => (
      <TemplateFilterTableRow
        key={templateFilter.id}
        templateFilter={templateFilter}
        getTemplateFilters={getTemplateFilters}
        collections={collections}
        tags={tags}
      />
    ))
    // eslint-disable-next-line
  }, [templateFilters, collections, tags, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default TemplateFiltersTable
