import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { BlockedDomainPhrases } from "../../../types"
import { Button } from "@mui/material"
import useDeleteBlockedDomainPhrase from "../hooks/useDeleteBlockedDomainPhrase"

type BlockedDomainPhrasesRowProps = {
  blockedDomainPhrase: BlockedDomainPhrases
  canDelete: boolean
}

const BlockedDomainPhrasesRow: FunctionComponent<BlockedDomainPhrasesRowProps> = ({
  blockedDomainPhrase,
  canDelete,
}) => {
  const { deleteBlockedDomainPhrase, loading } = useDeleteBlockedDomainPhrase()

  return (
    <BasicTableRow key={blockedDomainPhrase.id}>
      <BasicTableCell component="th" scope="row">
        {blockedDomainPhrase.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {blockedDomainPhrase.phrase}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {blockedDomainPhrase.type}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {canDelete ? (
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteBlockedDomainPhrase(blockedDomainPhrase.id)}
            disabled={loading}
          >
            Delete
          </Button>
        ) : null}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default BlockedDomainPhrasesRow
