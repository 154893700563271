import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { DomainInstruction } from "../../../types"
import { DOMAIN_INSTRUCTIONS } from "../../../constants/urlDefs"

const useCreateDomainInstructions = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      name: string,
      instructions: string,
      icon_url: string,
      onSuccess?: () => any,
    ): Promise<DomainInstruction> => {
      const response = await makeRequest<DomainInstruction>(
        `${DOMAIN_INSTRUCTIONS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, instructions, icon_url }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { createDomainInstruction: performAction, loading, error }
}

export default useCreateDomainInstructions
