import { HighlightOff as RemoveIcon } from "@mui/icons-material"
import { Box, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { getIn } from "formik"
import { FunctionComponent } from "react"
import { Row } from "../../../components/shared/Dialog"
import { Subhead } from "../../../components/shared/Typography"
import { UserGroup, Variant, VariantValue } from "../../../types"

interface Props {
  variant: Variant
  disabled: boolean
  removeVariantDisabled: boolean
  removeVariant: (id: number) => void
  updateVariantName: (id: number, e: any) => void
  updateVariantDistribution: (id: number, e: any) => void
  updateVariantValue: (id: number, valueKey: string, e: any) => void
  updateVariantUserGroup: (id: number, e: any) => void
  userGroups: UserGroup[]
  formik: any
  index: number
}

const ExperimentVariant: FunctionComponent<Props> = ({
  variant,
  disabled,
  removeVariantDisabled,
  removeVariant,
  updateVariantName,
  updateVariantDistribution,
  updateVariantValue,
  updateVariantUserGroup,
  userGroups,
  formik,
  index,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        m: "6px",
        p: "6px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.palette.primary.dark}`,
      }}
    >
      <Row
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          color="error"
          onClick={() => removeVariant(variant.id)}
          size="medium"
          disabled={removeVariantDisabled}
        >
          <RemoveIcon htmlColor="error" fontSize="large" />
        </IconButton>
      </Row>
      <Row>
        <TextField
          label="Name"
          fullWidth
          variant="outlined"
          margin="normal"
          id="name"
          name="name"
          type="text"
          onChange={(e) => updateVariantName(variant.id, e)}
          value={variant.name}
          disabled={disabled}
          error={!!getIn(formik.errors, `variants[${index}].name`)}
        />
      </Row>
      <Row>
        <TextField
          label="Distribution"
          fullWidth
          variant="outlined"
          margin="normal"
          id="distribution"
          name="distribution"
          type="number"
          onChange={(e) => updateVariantDistribution(variant.id, e)}
          value={variant.distribution}
          disabled={disabled && variant.user_group_id !== -1}
          error={!!getIn(formik.errors, `variants[${index}].distribution`)}
        />
      </Row>

      <Row>
        User Group
        <Select
          label="User Groups"
          fullWidth
          variant="outlined"
          id="user_group_id"
          name="user_group_id"
          type="number"
          onChange={(e) => updateVariantUserGroup(variant.id, e)}
          value={variant.user_group_id}
          defaultValue={-1}
          disabled={disabled && variant.distribution !== -1}
          error={!!getIn(formik.errors, `variants[${index}].user_group_id`)}
        >
          <MenuItem value="-1">(null)</MenuItem>
          {userGroups.map((userGroup, ind) => (
            <MenuItem key={ind} value={userGroup.id}>
              {userGroup.name}
            </MenuItem>
          ))}
        </Select>
      </Row>
      {!!variant.values.length && (
        <Box sx={{ m: "8px" }}>
          <Row>
            <Subhead>Values</Subhead>
          </Row>
          {variant.values.map((v: VariantValue, idx: number) => {
            return (
              <Box key={idx} sx={{ m: "8px" }}>
                <Row>
                  <Subhead sx={{ color: (theme: any) => theme.palette.primary.main }}>
                    {v.key}
                  </Subhead>
                </Row>
                <Row>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="value"
                    name="value"
                    type="text"
                    onChange={(e) => updateVariantValue(variant.id, v.key, e)}
                    value={v.value}
                    disabled={disabled}
                    multiline
                    maxRows={10}
                    error={!!getIn(formik.errors, `variants[${index}].values[${idx}].value`)}
                  />
                </Row>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default ExperimentVariant
