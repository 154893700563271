import { Box, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import dayjs from "dayjs"
import  { FunctionComponent, useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { TextBody, Title2, Title3 } from "../../components/shared/Typography"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { EditPromoRequest, PromoRedemption } from "../../types"
import EditPromoDialog from "./components/EditPromoDialog"
import RedemptionCard from "./components/RedemptionCard/RedemptionCard"
import useGetPromoById from "./hooks/useGetPromoById"
import useUpdatePromo from "./hooks/useUpdatePromo"

const Row = styled(Box)``
Row.defaultProps = {
  flex: 1,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  my: "5px",
}

const PromoDetailsScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.promo.update)

  const { promo, loading, error, getPromoById } = useGetPromoById()
  const { isOn: isEditing, turnOn: openEdit, turnOff: closeEdit } = useToggle()

  const { updatePromo, loading: updatingPromo, error: updateError } = useUpdatePromo()

  const params: any = useParams()
  const promoId = Number(params.promoId)

  const handleUpdatePromo = useCallback(
    async (item: EditPromoRequest) => {
      const onSuccess = async () => {
        closeEdit()
        await getPromoById(promoId)
        toast.success("Successfully updated promo code")
      }

      if (promo) {
        await updatePromo(promo.id, item, onSuccess)
      }
    },
    [promo, getPromoById, updatePromo, promoId, closeEdit],
  )

  useEffect(() => {
    getPromoById(promoId)

    // eslint-disable-next-line
  }, [promoId])

  if (loading) {
    return (
      <div
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!promo) {
    return null
  }

  const limitString =
    promo.redemption_limit || promo.redemption_limit === 0 ? `${promo.redemption_limit || 0}` : "∞"
  const redemptionString = `${promo.redemption_count} / ${limitString}`

  const expirationString = promo.expiration_date
    ? dayjs(promo.expiration_date).format("MM/DD/YYYY")
    : "Never"

  return (
    <ScreenContainer requiresScope={scopes.promo.read} title="Promo Code">
      <Box
        display="flex"
        flex={1}
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Row>
          <Title2>{promo.code}</Title2>
        </Row>
        <Row>
          <TextBody>Discount Key: {promo.discount_key}</TextBody>
        </Row>
        <Row>
          <TextBody>Purpose: {promo.purpose}</TextBody>
        </Row>
        <Row>
          <TextBody>Redemption Usage: {redemptionString}</TextBody>
        </Row>
        <Row>
          {" "}
          <TextBody>Expires: {expirationString}</TextBody>
        </Row>
        {canEdit && (
          <Row>
            <Button color="primary" variant="contained" onClick={openEdit}>
              Edit
            </Button>
          </Row>
        )}
      </Box>

      <Box>
        <Title3>Redemptions:</Title3>
        <ul style={{ listStyle: "none" }}>
          {(promo?.redemptions || []).map((redemption: PromoRedemption) => (
            <RedemptionCard redemption={redemption} />
          ))}
        </ul>
      </Box>
      <EditPromoDialog
        isOpen={isEditing}
        close={closeEdit}
        promo={promo}
        updatePromo={handleUpdatePromo}
        hasError={!!updateError}
        isUpdating={updatingPromo}
      ></EditPromoDialog>
    </ScreenContainer>
  )
}

export default PromoDetailsScreen
