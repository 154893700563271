import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import PageSectionTypesTable from "./components/PageSectionTypesTable"
import useGetPageSectionTypes from "./hooks/useGetPageSectionTypes"
import { Button, Switch, FormControlLabel } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import PageSectionTypeDialog from "./components/PageSectionTypeDialog"
import BulkAddBlockTemplate from "./components/BulkAddBlockTemplate"


const PageSectionTypesScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const [bulkOpen, setBulkOpen] = useState(false)

  const [enabled, setEnabled] = useState(true)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.pageSectionTypes.create)
  const canEdit = hasScope(scopes.pageSectionType.update)

  const { loading, error, getPageSectionTypes, pageSectionTypes, paginationData } =
    useGetPageSectionTypes()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickBulkOpen = () => {
    setBulkOpen(true)
  }

  const handleClickBulkClose = () => {
    setBulkOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    getPageSectionTypes(enabled)
  }

  useEffect(() => {
    getPageSectionTypes(enabled)
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Page Sections" requiresScope={scopes.pageSectionTypes.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <br />
          {canCreate && (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "300px" }}
                onClick={handleClickOpen}
              >
                Create Section Type
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{ width: "300px" }}
                onClick={handleClickBulkOpen}
              >
                Bulk Create Block Templates
              </Button>
              <br />
            </>
          )}
          <FormControlLabel
            control={<Switch checked={enabled} onChange={(evnt, checked) => setEnabled(checked)} />}
            label="Enabled"
          />
          <PageSectionTypesTable
            pageSectionTypes={pageSectionTypes}
            paginationData={paginationData}
            canEdit={canEdit}
            getPageSectionTypes={getPageSectionTypes}
          />
          {open ? <PageSectionTypeDialog isOpen={open} close={handleClose} /> : null}

          {bulkOpen ? (
            <BulkAddBlockTemplate isOpen={bulkOpen} close={handleClickBulkClose} />
          ) : null}
        </>
      )}
    </ScreenContainer>
  )
}

export default PageSectionTypesScreen
