import React, { useState, useEffect } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { ThemeProvider } from "@mui/material/styles"
import { FunctionComponent } from "react"
import { BrowserRouter } from "react-router-dom"
import Router from "./components/navigation/Router"
import theme from "./constants/theme"
import { AuthProvider } from "./services/auth-service"
import { Toaster } from "react-hot-toast"
import config from "./config"
import KonamiCodeWrapper from "./components/shared/KonamiCodeWrapper"
import { createTheme } from "@mui/material/styles"

const App: FunctionComponent = () => {
  const [muiTheme, setMUITheme] = useState(theme)

  const togglePapyrusFont = () => {
    sessionStorage.setItem("usePapyrusFont", `${true}`)
    window.location.reload()
  }

  const usePapyrusFontString = sessionStorage.getItem("usePapyrusFont")
  const usePapyrusFont = usePapyrusFontString && Boolean(usePapyrusFontString)

  // Apply the font change
  useEffect(() => {
    if (usePapyrusFont) {
      document.body.style.fontFamily = "Papyrus, sans-serif"
      const muiCustomTheme = createTheme({
        ...theme,
        typography: {
          fontFamily: "Papyrus, sans-serif",
        },
      })
      setMUITheme(muiCustomTheme)
    }
  }, [usePapyrusFont])

  return (
    <BrowserRouter>
      <ThemeProvider theme={muiTheme}>
        <AuthProvider googleClientId={config.googleClientId}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <KonamiCodeWrapper onSuccess={togglePapyrusFont} />
            <Router />
            <Toaster />
          </LocalizationProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
