import { Box, Button, MenuItem, Select, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { CouponDuration } from "../../../types"
import { capitalizeFirstLetter } from "../../../utils/formatters/format-text"

interface Props {
  isOpen: boolean
  close: () => void
  handleCreateCoupon: (coupon: any, callback: () => any) => any
  hasError: boolean
  creatingCoupon: boolean
}

const CreateCouponDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  hasError,
  handleCreateCoupon,
  creatingCoupon,
}) => {
  const validationSchema = Yup.object()
    .test((values) => {
      // validate amountOff XOR percentOff
      const { percentOff, amountOff } = values
      let isValid = !!percentOff !== !!amountOff
      if (!!percentOff && percentOff === null) {
        isValid = false
      }
      if (!!amountOff && amountOff === null) {
        isValid = false
      }
      return isValid
    })
    .shape({
      name: Yup.string().required(),
      discountKey: Yup.string().required(),
      duration: Yup.string().required(),
      durationInMonths: Yup.number().when("duration", {
        is: (val: any) => !!val && val === CouponDuration.Repeating,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      percentOff: Yup.number(),
      amountOff: Yup.number(),
      currency: Yup.string().when("amountOff", {
        is: (val: any) => !!val,
        then: (schema: any) => schema.required(),
        otherwise: (schema: any) => schema.notRequired(),
      }),
    })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      discountKey: "",
      duration: CouponDuration.Forever,
      durationInMonths: "",
      percentOff: "",
      amountOff: "",
      currency: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const item: any = {
        name: values.name,
        discount_key: values.discountKey,
        duration: values.duration,
      }

      if (!!values?.percentOff) {
        item["percent_off"] = values.percentOff
      } else if (!!values?.amountOff) {
        item["amount_off"] = values.amountOff
      }

      if (item.duration === CouponDuration.Repeating) {
        item["duration_in_months"] = values.durationInMonths
      }
      if (!!item.amount_off) {
        item["currency"] = values.currency
      }
      await handleCreateCoupon(item, handleClose)
    },
  })

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Create New Coupon"
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {hasError && (
              <TextBody hasError={hasError}>There was a problem creating the coupon.</TextBody>
            )}
          </Row>
          <Row>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              margin="normal"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!formik.errors.name}
            />
          </Row>
          <Row>
            <TextField
              label="Discount Key"
              fullWidth
              variant="outlined"
              margin="normal"
              id="discountKey"
              name="discountKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.discountKey}
              error={!!formik.errors.discountKey}
            />
          </Row>
          <Row>
            <Select
              fullWidth
              name="duration"
              variant="outlined"
              id="duration"
              onChange={formik.handleChange}
              value={formik.values.duration}
              sx={{ mt: "9px", mr: "4px" }}
              error={!!formik.errors.duration}
            >
              {[CouponDuration.Forever, CouponDuration.Repeating, CouponDuration.Once].map(
                (duration) => (
                  <MenuItem key={duration} value={duration}>
                    {capitalizeFirstLetter(duration)}
                  </MenuItem>
                ),
              )}
            </Select>
            <TextField
              label="Duration (Months)"
              fullWidth
              variant="outlined"
              margin="normal"
              id="durationInMonths"
              name="durationInMonths"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.durationInMonths}
              disabled={formik.values.duration !== CouponDuration.Repeating}
              error={!!formik.errors.durationInMonths}
            />
          </Row>
          <Row>
            <TextField
              label="Percent Off (%)"
              fullWidth
              variant="outlined"
              margin="normal"
              id="percentOff"
              name="percentOff"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.percentOff}
              disabled={!!formik.values.amountOff}
              error={!!formik.errors.percentOff}
            />
          </Row>
          <Row>
            <TextField
              label="Amount Off"
              fullWidth
              variant="outlined"
              margin="normal"
              id="amountOff"
              name="amountOff"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.amountOff}
              disabled={!!formik.values.percentOff}
              error={!!formik.errors.amountOff}
            />
            <TextField
              label="Currency"
              fullWidth
              variant="outlined"
              margin="normal"
              id="currency"
              name="currency"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.currency}
              disabled={!formik.values.amountOff}
              error={!!formik.errors.currency}
              sx={{ ml: "4px" }}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creatingCoupon}
          >
            Create
          </Button>
        </>
      }
    />
  )
}

export default CreateCouponDialog
