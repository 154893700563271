import { styled } from "@mui/material/styles"
import  { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Subdomain } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"
import UserLink from "../../Users/components/UserLink"
import SubdomainLink from "./SubdomainLink"

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: 5px;
`

interface SubdomainRowProps {
  subdomain: Subdomain
}

const SubdomainRow: FunctionComponent<SubdomainRowProps> = ({ subdomain }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        <SubdomainLink subdomainId={subdomain.id}>{subdomain.subdomain}</SubdomainLink>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subdomain.id && (
          <>
            {subdomain.id}
            <StyledCopyIcon content={subdomain.id} />
          </>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        Subdomain
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subdomain?.user_id && (
          <>
            <UserLink userId={subdomain.user_id}>{subdomain.user_id}</UserLink>
            <StyledCopyIcon content={subdomain.user_id} />
          </>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subdomain.site_id && (
          <>
            <SiteLink siteId={subdomain.site_id}>{subdomain.site_id}</SiteLink>
            <StyledCopyIcon content={subdomain.site_id} />
          </>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {subdomain.status}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        -
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        -
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default SubdomainRow
