import { USERGROUPS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PostUserGroupUser, UserGroupUser } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateUserGroupsUser = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      groupId: number,
      userGroupUser: PostUserGroupUser,
    ): Promise<UserGroupUser> => {
      return makeRequest<UserGroupUser>(
        `${USERGROUPS}/${groupId}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userGroupUser),
        },
        { handleUnauthenticated },
      )
    },
  )

  return {
    createUserGroupUser: performAction,
    loading,
    error,
  }
}

export default useCreateUserGroupsUser
