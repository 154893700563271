import { Box } from "@mui/material"
import  { FunctionComponent } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Title3 } from "../../../components/shared/Typography"
import { Payout } from "../../../types"
import PayoutRow from "./PayoutsRow"

interface Props {
  payouts: Payout[] | undefined
}

const columns: string[] = ["ID", "Status", "Automatic", "Value", "Created", "Arrival"]

const getRows = (payouts?: Payout[]) => {
  if (!payouts) {
    return []
  }
  return payouts.map((payout: Payout, index: number) => <PayoutRow key={index} payout={payout} />)
}

const PayoutsTable: FunctionComponent<Props> = ({ payouts }) => (
  <Box width="100%" sx={{ mt: "15px" }}>
    <Title3>Recent Payouts</Title3>
    <br />
    <BasicTable columns={columns} rows={getRows(payouts)} />
  </Box>
)

export default PayoutsTable
