import { Box } from "@mui/material"
import  { FunctionComponent } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Title3 } from "../../../components/shared/Typography"
import { Dispute } from "../../../types"
import DNSimpleZoneRow from "./DisputesRow"

interface Props {
  disputes: Dispute[] | undefined
}

const columns: string[] = [
  "ID",
  "Order ID",
  "Status",
  "Reason",
  "Price",
  "Evidence Submitted",
  "Evidence Due By",
  "Created",
]

const getRows = (disputes?: Dispute[]) => {
  if (!disputes) {
    return []
  }
  return disputes.map((dispute: Dispute, index: number) => (
    <DNSimpleZoneRow key={index} dispute={dispute} />
  ))
}

const DisputesTable: FunctionComponent<Props> = ({ disputes }) => (
  <Box width="100%">
    <Title3>Disputes</Title3>
    <br />
    <BasicTable columns={columns} rows={getRows(disputes)} />
  </Box>
)

export default DisputesTable
