/**
 * Converts a string from dash-case to Title Case
 * @param str String to convert
 * @returns Title case string
 */
export function dashToTitle(str: string) {
  const words = str.split("-")
  const titleWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase()
    const rest = word.substring(1).toLowerCase()
    return firstLetter + rest
  })
  return titleWords.join(" ")
}
