import { IDENTITIESV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useResetUserPassword = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      userId: number,
      identityId: number,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${IDENTITIESV1}/${identityId}/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "universe-user-id": `${userId}`,
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { resetPassword: performAction, loading, error }
}

export default useResetUserPassword
