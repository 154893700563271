import { EMPLOYEES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Employee } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateEmployee = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, employee: Employee, onSuccess?: () => any): Promise<Employee> => {
      const response = await makeRequest<Employee>(
        `${EMPLOYEES}/${employee.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: employee.name,
            email: employee.email,
            roles: employee.roles,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateEmployee: performAction, loading, error }
}

export default useUpdateEmployee
