import { BLOCKEDDOMAINPHRASES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BlockedDomainPhrases } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteBlockedDomainPhrase = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      blockedDomainPhraseId: number,
      onSuccess?: () => any,
    ): Promise<BlockedDomainPhrases> => {
      const blockedDomainPhrase = await makeRequest<BlockedDomainPhrases>(
        `${BLOCKEDDOMAINPHRASES}/${blockedDomainPhraseId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return blockedDomainPhrase
    },
  )

  return { deleteBlockedDomainPhrase: performAction, loading, error }
}

export default useDeleteBlockedDomainPhrase
