import { Box, Checkbox, Grid, IconButton } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title3 } from "../../components/shared/Typography"
import useGetEmailForward from "../Domains/hooks/useGetEmailForward"
import ChangeUserRoleConfirmationDialog from "./components/ChangeUserRoleConfirmationDialog"
import DomainCard from "./components/DomainCard"
import RemoveSiteUserConfirmationDialog from "./components/RemoveSiteUserConfirmationDialog"
import SiteActions from "./components/SiteActions"
import SiteImage from "./components/SiteImage"
import SiteOverview from "./components/SiteOverview"
import SiteUserCard from "./components/SiteUserCard"
import useGetSiteById from "./hooks/useGetSiteById"
import useGetSiteUsers from "./hooks/useGetSiteUsers"
import useRemoveSiteUser from "./hooks/useRemoveSiteUser"
import useSetUserRoleForSite from "./hooks/useSetUserRoleForSite"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import useToggle from "../../hooks/useToggle"
import AddSiteUserDialog from "./components/AddSiteUserDialog"
import useGetSiteNotes from "./hooks/useGetSiteNotes"
import NotesTable from "../Users/components/NotesTable"
import CreateNoteDialog from "../Users/components/CreateNoteDialog"
import useGetSitePages from "./hooks/useGetSitePages"
import config from "../../config"

export type UserRoleChangeType = {
  userId: number
  role: string
}

export type RemoveSiteUserType = {
  userId: number
  siteId: number
}

const SiteDetailsScreen: FunctionComponent = () => {
  const [createNoteOpen, setCreateNoteOpen] = useState(false)

  const { site, getSiteById, error: siteError, loading: loadingSite } = useGetSiteById()

  const {
    siteUsers: users,
    getSiteUsers,
    error: siteUsersError,
    loading: loadingSiteUsers,
  } = useGetSiteUsers()

  const { emailForward, getEmailForward, error: emailForwardError } = useGetEmailForward()
  const { pages, getPages } = useGetSitePages()

  const [roleChange, setRoleChange] = useState<UserRoleChangeType | undefined>()
  const [userToRemove, setUserToRemove] = useState<RemoveSiteUserType | undefined>()
  const { setUserRoleForSite, error: setRoleError, loading: settingRole } = useSetUserRoleForSite()
  const {
    removeSiteUser,
    error: removeSiteUserError,
    loading: removingSiteUser,
  } = useRemoveSiteUser()

  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const { siteId } = useParams<{ siteId: string }>()

  useEffect(() => {
    if (!!siteId) {
      handleGetSite()
    }
    // eslint-disable-next-line
  }, [siteId])

  const onChangeUserRole = (userId: number, role: string) => {
    setRoleChange({ userId, role })
  }

  const handleGetSite = useCallback(async () => {
    if (siteId) {
      await getSiteById(parseInt(siteId))
    }
  }, [siteId, getSiteById])

  const handleChangeRoleSuccess = useCallback(async () => {
    if (siteId) {
      await getSiteById(parseInt(siteId))
      setRoleChange(undefined)
    }
    toast.success("Successfully changed user role")
  }, [getSiteById, setRoleChange, siteId])

  const handleChangeUserRole = useCallback(async () => {
    if (!siteId || !roleChange?.userId || !roleChange?.role) {
      return
    }

    if (!["owner", "member"].includes(roleChange.role)) {
      return
    }

    await setUserRoleForSite(siteId, roleChange.userId, roleChange.role, handleChangeRoleSuccess)
  }, [setUserRoleForSite, siteId, handleChangeRoleSuccess, roleChange])

  const handleGetSiteUsers = useCallback(async () => {
    if (!site) {
      return
    }
    await getSiteUsers(site.id)
  }, [site, getSiteUsers])

  const handleGetEmailForward = useCallback(async () => {
    if (!site) {
      return
    }
    const hasDomain =
      site.domain &&
      !site.domain.includes(".univer.se") &&
      !site.domain.includes(".onuniverse.com") &&
      !site.domain.includes(".onuniverse-staging.com")

    if (hasDomain) {
      const emailForwardDomain = site.domain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      await getEmailForward(emailForwardDomain)
    }
  }, [site, getEmailForward])

  const onRemoveSiteUser = (userId: number, siteId: number) => {
    setUserToRemove({ userId, siteId })
  }

  const handleRemoveSiteUserSuccess = useCallback(async () => {
    if (siteId) {
      await getSiteById(parseInt(siteId))
      setUserToRemove(undefined)
    }
    toast.success("Successfully removed site user")
  }, [getSiteById, setUserToRemove, siteId])

  const handleRemoveSiteUser = useCallback(async () => {
    if (!userToRemove?.userId || !userToRemove?.siteId) {
      return
    }

    await removeSiteUser(userToRemove.siteId, userToRemove.userId, handleRemoveSiteUserSuccess)
  }, [removeSiteUser, userToRemove, handleRemoveSiteUserSuccess])

  // Add Site User
  const {
    isOn: AddSiteUserDialogIsOpen,
    turnOn: openAddSiteUserDialog,
    turnOff: closeAddSiteUserDialog,
  } = useToggle()

  const handleAddSiteUserDialogClose = () => {
    closeAddSiteUserDialog()
    handleGetSiteUsers()
  }

  // Site Notes
  const { getSiteNotes, notes, paginationData: siteNotesPaginationData } = useGetSiteNotes()

  const handleCreateNoteClose = async () => {
    setCreateNoteOpen(false)
    await getSiteNotes(Number(siteId))
  }

  useEffect(() => {
    handleGetSiteUsers()
    handleGetEmailForward()
    getSiteNotes(Number(siteId))
    getPages(Number(siteId), "&type=Root")
    // eslint-disable-next-line
  }, [site])

  const isLoadingContent = loadingSite || loadingSiteUsers
  const showContent = !!site && !isLoadingContent && !successMessage
  const siteFaviconUrl =
    site && site.favicon_asset_id
      ? `${config.assetsBaseUrl}/${site.favicon_asset_id}`
      : `https://univer.se/static/favicons/${site?.favicon}/favicon.ico`

  return (
    <ScreenContainer
      iconUrl={siteFaviconUrl}
      title={site?.title || site?.domain || `Site ${siteId}`}
    >
      <br />
      {(!!isLoadingContent || !!settingRole) && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {successMessage && <h1>{successMessage}</h1>}
      {siteError && <ErrorComponent error={siteError} />}
      {siteUsersError && <ErrorComponent error={siteUsersError} />}

      {setRoleError && <ErrorComponent error={setRoleError} />}

      {showContent && (
        <Grid container>
          <Grid item lg={4} xs={12} p="12px">
            {site?.thumbnails?.fullsize && (
              <Box
                display="flex"
                sx={{ width: "100%", flexDirection: "row", justifyContent: "center" }}
              >
                <SiteImage src={site.thumbnails.fullsize} alt={site?.title || "site image"} />
              </Box>
            )}
          </Grid>
          <Grid item lg={4} md={6} xs={12} p="12px">
            <SiteOverview
              site={site}
              emailForward={emailForward}
              emailForwardError={emailForwardError}
              themeId={pages && pages[0] && pages[0].theme_id}
              refreshData={handleGetEmailForward}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <SiteActions
              site={site}
              users={users}
              updateSuccessMessage={setSuccessMessage}
              handleGetSite={handleGetSite}
              emailForward={emailForward}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} p="12px">
            <Title3>
              URLs (External:
              <Checkbox
                disabled
                style={{ padding: 0 }}
                checked={site.has_external_domain}
              ></Checkbox>
              )
            </Title3>

            {site.urls.map((url: string) => (
              <DomainCard url={url} key={url} />
            ))}
          </Grid>
          <Grid item lg={4} md={6} xs={12} p="12px">
            <Title3>
              Site Notes{" "}
              <IconButton onClick={() => setCreateNoteOpen(true)} sx={{ padding: 0, margin: 0 }}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Title3>
            <NotesTable
              notes={notes}
              paginationData={siteNotesPaginationData}
              refreshData={() => getSiteNotes(Number(siteId))}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} p="12px">
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Title3>Users</Title3>
              <IconButton onClick={openAddSiteUserDialog} sx={{ padding: 0, margin: 0 }}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Box>
            {users &&
              users.map((user) => (
                <SiteUserCard
                  key={user.user.id}
                  siteUser={user}
                  changeRole={onChangeUserRole}
                  removeSiteUser={onRemoveSiteUser}
                ></SiteUserCard>
              ))}
          </Grid>
        </Grid>
      )}
      {!!roleChange && (
        <ChangeUserRoleConfirmationDialog
          roleChange={roleChange}
          handleConfirmation={handleChangeUserRole}
          close={() => setRoleChange(undefined)}
          isOpen={!!roleChange}
          hasError={!!setRoleError}
          loading={settingRole}
        />
      )}

      {!!userToRemove && (
        <RemoveSiteUserConfirmationDialog
          removeSiteUser={userToRemove}
          handleConfirmation={handleRemoveSiteUser}
          close={() => setUserToRemove(undefined)}
          isOpen={!!removeSiteUser}
          hasError={!!removeSiteUserError}
          loading={removingSiteUser}
        />
      )}
      <AddSiteUserDialog
        siteId={Number(siteId)}
        isOpen={AddSiteUserDialogIsOpen}
        close={handleAddSiteUserDialogClose}
      />
      {createNoteOpen && site ? (
        <CreateNoteDialog
          isOpen={createNoteOpen}
          close={handleCreateNoteClose}
          type="site"
          typeId={site.id}
        />
      ) : null}
    </ScreenContainer>
  )
}

export default SiteDetailsScreen
