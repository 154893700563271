import { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { TextGenerationAction } from "../../../types"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import { Button } from "@mui/material"
import TextGenerationActionDialog from "./TextGenerationActionDialog"

type Props = {
  textGenAction: TextGenerationAction
  close: () => any
}

const TextGenerationActionsRow: FunctionComponent<Props> = ({ textGenAction, close }) => {
  const [open, setOpen] = useState(false)
  const { hasScope } = useAuth()

  const canEdit = hasScope(scopes.textGenerationAction.update)
  const canDelete = hasScope(scopes.textGenerationAction.delete)

  return (
    <BasicTableRow key={textGenAction.id}>
      <BasicTableCell component="th" scope="row" style={{ width: 50 }}>
        <img
          src={textGenAction.icon_url}
          height="50"
          width="50"
          alt={`${textGenAction.title} Icon`}
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {textGenAction.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {textGenAction.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {textGenAction.message}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {textGenAction.model}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {canEdit && (
          <Button variant="contained" onClick={() => setOpen(true)}>
            Edit
          </Button>
        )}
      </BasicTableCell>
      <TextGenerationActionDialog
        isOpen={open}
        close={() => setOpen(false)}
        refreshData={close}
        canDelete={canDelete}
        textGenerationActionUpdate={textGenAction}
      />
    </BasicTableRow>
  )
}

export default TextGenerationActionsRow
