import { Paper, Table, TableBody, TableContainer, TableHead, TablePagination } from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import BasicTableCell from "../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../components/shared/BasicTable/BasicTableRow"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ThemeRow from "./components/ThemeRow"
import useGetThemes from "./hooks/useGetThemes"

const ThemesScreen: FunctionComponent = () => {
  const { getThemes, themes, loading, error, paginationData } = useGetThemes()

  useEffect(() => {
    getThemes()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <div style={{ marginTop: "20px", marginLeft: "160px" }}>
        <LoadingSpinner />
      </div>
    )
  } else if (error) {
    return <ErrorComponent error={error} />
  }

  return (
    <div>
      <h3>Theme</h3>

      <TableContainer component={Paper}>
        <TablePagination
          component="div"
          count={paginationData.count}
          page={paginationData.page}
          onPageChange={paginationData.onPageChange}
          rowsPerPage={paginationData.rowsPerPage}
          onRowsPerPageChange={paginationData.onRowsPerPageChange}
        />

        <Table size="small">
          <TableHead>
            <BasicTableRow>
              <BasicTableCell></BasicTableCell>
              <BasicTableCell>Name</BasicTableCell>
              <BasicTableCell>Description</BasicTableCell>
              <BasicTableCell></BasicTableCell>
              <BasicTableCell></BasicTableCell>
            </BasicTableRow>
          </TableHead>
          <TableBody>
            {themes?.map((row) => (
              <ThemeRow key={row.id} theme={row}></ThemeRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ThemesScreen
