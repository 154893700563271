import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import useGetTags from "./hooks/useGetTags"
import TagsTable from "./components/TagsTable"
import useGetCollections from "./hooks/useGetCollections"
import { Button, InputLabel, MenuItem, Select } from "@mui/material"
import CreateTagDialog from "./components/CreateTagDialog"
import { Collection } from "../../types"
import { useSearchParams } from "react-router-dom"
import { useAuth } from "../../services/auth-service"
import CreateCollectionDialog from "./components/CreateCollectionDialog"

const TagsScreen: FunctionComponent = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const [collectionOpen, setCollectionOpen] = useState(false)
  const [selectedCollection, setSelectedCollection] = useState("")

  // Permissions
  const { hasScope } = useAuth()
  const canCreateTag = hasScope(scopes.tags.create)
  const canCreateCollection = hasScope(scopes.collections.create)

  const { tags, getTags, paginationData, error, loading } = useGetTags()
  const {
    collections,
    getCollections,
    loading: collectionsLoading,
    error: collectionsError,
  } = useGetCollections()

  const handleTagCreateClose = async () => {
    setOpen(false)
    setCollectionOpen(false)
    await getCollections()
    await getTags("")
  }

  const handleCollectionChange = async (event: any) => {
    const collectionName = event.target.value
    setSelectedCollection(collectionName)
    setSearchParams({ collection: collectionName as string })
    await getTags(collectionName)
  }

  useEffect(() => {
    const collection = searchParams.get("collection") || ""
    setSelectedCollection(collection)
    getTags(collection)
    getCollections()

    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Tags" requiresScope={scopes.tags.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {collectionsError ? <ErrorComponent error={collectionsError} /> : ""}
      {loading || collectionsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <InputLabel>Filter by collection</InputLabel>
          <Select
            id="collection-select"
            value={selectedCollection}
            onChange={handleCollectionChange}
            sx={{ my: "12px", width: "300px" }}
          >
            <MenuItem value="">No Collection</MenuItem>
            {collections.map((collection: Collection) => (
              <MenuItem key={collection.id} value={collection.name}>
                {collection.name}
              </MenuItem>
            ))}
          </Select>
          {canCreateTag ? (
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{ my: "12px", width: "300px" }}
            >
              Create Tag
            </Button>
          ) : null}
          {canCreateCollection ? (
            <Button
              variant="contained"
              onClick={() => setCollectionOpen(true)}
              sx={{ my: "12px", width: "300px" }}
            >
              Create Collection
            </Button>
          ) : null}
          <TagsTable
            tags={tags}
            paginationData={paginationData}
            collections={collections}
            close={handleTagCreateClose}
          />
          {open ? (
            <CreateTagDialog collections={collections} isOpen={open} close={handleTagCreateClose} />
          ) : null}
          {collectionOpen ? (
            <CreateCollectionDialog isOpen={collectionOpen} close={handleTagCreateClose} />
          ) : null}
        </>
      )}
    </ScreenContainer>
  )
}

export default TagsScreen
