import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import { ViewerSubscription } from "../../types"
import ConfirmUnsubscribeViewerDialog from "./components/ConfirmUnsubscribeViewerDialog"
import SubscribersTable from "./components/SubscribersTable"
import useGetSiteSubscribers from "./hooks/useGetSiteSubscribers"
import useUnsubscribeViewerSubscriber from "./hooks/useUnsubscribeViewerSubscriber"
import toast from "react-hot-toast"
import useExportSiteViewerSubscriptions from "./hooks/useExportSiteViewerSubscriptions"

const SiteSubscribersScreen: FunctionComponent = () => {
  const { siteId } = useParams<{ siteId: string }>()
  const { loading, error, getSubscribers, subscribers, paginationData } = useGetSiteSubscribers()
  const {
    loading: unsubLoading,
    error: unsubError,
    unsubscribeViewerSubscriber,
  } = useUnsubscribeViewerSubscriber()
  const [viewerSubscription, setViewerSubscription] = useState<ViewerSubscription>()
  const { hasScope } = useAuth()
  const canUnsubscribe = hasScope(scopes.site.viewerSubscriptionSubscriber.unsubscribe)

  const { exportSiteViewerSubscriptions } = useExportSiteViewerSubscriptions()

  useEffect(() => {
    getSubscribers(Number(siteId))
    // eslint-disable-next-line
  }, [])

  const handleCloseUnsubscribeDialog = useCallback(() => {
    setViewerSubscription(undefined)
  }, [setViewerSubscription])

  const handleExportSiteViewerSubscriptions = useCallback(async () => {
    try {
      const resp = await exportSiteViewerSubscriptions(Number(siteId))

      if (!resp) {
        toast.error("CSV is empty")
        return
      }

      // Create a Blob object with the CSV content
      const blob = new Blob([resp], { type: "text/csv" })

      // Create a link for download
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = `Site_${siteId}_Viewer_Subscriptions.csv`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger a click event to initiate the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
    } catch (error) {
      toast.error(`Error exporting subscriptions: ${error}`)
    }
  }, [siteId, exportSiteViewerSubscriptions])

  const handleUnsubscribeViewer = useCallback(async () => {
    const onSuccess = async () => {
      handleCloseUnsubscribeDialog()
      await getSubscribers(Number(siteId))
      toast.success(`Successfully removed subscriber from site ${siteId}`)
    }
    if (viewerSubscription) {
      await unsubscribeViewerSubscriber(Number(siteId), Number(viewerSubscription), onSuccess)
    }
  }, [
    viewerSubscription,
    handleCloseUnsubscribeDialog,
    getSubscribers,
    siteId,
    unsubscribeViewerSubscriber,
  ])

  return (
    <ScreenContainer title={`Site ${siteId} Viewer Subscriptions`}>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      <Box display="flex" sx={{ flexDirection: "column", justifyContent: "center" }}>
        <Button sx={{ maxWidth: 300, ml: 0, pl: 0 }} onClick={handleExportSiteViewerSubscriptions}>
          Export Viewer Subscriptions
        </Button>
        <SubscribersTable
          subscribers={subscribers}
          unsubscribeViewer={setViewerSubscription}
          canUnsubscribe={canUnsubscribe}
          paginationData={paginationData}
        />
      </Box>
      {!!viewerSubscription && (
        <ConfirmUnsubscribeViewerDialog
          subscriber={viewerSubscription}
          isOpen={!!viewerSubscription}
          handleConfirmation={handleUnsubscribeViewer}
          hasError={!!unsubError}
          loading={unsubLoading}
          close={handleCloseUnsubscribeDialog}
        />
      )}
    </ScreenContainer>
  )
}

export default SiteSubscribersScreen
