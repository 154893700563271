import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import BlocksTable from "./components/BlocksTable"
import useGetBlocks from "./hooks/useGetBlocks"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import BlockDialog from "./components/BlockDialog"

const BlocksScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.blocks.create)
  const canEdit = hasScope(scopes.block.update)

  const { loading, error, getBlocks, blocks, paginationData } = useGetBlocks()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getBlocks()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Blocks" requiresScope={scopes.blocks.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <br />
          {canCreate && (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "300px" }}
                onClick={handleClickOpen}
              >
                Create Block
              </Button>
              <br />
            </>
          )}
          <BlocksTable blocks={blocks} paginationData={paginationData} canEdit={canEdit} />
          {open ? <BlockDialog isOpen={open} close={handleClose} /> : null}
        </>
      )}
    </ScreenContainer>
  )
}

export default BlocksScreen
