import React, { FunctionComponent, useState } from "react"
import { Button, Switch } from "@mui/material"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { Entitlement } from "../../../types"
import EntitlementDialog from "./EntitlementDialog"

type EntitlementTableRowProps = {
  entitlement: Entitlement
  getEntitlements: () => any
}

const EntitlementTableRow: FunctionComponent<EntitlementTableRowProps> = ({
  entitlement,
  getEntitlements,
}) => {
  const { hasScope } = useAuth()
  const canEdit = hasScope(scopes.billing.entitlement.update)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getEntitlements()
  }

  return (
    <BasicTableRow key={entitlement.id}>
      <BasicTableCell component="td" scope="row">
        {entitlement.id}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {entitlement.title}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {entitlement.description}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {entitlement.variant}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <Switch checked={entitlement.active} disabled />
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <Switch checked={entitlement.is_free} disabled />
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        <Switch checked={entitlement.usage_enabled} disabled />
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {entitlement.usage_limit_default}
      </BasicTableCell>
      <BasicTableCell component="td" scope="row">
        {entitlement.usage_limit_unit}
      </BasicTableCell>

      {canEdit ? (
        <>
          <BasicTableCell component="td" scope="row">
            <Button onClick={handleClickOpen}>Edit</Button>
          </BasicTableCell>
        </>
      ) : null}
      {open ? (
        <EntitlementDialog isOpen={open} close={handleClose} editEntitlement={entitlement} />
      ) : null}
    </BasicTableRow>
  )
}

export default EntitlementTableRow
