import { Box } from "@mui/material"
import  { FunctionComponent } from "react"
import { Title2, Subhead } from "../../../components/shared/Typography"
import { Product } from "../../../types"
import { formatPrice } from "../../../utils/formatters"
import SiteLink from "../../Sites/components/SiteLink"
import { Row, StyledText } from "./SiteLayoutShared"

interface Props {
  product: Product | undefined
}

const SiteProductOverview: FunctionComponent<Props> = ({ product }) => {
  if (!product) {
    return null
  }

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "400px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
        marginTop: "10px",
        marginBottom: "10px",
        marginRight: "10px",
      }}
    >
      <Title2>Product:</Title2>
      <Row>
        <Subhead>ID: </Subhead>
        <StyledText>{product.id}</StyledText>
      </Row>
      <Row>
        <Subhead>Name: </Subhead>
        <StyledText>{product.name}</StyledText>
      </Row>
      <Row>
        <Subhead>Price: </Subhead>
        <StyledText>{formatPrice(product.price)}</StyledText>
      </Row>
      <Row>
        <Subhead>Site ID: </Subhead>
        <SiteLink siteId={product.site_id}> {product.site_id}</SiteLink>
      </Row>
      <Row>
        <Subhead>Allows Notes: </Subhead>
        <StyledText>{String(product.allows_notes)}</StyledText>
      </Row>
      <Row>
        <Subhead>Notes Prompt: </Subhead>
        <StyledText>{product.notes_prompt}</StyledText>
      </Row>
      <Row>
        <Subhead>Requires Phone Number: </Subhead>
        <StyledText>{String(product.requires_phone_number)}</StyledText>
      </Row>
      <Row>
        <Subhead>Requires Address: </Subhead>
        <StyledText>{String(product.requires_address)}</StyledText>
      </Row>
      <Row>
        <Subhead>Requires Shipping: </Subhead>
        <StyledText>{String(product.requires_shipping)}</StyledText>
      </Row>
    </Box>
  )
}

export default SiteProductOverview
