import { NOTES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Note } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateNote = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      type: string,
      typeId: number,
      note: string,
      onSuccess?: () => any,
    ): Promise<Note> => {
      const response = await makeRequest<Note>(
        `${NOTES}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type, type_id: typeId, note }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createNote: performAction, loading, error }
}

export default useCreateNote
