import { FunctionComponent, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import AISuggestionsTable from "./components/AISuggestionTable"
import AISuggestionDialog from "./components/AISuggestionDialog"
import useListAISuggestions from "./hooks/useListAISuggestions"

const AISuggestionScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.suggestions.create)

  const { loading, error, listAISuggestions, aiSuggestions, paginationData } =
    useListAISuggestions()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    listAISuggestions()
  }

  useEffect(() => {
    listAISuggestions()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Ai Suggestion" requiresScope={scopes.suggestions.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {loading && !error ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <LoadingSpinner />
        </div>
      ) : (
        ""
      )}
      <>
        {canCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              onClick={handleClickOpen}
            >
              Create Ai Suggestion
            </Button>
            <br />
          </>
        )}
        <AISuggestionsTable
          aiSuggestions={aiSuggestions}
          paginationData={paginationData}
          getAISuggestions={listAISuggestions}
        />
        {open ? <AISuggestionDialog isOpen={open} close={handleClose} /> : null}
      </>
    </ScreenContainer>
  )
}

export default AISuggestionScreen
