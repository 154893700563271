import { BLOCKTEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BlockTemplate, UpdateBlockTemplate } from "../../../types"
import makeRequest from "../../../utils/make-request"

const nullEmptyStrings = (obj: any) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === "") {
      newObj[key] = null
    }
  })
  return newObj
}

const useUpdateBlockTemplate = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      blockTemplateId: string,
      blockTemplate: UpdateBlockTemplate,
      onSuccess?: () => any,
    ): Promise<BlockTemplate> => {
      // console.log(blockTemplate)
      const blockTemplateResponse = await makeRequest<BlockTemplate>(
        `${BLOCKTEMPLATES}/${blockTemplateId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(nullEmptyStrings(blockTemplate)),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return blockTemplateResponse
    },
  )

  return { updateBlockTemplate: performAction, loading, error }
}

export default useUpdateBlockTemplate
