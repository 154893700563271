import { Button, Select, MenuItem, TextField, FormControl } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useEffect } from "react"
import toast from "react-hot-toast"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { PageContent, PageSectionType, UpdateBlockTemplate } from "../../../types"
import {
  BlockOnGrid as BlockOnGridV3,
  GridBlockV3,
} from "../../../types/blocks/grid/v3/grid-block-v3"
import { BlockOnGrid as BlockOnGridV2 } from "../../../types/blocks/grid/v2/grid-block-v2"
import { GridBlockV4 } from "../../../types/blocks/grid/v4/grid-block-v4"
import useCreateBlockTemplate from "../hooks/useCreateBlockTemplate"
import useDeleteBlockTemplate from "../hooks/useDeleteBlockTemplate"
import useUpdateBlockTemplate from "../hooks/useUpdateBlockTemplate"

// @ts-ignore
import { JSONEditor } from "react-json-editor-viewer"
import useGetPageById from "../../Sites/hooks/useGetPageById"
import useUpdatePageContent from "../../PageSectionTypes/hooks/useUpdatePageContent"
import { GridBlockV2 } from "../../../types/blocks/grid/v2/grid-block-v2"
import useGetBlockTemplate from "../hooks/useGetBlockTemplate"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import BlockTemplateContentRow from "./BlockTemplateContentRow"
import { Position, Position3d } from "../../../types/blocks/attributes"

interface Props {
  isOpen: boolean
  close: () => void
  blockTemplateId?: string
  pageSectionTypes: PageSectionType[]
}

const BlockTemplateDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  blockTemplateId,
  pageSectionTypes,
}) => {
  const { createBlockTemplate, loading, error: createError } = useCreateBlockTemplate()
  const {
    updateBlockTemplate,
    loading: updateLoading,
    error: updateError,
  } = useUpdateBlockTemplate()
  const {
    deleteBlockTemplate,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteBlockTemplate()

  const { getBlockTemplate, blockTemplate, loading: blockTemplateLoading } = useGetBlockTemplate()

  const { getPageById } = useGetPageById()
  const { updatePageContent } = useUpdatePageContent()

  const pageSectionTypeMap = pageSectionTypes.reduce(
    (acc, curr) => {
      acc[curr.id] = curr
      return acc
    },
    {} as Record<number, PageSectionType>,
  )

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleDeleteBlockTemplate: any = async () => {
    if (blockTemplateId) {
      await deleteBlockTemplate(blockTemplateId, handleDeleteBlockTemplateSuccess)
    }
  }

  const findKeyPath = (
    obj: any,
    keyToFind: string,
    valueToFind: any,
    currentPath: string[] = [],
  ): string[] | null => {
    // Loop through all keys in the object
    for (let key in obj) {
      // Add the current key to the path
      const newPath = [...currentPath, key]

      // Check if the current key is the key we're looking for
      if (key === keyToFind) {
        // Check if the value matches the one we're looking for
        if (obj[key] === valueToFind) {
          return newPath // Found the key and value, return the path
        }
      }

      // If the current value is an object, recursively search it
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const result = findKeyPath(obj[key], keyToFind, valueToFind, newPath)
        if (result) {
          return result // If found in the nested object, return the path
        }
      }
    }

    return null // Key and value not found in the object
  }

  const findValueByPath = (obj: any, keys: string[]) => {
    let currentObj = obj
    for (const key of keys) {
      if (currentObj && typeof currentObj === "object" && key in currentObj) {
        currentObj = currentObj[key]
      } else {
        return undefined // Key path not valid in the object
      }
    }
    return currentObj
  }

  const refreshContent = async (pageId: number | null) => {
    try {
      if (pageId) {
        const page = await getPageById(pageId)

        if (page?.content) {
          const content = await updatePageContent(page.content)
          formik.setFieldValue("content", content)

          const fieldMapping = formik.values.field_mapping ?? {}
          if (!Object.keys(fieldMapping).length && content?.customData) {
            ;(content?.customData as GridBlockV2 | GridBlockV3 | GridBlockV4)?.children.forEach(
              (child: GridBlockV4.BlockOnGrid | BlockOnGridV3 | BlockOnGridV2) => {
                const { id, type } = child.block
                if (id) {
                  if (!fieldMapping[id]) {
                    fieldMapping[id] = {}
                  }

                  if (type === "v3-text") {
                    fieldMapping[id]["block.customData.text"] =
                      Object.keys(
                        pageSectionTypeMap[parseInt(formik.values.page_section_type_id, 10)]
                          ?.fields,
                      )[0] ?? "description"
                    fieldMapping[id]["block.customData.utf.content.value"] =
                      Object.keys(
                        pageSectionTypeMap[parseInt(formik.values.page_section_type_id, 10)]
                          ?.fields,
                      )[0] ?? "description"
                  } else if (type === "v4-image") {
                    fieldMapping[id]["block.customData"] = "image"
                  } else if (type === "youtube") {
                    fieldMapping[id]["block.customData"] = "video"
                  } else if (type === "soundcloud") {
                    fieldMapping[id]["block.customData"] = "song"
                  } else if (type === "v3-buy") {
                    fieldMapping[id]["block.customData.price"] = "price"
                    fieldMapping[id]["block.customData.itemName"] = "name"
                    fieldMapping[id]["block.customData.text"] = "{{ price }}"
                    fieldMapping[id]["block.customData.utf.content.value"] = "{{ price }}"
                  } else if (type === "v2-map") {
                    fieldMapping[id]["block.customData.address"] = "address"
                    fieldMapping[id]["block.customData.latitude"] = "latitude"
                    fieldMapping[id]["block.customData.longitude"] = "longitude"
                  } else if (type === "v3-link") {
                    fieldMapping[id]["block.customData.socialHandle"] = "handle"
                  } else if (type === "v3-button") {
                    fieldMapping[id]["block.customData.text"] = "text"
                    fieldMapping[id]["block.customData.utf.content.value"] = "text"
                  }
                }
              },
            )

            formik.setFieldValue("field_mapping", fieldMapping)
          }

          toast.success("Successfully refreshed content")
        }
      }
    } catch (e) {
      toast.error(`Error refreshing content: ${e}`)
    }
  }

  const handleDeleteBlockTemplateSuccess: any = async () => {
    toast.success("Successfully deleted block template")
    close()
  }

  const handleJsonChange: any = async (
    key: string,
    value: any,
    parent: { [key: string]: any },
    data: any,
  ) => {
    // console.log("handleJsonChange", key, value, parent, data)

    if (value) {
      const keyPath = findKeyPath(data, key, value)

      if (keyPath?.length) {
        const fieldMapping = formik.values.field_mapping ?? {}

        const blockRoute = keyPath.slice(0, keyPath.indexOf("block"))
        const blockPath = keyPath.slice(keyPath.indexOf("block"))

        const blockOnGrid = findValueByPath(data, blockRoute)
        const { id } = blockOnGrid.block

        if (!fieldMapping[id]) {
          fieldMapping[id] = {}
        }

        fieldMapping[id][blockPath.join(".")] = "description"
        formik.setFieldValue("field_mapping", fieldMapping)
      }
    }
    while (Object.hasOwn(data, "root")) {
      data = data.root
    }
    formik.setFieldValue("content", data)
  }

  const handleJsonFieldMappingChange: any = async (
    key: string,
    value: any,
    parent: any,
    data: any,
  ) => {
    // console.log("handleJsonFieldMappingChange", key, value, parent, data)

    while (Object.hasOwn(data, "root")) {
      data = data.root
    }

    formik.setFieldValue("field_mapping", data)
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      field_mapping: {} as { [key: string]: { [key: string]: string } },
      page_section_type_id: "",
      priority: 500,
      template_group: "",
      content: {} as PageContent,
      page_id: null as number | null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!blockTemplateId) {
        const updateData: UpdateBlockTemplate = {
          name: values.name,
          field_mapping: values.field_mapping,
          page_section_type_id: values.page_section_type_id,
          priority: values.priority,
          template_group: values.template_group,
          content: values.content,
          page_id: values.page_id,
        }

        try {
          await updateBlockTemplate(blockTemplateId, updateData)

          handleUpdateBlockTemplateSuccess()
        } catch (e: unknown) {
          toast.error(`Error updating block template: ${e}`)
          console.log(e)
        }
      } else {
        try {
          await createBlockTemplate({
            name: values.name,
            field_mapping: values.field_mapping,
            page_section_type_id: values.page_section_type_id,
            priority: values.priority,
            template_group: values.template_group,
            page_id: values.page_id,
          })

          handleCreateBlockTemplateSuccess()
        } catch (e) {
          console.log(e)
        }
      }
    },
  })

  const handleCreateBlockTemplateSuccess: any = async () => {
    toast.success("Successfully created block template")
    close()
  }

  const handleUpdateBlockTemplateSuccess: any = async () => {
    toast.success("Successfully updated block template")
    close()
  }

  const deleteBlock = (index: number) => {
    const content = formik.values.content as PageContent

    const gridBlock = (content?.customData as GridBlockV2 | GridBlockV3)?.children[index]

    const fieldMapping = formik.values.field_mapping ?? {}
    if (gridBlock.block.id) {
      delete fieldMapping[gridBlock.block.id]
    }

    formik.setFieldValue("content", content)
    formik.setFieldValue("field_mapping", fieldMapping)
  }

  const handleGenerationGroupChange = (index: number, value: string) => {
    const content = formik.values.content as PageContent

    const { block } = (content?.customData as GridBlockV3)?.children[index]

    if (!block.tags) {
      block.tags = []
    }

    if (value && value !== "") {
      block.tags.push(`generationGroup:${value}`)
    } else {
      block.tags = block.tags.filter((x) => !x.includes(`generationGroup:${value}`))
    }

    formik.setFieldValue("content", content)
  }

  const handleToggle = (index: number, key: string = "doNotResize", value: boolean) => {
    const content = formik.values.content as PageContent

    const { block } = (content?.customData as GridBlockV3)?.children[index]

    if (!block.tags) {
      block.tags = []
    }

    if (value) {
      block.tags.push(key)
    } else {
      block.tags = block.tags.filter((x) => x !== key)
    }

    formik.setFieldValue("content", content)
  }

  useEffect(() => {
    if (blockTemplateId) {
      getBlockTemplate(blockTemplateId)
    }
    // eslint-disable-next-line
  }, [blockTemplateId])

  useEffect(() => {
    if (blockTemplate) {
      formik.setValues({
        name: blockTemplate.name,
        field_mapping: blockTemplate.field_mapping,
        page_section_type_id: blockTemplate.page_section_type_id.toString(),
        priority: blockTemplate.priority,
        template_group: blockTemplate.template_group,
        content: blockTemplate.content,
        page_id: blockTemplate.page_id,
      })
    }
  })

  const positionSort = (a: Position3d, b: Position3d) => {
    if (a.y !== b.y) {
      return a.y - b.y
    } else if (a.x !== b.x) {
      return a.x - b.x
    } else {
      return a.z - b.z
    }
  }

  return (
    <Dialog
      title={
        !!blockTemplateId
          ? `${blockTemplateId} - Update ${formik.values.name}`
          : "Create BlockTemplate"
      }
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading || updateLoading || deleteLoading || blockTemplateLoading}
      maxWidth="lg"
      content={
        <>
          {createError ||
            updateError ||
            (deleteError && <ErrorComponent error={createError || updateError || deleteError} />)}

          <div>
            <FormControl fullWidth>
              Name
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                variant="outlined"
                required
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
              />
            </FormControl>
            <FormControl>
              Priority
              <TextField
                margin="dense"
                id="priority"
                name="priority"
                fullWidth
                variant="outlined"
                required
                onChange={formik.handleChange}
                value={formik.values.priority}
                error={!!formik.errors.priority}
              />
            </FormControl>
            <FormControl>
              Template Group
              <TextField
                margin="dense"
                id="template_group"
                name="template_group"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.template_group}
                error={!!formik.errors.template_group}
              />
            </FormControl>
            <FormControl>
              Page
              <TextField
                margin="dense"
                id="page_id"
                name="page_id"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.page_id}
                error={!!formik.errors.page_id}
              />
              <Button onClick={() => refreshContent(formik.values.page_id)}>Refresh</Button>
            </FormControl>
            <FormControl fullWidth>
              Page Section
              <Select
                fullWidth
                name="page_section_type_id"
                onChange={formik.handleChange}
                value={formik.values.page_section_type_id ?? ""}
                error={!!formik.errors.page_section_type_id}
                label="Page Section"
                margin="dense"
                variant="outlined"
              >
                <MenuItem key={""} value={""}>
                  -- Select Page Section --
                </MenuItem>
                {pageSectionTypes
                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                  .map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <p>
              Fields:{" "}
              {JSON.stringify(
                pageSectionTypeMap[parseInt(formik.values.page_section_type_id)]?.fields,
              )}
            </p>
          </div>
          <div>
            Field Mapping:
            <JSONEditor
              data={{ root: formik.values.field_mapping }}
              onChange={handleJsonFieldMappingChange}
            />
          </div>
          <div>
            <br />
            <BasicTable
              columns={[
                "ID",
                "Type",
                "Size",
                "Position",
                "Text",
                "Do Not Resize",
                "Generation Group",
                "Action",
              ]}
              rows={(
                formik.values.content?.customData as GridBlockV4 | GridBlockV3 | GridBlockV2
              )?.children
                .sort((a, b) => positionSort(a.position, b.position))
                .map(
                  (child: GridBlockV4.BlockOnGrid | BlockOnGridV3 | BlockOnGridV2, idx: number) => (
                    <BlockTemplateContentRow
                      child={child}
                      idx={idx}
                      handleGenerationGroupChange={handleGenerationGroupChange}
                      handleToggle={handleToggle}
                      deleteBlock={deleteBlock}
                      pageSectionType={
                        pageSectionTypeMap[parseInt(formik.values.page_section_type_id)]
                      }
                    />
                  ),
                )}
            />
          </div>
          <div>
            <br />
            Content:
            <JSONEditor
              data={{ root: formik.values.content }}
              onChange={handleJsonChange}
              view="dual"
            />
          </div>
        </>
      }
      buttonContent={
        <>
          {blockTemplateId ? (
            <Button onClick={handleDeleteBlockTemplate} color="error" variant="contained">
              Delete Block Template
            </Button>
          ) : null}
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={(x) => {
              console.log(x)
              formik.submitForm()
            }}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading || deleteLoading}
          >
            {!!blockTemplateId ? "Update Block Template" : "Create Block Template"}
          </Button>
        </>
      }
    />
  )
}

export default BlockTemplateDialog
