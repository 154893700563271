import { PAGETYPESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageType } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPageTypeById = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, pageTypeId: number): Promise<PageType> => {
      return makeRequest<PageType>(`${PAGETYPESV5}/${pageTypeId}`, {}, { handleUnauthenticated })
    },
  )

  return { loading, error, getPageTypeById: performAction, pageType: data }
}

export default useGetPageTypeById
