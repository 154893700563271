import { useEffect, FunctionComponent } from "react"
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer } from "@mui/material"
import RelativeDate from "../../components/shared/RelativeDate"
import Tags from "../../components/shared/Tags"
import PRNumber from "../../components/shared/PRNumber"
import Commit from "../../components/shared/Commit"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import { web, services } from "../../constants/services"
import useGetDeploymentsLabels from "./hooks/useGetDeploymentsLabels"

const TektonDeployments: FunctionComponent = () => {
  return (
    <ScreenContainer title="Deployments">
      <Deployments />
    </ScreenContainer>
  )
}

function get(object: Record<string, any>, key: string, def: any = undefined): any {
  const result = key
    .split(".")
    .reduce((acc, key) => (typeof acc === "object" ? acc[key] : def), object)

  return !!result ? result : def
}

const Deployments: FunctionComponent = () => {
  const { performAction, data, error, loading } = useGetDeploymentsLabels()

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (data === null) {
    return <ErrorComponent error={new Error("Data is null")} />
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>Commit</TableCell>
            {/* <TableCell>Branch</TableCell> */}
            <TableCell>PR</TableCell>
            <TableCell>Release</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Deployed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={7} align="center">
              <b>Web</b>
            </TableCell>
          </TableRow>
          {web.map((service) => (
            <TableRow key={service}>
              <TableCell>
                <code>{service}</code>
              </TableCell>
              <TableCell>
                <Commit commit={data[service]["commit"]} />
              </TableCell>
              <TableCell>
                <PRNumber
                  prnumber={get(data, `${service}.prNumber`, "N/A")}
                  branch={get(data, `${service}.branch`, "N/A")}
                />
              </TableCell>
              <TableCell>
                <Tags tags={get(data, `${service}.release`, "")} />
              </TableCell>
              <TableCell>
                <code>{get(data, `${service}.lastDeployedBy`, "tekton")}</code>
              </TableCell>
              <TableCell>
                <RelativeDate milliseconds={parseInt(get(data, `${service}.date`, 0), 10) * 1000} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={7} align="center">
              <b>Backend</b>
            </TableCell>
          </TableRow>
          {services.map((service) => (
            <TableRow key={service}>
              <TableCell>
                <code>{service}</code>
              </TableCell>
              <TableCell>
                <Commit commit={data[service]["commit"]} />
              </TableCell>
              <TableCell>
                <PRNumber
                  prnumber={get(data, `${service}.prNumber`, "N/A")}
                  branch={get(data, `${service}.branch`, "N/A")}
                />
              </TableCell>
              <TableCell>
                <Tags tags={get(data, `${service}.release`, "")} />
              </TableCell>
              <TableCell>
                <code>{get(data, `${service}.lastDeployedBy`, "tekton")}</code>
              </TableCell>
              <TableCell>
                <RelativeDate milliseconds={parseInt(get(data, `${service}.date`, 0), 10) * 1000} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TektonDeployments
