import { Box } from "@mui/material"
import  { FunctionComponent } from "react"
import { Title2, Subhead, Title3 } from "../../../components/shared/Typography"
import { OrderDetail } from "../../../types"
import { formatPrice } from "../../../utils/formatters"
import { Row, StyledText } from "./OrderLayoutShared"

interface Props {
  order: OrderDetail | undefined
}

const OrderBuyerOverview: FunctionComponent<Props> = ({ order }) => {
  if (!order) {
    return null
  }

  return (
    <Box
      sx={{
        minWidth: "300px",
        maxWidth: "400px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
        marginTop: "10px",
        marginBottom: "10px",
        marginRight: "10px",
      }}
    >
      <Title2>Buyer:</Title2>
      <Row>
        <Subhead>Name: </Subhead>
        <StyledText>{order.buyer_name}</StyledText>
      </Row>
      <Row>
        <Subhead>Email: </Subhead>
        <StyledText>{order.buyer_email}</StyledText>
      </Row>
      <Row>
        <Subhead>Phone: </Subhead>
        <StyledText>{order.phone_number}</StyledText>
      </Row>
      <br />
      <Title3>Shipping:</Title3>
      {order.shipping_rate ? (
        <Row>
          <Subhead>Rate: </Subhead>
          <StyledText>{formatPrice(order.shipping_rate)}</StyledText>
        </Row>
      ) : null}
      {order.shipping_info ? (
        <>
          <Row>{order.shipping_info.name}</Row>
          <Row>{order.shipping_info.address.line1}</Row>
          <Row>{order.shipping_info.address.line2}</Row>
          <Row>
            {order.shipping_info.address.city}
            {", "}
            {order.shipping_info.address.state} {order.shipping_info.address.postal_code}
            {", "}
            {order.shipping_info.address.country}
          </Row>
        </>
      ) : (
        <Row>No shipping information found</Row>
      )}
    </Box>
  )
}

export default OrderBuyerOverview
