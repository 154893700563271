import { useEffect, useState } from "react"
import { NOTES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Note } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetDomainNotes = (defaultRowsPerPage = 5) => {
  const [domainId, setDomainId] = useState<number>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    loading,
    error,
    data,
    performAction: getDomainNotes,
  } = useHandleRequest(
    (handleUnauthenticated, incomingDomainId: number): Promise<PagedResponse<Note>> => {
      setDomainId(incomingDomainId)

      return makeRequest<PagedResponse<Note>>(
        `${NOTES}?limit=${encodeURIComponent(rowsPerPage)}&offset=${encodeURIComponent(
          page * rowsPerPage,
        )}&type=domain&type_id=${incomingDomainId}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getDomainNotes(domainId!)
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getDomainNotes,
    notes: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetDomainNotes
