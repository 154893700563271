import { Button, Checkbox } from "@mui/material"
import React, { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { scopes } from "../../../scopes"
import { useAuth } from "../../../services/auth-service"
import { DeadLetterEvent } from "../../../types/events"

type DeadletterCardProps = {
  deadletter: DeadLetterEvent
  replayEvent: any
  deleteEvent: any
  handleChecked: any
  checked: boolean
  deleteDisabled: boolean
  retryDisabled: boolean
  hidden: boolean
}

const DeadLetterRow: FunctionComponent<DeadletterCardProps> = ({
  deadletter,
  replayEvent,
  handleChecked,
  deleteEvent,
  checked,
  deleteDisabled,
  retryDisabled,
  hidden,
}) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.deadletterEvent.delete)
  const canReplay = hasScope(scopes.deadletterEvent.retry)

  const [fullBody, setFullBody] = React.useState(false)

  const bodyType = JSON.parse(JSON.parse(deadletter.body).Message).body?.type
  const body = JSON.stringify(JSON.parse(JSON.parse(deadletter.body).Message).body, null, 2)

  const commaPosition = body?.indexOf(",", 40)

  let shortBody = body
  if (commaPosition && commaPosition > -1) {
    shortBody = body.slice(0, Math.min(commaPosition, 120))
  }

  if (hidden === true) {
    return null
  }

  return (
    <BasicTableRow key={deadletter.id} hidden={hidden}>
      <BasicTableCell component="th" scope="row">
        {deadletter.id}
      </BasicTableCell>
      <BasicTableCell>{deadletter.date}</BasicTableCell>
      <BasicTableCell>
        {deadletter.topic} <br />
        <i>{bodyType}</i>
      </BasicTableCell>
      <BasicTableCell>{deadletter.endpoint?.split(":").slice(-1)[0]}</BasicTableCell>
      <BasicTableCell>{deadletter.request_id}</BasicTableCell>

      <BasicTableCell style={{ overflow: "auto", maxWidth: 400 }}>
        {commaPosition > -1 ? (
          <Button style={{ float: "right" }} onClick={(x) => setFullBody(!fullBody)}>
            ...
          </Button>
        ) : (
          ""
        )}
        <pre style={{ whiteSpace: "pre-wrap" }}>{fullBody ? body : shortBody}</pre>
      </BasicTableCell>

      {(canReplay || canDelete) && (
        <BasicTableCell>
          {canReplay && (
            <Button
              style={{
                fontWeight: "bold",
                alignSelf: "center",
              }}
              onClick={replayEvent}
              disabled={retryDisabled}
            >
              Replay
            </Button>
          )}
          {canDelete && (
            <Button
              style={{
                fontWeight: "bold",
                alignSelf: "center",
              }}
              onClick={deleteEvent}
              disabled={deleteDisabled}
            >
              Delete
            </Button>
          )}
        </BasicTableCell>
      )}

      <BasicTableCell>
        <Checkbox checked={checked} onChange={handleChecked} />
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default DeadLetterRow
