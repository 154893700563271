import { Button } from "@mui/material"
import { FunctionComponent, useCallback } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { RemoteValue } from "../../../types"

interface Props {
  remoteValue: RemoteValue
  onEditSelect: (item: RemoteValue) => any
  canEdit: boolean
}

const RemoteValueRow: FunctionComponent<Props> = ({ remoteValue, onEditSelect, canEdit }) => {
  const handleSelect = useCallback(() => {
    onEditSelect(remoteValue)
  }, [onEditSelect, remoteValue])

  return (
    <BasicTableRow sx={{ width: "100%" }}>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "300px",
        }}
      >
        {remoteValue.key}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "600px",
        }}
      >
        {remoteValue.value}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          maxWidth: "300px",
          wordWrap: "break-word",
        }}
      >
        {remoteValue?.description}
      </BasicTableCell>
      <BasicTableCell
        component="th"
        scope="row"
        style={{
          whiteSpace: "normal",
          maxWidth: "100px",
          wordWrap: "break-word",
        }}
      >
        {remoteValue.created_at}
      </BasicTableCell>

      {canEdit && (
        <BasicTableCell
          component="th"
          scope="row"
          style={{
            whiteSpace: "normal",
            maxWidth: "100px",
            wordWrap: "break-word",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSelect}>
            Edit
          </Button>
        </BasicTableCell>
      )}
    </BasicTableRow>
  )
}

export default RemoteValueRow
