import React, { useState, useEffect } from "react"
import { toast } from "react-hot-toast"

interface KonamiCodeWrapperProps {
  onSuccess: () => void
}

const KonamiCodeWrapper: React.FC<KonamiCodeWrapperProps> = ({ onSuccess }) => {
  const [konamiCodeIndex, setKonamiCodeIndex] = useState<number>(0)

  const konamiCodeSequence: string[] = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "b",
    "a",
  ]

  // Check if code is correct
  const checkKonamiCode = (): void => {
    if (konamiCodeIndex === konamiCodeSequence.length) {
      toast.success("Activated")
      setKonamiCodeIndex(0) // Reset the index after successful entry
      onSuccess() // Perform the easter egg
    }
  }

  // Event listener to track key presses
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      const key = event.key.toLowerCase()
      if (!key) {
        return
      }

      if (key === konamiCodeSequence[konamiCodeIndex].toLowerCase()) {
        setKonamiCodeIndex((prevIndex) => prevIndex + 1)
      } else {
        setKonamiCodeIndex(0) // Reset the index if the wrong key is pressed
      }
    }

    window.addEventListener("keydown", handleKeyPress)

    checkKonamiCode()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  })

  return null
}

export default KonamiCodeWrapper
