import config from "../../../config"
import { SUBSCRIPTIONSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Subscription } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateCreditSubscription = () => {
  const skProductId =
    config.environmentName === "production" ? "v2.pro.c.lifetime.1" : "v2.pro.b.lifetime.1.dev" // it works

  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      userId: number,
      siteId: number,
      promoCode: string,
      onSuccess?: () => any,
    ): Promise<Subscription> => {
      const response = await makeRequest<Subscription>(
        `${SUBSCRIPTIONSV1}/v2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "universe-user-id": `${userId}`,
          },
          body: JSON.stringify({
            site_id: siteId,
            promo_code: promoCode,
            sk_product_id: skProductId,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createCreditSubscription: performAction, loading, error }
}

export default useCreateCreditSubscription
