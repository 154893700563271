import { Button, DialogContentText, TextField } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import UserSearchAutocomplete from "../../Users/components/UserSearchAutocomplete"
import SitesByUserSelect from "../../Sites/components/SitesByUserSelect"
import * as Yup from "yup"
import useTransferInDomain from "../hooks/useTransferInDomain"
import { stripDomainJunk } from "../../../utils/formatters"
import { Row } from "./DomainLayoutShared"
import config from "../../../config"

type TransferInDomainDialogProps = {
  close: () => any
  isOpen: boolean
}

const TransferInDomainDialog: FunctionComponent<TransferInDomainDialogProps> = ({
  close,
  isOpen,
}) => {
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams()
  const [error, setError] = useState<Error>()

  const { transferIn, loading, error: requestError } = useTransferInDomain()

  const validationSchema = Yup.object().shape({
    domain: Yup.string().required(),
    authCode: Yup.string().required(),
    siteOption: Yup.object().required(),
    userOption: Yup.object().required(),
  })

  const formik = useFormik({
    initialValues: {
      domain: "",
      authCode: "",
      userOption: "",
      siteOption: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      try {
        const junklessDomain = stripDomainJunk(values.domain)
        await transferIn(
          junklessDomain,
          values.authCode,
          values.userOption.user?.id,
          values.siteOption.id,
          close,
        )
        setSearchParams({ type: "url", term: junklessDomain, source: "transferin" })
      } catch (err) {
        setError(enforceError(err))
      }
    },
  })

  return (
    <Dialog
      title="Transfer In Domain"
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      content={
        <>
          {error || requestError ? <br /> : null}

          <DialogContentText>
            Use this form to transfer in a domain using the authorization code (EPP).
          </DialogContentText>
          <br />
          <DialogContentText>
            <b>Note:</b> If the domain is already connected (externally) to our system, the entered
            User ID and Site ID must match for the transfer to begin.
          </DialogContentText>
          <TextField
            margin="dense"
            id="domain"
            label="Domain"
            fullWidth
            required
            variant="outlined"
            onChange={formik.handleChange}
            value={stripDomainJunk(formik.values.domain)}
            error={!!formik.errors.domain}
          />
          <TextField
            margin="dense"
            id="authCode"
            label="Authorization Code"
            fullWidth
            required
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.authCode}
            error={!!formik.errors.authCode}
          />
          <FormikProvider value={formik}>
            <UserSearchAutocomplete />
            <SitesByUserSelect
              userFieldName="userOption"
              siteFieldName="siteOption"
              highlightAssigned
            />
          </FormikProvider>
          {formik.values.domain && formik.values.siteOption.id ? (
            <Row>
              <a
                href={`https://web.${config.environmentName === "production" ? "univer.se" : "onuniverse-staging.com"}/sites/${formik.values.siteOption.id}/domains/claim?domain=${formik.values.domain}&onboarding=false&context=buy-domain-existing-site`}
                target="_blank"
                rel="noreferrer"
              >
                Add Subscription Link
              </a>
            </Row>
          ) : null}
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={formik.submitForm} color="primary" variant="outlined">
              Transfer In Domain
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default TransferInDomainDialog
