import { styled } from "@mui/material/styles"
import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"
import { DNSimpleZone } from "../../../types"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  record: DNSimpleZone
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const ConfirmDNSDeletionDialog: FunctionComponent<Props> = ({ record, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Warning"
      errorMessage={hasError ? "There was a problem deleting this DNS record" : null}
      affirmativeButtonText="Delete Record"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>You are about to delete the following DNS record:</TextBody>
          </ConfirmationRow>
          <ConfirmationRow>
            <StyledText>{record}</StyledText>
          </ConfirmationRow>
          <ConfirmationRow pb="15px" pt="8px">
            <TextBody>Would you like to proceed with deletion?</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default ConfirmDNSDeletionDialog
