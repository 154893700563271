import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  domainID: string | number
}

const RemoveVercelDialog: FunctionComponent<Props> = ({ domainID, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Remove domain from Vercel"
      errorMessage={hasError ? "There was a problem removing this domain from Vercel" : null}
      affirmativeButtonText="Remove from Vercel"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>
              You are about to remove this domain from Vercel. Doing so will remove the SSL
              certificates and cause the site to be disconnected from the project.
            </TextBody>
          </ConfirmationRow>
          <ConfirmationRow pt="8px">
            <TextBody>
              The domain will no longer display a Universe site and will instead show a "deployment
              not found" error. If you're having trouble getting the domain to work, you may remove
              it from the project and then try adding it back.
            </TextBody>
          </ConfirmationRow>
          <ConfirmationRow pb="15px" pt="8px">
            <TextBody>Are you sure you want to remove this domain from Vercel?</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default RemoveVercelDialog
