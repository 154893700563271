import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { BlockTemplate, PageSectionType } from "../../../types"
import BlockTemplateRow from "./BlockTemplateRow"

interface Props {
  blockTemplates: BlockTemplate[]
  paginationData: PaginationData
  canEdit: boolean
  pageSectionTypes: PageSectionType[]
  getBlockTemplates: () => any
}

const BlockTemplatesTable: FunctionComponent<Props> = ({
  blockTemplates,
  paginationData,
  pageSectionTypes,
  canEdit,
  getBlockTemplates,
}) => {
  const columns = canEdit
    ? ["ID", "Name", "Section", "Group", "Created", "Enabled", "Action", "Tags"]
    : ["ID", "Name", "Section", "Group", "Created"]

  const getRows = useCallback(() => {
    return blockTemplates.map((blockTemplate: BlockTemplate) => (
      <BlockTemplateRow
        key={blockTemplate.id}
        blockTemplate={blockTemplate}
        canEdit={canEdit}
        pageSectionTypes={pageSectionTypes}
        getBlockTemplates={getBlockTemplates}
      />
    ))
    // eslint-disable-next-line
  }, [blockTemplates, canEdit, pageSectionTypes])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default BlockTemplatesTable
