import { WEBV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Page } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPageById = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, pageId: number): Promise<Page> => {
      return makeRequest<Page>(`${WEBV4}/pages/${pageId}`, {}, { handleUnauthenticated })
    },
  )

  return { loading, error, getPageById: performAction, page: data }
}

export default useGetPageById
