import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Coupon } from "../../../types"
import CouponRow from "./CouponRow"

interface Props {
  coupons: Coupon[]
  paginationData: PaginationData
}

const CouponsTable: FunctionComponent<Props> = ({ coupons, paginationData }) => {
  const columns = [
    "Coupon ID",
    "Name",
    "Discount Key",
    "Stripe Coupon ID",
    "Duration",
    "Duration (Months)",
    "% Off",
    "Amount Off",
    "Currency",
    "Created At",
    "Updated At",
  ]

  const getCouponRows = useCallback(() => {
    return coupons.map((coupon: Coupon, index: number) => <CouponRow key={index} coupon={coupon} />)
  }, [coupons])

  return <BasicTable columns={columns} rows={getCouponRows()} paginationOptions={paginationData} />
}

export default CouponsTable
