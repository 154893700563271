import { useCallback, useEffect, FunctionComponent } from "react"
import { Box, Button, Stack, CircularProgress } from "@mui/material"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import PipelinesTable from "./components/PipelinesTable"
import PipelineRunsTable from "./components/PipelineRunsTable"
import useGetPipelineById from "./hooks/useGetPipelineById"
import useGetPipelinePipelineRuns from "./hooks/useGetPipelinePipelineRuns"
import useRetry from "./hooks/useRetry"
import config from "../../config"
import OhShitHamid from "../../components/images/oh-shit-hamid.jpg"

const TektonPipelines: FunctionComponent = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>()
  const getPipeline = useGetPipelineById()
  const getPipelinePipelineRuns = useGetPipelinePipelineRuns()
  const retry = useRetry()

  const getPipelinePipelineRunsCallback = useCallback(() => {
    if (!pipelineId) {
      return
    }
    retry.execute(getPipelinePipelineRuns.performAction, pipelineId)
  }, [retry, getPipelinePipelineRuns, pipelineId])

  useEffect(() => {
    if (!pipelineId) {
      return
    }
    retry.execute(getPipeline.performAction, pipelineId)
    retry.execute(getPipelinePipelineRuns.performAction, pipelineId)
    // eslint-disable-next-line
  }, [])

  if (getPipeline.loading) {
    return (
      <ScreenContainer title={`Tekton Pipeline - ${pipelineId} - Fetching...`}>
        <LoadingSpinner />
      </ScreenContainer>
    )
  }

  // Tekton does not work in the production cluster
  if (config.environmentName === "production") {
    return (
      <ScreenContainer title="Environment Error">
        <img
          height={100}
          width={100}
          style={{ marginTop: 5 }}
          src={OhShitHamid}
          alt={"Oh Shit Hamid"}
        />
        <pre style={{ color: "red", fontSize: 18 }}>
          Tekton is not configured to run in the production K8s cluster. Please use a staging build
          of Internal Tools to complete DevOps tasks.
        </pre>
        <Button
          onClick={() => (window.location.href = "https://tools.onuniverse-staging.com/tekton")}
        >
          Take me there 🏄‍♂️
        </Button>
      </ScreenContainer>
    )
  }

  if (retry.error) {
    return (
      <ScreenContainer title={`Tekton Pipeline - ${pipelineId} - Error`}>
        {getPipeline.error && <ErrorComponent error={getPipeline.error} />}
        {getPipelinePipelineRuns.error && <ErrorComponent error={getPipelinePipelineRuns.error} />}
      </ScreenContainer>
    )
  }

  if (!getPipeline.data) {
    return (
      <ScreenContainer title={`Tekton Pipeline - ${pipelineId} - Not Found`}>
        <Box>{`Pipeline ${pipelineId} not found`}</Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer title="Tekton Pipelines">
      <Box style={{ marginTop: 20 }}>
        <PipelinesTable pipelines={[getPipeline.data]} />
        <Stack direction="row" spacing={2} alignItems="center" justifyItems="center" mt={2} mb={2}>
          <Button
            disabled={getPipelinePipelineRuns.loading}
            variant="contained"
            color="primary"
            onClick={getPipelinePipelineRunsCallback}
          >
            Refresh
          </Button>
          {getPipelinePipelineRuns.loading ? <CircularProgress size={20} /> : null}
        </Stack>
        <PipelineRunsTable pipelineRuns={getPipelinePipelineRuns.data} />
      </Box>
    </ScreenContainer>
  )
}

export default TektonPipelines
