import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { PageType } from "../../../types"
import PageTypeRow from "./PageTypeRow"

interface Props {
  pageTypes: PageType[]
  paginationData: PaginationData
  canEdit: boolean
  canDelete: boolean
  refreshData: () => void
}

const PageTypesTable: FunctionComponent<Props> = ({
  pageTypes,
  paginationData,
  canEdit,
  canDelete,
  refreshData,
}) => {
  const getColumns = useCallback(() => {
    const columns = ["ID", "Icon", "Name", "Min. Version", "Max Version", "Tags"]
    if (canEdit) {
      columns.push("Edit")
    }
    return columns
  }, [canEdit])

  const getProductRows = useCallback(() => {
    return pageTypes.map((pageType: PageType, index: number) => (
      <PageTypeRow
        key={pageType.id}
        pageType={pageType}
        canEdit={canEdit}
        canDelete={canDelete}
        refreshData={refreshData}
      />
    ))
  }, [pageTypes, canEdit, canDelete])

  return (
    <BasicTable columns={getColumns()} rows={getProductRows()} paginationOptions={paginationData} />
  )
}

export default PageTypesTable
