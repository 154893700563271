import { AIIMAGEGENERATIONSTYLES } from "../../../constants/urlDefs"
import { useEffect, useState } from "react"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { AIImageGenerationStyle } from "../../../types"

const useListAIImageGenerationStyles = (defaultRowsPerPage = 100) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: listAIImageGenerationStyles,
  } = useHandleRequest((handleUnauthenticated): Promise<PagedResponse<AIImageGenerationStyle>> => {
    return makeRequest<PagedResponse<AIImageGenerationStyle>>(
      `${AIIMAGEGENERATIONSTYLES}?limit=${encodeURIComponent(
        rowsPerPage,
      )}&offset=${encodeURIComponent(page * rowsPerPage)}`,
      {},
      { handleUnauthenticated },
    )
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    listAIImageGenerationStyles()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    listAIImageGenerationStyles,
    aiImageGenerationStyles: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useListAIImageGenerationStyles
