import { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Button, Switch } from "@mui/material"
import { BlockOnGrid as BlockOnGridV3 } from "../../../types/blocks/grid/v3/grid-block-v3"
import { BlockOnGrid as BlockOnGridV2 } from "../../../types/blocks/grid/v2/grid-block-v2"
import { TextBlockV3 } from "../../../types/blocks/custom-data/text/text-block-v3"
import { TextBlockV2 } from "../../../types/blocks/custom-data/text/text-block-v2"
import { ButtonBlockV3 } from "../../../types/blocks/custom-data/button/button-block-v3"
import { DownloadBlockV3 } from "../../../types/blocks/custom-data/download/download-block-v3"
import { PayPalBlockV2 } from "../../../types/blocks/custom-data/paypal/paypal-block-v2"
import { PageSectionType } from "../../../types"
import { GridBlockV4 } from "../../../types/blocks/grid/v4/grid-block-v4"

type BlockContentRowProps = {
  child: GridBlockV4.BlockOnGrid | BlockOnGridV3 | BlockOnGridV2
  idx: number
  handleToggle: (idx: number, key: string, value: boolean) => void
  handleGenerationGroupChange: (idx: number, value: string) => void
  deleteBlock: (idx: number) => void
  pageSectionType: PageSectionType
}

const BlockTemplateContentRow: FunctionComponent<BlockContentRowProps> = ({
  child,
  idx,
  handleToggle,
  handleGenerationGroupChange,
  deleteBlock,
  pageSectionType,
}) => {
  return (
    <BasicTableRow key={child.block.id}>
      <BasicTableCell>{child.block.id}</BasicTableCell>
      <BasicTableCell>{child.block.type}</BasicTableCell>
      <BasicTableCell>
        {child.size.width}x{child.size.height}
      </BasicTableCell>
      <BasicTableCell>
        ({child.position.x}, {child.position.y}, {child.position.z})
      </BasicTableCell>
      <BasicTableCell style={{ overflow: "auto" }}>
        <p style={{ maxHeight: "12em", whiteSpace: "pre-wrap" }}>
          {
            (
              child.block.customData as
                | TextBlockV3
                | TextBlockV2
                | ButtonBlockV3
                | DownloadBlockV3
                | PayPalBlockV2
            ).text
          }
        </p>
      </BasicTableCell>
      <BasicTableCell>
        <Switch
          checked={!!child.block.tags?.find((x) => x === "doNotResize") ?? false}
          onChange={(evt) => handleToggle(idx, "doNotResize", evt.target.checked)}
        />{" "}
      </BasicTableCell>
      <BasicTableCell>
        <select
          name="generationGroup"
          onChange={(evt) => handleGenerationGroupChange(idx, evt.target.value)}
        >
          <option value="">-- Select Generation Group --</option>
          {Object.keys(pageSectionType.fields).map((field) => (
            <option
              value={field}
              selected={!!child.block.tags?.find((x) => x === `generationGroup:${field}`) ?? false}
            >
              {field}
            </option>
          ))}
        </select>
      </BasicTableCell>
      <BasicTableCell>
        <Button onClick={() => deleteBlock(idx)}>Delete</Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default BlockTemplateContentRow
