import { Box } from "@mui/material"
import  { FunctionComponent, useEffect } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SitePageOverview from "./components/SitePageOverview"
import useGetSitePageById from "./hooks/useGetSitePageById"

const SitePageDetailsScreen: FunctionComponent = () => {
  const { siteId, pageId } = useParams<{
    siteId: string
    pageId: string
  }>()
  const { loading: pageLoading, error: pageError, getPageById, page } = useGetSitePageById()

  useEffect(() => {
    getPageById(Number(siteId), Number(pageId))
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title={page?.title ? page?.title : `Page ${page?.id}`}>
      {pageLoading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {pageError && <ErrorComponent error={pageError} />}
      <SitePageOverview
        page={page}
        handleGetPage={() => getPageById(Number(siteId), Number(pageId))}
      />
    </ScreenContainer>
  )
}

export default SitePageDetailsScreen
