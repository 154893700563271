import { Button, DialogContentText, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"

type CreateDNSRecordProps = {
  close: () => any
  isOpen: boolean
  handleCreate: (record: any, callback: () => any) => any
  hasError: boolean
  loading: boolean
}

const CreateDNSRecordDialog: FunctionComponent<CreateDNSRecordProps> = ({
  close,
  isOpen,
  handleCreate,
  hasError,
  loading,
}) => {
  const [name, setName] = useState<string | null>(null)
  const [recordType, setRecordType] = useState<string | null>(null)
  const [priority, setPriority] = useState<number | null>(null)
  const [content, setContent] = useState<string | null>(null)
  const [error, setError] = useState<Error>()

  const handleClose: any = () => {
    setName("")
    setRecordType("")
    setPriority(0)
    setContent("")
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: name,
      record_type: recordType,
      priority: priority,
      content: content,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      try {
        // Validation check
        if (!recordType) {
          setError(enforceError("Type cannot be empty"))
          return
        }

        if (recordType !== "ALIAS" && !name) {
          setError(enforceError("Name cannot be empty"))
          return
        }

        if (recordType === "CNAME" && name === "@") {
          setError(
            enforceError(
              "CNAME records cannot be set at the root level. Please use an ALIAS record instead.",
            ),
          )
          return
        }

        if (recordType === "MX" && !priority) {
          setError(enforceError("Priority cannot be empty"))
          return
        }

        if (!content) {
          setError(enforceError("Content cannot be empty"))
          return
        }

        // Format values based on different types
        let formattedName = name
        if (recordType === "ALIAS") {
          formattedName = " "
        }

        // Format the record based on formatted values
        const formattedRecord = {
          name: formattedName,
          record_type: recordType,
          priority: priority,
          content: content,
        }
        await handleCreate(formattedRecord, handleClose)
      } catch (err) {
        setError(enforceError(err))
      }
    },
  })

  return (
    <Dialog
      title="Create DNS Record"
      isOpen={isOpen}
      handleClose={close}
      error={error}
      loading={loading}
      content={
        <>
          {error || hasError ? <br /> : null}
          <DialogContentText>
            Each record type will have different requiremets on what data is required. Be sure to
            complete each field as requested by the type.
          </DialogContentText>
          <br />
          <DialogContentText>
            <b>Note: </b>Domains created from Internal Tools <b>will not</b> appear inside the app
            for the user to manage.
          </DialogContentText>
          <br />
          <InputLabel>Record Type</InputLabel>
          <Select
            fullWidth
            name="type"
            onChange={(event) => setRecordType(event.target.value as string)}
            value={recordType}
            label="Type"
            margin="dense"
            variant="standard"
          >
            {[
              { record_type: "A" },
              { record_type: "AAAA" },
              { record_type: "ALIAS", name: "ALIAS (@ CNAME)" },
              { record_type: "CNAME" },
              { record_type: "MX" },
              { record_type: "TXT" },
            ].map((record_type) => (
              <MenuItem key={record_type.record_type} value={record_type.record_type}>
                {`${record_type.name ? record_type.name : record_type.record_type}`}
              </MenuItem>
            ))}
          </Select>
          {recordType !== "ALIAS" ? (
            <TextField
              margin="dense"
              id="name"
              label="Name (www, etc.)"
              fullWidth
              variant="standard"
              onChange={(x) => setName(x.target.value)}
            />
          ) : null}
          {recordType === "MX" ? (
            <TextField
              margin="dense"
              id="priority"
              label="Priority"
              fullWidth
              variant="standard"
              type="number"
              onChange={(x) => setPriority(Number(x.target.value))}
            />
          ) : null}
          <TextField
            margin="dense"
            id="content"
            label="Content"
            fullWidth
            variant="standard"
            onChange={(x) => setContent(x.target.value)}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={formik.submitForm} color="primary">
              Create Record
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default CreateDNSRecordDialog
