import  { FunctionComponent, useCallback } from "react"
import { DNSimpleZone } from "../../../types"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Button } from "@mui/material"

interface Props {
  zone: DNSimpleZone
  deleteZone: any
}

const DNSimpleZoneRow: FunctionComponent<Props> = ({ zone, deleteZone }) => {
  const handleDeleteDNSRecord = useCallback(() => {
    deleteZone(zone.id)
  }, [zone, deleteZone])

  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {zone.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {zone.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {zone.type}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {zone.ttl}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {zone.zone_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {zone.parent_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {zone.content}
      </BasicTableCell>
      <BasicTableCell>
        <Button
          style={{
            fontWeight: "bold",
            alignSelf: "center",
          }}
          disabled={zone.type === "NS" || zone.type === "SOA"}
          onClick={handleDeleteDNSRecord}
        >
          Delete
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default DNSimpleZoneRow
