import { FunctionComponent, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import DisputesTable from "./components/DisputesTable"
import MerchantOverview from "./components/MerchantOverview"
import PayoutsTable from "./components/PayoutsTable"
import useGetMerchantById from "./hooks/useGetMerchantById"
import useGetMerchantDisputes from "./hooks/useGetMerchantDisputes"
import useGetMerchantPayoutHistory from "./hooks/useGetMerchantPayoutHistory"
import CreateNoteDialog from "../Users/components/CreateNoteDialog"
import { Box } from "@mui/system"
import { Title3 } from "../../components/shared/Typography"
import { IconButton } from "@mui/material"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import NotesTable from "../Users/components/NotesTable"
import useGetMerchantNotes from "./hooks/useGetMerchantNotes"
import useGetMerchantPayoutSchedule from "./hooks/useGetMerchantPayoutSchedule"
import MerchantRejectedBanner from "./components/MerchantRejectedBanner"

const MerchantDetails: FunctionComponent = () => {
  const { merchantId } = useParams<{ merchantId: string }>()

  const [createNoteOpen, setCreateNoteOpen] = useState(false)

  const {
    merchant,
    getMerchantById,
    error: merchantError,
    loading: merchantLoading,
  } = useGetMerchantById()

  const {
    getMerchantDisputes,
    loading: disputesLoading,
    error: disputesError,
    disputes,
  } = useGetMerchantDisputes()

  const {
    getMerchantPayoutHistory,
    loading: payoutsLoading,
    error: payoutsError,
    payouts,
  } = useGetMerchantPayoutHistory()

  const { getMerchantNotes, paginationData, notes } = useGetMerchantNotes()

  const { getMerchantPayoutSchedule, payoutSchedule } = useGetMerchantPayoutSchedule()

  const handleCreateNoteClose = async () => {
    setCreateNoteOpen(false)
    await getMerchantNotes(Number(merchantId))
  }

  useEffect(() => {
    if (!merchantId) {
      return
    }
    getMerchantById(Number(merchantId))
    getMerchantDisputes(Number(merchantId))
    getMerchantPayoutHistory(Number(merchantId))
    getMerchantNotes(Number(merchantId))
    getMerchantPayoutSchedule(Number(merchantId))
    // eslint-disable-next-line
  }, [merchantId])

  const refreshMerchantData = () => {
    getMerchantById(Number(merchantId))
    getMerchantDisputes(Number(merchantId))
    getMerchantPayoutHistory(Number(merchantId))
    getMerchantNotes(Number(merchantId))
    getMerchantPayoutSchedule(Number(merchantId))
  }

  if (merchantLoading || disputesLoading || payoutsLoading) {
    return (
      <div
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (merchantError) {
    return <ErrorComponent error={merchantError} />
  }

  if (!merchant || !payoutSchedule) {
    return null
  }

  return (
    <ScreenContainer title="Merchant">
      {!payoutSchedule.charges_enabled &&
        !payoutSchedule.payouts_enabled &&
        payoutSchedule.disabled_reason && (
          <MerchantRejectedBanner merchant={merchant} reason={payoutSchedule.disabled_reason} />
        )}
      <Box
        my="15px"
        sx={{
          my: "15px",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        display="flex"
      >
        <MerchantOverview
          data={merchant}
          payoutSchedule={payoutSchedule}
          refreshData={refreshMerchantData}
        />
        <Box sx={{ ml: 2 }}>
          <Title3>
            Merchant Notes{" "}
            <IconButton onClick={() => setCreateNoteOpen(true)} sx={{ padding: 0, margin: 0 }}>
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Title3>
          <NotesTable
            notes={notes}
            paginationData={paginationData}
            refreshData={() => getMerchantById(Number(merchantId))}
          />
        </Box>
      </Box>
      {disputesError ? (
        <ErrorComponent error={disputesError} />
      ) : (
        <DisputesTable disputes={disputes} />
      )}
      {payoutsError ? <ErrorComponent error={payoutsError} /> : <PayoutsTable payouts={payouts} />}
      {createNoteOpen && merchantId ? (
        <CreateNoteDialog
          isOpen={createNoteOpen}
          close={handleCreateNoteClose}
          type="merchant"
          typeId={Number(merchantId)}
        />
      ) : null}
    </ScreenContainer>
  )
}

export default MerchantDetails
