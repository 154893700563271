import { Button, DialogContentText } from "@mui/material"
import { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import { DomainDiagnostic } from "../../../types"
import useValidateDomain from "../hooks/useValidateDomain"

type ValidateDomainDialogProps = {
  domainDiagnostics: DomainDiagnostic
  close: () => any
  isOpen: boolean
}

const ValidateDomainDialog: FunctionComponent<ValidateDomainDialogProps> = ({
  domainDiagnostics,
  close,
  isOpen,
}) => {
  const [error, setError] = useState<Error>()

  const { validateDomain, loading, error: requestError } = useValidateDomain()

  const requestDomainValidation = async () => {
    try {
      await validateDomain(domainDiagnostics.domain.id, close)
    } catch (err) {
      setError(enforceError(err))
    }
  }

  return (
    <Dialog
      title={`Validate domain (${domainDiagnostics.domain.url})`}
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      loading={loading}
      content={
        <>
          {error || requestError ? <br /> : null}
          <DialogContentText>
            Validating a domain will run it through a series of events to check if this domain is
            truly live on the internet or not. It will then update the status accordingly as well as
            setup DNS records (and other domain setup functions) if it determines that this is
            necessary. This does not cover all issues, but should be able to get a domain into the
            proper state for how it is currently setup.
          </DialogContentText>
          <br />
          <DialogContentText>Would you like to continue?</DialogContentText>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={requestDomainValidation} color="primary" disabled={loading}>
            Validate Domain
          </Button>
        </>
      }
    />
  )
}

export default ValidateDomainDialog
