import dayjs from "dayjs"
import { EXPERIMENT } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Experiment } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetExperiments = () => {
  const { data, loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, onSuccess?: Function): Promise<Experiment[]> => {
    const results = await makeRequest<Experiment[]>(EXPERIMENT, {}, { handleUnauthenticated })

    const sortedResults = results.sort((a, b) =>
      dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1,
    )

    if (onSuccess) {
      await onSuccess(sortedResults)
    }

    return sortedResults
  })

  return { getExperiments: performAction, loading, error, experiments: data || [] }
}

export default useGetExperiments
