import { Box } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title3 } from "../../components/shared/Typography"
import { Package } from "../../types"
import useGetSiteById from "../Sites/hooks/useGetSiteById"
import DiscountsTable from "./components/DiscountsTable"
import LineItemsTable from "./components/LineItemsTable"
import OrderBuyerOverview from "./components/OrderBuyerOverview"
import OrderOverview from "./components/OrderOverview"
import PackageTable from "./components/PackageTable"
import VoidShippingLabelConfirmationDialog from "./components/VoidShippingLabelConfirmationDialog"
import useGetOrderById from "./hooks/useGetOrderById"
import useGetOrderPackages from "./hooks/useGetOrderPackages"

const OrderDetailsScreen: FunctionComponent = () => {
  const params: any = useParams()
  const orderId = Number(params.orderId)

  const { order, getOrderById, error: orderError, loading: orderLoading } = useGetOrderById()
  const { site, getSiteById, loading: siteLoading } = useGetSiteById()
  const {
    packages,
    getOrderPackages,
    error: packagesError,
    loading: packagesLoading,
  } = useGetOrderPackages()
  const [voidPackage, setPackage] = useState<Package>()

  useEffect(() => {
    getOrderById(orderId)
    getOrderPackages(orderId)
    // eslint-disable-next-line
  }, [orderId])

  const handleCloseVoidShippingLabelDialog = useCallback(() => {
    setPackage(undefined)
    getOrderById(orderId)
    getOrderPackages(orderId)
  }, [orderId, setPackage, getOrderById, getOrderPackages])

  if (orderLoading || packagesLoading || siteLoading) {
    return (
      <div
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (!order) {
    return null
  }

  if (!site && order) {
    getSiteById(order.site_id)
  }

  return (
    <ScreenContainer title={`Order ${orderId}`}>
      <Box
        sx={{
          my: "15px",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        display="flex"
      >
        <OrderBuyerOverview order={order} />
        <OrderOverview order={order} />
        {orderError ? <ErrorComponent error={orderError} /> : <span />}
      </Box>
      {order.discounts?.length! > 0 ? (
        <>
          <Title3>Discounts</Title3>
          <DiscountsTable discounts={order.discounts!} />
        </>
      ) : (
        <span />
      )}
      {packages?.length! > 0 ? (
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Title3>Packages</Title3>
          {packages && site && (
            <PackageTable
              packages={packages}
              voidShippingLabel={setPackage}
              merchantId={site.merchant_id}
            />
          )}
          {packagesError ? <ErrorComponent error={packagesError} /> : <span />}
        </Box>
      ) : (
        <span />
      )}
      <Box
        sx={{
          mt: "25px",
        }}
      >
        <Title3>Line Items</Title3>
        <LineItemsTable lineItems={order.line_items} />
      </Box>
      {!!voidPackage && (
        <VoidShippingLabelConfirmationDialog
          orderId={voidPackage.order_id}
          shipmentId={voidPackage.shipment_id}
          isOpen={!!voidPackage}
          close={handleCloseVoidShippingLabelDialog}
        />
      )}
    </ScreenContainer>
  )
}

export default OrderDetailsScreen
