import { styled } from "@mui/material/styles"

const SiteImage = styled("img")`
  max-height: 400px;
  object-fit: cover;
  display: block;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid black;
`

export default SiteImage
