export const vercel = ["public-client", "explore", "web-app"]

export const web = ["explore-client", "internal-tools"]

export const services = [
  "asset-worker",
  "auth-gateway",
  "aws-worker",
  "billing-worker",
  "cache-worker",
  "commerce-service",
  "commerce-worker",
  "data-etl-service",
  "data-etl-worker",
  "deadletter-worker",
  "domain-worker",
  "event-api",
  "event-worker",
  "image-proxy",
  "notification-api",
  "notification-worker",
  "render-api",
  "site-service",
  "site-worker",
  "support-api",
  "tracking-worker",
  "user-api",
  "user-worker",
  "webhook-gateway",
]
