import { Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { DBBlock, CreateDBBlock } from "../../../types"
import useCreateBlock from "../hooks/useCreateBlock"
import useDeleteBlock from "../hooks/useDeleteBlock"
import useUpdateBlock from "../hooks/useUpdateBlock"

interface Props {
  isOpen: boolean
  close: () => void
  blockUpdate?: DBBlock | undefined
}

const BlockDialog: FunctionComponent<Props> = ({ isOpen, close, blockUpdate }) => {
  const { createBlock, loading, error } = useCreateBlock()
  const { updateBlock, loading: updateLoading, error: updateError } = useUpdateBlock()
  const { deleteBlock, loading: deleteLoading, error: deleteError } = useDeleteBlock()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    author: Yup.string().required(),
    code: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleDeleteBlock: any = async () => {
    if (blockUpdate) {
      await deleteBlock(blockUpdate.id, handleDeleteBlockSuccess)
    }
  }

  const handleDeleteBlockSuccess: any = async () => {
    toast.success("Successfully deleted code snippet")
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      // fields: [],
      defaults: {},
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!blockUpdate) {
        const updateData: CreateDBBlock = {
          name: values.name,
          type: values.type,
          fields: values.fields,
          defaults: values.defaults,
        }

        await updateBlock(blockUpdate.id, updateData, handleUpdateBlockSuccess)
      } else {
        await createBlock(
          {
            name: values.name,
            type: values.type,
            fields: values.fields,
            defaults: values.defaults,
          },
          handleCreateBlockSuccess,
        )
      }
    },
  })

  const handleCreateBlockSuccess: any = async () => {
    toast.success("Successfully created code snippet")
    close()
  }

  const handleUpdateBlockSuccess: any = async () => {
    toast.success("Successfully updated code snippet")
    close()
  }

  useEffect(() => {
    if (blockUpdate) {
      formik.setValues({
        name: blockUpdate.name,
        type: blockUpdate.type,
        // fields: blockUpdate.fields,
        defaults: blockUpdate.defaults,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={!!blockUpdate ? `Update ${blockUpdate.name}` : "Create Block"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading || updateLoading || deleteLoading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {updateError && <ErrorComponent error={updateError} />}
          {deleteError && <ErrorComponent error={deleteError} />}
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!formik.errors.name}
            />
            <TextField
              margin="dense"
              id="type"
              name="type"
              label="Type"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.type}
              error={!!formik.errors.type}
            />
          </div>
        </>
      }
      buttonContent={
        <>
          {blockUpdate ? (
            <Button onClick={handleDeleteBlock} color="error" variant="contained">
              Delete Block
            </Button>
          ) : null}
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading || deleteLoading}
          >
            {!!blockUpdate ? "Update Block" : "Create Block"}
          </Button>
        </>
      }
    />
  )
}

export default BlockDialog
