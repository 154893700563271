import { Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { CodeSnippet, UpdateCodeSnippet } from "../../../types"
import useCreateCodeSnippet from "../hooks/useCreateCodeSnippet"
import useDeleteCodeSnippet from "../hooks/useDeleteCodeSnippet"
import useUpdateCodeSnippet from "../hooks/useUpdateCodeSnippet"

interface Props {
  isOpen: boolean
  close: () => void
  codeSnippetUpdate?: CodeSnippet | undefined
}

const CodeSnippetDialog: FunctionComponent<Props> = ({ isOpen, close, codeSnippetUpdate }) => {
  const { createCodeSnippet, loading, error } = useCreateCodeSnippet()
  const { updateCodeSnippet, loading: updateLoading, error: updateError } = useUpdateCodeSnippet()
  const { deleteCodeSnippet, loading: deleteLoading, error: deleteError } = useDeleteCodeSnippet()

  const [active, setActive] = useState<boolean>(true)

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    author: Yup.string().required(),
    code: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const handleDeleteCodeSnippet: any = async () => {
    if (codeSnippetUpdate) {
      await deleteCodeSnippet(codeSnippetUpdate.id, handleDeleteCodeSnippetSuccess)
    }
  }

  const handleDeleteCodeSnippetSuccess: any = async () => {
    toast.success("Successfully deleted code snippet")
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      author: "",
      code: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!codeSnippetUpdate) {
        const updateData: UpdateCodeSnippet = {
          title: values.title,
          author: values.author,
          code: values.code,
          active: active,
        }

        await updateCodeSnippet(codeSnippetUpdate.id, updateData, handleUpdateCodeSnippetSuccess)
      } else {
        await createCodeSnippet(
          {
            title: values.title,
            author: values.author,
            code: values.code,
            active: active,
          },
          handleCreateCodeSnippetSuccess,
        )
      }
    },
  })

  const handleCreateCodeSnippetSuccess: any = async () => {
    toast.success("Successfully created code snippet")
    close()
  }

  const handleUpdateCodeSnippetSuccess: any = async () => {
    toast.success("Successfully updated code snippet")
    close()
  }

  useEffect(() => {
    if (codeSnippetUpdate) {
      formik.setValues({
        title: codeSnippetUpdate.title,
        author: codeSnippetUpdate.author,
        code: codeSnippetUpdate.code,
      })
      setActive(codeSnippetUpdate.active)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={!!codeSnippetUpdate ? `Update ${codeSnippetUpdate.title}` : "Create Code Snippet"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading || updateLoading || deleteLoading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {updateError && <ErrorComponent error={updateError} />}
          {deleteError && <ErrorComponent error={deleteError} />}
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              name="title"
              label="Title"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
            />
            <TextField
              margin="dense"
              id="author"
              name="author"
              label="Author"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.author}
              error={!!formik.errors.author}
            />
            <TextField
              margin="dense"
              id="code"
              name="code"
              label="Code"
              fullWidth
              variant="outlined"
              required
              multiline
              onChange={formik.handleChange}
              value={formik.values.code}
              error={!!formik.errors.code}
              style={{ fontFamily: "monospace", fontSize: "1.2rem" }}
            />
            <FormControlLabel
              control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
              label="Show the code snippet in the editor"
            />
          </div>
        </>
      }
      buttonContent={
        <>
          {codeSnippetUpdate ? (
            <Button onClick={handleDeleteCodeSnippet} color="error" variant="contained">
              Delete Snippet
            </Button>
          ) : null}
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading || deleteLoading}
          >
            {!!codeSnippetUpdate ? "Update Snippet" : "Create Snippet"}
          </Button>
        </>
      }
    />
  )
}

export default CodeSnippetDialog
