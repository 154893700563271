import { Button } from "@mui/material"
import { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { UserGroupUser } from "../../../types"
import { Link } from "react-router-dom"

interface Props {
  user: UserGroupUser
  removeUser: (userId: number) => any
}

const UserGroupUserRow: FunctionComponent<Props> = ({ user, removeUser }) => {
  return (
    <BasicTableRow sx={{ width: "100%" }}>
      <BasicTableCell component="th" scope="row">
        {user.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Link to={`/ai/conversations?q=${user.user_id}`}>{user.user_id}</Link>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {user.email}
      </BasicTableCell>

      <BasicTableCell component="th" scope="row">
        {user.last_login}
      </BasicTableCell>

      <BasicTableCell component="th" scope="row">
        {user.analytics_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => removeUser(user.id)}
          sx={{ width: "80px" }}
        >
          Remove
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default UserGroupUserRow
