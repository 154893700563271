import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { BlockedDomainPhrases } from "../../../types"
import BlockedDomainPhrasesRow from "./BlockedDomainPhrasesRow"

interface Props {
  blockedDomainPhrases: BlockedDomainPhrases[]
  paginationData: PaginationData
  canDelete: boolean
}

const BlockedDomainPhrasesTable: FunctionComponent<Props> = ({
  blockedDomainPhrases,
  paginationData,
  canDelete,
}) => {
  const columns = ["ID", "Phrase", "Type", "Action"]

  const getRows = useCallback(() => {
    return blockedDomainPhrases.map((blockedDomainPhrase: BlockedDomainPhrases, index: number) => (
      <BlockedDomainPhrasesRow
        key={index}
        blockedDomainPhrase={blockedDomainPhrase}
        canDelete={canDelete}
      />
    ))
  }, [blockedDomainPhrases, canDelete])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default BlockedDomainPhrasesTable
