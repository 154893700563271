import { FunctionComponent, useCallback } from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { BillingProduct, Plan } from "../../../types"
import PlanRow from "./PlanRow"

interface Props {
  plans: Plan[]
  handleOpenEditPlan: (plan: Plan) => any
  products: BillingProduct[]
  canEdit: boolean
}

const PlansTable: FunctionComponent<Props> = ({ plans, handleOpenEditPlan, products, canEdit }) => {
  const columns = canEdit
    ? ["Id", "Title", "Description", "Product", "SKU", "Amount", "Active", "Edit"]
    : ["Id", "Title", "Description", "Product", "SKU", "Amount", "Active"]

  const getPlanRows = useCallback(() => {
    return plans.map((plan: Plan) => (
      <PlanRow
        key={plan.id}
        plan={plan}
        handleOpenEditPlan={handleOpenEditPlan}
        products={products}
        canEdit={canEdit}
      />
    ))
  }, [plans, handleOpenEditPlan, products, canEdit])

  return <BasicTable columns={columns} rows={getPlanRows()} />
}

export default PlansTable
