import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import Chip from "@mui/material/Chip"
import DeleteIcon from "@mui/icons-material/Delete"
import DragIcon from "@mui/icons-material/DragIndicator"

import { CSSProperties, useEffect, useMemo, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"
import ErrorComponent from "../../../components/shared/Error"
import { Archetype, Template } from "../../../types"
import enforceError from "../../../utils/enforce-error"
import toast from "react-hot-toast"
import useGetArchetypesForTemplate from "../hooks/useGetArchetypesForTemplate"
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner"
import useUpdateArchetypesForTemplateById from "../hooks/useUpdateArchetypesCategoryById"
import useListArchetypes from "../../Archetypes/hooks/useListArchetypes"

type Props = {
  template: Template
  onSave: () => void
  onCancel: () => void
}

const ArchetypeSelectionTags = ({
  archetypes,
  selectedArchetypeIds,
  onSelected,
  onDeselected,
}: {
  archetypes: Archetype[]
  selectedArchetypeIds: number[]
  onSelected: (archetype: Archetype) => void
  onDeselected: (archetype: Archetype) => void
}) => {
  const selectedArchetypeIdMap = useMemo(() => {
    return selectedArchetypeIds.reduce((acc, next) => {
      if (!acc[next.toString()]) {
        acc[next.toString()] = true
      }

      return acc
    }, {} as { [key: string]: boolean })
  }, [selectedArchetypeIds])

  const isArchetypeSelected = (archetype: Archetype) =>
    !!selectedArchetypeIdMap[archetype.id.toString()]

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 8,
        overflowY: "scroll",
      }}
    >
      {archetypes.map((archetype) => {
        const selected = isArchetypeSelected(archetype)
        const isOther = archetype.name === "other"

        return (
          <Chip
            key={`archetype-selection-tag-${archetype.id}`}
            label={archetype.display_name}
            variant={selected ? "filled" : "outlined"}
            color={selected ? "primary" : undefined}
            disabled={isOther}
            onClick={() => {
              if (selected) {
                onDeselected(archetype)
              } else {
                onSelected(archetype)
              }
            }}
          />
        )
      })}
    </div>
  )
}

const EditTemplateArchetypesDialogContent = ({ template, onSave, onCancel }: Props) => {
  const { id: templateId, display_name: categoryDisplayName } = template

  const [templateArchetypes, setTemplateArchetypes] = useState<Archetype[]>([])

  const selectedArchetypeIds = useMemo(
    () => templateArchetypes.map((a) => a.id),
    [templateArchetypes],
  )

  const [error, setError] = useState<Error>()

  const { updateArchetypesForTemplate, loading: updateLoading } =
    useUpdateArchetypesForTemplateById()

  const {
    getArchetypesForTemplate,
    archetypes: loadedTemplateArchetypes,
    loading: archetypesForTemplateLoading,
  } = useGetArchetypesForTemplate()

  const {
    listArchetypes,
    archetypes: allArchetypes,
    loading: archetypesLoading,
  } = useListArchetypes(1000)

  const load = async () => {
    await getArchetypesForTemplate(template)

    if (!allArchetypes) {
      await listArchetypes()
    }
  }

  useEffect(() => {
    load()
  }, [template])

  useEffect(() => {
    if (loadedTemplateArchetypes.length && allArchetypes.length) {
      const otherArchetype = allArchetypes.find((a) => a.name === "other")

      const selectedArchetypes = loadedTemplateArchetypes.sort((a, b) => {
        if (a.name === "other") {
          return 1
        }

        return 0
      })

      if (otherArchetype) {
        if (!selectedArchetypes.find((a) => a.id === otherArchetype.id)) {
          selectedArchetypes.push(otherArchetype)
        }
      }

      setTemplateArchetypes(selectedArchetypes)
    }
  }, [loadedTemplateArchetypes])

  const reorder = (list: Archetype[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: any | undefined) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#fafafa" : "white",
    display: "flex",
    alignItems: "center",
    gap: 8,

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
    background: isDraggingOver ? "lightblue" : "white",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  })

  const save = async () => {
    try {
      await updateArchetypesForTemplate(templateId, {
        archetype_ids: templateArchetypes.map((x) => x.id),
      })
      onSave()
      toast.success("Successfully updated category archetype")
    } catch (err) {
      console.error(err)
      setError(enforceError(err))
    }
  }

  const deleteArchetype = (archetype: Archetype) => {
    const items = templateArchetypes.filter((x) => x.id !== archetype.id)
    setTemplateArchetypes(items)
  }

  const addArchetype = (archetype: Archetype) => {
    const items = [...templateArchetypes]
    const other = items.pop()
    const t = allArchetypes.find((x) => x.id === archetype.id)
    if (t) {
      items.push(t)
    }
    items.push(other as Archetype)
    setTemplateArchetypes(items)
  }

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(templateArchetypes, result.source.index, result.destination.index)

    setTemplateArchetypes(items)
  }

  if (archetypesLoading || archetypesForTemplateLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <DialogTitle id="edit-category-archetypes-dialog-title">
        Edit <b>{categoryDisplayName}</b> Archetypes
      </DialogTitle>

      <DialogContent style={{ display: "flex", gap: 12 }}>
        {error ? <ErrorComponent error={error} /> : ""}

        {/* {JSON.stringify(allArchetypes)}
        {JSON.stringify(templateArchetypes)} */}

        <div style={{ flex: 1 }}>
          <h4>Selected Archetypes</h4>
          <p>
            Drag to reorder. The order shown here is how the archetypes will be displayed in NUX.
          </p>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {templateArchetypes.map((archetype, index) => {
                    const key = `archetype-${archetype.id.toString()}`
                    const isOther = archetype.name === "other"
                    return (
                      <Draggable key={key} draggableId={key} index={index} isDragDisabled={isOther}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div
                              style={{
                                width: 28,
                                height: 28,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {isOther ? null : <DragIcon />}
                            </div>

                            <div style={{ flex: 1 }}>
                              <Chip label={archetype.display_name} disabled={isOther} />
                            </div>

                            <div
                              style={{
                                width: 40,
                                height: 40,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {isOther ? null : (
                                <IconButton onClick={() => deleteArchetype(archetype)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div style={{ flex: 3 }}>
          <h4>All Archetypes</h4>
          <p>
            Selecting an archetype will add it to the selected archetypes list. Deselecting it will
            remove it.
          </p>
          <ArchetypeSelectionTags
            archetypes={allArchetypes}
            selectedArchetypeIds={selectedArchetypeIds}
            onSelected={addArchetype}
            onDeselected={deleteArchetype}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="error" variant="outlined">
          Cancel
        </Button>
        <Button onClick={save} color="primary" variant="outlined" disabled={updateLoading}>
          Save
        </Button>
      </DialogActions>
    </>
  )
}

const useEditTemplateArchetypesDialog = () => {
  const [template, setTemplate] = useState<Template>()

  const close = () => setTemplate(undefined)

  const dialog = template ? (
    <Dialog open={true} onClose={close} maxWidth="xl">
      <EditTemplateArchetypesDialogContent template={template} onSave={close} onCancel={close} />
    </Dialog>
  ) : null

  return {
    editTemplateArchetypesDialog: dialog,
    openEditTemplateArchetypesDialog: setTemplate,
  }
}

export default useEditTemplateArchetypesDialog
