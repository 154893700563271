import  { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { ImageStatus } from "../../../types"

interface RowProps {
  image: ImageStatus
}

const url = (el: ImageStatus) => {
  if (el.completed_at) {
    if (el.bucket === "universe-assets-production") {
      return `https://assets-production.onuniverse.cloud/${el.s3_key}`
    }
    return `https://assets-staging.onuniverse.cloud/${el.s3_key}`
  }
  if (el.latest) {
    return `https://image-yeeter.onuniverse.cloud${el.latest}`
  }
}

const NoContextRow: FunctionComponent<RowProps> = ({ image }) => {
  return (
    <BasicTableRow>
      <BasicTableCell>
        {image.completed_at ? (
          <a href={url(image)}>
            <img src={url(image)} width={250} alt={image.prompt || "perhaps nonsense?"} />
          </a>
        ) : (
          ""
        )}
      </BasicTableCell>
      <BasicTableCell>
        {image.prompt}
        <br />
        {image.completed_at ? (
          <a href={`https://yeeter-images.onuniverse.cloud${image.url}index.html`}>[History]</a>
        ) : (
          ""
        )}
      </BasicTableCell>
      <BasicTableCell>
        {image.started_at?.replace("T", " ") || image.created_at?.replace("T", " ")}
      </BasicTableCell>
      <BasicTableCell>{image.completed_at?.replace("T", " ")}</BasicTableCell>
      <BasicTableCell>{image.username}</BasicTableCell>
    </BasicTableRow>
  )
}

export default NoContextRow
