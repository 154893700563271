import  { FunctionComponent, useCallback } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { Product } from "../../../types"
import { formatPrice } from "../../../utils/formatters"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"

type SiteProductRowProps = {
  product: Product
}

const SiteProductRow: FunctionComponent<SiteProductRowProps> = ({ product }) => {
  const navigate = useNavigate()
  const navToProduct = useCallback(() => {
    navigate(`/sites/${product.site_id}/products/${product.id}`)
  }, [navigate, product])

  return (
    <BasicTableRow key={product.id}>
      <BasicTableCell component="th" scope="row">
        {product.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {product.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatPrice(product.price)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {product.notes_prompt}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(product.requires_shipping)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(product.requires_phone_number)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Button
          style={{
            fontWeight: "bold",
            alignSelf: "center",
          }}
          onClick={navToProduct}
        >
          View
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default SiteProductRow
