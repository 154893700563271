import { FAFOV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useFindethOut = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, query: string, onSuccess?: () => any): Promise<string> => {
      const fuckethAround = await makeRequest<string>(
        `${FAFOV1}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
          }),
        },
        { handleUnauthenticated },
      )

      const findethOut = JSON.stringify(fuckethAround, null, 2)

      if (onSuccess) {
        await onSuccess()
      }

      return findethOut
    },
  )

  return { findOut: performAction, loading, error, data }
}

export default useFindethOut
