import { SUBSCRIPTIONSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Subscription } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSubscriptionById = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, subscriptionId: number): Promise<Subscription> => {
    return makeRequest<Subscription>(`${SUBSCRIPTIONSV3}/${subscriptionId}`, {}, { handleUnauthenticated })
  })

  return { loading, error, getSubscriptionById: performAction, subscription: data }
}

export default useGetSubscriptionById
