import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import useGetBlockedDomainPhrases from "./hooks/useGetBlockedDomainPhrases"
import BlockedDomainPhrasesTable from "./components/BlockedDomainPhrasesTable"
import BlockedDomainPhraseDialog from "./components/BlockedDomainPhraseDialog"

const BlockedDomainPhrasesScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.domains.blockedPhrases.create)
  const canDelete = hasScope(scopes.domains.blockedPhrase.delete)

  const { loading, error, getBlockedDomainPhrases, blockedDomainPhrases, paginationData } =
    useGetBlockedDomainPhrases()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getBlockedDomainPhrases()
  }

  useEffect(() => {
    getBlockedDomainPhrases()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer
      title="Blocked Domain Phrases"
      requiresScope={scopes.domains.blockedPhrases.read}
    >
      {error ? <ErrorComponent error={error} /> : ""}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <br />
          {canCreate && (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "300px" }}
                onClick={handleClickOpen}
              >
                Create Blocked Domain Phrase
              </Button>
              <br />
            </>
          )}
          <BlockedDomainPhrasesTable
            blockedDomainPhrases={blockedDomainPhrases}
            paginationData={paginationData}
            canDelete={canDelete}
          />
          {open ? <BlockedDomainPhraseDialog isOpen={open} close={handleClose} /> : null}
        </>
      )}
    </ScreenContainer>
  )
}

export default BlockedDomainPhrasesScreen
