import { useCallback, useState } from "react"
import { useAuth } from "../services/auth-service"
import enforceError from "../utils/enforce-error"

type FunctionOmittingFirstParam<F> = F extends (
  handleUnauthenticated: () => Promise<void>,
  ...args: infer P
) => infer R
  ? (...args: P) => R
  : never

function useHandleRequest<
  ActionFunction extends (handleUnauthenticated: () => Promise<void>, ...args: any[]) => any,
>(action: ActionFunction) {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<Awaited<ReturnType<ActionFunction>> | null>(null)

  const auth = useAuth()

  const performAction = useCallback(
    async (...params: Parameters<FunctionOmittingFirstParam<ActionFunction>>) => {
      setLoading(true)
      setError(null)

      let results: Awaited<ReturnType<ActionFunction>> | undefined
      try {
        const nonOptionalResults = await action(auth.handleUnauthenticated, ...params)
        setData(nonOptionalResults)
        results = nonOptionalResults
      } catch (error) {
        setError(enforceError(error))
      } finally {
        setLoading(false)
      }

      return results
    },
    // eslint-disable-next-line
    [action],
  )

  return {
    data,
    error,
    loading,
    performAction,
  }
}

export default useHandleRequest
