import { Button } from "@mui/material"
import  { FunctionComponent, useCallback } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { BillingProduct } from "../../../types"

type BillingProductRowProps = {
  product: BillingProduct
  handleOpenEditProduct: (product: any) => any
  handleOpenEditEntitlements: (product: any) => any
  canEdit: boolean
  canEditEntitlements: boolean
}

const BillingProductRow: FunctionComponent<BillingProductRowProps> = ({
  product,
  handleOpenEditProduct,
  handleOpenEditEntitlements,
  canEdit,
  canEditEntitlements,
}) => {
  const openEditEntitlementsDialog = useCallback(() => {
    handleOpenEditEntitlements(product)
  }, [handleOpenEditEntitlements, product])

  const openEditProductDialog = useCallback(() => {
    handleOpenEditProduct(product)
  }, [handleOpenEditProduct, product])

  return (
    <BasicTableRow key={product.id}>
      <BasicTableCell component="th" scope="row">
        {product.title}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {product.description}
      </BasicTableCell>
      <BasicTableCell>{`${product.active}`}</BasicTableCell>
      {canEdit && (
        <BasicTableCell>
          <Button variant="outlined" color="primary" onClick={openEditProductDialog}>
            Edit Product
          </Button>
        </BasicTableCell>
      )}
      {canEditEntitlements && (
        <BasicTableCell>
          <Button variant="outlined" color="primary" onClick={openEditEntitlementsDialog}>
            Edit Entitlements
          </Button>
        </BasicTableCell>
      )}
    </BasicTableRow>
  )
}

export default BillingProductRow
