import { Box } from "@mui/material"
import  { FunctionComponent } from "react"
import CopyIcon from "../../../components/shared/CopyIcon"
import { Title3 } from "../../../components/shared/Typography"
import { Subdomain } from "../../../types"
import SiteLink from "../../Sites/components/SiteLink"
import UserLink from "../../Users/components/UserLink"
import { Row, StyledText } from "./DomainLayoutShared"

interface Props {
  subdomain: Subdomain | undefined
}

const DomainOverview: FunctionComponent<Props> = ({ subdomain }) => {
  if (!subdomain) {
    return null
  }

  return (
    <Box
      sx={{
        maxWidth: "400px",
        borderRadius: "8px",
        border: (theme) => `1px solid ${theme.colors.disabled}`,
        p: "15px",
      }}
    >
      <Row>
        <Title3>Name: </Title3>
        <StyledText>{subdomain.subdomain}</StyledText>
      </Row>
      <Row>
        <Title3>Id: </Title3>
        <StyledText>{subdomain.id}</StyledText>
        <CopyIcon content={subdomain.id} />
      </Row>
      {subdomain?.user_id && (
        <Row>
          <Title3>User Id: </Title3>
          <UserLink userId={subdomain.user_id}> {subdomain.user_id}</UserLink>
          <CopyIcon content={subdomain.user_id} />
        </Row>
      )}
      {subdomain?.site_id && (
        <Row>
          <Title3>Site Id: </Title3>
          <SiteLink siteId={subdomain.site_id}> {subdomain.site_id}</SiteLink>
          <CopyIcon content={subdomain.site_id} />
        </Row>
      )}
      <Row>
        <Title3>Status: </Title3>
        <StyledText>{subdomain.status}</StyledText>
      </Row>
      <Row>
        <Title3>Created At: </Title3>
        <StyledText>{subdomain.created_at}</StyledText>
      </Row>
    </Box>
  )
}

export default DomainOverview
