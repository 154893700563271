import { Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { useFormik } from "formik"
import  { FunctionComponent, useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { BillingProduct, UpdateBillingProduct } from "../../../types"
import useUpdateProduct from "../hooks/useUpdateProduct"

type BillingProductProps = {
  product: BillingProduct | undefined
  isOpen: boolean
  close: any
  handleGetProducts: () => any
}

const EditBillingProductDialog: FunctionComponent<BillingProductProps> = ({
  isOpen,
  close,
  handleGetProducts,
  product,
}) => {
  const { updateProduct, loading: updatingProduct, error } = useUpdateProduct()

  const handleUpdateSuccess = useCallback(() => {
    close()
    handleGetProducts()
    toast.success("Successfully updated product")
  }, [handleGetProducts, close])

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string(),
    active: Yup.boolean(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      active: false,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!product) {
        return
      }

      const item: UpdateBillingProduct = {
        ...values,
      }

      await updateProduct(product?.id, item, handleUpdateSuccess)
    },
  })

  useEffect(() => {
    if (!product) {
      formik.resetForm()
    } else {
      formik.setValues({
        title: product?.title,
        description: product?.description,
        active: product?.active || false,
      })
    }
    // eslint-disable-next-line
  }, [product])

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={`Edit ${product?.title || "Product"}`}
      aria-labelledby="edit-product-dialog"
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {!!error && (
              <TextBody hasError={!!error}>There was a problem updating the product.</TextBody>
            )}
          </Row>
          <Row mb={"17px"}>
            <TextField
              id="title"
              label="Title"
              type="text"
              variant="outlined"
              required
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
            />
          </Row>
          <Row>
            <TextField
              id="description"
              label="Description"
              fullWidth
              required
              type="text"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={!!formik.errors.description}
            />
          </Row>
          <Row>
            <FormControlLabel
              control={<Checkbox />}
              label="Active"
              checked={!!formik.values.active}
              name="active"
              onChange={() => formik.setFieldValue("active", !formik.values.active)}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={updatingProduct}
          >
            Save
          </Button>
        </>
      }
    ></Dialog>
  )
}
export default EditBillingProductDialog
