import { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { PaymentIntegration } from "../../../types"
import { Button } from "@mui/material"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"
import DeletePaymentIntegrationConfirmationDialog from "./DeletePaymentIntegrationConfirmationDialog"

type Props = {
  paymentIntegration: PaymentIntegration
}

const SitePaymentIntegrationRow: FunctionComponent<Props> = ({ paymentIntegration }) => {
  const [open, setOpen] = useState(false)
  const [hidden, setHidden] = useState(false)

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleDialogSuccess = () => {
    setOpen(false)
    setHidden(true)
  }

  // Auth scopes
  const { hasScope } = useAuth()
  const canDeleteIntegration = hasScope(scopes.paymentIntegration.delete)

  if (hidden) {
    return null
  }

  return (
    <BasicTableRow key={paymentIntegration.id}>
      <BasicTableCell component="th" scope="row">
        {paymentIntegration.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {paymentIntegration.type}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {paymentIntegration.integration_merchant_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {paymentIntegration.integration_merchant_email}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {paymentIntegration.primary_currency}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {paymentIntegration.status}
      </BasicTableCell>
      {canDeleteIntegration ? (
        <BasicTableCell component="th" scope="row">
          <Button
            style={{
              fontWeight: "bold",
              alignSelf: "center",
            }}
            onClick={() => setOpen(true)}
          >
            Delete Integration
          </Button>
        </BasicTableCell>
      ) : null}
      {open ? (
        <DeletePaymentIntegrationConfirmationDialog
          paymentIntegration={paymentIntegration}
          isOpen={open}
          close={handleDialogClose}
          handleSuccess={handleDialogSuccess}
        />
      ) : null}
    </BasicTableRow>
  )
}

export default SitePaymentIntegrationRow
