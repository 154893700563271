import makeRequest, { PagedResponse } from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Payout } from "../../../types"
import { MERCHANTSV3 } from "../../../constants/urlDefs"

const useGetMerchantPayoutHistory = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, merchantId: number): Promise<PagedResponse<Payout>> => {
    let queryString = `${MERCHANTSV3}/${merchantId}/payout-history`
    return makeRequest<PagedResponse<Payout>>(queryString, {}, { handleUnauthenticated })
  })

  return { getMerchantPayoutHistory: performAction, loading, error, payouts: data?.results || [] }
}

export default useGetMerchantPayoutHistory
