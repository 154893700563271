import { Box } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SearchInput from "../../components/shared/SearchInput"
import { Merchant } from "../../types"
import useSearchOrders from "./hooks/useSearchMerchants"
import MerchantTable from "./components/MerchantTable"

const MerchantSearchScreen: FunctionComponent = () => {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const [previousTerm] = useState<string>()
  const { searchMerchants, merchants, paginationData, loading, error } = useSearchOrders()

  const handleSuccessfulSearch = useCallback(
    (results: Merchant[], term: string) => {
      setSearchParams({ term: term })
      if (results?.length === 1) {
        navigate(`/merchants/${results[0].id}`)
      }
    },
    [navigate, setSearchParams],
  )

  const handleSearchMerchants = useCallback(
    async (term: string) => {
      await searchMerchants(term, handleSuccessfulSearch)
    },
    [searchMerchants, handleSuccessfulSearch],
  )

  useEffect(() => {
    const term = searchParams.get("term")
    if (term) {
      handleSearchMerchants(term)
    }
    // eslint-disable-next-line
  }, [])

  // This is a work around for an issue with updating the search term
  // directly from the handleSuccess callback resulting in react-router warnings
  useEffect(() => {
    if (previousTerm) {
      setSearchParams({ term: previousTerm })
    }

    // eslint-disable-next-line
  }, [previousTerm])

  const showTable = !loading && merchants.length > 1

  return (
    <ScreenContainer title="Merchant Search">
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <SearchInput
          handleSearch={handleSearchMerchants}
          title={`Search for Merchants by id, email, or stripe account id`}
          label={`Merchant Search`}
          disabledWhenEmpty
          defaultValue={searchParams.get("term")}
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      {showTable && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "500px" }}
        >
          <MerchantTable merchants={merchants} paginationData={paginationData} />
        </Box>
      )}
    </ScreenContainer>
  )
}

export default MerchantSearchScreen
