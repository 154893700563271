import { PLANSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BillingProduct, UpdatePlanRequest } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdatePlan = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      planId: number,
      plan: UpdatePlanRequest,
      onSuccess?: () => any,
    ): Promise<BillingProduct> => {
      const response = await makeRequest<BillingProduct>(
        `${PLANSV4}/${planId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(plan),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updatePlan: performAction, loading, error }
}

export default useUpdatePlan
