import { useEffect } from "react"
import { MapContainer, useMap } from "react-leaflet"
import L, { LatLngBoundsLiteral } from "leaflet"

const SvgOverlay = ({ svgData }: { svgData: string }) => {
  const map = useMap()

  useEffect(() => {
    if (!svgData) return

    const svgElement = new DOMParser().parseFromString(svgData, "image/svg+xml").documentElement
    const bounds: LatLngBoundsLiteral = [
      [-500, -500],
      [500, 1000],
    ]

    const svgOverlay = L.svgOverlay(svgElement as unknown as SVGElement, bounds)
    svgOverlay.addTo(map)

    return () => {
      map.removeLayer(svgOverlay)
    }
  }, [svgData, map])

  return null
}

const MapComponent = ({ data }: { data: string }) => {
  return (
    <MapContainer
      center={[0, 0]}
      zoom={1}
      crs={L.CRS.Simple}
      style={{ height: "calc(100vh - 14rem)" }}
    >
      <SvgOverlay svgData={data} />
    </MapContainer>
  )
}

export default MapComponent
