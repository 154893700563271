import { PROMOSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PromoRedemption } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPromoRedemptions = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, promoId: string | number): Promise<PromoRedemption[]> => {
    let queryString = `${PROMOSV1}/${promoId}/redemptions`
    return makeRequest<PromoRedemption[]>(queryString, {}, { handleUnauthenticated })
  })

  return { getPromoRedemptions: performAction, loading, error, redemptions: data }
}

export default useGetPromoRedemptions
