import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useRefreshPageSnapshot = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      siteId: number,
      pageId: number,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${SITESV4}/${siteId}/pages/${pageId}/invalidate-snapshots`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { refreshSnapshot: performAction, loading, error }
}

export default useRefreshPageSnapshot
