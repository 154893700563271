import { Button } from "@mui/material"
import { FunctionComponent, useState } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { PageType } from "../../../types"
import CreateEditPageTypeDialog from "./CreateEditPageTypeDialog"
import usePageTypeTagDialog from "./PageTypeTagDialog"
import { LoadingButton } from "@mui/lab"

type Props = {
  pageType: PageType
  canEdit: boolean
  canDelete: boolean
  refreshData: () => void
}

const PageTypeRow: FunctionComponent<Props> = ({ pageType, canEdit, canDelete, refreshData }) => {
  const { openPageTypeTagDialog, pageTypeTagDialogLoading, pageTypeTagDialog } =
    usePageTypeTagDialog()

  const [editOpen, setEditOpen] = useState(false)

  const handlePageUpdateClose = () => {
    setEditOpen(false)
  }

  const tagTitles = pageType.tags.map((tag) => tag.title).join(", ")

  return (
    <>
      <BasicTableRow key={pageType.id}>
        <BasicTableCell component="th" scope="row">
          {pageType.id}
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <img
            src={pageType.icon_url}
            height="50"
            width="50"
            alt={`${pageType.display_name} Icon`}
          />
        </BasicTableCell>
        <BasicTableCell>{pageType.display_name}</BasicTableCell>
        <BasicTableCell>{pageType.minimum_version}</BasicTableCell>
        <BasicTableCell>{pageType.maximum_version}</BasicTableCell>
        <BasicTableCell>{tagTitles}</BasicTableCell>
        <BasicTableCell>
          {canEdit && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                justifyContent: "flex-start",
              }}
            >
              <Button variant="outlined" color="primary" onClick={() => setEditOpen(true)}>
                Edit
              </Button>

              <LoadingButton
                loading={pageTypeTagDialogLoading}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => openPageTypeTagDialog(pageType)}
              >
                Tags
              </LoadingButton>
            </div>
          )}
        </BasicTableCell>
      </BasicTableRow>

      {pageTypeTagDialog}

      {editOpen && (
        <CreateEditPageTypeDialog
          isOpen={editOpen}
          close={handlePageUpdateClose}
          refreshData={refreshData}
          pageTypeUpdate={pageType}
          canDelete={canDelete}
        />
      )}
    </>
  )
}

export default PageTypeRow
