import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useState } from "react"
import toast from "react-hot-toast"
import ErrorComponent from "../../../components/shared/Error"
import { Category } from "../../../types"
import useUpdateCategoryById from "../hooks/useUpdateCategoryById"

type Props = {
  category: Category
  onSave: () => void
  onCancel: () => void
}

const EditCategoryDialogContent = ({ category, onSave, onCancel }: Props) => {
  const [displayName, setDisplayName] = useState<string>()

  const [iconUrl, setIconUrl] = useState<string>()

  const [sortOrder, setSortOrder] = useState<string>()
  const [archetypeSelectionDescription, setArchetypeSelectionDescription] = useState<string>()

  const { updateCategory, error } = useUpdateCategoryById()

  const save = async () => {
    await updateCategory(category.id, {
      display_name: displayName || category.display_name,
      icon_url: iconUrl || category.icon_url,
      sort_order: sortOrder || category.sort_order,
      archetype_selection_description:
        archetypeSelectionDescription || category.archetype_selection_description,
    })
    onSave()
    toast.success("Successfully edited category") // FIXME: there are some bugs around this - it will fire regardless of success of failure.
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Edit {category.display_name}</DialogTitle>

      <DialogContent>
        {error ? <ErrorComponent error={error} /> : ""}
        <TextField
          margin="normal"
          id="name"
          label="Name"
          fullWidth
          variant="outlined"
          defaultValue={category.display_name}
          onChange={(e) => setDisplayName(e.target.value)}
        />

        <TextField
          margin="normal"
          id="sort_order"
          label="Position"
          defaultValue={category.sort_order}
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => setSortOrder(e.target.value)}
        />
        <TextField
          margin="normal"
          id="icon_url"
          label="Icon URL (SVG)"
          defaultValue={category.icon_url}
          fullWidth
          variant="outlined"
          onChange={(e) => setIconUrl(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="archetypeSelectionDescription"
          label="Archetype Selection Description"
          defaultValue={category.archetype_selection_description}
          fullWidth
          variant="outlined"
          onChange={(e) => setArchetypeSelectionDescription(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="error" variant="outlined">
          Cancel
        </Button>
        <Button onClick={save} color="primary" variant="outlined">
          Save
        </Button>
      </DialogActions>
    </>
  )
}

const useEditCategoryDialog = (callbacks: { onSave: () => void }) => {
  const [category, setCategory] = useState<Category>()

  const close = () => setCategory(undefined)

  const dialog = category ? (
    <Dialog open={true} onClose={close}>
      <EditCategoryDialogContent category={category} onSave={callbacks.onSave} onCancel={close} />
    </Dialog>
  ) : null

  return {
    editCategoryDialog: dialog,
    openEditCategoryDialog: setCategory,
  }
}

export default useEditCategoryDialog
