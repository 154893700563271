import { Button, DialogContentText } from "@mui/material"
import { FunctionComponent, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import useTransferOutDomain from "../hooks/useTransferOutDomain"
import { DomainDiagnostic } from "../../../types"

type TransferOutDomainDialogProps = {
  domainDiagnostics: DomainDiagnostic
  close: () => any
  isOpen: boolean
}

const TransferOutDomainDialog: FunctionComponent<TransferOutDomainDialogProps> = ({
  domainDiagnostics,
  close,
  isOpen,
}) => {
  const [error, setError] = useState<Error>()

  const { transferOutDomain, loading, error: requestError } = useTransferOutDomain()

  const requestTransfer = async () => {
    try {
      await transferOutDomain(domainDiagnostics.domain.id, close)
    } catch (err) {
      setError(enforceError(err))
    }
  }

  const today = new Date()
  const domainCreated = new Date(
    domainDiagnostics && domainDiagnostics.dnsimple && domainDiagnostics.dnsimple.created_at,
  )
  const dateDifference = Math.abs(today.getTime() - domainCreated.getTime())
  const daysSinceDomainCreation = Math.ceil(dateDifference / (1000 * 3600 * 24))
  const canTransferOutDomain = daysSinceDomainCreation > 60
  const domainAgeString =
    daysSinceDomainCreation > 1000
      ? `old af 😮‍💨 (${daysSinceDomainCreation} days)`
      : `${daysSinceDomainCreation} day${daysSinceDomainCreation === 1 ? "" : "s"}`

  // TODO: Replace with a rad confirmation dialog
  return (
    <Dialog
      title={`Initiate Domain Transfer Out (${domainDiagnostics.domain.url})`}
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      loading={loading}
      content={
        <>
          {error || requestError ? <br /> : null}
          {canTransferOutDomain ? (
            <>
              <DialogContentText>
                The transfer out code will be sent to <b>cx@univer.se</b> if you proceed with this
                action.
              </DialogContentText>
              <br />
              <DialogContentText>
                <b>Domain age:</b> {domainAgeString}
              </DialogContentText>
              <br />
              <DialogContentText>Would you like to continue?</DialogContentText>
            </>
          ) : (
            <>
              <DialogContentText>
                Unable to initiate domain transfer out request. Domain does not meet the minimum age
                requirement of 60 days (current age: {domainAgeString}).
              </DialogContentText>
              <br />
              <DialogContentText>
                Please note that this policy is put into place after a domain registration contact
                has changed (transfer in) or the domain has been registered.
              </DialogContentText>
            </>
          )}
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button
            onClick={requestTransfer}
            color="primary"
            disabled={loading || !canTransferOutDomain}
          >
            Transfer Out
          </Button>
        </>
      }
    />
  )
}

export default TransferOutDomainDialog
