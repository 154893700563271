import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent } from "react"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { BillingProduct, CreatePlanRequest, UpdatePlanRequest } from "../../../types"

type PlanProps = {
  isOpen: boolean
  close: () => any
  hasError: boolean
  creatingPlan: boolean
  handleCreatePlan: (plan: CreatePlanRequest, callback: () => any) => any
  products: BillingProduct[]
  defaults?: UpdatePlanRequest
}

const CreatePlanDialog: FunctionComponent<PlanProps> = ({
  isOpen,
  close,
  hasError,
  products,
  creatingPlan,
  handleCreatePlan,
  defaults,
}) => {
  const validationSchema = Yup.object().shape({
    productId: Yup.number().integer().required(),
    title: Yup.string().required(),
    description: Yup.string().required(),
    variant: Yup.string().required(),
    amount: Yup.number().required(),
    currency: Yup.string().required(),
    duration: Yup.string().required(),
    active: Yup.boolean(),
    version: Yup.string().required(),
    group: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      productId: defaults?.product_id?.toString() ?? "",
      title: defaults?.title ?? "",
      description: defaults?.description ?? "",
      variant: defaults?.variant ?? "",
      amount: defaults?.price?.amount ?? "",
      currency: defaults?.price?.currency ?? "usd",
      duration: defaults?.duration ?? "",
      active: defaults?.active ?? false,
      version: defaults?.version ?? "",
      group: defaults?.group ?? "",
      domain_enabled: defaults?.domain_enabled ?? false,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const item: CreatePlanRequest = {
        product_id: Number(values.productId),
        title: values.title,
        description: values.description,
        variant: values.variant,
        price: {
          amount: Number(values.amount),
          currency: values.currency,
        },
        duration: values.duration,
        active: values.active,
        version: values.version,
        group: values.group,
        domain_enabled: values.domain_enabled,
      }

      await handleCreatePlan(item, handleClose)
    },
  })

  const handleDurationChange = (event: any) => {
    formik.setFieldValue("duration", event.target.value)
  }

  const handleVersionChange = (event: any) => {
    formik.setFieldValue("version", event.target.value)
  }

  const handleProductChange = (event: any) => {
    formik.setFieldValue("productId", parseInt(event.target.value, 10))
  }

  const handleChecked = async (event: any) => {
    formik.setFieldValue("active", event.target.checked)
  }

  const handleDomainChecked = async (event: any) => {
    formik.setFieldValue("domain_enabled", event.target.checked)
  }

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      title="Create New Plan"
      handleClose={handleClose}
      content={
        <Box width="400px">
          <Row height="8px" mb="4px">
            {!!hasError && (
              <TextBody hasError={!!hasError}>There was a problem updating the plan.</TextBody>
            )}
          </Row>
          <Row>
            <TextField
              margin="normal"
              id="title"
              name="title"
              label="Title"
              type="text"
              fullWidth
              required
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
            />
          </Row>
          <Row>
            <TextField
              margin="normal"
              id="description"
              name="description"
              label="Description"
              required
              variant="outlined"
              type="text"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.description}
              error={!!formik.errors.description}
            />
          </Row>
          <Row>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Product</InputLabel>
              <Select
                name="product"
                onChange={handleProductChange}
                value={formik.values.productId}
                label="Product"
                required
                error={!!formik.errors.productId}
              >
                {(products || []).map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Duration</InputLabel>
              <Select
                name="duration"
                required
                error={!!formik.errors.duration}
                onChange={handleDurationChange}
                value={formik.values.duration}
                label="Duration"
              >
                {["annual", "monthly", "lifetime"].map((d) => (
                  <MenuItem key={d} value={d}>
                    {`${d[0].toUpperCase()}${d.slice(1)}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <TextField
              margin="normal"
              id="amount"
              name="amount"
              type="number"
              label="Amount"
              required
              error={!!formik.errors.amount}
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.amount}
            />
          </Row>
          <Row>
            <TextField
              margin="normal"
              id="variant"
              label="Variant"
              name="variant"
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.variant}
              error={!!formik.errors.variant}
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              margin="normal"
              id="group"
              label="Group"
              name="group"
              required
              error={!!formik.errors.group}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.group}
            />
          </Row>
          <Row>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Version</InputLabel>
              <Select
                name="duration"
                onChange={handleVersionChange}
                value={formik.values.version}
                required
                error={!!formik.errors.version}
                label="Version"
              >
                {["v1", "v2", "v3"].map((versionType, idx) => (
                  <MenuItem key={idx} value={versionType}>
                    {`${versionType}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControlLabel
              sx={{ ml: "0px" }}
              control={<Checkbox checked={formik.values.active} />}
              label="Active"
              name="active"
              onChange={handleChecked}
            />
          </Row>
          <Row>
            <FormControlLabel
              sx={{ ml: "0px" }}
              control={<Checkbox checked={formik.values.domain_enabled} />}
              label="Domain Enabled"
              name="domain_enabled"
              onChange={handleDomainChecked}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={!!creatingPlan}
          >
            Create
          </Button>
        </>
      }
    />
  )
}
export default CreatePlanDialog
