import { DOMAINTLDPRICING } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DomainTldPricing, UpdateDomainTldPricing } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateDomainTldPricing = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainTldPricingId: number,
      domainTldPricing: UpdateDomainTldPricing,
      onSuccess?: Function,
    ): Promise<DomainTldPricing> => {
      const res = await makeRequest<DomainTldPricing>(
        `${DOMAINTLDPRICING}/${domainTldPricingId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(domainTldPricing),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { updateDomainTldPricing: performAction, loading, error }
}

export default useUpdateDomainTldPricing
