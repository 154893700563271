import { FunctionComponent, useEffect } from "react"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import PipelinesTable from "./components/PipelinesTable"
import useGetPipelines from "./hooks/useGetPipelines"
import config from "../../config"
import OhShitHamid from "../../components/images/oh-shit-hamid.jpg"
import { Button } from "@mui/material"

const TektonPipelines: FunctionComponent = () => {
  const { performAction, data, error, loading } = useGetPipelines()

  useEffect(() => {
    performAction()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ScreenContainer title="Tekton Pipelines - Fetching...">
        <LoadingSpinner />
      </ScreenContainer>
    )
  }

  // Tekton does not work in the production cluster
  if (config.environmentName === "production") {
    return (
      <ScreenContainer title="Environment Error">
        <img
          height={100}
          width={100}
          style={{ marginTop: 5 }}
          src={OhShitHamid}
          alt={"Oh Shit Hamid"}
        />
        <pre style={{ color: "red", fontSize: 18 }}>
          Tekton is not configured to run in the production K8s cluster. Please use a staging build
          of Internal Tools to complete DevOps tasks.
        </pre>
        <Button
          onClick={() => (window.location.href = "https://tools.onuniverse-staging.com/tekton")}
        >
          Take me there 🏄‍♂️
        </Button>
      </ScreenContainer>
    )
  }

  if (error) {
    return (
      <ScreenContainer title="Tekton Pipelines - Error">
        {error && <ErrorComponent error={error} />}
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer title="Tekton Pipelines">
      <PipelinesTable pipelines={data} />
    </ScreenContainer>
  )
}

export default TektonPipelines
