import { PROMOSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreatePromoRequest, Promo } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreatePromo = () => {
  const { loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, promo: CreatePromoRequest, onSuccess?: Function): Promise<Promo> => {
    const response = await makeRequest<Promo>(
      `${PROMOSV1}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(promo),
      },
      { handleUnauthenticated },
    )

    if (onSuccess) {
      await onSuccess(response)
    }

    return response
  })

  return { createPromo: performAction, loading, error }
}

export default useCreatePromo
