import { FunctionComponent } from "react"
import { Paper, Table, TableBody, TableContainer, TableHead } from "@mui/material"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import PipelineRow from "./PipelineRow"
import type { TektonPipeline } from "../../../types"

type PipelinesTableRowProps = {
  pipelines: TektonPipeline[]
}

const PipelinesTable: FunctionComponent<PipelinesTableRowProps> = ({ pipelines }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <BasicTableRow>
            <BasicTableCell>Name</BasicTableCell>
            <BasicTableCell>Status</BasicTableCell>
            <BasicTableCell>Description</BasicTableCell>
          </BasicTableRow>
        </TableHead>
        <TableBody>
          {pipelines.map((pipeline) => (
            <PipelineRow key={pipeline.metadata.name} pipeline={pipeline}></PipelineRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PipelinesTable
