import { useCallback, useEffect, useState } from "react"
import { PROMOSV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Promo } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetPromos = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, searchVal?: string | undefined): Promise<PagedResponse<Promo>> => {
    let queryString = `${PROMOSV1}?limit=${encodeURIComponent(
      rowsPerPage,
    )}&offset=${encodeURIComponent(page * rowsPerPage)}`

    if (searchVal) {
      queryString += `&search=${searchVal}`
    }

    return makeRequest<PagedResponse<Promo>>(queryString, {}, { handleUnauthenticated })
  })

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    performAction(searchValue)
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const getPromos = useCallback(
    async (searchVal?: string, offset = 0) => {
      setSearchValue(searchVal || undefined)
      return performAction(searchVal)
    },
    [performAction],
  )

  return {
    getPromos,
    loading,
    error,
    promos: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetPromos
