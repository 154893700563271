import { PAGETYPESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageType } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeletePageType = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, pageTypeId: number, onSuccess?: () => any): Promise<PageType> => {
      const response = await makeRequest<PageType>(
        `${PAGETYPESV5}/${pageTypeId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deletePageType: performAction, loading, error }
}

export default useDeletePageType
