import { TEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TemplatePage, UpdateTemplatePage } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateTemplatePage = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      templateId: number,
      templatePageId: number,
      templatePage: UpdateTemplatePage,
    ): Promise<TemplatePage> => {
      return makeRequest<TemplatePage>(
        `${TEMPLATES}/${templateId}/pages/${templatePageId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(templatePage),
        },
        { handleUnauthenticated },
      )
    },
  )

  return { updateTemplatePage: performAction, loading, error }
}

export default useUpdateTemplatePage
