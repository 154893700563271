import { ORDERSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Package } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetOrderPackages = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, orderId: number): Promise<Package[]> => {
    return makeRequest<Package[]>(`${ORDERSV3}/${orderId}/packages`, {}, { handleUnauthenticated })
  })

  return { loading, error, getOrderPackages: performAction, packages: data }
}

export default useGetOrderPackages
