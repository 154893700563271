import { RENDERUPDATE } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PageContent } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdatePageContent = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      content: PageContent,
      onSuccess?: () => any,
    ): Promise<PageContent> => {
      const snippet = await makeRequest<PageContent>(
        `${RENDERUPDATE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(content),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return snippet
    },
  )

  return { updatePageContent: performAction, loading, error }
}

export default useUpdatePageContent
