import { BILLINGPRODUCTSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BillingProduct, UpdateBillingProduct } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateProduct = () => {
  const { loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, productId: number, product: UpdateBillingProduct, onSuccess?: Function): Promise<BillingProduct> => {
    const response = await makeRequest<BillingProduct>(
      `${BILLINGPRODUCTSV4}/${productId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      },
      { handleUnauthenticated },
    )

    if (onSuccess) {
      await onSuccess()
    }

    return response
  })

  return { updateProduct: performAction, loading, error }
}

export default useUpdateProduct
