import { useEffect, useState } from "react"
import { CONVERSATIONLOGS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { AILog } from "../../../types"

const useGetLogsForConversationId = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const {
    data,
    loading,
    error,
    performAction: getAILogForConversation,
  } = useHandleRequest(
    (handleUnauthenticated, conversationId: string): Promise<PagedResponse<AILog>> => {
      const query = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        conversation_id: conversationId,
      }
      return makeRequest<PagedResponse<AILog>>(
        `${CONVERSATIONLOGS}?${Object.entries(query)
          .map(
            ([key, value]: [string, string | number | boolean | undefined]) =>
              value && `${key}=${encodeURIComponent(value)}`,
          )
          .filter(Boolean)
          .join("&")}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    // getAILogForConversation()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getAILogForConversation,
    logs: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetLogsForConversationId
