import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useExportSiteViewerSubscriptions = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, siteId: number): Promise<void> => {
      let queryString = `${SITESV4}/${siteId}/subscribers/export`
      return makeRequest<void>(queryString, {}, { handleUnauthenticated })
    },
  )

  return {
    exportSiteViewerSubscriptions: performAction,
    loading,
    error,
    viewerSubscriptionsExport: data,
  }
}

export default useExportSiteViewerSubscriptions
