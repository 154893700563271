import { MERCHANTSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Merchant } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useRejectMerchant = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      merchantId: number,
      reason: string,
      onSuccess?: () => any,
    ): Promise<Merchant> => {
      const response = await makeRequest<Merchant>(
        `${MERCHANTSV3}/${merchantId}/reject`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reason }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { rejectMerchant: performAction, loading, error }
}

export default useRejectMerchant
