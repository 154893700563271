import { CATEGORIESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Category } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetCategories = () => {
  const { data, error, loading, performAction } = useHandleRequest((handleUnauthenticated) => {
    return makeRequest<Category[]>(`${CATEGORIESV5}`, {}, { handleUnauthenticated })
  })
  return { loading, error, getCategories: performAction, categories: data || [] }
}

export default useGetCategories
