import { TAGSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Tag } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetTags = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated): Promise<PagedResponse<Tag>> => {
      const data = await makeRequest<PagedResponse<Tag>>(TAGSV4, {}, { handleUnauthenticated })

      data.results.sort((a, b) => a.display_title.localeCompare(b.display_title))

      return data
    },
  )

  return { loading, error, getTags: performAction, tags: data?.results }
}

export default useGetTags
