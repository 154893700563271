import React, { FunctionComponent, useState } from "react"
import { Box, Paper, Button, Chip, FormLabel } from "@mui/material"
import { Collection } from "../../../types"
import ErrorComponent from "../../../components/shared/Error"
import Dialog from "../../../components/shared/Dialog"
import { toast } from "react-hot-toast"
import useCreateTag from "../hooks/useCreateTag"
import { useFormik } from "formik"
import * as Yup from "yup"
import FormikTextField from "../../../components/shared/FormikTextField"
import { FormikProvider } from "formik"

interface Props {
  collections: Collection[]
  isOpen: boolean
  close: () => any
}

const CreateTagDialog: FunctionComponent<Props> = ({ collections, isOpen, close }) => {
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>([])
  const { createTag, loading, error } = useCreateTag()

  const toggleCollection = (collection: Collection) => {
    if (selectedCollections.includes(collection)) {
      setSelectedCollections(selectedCollections.filter((c) => c !== collection))
    } else {
      setSelectedCollections([...selectedCollections, collection])
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    display_title: Yup.string().required(),
    description: Yup.string().optional(),
  })

  const handleSuccessfulCreate = () => {
    toast.success("Successfully created tag")
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      display_title: "",
      description: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const collectionNames = selectedCollections.map((collection) => collection.name)
      await createTag({ ...values, collections: collectionNames }, handleSuccessfulCreate)
    },
  })

  return (
    <Dialog
      title={"Create tag"}
      isOpen={isOpen}
      handleClose={close}
      content={
        <Box>
          {error ? <ErrorComponent error={error} /> : null}
          <FormikProvider value={formik}>
            <FormikTextField
              margin="dense"
              id="title"
              name="title"
              label="Title"
              fullWidth
              variant="outlined"
              required
              value={formik.values.title}
              error={!!formik.errors.title}
            />
            <FormikTextField
              margin="dense"
              id="display_title"
              name="display_title"
              label="Display Title"
              fullWidth
              variant="outlined"
              required
              value={formik.values.display_title}
              error={!!formik.errors.display_title}
            />
            <FormikTextField
              margin="dense"
              id="description"
              name="description"
              label="Description"
              fullWidth
              variant="outlined"
              multiline
              value={formik.values.description}
              error={!!formik.errors.description}
            />
          </FormikProvider>
          <FormLabel>Collections</FormLabel>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              padding: (theme: any) => theme.spacing(0.5),
              margin: 0,
              borderColor: "#000",
              boxShadow: "none",
            }}
          >
            {collections.map((collection, index) => (
              <Chip
                key={index}
                sx={{
                  margin: (theme) => theme.spacing(0.5),
                  borderRadius: "8px",
                }}
                label={collection.name}
                color={selectedCollections.includes(collection) ? "primary" : undefined}
                onClick={() => toggleCollection(collection)}
              />
            ))}
          </Paper>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} color="primary" variant="outlined" disabled={loading}>
            Create
          </Button>
        </>
      }
    />
  )
}

export default CreateTagDialog
