import { Button, Grid } from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import TemplateDialog from "./components/TemplateDialog"
import useGetSetupFlows from "./hooks/useGetSetupFlows"
import TemplatesTable from "./components/TemplatesTable"
import useTemplateFilters from "../TemplateFilters/TemplateFilters"
import useGetSiteTemplates from "./hooks/useGetSiteTemplates"

const TemplateListComponent: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { getSetupFlows, setupFlows } = useGetSetupFlows()

  const { templateFilters, selectedFilters } = useTemplateFilters()

  const { getSiteTemplates, templates, loading, error } = useGetSiteTemplates()

  const handleClose = () => {
    setOpen(false)
    getSiteTemplates(selectedFilters)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    getSetupFlows()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedFilters.platform) {
      getSiteTemplates(selectedFilters)
    }
  }, [selectedFilters])

  return (
    <ScreenContainer title="Templates">
      <br />
      {templateFilters}
      <br />
      <Grid container>
        <Grid item xs={10}>
          <i>{templates.length} Templates</i>
        </Grid>
        <Grid item xs={2} style={{ alignContent: "flex-end" }}>
          <Button color="primary" variant="outlined" onClick={handleClickOpen}>
            Create Template
          </Button>
        </Grid>
      </Grid>
      <br />
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorComponent error={error} />
      ) : (
        <TemplatesTable templates={templates} setupFlows={setupFlows} close={handleClose} />
      )}
      {open ? <TemplateDialog close={handleClose} open={open} /> : null}
    </ScreenContainer>
  )
}

export default TemplateListComponent
