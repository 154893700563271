import React, { useCallback, useState, useEffect, FunctionComponent } from "react"
import { useParams, useNavigate } from "react-router-dom"
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  FormHelperText,
  Container,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material"
import ReactMarkdown from "react-markdown"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import {
  DeployedByReadonlyInput,
  EnvironmentsParamSelect,
  ImagesParamSelect,
  PullRequestsParamSelect,
  ReleaseInput,
  RevisionParamSelect,
  ServiceParamSelect,
  ServicesParamSelect,
  VercelServiceParamSelect,
  VercelEnvironmentsParamSelect,
} from "./components/ParamSelect"
import { services, web } from "../../constants/services"
import useGetPipelineById from "./hooks/useGetPipelineById"
import useRunPipeline from "./hooks/useRunPipeline"
import config from "../../config"
import OhShitHamid from "../../components/images/oh-shit-hamid.jpg"

const HIDDEN_PARAMS = ["context", "url", "secondary", "changedfiles"]
const ALL = [...services, ...web].join(" ")

const TektonPipelinePipelineRun: FunctionComponent = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>()
  const navigate = useNavigate()
  const [patch, setPatch] = useState<Record<string, any>>({})
  const { data: pipeline, performAction: getPipeline, loading, error } = useGetPipelineById()
  const runPipeline = useRunPipeline()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!pipelineId) {
        return
      }

      const body = Object.fromEntries(
        Object.entries(patch).filter(([_, value]) => value !== "" && value !== "null"),
      )

      await runPipeline.performAction(pipelineId, body)

      if (!error) {
        navigate(`/tekton/${pipelineId}`)
      } else {
        alert(error)
      }
    },
    [pipelineId, patch, navigate, runPipeline, error],
  )

  const handleChange = useCallback(
    (key: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPatch((prevPatch) => ({ ...prevPatch, [key]: e.target.value }))
    },
    [setPatch],
  )

  const handleReleaseChange = useCallback(
    (release: string) => {
      setPatch((prevPatch) => ({ ...prevPatch, release }))
    },
    [setPatch],
  )

  const handleDeployedByChange = useCallback(
    (name: string) => {
      setPatch((prevPatch) => ({ ...prevPatch, deployedBy: name }))
    },
    [setPatch],
  )

  // We handle prnumbers differently to make sure that Pipelines that are run from a specific PR
  // use the correct PR branch.
  const handlePullRequestChange = useCallback(
    (prnumber: number | string, revision?: string) => {
      setPatch((prevPatch) =>
        revision
          ? { ...prevPatch, prnumber, revision: revision }
          : { ...prevPatch, prnumber, revision: undefined },
      )
    },
    [setPatch],
  )

  const handleCheck = useCallback(
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPatch((prevPatch) => ({ ...prevPatch, [key]: e.target.checked.toString() }))
    },
    [setPatch],
  )

  const handleSelectChange = useCallback(
    (key: string) => (value: any) => {
      setPatch((prevPatch) => ({ ...prevPatch, [key]: value }))
    },
    [setPatch],
  )

  const handleSelectServiceChange = useCallback(
    (value: any) => {
      const context = web.includes(value) ? `web/${value}` : `services/${value}`
      setPatch((prevPatch) => ({ ...prevPatch, context, service: value }))
    },
    [setPatch],
  )

  const handleSelectServicesChange = useCallback(
    (value: any) => {
      let val = String(value)

      if (val === "all") {
        val = ALL
      }

      if (val === ALL) {
        setPatch((prevPatch) => ({ ...prevPatch, services: prevPatch.services === ALL ? "" : ALL }))
        return
      }

      setPatch((prevPatch) => {
        const services = prevPatch.services.includes(val)
          ? prevPatch.services
              .split(" ")
              .filter((service: string) => service !== val)
              .join(" ")
              .trim()
          : `${prevPatch.services} ${val}`.trim()
        return { ...prevPatch, services }
      })
    },
    [setPatch],
  )

  useEffect(() => {
    if (!pipelineId) {
      return
    }

    if (!pipeline) {
      getPipeline(pipelineId)
      return
    }

    const newPatch: Record<string, any> = {}
    pipeline.spec.params.forEach((param) => {
      newPatch[param.name] = param.default
    })

    if (newPatch.services === "all") {
      newPatch.services = ALL
    }

    setPatch(newPatch)
    // eslint-disable-next-line
  }, [pipelineId, pipeline])

  if (loading || runPipeline.loading) {
    return (
      <ScreenContainer title={`Tekton Pipeline Run - ${pipelineId} - Fetching...`}>
        <LoadingSpinner />
      </ScreenContainer>
    )
  }

  console.dir(patch)

  // Tekton does not work in the production cluster
  if (config.environmentName === "production") {
    return (
      <ScreenContainer title="Environment Error">
        <img
          height={100}
          width={100}
          style={{ marginTop: 5 }}
          src={OhShitHamid}
          alt={"Oh Shit Hamid"}
        />
        <pre style={{ color: "red", fontSize: 18 }}>
          Tekton is not configured to run in the production K8s cluster. Please use a staging build
          of Internal Tools to complete DevOps tasks.
        </pre>
        <Button
          onClick={() => (window.location.href = "https://tools.onuniverse-staging.com/tekton")}
        >
          Take me there 🏄‍♂️
        </Button>
      </ScreenContainer>
    )
  }

  if (error || runPipeline.error) {
    return (
      <ScreenContainer title={`Tekton Pipeline Run - ${pipelineId} - Error`}>
        {error && <ErrorComponent error={error} />}
        {runPipeline.error && <ErrorComponent error={runPipeline.error} />}
      </ScreenContainer>
    )
  }

  if (!pipeline) {
    return (
      <ScreenContainer title={`Tekton Pipelines Run - ${pipelineId} not found`}>
        <h2>Pipeline not found</h2>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer title={`Tekton Pipelines Run - ${pipelineId}`}>
      <Container
        maxWidth="xl"
        style={{ lineHeight: 1.5, margin: "2rem 0 0 0", alignSelf: "flex-start" }}
      >
        <ReactMarkdown
          components={{
            code({ inline, className, children, ...props }) {
              return (
                <code
                  {...props}
                  className={className}
                  style={{
                    padding: "2px 4px",
                    borderRadius: "4px",
                    color: "#7b1fa2",
                    backgroundColor: "whitesmoke",
                    fontSize: "0.95em",
                  }}
                >
                  {children}
                </code>
              )
            },
          }}
        >
          {pipeline.spec.description}
        </ReactMarkdown>
        <form style={{ marginTop: "2rem" }} onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {pipeline.spec.params.map((param) => {
              if (HIDDEN_PARAMS.includes(param.name)) {
                return null
              }

              if (param.default === "true" || param.default === "false") {
                return (
                  <FormControl key={param.name} style={{ marginBottom: "1rem" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={patch[param.name] === "true"}
                          onChange={handleCheck(param.name)}
                          name={param.name}
                          color="primary"
                        />
                      }
                      label={param.name}
                    />
                    <FormHelperText style={{ marginLeft: "0" }} id="description-helper-text">
                      {param.description}
                    </FormHelperText>
                  </FormControl>
                )
              }

              let element
              switch (param.name) {
                case "release":
                  element = (
                    <ReleaseInput
                      label="-- Select services --"
                      value={patch[param.name] || ""}
                      onChange={handleReleaseChange}
                      param={param}
                    />
                  )
                  break
                case "deployedBy":
                  element = (
                    <DeployedByReadonlyInput
                      label="-- Select services --"
                      value={patch[param.name] || "tekton"}
                      onChange={handleDeployedByChange}
                      param={param}
                    />
                  )
                  break
                case "services":
                  element = (
                    <ServicesParamSelect
                      label="-- Select services --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectServicesChange}
                      param={param}
                    />
                  )
                  break
                case "service":
                  element = (
                    <ServiceParamSelect
                      label="-- Select a service --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectServiceChange}
                      param={param}
                    />
                  )
                  break
                case "vercel-service":
                  element = (
                    <VercelServiceParamSelect
                      label="-- Select a service --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectChange(param.name)}
                      param={param}
                    />
                  )
                  break
                case "environment":
                  element = (
                    <EnvironmentsParamSelect
                      label="-- Select an environment --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectChange(param.name)}
                      param={param}
                    />
                  )
                  break
                case "vercel-environment":
                  element = (
                    <VercelEnvironmentsParamSelect
                      label="-- Select an environment --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectChange(param.name)}
                      param={param}
                    />
                  )
                  break
                case "prnumber":
                  element = (
                    <PullRequestsParamSelect
                      label="-- Select a PR --"
                      value={patch[param.name] || ""}
                      onChange={handlePullRequestChange}
                      param={param}
                    />
                  )
                  break
                case "revision":
                  if (patch.prnumber && patch.prnumber !== "0") {
                    return null
                  }
                  element = (
                    <RevisionParamSelect
                      label="-- Select a Revision --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectChange(param.name)}
                      param={param}
                    />
                  )
                  break
                case "image":
                  element = (
                    <ImagesParamSelect
                      label="-- Select a Tekton runner image --"
                      value={patch[param.name] || ""}
                      onChange={handleSelectChange(param.name)}
                      param={param}
                    />
                  )
                  break
                default:
                  element = (
                    <Input
                      id={param.name}
                      aria-describedby={param.description}
                      type="text"
                      defaultValue={param.default}
                      onChange={handleChange(param.name)}
                    />
                  )
                  break
              }
              return (
                <FormControl key={param.name} style={{ marginBottom: "1rem" }}>
                  <InputLabel
                    style={{ marginLeft: "-1rem", textTransform: "uppercase" }}
                    htmlFor={param.name}
                  >
                    {param.name.replace("_", " ")}
                  </InputLabel>
                  {element}
                  <FormHelperText style={{ marginLeft: "0" }} id="description-helper-text">
                    {param.description}
                  </FormHelperText>
                </FormControl>
              )
            })}
            <Button variant="contained" type="submit" disabled={loading || !!runPipeline.loading}>
              Run
            </Button>
          </Stack>
        </form>
      </Container>
    </ScreenContainer>
  )
}

export default TektonPipelinePipelineRun
