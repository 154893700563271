import { Box, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import CreateDomainInstructionDialog from "./components/CreateDomainInstructionDialog"
import DomainInstructionsTable from "./components/DomainInstructionsTable"
import useGetDomainInstructions from "./hooks/useGetDomainInstructions"

const DomainInstructionsScreen: FunctionComponent = () => {
  const { isOn, turnOn, turnOff: closeCreateDialog } = useToggle()
  const {
    loading: loadingInstructions,
    error: instructionsError,
    getDomainInstructions,
    instructions,
  } = useGetDomainInstructions()

  useEffect(() => {
    getDomainInstructions()
    // eslint-disable-next-line
  }, [])

  const showTable = !loadingInstructions && instructions.length > 0
  return (
    <ScreenContainer title="External Domain Instructions">
      <CreateDomainInstructionButtonWrapper />
      <Button sx={{ width: "300px" }} variant="outlined" color="primary" onClick={turnOn}>
        Create Domain Instruction
      </Button>
      <CreateDomainInstructionButtonWrapper />
      {loadingInstructions && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {instructionsError && <ErrorComponent error={instructionsError} />}
      {showTable && (
        <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
          <DomainInstructionsTable instructions={instructions} />
        </Box>
      )}
      <CreateDomainInstructionDialog isOpen={isOn} handleClose={closeCreateDialog} />
    </ScreenContainer>
  )
}

const CreateDomainInstructionButtonWrapper = styled("div")`
  width: 300px;
  margin-top: 15px;
  margin-bottom: 15px;
`

export default DomainInstructionsScreen
