import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Domain } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateDomain = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      url: string,
      user_id: number,
      site_id: number,
      onSuccess?: () => any,
    ): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url, status: "unregistered", user_id, site_id }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { createDomain: performAction, loading, error }
}

export default useCreateDomain
