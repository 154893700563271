import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { PageSectionType } from "../../../types"
import PageSectionTypeRow from "./PageSectionTypeRow"

interface Props {
  pageSectionTypes: PageSectionType[]
  paginationData: PaginationData
  canEdit: boolean
  getPageSectionTypes: () => any
}

const PageSectionTypesTable: FunctionComponent<Props> = ({
  pageSectionTypes,
  paginationData,
  canEdit,
  getPageSectionTypes,
}) => {
  const columns = canEdit
    ? ["ID", "Name", "Fields", "Created", "Templates", "Enabled", "Action"]
    : ["ID", "Name", "Fields", "Created"]

  const getRows = useCallback(() => {
    return pageSectionTypes.map((pageSectionType: PageSectionType) => (
      <PageSectionTypeRow
        key={pageSectionType.id}
        pageSectionType={pageSectionType}
        canEdit={canEdit}
        getPageSectionTypes={getPageSectionTypes}
      />
    ))
    // eslint-disable-next-line
  }, [pageSectionTypes, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default PageSectionTypesTable
