import { FunctionComponent, useEffect } from "react"

import { useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { useAuth } from "../../services/auth-service"
import "./Login.css"

interface RedirectParamsType {
  pathname: string
  search?: string
}

interface SearchParamType {
  name: string
  value: string
}

const createSearchQuery = (searchParams: SearchParamType[]) => {
  let query = ``
  searchParams.forEach((param: SearchParamType, index: number) => {
    query += index === 0 ? `?${param.name}=${param.value}` : `&${param.name}=${param.value}`
  })

  return query
}

const LoginComponent: FunctionComponent = () => {
  const { isAuthenticated, authenticationError: error, isAuthenticating: loading } = useAuth()
  const navigate = useNavigate()
  const location: any = useLocation()
  const redirectPath = location?.state?.redirectPath || "/"

  if (isAuthenticated) {
    const navigationProps: RedirectParamsType = {
      pathname: redirectPath,
    }
    const redirectSearchParams = location?.state?.searchParams
    if (redirectSearchParams?.length) {
      navigationProps.search = createSearchQuery(redirectSearchParams)
    }
    navigate(navigationProps)
  }

  useEffect(() => {
    const google = (window as any).google

    const parent = document.getElementById("google-btn")
    google.accounts.id.renderButton(parent, { theme: "filled_black" })
  }, [])

  return (
    <div className="login-wrapper">
      <h1>Universe Internal Tools</h1>
      {loading && <LoadingSpinner />}
      {error && <p className="login-error">Login Error: {error.message}</p>}
      {<div id="google-btn" className={isAuthenticated ? "hidden" : ""}></div>}
    </div>
  )
}

export default LoginComponent
