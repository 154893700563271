// DO NOT EDIT THIS FILE DIRECTLY
// See `specs/authorization/README.md` for common issues / usage
const roles = [
  "visitor",
  "guest",
  "system",
  "asset-worker",
  "aws-worker",
  "auth-gateway",
  "billing-worker",
  "cache-worker",
  "commerce-worker",
  "commerce-service",
  "data-etl-worker",
  "deadletter-worker",
  "domain-worker",
  "event-worker",
  "event-api",
  "notification-worker",
  "public-client",
  "site-service",
  "site-worker",
  "tracking-worker",
  "user-worker",
  "verse-service",
  "webhook-gateway",
  "web-app",
  "creator",
  "owner",
  "member",
  "employee",
  "admin",
  "developer-admin",
  "tag-manager",
  "tag-admin",
  "api-developer",
  "app-developer",
  "web-developer",
  "developer",
  "designer",
  "art-designer",
  "brand-designer",
  "product-designer",
  "employee-admin",
  "ai-admin",
  "billing-admin",
  "bug-admin",
  "template-filter-admin",
  "template-creator",
  "designer-admin",
  "growth",
  "growth-admin",
  "support-admin",
  "t2-support",
  "t1-support",
  "promo-code-generator",
  "site-generator",
  "accountant",
  "commerce-admin",
]

export default roles