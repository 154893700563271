import { BLOCKS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DBBlock, CreateDBBlock } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateBlock = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      block: CreateDBBlock,
      onSuccess?: () => any,
    ): Promise<DBBlock> => {
      const blockResponse = await makeRequest<DBBlock>(
        `${BLOCKS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...block }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return blockResponse
    },
  )

  return { createBlock: performAction, loading, error }
}

export default useCreateBlock
