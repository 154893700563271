import { TEMPLATEFILTERS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TemplateFilter, UpdateTemplateFilter } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateTemplateFilter = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      templateFilterId: number,
      templateFilter: UpdateTemplateFilter,
      onSuccess?: () => any,
    ): Promise<TemplateFilter> => {
      const res = await makeRequest<TemplateFilter>(
        `${TEMPLATEFILTERS}/${templateFilterId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(templateFilter),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { updateTemplateFilter: performAction, loading, error }
}

export default useUpdateTemplateFilter
