import  { FunctionComponent } from "react"

type ErrorComponentProps = {
  error?: Error | null
}

const ErrorComponent: FunctionComponent<ErrorComponentProps> = ({ error }) => {
  if (!error) {
    return null
  }
  return (
    <div>
      <span>{`Error: ${error.message}`}</span>
    </div>
  )
}

export default ErrorComponent
