import { TEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Template } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateArchetypesForTemplateById = () => {
  const { loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, id: number, options: object): Promise<Template> => {
      return makeRequest<Template>(
        `${TEMPLATES}/${id}/archetypes`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(options),
        },
        { handleUnauthenticated },
      )
    },
  )

  return { updateArchetypesForTemplate: performAction, loading, error }
}

export default useUpdateArchetypesForTemplateById
