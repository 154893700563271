import { THEMES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Theme } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteTheme = () => {
  const { loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, id: string): Promise<Theme> => {
      return makeRequest<Theme>(
        `${THEMES}/${id}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )
    },
  )

  return { deleteTheme: performAction, loading, error }
}

export default useDeleteTheme
