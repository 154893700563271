const containsSpecialChars = (str: string, includingSpace: boolean = false) => {
  const specialChars = includingSpace
    ? // eslint-disable-next-line
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    : // eslint-disable-next-line
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return specialChars.test(str)
}

export default containsSpecialChars
