import { Box } from "@mui/material"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SearchInput from "../../components/shared/SearchInput"
import { Title3 } from "../../components/shared/Typography"
import { OrderStub } from "../../types"
import OrderTable from "./components/OrderTable"
import useSearchOrders from "./hooks/useSearchOrders"
const OrderSearchScreen: FunctionComponent = () => {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const [previousTerm] = useState<string>()
  const { searchOrders, orders, paginationData, loading, error } = useSearchOrders()

  const handleSuccessfulSearch = useCallback(
    (results: OrderStub[], term: string) => {
      setSearchParams({ term: term })
      if (results?.length === 1) {
        navigate(`/orders/${results[0].id}`)
      }
    },
    [navigate, setSearchParams],
  )

  const handleSearchOrders = useCallback(
    async (term: string) => {
      await searchOrders(term, handleSuccessfulSearch)
    },
    [searchOrders, handleSuccessfulSearch],
  )

  useEffect(() => {
    const term = searchParams.get("term")
    if (term) {
      handleSearchOrders(term)
    }
    // eslint-disable-next-line
  }, [])

  // This is a work around for an issue with updating the search term
  // directly from the handleSuccess callback resulting in react-router warnings
  useEffect(() => {
    if (previousTerm) {
      setSearchParams({ term: previousTerm })
    }

    // eslint-disable-next-line
  }, [previousTerm])

  const showTable = !loading && orders.length > 1
  return (
    <ScreenContainer title="Order Search">
      <Title3 sx={{ mb: "12px" }}>
        Search Orders by ID, Site ID, Buyer / Merchant Email, or Payment Intent
      </Title3>
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <SearchInput
          handleSearch={handleSearchOrders}
          label={`Order Search`}
          disabledWhenEmpty
          defaultValue={searchParams.get("term")}
        />
      </Box>
      {loading && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {error && <ErrorComponent error={error} />}
      {showTable && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "600px" }}
        >
          <OrderTable orders={orders} paginationData={paginationData} />
        </Box>
      )}
    </ScreenContainer>
  )
}

export default OrderSearchScreen
