import { Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FunctionComponent, useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { TextBody } from "../../../components/shared/Typography"
import { PostRemoteValue, RemoteValue } from "../../../types"
import useCreateRemoteValue from "../hooks/useCreateRemoteValue"
import useUpdateRemoteValue from "../hooks/useUpdateRemoteValue"

interface Props {
  isOpen: boolean
  close: () => void
  onRefresh: () => any
  remoteValue: RemoteValue | undefined
}

const RemoteValueDialog: FunctionComponent<Props> = ({ isOpen, close, onRefresh, remoteValue }) => {
  const { createRemoteValue, loading: createLoading, error: createError } = useCreateRemoteValue()

  const { updateRemoteValue, loading: updateLoading, error: updateError } = useUpdateRemoteValue()

  const onSuccess = useCallback(async () => {
    await onRefresh()
    close()
    toast.success("Success")
  }, [onRefresh, close])

  const handleCreate = useCallback(
    async (item: PostRemoteValue) => {
      await createRemoteValue(item, onSuccess)
    },
    [createRemoteValue, onSuccess],
  )

  const handleUpdate = useCallback(
    async (item: RemoteValue) => {
      await updateRemoteValue(item, onSuccess)
    },
    [updateRemoteValue, onSuccess],
  )

  const validationSchema = Yup.object().shape({
    key: Yup.string().required(),
    description: Yup.string().optional(),
    value: Yup.string().required(),
  })

  const handleClose: any = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      key: "",
      description: "",
      value: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const item: any = {
        key: values.key,
        value: values.value,
      }

      if (values?.description) {
        item.description = values.description
      }

      if (!!remoteValue) {
        const updatedItem = { ...remoteValue, ...item }
        await handleUpdate(updatedItem)
      } else {
        await handleCreate(item)
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      key: remoteValue?.key || "",
      description: remoteValue?.description || "",
      value: remoteValue?.value || "",
    })

    // eslint-disable-next-line
  }, [remoteValue])

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={!!remoteValue ? `Update Value ${remoteValue.id}` : "Create New Value"}
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {(!!createError || !!updateError) && (
              <TextBody hasError={!!createError || !!updateError}>{`There was a problem ${
                !!createError ? "creating" : "updating"
              } the remote value.`}</TextBody>
            )}
          </Row>
          <Row>
            <TextField
              label="Key"
              fullWidth
              variant="outlined"
              margin="normal"
              id="key"
              name="key"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.key}
              error={!!formik.errors.key}
            />
          </Row>
          <Row>
            <TextField
              label="Default Value"
              fullWidth
              variant="outlined"
              margin="normal"
              id="value"
              name="value"
              onChange={formik.handleChange}
              value={formik.values.value}
              error={!!formik.errors.value}
            />
          </Row>
          <Row>
            <TextField
              label="Description"
              fullWidth
              variant="outlined"
              margin="normal"
              id="description"
              name="description"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={!!formik.errors.description}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={createLoading || updateLoading}
          >
            {!!remoteValue ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default RemoteValueDialog
