import { PLANSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { CreatePlanRequest, Plan } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreatePlan = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      plan: CreatePlanRequest,
      onSuccess?: () => any,
    ): Promise<Plan> => {
      const response = await makeRequest<Plan>(
        `${PLANSV4}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(plan),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { createPlan: performAction, loading, error }
}

export default useCreatePlan
