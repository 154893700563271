import { useCallback, useEffect, useState } from "react"
import { SITESV5 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Site } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetSitesByUserId = (defaultRowsPerPage: number) => {
  const [userId, setUserId] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, userId: number): Promise<PagedResponse<Site>> => {
      return makeRequest<PagedResponse<Site>>(
        `${SITESV5}?limit=${encodeURIComponent(rowsPerPage)}&offset=${rowsPerPage * page}`,
        {
          headers: {
            "universe-user-id": `${userId}`,
          },
        },
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (userId) {
      performAction(userId)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage, userId])

  const getSitesByUserId = useCallback(
    async (userId: number) => {
      setUserId(userId)
      return await performAction(userId)
    },
    [performAction],
  )

  return {
    loading,
    error,
    getSitesByUserId,
    sites: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetSitesByUserId
