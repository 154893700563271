import toast from "react-hot-toast"
import { IDENTITIESV1 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useUpdateIdentityEmail = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      identityId: string,
      email: string,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${IDENTITIESV1}/${identityId}/email`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        // TODO: Should be in this callback, not here
        toast.success("Successfully updated user identity email")
        await onSuccess()
      }

      return response
    },
  )

  return { updateIdentityEmail: performAction, loading, error }
}

export default useUpdateIdentityEmail
