export enum CouponDuration {
  Once = "once",
  Repeating = "repeating",
  Forever = "forever",
}

export type Coupon = {
  id: number
  name: string
  discount_key: string
  stripe_coupon_id: string
  duration: CouponDuration
  duration_in_months: number | null
  percent_off: number
  amount_off: number | null
  currency: string | null
  created_at: Date | null
  updated_at: Date | null
}
