import { TEKTON_PIPELINESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TektonPipelineRun } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPipelinePipelineRuns = () => {
  const { loading, error, data, performAction } = useHandleRequest(
    (handleUnauthenticated, pipelineId: string): Promise<TektonPipelineRun[]> => {
      return makeRequest<TektonPipelineRun[]>(
        `${TEKTON_PIPELINESV4}/${pipelineId}/pipelineruns`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return {
    loading,
    error,
    performAction,
    data: data || [],
  }
}

export default useGetPipelinePipelineRuns
