import { Price } from "../../types"
import dayjs from "dayjs"

export const formatDate = (dateTime: Date, locale = "en-US") => {
  return dayjs(dateTime).format("MM-DD-YY HH:MM a")
}

export const formatPrice = (price: Price | number | null | undefined, locale = "en-US") => {
  if (typeof price === "number") {
    const floatAmount = (price / 100).toFixed(2)
    return new Intl.NumberFormat(locale, { style: "currency", currency: "USD" }).format(
      Number.parseFloat(floatAmount),
    )
  }

  if (price === null || price === undefined || !price.amount || !price.currency) {
    return ""
  }

  const floatAmount = (price.amount / 100).toFixed(2)

  return new Intl.NumberFormat(locale, { style: "currency", currency: price.currency }).format(
    Number.parseFloat(floatAmount),
  )
}
