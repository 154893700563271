import makeRequest from "../../../utils/make-request"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DomainSearchResults } from "../../../types"
import { DOMAINS } from "../../../constants/urlDefs"

const useSearchDomains = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, domain: string): Promise<DomainSearchResults> => {
    return makeRequest<DomainSearchResults>(
      `${DOMAINS}/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domain }),
      },
      { handleUnauthenticated },
    )
  })

  return { searchDomains: performAction, loading, error, domains: data }
}

export default useSearchDomains
