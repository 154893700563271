import { USERGROUPS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PostUserGroup, UserGroup } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateExperiment = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      userGroup: UserGroup,
      onSuccess?: () => any,
    ): Promise<UserGroup> => {
      const requestBody: PostUserGroup = {
        name: userGroup.name,
      }

      const response = await makeRequest<UserGroup>(
        `${USERGROUPS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return {
    createUserGroup: performAction,
    loading,
    error,
  }
}

export default useCreateExperiment
