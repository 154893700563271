import { Domain } from "domain"
import { DOMAINS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useDeleteVercel = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      domainId: string | number,
      onSuccess?: () => any,
    ): Promise<Domain> => {
      const response = await makeRequest<Domain>(
        `${DOMAINS}/${domainId}/vercel`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return response
    },
  )

  return { deleteVercel: performAction, loading, error }
}

export default useDeleteVercel
