import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { DBBlock } from "../../../types"
import BlockRow from "./BlockRow"

interface Props {
  blocks: DBBlock[]
  paginationData: PaginationData
  canEdit: boolean
}

const BlocksTable: FunctionComponent<Props> = ({ blocks, paginationData, canEdit }) => {
  const columns = canEdit
    ? ["ID", "Name", "Type", "Fields", "Created", "Action"]
    : ["ID", "Name", "Type", "Fields", "Created", "Action"]

  const getRows = useCallback(() => {
    return blocks.map((block: DBBlock, index: number) => (
      <BlockRow key={index} block={block} canEdit={canEdit} />
    ))
  }, [blocks, canEdit])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default BlocksTable
