import { FunctionComponent, useCallback } from "react"

import { UserGroupUser } from "../../../types"

import UserGroupUserRow from "./UserGroupUserRow"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"

interface Props {
  users: UserGroupUser[]
  removeUser: (userId: number) => any
}

const UserGroupUserTable: FunctionComponent<Props> = ({ users, removeUser }) => {
  const columns = ["Id", "UserId", "Email", "Last Login", "Analytics Id", ""]

  const getRows = useCallback(() => {
    return users.map((user: UserGroupUser, index: number) => (
      <UserGroupUserRow user={user} key={index} removeUser={removeUser} />
    ))
  }, [users, removeUser])

  return <BasicTable columns={columns} rows={getRows()} />
}

export default UserGroupUserTable
