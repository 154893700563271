import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  siteId: number
}

const UnarchiveSiteConfirmationDialog: FunctionComponent<Props> = ({
  siteId,
  hasError,
  ...rest
}) => {
  return (
    <ConfirmationDialog
      title="Unarchive Site"
      errorMessage={hasError ? "There was a problem unarchiving the site" : null}
      affirmativeButtonText="Unarchive"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>{`Are you sure you want to unarchive site ${siteId}?`}</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default UnarchiveSiteConfirmationDialog
