import { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  themeId: string
}

type ThemeLinkProps = Omit<Props, "to">

const ThemeLink: FunctionComponent<ThemeLinkProps> = ({ themeId, children, ...rest }) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/themes/${themeId}`}>
    {children}
  </Link>
)

export default ThemeLink
