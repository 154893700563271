import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import useToggle from "../../hooks/useToggle"
import CategoryTable from "./components/CategoryTable"
import CreateCategoryDialog from "./components/CreateCategoryDialog"
import useGetCategories from "./hooks/useGetCategories"

// TODO: update how the dialogs receive data and refresh the list
const CategoriesScreen: FunctionComponent = () => {
  const { isOn, turnOn, turnOff } = useToggle()

  const {
    getCategories,
    loading: loadingCategories,
    error: categoriesError,
    categories,
  } = useGetCategories()

  const fetchData = useCallback(() => {
    getCategories()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  const handleClose = useCallback(() => {
    turnOff()
    getCategories()
  }, [turnOff, getCategories])

  const showTable = !loadingCategories && categories.length > 0
  return (
    <ScreenContainer
      title="Categories"
      subtitle={"Categories are groups of archetypes used during NUX."}
    >
      <br />
      <Button sx={{ width: "300px" }} variant="outlined" color="primary" onClick={turnOn}>
        Create New Category
      </Button>
      <br />
      {loadingCategories && (
        <Box
          display="flex"
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {categoriesError && <ErrorComponent error={categoriesError} />}
      {showTable && (
        <Box display="flex" sx={{ flexDirection: "row", justifyContent: "center" }}>
          <CategoryTable categories={categories} refetch={fetchData} />
        </Box>
      )}

      <CreateCategoryDialog isOpen={isOn} handleClose={handleClose} />
    </ScreenContainer>
  )
}

export default CategoriesScreen
