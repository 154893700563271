import { FunctionComponent, useState, useEffect } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { AIConversationSearch } from "../../../types"
import { Button } from "@mui/material"
import { formatDate } from "../../../utils/formatters"
import AIConversationDialog from "./AIConversationDialog"
import SiteLink from "../../Sites/components/SiteLink"
import UserLink from "../../Users/components/UserLink"
import { Link } from "react-router-dom"

type AIConversationRowProps = {
  conversation: AIConversationSearch
}

const AIConversationRow: FunctionComponent<AIConversationRowProps> = ({ conversation }) => {
  const [open, setOpen] = useState(false)
  // const [fullBody, setFullBody] = useState(false)

  // const body = JSON.stringify(blockTemplate.field_mapping)

  const [userId, setUserId] = useState<number | null>(null)

  useEffect(() => {
    const userId = conversation.messages.find((x) => x.sender?.role === "user")?.sender?.user_id
    if (userId) {
      setUserId(userId)
    }
    // eslint-disable-next-line
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BasicTableRow key={conversation.id}>
      <BasicTableCell component="th" scope="row">
        <Link to={`/ai/conversations/${conversation.id}`}>{conversation.id}</Link>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {conversation.site_id ? (
          <SiteLink siteId={conversation.site_id}>
            {conversation.site?.title || conversation.site_id}
          </SiteLink>
        ) : (
          <></>
        )}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {userId ? <UserLink userId={userId}>{userId}</UserLink> : ""}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {conversation.messages.find((x) => x.sender?.role === "user")?.content.text}
      </BasicTableCell>

      <BasicTableCell component="th" scope="row" width={"5em"}>
        {conversation.site && conversation.site?.thumbnails ? (
          <a href={conversation.site.urls[0]} target="_blank" rel="noreferrer">
            <img
              className="thumbnail"
              src={conversation.site?.thumbnails.small}
              height={100}
              width={46}
              alt="Site thumbnail"
            />
          </a>
        ) : (
          ""
        )}
      </BasicTableCell>

      <BasicTableCell component="th" scope="row">
        {formatDate(new Date(conversation.created_at))}
      </BasicTableCell>

      <BasicTableCell component="th" scope="row">
        <Button onClick={handleClickOpen}>Open Chat</Button>
      </BasicTableCell>

      {open ? (
        <AIConversationDialog isOpen={open} close={handleClose} conversation={conversation} />
      ) : null}
    </BasicTableRow>
  )
}

export default AIConversationRow
