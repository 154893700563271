import { SUBSCRIPTIONSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useCancelSubscription = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      subscriptionId: string | number,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${SUBSCRIPTIONSV3}/${subscriptionId}/cancel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { cancelSubscription: performAction, loading, error }
}

export default useCancelSubscription
