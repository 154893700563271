import { useCallback } from "react"
import { EXPERIMENT } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import {
  Experiment,
  PostExperiment,
  PostVariant,
  PostVariantValue,
  Variant,
  VariantValue,
} from "../../../types"
import makeRequest from "../../../utils/make-request"

const useCreateExperiment = () => {
  const { loading: postExperimentLoading, error: postExperimentError, performAction: postExperiment } = useHandleRequest((handleUnauthenticated, experiment: PostExperiment): Promise<Experiment> => {
    return makeRequest<Experiment>(
      `${EXPERIMENT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(experiment),
      },
      { handleUnauthenticated },
    )
  })

  const { loading: postVariantLoading, error: postVariantError, performAction: postVariant } = useHandleRequest((handleUnauthenticated, variant: PostVariant, experimentId: number): PromiseLike<Variant> => {
    return makeRequest<Variant>(
      `${EXPERIMENT}/${experimentId}/variants`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(variant),
      },
      { handleUnauthenticated },
    )
  })

  const { loading: postVariantValueLoading, error: postVariantValueError, performAction: postVariantValue } = useHandleRequest((
    handleUnauthenticated,
    variantValue: PostVariantValue,
    experimentId: number,
    variantId: number,
  ): PromiseLike<Variant> => {
    return makeRequest<Variant>(
      `${EXPERIMENT}/${experimentId}/variants/${variantId}/values`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(variantValue),
      },
      { handleUnauthenticated },
    )
  })

  const loading = postExperimentLoading || postVariantLoading || postVariantValueLoading
  const error = postExperimentError || postVariantError || postVariantValueError

  const processVariantValues = useCallback(
    async (variantValues: VariantValue[], experimentId: number, variantId: number) => {
      for (let i = 0; i < variantValues.length; i++) {
        if (variantValues[i].hasOwnProperty("remoteValueId")) {
          let item: PostVariantValue = {
            remote_value_id: variantValues[i].remoteValueId as number,
            value: variantValues[i].value,
          }
          await postVariantValue(item, experimentId, variantId)
        }
      }
    },
    [postVariantValue],
  )

  const processVariants = useCallback(
    async (variants: Variant[], experimentId: number) => {
      for (let i = 0; i < variants.length; i++) {
        let item = {
          name: variants[i].name,
          distribution:
            variants[i].distribution !== -1 ? parseFloat(variants[i].distribution as any) : null,
          user_group_id: variants[i].user_group_id !== -1 ? variants[i].user_group_id : null,
        }
        const newVariant = await postVariant(item, experimentId)

        if (!newVariant) {
          throw new Error("Variant didn't get created for some reason")
        }

        const variantValues = variants[i].values
        if (variantValues.length) {
          await processVariantValues(variantValues, experimentId, newVariant.id)
        }
      }
    },
    [postVariant, processVariantValues],
  )

  const createExperiment = useCallback(
    async (experiment: Experiment, onSuccess: Function) => {
      const item = {
        name: experiment.name,
        description: experiment.description,
      }

      const newExperiment = await postExperiment(item)

      // It failed, bail
      if (!newExperiment) {
        return
      }

      if (experiment.variants?.length) {
        await processVariants(experiment.variants, newExperiment.id)
      }

      if (onSuccess) {
        await onSuccess()
      }
    },
    [postExperiment, processVariants],
  )

  return {
    createExperiment,
    loading,
    error,
    createVariant: postVariant,
    createVariantValue: postVariantValue,
  }
}

export default useCreateExperiment
