import { FunctionComponent, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import EntitlementsTable from "./components/EntitlementTable"
import EntitlementDialog from "./components/EntitlementDialog"
import useListEntitlements from "./hooks/useListEntitlements"

const EntitlementScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.billing.entitlements.create)

  const { loading, error, listEntitlements, entitlements, paginationData } = useListEntitlements()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    listEntitlements()
  }

  useEffect(() => {
    listEntitlements()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Entitlement" requiresScope={scopes.billing.entitlements.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {loading && !error ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <LoadingSpinner />
        </div>
      ) : (
        ""
      )}
      <>
        {canCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              onClick={handleClickOpen}
            >
              Create Entitlement
            </Button>
            <br />
          </>
        )}
        <EntitlementsTable
          entitlements={entitlements}
          paginationData={paginationData}
          getEntitlements={listEntitlements}
        />
        {open ? <EntitlementDialog isOpen={open} close={handleClose} /> : null}
      </>
    </ScreenContainer>
  )
}

export default EntitlementScreen
