import  { FunctionComponent } from "react"
import { Payout } from "../../../types"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { formatPrice, formatDate } from "../../../utils/formatters"
import { Chip } from "@mui/material"

interface Props {
  payout: Payout
}

const PayoutRow: FunctionComponent<Props> = ({ payout }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {payout.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Chip
          key={payout.id}
          sx={{
            margin: (theme) => theme.spacing(0.5),
            borderRadius: "8px",
          }}
          label={payout.status}
          color={payout.status === "paid" ? "success" : "warning"}
        />
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {String(payout.automatic)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatPrice(payout.value)}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(new Date(payout.created))}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {formatDate(new Date(payout.arrival_date))}
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default PayoutRow
