import { BLOCKTEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { BlockTemplate } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetBlockTemplate = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, blockTemplateId: string): Promise<BlockTemplate> => {
      const blockTemplate = await makeRequest<BlockTemplate>(
        `${BLOCKTEMPLATES}/${blockTemplateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      return blockTemplate
    },
  )

  return { getBlockTemplate: performAction, loading, error, blockTemplate: data }
}

export default useGetBlockTemplate
