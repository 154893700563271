import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Archetype, Template } from "../../../types"
import { TEMPLATES } from "../../../constants/urlDefs"

const useGetArchetypesForTemplate = () => {
  const { data, error, loading, performAction } = useHandleRequest(
    (handleUnauthenticated, template: Template) => {
      return makeRequest<Archetype[]>(
        `${TEMPLATES}/${template.id}/archetypes`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return { loading, error, getArchetypesForTemplate: performAction, archetypes: data || [] }
}

export default useGetArchetypesForTemplate
