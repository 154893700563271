import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@mui/material"
import React, { FunctionComponent, useState, useEffect } from "react"
import toast from "react-hot-toast"
import ErrorComponent from "../../../components/shared/Error"
import { Entitlement, Theme } from "../../../types"
import enforceError from "../../../utils/enforce-error"
import useDeleteTheme from "../hooks/useDeleteTheme"
import useUpdateTheme from "../hooks/useUpdateTheme"
import useListEntitlements from "../../Entitlements/hooks/useListEntitlements"

// TODO: refactor to use reusable Dialog
// TODO: check styles
// This needs work in general, double modal, etc

type TemplateProps = {
  open: boolean
  handleClose: any
  theme: Theme
}

const EditThemeDialog: FunctionComponent<TemplateProps> = ({
  open = false,
  handleClose,
  theme,
}) => {
  const [openWarning, setOpenWarning] = useState(false)

  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()

  const [isUniverse, setIsUniverse] = useState<boolean>(theme.is_universe)
  const [isPublic, setIsPublic] = useState<boolean>(theme.is_public)

  const [iconUrl, setIconUrl] = useState<string | null>()
  const [symbolName, setSymbolName] = useState<string | null>()

  const [badge, setBadge] = React.useState<string | null>()
  const [requiredEntitlement, setRequiredEntitlement] = React.useState<string | null>()

  const [error, setError] = useState<Error>()

  const { deleteTheme: deleteThemeRequest } = useDeleteTheme()
  const { updateTheme } = useUpdateTheme()

  const { listEntitlements, entitlements } = useListEntitlements(1000)

  const save = async () => {
    try {
      await updateTheme(theme.id, {
        name: name,
        description,
        is_public: isPublic,
        is_universe: isUniverse,
        icon_url: iconUrl,
        symbol_name: symbolName,
        badge,
        required_entitlement:
          requiredEntitlement && requiredEntitlement.length > 0 ? requiredEntitlement : null,
      })
      handleClose()
      toast.success("Successfully edited theme")
    } catch (err) {
      setError(enforceError(err))
    }
  }

  const deleteTheme = async () => {
    try {
      await deleteThemeRequest(theme.id)
      handleClose()
    } catch (err) {
      setError(enforceError(error))
    }
  }

  const handleOpenWarning = () => {
    setOpenWarning(true)
  }

  const handleCloseWarning = () => {
    setOpenWarning(false)
  }

  useEffect(() => {
    listEntitlements()
  }, [])

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit {theme.name}</DialogTitle>

      <DialogContent>
        {error ? <ErrorComponent error={error} /> : ""}
        <FormControl fullWidth>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            variant="standard"
            defaultValue={theme.name}
            fullWidth
            onChange={(x) => setName(x.target.value)}
          />
        </FormControl>

        <TextField
          margin="dense"
          id="description"
          label="Description"
          fullWidth
          variant="standard"
          defaultValue={theme.description}
          onChange={(x) => setDescription(x.target.value)}
        />

        <TextField
          margin="dense"
          id="icon_url"
          label="Icon URL"
          fullWidth
          variant="standard"
          defaultValue={theme.icon_url}
          onChange={(x) => setIconUrl(x.target.value)}
        />
        <br />

        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isUniverse}
                onChange={(event) => setIsUniverse(event.target.checked)}
              />
            }
            label="Is Universe?"
          />
        </FormControl>

        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
                onChange={(event) => setIsPublic(event.target.checked)}
              />
            }
            label="Is Public?"
          />
        </FormControl>
        <br />

        <TextField
          margin="dense"
          id="badge"
          label="Badge"
          variant="standard"
          fullWidth
          defaultValue={theme.badge}
          onChange={(x) => setBadge(x.target.value)}
        />

        <TextField
          margin="dense"
          id="symbol_name"
          label="Symbol Name"
          variant="standard"
          fullWidth
          defaultValue={theme.symbol_name}
          onChange={(x) => setSymbolName(x.target.value)}
        />

        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="required-entitlement">Requires Entitlement</InputLabel>
          <Select
            native
            label="Required Entitlement"
            defaultValue={theme.required_entitlement}
            onChange={(x) => setRequiredEntitlement(x.target.value as string)}
            id="required-entitlement"
          >
            <option aria-label="None" value="" key={"option-null"} />
            {Object.keys(
              entitlements.reduce((acc: Record<string, boolean>, curr: Entitlement) => {
                acc[curr.title] = true
                return acc
              }, {}),
            ).map((ent) => (
              <option value={ent} selected={ent === theme.required_entitlement} key={ent}>
                {" "}
                {ent
                  .replaceAll("_", " ")
                  .split(" ")
                  .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                  .join(" ")}
              </option>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpenWarning} color="error" variant="contained">
          Delete
        </Button>
        <Dialog open={openWarning} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Delete {theme.name}?</DialogTitle>
          <DialogContent>
            <Button onClick={handleCloseWarning} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={deleteTheme} color="error" variant="outlined">
              Delete
            </Button>
          </DialogContent>
        </Dialog>

        <div style={{ flex: "1 0 0" }} />

        <Button onClick={handleClose} color="error" variant="outlined">
          Cancel
        </Button>
        <Button onClick={save} color="primary" variant="outlined">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default EditThemeDialog
