import React, { FunctionComponent, useEffect, useState } from "react"
import { ImageStatus as ImageStatusType } from "../../../types/images"
import enforceError from "../../../utils/enforce-error"
import useGetStatus from "../hooks/useGetStatus"

type ImageStatusProps = {
  inputStatus: ImageStatusType | undefined | null
  setError: (error: Error | null) => void
}

const ImageStatus: FunctionComponent<ImageStatusProps> = ({ inputStatus, setError }) => {
  const [status, setStatus] = useState<ImageStatusType | null>()

  const { getStatus: requestGetStatus } = useGetStatus()

  const getStatus = React.useCallback(async () => {
    setError(null)
    try {
      const statusResponse = await requestGetStatus()

      setStatus(statusResponse)
    } catch (err) {
      setError(enforceError(err))
      setStatus(null)
    }
    setTimeout(getStatus, 10000)
    // eslint-disable-next-line
  }, [setStatus, setError])

  useEffect(() => {
    if (inputStatus) {
      setStatus(inputStatus)
    } else {
      getStatus()
    }
  }, [setStatus, getStatus, inputStatus])

  if (!status) {
    return null
  }
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Message</td>
            <td>{status?.message}</td>
          </tr>
          <tr>
            <td>Prompt</td>
            <td>{status?.prompt}</td>
          </tr>
          <tr>
            <td>Started</td>
            <td>{status?.started_at}</td>
          </tr>
          <tr>
            <td>URL</td>
            <td>
              <a
                href={`https://image-yeeter.onuniverse.cloud${status?.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {status?.url}
              </a>
            </td>
          </tr>
          {status.message === ":coffee:" ? (
            <tr>
              <td>Image</td>
              <td>
                {status?.latest}
                <br />
                {status.latest ? (
                  <img
                    src={`https://image-yeeter.onuniverse.cloud${status?.latest}`}
                    width={status?.width || 0}
                    height={status?.height || 0}
                    alt={status?.prompt || "work in progress"}
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ImageStatus
