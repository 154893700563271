import  { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  domainID: string | number
}

const DeleteMailgunDomainDialog: FunctionComponent<Props> = ({ domainID, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Remove Domain From Mailgun"
      errorMessage={hasError ? "There was a problem deleting this domain from Mailgun" : null}
      affirmativeButtonText="Remove Mailgun"
      negativeButtonText="Cancel"
      content={
        <>
          <ConfirmationRow>
            <TextBody>
              You are about to remove domainId {domainID} from Mailgun. Doing this will remove the
              ability to use email forwarding and sending emails from the custom domain.
            </TextBody>
          </ConfirmationRow>
          <ConfirmationRow pt="8px">
            <TextBody>
              To ensure a smooth deletion, it is also recommended to fully remove the email forward
              setup.
            </TextBody>
          </ConfirmationRow>
          <ConfirmationRow pb="15px" pt="8px">
            <TextBody>Are you sure you want to remove this domain from Mailgun?</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default DeleteMailgunDomainDialog
