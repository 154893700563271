import { Button, DialogContentText } from "@mui/material"
import  { FunctionComponent, useState } from "react"
import toast from "react-hot-toast"
import Dialog from "../../../components/shared/Dialog"
import enforceError from "../../../utils/enforce-error"
import useAddApplePay from "../hooks/useAddApplePay"

type AddApplePayDialogProps = {
  merchantId: number
  urls: object
  close: () => any
  isOpen: boolean
}

const AddApplePayDialog: FunctionComponent<AddApplePayDialogProps> = ({
  merchantId,
  urls,
  close,
  isOpen,
}) => {
  const [error, setError] = useState<Error>()

  const { addApplePay, loading, error: requestError } = useAddApplePay()

  const addApplePayRequest = async () => {
    try {
      await addApplePay(merchantId, urls, close)
      toast.success("Successfully enabled Apple Pay")
    } catch (err) {
      setError(enforceError(err))
    }
  }

  // TODO: Replace with a rad confirmation dialog
  return (
    <Dialog
      title="Add Apple Pay"
      isOpen={isOpen}
      handleClose={close}
      error={error || requestError}
      content={
        <>
          {error || requestError ? <br /> : null}

          <DialogContentText>
            A request will be sent adding Apple Pay to all domains on this site.
            <br />
            <br />
            Would you like to continue?
          </DialogContentText>
        </>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={addApplePayRequest} color="primary" variant="outlined">
              Add Apple Pay
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default AddApplePayDialog
