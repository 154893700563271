import { Tooltip, Typography } from "@mui/material"
import { FunctionComponent } from "react"
interface Props {
  text: string
  textColor: string
  backgroundColor: string
  hoverText?: string
  borderColor?: string
  borderWidth?: number
}

const SubscriptionBadge: FunctionComponent<Props> = ({
  text,
  textColor,
  backgroundColor,
  hoverText,
  borderColor = "transparent",
  borderWidth = backgroundColor,
}) => {
  return (
    <Tooltip
      title={
        hoverText ? <Typography style={{ whiteSpace: "pre-line" }}>{hoverText}</Typography> : null
      }
    >
      <span
        style={{
          fontWeight: 500,
          fontSize: "13px",
          lineHeight: "15px",
          borderRadius: "5px",
          padding: "2px 5px",
          marginRight: "6px",
          color: textColor,
          backgroundColor,
          borderColor,
          borderWidth,
          borderStyle: "solid",
        }}
      >
        {text}
      </span>
    </Tooltip>
  )
}

export default SubscriptionBadge
