import { FunctionComponent } from "react"
import { Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { AISuggestion } from "../../../types"
import useCreateAISuggestion from "../hooks/useCreateAISuggestion"
import useUpdateAISuggestion from "../hooks/useUpdateAISuggestion"
import useDeleteAISuggestion from "../hooks/useDeleteAISuggestion"

interface Props {
  isOpen: boolean
  close: () => void
  editAISuggestion?: AISuggestion
}

const AISuggestionDialog: FunctionComponent<Props> = ({ isOpen, close, editAISuggestion }) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.suggestion.delete)

  const { createAISuggestion, loading: creating, error: creationError } = useCreateAISuggestion()
  const { updateAISuggestion, loading: updating, error: updateError } = useUpdateAISuggestion()
  const { deleteAISuggestion, loading: deleting, error: deleteError } = useDeleteAISuggestion()

  const handleSuccess = async (msg: string) => {
    toast.success(msg)
    handleClose()
  }

  const handleDelete = async () => {
    if (editAISuggestion) {
      await deleteAISuggestion(editAISuggestion.id, () =>
        handleSuccess("Successfully deleted Ai Suggestion"),
      )
    }
  }

  const validationSchema = Yup.object().shape({
    suggestion: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      suggestion: editAISuggestion?.suggestion || "",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      if (editAISuggestion) {
        await updateAISuggestion(
          editAISuggestion.id,
          {
            suggestion: values.suggestion !== "" ? values.suggestion : null,
          },
          () => handleSuccess("Successfully updated Ai Suggestion"),
        )
      } else {
        await createAISuggestion(
          {
            suggestion: values.suggestion !== "" ? values.suggestion : null,
          },
          () => handleSuccess("Successfully created Ai Suggestion"),
        )
      }
      close()
    },
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editAISuggestion
          ? `Update Ai Suggestion: ${editAISuggestion.id}`
          : "Create A New Ai Suggestion"
      }
      content={
        <Box width="500px">
          <Row height="8px" mb="4px">
            {(!!creationError || !!updateError || !!deleteError) && (
              <ErrorComponent error={creationError || updateError || deleteError} />
            )}
          </Row>
          <Row>
            <TextField
              label="Suggestion"
              fullWidth
              variant="outlined"
              margin="normal"
              id="suggestion"
              name="suggestion"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.suggestion}
              error={!!formik.errors.suggestion}
              helperText={formik.errors.suggestion?.toString()}
              onBlur={(evt) => formik.validateField("suggestion")}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!editAISuggestion && canDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={creating || updating || deleting}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creating || updating || deleting || !formik.isValid}
          >
            {editAISuggestion ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default AISuggestionDialog
