import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from "@mui/material"
import  { FunctionComponent, ReactElement, useCallback } from "react"
import { Headline } from "./Typography"

interface RadioGroupProps {
  handleChange: (value: any) => void
  value: string | null
  label?: string
  row?: boolean
  ariaLabel?: string
  options: RadioOption[]
}

export interface RadioOption {
  value: string | number
  label: string
  control?: ReactElement<any, any>
}

const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  handleChange,
  value,
  row,
  label,
  ariaLabel,
  options,
}) => {
  const onChange = useCallback(
    (e: any) => {
      handleChange(e.target.value)
    },
    [handleChange],
  )

  const radioButtons = options.map((option, index) => {
    return (
      <FormControlLabel
        value={option.value}
        control={option.control || <Radio />}
        label={option.label}
        key={`radio-label-${index}`}
      />
    )
  })

  return (
    <FormControl>
      {label && <Headline>{label}</Headline>}
      <MaterialRadioGroup
        row={row}
        aria-labelledby={ariaLabel || "radio button group"}
        name="controlled-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {radioButtons}
      </MaterialRadioGroup>
    </FormControl>
  )
}

export default RadioGroup
