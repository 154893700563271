import { SITEPRODUCTSV3, SITEPRODUCTSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Product, Unit, Variation } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetSiteProductById = () => {
  const { data, loading, error, performAction } = useHandleRequest(async (handleUnauthenticated, productId: number): Promise<Product> => {
    const product = await makeRequest<Product>(`${SITEPRODUCTSV4}/${productId}`, {}, { handleUnauthenticated })
    const variations = await makeRequest<PagedResponse<Variation>>(`${SITEPRODUCTSV3}/${productId}/variations`, {}, { handleUnauthenticated })
    const units = await makeRequest<PagedResponse<Unit>>(`${SITEPRODUCTSV3}/${productId}/units`, {}, { handleUnauthenticated })

    return {
      ...product,
      variations: variations?.results || [],
      units: units?.results || [],
    }
  })

  return { loading, error, getProductById: performAction, product: data }
}

export default useGetSiteProductById
