import  { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Product } from "../../../types"
import SiteProductRow from "./SiteProductRow"

interface SiteProductsTableProps {
  products: Product[]
  paginationData: PaginationData
}

const SiteProductsTable: FunctionComponent<SiteProductsTableProps> = ({
  products,
  paginationData,
}) => {
  const columns: string[] = [
    "ID",
    "Name",
    "Price",
    "Notes Prompt",
    "Shipping",
    "Phone Number",
    "Action",
  ]

  const getRows = useCallback(() => {
    return products.map((product: Product, index: number) => (
      <SiteProductRow key={index} product={product} />
    ))
  }, [products])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default SiteProductsTable
