import { FunctionComponent, useCallback } from "react"
import BasicTable, { PaginationData } from "../../../components/shared/BasicTable/BasicTable"
import { Note } from "../../../types"
import NoteRow from "./NoteRow"

interface Props {
  notes: Note[]
  paginationData: PaginationData
  refreshData: () => any
}

const NotesTable: FunctionComponent<Props> = ({ notes, paginationData, refreshData }) => {
  const columns: string[] = ["Note", "Author", "Date", "Actions"]

  const getRows = useCallback(() => {
    return notes.map((note: Note, index: number) => (
      <NoteRow key={index} note={note} close={refreshData} />
    ))
  }, [notes, refreshData])

  return <BasicTable columns={columns} rows={getRows()} paginationOptions={paginationData} />
}

export default NotesTable
