import { THEMES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Theme } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetThemeById = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    (handleUnauthenticated, themeId: string): Promise<Theme> => {
      return makeRequest<Theme>(`${THEMES}/${themeId}`, {}, { handleUnauthenticated })
    },
  )

  return { loading, error, getThemeById: performAction, theme: data }
}

export default useGetThemeById
