import { SITESV1 } from "../constants/urlDefs"
import useHandleRequest from "../hooks/useHandleRequest"
import { Asset, CreateAsset } from "../types"
import makeRequest from "../utils/make-request"

const useCreateAsset = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      siteId: number,
      body: CreateAsset,
      onSuccess?: () => any,
    ): Promise<Asset> => {
      const res = makeRequest<Asset>(
        `${SITESV1}/${siteId}/assets`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return res
    },
  )

  return { createAsset: performAction, loading, error }
}

export default useCreateAsset
