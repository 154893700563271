import React from "react"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Domain, Subdomain } from "../../../types"
import DomainRow from "./DomainRow"
import SubdomainRow from "./SubdomainRow"

interface DomainsTableProps {
  allDomains: (Domain | Subdomain)[]
}

const formatSubdomainRow = (subdomain: Subdomain) => {
  return <SubdomainRow subdomain={subdomain} key={`subdomain-${subdomain.id}`} />
}

const formatDomainRow = (domain: Domain) => {
  return <DomainRow domain={domain} key={`domain-${domain.id}`} />
}

const getRows = (allDomains: (Domain | Subdomain)[]) => {
  return allDomains.map((row, index) => {
    if (row.hasOwnProperty("type")) {
      let subdomain: Subdomain = row as Subdomain
      return formatSubdomainRow(subdomain)
    } else {
      let domain: Domain = row as Domain
      return formatDomainRow(domain)
    }
  })
}

const columns: string[] = [
  "Domain",
  "Domain ID",
  "Type",
  "User ID",
  "Site ID",
  "Status",
  "Enabled",
  "External",
]

const DomainsTable = ({ allDomains }: DomainsTableProps) => {
  return <BasicTable columns={columns} rows={getRows(allDomains)} />
}

export default DomainsTable
