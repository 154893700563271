import { THEMES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Template, UpdateTheme } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateTheme = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated, id: string, theme: UpdateTheme): Promise<Template> => {
      return makeRequest<Template>(
        `${THEMES}/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(theme),
        },
        { handleUnauthenticated },
      )
    },
  )

  return { updateTheme: performAction, loading, error }
}

export default useUpdateTheme
