import { FunctionComponent } from "react"
import ReactMarkdown from "react-markdown"

type RenderMarkdownProps = {
  markdown: string
}

const CODE_STYLES = {
  padding: "2px 4px",
  borderRadius: "4px",
  color: "#7b1fa2",
  backgroundColor: "whitesmoke",
  fontSize: "0.95em",
}

const BLOCKQUOTE_STYLES = {
  borderLeft: "4px solid #7b1fa2",
  padding: "0.1rem 0.1rem 0.1rem 1rem",
  backgroundColor: "whitesmoke",
}

const RenderMarkdown: FunctionComponent<RenderMarkdownProps> = ({ markdown }) => {
  return (
    <ReactMarkdown
      components={{
        blockquote({ node, ...props }) {
          return <blockquote {...props} style={BLOCKQUOTE_STYLES} />
        },
        code({ inline, className, children, ...props }) {
          return (
            <code {...props} className={className} style={CODE_STYLES}>
              {children}
            </code>
          )
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}

export default RenderMarkdown
