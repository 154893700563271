import  { FunctionComponent } from "react"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import { Coupon } from "../../../types"

interface CouponRowProps {
  coupon: Coupon
}

const CouponRow: FunctionComponent<CouponRowProps> = ({ coupon }) => {
  return (
    <BasicTableRow>
      <BasicTableCell component="th" scope="row">
        {coupon.id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon.name}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon?.discount_key}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon?.stripe_coupon_id}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon?.duration}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon.duration_in_months}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon.percent_off ? `${coupon.percent_off}%` : null}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon.amount_off}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        {coupon.currency}
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <>{coupon.created_at}</>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <>{coupon.updated_at}</>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default CouponRow
