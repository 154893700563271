import { Box } from "@mui/material"
import  { FunctionComponent, useCallback } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import SearchInput from "../../components/shared/SearchInput"
import useFindethOut from "./hooks/useFindOut"

const SandboxScreen: FunctionComponent = () => {
  const { findOut, loading, error, data } = useFindethOut()

  const handleFindOut = useCallback(
    async (query: string) => {
      await findOut(query)
    },
    [findOut],
  )

  return (
    <ScreenContainer title="Sandbox">
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        <SearchInput
          handleSearch={handleFindOut}
          title={""}
          disabledWhenEmpty
          label={"Fuck Around here"}
          buttonText={"Find Out"}
        />
      </Box>
      <ErrorComponent error={error} />
      {loading && (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {data && (
        <>
          <pre style={{ whiteSpace: "pre-wrap" }}>{data}</pre>
        </>
      )}
    </ScreenContainer>
  )
}

export default SandboxScreen
