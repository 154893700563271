import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Page } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetSitePageById = () => {
  const { data, loading, error, performAction } = useHandleRequest((handleUnauthenticated, siteId: number, pageId: number): Promise<Page> => {
    return makeRequest<Page>(`${SITESV4}/${siteId}/pages/${pageId}`, {}, { handleUnauthenticated })
  })

  return { loading, error, getPageById: performAction, page: data }
}

export default useGetSitePageById
