import { FunctionComponent } from "react"
import { Category } from "../../../types"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import CategoryRow from "./CategoryRow"
import useEditCategoryArchetypesDialog from "./EditCategoryArchetypesDialog"
import useEditCategoryDialog from "./EditCategoryDialog"

interface Props {
  categories: Category[]
  refetch: () => any
}

const CategoryTable: FunctionComponent<Props> = ({ categories, refetch }) => {
  const { editCategoryArchetypesDialog, openEditCategoryArchetypesDialog } =
    useEditCategoryArchetypesDialog({ onSave: refetch })
  const { editCategoryDialog, openEditCategoryDialog } = useEditCategoryDialog({ onSave: refetch })

  const columns: string[] = ["Icon", "Sort", "Name", "Display Name", "Selection Description", ""]

  const rows = categories.map((category, index) => {
    return (
      <CategoryRow
        key={index}
        category={category}
        onEditClicked={() => openEditCategoryDialog(category)}
        onEditArchetypesClicked={() => openEditCategoryArchetypesDialog(category)}
      />
    )
  })

  return (
    <>
      <BasicTable columns={columns} rows={rows} />

      {editCategoryArchetypesDialog}
      {editCategoryDialog}
    </>
  )
}

export default CategoryTable
