import { Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { FunctionComponent, useEffect, useState } from "react"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import { TextGenerationAction } from "../../../types"
import toast from "react-hot-toast"
import ErrorComponent from "../../../components/shared/Error"
import FormikTextField from "../../../components/shared/FormikTextField"
import useCreateTextGenerationAction from "../hooks/useCreateTextGenerationAction"
import useUpdateTextGenerationAction from "../hooks/useUpdateTextGenerationAction"
import useDeleteTextGenerationAction from "../hooks/useDeleteTextGenerationAction"

interface Props {
  isOpen: boolean
  close: () => void
  refreshData: () => void
  textGenerationActionUpdate?: TextGenerationAction | undefined
  canDelete: boolean
}

const TextGenerationActionDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  refreshData,
  textGenerationActionUpdate,
  canDelete,
}) => {
  const [acceptsUserInput, setAcceptsUserInput] = useState(false)
  const {
    createTextGenerationAction,
    loading: createLoading,
    error: createError,
  } = useCreateTextGenerationAction()
  const {
    updateTextGenerationAction,
    loading: updateLoading,
    error: updateError,
  } = useUpdateTextGenerationAction()
  const {
    deleteTextGenerationAction,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteTextGenerationAction()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    message: Yup.string().optional(),
    placeholder: Yup.string().optional(),
    options: Yup.string().optional(),
    icon_url: Yup.string().url().required(),
    model: Yup.string().required(),
    sort_order: Yup.number().integer().required(),
    prompt_template: Yup.string().required(),
  })

  const handleCreateSuccess = async () => {
    toast.success("Successfully created text generation action")
    formik.resetForm()
    await refreshData()
    close()
  }

  const handleUpdateSuccess = async () => {
    toast.success("Successfully updated text generation action")
    formik.resetForm()
    await refreshData()
    close()
  }

  const handleDeleteTextGenAction = async () => {
    if (textGenerationActionUpdate) {
      await deleteTextGenerationAction(
        textGenerationActionUpdate.id,
        handleDeleteTextGenActionSuccess,
      )
    }
  }

  const handleDeleteTextGenActionSuccess = async () => {
    toast.success("Successfully deleted text generation action")
    formik.resetForm()
    await refreshData()
    close()
  }

  const handleClose: any = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
      placeholder: "",
      options: "",
      icon_url: "",
      model: "",
      sort_order: 0,
      prompt_template: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!!textGenerationActionUpdate) {
        await updateTextGenerationAction(
          textGenerationActionUpdate.id,
          {
            title: values.title,
            message: values.message === "" ? null : values.message,
            placeholder: values.placeholder === "" ? null : values.placeholder,
            options: values.options === "" ? null : JSON.parse(values.options),
            icon_url: values.icon_url,
            model: values.model,
            sort_order: values.sort_order,
            prompt_template: values.prompt_template,
            accepts_user_input: acceptsUserInput,
          },
          handleUpdateSuccess,
        )
      } else {
        await createTextGenerationAction(
          {
            title: values.title,
            message: values.message === "" ? null : values.message,
            placeholder: values.placeholder === "" ? null : values.placeholder,
            options: values.options === "" ? null : JSON.parse(values.options),
            icon_url: values.icon_url,
            model: values.model,
            sort_order: values.sort_order,
            prompt_template: values.prompt_template,
            accepts_user_input: acceptsUserInput,
          },
          handleCreateSuccess,
        )
      }
    },
  })

  useEffect(() => {
    if (textGenerationActionUpdate) {
      formik.setValues({
        title: textGenerationActionUpdate.title,
        message: textGenerationActionUpdate.message,
        placeholder: textGenerationActionUpdate.placeholder,
        options: textGenerationActionUpdate.options
          ? JSON.stringify(textGenerationActionUpdate.options)
          : "",
        icon_url: textGenerationActionUpdate.icon_url,
        model: textGenerationActionUpdate.model,
        sort_order: textGenerationActionUpdate.sort_order,
        prompt_template: textGenerationActionUpdate.prompt_template,
      })
      setAcceptsUserInput(textGenerationActionUpdate.accepts_user_input)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      title={
        textGenerationActionUpdate && !!textGenerationActionUpdate.title
          ? `Update ${textGenerationActionUpdate.title}`
          : "Create Text Generation Action"
      }
      isOpen={isOpen}
      handleClose={handleClose}
      loading={createLoading || updateLoading}
      content={
        <>
          {createError && <ErrorComponent error={createError} />}
          {updateError && <ErrorComponent error={updateError} />}
          {deleteError && <ErrorComponent error={deleteError} />}
          <FormikProvider value={formik}>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              name="title"
              label="Title"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.title}
              error={!!formik.errors.title}
            />
            <TextField
              margin="dense"
              id="message"
              name="message"
              label="Message"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.message}
              error={!!formik.errors.message}
            />
            <TextField
              margin="dense"
              id="placeholder"
              name="placeholder"
              label="Placeholder"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.placeholder}
              error={!!formik.errors.placeholder}
            />
            <TextField
              margin="dense"
              id="options"
              name="options"
              label="Options"
              fullWidth
              variant="outlined"
              multiline
              maxRows={5}
              onChange={formik.handleChange}
              value={formik.values.options}
              error={!!formik.errors.options}
            />
            <TextField
              margin="dense"
              id="icon_url"
              name="icon_url"
              label="Icon URL"
              fullWidth
              variant="outlined"
              required
              onChange={formik.handleChange}
              value={formik.values.icon_url}
              error={!!formik.errors.icon_url}
            />
            <FormikTextField
              margin="dense"
              id="model"
              name="model"
              label="Model"
              fullWidth
              required
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.model}
              error={!!formik.errors.model}
            />
            <FormikTextField
              margin="dense"
              id="sort_order"
              name="sort_order"
              label="Sort Order"
              fullWidth
              required
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.sort_order}
              error={!!formik.errors.sort_order}
            />
            <FormikTextField
              margin="dense"
              id="prompt_template"
              name="prompt_template"
              label="Prompt Template"
              fullWidth
              required
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.prompt_template}
              error={!!formik.errors.prompt_template}
            />
            <FormControlLabel
              control={
                <Switch
                  id="acceptsUserInput"
                  name="acceptsUserInput"
                  onChange={(e) => setAcceptsUserInput(e.target.checked)}
                  checked={acceptsUserInput}
                />
              }
              label={"Accepts User Input"}
            ></FormControlLabel>
          </FormikProvider>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!textGenerationActionUpdate && canDelete && (
            <Button
              onClick={handleDeleteTextGenAction}
              color="error"
              variant="contained"
              disabled={createLoading || updateLoading || deleteLoading}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={createLoading || updateLoading || deleteLoading}
          >
            {!!textGenerationActionUpdate ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default TextGenerationActionDialog
