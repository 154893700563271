import dayjs from "dayjs"
import { REMOTE_VALUE } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { RemoteValue } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetRemoteValues = () => {
  const { data, loading, error, performAction } = useHandleRequest(
    async (handleUnauthenticated): Promise<RemoteValue[]> => {
      const results = await makeRequest<RemoteValue[]>(REMOTE_VALUE, {}, { handleUnauthenticated })

      const sortedResults = results.sort((a, b) =>
        dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1,
      )

      return sortedResults
    },
  )

  return { getRemoteValues: performAction, loading, error, remoteValues: data || [] }
}

export default useGetRemoteValues
