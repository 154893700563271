import { Card } from "@mui/material"
import  { FunctionComponent } from "react"
import { TextBody } from "../../../components/shared/Typography"

type DomainCardProps = {
  url: string
}

const DomainCard: FunctionComponent<DomainCardProps> = ({ url }) => (
  <Card sx={{ minWidth: "300px", backgroundColor: "#f2f2f2", mb: "15px", p: "8px" }}>
    <TextBody>
      <b>Domain: </b>
      <a style={{ color: "black" }} href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    </TextBody>
  </Card>
)

export default DomainCard
