import { Box, Button } from "@mui/material"
import { FunctionComponent, useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import SearchInput from "../../components/shared/SearchInput"
import useToggle from "../../hooks/useToggle"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import { CreatePromoRequest, Promo } from "../../types"
import useGetCoupons from "../Coupons/hooks/useGetCoupons"
import useGetPlans from "../Plans/hooks/useGetPlans"
import useGetProducts from "../Products/hooks/useGetProducts"
import CreatePromoDialog from "./components/CreatePromoDialog"
import PromoList from "./components/PromoList/PromoList"
import useCreatePromo from "./hooks/useCreatePromo"
import useGetPromos from "./hooks/useGetPromos"
import { useSearchParams } from "react-router-dom"

const PromosScreen: FunctionComponent = () => {
  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.promos.create)

  let [searchParams, setSearchParams] = useSearchParams()

  const { isOn, turnOn, turnOff } = useToggle()
  const navigate = useNavigate()
  const { getPromos, error: errorGettingPromos, promos, paginationData } = useGetPromos()
  const { createPromo, loading: loadingNewPromo, error: errorCreatingPromo } = useCreatePromo()
  const { getPlans, loading: loadingPlans, error: errorGettingPlans, plans } = useGetPlans(1000)
  const {
    getProducts,
    loading: loadingProducts,
    error: errorGettingProducts,
    products,
  } = useGetProducts(1000)
  const {
    getCoupons,
    loading: loadingCoupons,
    error: errorGettingCoupons,
    coupons,
  } = useGetCoupons(1000)

  const handleCreateSuccess = (promo: Promo) => {
    navigate(`/promos/${promo.id}`)
    turnOff()
    toast.success("Successfully created promo code")
  }

  const createPromoCode = async (newPromo: CreatePromoRequest) => {
    await createPromo(newPromo, handleCreateSuccess)
  }

  const handleSearchFromRouter = useCallback(async () => {
    const term = searchParams.get("term")

    if (term) {
      await getPromos(term)
    }
  }, [searchParams, getPromos])

  const handleSearchPromos = useCallback(
    async (term: string) => {
      await getPromos(term)
      setSearchParams({ term: term as string })
    },
    [getPromos, setSearchParams],
  )

  useEffect(() => {
    handleSearchFromRouter()
    getCoupons()
    getPlans()
    getProducts()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Promo Codes" requiresScope={scopes.promos.read}>
      {(errorCreatingPromo || errorGettingPromos) && (
        <ErrorComponent error={errorCreatingPromo || errorGettingPromos} />
      )}
      <Box
        display="flex"
        width="500px"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <SearchInput
          handleSearch={handleSearchPromos}
          title="Search Promos"
          label="Search"
          defaultValue={searchParams.get("term")}
        />
        {canCreate && (
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            sx={{
              my: "12px",
            }}
            onClick={turnOn}
          >
            Create New
          </Button>
        )}
      </Box>

      <Box
        display="flex"
        sx={{ flexDirection: "row", justifyContent: "center", maxHeight: "600px" }}
      >
        <PromoList promos={promos} paginationData={paginationData} />
      </Box>

      <CreatePromoDialog
        isOpen={isOn}
        handleCreatePromo={createPromoCode}
        creatingPromo={loadingNewPromo}
        loading={!!loadingCoupons || !!loadingPlans || !!loadingProducts}
        close={turnOff}
        hasError={
          !!errorCreatingPromo ||
          !!errorGettingCoupons ||
          !!errorGettingPlans ||
          !!errorGettingProducts
        }
        coupons={coupons}
        products={products}
        plans={plans}
      />
    </ScreenContainer>
  )
}

export default PromosScreen
