import { useEffect, useState } from "react"
import { TAGSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { Tag } from "../../../types"

const useGetTags = (defaultRowsPerPage = 25) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const [collection, setCollection] = useState("")

  const {
    data,
    loading,
    error,
    performAction: getTags,
  } = useHandleRequest(
    (handleUnauthenticated, collection?: string): Promise<PagedResponse<Tag>> => {
      setCollection(collection ?? "")

      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}${
        collection ? `&collection=${collection}` : ""
      }`
      return makeRequest<PagedResponse<Tag>>(
        `${TAGSV4}?${queryParams}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    getTags(collection)
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  return {
    loading,
    error,
    getTags,
    tags: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetTags
