import  { FunctionComponent, useCallback } from "react"
import { Box } from "@mui/material"
import BasicTable from "../../../components/shared/BasicTable/BasicTable"
import { Title3 } from "../../../components/shared/Typography"
import { DNSimpleZone } from "../../../types"
import DNSimpleZoneRow from "./DNSimpleZoneRow"

interface Props {
  zones: DNSimpleZone[]
  deleteZone: any
}

const DNSimpleZonesTable: FunctionComponent<Props> = ({ zones, deleteZone }) => {
  const columns: string[] = [
    "ID",
    "Name",
    "Type",
    "TTL",
    "Zone ID",
    "Parent ID",
    "Content",
    "Action",
  ]

  const getRows = useCallback(() => {
    return zones.map((zone: DNSimpleZone, index: number) => (
      <DNSimpleZoneRow key={index} zone={zone} deleteZone={deleteZone} />
    ))
  }, [zones, deleteZone])

  return (
    <Box width="100%">
      <Title3>DNSimple Zones</Title3>
      <BasicTable columns={columns} rows={getRows()} />
    </Box>
  )
}

export default DNSimpleZonesTable
