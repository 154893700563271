import React from "react"
import { TextField, TextFieldProps } from "@mui/material"
import { useFormikContext, FieldAttributes } from "formik"

type FormikTextFieldProps = FieldAttributes<TextFieldProps> & {}

// Custom TextField component that converts empty strings to null
const FormikTextField: React.FC<FormikTextFieldProps> = ({ onChange, ...props }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? null : event.target.value
    setFieldValue(props.name!, value)
    if (onChange) onChange(event)
  }

  return <TextField {...props} onChange={handleChange} />
}

export default FormikTextField
