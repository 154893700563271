import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
} from "@mui/material"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import ErrorComponent from "../../../components/shared/Error"
import { Block, SetupFlow, Template, TemplateContent } from "../../../types"
import enforceError from "../../../utils/enforce-error"
import useUpdateTemplatePage from "../hooks/useUpdateTemplatePage"
import useGetTemplateContentById from "../hooks/useGetTemplateContentById"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type Props = {
  template: Template
  setupFlows: SetupFlow[]
  onCancel: () => void
}

const EditArchetypeDialogContent = ({ template, setupFlows, onCancel }: Props) => {
  const [templateContent, setTemplateContent] = useState<TemplateContent>()
  const [setupFlow, setSetupFlow] = useState<SetupFlow>()
  const [gridVersion, setGridVersion] = useState<number>()
  const [tags, setTags] = useState<Record<string, string[]>>({})
  const [error, setError] = useState<Error>()

  const { updateTemplatePage } = useUpdateTemplatePage()
  const { getTemplateContent } = useGetTemplateContentById()

  const { id: templateId, setup_flow_required: templateSetupFlowRequired } = template

  const handleChange = (block: Block, event: any) => {
    const newTags = { ...tags, [block.id]: event.target.value }
    setTags(newTags)
  }

  useEffect(
    () => {
      const loadTemplateContent = async () => {
        const blockTags = {} as Record<string, string[]>
        const retunedTemplateContent = await getTemplateContent(templateId)

        if (
          retunedTemplateContent?.content.type &&
          retunedTemplateContent.content.type === "v3-grid"
        ) {
          // V3 Grid
          setGridVersion(3)
          retunedTemplateContent.content.customData?.children.forEach((block) => {
            if (block.block.tags) {
              blockTags[block.block.id] = block.block.tags
            }
          })
          setTags(blockTags)
          setTemplateContent(retunedTemplateContent)
        } else if (
          retunedTemplateContent?.content.type &&
          retunedTemplateContent.content.type === "v2-grid"
        ) {
          // V2 Grid
          setGridVersion(2)
          retunedTemplateContent.content.customData?.children.forEach((block) => {
            if (block.block.tags) {
              blockTags[block.block.id] = block.block.tags
            }
          })
          setTags(blockTags)
          setTemplateContent(retunedTemplateContent)
        } else if (retunedTemplateContent?.content.type === "grid") {
          // V1 Grid
          setGridVersion(1)
          retunedTemplateContent.content.blocks!.forEach((block) => {
            if (block.tags) {
              blockTags[block.id] = block.tags
            }
          })
          setTags(blockTags)
          setTemplateContent(retunedTemplateContent)
        } else {
          toast.error(
            `Unsupported grid type: ${retunedTemplateContent?.content.type} on template ${retunedTemplateContent?.id}`,
          )
        }
      }

      const load = async () => {
        await loadTemplateContent()

        setSetupFlow(setupFlows.find((x) => x.setup_flow === templateSetupFlowRequired))
      }

      load().catch((error) => {
        setError(enforceError(error))
      })
    },
    // eslint-disable-next-line
    [],
  )

  const save = async () => {
    if (!templateContent) return

    try {
      if (gridVersion === 3) {
        await updateTemplatePage(templateId, templateContent.id, {
          type: "Grid",
          content: {
            ...templateContent.content,
            customData: {
              ...templateContent.content?.customData,
              children: (templateContent.content.customData?.children || []).map((v2Block) => ({
                ...v2Block,
                block: {
                  ...v2Block.block,
                  tags: tags[v2Block.block.id],
                },
              })),
            },
          },
        })
        onCancel()
      } else if (gridVersion === 2) {
        await updateTemplatePage(templateId, templateContent.id, {
          type: "Grid",
          content: {
            ...templateContent.content,
            customData: {
              ...templateContent.content?.customData,
              children: (templateContent.content.customData?.children || []).map((v2Block) => ({
                ...v2Block,
                block: {
                  ...v2Block.block,
                  tags: tags[v2Block.block.id],
                },
              })),
            },
          },
        })
        onCancel()
      } else if (gridVersion === 1) {
        console.log({
          type: "Grid",
          content: {
            ...templateContent.content,
            blocks: templateContent.content.blocks!.map((block) => ({
              ...block,
              tags: tags[block.id],
            })),
          },
        })
        await updateTemplatePage(templateId, templateContent.id, {
          type: "Grid",
          content: {
            ...templateContent.content,
            blocks: templateContent.content.blocks!.map((block) => ({
              ...block,
              tags: tags[block.id],
            })),
          },
        })
        onCancel()
      } else {
        toast.error(`Unsupported grid version: ${gridVersion}`)
      }
    } catch (err) {
      setError(enforceError(error))
    }
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Edit Template Page</DialogTitle>

      <DialogContent>
        {error ? <ErrorComponent error={error} /> : ""}

        <Table size="small">
          <TableHead>
            <BasicTableRow>
              <BasicTableCell>i</BasicTableCell>
              <BasicTableCell>Type</BasicTableCell>
              <BasicTableCell>Text</BasicTableCell>
              <BasicTableCell>Tags</BasicTableCell>
            </BasicTableRow>
          </TableHead>
          <TableBody>
            {gridVersion === 1 && templateContent && templateContent.content.blocks
              ? templateContent.content.blocks.map((block, idx) => (
                  <BasicTableRow key={block.id}>
                    <BasicTableCell>{idx}</BasicTableCell>
                    <BasicTableCell>{block.type}</BasicTableCell>
                    <BasicTableCell>{block.customData.text}</BasicTableCell>
                    <BasicTableCell style={{ minWidth: 200 }}>
                      <FormControl
                        sx={{
                          margin: (theme) => theme.spacing(1),
                          minWidth: "120px",
                          maxWidth: "300px",
                        }}
                        fullWidth
                        variant="standard"
                      >
                        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={tags[block.id] || []}
                          onChange={(x) => handleChange(block, x)}
                          input={<Input />}
                          renderValue={(selected: any) =>
                            selected.map((x: unknown) => x as string).join(",")
                          }
                          MenuProps={MenuProps}
                        >
                          {setupFlow?.tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                              <Checkbox checked={tags[block.id]?.indexOf(tag) > -1} />
                              <ListItemText primary={tag} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </BasicTableCell>
                  </BasicTableRow>
                ))
              : null}
            {gridVersion === 2 || gridVersion === 3
              ? templateContent?.content.customData?.children.map((block, idx) => (
                  <BasicTableRow key={block.block.id}>
                    <BasicTableCell>{idx}</BasicTableCell>
                    <BasicTableCell>{block.block.type}</BasicTableCell>
                    <BasicTableCell>{block.block.customData.text}</BasicTableCell>
                    <BasicTableCell style={{ minWidth: 200 }}>
                      <FormControl
                        sx={{
                          margin: (theme) => theme.spacing(1),
                          minWidth: "120px",
                          maxWidth: "300px",
                        }}
                        fullWidth
                        variant="standard"
                      >
                        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={tags[block.block.id] || []}
                          onChange={(x) => handleChange(block.block, x)}
                          input={<Input />}
                          renderValue={(selected: any) =>
                            selected.map((x: unknown) => x as string).join(",")
                          }
                          MenuProps={MenuProps}
                        >
                          {setupFlow?.tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                              <Checkbox checked={tags[block.block.id]?.indexOf(tag) > -1} />
                              <ListItemText primary={tag} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </BasicTableCell>
                  </BasicTableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="error" variant="outlined">
          Cancel
        </Button>
        <Button onClick={save} color="primary" variant="outlined">
          Edit
        </Button>
      </DialogActions>
    </>
  )
}

const useEditPageTemplateDialog = (setupFlows: SetupFlow[]) => {
  const [template, setTemplate] = useState<Template>()

  const close = () => setTemplate(undefined)

  const dialog = template ? (
    <Dialog open={true} onClose={close}>
      <EditArchetypeDialogContent template={template} setupFlows={setupFlows} onCancel={close} />
    </Dialog>
  ) : null

  return {
    editPageTemplateDialog: dialog,
    openEditPageTemplateDialog: setTemplate,
  }
}

export default useEditPageTemplateDialog
