import { FunctionComponent } from "react"
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Dialog, { Row } from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useAuth } from "../../../services/auth-service"
import { scopes } from "../../../scopes"

import { DomainTldPricing, Plan } from "../../../types"
import useCreateDomainTldPricing from "../hooks/useCreateDomainTldPricing"
import useUpdateDomainTldPricing from "../hooks/useUpdateDomainTldPricing"
import useDeleteDomainTldPricing from "../hooks/useDeleteDomainTldPricing"

interface Props {
  isOpen: boolean
  close: () => void
  editDomainTldPricing?: DomainTldPricing
  plans: Plan[]
}

const DomainTldPricingDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  editDomainTldPricing,
  plans,
}) => {
  const { hasScope } = useAuth()
  const canDelete = hasScope(scopes.domainTldPricing.delete)

  const {
    createDomainTldPricing,
    loading: creating,
    error: creationError,
  } = useCreateDomainTldPricing()
  const {
    updateDomainTldPricing,
    loading: updating,
    error: updateError,
  } = useUpdateDomainTldPricing()
  const {
    deleteDomainTldPricing,
    loading: deleting,
    error: deleteError,
  } = useDeleteDomainTldPricing()

  const handleSuccess = async (msg: string) => {
    toast.success(msg)
    handleClose()
  }

  const handleDelete = async () => {
    if (editDomainTldPricing) {
      await deleteDomainTldPricing(editDomainTldPricing.id, () =>
        handleSuccess("Successfully deleted Domain Tld Pricing"),
      )
    }
  }

  const validationSchema = Yup.object().shape({
    tld: Yup.string().required(),
    active: Yup.boolean().required(),
    registrationPrice: Yup.number().integer().required(),
    renewalPrice: Yup.number().integer().optional(),
    transferPrice: Yup.number().integer().optional(),
    restorePrice: Yup.number().integer().optional(),
    planId: Yup.number().integer().optional(),
    legacyTier: Yup.string().optional(),
  })

  const formik = useFormik({
    initialValues: {
      tld: editDomainTldPricing?.tld || "",
      active: editDomainTldPricing?.active ?? true,
      registrationPrice: editDomainTldPricing?.registration_price || "",
      renewalPrice: editDomainTldPricing?.renewal_price || "",
      transferPrice: editDomainTldPricing?.transfer_price || "",
      restorePrice: editDomainTldPricing?.restore_price || "",
      planId: editDomainTldPricing?.plan_id || "",
      legacyTier: editDomainTldPricing?.legacy_tier || "",
    } as any,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      if (editDomainTldPricing) {
        await updateDomainTldPricing(
          editDomainTldPricing.id,
          {
            tld: values.tld !== "" ? values.tld : null,
            active: values.active !== "" ? values.active : null,
            registration_price: values.registrationPrice !== "" ? values.registrationPrice : null,
            renewal_price: values.renewalPrice !== "" ? values.renewalPrice : null,
            transfer_price: values.transferPrice !== "" ? values.transferPrice : null,
            restore_price: values.restorePrice !== "" ? values.restorePrice : null,
            plan_id: values.planId !== "" ? values.planId : null,
            legacy_tier: values.legacyTier !== "" ? values.legacyTier : null,
          },
          () => handleSuccess("Successfully updated Domain Tld Pricing"),
        )
      } else {
        await createDomainTldPricing(
          {
            tld: values.tld !== "" ? values.tld : null,
            active: values.active,
            registration_price: values.registrationPrice !== "" ? values.registrationPrice : null,
            renewal_price: values.renewalPrice !== "" ? values.renewalPrice : null,
            transfer_price: values.transferPrice !== "" ? values.transferPrice : null,
            restore_price: values.restorePrice !== "" ? values.restorePrice : null,
            plan_id: values.planId !== "" ? values.planId : null,
            legacy_tier: values.legacyTier !== "" ? values.legacyTier : null,
          },
          () => handleSuccess("Successfully created Domain Tld Pricing"),
        )
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        !!editDomainTldPricing
          ? `Update Domain Tld Pricing: ${editDomainTldPricing.id}`
          : "Create A New Domain Tld Pricing"
      }
      content={
        <Box width="500px">
          <Row mb="4px">
            {(!!creationError || !!updateError || !!deleteError) && (
              <ErrorComponent error={creationError || updateError || deleteError} />
            )}
          </Row>
          <Row>
            <TextField
              label="Tld"
              fullWidth
              variant="outlined"
              margin="normal"
              id="tld"
              name="tld"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.tld}
              error={!!formik.errors.tld}
              helperText={formik.errors.tld?.toString()}
              onBlur={(evt) => formik.validateField("tld")}
            />
          </Row>
          <Row>
            <FormControlLabel
              sx={{ ml: "0px" }}
              control={<Checkbox checked={formik.values.active} />}
              label="Active"
              name="active"
              onChange={() => formik.setFieldValue("active", !formik.values.active)}
            />
          </Row>
          <Row>
            <TextField
              label="Registration Price"
              variant="outlined"
              margin="normal"
              id="registrationPrice"
              name="registrationPrice"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.registrationPrice}
              error={!!formik.errors.registrationPrice}
              helperText={formik.errors.registrationPrice?.toString()}
              onBlur={(evt) => formik.validateField("registrationPrice")}
            />

            <TextField
              label="Renewal Price"
              variant="outlined"
              margin="normal"
              id="renewalPrice"
              name="renewalPrice"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.renewalPrice}
              error={!!formik.errors.renewalPrice}
              helperText={formik.errors.renewalPrice?.toString()}
              onBlur={(evt) => formik.validateField("renewalPrice")}
            />
          </Row>
          <Row>
            <TextField
              label="Transfer Price"
              variant="outlined"
              margin="normal"
              id="transferPrice"
              name="transferPrice"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.transferPrice}
              error={!!formik.errors.transferPrice}
              helperText={formik.errors.transferPrice?.toString()}
              onBlur={(evt) => formik.validateField("transferPrice")}
            />
            <TextField
              label="Restore Price"
              variant="outlined"
              margin="normal"
              id="restorePrice"
              name="restorePrice"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.restorePrice}
              error={!!formik.errors.restorePrice}
              helperText={formik.errors.restorePrice?.toString()}
              onBlur={(evt) => formik.validateField("restorePrice")}
            />
          </Row>
          <Row>
            <Select
              label="Plan"
              variant="outlined"
              id="planId"
              name="planId"
              onChange={formik.handleChange}
              value={formik.values.planId}
              error={!!formik.errors.planId}
              onBlur={(evt) => formik.validateField("planId")}
            >
              <MenuItem value="">Select Plan</MenuItem>
              {plans
                ?.filter((x) => x.variant === "tld")
                .map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {plan.title} - {plan.version}.{plan.group}
                    {plan.variant && `.${plan.variant}`}.{plan.duration}
                  </MenuItem>
                ))}
            </Select>
          </Row>
          <Row>
            <TextField
              label="Legacy Tier"
              fullWidth
              variant="outlined"
              margin="normal"
              id="legacyTier"
              name="legacyTier"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.legacyTier}
              error={!!formik.errors.legacyTier}
              helperText={formik.errors.legacyTier?.toString()}
              onBlur={(evt) => formik.validateField("legacyTier")}
            />
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!!editDomainTldPricing && canDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              variant="contained"
              disabled={creating || updating || deleting}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={creating || updating || deleting || !formik.isValid}
          >
            {editDomainTldPricing ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default DomainTldPricingDialog
