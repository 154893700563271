import "highlight.js/styles/xcode.css"
import hljs from "highlight.js"
import { Box, Stack } from "@mui/material"
import { stringify as YAMLstringify } from "yaml"
import omit from "lodash/omit"
import { useEffect, FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import PipelineRunsTable from "./components/PipelineRunsTable"
import PipelineRunLogs from "./components/PipelineRunLogs"
import useGetPipelineRunById from "./hooks/useGetPipelineRunById"
import useRetry from "./hooks/useRetry"
import config from "../../config"
import OhShitHamid from "../../components/images/oh-shit-hamid.jpg"

const TektonPipelineRun: FunctionComponent = () => {
  const { pipelineRunId } = useParams<{ pipelineRunId: string }>()
  const getPipelineRun = useGetPipelineRunById()
  const retry = useRetry()

  useEffect(() => {
    if (!pipelineRunId) {
      return
    }

    retry.execute(getPipelineRun.performAction, pipelineRunId)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!getPipelineRun.data || !pipelineRunId) return

    const status = getPipelineRun.data?.status?.conditions?.[0]?.reason

    if (!status) return

    if (status === "Running") {
      const timer = setTimeout(() => {
        retry.execute(getPipelineRun.performAction, pipelineRunId)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [retry, getPipelineRun, pipelineRunId])

  // We need it configured this way so that hljs can highlight the code
  useEffect(() => {
    hljs.highlightAll()
  })

  if (getPipelineRun.loading && !getPipelineRun.data) {
    return (
      <ScreenContainer title={`Tekton Pipeline Run - ${pipelineRunId} - Fetching...`}>
        <LoadingSpinner />
      </ScreenContainer>
    )
  }

  // Tekton does not work in the production cluster
  if (config.environmentName === "production") {
    return (
      <ScreenContainer title="Environment Error">
        <img
          height={100}
          width={100}
          style={{ marginTop: 5 }}
          src={OhShitHamid}
          alt={"Oh Shit Hamid"}
        />
        <pre style={{ color: "red", fontSize: 18 }}>
          Tekton is not configured to run in the production K8s cluster. Please use a staging build
          of Internal Tools to complete DevOps tasks.
        </pre>
      </ScreenContainer>
    )
  }

  if (retry.error) {
    return (
      <ScreenContainer title={`Tekton Pipeline Run - ${pipelineRunId} - Error`}>
        {getPipelineRun.error && <ErrorComponent error={getPipelineRun.error} />}
      </ScreenContainer>
    )
  }

  if (!getPipelineRun.data) {
    return (
      <ScreenContainer title={`Tekton Pipeline Run - ${pipelineRunId} - Not Found`}>
        <Box>{`Pipeline ${pipelineRunId} not found`}</Box>
      </ScreenContainer>
    )
  }

  const status = getPipelineRun.data?.status?.conditions?.[0]?.reason

  return (
    <ScreenContainer title="Tekton Pipelines">
      <Stack direction="column" spacing={2} style={{ marginTop: "1rem" }}>
        <Stack direction="row" spacing={2}>
          <PipelineRunsTable pipelineRuns={[getPipelineRun.data]} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack direction="column">
            <h3>Definition</h3>
            <Box style={{ maxWidth: "50vw" }}>
              <pre style={{ margin: "0", overflow: "scroll", maxHeight: "82vh" }}>
                <code className="language-yaml">
                  {YAMLstringify(
                    omit(
                      getPipelineRun.data,
                      "metadata.managedFields",
                      'metadata.annotations["kubectl.kubernetes.io/last-applied-configuration"]',
                    ),
                    null,
                    2,
                  )}
                </code>
              </pre>
            </Box>
          </Stack>
          <Stack direction="column">
            <h3>Logs</h3>
            <Box style={{ overflow: "scroll", maxHeight: "82vh", minWidth: "100%" }}>
              <PipelineRunLogs pipelineRunId={getPipelineRun.data.metadata.name} status={status} />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </ScreenContainer>
  )
}

export default TektonPipelineRun
