import { Button, TextField } from "@mui/material"
import  { FunctionComponent, useCallback, useState } from "react"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreateDomainInstructions from "../hooks/useCreateDomainInstructions"

interface Props {
  isOpen: boolean
  handleClose: () => any
}

const CreateDomainInstructionDialog: FunctionComponent<Props> = ({ isOpen, handleClose }) => {
  const [name, setName] = useState<string>()
  const [instructionsUrl, setInstructionsUrl] = useState<string>()
  const [iconUrl, setIconUrl] = useState<string>()

  const { createDomainInstruction, loading, error } = useCreateDomainInstructions()

  const handleSetName = useCallback((event: any) => {
    setName(event?.target?.value)
  }, [])

  const handleSetInstructionsUrl = useCallback((event: any) => {
    setInstructionsUrl(event?.target?.value)
  }, [])

  const handleSetIconUrl = useCallback((event: any) => {
    setIconUrl(event?.target?.value)
  }, [])

  // TODO: Refactor this to use hooks and onSuccess() function
  // whenever this is all switched to Formik
  const handleConfirmClose = useCallback(async () => {
    if (name && instructionsUrl && iconUrl) {
      await createDomainInstruction(name, instructionsUrl, iconUrl, handleClose)
    }
  }, [name, iconUrl, handleClose, createDomainInstruction, instructionsUrl])

  return (
    <Dialog
      title="New Domain Instruction"
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <>
          {error ? <ErrorComponent error={error} /> : ""}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            fullWidth
            variant="outlined"
            required
            onChange={handleSetName}
          />
          <TextField
            margin="dense"
            id="instructions"
            label="Instruction URL"
            value={instructionsUrl}
            fullWidth
            variant="outlined"
            required
            onChange={handleSetInstructionsUrl}
          />
          <TextField
            margin="dense"
            id="iconUrl"
            label="Icon URL"
            value={iconUrl}
            fullWidth
            variant="outlined"
            required
            onChange={handleSetIconUrl}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={handleConfirmClose} color="primary" variant="outlined">
              Create Domain Instruction
            </Button>
          ) : null}
        </>
      }
    />
  )
}

export default CreateDomainInstructionDialog
