import { BLOCKTEMPLATES } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { BlockTemplate } from "../../../types"

const useGetBlockTemplates = (defaultRowsPerPage = 25) => {
  const {
    data,
    loading,
    error,
    performAction: getBlockTemplates,
  } = useHandleRequest(
    (
      handleUnauthenticated,
      searchState: string | undefined = undefined,
      enabled: Boolean = true,
      template_group: string | undefined = undefined,
      page: number = 0,
      rowsPerPage: number = defaultRowsPerPage,
    ): Promise<PagedResponse<BlockTemplate>> => {
      const query = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        page_section_type_id: searchState,
        deleted: !enabled,
        template_group,
      }
      return makeRequest<PagedResponse<BlockTemplate>>(
        `${BLOCKTEMPLATES}?${Object.entries(query)
          .map(
            ([key, value]: [string, string | number | boolean | undefined]) =>
              value && `${key}=${encodeURIComponent(value)}`,
          )
          .filter(Boolean)
          .join("&")}`,
        {},
        { handleUnauthenticated },
      )
    },
  )

  return {
    loading,
    error,
    getBlockTemplates,
    blockTemplates: data?.results || [],
    paginationData: {
      count: data?.metadata?.total || 0,
    },
  }
}

export default useGetBlockTemplates
