import { FunctionComponent, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import TemplateFiltersTable from "./components/TemplateFilterTable"
import TemplateFilterDialog from "./components/TemplateFilterDialog"
import useListTemplateFilters from "./hooks/useListTemplateFilters"
import useGetCollections from "../Tags/hooks/useGetCollections"
import useGetTags from "../Sites/hooks/useGetTags"

const TemplateFilterScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.templateFilters.create)

  const { getCollections, collections } = useGetCollections()
  const { getTags, tags } = useGetTags()

  const { loading, error, listTemplateFilters, templateFilters, paginationData } =
    useListTemplateFilters()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    listTemplateFilters()
  }

  useEffect(() => {
    listTemplateFilters()
    getCollections()
    getTags()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Template Filter" requiresScope={scopes.templateFilters.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {loading && !error ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <LoadingSpinner />
        </div>
      ) : (
        ""
      )}
      <>
        {canCreate && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              onClick={handleClickOpen}
            >
              Create Template Filter
            </Button>
            <br />
          </>
        )}
        <TemplateFiltersTable
          templateFilters={templateFilters}
          paginationData={paginationData}
          getTemplateFilters={listTemplateFilters}
          collections={collections}
          tags={tags}
        />
        {open ? (
          <TemplateFilterDialog
            isOpen={open}
            close={handleClose}
            collections={collections}
            tags={tags}
            maxSortOrder={templateFilters.length}
          />
        ) : null}
      </>
    </ScreenContainer>
  )
}

export default TemplateFilterScreen
