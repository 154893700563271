import { BLOCKS } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DBBlock, CreateDBBlock } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateBlock = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      blockId: string,
      block: CreateDBBlock,
      onSuccess?: () => any,
    ): Promise<DBBlock> => {
      const snippet = await makeRequest<DBBlock>(
        `${BLOCKS}/${blockId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...block }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        onSuccess()
      }

      return snippet
    },
  )

  return { updateBlock: performAction, loading, error }
}

export default useUpdateBlock
