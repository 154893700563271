import { SUBSCRIPTIONSV3 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useAssignSiteToSubscription = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      subscriptionId: number,
      siteId: number,
      onSuccess?: () => any,
    ): Promise<void> => {
      const response = await makeRequest<void>(
        `${SUBSCRIPTIONSV3}/${subscriptionId}/sites`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ site_id: siteId }),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { assignSiteToSubscription: performAction, loading, error }
}

export default useAssignSiteToSubscription
