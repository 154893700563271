import { styled } from "@mui/material/styles"
import { FunctionComponent } from "react"
import ConfirmationDialog, { ConfirmationRow } from "../../../components/shared/ConfirmationDialog"
import { TextBody } from "../../../components/shared/Typography"
import { User } from "../../../types"

interface Props {
  isOpen: boolean
  close: () => any
  handleConfirmation: () => any
  hasError: boolean
  loading: boolean
  user: User
}

export const StyledText: any = styled(TextBody)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning};
`

const DeleteUserConfirmationDialog: FunctionComponent<Props> = ({ user, hasError, ...rest }) => {
  return (
    <ConfirmationDialog
      title="Warning"
      errorMessage={hasError ? "There was a problem deleting this user" : null}
      affirmativeButtonText="Delete User"
      content={
        <>
          <ConfirmationRow>
            <TextBody>You are about to delete the following user:</TextBody>
          </ConfirmationRow>
          <ConfirmationRow>
            <StyledText>{`${user.id} - ${user.first_name} ${user.last_name}`}</StyledText>
          </ConfirmationRow>
          <ConfirmationRow pb="15px" pt="8px">
            <TextBody>Would you like to continue deleting this user?</TextBody>
          </ConfirmationRow>
        </>
      }
      {...rest}
    />
  )
}

export default DeleteUserConfirmationDialog
