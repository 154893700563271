import { useMemo, useState } from "react"
import Dialog, { Row } from "../../../components/shared/Dialog"
import { Box, Paper, Button, Chip, Divider, Typography } from "@mui/material"
import { Tag, BlockTemplate } from "../../../types"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest, { PagedResponse } from "../../../utils/make-request"
import { TAGSV4, BLOCKTEMPLATES } from "../../../constants/urlDefs"
import useUpdateTagsForBlockTemplate from "../../Sites/hooks/useUpdateTagsForBlockTemplate"
import { LoadingButton } from "@mui/lab"
import { TextBody } from "../../../components/shared/Typography"
import toast from "react-hot-toast"

export interface SelectableTag {
  tag: Tag
  selected: boolean
}

interface State {
  blockTemplate: BlockTemplate
  tags: SelectableTag[]
}

const BlockTemplateTagDialog = ({
  blockTemplate,
  close,
  tags: rawTags,
}: {
  blockTemplate: BlockTemplate
  tags: SelectableTag[]
  close: () => void
}) => {
  const [tags, setTags] = useState(rawTags)

  const { loading, error, updateTagsForBlockTemplate } = useUpdateTagsForBlockTemplate()

  const save = () => {
    const selectedTags = tags.filter((t) => t.selected).map((t) => t.tag)
    updateTagsForBlockTemplate(blockTemplate.id, selectedTags, () => {
      toast.success("Successfully updated tags!")
      close()
    })
  }

  const toggleTag = (tag: Tag) => {
    const index = tags.findIndex((t) => t.tag.id === tag.id)
    const newTags = [...tags]
    newTags[index].selected = !newTags[index].selected
    setTags(newTags)
  }

  const collections: string[] = useMemo(() => {
    const collectionSet = tags.reduce((acc, next) => {
      next.tag.collections?.forEach((collection) => {
        acc.add(collection)
      })

      return acc
    }, new Set<string>())

    return Array.from(collectionSet).sort()
  }, [tags])

  const [filter, setFilter] = useState<string | null>(null)

  const filteredTags = useMemo(() => {
    return tags
      .filter((tag) => {
        if (!filter) {
          return true
        }

        const collections = tag.tag.collections || []
        return collections.includes(filter)
      })
      .sort()
  }, [filter, tags])

  return (
    <Dialog
      title={`Editing Tags for BlockTemplate '${blockTemplate.name}'`}
      isOpen={true}
      handleClose={close}
      content={
        <Box>
          {error ? (
            <Row mb="4px" mt="4px">
              <TextBody hasError={true}>There was a problem updating the tags.</TextBody>
            </Row>
          ) : null}
          <Row>
            <Paper
              sx={{
                listStyle: "none",
                padding: (blockTemplate: any) => blockTemplate.spacing(0.5),
                margin: 0,
                borderColor: "#000",
                boxShadow: "none",
              }}
            >
              <div>
                <Typography variant="h6">Collection</Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Chip
                    key={"all-filter"}
                    sx={{
                      margin: (template) => template.spacing(0.5),
                      padding: "4px 6px",
                      borderRadius: "100px",
                    }}
                    label={"All"}
                    color={filter ? undefined : "secondary"}
                    onClick={() => setFilter(null)}
                    disabled={loading}
                  />

                  {collections.map((collection) => {
                    const selected = filter === collection
                    return (
                      <Chip
                        key={`${collection.toLowerCase()}-filter`}
                        sx={{
                          margin: (template) => template.spacing(0.5),
                          padding: "4px 6px",
                          borderRadius: "100px",
                        }}
                        label={collection}
                        color={selected ? "secondary" : undefined}
                        onClick={() => setFilter(collection)}
                        disabled={loading}
                      />
                    )
                  })}
                </div>
              </div>

              <Divider style={{ marginTop: 12, marginBottom: 12 }} />

              <div>
                <Typography variant="h6">Tags</Typography>

                <div>
                  {filteredTags.map((tagSelection, index) => {
                    const { tag, selected } = tagSelection

                    return (
                      <Chip
                        key={index}
                        sx={{
                          margin: (template) => template.spacing(0.5),
                          borderRadius: "8px",
                        }}
                        label={tag.display_title}
                        color={selected ? "primary" : undefined}
                        onClick={() => toggleTag(tag)}
                        disabled={loading}
                      />
                    )
                  })}
                </div>
              </div>
            </Paper>
          </Row>
        </Box>
      }
      buttonContent={
        <>
          <Button onClick={close} color="error" variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={save}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Update
          </LoadingButton>
        </>
      }
    />
  )
}

const useBlockTemplateTagDialog = () => {
  const [state, setState] = useState<State>()

  const { loading, performAction } = useHandleRequest(
    async (handleUnauthenticated, blockTemplate: BlockTemplate) => {
      const getTags = makeRequest<PagedResponse<Tag>>(TAGSV4, {}, { handleUnauthenticated })
      const getTagsForBlockTemplate = makeRequest<BlockTemplate>(
        `${BLOCKTEMPLATES}/${blockTemplate.id}?include=tags`,
        {},
        { handleUnauthenticated },
      ).then((blockTemplate) => blockTemplate.tags || [])

      return Promise.all([getTags, getTagsForBlockTemplate]).then(
        ([allTags, blockTemplateTags]) => {
          const blockTemplateTagsByIdMap = blockTemplateTags.reduce((acc, next) => {
            acc[next.id] = next
            return acc
          }, {} as { [key: number]: Tag })

          const selectableTags = allTags.results.map((tag) => ({
            tag,
            selected: !!blockTemplateTagsByIdMap[tag.id],
          }))
          setState({ tags: selectableTags, blockTemplate })
        },
      )
    },
  )

  const close = () => setState(undefined)

  const open = (blockTemplate: BlockTemplate) => {
    performAction(blockTemplate)
  }

  const dialog = state ? (
    <BlockTemplateTagDialog blockTemplate={state.blockTemplate} tags={state.tags} close={close} />
  ) : null

  return {
    blockTemplateTagDialog: dialog,
    blockTemplateTagDialogLoading: loading,
    openBlockTemplateTagDialog: open,
  }
}

export default useBlockTemplateTagDialog
