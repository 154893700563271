import { SITESV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { ViewerSubscription } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUnsubscribeViewerSubscriber = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      siteId: number,
      id: number,
      onSuccess?: () => any,
    ): Promise<ViewerSubscription> => {
      const response = await makeRequest<ViewerSubscription>(
        `${SITESV4}/${siteId}/subscribers/${id}/unsubscribe`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { unsubscribeViewerSubscriber: performAction, loading, error }
}

export default useUnsubscribeViewerSubscriber
