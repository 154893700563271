import { REMOTE_VALUE } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { RemoteValue } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateRemoteValue = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      value: RemoteValue,
      onSuccess?: () => any,
    ): Promise<RemoteValue> => {
      const requestBody = {
        key: value.key,
        value: value.value,
        description: value.description,
      }
      const response = await makeRequest<RemoteValue>(
        `${REMOTE_VALUE}/${value.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { updateRemoteValue: performAction, loading, error }
}

export default useUpdateRemoteValue
