import { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/layout/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { scopes } from "../../scopes"
import CodeSnippetsTable from "./components/CodeSnippetsTable"
import useGetCodeSnippets from "./hooks/useGetCodeSnippets"
import { Button } from "@mui/material"
import { useAuth } from "../../services/auth-service"
import CodeSnippetDialog from "./components/CodeSnippetDialog"

const CodeSnippetsScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const { hasScope } = useAuth()
  const canCreate = hasScope(scopes.codeSnippets.create)
  const canEdit = hasScope(scopes.codeSnippet.update)

  const { loading, error, getCodeSnippets, codeSnippets, paginationData } = useGetCodeSnippets()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getCodeSnippets()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Code Snippets" requiresScope={scopes.codeSnippets.read}>
      {error ? <ErrorComponent error={error} /> : ""}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <br />
          {canCreate && (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "300px" }}
                onClick={handleClickOpen}
              >
                Create Code Snippet
              </Button>
              <br />
            </>
          )}
          <CodeSnippetsTable
            codeSnippets={codeSnippets}
            paginationData={paginationData}
            canEdit={canEdit}
          />
          {open ? <CodeSnippetDialog isOpen={open} close={handleClose} /> : null}
        </>
      )}
    </ScreenContainer>
  )
}

export default CodeSnippetsScreen
