import  { FunctionComponent } from "react"
import { Link, LinkProps } from "react-router-dom"

interface Props extends LinkProps {
  merchantId: string | number
}

type UserLinkProps = Omit<Props, "to">

const MerchantLink: FunctionComponent<UserLinkProps> = ({ merchantId, children, ...rest }) => (
  <Link {...rest} style={{ textDecoration: "none" }} to={`/merchants/${merchantId}`}>
    {children}
  </Link>
)

export default MerchantLink
