import { createContext, useContext, useEffect, useMemo, useState } from "react"

import { GetMeResponse } from "../types/users"
import Future from "../utils/future"
import makeRequest from "../utils/make-request"
import { RequestError } from "../utils/make-request"
import enforceError from "../utils/enforce-error"

export const BILLING_ADMIN = "billing-admin"
export const EMPLOYEE_ADMIN = "employee-admin"
export const PROMO_GENERATOR = "promo-code-generator"
export const DEV_ROLES = ["developer-admin", "api-developer", "app-developer"]

type GoogleCredentialResponse = {
  credential: string
}

type AuthContextType = {
  isAuthenticating: boolean
  user: GetMeResponse | null
  authenticationError: Error | null
  getUser: () => GetMeResponse | null
  isAuthenticated: boolean
  hasScope: (scope: string) => boolean
  handleUnauthenticated: () => Promise<void>
  handleLogout: () => void
}

const getUserForToken = async (token: string): Promise<GetMeResponse> =>
  makeRequest<GetMeResponse>("/api/internal/me", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

const getUserIfAuthenticated = async (): Promise<GetMeResponse | null> => {
  try {
    return await makeRequest<GetMeResponse>("/api/internal/me")
  } catch (error) {
    if (error instanceof RequestError && error.status === 401) {
      return null
    }

    throw error
  }
}

const AuthContext = createContext(null as any as AuthContextType)

export const AuthProvider = ({
  googleClientId,
  children,
}: {
  googleClientId: string
  children: any
}) => {
  const [user, setUser] = useState<GetMeResponse | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [userFuture, setUserFuture] = useState<Future<GetMeResponse> | null>(null)

  const isAuthenticating = !!userFuture

  const getNewUser = (): Promise<GetMeResponse> => {
    const google = (window as any).google

    // Trigger new token -> user flow - should be fine to call this multiple times
    google.accounts && google.accounts.id && google.accounts.id.prompt()

    if (userFuture) {
      return userFuture.promise
    }

    const newFuture = new Future<GetMeResponse>()
    setUserFuture(newFuture)

    return newFuture.promise
  }

  const handleUnauthenticated = async (): Promise<void> => {
    if (user && !isAuthenticating) {
      setUser(null)
    }

    await getNewUser()
  }

  const handleLoginSuccess = (user: GetMeResponse) => {
    setUser(user)
    setError(null)

    userFuture?.completeValue(user)
    setUserFuture(null)
  }

  const handleLoginFailure = (error: Error) => {
    setUser(null)
    setError(error)

    userFuture?.completeError(error)
    setUserFuture(null)
  }

  const handleLogout = () => {
    setUser(null)
    setUserFuture(null)
  }

  const value = useMemo(() => {
    return {
      isAuthenticating,
      user,
      authenticationError: error,
      getUser: () => user,
      isAuthenticated: !!user,
      hasScope: (scope: string) => user?.scopes.includes(scope) || false,
      handleUnauthenticated,
      handleLogout,
    }
  }, [isAuthenticating, user, error])

  useEffect(() => {
    const receivedGoogleCredentialCallback = async (response: GoogleCredentialResponse) => {
      const { credential } = response

      try {
        if (!credential) {
          throw new Error("Invalid Google Auth Response")
        }

        const userResponse = await getUserForToken(credential)

        handleLoginSuccess(userResponse)
      } catch (error) {
        handleLoginFailure(enforceError(error))
      }
    }

    const google = (window as any).google

    google.accounts.id.initialize({
      client_id: googleClientId,
      auto_select: true,
      callback: receivedGoogleCredentialCallback,
      ux_mode: "popup",
    })

    const newFuture = new Future<GetMeResponse>()
    setUserFuture(newFuture)

    getUserIfAuthenticated()
      .then((existingUser) => {
        if (existingUser) {
          handleLoginSuccess(existingUser)
        } else {
          getNewUser()
        }
      })
      .catch((error) => {
        console.log("Error attempting to fetch user, triggering new login")
        getNewUser()
      })
    // eslint-disable-next-line
  }, [googleClientId])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
