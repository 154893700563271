import { TEXTGENERATIONACTIONSV4 } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { TextGenerationAction } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteTextGenerationAction = () => {
  const { loading, error, performAction } = useHandleRequest(
    async (
      handleUnauthenticated,
      textGenActionId: number,
      onSuccess?: () => any,
    ): Promise<TextGenerationAction> => {
      const response = await makeRequest<TextGenerationAction>(
        `${TEXTGENERATIONACTIONSV4}/${textGenActionId}`,
        {
          method: "DELETE",
        },
        { handleUnauthenticated },
      )

      if (onSuccess) {
        await onSuccess()
      }

      return response
    },
  )

  return { deleteTextGenerationAction: performAction, loading, error }
}

export default useDeleteTextGenerationAction
