import { Button } from "@mui/material"
import dayjs from "dayjs"
import  { FunctionComponent, useCallback } from "react"
import { useNavigate } from "react-router"
import BasicTableCell from "../../../../components/shared/BasicTable/BasicTableCell"
import BasicTableRow from "../../../../components/shared/BasicTable/BasicTableRow"
import { Promo } from "../../../../types"

type PromoRowProps = {
  promo: Promo
}

const PromoRow: FunctionComponent<PromoRowProps> = ({ promo }) => {
  const navigate = useNavigate()

  const navigateToPromo = useCallback(() => {
    navigate(`/promos/${promo.id}`)
  }, [navigate, promo])

  const limitString =
    promo.redemption_limit || promo.redemption_limit === 0 ? `${promo.redemption_limit || 0}` : "∞"
  const redemptionString = `${promo.redemption_count} / ${limitString}`
  const creationDate = promo.created_at && dayjs(promo.created_at).format("MM/DD/YYYY")
  const expirationDate = promo.expiration_date && dayjs(promo.expiration_date).format("MM/DD/YYYY")

  return (
    <BasicTableRow key={promo.id}>
      <BasicTableCell>{promo.id}</BasicTableCell>
      <BasicTableCell>{promo.code}</BasicTableCell>
      <BasicTableCell>{promo.discount_key}</BasicTableCell>
      <BasicTableCell>{promo.purpose}</BasicTableCell>
      <BasicTableCell>{redemptionString}</BasicTableCell>
      <BasicTableCell>{creationDate}</BasicTableCell>
      <BasicTableCell>{expirationDate}</BasicTableCell>
      <BasicTableCell>
        <Button variant="outlined" color="primary" onClick={navigateToPromo}>
          Details
        </Button>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default PromoRow
